export const SlideListRendererClassNames = {
  SmallSlide: "slr_small-slide",
  SmallSlidePage: "slr_small-slide-page",
  SmallSlideWrapper: "slr_small-slide-wrapper",
  SmallSlidePageIndicator: "slr_small-slide",
  SmallSlideWrapperIsActive: "slr_small-slide-wrapper--is-active",
  SlidesList: "slr_slides-list",
  SmallSlideLoadingPage: "slr_small-slide-loading-page",
  SmallSlideLoadingPageAnimation: "slr_small-slide-loading-page-animation",
  SlidesSidebar: "slr_slides-sidebar",
  SlidesSidebarInvisible: "slr_slides-sidebar--invisible",
};

export interface SlideListRendererProps {
  render: boolean;
  visible: boolean;
  readyToRender: boolean;
  pagesToRender: number;
  currentPage: number;
  onPageSelected: (page: number) => void;
}
