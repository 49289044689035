import {
  ApolloError,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useEffect, useState } from "react";

import { throttle } from "lodash";

import {
  SAVE_ATTENDEE_ATTESTATION_FORM_DETAILS_MUTATION,
  ATTENDEES_QUERY,
  GetAttendeeData,
  GetAttendeeVariables,
  ATTENDEE_QUERY,
  ATTENDEES_JOINED_SUBSCRIPTION,
  ATTENDEES_LEFT_SUBSCRIPTION,
  ATTENDEES_COUNT_QUERY,
  UPDATE_ATTENDEES_INTERVAL,
  GENERATE_EVENT_ATTENDEE_ATTENDANCE_REPORT,
  AttendeeJoinedStatusSubData,
  AttendeeJoinedStatusSubVars,
  AttendeeLeftStatusSubData,
  AttendeeLeftStatusSubVars,
  AttendeesCountData,
  AttendeesCountVars,
  GenerateEventAttendeesAttendanceReportData,
  GenerateEventAttendeesAttendanceReportVars,
  AttendeesCountQueryHookProps,
  GetAttendeesData,
  GetAttendeesVariables,
} from "./attendees.hook.definition";
import {
  addAttendeeToAttendeeList,
  removeAttendeeFromAttendeeList,
} from "./attendees.hook.utilities";

export const attendeesMergePolicy = {
  attendeesCount: {
    merge: false,
  },
  attendees: {
    merge: false,
  },
};

export function useSaveAttendeeAttestationFormDetailsMutation(options?: MutationHookOptions) {
  return useMutation(SAVE_ATTENDEE_ATTESTATION_FORM_DETAILS_MUTATION, options);
}

export function useAttendeesCountQuery(
  options: QueryHookOptions<AttendeesCountData, AttendeesCountVars>
) {
  return useQuery(ATTENDEES_COUNT_QUERY, options);
}

export function useAttendeesCountLazyQuery(
  options: QueryHookOptions<AttendeesCountData, AttendeesCountVars>
) {
  return useLazyQuery(ATTENDEES_COUNT_QUERY, options);
}

export function useGenerateEventAttendeesAttendanceReportMutation(
  options?: MutationHookOptions<
    GenerateEventAttendeesAttendanceReportData,
    GenerateEventAttendeesAttendanceReportVars
  >
) {
  return useMutation(GENERATE_EVENT_ATTENDEE_ATTENDANCE_REPORT, options);
}

export function useAttendeeJoinedSubscription(
  options?: SubscriptionHookOptions<AttendeeJoinedStatusSubData, AttendeeJoinedStatusSubVars>
) {
  return useSubscription<AttendeeJoinedStatusSubData, AttendeeJoinedStatusSubVars>(
    ATTENDEES_JOINED_SUBSCRIPTION,
    options
  );
}

export function useAttendeeLeftSubscription(
  options?: SubscriptionHookOptions<AttendeeLeftStatusSubData, AttendeeLeftStatusSubVars>
) {
  return useSubscription<AttendeeLeftStatusSubData, AttendeeLeftStatusSubVars>(
    ATTENDEES_LEFT_SUBSCRIPTION,
    options
  );
}

export function useAttendeesCountQueryHook(props: AttendeesCountQueryHookProps) {
  const { meetingId, live } = props;
  const [attendeesCount, setAttendeesCount] = useState<number>();

  const { loading: attendeesCountLoading, refetch: refetchAttendeesCount } = useAttendeesCountQuery(
    {
      variables: { meetingId, live },
      onCompleted: (data) => {
        setAttendeesCount(data.attendeesCount);
      },
      onError: (error: ApolloError) => {
        console.warn(`Failed to get attendee count ${error.message}`);
      },
    }
  );
  return {
    attendeesCount,
    attendeesCountLoading,
    refetchAttendeesCount,
  };
}

export function useAttendeesQuery(
  options: QueryHookOptions<GetAttendeesData, GetAttendeesVariables>
) {
  return useQuery<any, any>(ATTENDEES_QUERY, options);
}

export function useGetAttendeeQuery(
  options: QueryHookOptions<GetAttendeeData, GetAttendeeVariables>
) {
  return useQuery(ATTENDEE_QUERY, { fetchPolicy: "cache-and-network", ...options });
}

const attendeeJoinedUpdateQuery = throttle((prev, { subscriptionData }) => {
  if (!subscriptionData) return prev;
  return addAttendeeToAttendeeList(subscriptionData, prev);
}, UPDATE_ATTENDEES_INTERVAL);

const attendeeLeftUpdateQuery = throttle((prev, { subscriptionData }) => {
  if (!subscriptionData) return prev;
  return removeAttendeeFromAttendeeList(subscriptionData, prev);
}, UPDATE_ATTENDEES_INTERVAL);

export function useAttendeesQueryHook(props: GetAttendeesVariables) {
  const { meetingId, attendeeFilter, page, limit } = props;

  const {
    subscribeToMore: subscribeToAttendeeUpdated,
    data: attendeesData,
    loading: attendeesLoading,
  } = useAttendeesQuery({
    variables: {
      meetingId,
      attendeeFilter: attendeeFilter,
      page: page,
      limit: limit,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    /** Subscribe to attendees joining */
    subscribeToAttendeeUpdated<any>({
      document: ATTENDEES_JOINED_SUBSCRIPTION,
      variables: { meetingId },
      updateQuery: attendeeJoinedUpdateQuery,
    });

    /** Subscribe to attendees leaving */
    subscribeToAttendeeUpdated<any>({
      document: ATTENDEES_LEFT_SUBSCRIPTION,
      variables: { meetingId },
      updateQuery: attendeeLeftUpdateQuery,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    subscribeToAttendeeUpdated,
    attendeesData,
    attendeesLoading,
  };
}
