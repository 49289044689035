import config from "../../config";

export const HelpCenterConfigs = {
  url: `${config.app.helpCenterUrl}/index.html?lang=en`,
  token: {
    key: `${config.app.stage}.helpCenterToken`,
    domain: config.app.helpCenterCookieDomain || "",
    secure: process.env.NODE_ENV === "production",
  },
};
