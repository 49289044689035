import {
  PlatformRoles,
  UserEventRole,
} from "../../../../configurations/userRolesPermissions.configuration";
import { EventContextState } from "../../../../contexts/event/event.definition";
import { ServicesContextModel } from "../../../../contexts/services/services.context";

export interface CurrentUser {
  userName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  userPlatformRole: PlatformRoles;
  userEventRole: UserEventRole;
  isGuest: boolean;
}

export interface OpenTokProps {
  currentUser?: CurrentUser;
  services?: ServicesContextModel;
  eventContext?: EventContextState;
}

export enum OpenTokClassNames {
  Base = "open-tok",
  BaseWithSubscribersModifier = "open-tok--with-subscribers",
  Inner = "open-tok_inner",
  ScaleWrapper = "open-tok_scale-wrapper",
  VideoWrapper = "open-tok_video-wrapper",
  StageWrapper = "open-tok_stage-wrapper",
  AssetPreview = "open-tok_asset-preview",
  AssetStaged = "open-tok_asset-staged",
  Publisher = "open-tok_publisher",
  Streams = "open-tok_streams",
  Subscriber = "open-tok_subscriber",
  Preview = "open-tok_button-preview",
  Collapse = "open-tok_button-collapse",
  Stage = "open-tok_button-stage",
  StagePopover = "open-tok_button-stage-popover",
  AddToStageConfirmation = "open-tok_button-stage-add",
  ReplaceStageConfirmation = "open-tok_button-stage-replace",

  OTPublisherWrapper = "OT-publisher-wrapper",
  OTShareScreenWrapper = "OT-share-screen-publisher-wrapper",
  OTSubscriberWrapper = "OT-subscriber-wrapper",

  SelectionPreviewModal = "open-tok_selection-preview-modal",

  CheckboxWrapper = "open-tok_checkbox",
  CheckboxContainer = "open-tok_checkbox-container",
  ToggleSpeakerThumbnailsModal = "open-tok_modal-toggle-speaker-thumbnails",
  ToggleSpeakerThumbnailsButton = "open-tok_button-toggle-speaker-thumbnails",
  ToggleSpeakerThumbnailsCheckbox = "open-tok_checkbox-toggle-speaker-thumbnails",
  ToggleAutoPlayCheckbox = "open-tok_checkbox-toggle-autoplay",
  SwitchBroadcastStream = "open-tok_switch-broadcast-stream",
  BroadcastStreamConfirmation = "open-tok_broadcast-stream-confirmation",
}

export const OpenTokConfig = {
  apiToken: process.env.REACT_APP_OPENTOK_API_KEY,
};

export enum OpenTokErrorCodes {
  UNABLE_TO_PUBLISH = 1500,
}

export const SubscriberDisplayCount = 4;
export const SubscriberGutter = 16;

// Using viewport dimensions so carousel doesn't get cut off
// on some screens
export const SubscriberWidth = "32vh";

export enum PublisherWidth {
  Collapsed = "100%",
  Expanded = "80%",
}

export const MAX_SCREENSHARE_ALLOWED = 2;
