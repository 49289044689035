import { ButtonTheme, Message, MessageType } from "@q4/nimbus-ui";
import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import DisplayAssetDuration from "../../../../components/AudioVideoControls/displayDuration.component";
import { CustomAvatarSizes } from "../../../../components/customAvatar/customAvatar.definition";
import { EventPermission } from "../../../../configurations/userRolesPermissions.configuration";
import { AdminConsoleContext, EventContext, PermissionObj } from "../../../../contexts";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { close, open } from "../../../../utils/ui.utils";
import { useStreamThumbnail } from "../../hooks/streamThumbnail/streamThumbnail.hook";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import {
  AudioStreamWrapper,
  AWSelectionTheme,
} from "../audioStreamWrapper/audioStreamWrapper.component";
import { BroadcastStreamBadge } from "../BroadcastStreamBadge/BroadcastStreamBadge";
import { VideoWrapper } from "../VideoWrapper/VideoWrapper.component";
import { VWSelectionTheme } from "../VideoWrapper/VideoWrapper.definition";
import { useStreamDisplayName } from "./hooks/useStreamDisplayName.hook";
import { StagedStreamWrapperClassNames } from "./streamWrapper.definition";
import "./stageStreamWrapper.style.scss";
import { FeatureFlagKeys } from "../../../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../../../contexts/featureFlags";
import { useRebrandFlag } from "../../../../hooks/featureFlagHooks/useRebrandFlag.hook";

interface StageStreamWrapperProps extends PropsWithChildren<unknown> {
  audioOnly?: boolean;
  participant: Participant;
  previewMode?: boolean;
  stream: BroadcastStream;
  userEventPermissions?: PermissionObj;
  avatarSize?: CustomAvatarSizes;
  onRemoveStream: (stream: BroadcastStream) => void;
}
export const StageStreamWrapper = (props: StageStreamWrapperProps) => {
  const {
    audioOnly = false,
    children = null,
    participant,
    previewMode = false,
    stream,
    userEventPermissions,
    avatarSize = CustomAvatarSizes.Large,
    onRemoveStream,
  } = props;

  const { currentEvent } = useContext(EventContext);
  const { dominantSpeakerStream } = useContext(AdminConsoleContext);

  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);

  const { featureFlags } = useContext(FeatureFlagsContext);
  const { getRebrandingClassName } = useRebrandFlag();

  const removeFromStage = useCallback(
    (_event) => {
      onRemoveStream?.(stream);
      close(setConfirmationVisible)();
    },
    [stream, onRemoveStream]
  );

  /** Stream Info */
  const { streamDisplayName, phoneNumberVisible } = useStreamDisplayName({ stream, participant });

  const dominantSpeaker = useMemo(
    () => dominantSpeakerStream?.id === stream?.id,
    [dominantSpeakerStream?.id, stream?.id]
  );

  const isPlayableAsset = useMemo(() => stream?.isVideoAsset || stream?.isAudioAsset, [stream]);

  /** Thumbnails */
  const thumbnail = useStreamThumbnail({
    stream,
    displayName: phoneNumberVisible ? null : streamDisplayName,
    useV2: currentEvent?.configuration?.layoutManager?.enabled,
    size: avatarSize,
    classNames: {
      wrapper: StagedStreamWrapperClassNames.Thumbnail,
      content: StagedStreamWrapperClassNames.Avatar,
    },
  });

  return (
    <>
      {audioOnly ? (
        <AudioStreamWrapper
          id={stream?.id}
          displayName={streamDisplayName}
          customLeftBadge={
            <BroadcastStreamBadge stream={stream} participant={participant} audioOnly={audioOnly} />
          }
          primaryAction={
            !previewMode &&
            userEventPermissions?.[EventPermission.eventManageStreams] && {
              icon: "q4i-close-4pt",
              onClick: open(setConfirmationVisible),
            }
          }
          previewMode={previewMode}
          selected={dominantSpeaker}
          selectionTheme={AWSelectionTheme.teal}
        />
      ) : (
        <VideoWrapper
          id={stream?.id}
          displayName={streamDisplayName}
          customLeftBadge={
            <BroadcastStreamBadge stream={stream} participant={participant} audioOnly={audioOnly} />
          }
          customRightBadge={isPlayableAsset && <DisplayAssetDuration stream={stream} />}
          topRightProps={
            !previewMode &&
            userEventPermissions?.[EventPermission.eventManageStreams] && {
              buttonProps: { icon: "q4i-close-4pt", onClick: open(setConfirmationVisible) },
              showTRMOnHover: true,
            }
          }
          topLeftProps={{
            release:
              stream?.isV2AssetStream &&
              currentEvent.configuration?.layoutManager?.enabled &&
              featureFlags[FeatureFlagKeys.LayoutManagerUi]?.tagState,
          }}
          thumbnailImg={thumbnail}
          selected={dominantSpeaker}
          selectionTheme={VWSelectionTheme.teal}
        >
          {children}
        </VideoWrapper>
      )}
      <Message
        className={getRebrandingClassName()}
        messageType={MessageType.Warning}
        title="Remove From Stage"
        message={`${streamDisplayName || "Your selection"} will be removed from the live stage`}
        visible={confirmationVisible}
        primaryActionProps={{
          label: "remove",
          // @ts-ignore
          theme: ButtonTheme.Cherry,
          onClick: removeFromStage,
        }}
        secondaryActionProps={{
          label: "cancel",
          onClick: close(setConfirmationVisible),
        }}
        onCloseRequest={close(setConfirmationVisible)}
      />
    </>
  );
};
