import { fetchAttendeeApi } from "../util";
import useSWR from "swr";
import { RETRY_COUNT } from "../definition";

interface UseGetRequestProps {
  path: string;
  skip?: boolean;
  refreshInterval?: number;
  getAuthToken?: () => Promise<string>;
  onErrorRetry?: (
    error: any,
    key: string,
    config: any,
    revalidate: any,
    opts: { retryCount: number }
  ) => void;
}

interface ApiFetcherProps {
  url: string;
  getAuthToken: () => Promise<string>;
}

const apiFetcher = async ({ url, getAuthToken }: ApiFetcherProps) => {
  const headers = new Headers();
  if (getAuthToken) {
    const token = await getAuthToken();
    if (!token) throw new Error("No token found");
    headers.set("Authorization", `Bearer ${token}`);
  }
  if (!url) throw new Error("No url found");
  return fetchAttendeeApi(url, {
    method: "GET",
    headers,
  });
};

export const useGetRequest = (props: UseGetRequestProps) => {
  const { skip, refreshInterval, path, getAuthToken, onErrorRetry } = props;

  const { data, error, isLoading, mutate } = useSWR({ url: path, getAuthToken }, apiFetcher, {
    initialData: null,
    revalidateOnMount: !skip,
    revalidateOnFocus: false,
    errorRetryCount: RETRY_COUNT,
    refreshInterval: !skip && refreshInterval,
    ...(onErrorRetry && { onErrorRetry })
  });

  return {
    data: data?.data,
    error,
    isLoading,
    refetch: mutate
  };
};
