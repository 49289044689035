import { TextTheme, Text } from "@q4/nimbus-ui";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { EventAssetsContext, EventContext } from "../../contexts";
import { AudioVideoUploads } from "../../hooks/assetUploads/assetUploads.definition";
import { formatDuration } from "../../utils/datetime.utils";
import {
  DisplayAssetDurationClassNames,
  DisplayAssetDurationProps,
} from "./AudioVideoControls.definition";

function DisplayAssetDuration(props: DisplayAssetDurationProps) {
  const { stream } = props;

  const [currentTime, setCurrentTime] = useState(0);

  const { currentEvent: event } = useContext(EventContext);
  const { assetsState } = useContext(EventAssetsContext);

  useEffect(() => {
    const currentAsset = assetsState.find(
      (asset) => asset.name === stream.id || asset.name === stream.name
    );
    setCurrentTime(currentAsset?.currentTime);
  }, [assetsState, stream.id, stream.name]);

  useEffect(() => {
    // check to see if the current time needs to be rerendered every 1/4 second
    if (stream.isV2AssetStream) {
      const rerender = setInterval(() => {
        setCurrentTime(stream.videoElement()?.currentTime);
      }, 250);
      return () => {
        clearInterval(rerender);
      };
    }
  }, [stream]);

  const currentAssetTime = useMemo(() => {
    return formatDuration(currentTime);
  }, [currentTime]);

  const currentAssetDuration = useMemo(() => {
    const currentAsset = event?.assetUploads?.find(
      (asset) => asset.id === stream.id || asset.id === stream.name
    );
    return formatDuration((currentAsset as AudioVideoUploads)?.context.duration);
  }, [event, stream.id, stream.name]);

  return (
    <div className={DisplayAssetDurationClassNames.base}>
      <div className={DisplayAssetDurationClassNames.wrapper}>
        <Text theme={TextTheme.White}>
          {currentAssetTime} / {currentAssetDuration}
        </Text>
      </div>
    </div>
  );
}

export default memo(DisplayAssetDuration);
