import { Scrollbars, ScrollbarsTheme } from "@q4/nimbus-ui";
import { keys } from "lodash";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PrivateRouteProps } from "./privateRoute.definition";

import { Route, withRouter } from "react-router-dom";
import { userHasPermittedPermissions } from "../../../../utils/userHasPermittedPermissions";
import { userHasPermittedRoles } from "../../../../utils/userHasPermittedRoles";

import InactivityPrompt from "../../../../components/inactivityPrompt";
import { LoadingScreen } from "../../../../components/loadingScreen/loadingScreen";
import Navigation from "../../../../components/navigation/navigation.component";
import {
  BuildRoleBasedNavigationRoutes,
  NavigationBarRoutes,
} from "../../../../configurations/navigation.configuration";
import { AppContext } from "../../../../contexts/app/app.context";
import { useRebrandFlag } from "../../../../hooks/featureFlagHooks/useRebrandFlag.hook";
import { minutesToMilliseconds } from "../../../../utils/datetime.utils";
import { AppClassNames } from "../../app.definition";
import Header from "../header/header.component";

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const {
    isAuthenticated,
    isLoading,
    path,
    routeDetails,
    user,
    loginWithRedirect,
    logoutApplication,
    updateUser,
    location,
    ...rest
  } = props;

  const { updateAppDomain } = useContext(AppContext);

  const { logoClassName: logoIconClassName } = useRebrandFlag();

  const [userPermitted, setUserPermitted] = useState(false);
  const [navBarRoutes, setNavBarRoutes] = useState([]);

  const buildUserBasedNavigationRoutes = useCallback(() => {
    const navigationBarRoutes = BuildRoleBasedNavigationRoutes(NavigationBarRoutes, user);
    setNavBarRoutes(navigationBarRoutes);
  }, [user]);

  const determineUsersAccessToRoute = useCallback(() => {
    const { permittedRoles, permittedPermissions } = routeDetails;

    const hasAccess =
      userHasPermittedRoles(user?.userTypes, permittedRoles) ||
      userHasPermittedPermissions(keys(user?.permissions), permittedPermissions);

    setUserPermitted(hasAccess);
  }, [routeDetails, user?.permissions, user?.userTypes]);

  useEffect(
    function setAppDomainForRoute() {
      updateAppDomain(routeDetails.appDomain);
    },
    [routeDetails, updateAppDomain]
  );

  useEffect(
    function redirectAfterLogin() {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            targetUrl: `${location?.pathname ?? path}${location?.search ?? window.location.search}`,
          },
        });
      }
    },
    [isAuthenticated, loginWithRedirect, location?.pathname, location?.search, isLoading, path]
  );

  useEffect(
    function isUserAuthenticatedCheck() {
      if (isAuthenticated) {
        determineUsersAccessToRoute();
        buildUserBasedNavigationRoutes();
      }
    },
    [isAuthenticated] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const logo = useMemo(
    () => <i className={`${AppClassNames.Logo} ${logoIconClassName}`} />,
    [logoIconClassName]
  );
  const collapsedLogo = useMemo(
    () => <i className={`${AppClassNames.CollapsedLogo} ${logoIconClassName}`} />,
    [logoIconClassName]
  );

  const { component: Component, showNavigation, showHistoryBack } = routeDetails;
  const render = (props: any) => {
    return isAuthenticated && userPermitted ? (
      <>
        {showNavigation && (
          <Navigation
            className={AppClassNames.Navigation}
            routes={navBarRoutes}
            logo={logo}
            collapsedLogo={collapsedLogo}
          />
        )}
        <div className={AppClassNames.Inner}>
          {showNavigation && (
            <Header
              user={user}
              updateUser={updateUser}
              showHistoryBack={showHistoryBack}
              logoutApplication={logoutApplication}
            />
          )}
          <div className={`${AppClassNames.View} ${AppClassNames.ViewWithPrivateModifier}`}>
            <Scrollbars theme={ScrollbarsTheme.Dark} showHorizontalTrack>
              <Component {...props} user={user} />
            </Scrollbars>
          </div>
        </div>
        <InactivityPrompt
          id="inactivty_prompt_id"
          broadcastChannelId="q4inc-broadcast-channel"
          timeToIdle={minutesToMilliseconds(240)} // 4 hour time to idle
          promptFooterAction={() => logoutApplication({})}
        />
      </>
    ) : (
      <LoadingScreen q4Icon="ni-q4-logo" />
    );
  };

  return <Route path={path} render={render} {...rest} />;
};
export default withRouter(memo(PrivateRoute));
