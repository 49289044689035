import { gql } from "@apollo/client";

export const GET_EVENT_FEATURES_QUERY = gql`
  query GET_EVENT_FEATURES_QUERY($eventType: EventTypeEnum) {
    getFeaturesByEventType(eventType: $eventType) {
      eventType
      features {
        featureName
        enabled
      }
    }
  }
`;
