import { getClassName } from "@q4/nimbus-ui";
import React, { useMemo } from "react";
import Avatar, { ReactAvatarProps } from "react-avatar";
import {
  CustomAvatarClassNames,
  CustomAvatarProps,
  CustomAvatarSizes,
  getIconInitials,
} from "./customAvatar.definition";

export const CustomAvatar = (props: CustomAvatarProps) => {
  const {
    name = "",
    size = CustomAvatarSizes.Medium,
    colour,
    icon,
    maxInitials = 3,
    classNames,
  } = props;

  const baseClassName = getClassName(CustomAvatarClassNames.Base, [
    {
      condition: !!classNames?.wrapper,
      trueClassName: classNames?.wrapper,
    },
  ]);

  const contentClassName = getClassName(CustomAvatarClassNames.Content, [
    {
      condition: !!classNames?.content,
      trueClassName: classNames?.content,
    },
  ]);

  const avatarProps = useMemo(() => {
    const baseProps = {
      name,
      className: contentClassName,
      size,
      maxInitials,
      color: colour,
      round: true,
      initials: getIconInitials.bind(null, null, maxInitials),
    } as Partial<ReactAvatarProps>;

    if (!!icon) {
      const iconElem = <i className={icon} />;

      baseProps.name = name ?? " ";
      baseProps.initials = getIconInitials.bind(null, iconElem, maxInitials);
    }

    return baseProps;
  }, [colour, icon, name, size, maxInitials, contentClassName]);

  return (
    <div className={baseClassName}>
      <Avatar {...avatarProps} />
    </div>
  );
};
