export enum PlaceholderLayoutClassNames {
  LAYOUT = "placeholderLayout",
  INNER = "innerPlaceholderLayout",
  TEXT_CONTAINER = "textContainer",
  ICON_CONTAINER = "iconContainer",
}

const dataIdPrefix = `PlaceholderLayout`;

export const DATA_IDS = {
  PLACEHOLDER: `${dataIdPrefix}Placeholder`,
};

export interface PlaceholderLayoutProps {
  id?: string;
  message?: string;
  className?: string;
}
