import { useCallback } from "react";
import { fetchAttendeeApi } from "../util";
import useSWRMutation from "swr/mutation";

interface UseDeleteRequestProps {
  path: string;
  getAuthToken?: () => Promise<string>;
}

export const useDeleteRequest = (props: UseDeleteRequestProps) => {
  const { path, getAuthToken } = props;

  const apiFetcher = useCallback(
    async <T>(url: string, { arg }: { arg: T }) => {
      const headers = new Headers();
      if (!url) throw new Error("No url found");
      if (getAuthToken) {
        const token = await getAuthToken();
        if (!token) throw new Error("No token found");
        headers.set("Authorization", `Bearer ${token}`);
      }
      return fetchAttendeeApi(url, {
        method: "DELETE",
        headers,
        body: JSON.stringify(arg),
      });
    },
    [getAuthToken]
  );

  const { data, error, isMutating, trigger } = useSWRMutation(path, apiFetcher);

  return {
    data: data?.data,
    error,
    isLoading: isMutating,
    remove: trigger,
  };
};
