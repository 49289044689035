import { Button, ButtonSize, ButtonTheme } from "@q4/nimbus-ui";
import { isFunction } from "lodash";
import React, { memo, ReactNode, useMemo } from "react";
import { ReleaseTag, ReleaseState } from "../../../../components/tags/ReleaseTag.component";
import "./assetVideoWrapper.component.scss";
interface AssetVideoWrapperProps {
  onStreamRemove?: () => void;
  children: ReactNode;
  release?: ReleaseState;
}

const AssetVideoWrapperClassNames = {
  Base: "asset-video-wrapper",
  ReleaseTag: "asset-video-wrapper_release",
  RemoveStreamButton: "asset-video-wrapper_remove",
};

const AssetVideoWrapper = (props: AssetVideoWrapperProps): JSX.Element => {
  const { onStreamRemove, children, release } = props;

  const isUserCanRemoveStream = useMemo(() => {
    return isFunction(onStreamRemove);
  }, [onStreamRemove]);

  return (
    <div className={AssetVideoWrapperClassNames.Base}>
      {release && <ReleaseTag className={AssetVideoWrapperClassNames.ReleaseTag} tag={release} />}
      {isUserCanRemoveStream && (
        <Button
          className={AssetVideoWrapperClassNames.RemoveStreamButton}
          theme={ButtonTheme.White}
          onClick={onStreamRemove}
          icon="q4i-close-4pt"
          square={true}
          size={ButtonSize.Small}
        />
      )}
      {children}
    </div>
  );
};

export default memo(AssetVideoWrapper);
