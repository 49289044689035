import { useEffect, useState } from "react";
import { isMobileSafari, isChrome, isMobileOnly, isIOS } from "react-device-detect";

const HEURISTIC_VIEWPORT_HEIGHT_CLIENT_HEIGHT_RATIO = 0.85;

export default function useWindowResize() {
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isIOSKeyboardOpen, setIsIOSKeyboardOpen] = useState<boolean | null>(null);

  function setDimensions() {
    if (isMobileSafari || (isIOS && isMobileOnly && isChrome)) {
      // HAX: - aChan: Race condition between grabbing the inner height and safari browsers nav bar rendering,
      //      We delay grabbing the innerHeight to ensure that the navbar has finished rendering and the correct
      //      innerHeight is fetched
      setTimeout(() => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      }, 300);
    } else {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }
  }

  useEffect(
    function detectIOSKeyboard() {
      // No support
      if (!window.visualViewport) return;

      // Not IOS mobile safari
      if (!isMobileSafari || !isIOS) return;

      const id = setInterval(() => {
        const visualViewport = window.visualViewport;
        const isShown =
          (visualViewport.height * visualViewport.scale) / document.documentElement.clientHeight <
          HEURISTIC_VIEWPORT_HEIGHT_CLIENT_HEIGHT_RATIO;

        // Only set the state if is to set it to true on the first state change (no need to set it to false when isIOSKeyboardOpen it's null)
        if (isShown !== isIOSKeyboardOpen && isIOSKeyboardOpen !== null) {
          setIsIOSKeyboardOpen(isShown);
        } else if (isShown && isIOSKeyboardOpen === null) {
          setIsIOSKeyboardOpen(true);
        }
      }, 300);

      return () => {
        clearInterval(id);
      };
    },
    [isIOSKeyboardOpen]
  );

  useEffect(
    function handleIOSKeyboardCloses() {
      // IOS keyboad just closed
      if (isIOSKeyboardOpen === false) {
        // Inner height can cause bugs on the UI if the keyboard is open many times on sequences of clicks or touches (Only on IOS mobile)
        // Also, outerHeight is not affected by keyboard height
        // Keep innerHeight for anything different from mobile (Ipads, etc) on IOS. Prevent cut off on the lobby footer
        setHeight(isMobileOnly ? window.outerHeight : window.innerHeight);
        setWidth(isMobileOnly ? window.outerWidth : window.innerWidth);
      }
    },
    [isIOSKeyboardOpen]
  );

  useEffect(() => {
    window.addEventListener("resize", setDimensions, true);
    return () => {
      window.removeEventListener("resize", setDimensions, true);
    };
  }, []);

  return { innerHeight: height, innerWidth: width };
}
