import { RouteComponentProps } from "react-router";
import { isEmpty } from "lodash";
import { ErrorModel } from "../../../components/modal/modal.definition";

export enum LoginResetClassNames {
  Base = "loginReset",
  Background = "loginReset_backgroundImage",
  Button = "loginReset_button",
  Form = "loginReset_form",
  Icon = "loginReset_icon",
  Inner = "loginReset_inner",
  Password = "loginReset_password",
  PasswordDescription = "loginReset_passwordDescription",
  ResetIdInvalid = "loginReset_invalidResetId",
  ShowPassword = "loginReset_showPassword",
  Submit = "loginReset_submit",
}

interface MatchParams {
  resetId: string;
}

export interface ResetLoginProps extends RouteComponentProps<MatchParams> {
  children?: any;
}

export enum FormErrors {
  CURRENT_PASS_EMPTY = "Please specify your current password",
  PASS_NOT_VALID = "Password must be a minimum of 10 characters, including at least 1 uppercase letter, 1 number, and 1 special character",
  PASS_MISMATCH = "Passwords do not match",
  PASS_EMPTY = "Please specify a new password",
  RESET_ID_INVALID = "Password reset token is invalid or has expired. Go back to the homepage to request a new link.",
}
export class ResetLoginFormModel {
  public currentPassword?: string;
  public password: string;
  public passwordConfirmation: string;

  constructor(registrationData) {
    if (isEmpty(registrationData)) return;

    this.currentPassword = registrationData?.currentPassword;
    this.password = registrationData.password;
    this.passwordConfirmation = registrationData.passwordConfirmation;
  }
}

export class ErrorFormState {
  currentPasswordError?: ErrorModel;
  passwordError?: ErrorModel;
  confirmPasswordError?: ErrorModel;

  constructor(state: ErrorFormState) {
    this.currentPasswordError = state.currentPasswordError;
    this.passwordError = state.passwordError;
    this.confirmPasswordError = state.confirmPasswordError;
  }
}
