import { some } from "lodash";
import moment, { Moment } from "moment";
import {
  AttendeeEventRoles,
  UserEventRole,
} from "../configurations/userRolesPermissions.configuration";
import {
  AdminConsolePathPrefix,
  PreCallPathPrefix,
  ReportPathPrefix,
  BroadcastPreviewPathPrefix,
} from "../configurations/routes/routes.configuration";
import {
  Guest,
  GuestType,
  Host,
  Inspector,
  Observer,
  Speaker,
  Support,
} from "../services/event/event.model";
import { User } from "../services/user/user.model";
import { PublicEventDetails } from "../services/event/eventGql.model";
import { EventModelPopulated } from "../hooks/events/events.hook.definitions";
import {
  EventMicrositeConfigurationPublishSchedule,
  PublishScheduleStatusEnum,
} from "../views/eventMicrosite/hooks/microsite.hook.definition";
import { isAttendeeView } from "./attendee.utils";

export const WELCOME_ROOM_CUTOFF_TIME = 15;

export const POST_EVENT_ROOM_AUTOMATIC_DISPLAY_TIME = 2;

export enum RegistrationType {
  PRE_REGISTRATION = "PRE_REGISTRATION",
  POST_REGISTRATION = "POST_REGISTRATION",
  LOBBY_REGISTRATION = "LIVE_REGISTRATION",
}

const roleLabels = {
  [UserEventRole.EP_EVENT_HOST]: "Host",
  [UserEventRole.EP_EVENT_SPEAKER]: "Speakers",
  [UserEventRole.EP_EVENT_SUPPORT]: "Company Support",
  [UserEventRole.EP_EVENT_ADMINISTRATOR]: "Event Managers",
  [UserEventRole.EP_EVENT_OBSERVER]: "Board Observer",
  [UserEventRole.EP_EVENT_INSPECTOR]: "Inspector",
};

export function getEventRegistrationToNowDuration(
  msToStart: number,
  minsCutOff: number
): moment.Duration | null {
  if (msToStart && minsCutOff) {
    return moment.duration(msToStart - moment.duration(minsCutOff, "minutes").asMilliseconds());
  }
  return null;
}

export function getEventEndToNowDuration(eventEnd: Moment): moment.Duration | null {
  return eventEnd ? moment.duration(moment().diff(moment(eventEnd))) : null;
}

export interface EventUsers {
  guests: Guest[];
  speakers: Speaker[];
  supports: Support[];
  hosts: Host[];
  observers: Observer[];
  inspectors: Inspector[];
}

export function getUserRoleInEvent(eventUsers?: EventUsers, user?: User): UserEventRole | null {
  if (!eventUsers || !user) {
    return null;
  }
  const { guests, supports, speakers, hosts, observers, inspectors } = eventUsers;

  if (guests) {
    const guestRole = getGuestRoleInEvent(eventUsers, user);
    if (guestRole) return guestRole;
  }

  switch (true) {
    case some(speakers, { id: user.id }):
      return UserEventRole.EP_EVENT_SPEAKER;
    case some(supports, { id: user.id }):
      return UserEventRole.EP_EVENT_SUPPORT;
    case some(hosts, { id: user.id }):
      return UserEventRole.EP_EVENT_HOST;
    case some(observers, { id: user.id }):
      return UserEventRole.EP_EVENT_OBSERVER;
    case some(inspectors, { id: user.id }):
      return UserEventRole.EP_EVENT_INSPECTOR;
    default:
      return UserEventRole.EP_EVENT_ADMINISTRATOR;
  }
}

export function getGuestRoleInEvent(
  eventUsers?: EventUsers,
  user?: Pick<User, "email">
): UserEventRole | null {
  if (!eventUsers || !user) {
    return null;
  }

  const guestUser = eventUsers.guests.find(
    (guest) => String(guest?.email)?.toUpperCase() === String(user?.email)?.toUpperCase()
  );

  if (!guestUser) {
    return null;
  }

  switch (guestUser.guestType) {
    case GuestType.SPEAKER:
      return UserEventRole.EP_EVENT_SPEAKER;
    case GuestType.SUPPORT:
      return UserEventRole.EP_EVENT_SUPPORT;
    case GuestType.OBSERVER:
      return UserEventRole.EP_EVENT_OBSERVER;
    case GuestType.INSPECTOR:
      return UserEventRole.EP_EVENT_INSPECTOR;
    default:
      return null;
  }
}

export function isObserverRole(userEventRole: UserEventRole | AttendeeEventRoles) {
  return userEventRole === UserEventRole.EP_EVENT_OBSERVER;
}

export function isInspectorRole(userEventRole: UserEventRole | AttendeeEventRoles) {
  return userEventRole === UserEventRole.EP_EVENT_INSPECTOR;
}

export function isSpeakerRole(userEventRole: UserEventRole | AttendeeEventRoles) {
  return userEventRole === UserEventRole.EP_EVENT_SPEAKER;
}

export function getUserRoleInEventLabel(role?: UserEventRole | null): string {
  return roleLabels[role] || "";
}

export function isAdminConsoleView() {
  return window.location.pathname.startsWith(AdminConsolePathPrefix);
}

export function isPreCallView(): boolean {
  return window.location.pathname.startsWith(PreCallPathPrefix);
}

export function isReportView(): boolean {
  return window.location.pathname.startsWith(ReportPathPrefix);
}

export function isBroadcastPreview(): boolean {
  return window.location.pathname.includes(BroadcastPreviewPathPrefix);
}

export function isTenantedView(): boolean {
  return (
    !isBroadcastPreview() &&
    (isAttendeeView() || isAdminConsoleView() || isPreCallView() || isReportView())
  );
}

export const downloadReportCSV = (csvContent, fileName) => {
  const csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  const tempLink = document.createElement("a");
  tempLink.setAttribute("href", csv);
  tempLink.setAttribute("download", fileName);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const isPreRegistrationTimeActive = (eventDetails: PublicEventDetails) => {
  const msToStart = eventDetails?.msToStart;
  const minsCutOff =
    parseInt(eventDetails?.branding?.registrationPageBranding?.openRegistration) ||
    WELCOME_ROOM_CUTOFF_TIME;

  const msToRegistration = getEventRegistrationToNowDuration(
    msToStart,
    minsCutOff
  )?.asMilliseconds();
  return msToRegistration > 0;
};

export const isAutoPublishPostEventReady = (publishSchedule, hoursAfterEventEnd) =>
  publishSchedule === EventMicrositeConfigurationPublishSchedule.AUTOMATIC &&
  hoursAfterEventEnd > POST_EVENT_ROOM_AUTOMATIC_DISPLAY_TIME;

export const isManualPublishPostEventReady = (
  publishSchedule,
  publishScheduleStatus,
  hoursAfterEventEnd
) =>
  hoursAfterEventEnd > 0 &&
  publishSchedule === EventMicrositeConfigurationPublishSchedule.MANUAL &&
  publishScheduleStatus === PublishScheduleStatusEnum.PUBLISHED;

export const isPostEventPublishReady = (
  currentEventDetails: PublicEventDetails | EventModelPopulated,
  hoursAfterEnd: number
) => {
  const publishSchedule = currentEventDetails?.branding?.controlPanelBranding?.publishSchedule;
  const publishScheduleStatus =
    currentEventDetails?.branding?.controlPanelBranding?.publishScheduleStatus;
  const postEventArchiveEnabled = !!currentEventDetails?.configuration?.postEvent?.enableArchive;

  return (
    postEventArchiveEnabled &&
    (isAutoPublishPostEventReady(publishSchedule, hoursAfterEnd) ||
      isManualPublishPostEventReady(publishSchedule, publishScheduleStatus, hoursAfterEnd))
  );
};

export const getRTMPUrl = (ingestEndpoint = "") => {
  return `rtmps://${ingestEndpoint}:443/app/`;
};
