export enum SnowplowSchema {
  EventRegistrationSuccessSchema = "iglu:com.q4inc/event-registration-success/jsonschema/1-0-5",
  EventBroadcastInteractionSchema = "iglu:com.q4inc/event-broadcast-interaction/jsonschema/1-0-0",
  EventBroadcastAttendeeInteractionSchema = "iglu:com.q4inc/event-broadcast-attendee-interaction/jsonschema/1-0-3",
  EventContextSchema = "iglu:com.q4inc/event-entity/jsonschema/1-0-6",
  UserContextSchema = "iglu:com.q4inc/user-entity/jsonschema/1-0-5",
  ProductContextSchema = "iglu:com.q4inc/product-entity/jsonschema/1-0-7",
  ISPContextSchema = "iglu:com.q4inc/ipapi_geolocation/jsonschema/1-0-0",
}

export const SnowplowConfig = {
  ProductContext: {
    productId: "EP",
    productVersion: process.env.REACT_APP_VERSION,
    productEnv: process.env.REACT_APP_STAGE,
  },
};

export enum BroadcastInteractionType {
  START = "start",
  PAUSED = "pause",
  FINALIZED = "stop",
}
// NB: Hardcoding this temporarilly for snowplow tracking
export const Q4_COMPANY_ID = "5fbbe3dad9470c000898471e";
export const Q4_ORGANIZATION_ID = "dc0baa76-db2b-4e28-8a9b-43b76a41803d";
