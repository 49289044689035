const config = {
  auth0: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    rolesDomain: process.env.REACT_APP_AUTH0_ROLES_DOMAIN,
    universalLoginClientId: process.env.REACT_APP_AUTH0_UNIVERSAL_LOGIN_CLIENT_ID,
    // universalLoginClientId: "w5wPVlxFZkk0oCRY9EN5DXSUcSRT1voV", //!!! DEBUG Q4L LOCALHOST ONLY - Auth0 App ID
    universalLoginDomain: process.env.REACT_APP_AUTH0_UNIVERSAL_LOGIN_DOMAIN,
    userDatabase: "Username-Password-Authentication",
  },
  app: {
    attendeeHelpCenterUrl: `${process.env.REACT_APP_ATTENDEE_HELP_CENTER_URL}`,
    helpCenterCookieDomain: process.env.REACT_APP_HELP_CENTER_COOKIE_DOMAIN,
    helpCenterUrl: process.env.REACT_APP_HELP_CENTER_URL,
    loginUrl: `${process.env.PUBLIC_URL}/login`,
    openTokApiToken: process.env.REACT_APP_OPENTOK_API_KEY,
    publicUrl: process.env.PUBLIC_URL,
    speakerInternationalDialInNumber:
      process.env.REACT_APP_TWILIO_SPEAKER_INTERNATIONAL_DIAL_IN_NUMBER,
    speakerUKDialInNumber: process.env.REACT_APP_TWILIO_SPEAKER_UK_DIAL_IN_NUMBER,
    stage: process.env.REACT_APP_STAGE ?? "dev",
    supportEmail: "support@q4inc.com",
    url: process.env.REACT_APP_URL,
    whitelistRequirementUrl: `${process.env.REACT_APP_WHITELIST_REQUIREMENTS_URL}`,
    capitalConnectDomain: process.env.REACT_APP_CAPITAL_CONNECT_DOMAIN,
    investorPortalDomain: `${process.env.REACT_APP_CAPITAL_CONNECT_DOMAIN}/investors`,
    name: "events-platform",
  },
  adminConsole: {
    streamThumbnails: {
      camera: `${process.env.REACT_APP_URL}/${
        process.env.REACT_APP_THUMBNAIL
          ? process.env.REACT_APP_THUMBNAIL
          : "streamThumbnails/camera-stream-thumbnail.png"
      }`,
      audio: `${process.env.REACT_APP_URL}/${
        process.env.REACT_APP_THUMBNAIL_AUDIO
          ? process.env.REACT_APP_THUMBNAIL_AUDIO
          : "streamThumbnails/audio-stream-thumbnail.png"
      }`,
      pstn: `${process.env.REACT_APP_URL}/${
        process.env.REACT_APP_THUMBNAIL_PSTN
          ? process.env.REACT_APP_THUMBNAIL_PSTN
          : "streamThumbnails/pstn-stream-thumbnail.png"
      }`,
    },
  },
  api: {
    url: process.env.REACT_APP_API_URL,
    graphqlBaseUrl: process.env.REACT_APP_GRAPHQL_API_BASE,
    attendeeRest: process.env.REACT_APP_ATTENDEE_REST_API,
    attendeeGraphql: process.env.REACT_APP_ATTENDEE_GRAPHQL_API,
  },
  socket: {
    url: process.env.REACT_APP_GRAPHQL_API_SOCKET,
    attendeeUrl: process.env.REACT_APP_GRAPHQL_ATTENDEE_API_SOCKET,
  },
  dvr: {
    url: process.env.REACT_APP_DVR_DOMAIN,
  },
  s3StaticFiles: "https://q4-touch.s3.amazonaws.com",
  branding: {
    defaultBackgroundImgUrl: `${process.env.PUBLIC_URL}/images/demoBackground.jpg`,
    defaultLogoUrl: `${process.env.PUBLIC_URL}/images/demoLogo.png`,
    defaultFontUploaderUrl: `${process.env.PUBLIC_URL}/images/uploader.png`,
    s3: {
      domain: process.env.REACT_APP_ASSETS_DOMAIN,
      fontPath: process.env.REACT_APP_ASSETS_RELATIVE_FONT_PATH,
      backgroundPath: process.env.REACT_APP_ASSETS_RELATIVE_BACKGROUND_PATH,
      logosPath: process.env.REACT_APP_ASSETS_RELATIVE_LOGOS_PATH,
      materialsPath:
        process.env.EVENTS_ASSETS_RELATIVE_MATERIALS_PATH || "eventAssets/<eventId>/materials",
      faviconsPath:
        process.env.REACT_APP_ASSETS_RELATIVE_FAVICONS_PATH || "companyAssets/<companyId>/favicons",
    },
  },
  events: {
    assets: {
      s3Path: process.env.EVENTS_ASSETS_UPLOADS_PATH || "",
    },
  },
  broadridge: {
    usSupportLine: process.env.REACT_APP_BR_US_SUPPORT_LINE,
    intlSupportLine: process.env.REACT_APP_BR_INTL_SUPPORT_LINE,
  },
  analytics: {
    snowplow: {
      collecterUrlProd: process.env.REACT_APP_SNOWPLOW_COLLECTOR_URL_PROD || "",
      collecterUrlMini: process.env.REACT_APP_SNOWPLOW_COLLECTOR_URL_MINI || "",
      trackerId: process.env.REACT_APP_SNOWPLOW_TRACKER_ID,
      trackerISPKey: process.env.REACT_APP_SNOWPLOW_TRACKER_ISP_KEY,
    },
    msClarity: {
      apiKey: process.env.REACT_APP_MS_CLARITY_API_KEY || "jdk9iutm19",
    },
  },
  google: {
    reCaptchaV2SiteKey: process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY,
  },
  localDevelopment: {
    disableBrowserSupportCheck: process.env.REACT_APP_DISABLE_BROWSER_SUPPORT_CHECK,
  },
  eventReport: {
    guideUrl: `${process.env.REACT_APP_HELP_CENTER_URL}/en/events-platform-management/event-management/post-event-report-and-recordings.html`,
  },
  datadog: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  },
  q4l: {
    helpUrl: "https://help.events.q4inc.com/index.html?lang=en",
  },
};

export default config;
