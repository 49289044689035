import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
} from "@apollo/client";

export enum ParticipantSignalEnum {
  REQUEST_MUTE = "REQUEST_MUTE",
  REQUEST_UNMUTE = "REQUEST_UNMUTE",
}

export enum AllParticipantsSignalEnum {
  MUTE = "MUTE",
  END_EVENT = "END_EVENT",
}
export interface SignalParticipantMutationData {
  signalParticipant: ParticipantSignalEnum;
}
export interface SignalParticipantMutationVars {
  meetingId: number;
  participantId: string;
  signalType: ParticipantSignalEnum;
}

export const PARTICIPANT_SIGNAL_MUTATION = gql`
  mutation signalParticipant(
    $meetingId: Int
    $participantId: String
    $signalType: ParticipantSignalEnum
  ) {
    signalParticipant(meetingId: $meetingId, participantId: $participantId, signalType: $signalType)
  }
`;

export function useParticipantSignalMutation(
  options?: MutationHookOptions<SignalParticipantMutationData, SignalParticipantMutationVars>
) {
  return useMutation<SignalParticipantMutationData, SignalParticipantMutationVars>(
    PARTICIPANT_SIGNAL_MUTATION,
    options
  );
}

export interface SignalAllParticipantsMutationData {
  signalAllParticipants: AllParticipantsSignalEnum;
}

export interface SignalAllParticipantsMutationVars {
  meetingId: number;
  signalType: AllParticipantsSignalEnum;
}

export const ALL_PARTICIPANTS_SIGNAL_MUTATION = gql`
  mutation signalAllParticipants($meetingId: Int, $signalType: AllParticipantsSignalEnum) {
    signalAllParticipants(meetingId: $meetingId, signalType: $signalType)
  }
`;
export function useAllParticipantsSignalMutation(
  options?: MutationHookOptions<
    SignalAllParticipantsMutationData,
    SignalAllParticipantsMutationVars
  >
) {
  return useMutation<SignalAllParticipantsMutationData, SignalAllParticipantsMutationVars>(
    ALL_PARTICIPANTS_SIGNAL_MUTATION,
    options
  );
}

export interface ParticipantSignalSubData {
  onParticipantSignal: ParticipantSignalEnum;
}
export interface ParticipantSignalSubVars {
  meetingId: number;
  participantId: string;
}

export const PARTICIPANT_SIGNAL_SUBSCRIPTION = gql`
  subscription onParticipantSignal($meetingId: Int, $participantId: String) {
    onParticipantSignal(meetingId: $meetingId, participantId: $participantId)
  }
`;

export function useParticipantSignalSubscription(
  options?: SubscriptionHookOptions<ParticipantSignalSubData, ParticipantSignalSubVars>
) {
  return useSubscription<ParticipantSignalSubData, ParticipantSignalSubVars>(
    PARTICIPANT_SIGNAL_SUBSCRIPTION,
    options
  );
}

export interface AllParticipantsSignalSubData {
  onAllParticipantsSignal: AllParticipantsSignalEnum;
}
export interface AllParticipantsSignalSubVars {
  meetingId: number;
}

export const ALL_PARTICIPANTS_SIGNAL_SUBSCRIPTION = gql`
  subscription onAllParticipantsSignal($meetingId: Int) {
    onAllParticipantsSignal(meetingId: $meetingId)
  }
`;

export function useAllParticipantsSignalSubscription(
  options?: SubscriptionHookOptions<AllParticipantsSignalSubData, AllParticipantsSignalSubVars>
) {
  return useSubscription<AllParticipantsSignalSubData, AllParticipantsSignalSubVars>(
    ALL_PARTICIPANTS_SIGNAL_SUBSCRIPTION,
    options
  );
}
