import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextIdModel,
} from "@q4/nimbus-ui";
import {
  RegistrationField,
  RegistrationFieldType,
} from "../../../../services/event/registrationFieldGql.model";
import { CustomRegistrationCardIdModel } from "../customRegistrationCard/customRegistrationCard.definition";

export enum CustomRegistrationFieldTypeLabels {
  text = "Text Answer",
}

export const CustomRegistrationFieldFormPromptSubheading = {
  [RegistrationFieldType.EmailAddress]: "Input text with email format validation",
  [RegistrationFieldType.PhoneNumber]: "Input text with phone number format validation",
};

export enum CustomRegistrationFieldTypeClassNames {
  Base = "custom-registration-field-type-selector",
  Content = "custom-registration-field-type-selector_content",
  Header = "custom-registration-field-type-selector_header",
  SubHeader = "custom-registration-field-type-selector_subheader",
  CardContainer = "custom-registration-field-type-selector_card-container",
  Footer = "custom-registration-field-type-selector_footer",
  Cancel = "custom-registration-field-type-selector_footer_cancel",
}

export interface CustomRegistrationFieldTypeProps extends BaseComponentProps {
  totalFieldCount: number;
  currentFields: RegistrationField[];
  onCancel: (registrationField?: RegistrationField) => void;
  onSelect: (registrationField: RegistrationField) => void;
}

export class CustomRegistrationFieldTypeIdModel extends IdModelBase {
  header: TextIdModel;
  subheader: TextIdModel;
  footer: string;
  fieldCount: TextIdModel;
  cancelButton: ButtonIdModel;
  textCard: CustomRegistrationCardIdModel;
  dropdownCard: CustomRegistrationCardIdModel;
  multichoiceCard: CustomRegistrationCardIdModel;
  emailCard: CustomRegistrationCardIdModel;
  phoneNumberCard: CustomRegistrationCardIdModel;
  textBlockCard: CustomRegistrationCardIdModel;
  consentCard: CustomRegistrationCardIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.header = new TextIdModel(`${this.id}Header`);
    this.subheader = new TextIdModel(`${this.id}SubHeader`);
    this.footer = `${this.id}FieldCount`;
    this.fieldCount = new TextIdModel(`${this.id}FieldCount`);
    this.cancelButton = new ButtonIdModel(`${this.id}CancelButton`);
    this.textCard = new CustomRegistrationCardIdModel(`${this.id}TextTypeCard`);
    this.textBlockCard = new CustomRegistrationCardIdModel(`${this.id}TextBlockTypeCard`);
    this.dropdownCard = new CustomRegistrationCardIdModel(`${this.id}DropdownTypeCard`);
    this.multichoiceCard = new CustomRegistrationCardIdModel(`${this.id}MultichoiceTypeCard`);
    this.emailCard = new CustomRegistrationCardIdModel(`${this.id}EmailTypeCard`);
    this.phoneNumberCard = new CustomRegistrationCardIdModel(`${this.id}PhoneNumberTypeCard`);
    this.consentCard = new CustomRegistrationCardIdModel(`${this.id}ConsentCard`);
  }
}
