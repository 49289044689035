import { gql } from "@apollo/client";

export const typeDefs = gql`
  enum BroadcastStatusEnum {
    STARTED
    STARTING
    START_FAILED
    ENDED
    ENDING
    END_FAILED
  }

  enum QuestionGroupEnum {
    RECEIVED
    REJECTED
    QUEUED
    ANSWERED
  }

  enum ParticipantSignalEnum {
    REQUEST_MUTE
    REQUEST_UNMUTE
  }

  enum AllParticipantsSignalEnum {
    MUTE
    END_EVENT
  }

  enum EventStatusEnum {
    NOT_STARTED
    STARTED
    ENDED
  }

  enum SortOption {
    ASC
    DESC
  }

  enum ActivityType {
    EVENT_STARTED
    EVENT_ENDED
    BROADCAST_STARTED
    BROADCAST_ENDED
  }

  enum EventTypeEnum {
    vsm
    earnings
    recording
  }

  enum CompanyBrandingResourceType {
    fonts
    logos
    background_imgs
  }
`;
