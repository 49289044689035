import { useState, useEffect, useCallback, useMemo } from "react";
import config from "../../config";
import { FontName } from "../../views/eventMicrosite/components/micrositeControlPanelModal/controlPanelForm/controlPanelForm.definition";
import FontFaceObserver from "fontfaceobserver";
import { FontType } from "../branding/branding.hook.definition";

export const ImageLibrary = [
  "austin.jpg",
  "boston.jpg",
  "chicago.jpg",
  "denver.jpg",
  "hongkong.jpg",
  "houston.jpg",
  "newyork.jpg",
  "sandiego.jpg",
  "sanfrancisco2.jpg",
  "seattle.jpg",
  "singapore.jpg",
  "timesquare.jpg",
  "tokyo.jpg",
  "toronto.jpg",
];

export default function useBackgroundImages() {
  const [backgroundImage, setBackgroundImage] = useState<string>();

  useEffect(() => {
    fetchImage(getBackgroundImage());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchImage(src: string) {
    const image = new Image();

    image.src = src;
    image.onload = () => {
      setBackgroundImage(src);
    };
  }

  function getBackgroundImage() {
    return `${config.s3StaticFiles}/q4splash/${
      ImageLibrary[Math.floor(Math.random() * ImageLibrary.length)]
    }`;
  }

  return { backgroundImage };
}

interface UseBackgroundImageWithTitle {
  imageUrl: string;
  title: string;
  fontData?: FontType;
  size?: number;
  loading?: boolean;
  fontColor?: string;
}

function wrapText(
  context: CanvasRenderingContext2D,
  text = "",
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number
) {
  const cars = text.split("\n");

  const lines = [];

  for (let ii = 0; ii < cars.length; ii++) {
    let line = "";
    const words = cars[ii].split(" ");

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + " ";
      const testWidth = context.measureText(testLine).width;

      if (testWidth > maxWidth) {
        lines.push(line);
        line = words[n] + " ";
      } else {
        line = testLine;
      }
    }
    lines.push(line);
  }

  let vertical = y - (lines.length / 2 - 1) * lineHeight;
  lines.forEach((line) => {
    context.fillText(line, x, vertical);
    vertical += lineHeight;
  });
}

const lineHeight = 70;

const loadFont = (font: string) => {
  return new Promise((resolve) => {
    const fontToLoad = new FontFaceObserver(font);
    fontToLoad.load(null, 2000).then(resolve, resolve);
  });
};

export function useBackgroundImageWithTitle(props: UseBackgroundImageWithTitle) {
  const { imageUrl, title, fontData, size = 50, loading, fontColor } = props;

  const isReadyToLoad = useMemo<boolean>(
    () => !!(imageUrl && title && fontColor),
    [imageUrl, title, fontColor]
  );

  const [backgroundImage, setBackgroundImage] = useState<string>();

  const isImageSrcChanged = (src: string, imageSrc: string): boolean => {
    try {
      if (!imageSrc || new URL(src, window.location.href)?.pathname !== new URL(imageSrc)?.pathname)
        return true;
    } catch {
      return true;
    }
    return false;
  };

  const onImageLoad = useCallback(
    async (event) => {
      const image = event?.target || event?.path[0];
      if (!image) return;
      if (isImageSrcChanged(imageUrl, image.src)) return;

      const font = fontData?.displayName || FontName.inter;
      if (font !== FontName.inter) {
        await loadFont(font);
      }
      const canvas = document.createElement("CANVAS") as HTMLCanvasElement;
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d", { alpha: false });

      context.drawImage(image, 0, 0, image.width, image.height);

      context.textAlign = "center";

      context.font = `${size}px ${font}`;
      context.fillStyle = fontColor?.startsWith("#") ? fontColor : `#${fontColor}`;

      wrapText(context, title, image.width / 2, image.height / 2, canvas.width, lineHeight);

      canvas.toBlob((blob) => {
        if (isImageSrcChanged(imageUrl, image.src)) return;
        setBackgroundImage(URL.createObjectURL(blob));
      });
    },
    [title, fontData, size, fontColor, imageUrl]
  );

  useEffect(
    function insertFont() {
      const { fontUrl: url, displayName: name } = fontData || {};
      if (!url || !name) return;

      const newStyle = document.createElement("style");
      newStyle.appendChild(
        document.createTextNode(
          `@font-face {
            font-family: ${name};
            src: url(${url});
            `
        )
      );
      document.head.appendChild(newStyle);
      return () => {
        document.head.appendChild(newStyle);
      };
    },
    [fontData]
  );

  useEffect(
    function generateBackgroundImageWithTitle() {
      if (loading || !isReadyToLoad) return;

      const imageCacheInvalidation = imageUrl?.includes(config.branding.defaultBackgroundImgUrl)
        ? ""
        : "?v=" + window.performance.timeOrigin;

      const image = new Image(1280, 720);
      try {
        image.crossOrigin = "Anonymous";
        image.onload = onImageLoad;
        image.onerror = console.error;
        image.src = imageUrl + imageCacheInvalidation;
      } catch (error) {
        console.error(error);
      }
      return () => {
        image.src = "";
        image.onload = null;
        image.onerror = null;
        backgroundImage && URL.revokeObjectURL(backgroundImage);
      };
    },
    [loading, isReadyToLoad, onImageLoad] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return { backgroundImageWithTitle: backgroundImage || imageUrl };
}
