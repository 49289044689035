import React, { memo, ReactNode, useCallback, useContext, useMemo, useRef } from "react";
import { getClassName } from "@q4/nimbus-ui";
import EventAssetsContext from "../../../contexts/eventAssets/eventAssets.context";
import { BroadcastStream } from "../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { AssetControlsFooter } from "../../assetControls/components/assetControlsFooter.component";
import { AssetControlsFooterProps } from "../../assetControls/components/assetControlsFooter.definition";
import StagedSlides from "./stagedSlides.component";
import "./stagedAssetView.component.scss";
import { EventContext } from "../../../contexts";
import { AssetBroadcastStream } from "../../adminConsole/vendors/openTok/components/AssetBroadcastStream";
import {
  ControlSlidesService,
  ControlSlidesState,
} from "../../../hooks/controlSlides/useControlSlides.hook.definition";
import { FeatureFlagKeys } from "../../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../../contexts/featureFlags";

interface StagedAssetViewProps {
  children: ReactNode;
  stream: BroadcastStream;
  onStreamRemove: (stream: BroadcastStream) => void;
  controlOptions: {
    includeThumbnails: boolean;
    includeFooter: boolean;
    includeRemoveStream: boolean;
  };
}

export enum StagedAssetViewClassNames {
  Base = "staged_asset_view",
  VideoWrapper = "staged-asset-view_video-wrapper",
  VideoWrapperFullscreen = "staged-asset-view_video-wrapper--fullscreen",
}

const StagedAssetView = (props: StagedAssetViewProps) => {
  const { children, stream, onStreamRemove, controlOptions } = props;

  const { currentEvent } = useContext(EventContext);
  const {
    onStop,
    takeControlService,
    participantInControl,
    controlSlidesState,
    controlSlidesService,
  } = useContext(EventAssetsContext);

  const { featureFlags } = useContext(FeatureFlagsContext);

  const baseRef = useRef(null);
  const isFullscreen = !!document.fullscreenElement;

  const onStreamRemoveCallback = useCallback(() => {
    if (stream?.isAudioAsset || stream?.isVideoAsset) {
      onStop?.(stream);
    }
    onStreamRemove(stream);
  }, [stream, onStreamRemove, onStop]);

  const onStreamRemoveFunction = useMemo(() => {
    if (isFullscreen || !controlOptions.includeRemoveStream) return null;
    return onStreamRemoveCallback;
  }, [controlOptions, isFullscreen, onStreamRemoveCallback]);

  const assetBroadcastStream = useMemo(() => stream as AssetBroadcastStream, [stream]);

  const fullScreenHandler = useCallback(() => {
    if (isFullscreen) {
      document?.exitFullscreen();
    } else {
      baseRef.current?.requestFullscreen();
    }
  }, [isFullscreen]);

  const useV2SlideControls = useMemo(
    () =>
      currentEvent?.configuration?.layoutManager?.enabled &&
      featureFlags[FeatureFlagKeys.RemoteBroadcastLayout] &&
      stream.isV2AssetStream,
    [currentEvent?.configuration?.layoutManager?.enabled, featureFlags, stream]
  );

  const controlSlidesStateV2: ControlSlidesState = useMemo(
    () => ({
      currentSlide: useV2SlideControls
        ? assetBroadcastStream.stats?.currentSlide
        : controlSlidesState.currentSlide,
      totalSlides: useV2SlideControls
        ? assetBroadcastStream.stats?.totalSlides
        : controlSlidesState.totalSlides,
    }),
    [
      useV2SlideControls,
      assetBroadcastStream.stats?.currentSlide,
      assetBroadcastStream.stats?.totalSlides,
      controlSlidesState.currentSlide,
      controlSlidesState.totalSlides,
    ]
  );

  const jumpToSlide = controlSlidesService.jumpToSlide;

  const _jumpToSlide = useCallback(
    (targetSlide: number, escapeControlCheck?: boolean) => {
      jumpToSlide(targetSlide, escapeControlCheck);
    },
    [jumpToSlide]
  );

  const controlSlidesServiceV2: ControlSlidesService = {
    ...controlSlidesService,
    jumpToSlide: _jumpToSlide,
  };

  const footerProps: AssetControlsFooterProps = {
    takeControlHook: {
      takeControlService,
      participantInControl,
    },
    fullScreenControls: {
      isFullscreen,
      toggleFullscreen: fullScreenHandler,
    },
    controlSlidesState: controlSlidesStateV2,
    controlSlidesService: controlSlidesServiceV2,
  };

  const videoWrapperClassName = useMemo(() => {
    if (controlOptions.includeFooter) {
      return getClassName(StagedAssetViewClassNames.VideoWrapper, [
        {
          condition: isFullscreen,
          trueClassName: StagedAssetViewClassNames.VideoWrapperFullscreen,
        },
      ]);
    }
  }, [controlOptions.includeFooter, isFullscreen]);

  const slidesUrl = useMemo(
    () => (stream.isV2AssetStream ? stream?.sourceUrl : stream?.assetData?.url),
    [stream]
  );

  return (
    <div ref={baseRef} className={StagedAssetViewClassNames.Base}>
      <StagedSlides
        onStreamRemove={onStreamRemoveFunction}
        url={slidesUrl}
        displayName={stream?.displayName}
        controlSlidesState={controlSlidesStateV2}
        controlSlidesService={controlSlidesServiceV2}
        includeThumbnails={
          controlOptions.includeThumbnails && participantInControl.isLocallyControlled
        }
        release={
          stream.isV2AssetStream &&
          currentEvent.configuration?.layoutManager?.enabled &&
          featureFlags[FeatureFlagKeys.LayoutManagerUi].tagState
        }
      >
        <div className={videoWrapperClassName}>{children}</div>
        {controlOptions.includeFooter && <AssetControlsFooter {...footerProps} />}
      </StagedSlides>
    </div>
  );
};

export default memo(StagedAssetView);
