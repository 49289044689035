import { BroadcastStream } from "../broadcastStream/broadcastStream";
import { OTStreamType } from "../../../../opentok-react/types";
import { OpenTokStream } from "../../vendors/openTok/components/OpenTokStream";

type vendorStream = OTStreamType | any;

export enum StreamFilterKeys {
  Cameras = "Cameras",
  Speakers = "Speakers",
  Media = "Media",
  Screens = "Screens",
  Phones = "Phones",
  Assets = "Assets",
  VideoAssets = "VideoAssets",
  AudioAssets = "AudioAssets",
  SlidesAssets = "SlidesAssets",
  AvAssets = "AvAssets",
}

export type StreamFilterFunctions = (stream: BroadcastStream) => boolean;
export type StreamFilterMapping = Record<StreamFilterKeys, StreamFilterFunctions>;

export const StreamFilters: StreamFilterMapping = {
  [StreamFilterKeys.Cameras]: (stream) => stream.isCameraStream,
  [StreamFilterKeys.Speakers]: (stream) => stream.isCameraStream || stream.isPSTNStream,
  [StreamFilterKeys.Media]: (stream) => stream.isScreenShare || stream.isAssetStream,
  [StreamFilterKeys.Screens]: (stream) => stream.isScreenShare,
  [StreamFilterKeys.Phones]: (stream) => stream.isPSTNStream,
  [StreamFilterKeys.Assets]: (stream) => stream.isAssetStream,
  [StreamFilterKeys.VideoAssets]: (stream) => stream.isVideoAsset,
  [StreamFilterKeys.AudioAssets]: (stream) => stream.isAudioAsset,
  [StreamFilterKeys.SlidesAssets]: (stream) => stream.isSlideAsset,
  [StreamFilterKeys.AvAssets]: (stream) => stream.isVideoAsset || stream.isAudioAsset,
};

export interface TrackBroadcastStreamsProps {}

export interface TrackBroadcastStreamsModel {
  streams: Array<BroadcastStream>;
  trackStreamsService: TrackStreamsModel;
}
export interface TrackStreamsModel {
  convertToVendorStreamType: (streams: BroadcastStream[]) => vendorStream[];
  getCurrentStreams: () => Array<BroadcastStream>;
  getStreamById: (id: string) => BroadcastStream;
  getStreamByConnectionId: (id: string) => BroadcastStream;
  isPSTNStream: (stream: OTStreamType) => boolean;
  onConnectionCreated: (
    connection: any,
    shouldTrackStream?: (connectionData: any) => boolean
  ) => void;
  onConnectionDestroyed: (connection: any) => void;
  onStreamCreated: (stream: vendorStream) => void;
  onStreamDestroyed: (stream: vendorStream) => void;
  refreshStreams: () => void;
  refreshStreamById: (streamId: string, refreshTimeoutMs: number) => Promise<void>;
  streamExists: (stream: OpenTokStream | OTStreamType) => boolean;
  clearStreams: () => void;
  areAssetsAvailable: boolean;
}
