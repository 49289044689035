import { BaseComponentWithChildrenProps, LayoutProps, LayoutTheme } from "@q4/nimbus-ui";
import { SharedConsoleProps } from "../broadcastControls/console.definition";
import { ConsoleSettingsProps } from "./components/settings/consoleSettings.definition";
import { ConsoleParticipantsProps } from "./components/participants/consoleParticipants.definition";
import { QuestionSettingProps } from "./components/question/consoleQuestion.definition";
import { WhiteListTestResultData } from "./components/helpCenter/hooks/runWhiteListTest.hook.definition";

export enum ConsoleSlideOutType {
  Participants = "Participants",
  Chats = "Chats",
  Settings = "Settings",
  Materials = "Materials",
  Question = "Q&A",
  Voting = "Voting",
  Rsl = "RSL",
  HelpCenter = "Help",
  HelpResource = "Help Resource",
  Speakers = "Speakers",
  LiveEventReplay = "Live Event Replay",
  RequestCallback = "Request Callback",
}

export interface ConsoleSlideOutProps extends BaseComponentWithChildrenProps, SharedConsoleProps {
  /**
   * Used to determine the type of slide-out content
   */
  type?: ConsoleSlideOutType;
  /**
   * Used to supply the internal component with props
   * TODO: This should be driven through context so that it doesn't need to be passed through props
   */
  settingsProps?: ConsoleSettingsProps;
  /**
   * Used to supply the internal component with props
   * TODO: This should be driven through context so that it doesn't need to be passed through props
   */
  participantsProps?: ConsoleParticipantsProps;
  /**
   * Used to supply the internal component with props
   * TODO: This should be driven through context so that it doesn't need to be passed through props
   */
  participantsWaitingRoomProps?: any;
  /**
   * A callback that is triggered whenever the users attempts to close the slide-out
   */
  onCloseRequest: () => void;
  /**
   *  Used to supply the q&a tabs
   *  TODO: attendee and speaker component should separated
   */
  questionProps?: QuestionSettingProps;
  setCurrentSlideOutType: (type: ConsoleSlideOutType) => void;
  testToolResult?: TestToolResult;
}

type CallbackType = () => void;

export interface TestToolResult {
  testToolLoading: boolean;
  runWhiteListTest: CallbackType;
  whitelistResult: WhiteListTestResultData;
}

export enum ConsoleSlideOutClassNames {
  Base = "console-slide-out",
  BaseChat = "console-slide-out_chat",
  Header = "console-slide-out_header",
  HeaderWithBackButton = "console-slide-out_header-with-back-button",
  MaterialsHeader = "console-slide-out_materials-header",
  Title = "console-slide-out_title",
  Close = "console-slide-out_close",
  CloseChat = "console-slide-out_close-chat",
  Children = "console-slide-out_children",
}

export const CommonLayoutProps: Partial<LayoutProps> = {
  theme: LayoutTheme.LightGrey,
  direction: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
};
