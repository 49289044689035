import { IMediaDeviceInfo } from "../contexts";
declare global {
  interface HTMLVideoElement {
    sinkId: string;
    setSinkId: (sinkId: string) => void;
  }
}

export async function getCurrentAudioOutput(): Promise<IMediaDeviceInfo> {
  const allDevices = await navigator?.mediaDevices?.enumerateDevices();
  const audioOutputDevices = (allDevices || [])
    .filter((device) => device?.kind === "audiooutput")
    .map((device) => {
      let _device: IMediaDeviceInfo = device;
      _device.titleCase = device.label;
      return _device;
    });
  const mediaElements = document.getElementsByTagName("video");

  if (mediaElements?.length > 0 && mediaElements?.[0]?.sinkId !== "") {
    const match = audioOutputDevices.find(
      (audioDevice) => audioDevice.deviceId === mediaElements[0].sinkId
    ) as IMediaDeviceInfo;

    if (match) return match;
  }

  return audioOutputDevices[0];
}

export async function getAudioOutputs(
  setAudioOutputs: (inputs: IMediaDeviceInfo[]) => void
): Promise<void> {
  // displays a prompt that requests microphone access
  // without the prompt, no audio input/output devices can be accessed
  await navigator?.mediaDevices?.getUserMedia({ audio: true });

  const allDevices = await navigator?.mediaDevices?.enumerateDevices();
  const audioOutputDevices = (allDevices || [])
    .filter((device) => device?.kind === "audiooutput")
    .map((device) => {
      let _device: IMediaDeviceInfo = device;
      _device.titleCase = device.label;
      return _device;
    });

  setAudioOutputs(audioOutputDevices);
}

export function setAudioOutput(audioOutput: IMediaDeviceInfo): void {
  if (audioOutput?.deviceId) {
    const mediaElements = document.getElementsByTagName("video");
    for (let i = 0; i < mediaElements.length; i++) {
      if (mediaElements[i].classList.contains("ignore-audio-settings")) continue;
      mediaElements[i].setSinkId(audioOutput.deviceId);
    }
  }
}
