import React, { useContext, useEffect } from "react";

import useShareScreenPublisherHook from "../../hooks/shareScreenPublisher/shareScreenPublisher.hook";
import { SessionContext } from "../../contexts/session/session.context";

import { OTShareScreenPublisherProps } from "./OTShareScreenPublisher.definition";
import { SessionContextModel } from "../../contexts/session/session.definition";

import { usePrevious } from "../../utils/usePrevious";

function userToggledShareScreen(prevProps, props): boolean {
  return prevProps?.publish !== props?.publish;
}

export default function OTPublisher(props: OTShareScreenPublisherProps): JSX.Element {
  const sessionContext = useContext(SessionContext);
  const { OTSession, onStreamCreated, onStreamDestroyed } = sessionContext as SessionContextModel;

  const { createShareScreenPublisher, destroyShareScreenPublisher } = useShareScreenPublisherHook({
    onStreamCreated,
    onStreamDestroyed,
    onSharePublisherCreated: props.onShareScreenPublisherCreated,
    session: OTSession,
    isScreenShareAllowed: props.publisherEnabled,
    onScreenShareNotAllowed: props.onScreenShareNotAllowed,
  });

  const prevProps = usePrevious(props);

  useEffect(() => {
    if (OTSession) {
      if (props.publish) {
        handleSharingScreen();
        if (!props.publisherEnabled) {
          destroyShareScreenPublisher();
        }
      } else {
        handleNotSharingScreen();
      }
    }
  }, [props.publish, OTSession, props.publisherEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSharingScreen() {
    if (userToggledShareScreen(prevProps, props)) {
      destroyShareScreenPublisher();
      createShareScreenPublisher(props);
    }
  }

  function handleNotSharingScreen() {
    if (userToggledShareScreen(prevProps, props)) {
      destroyShareScreenPublisher();
    }
  }

  const { videoWrapperClassNames, videoWrapperIds, insertDefaultUI } = props;
  return insertDefaultUI ? (
    <div className={videoWrapperClassNames} id={videoWrapperIds}></div>
  ) : null;
}
