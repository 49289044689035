import ApiService from "../api/api.service";
import { ApiResponse } from "../api/api.definition";
import { PublicUserInfo } from "./organization.model";

export default class OrganizationService {
  private readonly ORGANIZATION_PATH = "/rest/organization";
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public getPublicUserInfo(idToken: string): Promise<ApiResponse<{ user: PublicUserInfo }>> {
    const useAuth = false;
    const queryParams = { idToken };
    const returnFullResponse = false;

    return this.apiService.get(
      `${this.ORGANIZATION_PATH}/public-user-info`,
      useAuth,
      queryParams,
      returnFullResponse
    );
  }
}
