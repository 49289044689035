import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace, MessageIdModel } from "@q4/nimbus-ui";

export interface ExitModalConfirmationProps {
  id: string;
  visible: boolean;
  handleClose(): void;
  handleSaveAndExit(): void;
}

export enum ExitModalConfirmationClassNames {
  Base = "exit-modal-confirmation",
}

export class ExitModelConfirmationIdModel extends IdModelBase {
  cancel: ButtonIdModel;
  confirm: ButtonIdModel;
  message: MessageIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.cancel = new ButtonIdModel(`${id}Cancel`);
    this.confirm = new ButtonIdModel(`${id}Confirm`);
    this.message = new MessageIdModel(`${id}Message`);
  }
}
