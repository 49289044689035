import React, { FunctionComponent, memo, useCallback, useContext, useEffect } from "react";
import { Modal, ButtonTheme } from "@q4/nimbus-ui";
import useAppVersionQuery from "./hooks/versionQuery.hook";
import moment from "moment";
import "./cacheBuster.scss";
import {
  CacheBusterClassNames,
  CACHE_BUSTER_MODAL_ID_MODEL as modalId,
  EXCLUDED_CACHE_BUSTER,
} from "./cacheBuster.definition";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";
import { LoadingScreen } from "../../components/loadingScreen/loadingScreen";
import { useLocation } from "react-router-dom";
import { useRebrandFlag } from "../../hooks/featureFlagHooks/useRebrandFlag.hook";

const isLocalHost = window.location.origin.includes("localhost");

const withCacheBuster = (WrappedComponent: FunctionComponent<any>) =>
  memo((props: any) => {
    const { featureFlags } = useContext(FeatureFlagsContext);
    const { loading, isExpectedVersion } = useAppVersionQuery();
    const { getRebrandingClassName } = useRebrandFlag();

    const location = useLocation();

    const refreshCacheAndReload = useCallback(async () => {
      if (caches) {
        try {
          const names = await caches.keys();
          for (let name of names) await caches.delete(name);
        } catch (e) {
          console.error(e);
        }
      }
      // location.reload() has no parameter
      // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
      const timestamp = moment().utc().valueOf();
      const url = new URL(window.location.href);

      url.searchParams.set("t", `${timestamp}`);

      window.location.replace(url.toString());
    }, []);

    useEffect(() => {
      if (isExpectedVersion || isLocalHost || !featureFlags[FeatureFlagKeys.CacheBuster]) return;

      const url = new URL(window.location.href);
      const reloadTime = +url.searchParams.get("t");
      if (reloadTime) {
        const reloadTimeMoment = moment.utc(reloadTime);

        const reloadedRecently = !reloadTimeMoment.isBefore(moment.utc().subtract(20, "seconds"));
        if (reloadedRecently) return;
      }

      refreshCacheAndReload();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (
      EXCLUDED_CACHE_BUSTER.includes(process.env.NODE_ENV) &&
      // Used for testing purposes only
      !props.forceCacheTest
    ) {
      return <WrappedComponent {...props} />;
    }

    if (loading) {
      return <LoadingScreen q4Icon="ni-q4-logo" />;
    }

    return (
      <>
        <WrappedComponent {...props} />
        <Modal
          id={modalId.id}
          title="A new version is available"
          className={getRebrandingClassName(CacheBusterClassNames.Base)}
          visible={!featureFlags[FeatureFlagKeys.CacheBuster] && !isExpectedVersion}
          includeExit={false}
          focusOnProps={{
            inert: true,
          }}
          footerActions={[
            {
              key: "refresh",
              label: "Refresh",
              theme: ButtonTheme.Citrus,
              onClick: refreshCacheAndReload,
            },
          ]}
        >
          The version you are using is out-of-date. Refresh your browser to get the latest version
          of the Q4 Events Platform.
        </Modal>
      </>
    );
  });

export default withCacheBuster;
