import React, { CSSProperties, memo, useMemo } from "react";
import { getClassName } from "@q4/nimbus-ui";
import {
  AspectRatioSizerClassNames,
  AspectRatioSizerProps,
  AspectRatio,
} from "./aspectRatioSizer.definition";
import "./aspectRatioSizer.component.scss";

/**
 * This component is used to force a certain aspect ratio on the child element
 * Note: Width plays a huge role in the component's functionality, and will default to 100% of the container
 */
function AspectRatioSizer(props: AspectRatioSizerProps): JSX.Element {
  const { id, className, ratio, width, children } = props;

  const baseClassName = useMemo(getBaseClassName, [className, ratio]);
  const styles = useMemo(getStyles, [width]);

  function getBaseClassName(): string {
    return getClassName(AspectRatioSizerClassNames.Base, [
      {
        condition: !!className,
        trueClassName: className,
      },
      {
        condition: !!ratio,
        trueClassName: `${AspectRatioSizerClassNames.Base}--${ratio}`,
      },
    ]);
  }

  function getStyles(): CSSProperties {
    return {
      width,
    };
  }

  return (
    <div id={id} className={baseClassName} style={styles}>
      <div className={AspectRatioSizerClassNames.PaddedWrapper}>
        <div
          className={AspectRatioSizerClassNames.ChildWrapper}
          id={props.childWrapperId}
          ref={props.childRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

AspectRatioSizer.defaultProps = {
  ratio: AspectRatio.Wide,
};

export default memo(AspectRatioSizer);
