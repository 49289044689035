import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextIdModel,
} from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import {
  RegistrationField,
  RegistrationFieldType,
} from "../../../../../../../../services/event/registrationFieldGql.model";
import { CustomRegistrationCardListId } from "../../../../../customRegistrationCard/customRegistrationCard.definition";
import { CustomRegistrationFieldSlideoutIdModel } from "../../../../../customRegistrationFieldSlideout/customRegistrationFieldSlideout.definition";
import { MicrositeCustomRegistrationEmptyStateIdModel } from "../micrositeCustomRegistrationEmptyState/micrositeCustomRegistrationEmptyState.definition";
import { DeleteFieldModalIdModel } from "./components/deleteFieldModal/deleteFieldModal.definition";

export enum MicrositeCustomRegistrationPanelClassNames {
  Base = "microsite-custom-registration-panel",
  BaseWithCenteredModifier = "microsite-custom-registration-panel--centered",
  AddButton = "microsite-custom-registration-panel_add-button",
  Title = "microsite-custom-registration-panel_title",
  Image = "microsite-custom-registration-panel_image",
  Message = "microsite-custom-registration-panel_message",
  FieldCount = "microsite-custom-registration-panel_field-count",
  CardWrapper = "microsite-custom-registration-panel_card-wrapper",
}

export const RegistrationFieldTypeDisplayName: Record<RegistrationFieldType, string> = {
  [RegistrationFieldType.Text]: "Text Answer",
  [RegistrationFieldType.LongText]: "Text Answer",
  [RegistrationFieldType.EmailAddress]: "Email Address",
  [RegistrationFieldType.PhoneNumber]: "Phone Number",
  [RegistrationFieldType.TextBlock]: "Text Block",
  [RegistrationFieldType.Consent]: "Consent Agreement",
  [RegistrationFieldType.Date]: "Date",
  [RegistrationFieldType.Dropdown]: "Dropdown",
  [RegistrationFieldType.QuestionAnswer]: "Q&A",
  [RegistrationFieldType.MultiChoice]: "Multiple Choice",
};

export const RegistrationFieldTypeSubheading: Record<RegistrationFieldType, string> = {
  [RegistrationFieldType.Text]: "Free-text input for users to write their own answers",
  [RegistrationFieldType.LongText]: "Free-text input for users to write their own answers",
  [RegistrationFieldType.EmailAddress]: "Input text with email format validation",
  [RegistrationFieldType.PhoneNumber]: "Input text with phone number validation",
  [RegistrationFieldType.TextBlock]: "View rich-text in an unactionable block",
  [RegistrationFieldType.Consent]: "Agree to a policy or disclaimer using a single checkbox",
  [RegistrationFieldType.Date]: "",
  [RegistrationFieldType.Dropdown]: "Select a single choice from a list",
  [RegistrationFieldType.QuestionAnswer]: "",
  [RegistrationFieldType.MultiChoice]: "Select multiple choices from a list",
};

export enum ReorderDirection {
  Up = "up",
  Down = "down",
}

export interface MicrositeCustomRegistrationPanelProps extends BaseComponentProps {
  registrationFields: RegistrationField[];
  setRegistrationFieldsState?: Dispatch<SetStateAction<RegistrationField[]>>;
  closeSlideOut: boolean;
}

export class MicrositeCustomRegistrationPanelIdModel extends IdModelBase {
  fieldCount: TextIdModel;
  addButton: ButtonIdModel;
  emptyState: MicrositeCustomRegistrationEmptyStateIdModel;
  cardWrapper: string;
  cardList: CustomRegistrationCardListId;
  slideout: CustomRegistrationFieldSlideoutIdModel;
  deleteModal: DeleteFieldModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.fieldCount = new TextIdModel(`${this.id}FieldCount`);
    this.addButton = new ButtonIdModel(`${this.id}AddButton`);
    this.emptyState = new MicrositeCustomRegistrationEmptyStateIdModel(`${this.id}EmptyState`);
    this.cardWrapper = `${this.id}CardWrapper`;
    this.cardList = new CustomRegistrationCardListId(`${this.id}Card`);
    this.slideout = new CustomRegistrationFieldSlideoutIdModel(`${this.id}Slideout`);
    this.deleteModal = new DeleteFieldModalIdModel(`${this.id}DeleteModal`);
  }
}
