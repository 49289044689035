import { QueryHookOptions, useQuery } from "@apollo/client";
import { EventType } from "../../services/event/event.model";
import { GET_EVENT_FEATURES_QUERY } from "./features.gql";
import { earningsFeaturesList } from "./earningsFeaturesList";
import { recordingFeaturesList } from "./recordingFeaturesList";
import { investorDayFeaturesList } from "./investorDayFeaturesList";
import { vsmFeaturesList } from "./vsmFeaturesList";

export enum EventFeatures {
  ActiveSessionCap = "active-session-cap",
  Archive = "archive-12-month",
  AttendeeDialIn = "attendee-dial-in",
  AudioQA = "audio-qa",
  BoardObserverParticipants = "board-observer-participants",
  GuestAttendance = "guest-attendance",
  GuestSpeakerEmailVerification = "guest-speaker-email-verification",
  HostParticipants = "host-participants",
  InspectorParticipants = "inspector-participants",
  MeetingMaterials = "meeting-materials",
  Microsite = "microsite",
  ParticipantDialIn = "participant-dial-in",
  PostEventReport = "post-event-report",
  PreEventQA = "pre-event-qa",
  PreRegistration = "pre-registration",
  RecordingReplay = "recording-replay",
  Registration = "registration",
  RSL = "rsl",
  ShareholderAttendance = "shareholder-attendance",
  SpeakerBios = "speaker-bios",
  SpeakerDialIn = "speaker-dial-in",
  SpeakersParticipants = "speaker-participants",
  SupportParticipants = "support-participants",
  TextQA = "text-qa",
  VendorSupportBroadridge = "vendor-support-broadridge",
  Voting = "voting",
}

export type EventFeatureConfig = {
  featureName: EventFeatures;
  enabled: boolean;
};

export interface GetEventFeaturesQueryData {
  getFeaturesByEventType: {
    eventType: EventType;
    features: EventFeatureConfig[];
  };
}

export type GetEventFeaturesQueryVariables = {
  eventType: EventType;
};

export const featureConfigurationsMap = new Map([
  [EventType.EARNINGS, earningsFeaturesList],
  [EventType.RECORDING, recordingFeaturesList],
  [EventType.INVESTOR_DAY, investorDayFeaturesList],
  [EventType.VSM, vsmFeaturesList],
]);

export const useEventFeaturesQuery = (
  options: QueryHookOptions<GetEventFeaturesQueryData, GetEventFeaturesQueryVariables> = {}
) =>
  useQuery<GetEventFeaturesQueryData, GetEventFeaturesQueryVariables>(GET_EVENT_FEATURES_QUERY, {
    fetchPolicy: "cache-first",
    ...options,
  });

export type FeatureMap = {
  [key: string]: boolean;
};

export type FeaturesByEventTypeMap = {
  [key: string]: EventFeatureConfig[];
};

export interface IUseEventFeatures {
  features: FeatureMap;
  renderByFeature: (
    feature: EventFeatures,
    component: JSX.Element,
    fallback: JSX.Element | null
  ) => JSX.Element | null;
}
