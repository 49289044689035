import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace, MessageIdModel } from "@q4/nimbus-ui";

export interface DeleteFieldModalProps {
  id: string;
  visible: boolean;
  handleCancel(): void;
  handleDelete(): void;
}

export enum DeleteFieldModalClassNames {
  Base = "delete-field-modal",
}

export class DeleteFieldModalIdModel extends IdModelBase {
  cancel: ButtonIdModel;
  confirm: ButtonIdModel;
  message: MessageIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(id)) return;

    this.cancel = new ButtonIdModel(`${id}Cancel`);
    this.confirm = new ButtonIdModel(`${id}Confirm`);
    this.message = new MessageIdModel(`${id}Message`);
  }
}
