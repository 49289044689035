import { gql } from "@apollo/client";

export interface SearchOrganizationsVariables {
  searchInput: string;
}

export interface OrganizationDTO {
  id: string;
  name: string;
}

export interface SearchOrganizationsQueryData {
  searchOrganizations: Array<OrganizationDTO>;
}

export const SEARCH_ORGANIZATIONS = gql`
  query SEARCH_ORGANIZATIONS($searchInput: String!) {
    searchOrganizations(searchInput: $searchInput) {
      id
      name
    }
  }
`;
