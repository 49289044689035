import React from "react";
import createAuth0Client, { Auth0ClientOptions } from "@auth0/auth0-spa-js";
import { BaseComponentWithChildrenProps, NotificationService, useStateRef } from "@q4/nimbus-ui";
import auth0 from "auth0-js";
import { reduce } from "lodash";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import config from "../../config";
import { ApiMethod, ContentType, ResponseCode } from "../../services/api/api.definition";
import { UserStatusType } from "../../services/user/user.definition";
import { User } from "../../services/user/user.model";
import { isAttendeeView } from "../../utils/attendee.utils";
import { isAdminConsoleView, isPreCallView, isReportView } from "../../utils/event.utils";
import { useSafeState } from "../../utils/react.utils";
import {
  Auth0ContextState,
  AuthConstants,
  AuthKeySuffixes,
  BaseTokenPermissions,
  DecodedAccessToken,
  LoginApplicationProps,
  LoginAttemptStatus,
  LoginStates,
  LoginWithRedirectProps,
  LogoutApplicationProps,
  PermissionObj,
} from "./auth0.definition";

const LocalStorage = window.localStorage;

const auth0Config: Auth0ClientOptions = {
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  audience: config.auth0.audience,
};

function cleanAuth0PostUrl(): void {
  const uri = window.location.toString();

  if (uri.indexOf("#") > 0) {
    const cleanUri = uri.substring(0, uri.indexOf("#"));
    window.history.replaceState({}, document.title, cleanUri);
  }
}

function _checkLSTokenExpiry(tokenDataStr: string) {
  if (!tokenDataStr) return;

  try {
    const { value: token, expiry } = JSON.parse(tokenDataStr) || {};

    const now = new Date();
    if (now.getTime() >= expiry) return;

    return token;
  } catch (e) {
    return;
  }
}

/** Token In Local Storage */
const addEnvToName = (name: string) => `${config.app.stage}.${name}`;

export function storeTokenInLS(token: string, suffix: AuthKeySuffixes) {
  try {
    const { exp } = decodeAccessToken(token) || {};

    const envBasedTokenKey = addEnvToName(AuthConstants.AUTH_TOKEN_COOKIE_NAME);
    const LSKey = `${envBasedTokenKey}-${suffix}`;
    LocalStorage.setItem(LSKey, JSON.stringify({ value: token, expiry: exp * 1000 }));
  } catch (e) {
    // fail silently
  }
}

export function getTokenFromLS(suffix: AuthKeySuffixes) {
  const envBasedTokenKey = addEnvToName(AuthConstants.AUTH_TOKEN_COOKIE_NAME);
  const LSKey = `${envBasedTokenKey}-${suffix}`;
  const token = _checkLSTokenExpiry(LocalStorage.getItem(LSKey));

  if (!token) removeTokenFromLS();
  return token;
}

export function removeTokenFromLS() {
  const envBasedTokenKey = addEnvToName(AuthConstants.AUTH_TOKEN_COOKIE_NAME);
  Object.values(AuthKeySuffixes).forEach((suffix) => {
    const LSKey = `${envBasedTokenKey}-${suffix}`;
    LocalStorage.removeItem(LSKey);
  });
}

export function decodeAccessToken(token: string): DecodedAccessToken | undefined {
  try {
    const jwtData = token.split(".")[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    return decodedJwtData;
  } catch {
    return;
  }
}

export function buildPermissionsDictionary(
  permissions: string[] | undefined = []
): Record<string, true> {
  return reduce(permissions, (dict, key) => ({ ...dict, [key]: true }), {});
}

export const Auth0Context = createContext<Partial<Auth0ContextState>>({});

export const Auth0Provider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const auth0Client = useRef(null);
  const webAuth = useRef(null);

  const notificationService = useRef(new NotificationService());

  const [authInitialized, setAuthInitialized] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAttendeeAuthenticated, setIsAttendeeAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [user, userRef, setUser] = useStateRef<User>(null);
  const [failedLoginCount, setFailedLoginCount] = useState(0);
  const [isUserSuspended, setIsUserSuspended] = useState(false);
  const attendeeTokenRef = useRef<string>(null);
  const reportTokenRef = useRef<string>(null);

  const [eventTokenState, setEventTokenState, eventTokenRef] = useSafeState<string>(null);

  // TODO - expand on this to use the appropriate token depending on the page
  // being accessed
  const currentToken = useMemo(() => eventTokenState, [eventTokenState]);

  useEffect(() => {
    initializeAuth0();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (failedLoginCount >= AuthConstants.LOGIN_FAIL_LIMIT) {
      return setIsUserSuspended(true);
    }
  }, [failedLoginCount]);

  function updateAttendeeToken(token) {
    attendeeTokenRef.current = token;
    storeTokenInLS(token, AuthKeySuffixes.ATTENDEE);
  }

  function enableAuthentication() {
    setIsAuthenticated(true);
  }

  const updateEventToken = useCallback(
    (token) => {
      setEventTokenState(token);
      setIsAuthenticated(true);
    },
    [setEventTokenState]
  );

  const updateAttendeeAuthenticated = useCallback(
    (isAuthenticated: boolean) => setIsAttendeeAuthenticated(isAuthenticated),
    [setIsAttendeeAuthenticated]
  );

  function updateReportToken(token) {
    reportTokenRef.current = token;
    storeTokenInLS(token, AuthKeySuffixes.REPORT);
    setIsAuthenticated(true);
  }

  function createWebAuthClient() {
    webAuth.current = new auth0.WebAuth({
      clientID: config.auth0.clientId,
      domain: config.auth0.domain,
      responseType: "token id_token",
    });

    return webAuth;
  }

  function updateUser(user) {
    userRef.current = user;
    user && setUser(userRef.current);
  }

  // initialize the auth0 library
  async function initializeAuth0(): Promise<void> {
    createWebAuthClient();
    auth0Client.current = await createAuth0Client(auth0Config);

    setAuthInitialized(true);

    if (window.location.hash) {
      handlePostLogin();
      return;
    }

    const isAuthenticated = await auth0Client.current?.isAuthenticated();
    if (isAuthenticated) {
      const user: User = isAuthenticated ? await _getUserData() : null;
      if (!user)
        return logoutApplication({
          returnTo: `${config.app.url}/login?state=${LoginStates.UserMissing}`,
        });

      const permissions: PermissionObj = await getUserPermissions();

      userRef.current = { ...user, permissions };
      setUser(() => userRef.current);
    }
    setIsAuthenticated(isAuthenticated);
    setIsLoading(false);
  }

  async function handlePostLogin(): Promise<void> {
    setIsLoading(true);

    const user: User = await _getUserData();
    if (!user)
      return logoutApplication({
        returnTo: `${config.app.url}/login?state=${LoginStates.UserMissing}`,
      });

    const permissions: PermissionObj = await getUserPermissions();
    userRef.current = { ...user, permissions };
    await updateLoginCount(userRef.current.email, LoginAttemptStatus.SUCCESS);
    setUser(() => userRef.current);

    setIsAuthenticated(true);
    getUserAccessToken();
    setIsLoading(false);

    sessionStorage?.removeItem(AuthConstants.LOGIN_REDIRECT_SESSION_KEY);
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}${window.location.search}`
    );
    cleanAuth0PostUrl();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function checkAuthenticatedUser(): Promise<any> {
    return !!(await auth0Client.current.getUser());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getIdTokenClaims(): any {
    return auth0Client.current.getIdTokenClaims();
  }

  async function getUserPermissions(): Promise<PermissionObj> {
    const decodedAccessToken = await getDecodedUserAccessToken();
    return buildPermissionsDictionary(decodedAccessToken?.permissions);
  }

  function loginWithRedirect(loginProps: LoginWithRedirectProps) {
    if (loginProps?.appState?.targetUrl) {
      sessionStorage?.setItem(
        AuthConstants.LOGIN_REDIRECT_SESSION_KEY,
        `${config.app.url}${loginProps.appState.targetUrl}`
      );
    }
    window.location.pathname = "/login";
  }

  async function updateLoginCount(email: string, status: LoginAttemptStatus) {
    try {
      const headers: HeadersInit = new Headers({
        "Content-Type": ContentType.Json,
      });
      const data = {
        email,
        status,
      };

      const options: RequestInit = {
        method: ApiMethod.Post,
        headers,
        body: JSON.stringify(data),
      };

      const updateLoginCountUrl = `${config.api.graphqlBaseUrl}/rest/user/update-login-count`;
      const response = await fetch(updateLoginCountUrl, options).then((res) => res.json());

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async function loginApplication(loginProps: LoginApplicationProps, failedLoginCountNum) {
    const { email, password } = loginProps;

    if (failedLoginCountNum >= AuthConstants.LOGIN_FAIL_LIMIT) {
      return setIsUserSuspended(true);
    }

    const redirectURI = `${config.app.url}${AuthConstants.LOGIN_REDIRECT_PATH}`;

    if (webAuth.current) {
      webAuth.current.login(
        {
          email: email?.toLowerCase(),
          password: password,
          realm: config.auth0.userDatabase,
          redirectUri: redirectURI || config.app.url,
        },
        async function (error) {
          if (error) {
            const response = await updateLoginCount(email, LoginAttemptStatus.FAILURE);
            if (response?.success) {
              setFailedLoginCount(failedLoginCountNum + 1);
            }
          }
          notificationService.current.error("Incorrect email or password");
        }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleAuthOnCallback(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!auth0Client?.current) {
          auth0Client.current = await createAuth0Client(auth0Config);
        }
        await auth0Client.current.getTokenSilently();
        await initializeAuth0();
        return resolve();
      } catch (err) {
        return reject();
      }
    });
  }

  function logoutApplication(logoutProps: LogoutApplicationProps = {}) {
    const { returnTo = `${config.app.url}/login` } = logoutProps;

    removeTokenFromLS();

    webAuth.current?.logout({
      returnTo: returnTo,
      clientID: config.auth0.clientId,
    });
  }

  async function getDecodedUserAccessToken() {
    const jwtToken = await auth0Client.current?.getTokenSilently();
    if (jwtToken) {
      return decodeAccessToken(jwtToken);
    }
  }

  async function getRawUserAccessToken() {
    return await auth0Client.current?.getTokenSilently();
  }

  function adminConsoleTokenAvailable() {
    if (eventTokenRef?.current) return true;

    const token = getTokenFromLS(AuthKeySuffixes.CONSOLE);
    if (!token) return false;

    const decodedToken = decodeAccessToken(token);
    const userEventPermissions = buildPermissionsDictionary(decodedToken.permissions);

    if (userEventPermissions[BaseTokenPermissions.AdminConsole]) {
      eventTokenRef.current = token;
      return true;
    }
    return false;
  }

  async function getUserAccessToken() {
    try {
      if (isAttendeeView()) {
        storeTokenInLS(attendeeTokenRef?.current, AuthKeySuffixes.ATTENDEE);
        return attendeeTokenRef?.current;
      }
      if ((isAdminConsoleView() || isPreCallView()) && adminConsoleTokenAvailable()) {
        storeTokenInLS(eventTokenRef?.current, AuthKeySuffixes.CONSOLE);
        return eventTokenRef?.current;
      }
      if (isReportView()) {
        storeTokenInLS(reportTokenRef?.current, AuthKeySuffixes.REPORT);
        return reportTokenRef?.current;
      }
      const auth0Token = await auth0Client.current?.getTokenSilently();

      storeTokenInLS(auth0Token, AuthKeySuffixes.PlATFORM);
      return auth0Token;
    } catch (err) {
      return null;
    }
  }

  async function _getUserData(): Promise<User> {
    const rawAuthToken = await getRawUserAccessToken();
    const decodedAuthToken = await getDecodedUserAccessToken();

    const { sub } = decodedAuthToken;

    const headers: HeadersInit = new Headers({
      "Content-Type": ContentType.Json,
      "Authorization": `Bearer ${rawAuthToken}`,
    });

    const options: RequestInit = {
      method: ApiMethod.Get,
      headers,
    };

    try {
      const getUserUrl = `${config.api.graphqlBaseUrl}/rest/users/${sub}`;
      const response = await fetch(getUserUrl, options).then((res) => res.json());

      const { success, data } = response;

      if (success) {
        return data;
      }

      return errorInterceptor(response);
    } catch (err) {
      console.log("Failed to get user");
      return null;
    }
  }

  function errorInterceptor(response) {
    const code = response?.errors?.[0]?.extensions?.httpCode;
    const reason = response?.errors?.[0]?.extensions?.reason;
    const criticalError = response?.errors?.[0]?.extensions?.critical;

    return code === ResponseCode.Unauthorized && criticalError
      ? unauthorizedInterceptor(reason)
      : null;
  }

  function unauthorizedInterceptor(reason) {
    logoutApplication(
      reason === UserStatusType.Suspended
        ? { returnTo: `${config.app.url}/login?state=${LoginStates.Suspended}` }
        : {}
    );

    return null;
  }

  function getCurrentUser() {
    return userRef.current;
  }

  const { children } = props;
  return (
    <Auth0Context.Provider
      value={{
        authInitialized,
        isLoading,
        isAuthenticated,
        isAttendeeAuthenticated,
        user,
        isUserSuspended,
        currentToken,
        enableAuthentication,
        getCurrentUser,
        getUserAccessToken,
        checkAuthenticatedUser,
        getIdTokenClaims,
        loginWithRedirect,
        loginApplication,
        logoutApplication,
        handleAuthOnCallback,
        updateUser,
        updateAttendeeToken,
        updateAttendeeAuthenticated,
        updateEventToken,
        updateReportToken,
        errorInterceptor,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
