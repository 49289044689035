import { InvestorTypeNames } from "../../views/broadcastParticipant/eventRegistration/components/eventRegistrationForm.definition";

export enum PublicUserFlow {
  Register = 'register',
  Update = 'update',
  Login = 'login',
}
export interface PublicUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  type?: InvestorTypeNames;
  institutionId?: string;
  company?: string;
  role?: string;
  flow?: PublicUserFlow
}
