export const AVATAR_COLOURS = [
  "#297AC5", // Rain
  "#EC6A4C", // Spice
  "#E67F22", // Ginger
  "#0DA084", // Dark Teal
  "#935F39", // Walnut
  "#C08C0C", // Primary
  "#A4305E", // Raspberry
  "#804A8C", // Plum
  "#3A925F", // Avocado
];

export const AVATAR_DEFAULT_COLOUR = "#545B62"; // Light Slate

export enum CustomAvatarSizes {
  Mini = "25%",
  Small = "35%",
  Medium = "50%",
  Large = "65%",
  Thicc = "75%",
}

export enum CustomAvatarIcon {
  Phone = "q4i-phone-4pt",
}

export interface CustomAvatarProps {
  name?: string;
  size?: string | CustomAvatarSizes;
  icon?: CustomAvatarIcon;
  colour?: string;
  maxInitials?: number;
  classNames?: {
    wrapper?: string;
    content?: string;
  };
}

export enum CustomAvatarClassNames {
  Base = "custom_avatar",
  Content = "custom_avatar-content",
}

export const getDefaultInitials = (name: string, maxInitials: number) => {
  return name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v)
    .slice(0, maxInitials)
    .join("")
    .toUpperCase();
};

export function getIconInitials(icon: JSX.Element, maxInitials: number, displayName: string) {
  const initialsElements = [];
  const trueMaxInitials = icon && maxInitials > 0 ? maxInitials - 1 : maxInitials;
  const letters = getDefaultInitials(displayName, trueMaxInitials);

  if (icon) {
    initialsElements.push(icon);
    letters && initialsElements.push(" ", letters);
  } else if (letters) {
    initialsElements.push(letters);
  }

  return initialsElements as unknown as string;
}
