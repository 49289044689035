import {
  SIGNED_ASSET_QUERY,
  SignedUrlHookVariables,
  SignedUrlResult,
} from "../s3Hooks/signedUrls.hook.definition";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";

export default function useS3Hooks(props?: SignedUrlHookVariables) {
  function useSignedAssetQuery(
    options: QueryHookOptions<SignedUrlResult, SignedUrlHookVariables>
  ): QueryResult<SignedUrlResult, SignedUrlHookVariables> {
    return useQuery(SIGNED_ASSET_QUERY, options);
  }

  return {
    useSignedAssetQuery,
  };
}
