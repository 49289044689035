import { useEffect, useState } from "react";

import { isEmpty, omitBy } from "lodash";

import {
  generateBackgroundUrl,
  generateFaviconUrl,
  generateFontUrl,
  generateLogoUrl,
} from "../../utils/asset.utils";
import {
  FontName,
  GeneralFontsList,
} from "../../views/eventMicrosite/components/micrositeControlPanelModal/controlPanelForm/controlPanelForm.definition";

import config from "../../config";
import useIsMounted from "../isMounted/isMounted.hook";
import { useBackgroundImageWithTitle } from "../backgroundImages/backgroundImages.hook";
import {
  AttendeeConsoleBrandingFooterData,
  AttendeeConsoleBrandingHeaderData,
  AttendeeConsoleBrandingMediaBarData,
  BrandingHookModel,
  BrandingHookProps,
  FontType,
  ImageType,
} from "./branding.hook.definition";

// TODO: Remove this after figuring out the architecture
const preBroadcastMusic = `${config.branding.s3.domain}/companyAssets/general/music/lobby music.mp3`;

export default function useBranding(props: BrandingHookProps): BrandingHookModel {
  const {
    backgroundImage: backgroundImageProp,
    primaryFont,
    primaryColor,
  } = props.eventBranding || {};

  const [loading, setLoading] = useState<boolean>(true);

  // images
  const [backgroundImage, setBackgroundImage] = useState<string>();
  const [companyLogo, setCompanyLogo] = useState<string>();
  const [companyFavicon, setCompanyFavicon] = useState<string>();

  // music
  const [preBroadcastMusicUri, setPreBroadcastMusicUri] = useState<string>(preBroadcastMusic);

  // font & colors
  const [primaryColorData, setPrimaryColorData] = useState<string>();
  const [primaryFontData, setprimaryFontData] = useState<FontType>();
  const [secondaryColorData, setSecondaryColorData] = useState<string>();
  const [secondaryFontData, setSecondaryFontData] = useState<FontType>();

  // console branding
  const [eventHeaderColors, setEventHeaderColors] = useState<AttendeeConsoleBrandingHeaderData>();
  const [eventFooterColors, setEventFooterColors] = useState<AttendeeConsoleBrandingFooterData>();
  const [eventMediaBarColors, setEventMediaBarColors] =
    useState<AttendeeConsoleBrandingMediaBarData>();

  const hasEventBranding = !isEmpty(props.eventBranding);

  const isMounted = useIsMounted();

  const { backgroundImageWithTitle: brandedBackgroundImage } = useBackgroundImageWithTitle({
    fontColor: primaryColor,
    fontData: primaryFontData,
    imageUrl: backgroundImage,
    loading,
    title: props?.eventTitle,
  });

  useEffect(() => {
    if (hasEventBranding) {
      const {
        backgroundImage,
        companyLogo,
        companyFavicon,
        primaryFont,
        secondaryFont,
        primaryColor,
        secondaryColor,
        primaryFontWeight,
        secondaryFontWeight,
        headerColors,
        footerColors,
        mediaBarColors,
      } = props.eventBranding;
      const { companyId } = props;

      const backgroundImageUrl = generateBackgroundUrl(companyId, backgroundImage);

      const companyLogoUrl = generateLogoUrl(companyId, companyLogo);

      const companyFaviconUrl = generateFaviconUrl(companyId, companyFavicon);

      const primaryFontUrl = GeneralFontsList.includes(primaryFont?.displayName)
        ? `${config.branding.s3.domain}/companyAssets/general/fonts/${primaryFont?.name}`
        : generateFontUrl(companyId, primaryFont);
      const secondaryFontUrl = GeneralFontsList.includes(secondaryFont?.displayName)
        ? `${config.branding.s3.domain}/companyAssets/general/fonts/${secondaryFont?.name}`
        : generateFontUrl(companyId, secondaryFont);

      if (!isEmpty(primaryFont) && primaryFont.name !== FontName.inter)
        setprimaryFontData({
          ...omitBy({ fontUrl: primaryFontUrl, fontWeight: primaryFontWeight }, isEmpty),
          ...primaryFont,
        });

      if (!isEmpty(secondaryFont) && primaryFont.name !== FontName.inter)
        setSecondaryFontData({
          ...omitBy({ fontUrl: secondaryFontUrl, fontWeight: secondaryFontWeight }, isEmpty),
          ...secondaryFont,
        });

      if (backgroundImageUrl) {
        fetchImage(backgroundImageUrl, ImageType.backgroundImage);
      } else {
        setBackgroundImage(config.branding.defaultBackgroundImgUrl);
      }
      if (companyLogoUrl) {
        fetchImage(companyLogoUrl, ImageType.companyLogo);
      } else {
        setCompanyLogo(config.branding.defaultLogoUrl);
      }

      if (companyFaviconUrl) setCompanyFavicon(companyFaviconUrl);

      if (preBroadcastMusicUri) setPreBroadcastMusicUri(preBroadcastMusicUri);
      // Ensure backward compatibility with non hashed colors
      if (primaryColor) setPrimaryColorData(`#${primaryColor.replace("#", "")}`);
      if (secondaryColor) setSecondaryColorData(`#${secondaryColor.replace("#", "")}`);

      // Event Console Branding setters
      if (!isEmpty(headerColors)) setEventHeaderColors({ ...headerColors });
      if (!isEmpty(footerColors)) setEventFooterColors({ ...footerColors });
      if (!isEmpty(mediaBarColors)) setEventMediaBarColors({ ...mediaBarColors });
    } else {
      setBackgroundImage(config.branding.defaultBackgroundImgUrl);
    }

    setLoading(false);
  }, [hasEventBranding, backgroundImageProp, primaryFont, primaryColor]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchImage = (src: string, imageType: ImageType) => {
    const image = new Image();

    switch (imageType) {
      case ImageType.backgroundImage:
        if (src === backgroundImage) return;
        break;
      case ImageType.companyLogo:
        if (src === companyLogo) return;
        break;
      default:
        break;
    }

    image.src = src;
    image.onload = () => {
      if (!isMounted()) return;
      switch (imageType) {
        case ImageType.backgroundImage:
          return setBackgroundImage(src);
        case ImageType.companyLogo:
          return setCompanyLogo(src);
        default:
          return;
      }
    };
    image.onerror = () => {
      if (!isMounted()) return;

      switch (imageType) {
        case ImageType.backgroundImage:
          return setBackgroundImage(config.branding.defaultBackgroundImgUrl);
        case ImageType.companyLogo:
          return setCompanyLogo(config.branding.defaultLogoUrl);
        default:
          return;
      }
    };
  };

  return {
    backgroundImage,
    brandedBackgroundImage,
    companyLogo,
    companyFavicon,
    primaryFontData,
    secondaryFontData,
    primaryColor: primaryColorData,
    secondaryColor: secondaryColorData,
    hasEventBranding,
    loading,
    preBroadcastMusicUri,
    attendeeConsoleBrandingState: {
      headerColors: eventHeaderColors,
      footerColors: eventFooterColors,
      mediaBarColors: eventMediaBarColors,
    },
  };
}
