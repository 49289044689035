import {
  BaseComponentProps,
  ButtonIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  LayoutIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";

export enum MicrositeCustomRegistrationPreviewClassNames {
  Base = "microsite-custom-registration-preview",
  Layout = "microsite-custom-registration-preview_layout",
  Image = "microsite-custom-registration-preview_image",
  Title = "microsite-custom-registration-preview_title",
  Message = "microsite-custom-registration-preview_message",
  Button = "microsite-custom-registration-preview_button",
  Field = "microsite-custom-registration-preview_field",
}

export enum MicrositeCustomRegistrationPreviewLabels {
  Header = "Register for this event",
  Message = "Finish registering for the event by providing Hexatone with a few more details.",
  Button = "REGISTER & JOIN",
}

export interface MicrositeCustomRegistrationPreviewProps extends BaseComponentProps {}

export class MicrositeCustomRegistrationPreviewIdModel extends IdModelBase {
  layout: LayoutIdModel;
  image: string;
  header: TextIdModel;
  message: TextIdModel;
  button: ButtonIdModel;
  form: FormIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.layout = new LayoutIdModel(`${this.id}Layout`);
    this.image = `${this.id}Image`;
    this.header = new LayoutIdModel(`${this.id}Header`);
    this.message = new TextIdModel(`${this.id}Message`);
    this.button = new ButtonIdModel(`${this.id}Button`);
    this.form = new FormIdModel(`${this.id}Form`);
  }
}
