import { datadogLogs, LogsEvent } from "@datadog/browser-logs";

import { getAppVersion } from "../components/navigation/app-version.resolver";
import config from "../config";
import { User } from "../services/user/user.model";

export const ignoreErrors = [
  "OpenTok:RumorSocket", // ignore OT when socket is reconnecting due to network
  "OpenTok:RaptorSocket", // ignore OT when socket is reconnecting due to network
  "Unable to send signal - you are not connected to the session", // ignore OT error when sesison is not connected but trying to send signal
  "Incorrect email or password", // Ignore all incorrect email/password errors. These errors should be tracked by other means
  /^API error: .*/, // Ignore all API errors. These errors should be tracked by other means
  "ResizeObserver loop limit exceeded", // This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign
  /(safari|chrome)-extension:\/\//i, // Ignore errors caused by safari or chrome extensions like grammarly and similar
  "Non-Error promise rejection captured", // Caused when a rejection is raised with a non-error object, from which we cannot extract much information
  "Apollo SubscriptionClient error:", // Apollo connection errors
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.", // The same as "Failed to fetch" just in Firefox browser,
  /ChunkLoadError/i, // Ignore all ChunkLoad errors,
  /^XHR error GET https:\/\/broadcast.*\.media\.prod\.tokbox\.com\/.*/i, // Vonage broadcast errors
  /OpenTok:Session:warn/i, // Opentok WebRTC errors
  /OpenTok:Publisher:warn/i, // Opentok WebRTC errors
  /^The following deprecated features are currently enabled, these will be removed in TinyMCE 6.0.*/i, // TinyMCE errors
];

interface DDLogsContext {
  meetingId?: number;
  companyId?: string;
}
interface DDLogsContext {
  meetingId?: number;
  companyId?: string;
  userId?: string;
  email?: string;
}

export const setDDLogsUser = (user: Partial<User>) => {
  datadogLogs.setUser({
    id: user?.id,
    email: user?.email,
  });
};

export const initDDLogs = () => {
  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    env: config.app.stage,
    sessionSampleRate: 30,
    service: config.app.name,
    forwardErrorsToLogs: false,
    site: "datadoghq.com",
    version: getAppVersion(),
    beforeSend: loggerMiddleware,
  });
};

function loggerMiddleware(event: LogsEvent) {
  if (
    ignoreErrors?.some((error) => {
      if (error instanceof RegExp) {
        return error.test(event?.message);
      }

      return event?.message?.includes(error);
    })
  ) {
    return false;
  }
  // Ignore HeadlessChrome
  if (
    window.navigator.userAgent.indexOf("HeadlessChrome") !== -1 &&
    !window.location?.pathname?.startsWith("/admin/broadcastlayout")
  )
    return false;

  if (event.origin === "console") {
    // console messages have "console error:" appended to them, removing
    // to improve readability
    const consoleRegex = /^console (error|warn|info|debug|trace):/;
    event.message = event.message?.replace(consoleRegex, "");
  }
}

export const setDDLogsContext = (context: DDLogsContext) => {
  datadogLogs.setGlobalContextProperty("sessionInfo", context);
};
