import { useCallback } from "react";
import { useTimeDeltaQuery } from "./timeDelta.hook.definition";
import { ApolloError } from "@apollo/client";

export default function useTimeDelta() {
  const { refetch: GetTimeDeltaQueryData } = useTimeDeltaQuery({
    fetchPolicy: "cache-only",
    skip: true,
    onError: (error: ApolloError) => {
      console.warn(`Failed to get time delta ${error.message}`);
    },
  });

  const getTimeDelta = useCallback(
    async (currentTime: Date) => {
      const response = await GetTimeDeltaQueryData({ currentTime });
      return response?.data?.getTimeDelta;
    },
    [GetTimeDeltaQueryData]
  );

  return {
    getTimeDelta,
  };
}
