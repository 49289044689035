import "./fallback.component.scss";
import FallbackImage from "./fallback-error.svg";
import React, { memo } from "react";
import { isMobile, isTablet, isIOS, isMacOs } from "react-device-detect";
import { Anchor, AnchorTarget, AnchorTheme, ButtonTheme, PlaceholderContent } from "@q4/nimbus-ui";
import { FallbackProps, FallbackClassName, UnsupportedBrowser } from "./fallback.definition";
import GoogleLogo from "../../assets/events/google.svg";
import SafariLogo from "../../assets/events/safari.svg";
import {
  EdgeDownloadUrl,
  GoogleDownloadUrl,
  SafariDownloadUrl,
} from "../../views/broadcastParticipant/broadcastParticipant.definition";
import EdgeLogo from "../../assets/events/edge.svg";
import { EventSupportEmail } from "../waitingRoom/waitingRoom.definition";

const Fallback = (props: FallbackProps): JSX.Element => {
  const email = EventSupportEmail;
  const {
    title,
    hideBackBtn,
    subtitle,
    isBrowserSupport,
    showUnsupportedSafari,
    continueToEvent,
    actions,
  } = props;

  function BrowserSupport(): JSX.Element {
    return (
      <div className={UnsupportedBrowser.Base}>
        <div className={UnsupportedBrowser.Title}>
          {continueToEvent ? "Recommended Browsers" : "Supported Browsers:"}
        </div>
        <div className={UnsupportedBrowser.Container}>
          <div className={UnsupportedBrowser.ContainerItem}>
            <img className={UnsupportedBrowser.Logo} src={GoogleLogo} alt="Google Chrome" />
            <Anchor
              className={UnsupportedBrowser.Anchor}
              theme={AnchorTheme.Rain}
              url={GoogleDownloadUrl}
              target={AnchorTarget.Blank}
            >
              Google Chrome
            </Anchor>
          </div>
          {!isMobile && !isTablet && (
            <div className={UnsupportedBrowser.ContainerItem}>
              <img className={UnsupportedBrowser.Logo} src={EdgeLogo} alt="Microsoft Edge" />
              <Anchor
                className={UnsupportedBrowser.Anchor}
                theme={AnchorTheme.Rain}
                url={EdgeDownloadUrl}
                target={AnchorTarget.Blank}
              >
                Microsoft Edge
              </Anchor>
            </div>
          )}
          {(isIOS || isMacOs) && showUnsupportedSafari && (
            <div className={UnsupportedBrowser.ContainerItem}>
              <img className={UnsupportedBrowser.Logo} src={SafariLogo} alt="Safari" />
              <Anchor
                className={UnsupportedBrowser.Anchor}
                theme={AnchorTheme.Rain}
                url={SafariDownloadUrl}
                target={AnchorTarget.Blank}
              >
                Safari
              </Anchor>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={FallbackClassName.Base}>
      <PlaceholderContent
        title={title}
        image={FallbackImage}
        subtitle={
          <>
            {subtitle || (
              <p>
                We can&#39;t seem to load the page you&#39;re looking for.
                <br />
                If you need further assistance, please contact{" "}
                <Anchor theme={AnchorTheme.Rain} url={`mailto: ${email}`}>
                  {email}
                </Anchor>
                .
              </p>
            )}
            {isBrowserSupport && <BrowserSupport />}
          </>
        }
        actions={
          actions || [
            {
              label: "GO BACK",
              theme: ButtonTheme.Rain,
              onClick: (): void => {
                window.location.href = window.document.referrer;
              },
              hidden: hideBackBtn,
            },
          ]
        }
      />
      <div className="fallback_logo q4i-logo" />

      {continueToEvent && (
        <div className={FallbackClassName.ContinueFooter}>
          <Anchor
            className={FallbackClassName.ContinueAnchor}
            theme={AnchorTheme.Rain}
            onClick={continueToEvent}
          >
            Continue
          </Anchor>
          to event with my existing browser
        </div>
      )}
    </div>
  );
};

Fallback.defaultProps = {
  hideBackBtn: true,
  isSupportedBrowsers: false,
  title: "Oops! Something went wrong.",
  isBrowserSupport: false,
  showUnsupportedSafari: false,
};

export default memo(Fallback);
