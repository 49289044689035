import { FetchResult } from "@apollo/client";
import { FILE_SIZES } from "../../components/fileUploader/fileUploader.definition";
import { EventModelPopulated } from "../events/events.hook.definitions";
import { UploadEventAssetFileProps } from "./save.hook";

export enum ContentType {
  AUDIO_VIDEO = "AUDIO_VIDEO",
  SLIDES = "SLIDES",
  MATERIALS = "MATERIALS",
  SPEAKERBIOS = "SPEAKERBIOS",
}

export enum AssetType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  SLIDESHOW = "SLIDESHOW",
  SPREADSHEET = "SPREADSHEET",
  DOCUMENT = "DOCUMENT",
  WEBLINK = "WEBLINK",
  FONT = "FONT",
  IMAGE = "IMAGE",
  LOGO = "LOGO",
  FAVICON = "FAVICON",
}

export const FriendlyAssetType = {
  [AssetType.VIDEO]: "Video",
  [AssetType.AUDIO]: "Audio",
  [AssetType.SLIDESHOW]: "Slideshow",
  [AssetType.DOCUMENT]: "Document",
  [AssetType.SPREADSHEET]: "SpreadSheet",
  [AssetType.WEBLINK]: "Web Link",
};

export const FriendlyAssetExt = {
  doc: "Document",
  docx: "Document",
  pdf: "Document",
  ppt: "Slideshow",
  pptx: "Slideshow",
  xls: "SpreadSheet",
  xlsx: "SpreadSheet",
  mp3: "Audio",
  wav: "Audio",
  mp4: "Video",
  avi: "Video",
};

export enum SlidesType {
  PCS = "PCS",
  UCS = "UCS",
}

export type AudioVideoContext = {
  duration?: number;
};

export type SlidesContext = {
  shareAsMaterial: boolean;
  useInMeeting?: boolean;
  isUserControlled?: boolean;
};

export type MaterialContext = {};

export interface AssetUploadsBase {
  id: string;
  name: string;
  displayName: string;
  assetExt?: string;
  size?: number;
  uploadDate: string;
}

export interface AudioVideoUploads extends AssetUploadsBase {
  context: AudioVideoContext;
  contentType: ContentType.AUDIO_VIDEO;
  assetType: AssetType.AUDIO | AssetType.VIDEO;
}

export interface SlideUploads extends AssetUploadsBase {
  context: SlidesContext;
  contentType: ContentType.SLIDES;
  assetType: AssetType.SLIDESHOW;
}

export interface MaterialUploads extends AssetUploadsBase {
  context: MaterialContext;
  contentType: ContentType.MATERIALS;
  assetType: AssetType.SPREADSHEET | AssetType.WEBLINK | AssetType.DOCUMENT;
}

export interface SpeakerBioUploads extends AssetUploadsBase {
  context: {};
  contentType: ContentType.SPEAKERBIOS;
  assetType: AssetType.IMAGE;
}

export interface BrandingUploads extends AssetUploadsBase {
  context: undefined;
  contentType: undefined;
  assetType: AssetType.LOGO;
}

export type AssetUploads =
  | AudioVideoUploads
  | SlideUploads
  | MaterialUploads
  | SpeakerBioUploads
  | BrandingUploads;
export type AssetStats = { currentSlide?: number; totalSlides: number };
export type AssetData = AssetUploads & {
  url?: string;
  getAssetStream?: () => Promise<MediaStream>;
} & (
    | // slides assets require function for setting the current page
    {
      elementRef?: HTMLCanvasElement;
      setPage: (page: number) => void;
      stats: AssetStats;
    }
    | {
      elementRef?: HTMLMediaElement;
      setPage?: (page: number) => void;
      stats?: AssetStats;
    }
  );

export interface AttendeeMeetingMaterials
  extends Pick<MaterialUploads, "id" | "displayName" | "size"> {
  assetType: AssetType;
  assetExt?: string;
  url: string;
}

export interface GetAttendeeMeetingMaterialsData {
  assets: AttendeeMeetingMaterials[];
}

export interface AttendeeSlides
  extends Pick<SlideUploads, "id" | "displayName" | "size" | "context"> {
  assetType: AssetType;
  url: string;
}

export interface AssetUploadsHookProps {
  event: EventModelPopulated;
  uploadEventAssetFile(
    props: UploadEventAssetFileProps
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

export interface WebLinkEdit {
  id?: string;
  displayName?: string;
  url?: string;
}

export interface WebLinkError {
  displayName?: string;
  url?: string;
}

export const AV_ACCEPTABLE_FILE_TYPES = ["mpeg", "mp4", "quicktime", "wav", "x-wav"];
export const AV_ACCEPTABLE_FILE_INFO =
  "Files must be in mov, mp4, avi, wmv, mp3 or wav format and no larger than 1GB for video and 250MB for audio";
export const SLIDES_ACCEPTABLE_FILE_TYPES = ["pdf"];
export const SLIDES_ACCEPTABLE_FILE_INFO = "Files must be in pdf format and no larger than 50MB";
export const SLIDES_ACCEPTABLE_FILE_SIZE = 50 * FILE_SIZES.MEGABYTES;

export const AcceptableDocumentMaterialFileExtensions = [
  "pdf",
  "doc",
  "docx",
  "ms-doc",
  "msword",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const AcceptableSpreadsheetMaterialFileExtensions = [
  "xls",
  "xlsx",
  "excel",
  "vnd.ms-excel",
  "x-excel",
  "x-msexcel",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const AcceptableSlideMaterialFileExtensions = [
  "ppt",
  "pptx",
  "mspowerpoint",
  "powerpoint",
  "vnd.ms-powerpoint",
  "x-mspowerpoint",
  "vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const MATERIALS_ACCEPTABLE_FILE_TYPES = [
  ...AcceptableDocumentMaterialFileExtensions,
  ...AcceptableSlideMaterialFileExtensions,
  ...AcceptableSpreadsheetMaterialFileExtensions,
];
export const MATERIALS_ACCEPTABLE_FILE_INFO =
  "Files must be in pdf, doc, docx, pptx, ppt, xlsx or xls format and no larger than 25 MB";

export const MaterialsIDs = {
  MaterialsUploadButton: "MaterialsUploadButton",
  MaterialsFileButton: "MaterialsFileButton",
  MaterialsWebLinkButton: "MaterialsWebLinkButton",
};
