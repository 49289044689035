import { User as UserType } from "../../../../services/user/user.model";

export interface HeaderProps {
  user?: UserType;
  logoutApplication: any;
  showHistoryBack?: boolean;
  button?: any;
  updateUser?: (user: UserType) => void;
}

export enum HeaderClassNames {
  Base = "header",
  ProfileButton = "header_profile-button",
  BackButton = "header_back-button",
  UtilityMenu = "header_utility_menu",
}

export enum HeaderIDs {
  ProfileButton = "HeaderProfileButton",
}

const dataIdPrefix = "Header";

export const DATA_IDS = {
  PROFILE_BUTTON: `${dataIdPrefix}ProfileButton`,
  CHANGE_PASSWORD_BUTTON: `${dataIdPrefix}ChangePasswordButton`,
  EDIT_PROFILE_BUTTON: `${dataIdPrefix}EditProfileButton`,
  LOGOUT_BUTTON_BUTTON: `${dataIdPrefix}HeaderLogoutButton`,
};

export enum UserPopoverMenuClassNames {
  Info = "user_popover_info",
  InfoContent = "user_popover_info-content",
  Name = "user_popover_name",
  UserType = "user_popover_role",
}

export enum HeaderPopoverMenuButtonIds {
  Info = `Info`,
  EditProfile = `EDIT_PROFILE`,
  HelpCenter = `HELP_CENTER`,
  UploadZip = `HELP_CENTER_UPLOAD_ZIP`,
  ChangePassword = `CHANGE_PASSWORD`,
  LogOut = `LOG_OUT`,
}
