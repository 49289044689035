import {
  BaseComponentProps,
  CheckboxIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextboxIdModel,
} from "@q4/nimbus-ui";
import { RegistrationField } from "../../../../../services/event/registrationFieldGql.model";
import {
  CustomRegistrationFieldFormBaseEditorIdModel,
  TinyMCEEditorConfig,
} from "../baseEditor/customRegistrationFieldFormBaseEditor.definition";

export interface CustomRegistrationFieldFormConsentProps extends BaseComponentProps {
  currentRegistrationField: RegistrationField;
  totalFieldCount?: number;
  onUpdate: (field: RegistrationField) => void;
  onCancel: (field?: RegistrationField) => void;
}

export enum CustomRegistrationFieldFormConsentClassName {
  Base = "custom-registration-field-form-consent",
}

export enum CustomRegistrationFieldFormConsentLabel {
  Title = "Consent Agreement",
  Agreement = "Agreement Statement",
  Required = "Required field",
}

export const DefaultConsentRegistrationFieldStatement = "I agree";

export class CustomRegistrationFieldFormConsentIdModel extends IdModelBase {
  baseEditor: CustomRegistrationFieldFormBaseEditorIdModel;
  agreementStatement: TextboxIdModel;
  requiredCheckbox: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.baseEditor = new CustomRegistrationFieldFormBaseEditorIdModel(
      `${this.id}ConsentBaseEditor`
    );
    this.agreementStatement = new TextboxIdModel(`${this.id}AgreementStatement`);
    this.requiredCheckbox = new CheckboxIdModel(`${this.id}RequiredCheckbox`);
  }
}

export const ConsentEditorConfig: TinyMCEEditorConfig = {
  toolbar: "bold italic underline | link",
  block_formats: "Paragraph=p",
};
