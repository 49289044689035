const PASSWORD_MIN_LENGTH = 10;

export function passwordMeetsCriteria(password: string): boolean {
  return (
    passwordHasCorrectLength(password) &&
    passwordHasCapitals(password) &&
    passwordHasNumbers(password) &&
    passwordHasSpecialChar(password)
  );
}

export function passwordConfirmationMatches(
  password: string,
  confirmationPassword: string
): boolean {
  return password === confirmationPassword;
}

function passwordHasCorrectLength(password: string): boolean {
  return password?.length >= PASSWORD_MIN_LENGTH;
}
function passwordHasCapitals(password: string): boolean {
  return password?.toLowerCase() !== password;
}
function passwordHasNumbers(password: string): boolean {
  return /\d/.test(password);
}
function passwordHasSpecialChar(password: string): boolean {
  return /^(?=[^!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]*[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~])(?=\D*\d).{8,}$/.test(
    password
  );
}
