export enum ActivityState {
  Idle = "idle",
  Active = "active",
}

export interface ChannelMessageProps {
  state: ActivityState.Idle | ActivityState.Active;
}

export enum DomEventType {
  Click = "click",
  MouseMove = "mousemove",
  KeyDown = "keydown",
  DomMouseScroll = "DOMMouseScroll",
  MouseWheel = "mousewheel",
  MouseDown = "mousedown",
  TouchStart = "touchstart",
  TouchMove = "touchmove",
  Focus = "focus",
  Blur = "blur",
}

const activeEventsDefault: DomEventType[] = [
  DomEventType.Click,
  DomEventType.MouseMove,
  DomEventType.KeyDown,
  DomEventType.DomMouseScroll,
  DomEventType.MouseWheel,
  DomEventType.MouseDown,
  DomEventType.TouchStart,
  DomEventType.TouchMove,
  DomEventType.Focus,
];
const inactivityEventsDefault: DomEventType[] = [];
const ignoredEventsWhenIdleDefault: DomEventType[] = [DomEventType.MouseMove];

export interface InactivityDetectorDefaultProps {
  activeEvents: DomEventType[];
  inactivityEvents: DomEventType[];
  ignoredEventsWhenIdle: DomEventType[];
  initialState: ActivityState.Idle | ActivityState.Active;
  autoInit: boolean;
  timeToIdle: number;
  lockOnInactivity: boolean;
}

export const InactivityDetectorDefaults: InactivityDetectorDefaultProps = {
  activeEvents: [...activeEventsDefault],
  inactivityEvents: [...inactivityEventsDefault],
  ignoredEventsWhenIdle: [...ignoredEventsWhenIdleDefault],
  initialState: ActivityState.Active,
  autoInit: true,
  timeToIdle: 1000 * 120, // 2h (120 min) default
  lockOnInactivity: true,
};

export interface InactivityDetectorHookProps {
  timeToIdle: number;
  broadcastChannelId: string;
  initialState?: ActivityState.Idle | ActivityState.Active;
  autoInit?: boolean;
  activityEvents?: DomEventType[];
  inactivityEvents?: DomEventType[];
  ignoredEventsWhenIdle?: DomEventType[];
  lockOnInactivity?: boolean;
}

export interface CreateActivityMonitorProps {
  initialState: ActivityState.Idle | ActivityState.Active;
  autoInit: boolean;
}

export interface InactivityDetectorHookModel {
  userIsInactive: boolean;
}
