import moment from "moment";

export enum LogStreamTypes {
  MUTE_LOG_STREAM = "mute-log-stream",
  UNMUTE_LOG_STREAM = "unmute-log-stream",
}

function _getTimeStamp() {
  return moment().format("YY-MM-DD h:mm:ss");
}

/** Adds state to windows object */
function _registerStateToWindow(name: string, state: any) {
  try {
    (window as any).EPDebugger[name] = state;
  } catch {} //ignore
}

class DebugService {
  private statesToDebug: Record<string, any>;

  private logStreams: Record<LogStreamTypes, Array<any>>;

  constructor() {
    this.statesToDebug = {};
    this.logStreams = {} as any;

    (window as any).EPDebugger = {};
  }

  /** Start a logging stream */
  startLogStream(logStream: LogStreamTypes) {
    try {
      this.logStreams[logStream] = [];
    } catch {} //ignore
  }

  /** Add to a logging stream */
  addToLogStream(logStream: LogStreamTypes, log: string) {
    try {
      if (!this.logStreams[logStream]) this.startLogStream(logStream);

      this.logStreams[logStream].push(`${_getTimeStamp()}-${log}`);
    } catch {} //ignore
  }

  /** Clear a logging stream */
  clearLogStream(logStream: LogStreamTypes) {
    try {
      this.logStreams[logStream] = [];
    } catch {} //ignore
  }

  /** Sets a state to be debugged */
  public debugState(name: string, state: any) {
    try {
      this.statesToDebug[name] = state;
      _registerStateToWindow(name, state);
    } catch {} //ignore
  }

  /** Errors stats to console */
  public reportDebugStates(userEmail: string, logStreamsToReport: Array<LogStreamTypes> = []) {
    try {
      console.error(`Current debugging states for user: ${userEmail}`, this.statesToDebug);

      logStreamsToReport.forEach((logStream) => {
        if (logStream.length) {
          console.error(
            `Printing : ${logStream}`,
            `for user: ${userEmail}`,
            this.logStreams[logStream]
          );
        }
      });
    } catch {} //ignore
  }
}

const EPDebugService = () => {
  let instance;
  return {
    instance: () => {
      if (instance) return instance;
      instance = new DebugService();
      (window as any).EPDebugger = instance;
      return instance;
    },
  };
};

export default EPDebugService();
