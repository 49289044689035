import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { PublicEventDetails } from "../../../../services/event/eventGql.model";
import { RegistrationField } from "../../../../services/event/registrationFieldGql.model";
import { EventMicrositePageType } from "../../hooks/microsite.hook.definition";
import {
  ControlPanelBrandingState,
  OpenRegistrationSelectType,
  RegistrationBrandingState,
} from "../../micrositeModal.definition";
import { MicrositePagesPreviewIdModel } from "../micrositePagesPreview/micrositePagesPreview.definition";
import { MicrositePageConfigurationModalFormIdModel } from "./PageConfigurationModalForm/micrositePageConfigurationModalForm.definition";

export interface MicrositePagesConfigurationModalProps {
  currentPage: EventMicrositePageType;
  controlPanelState: ControlPanelBrandingState;
  currentPageState: any;
  currentPageErrorState: any;
  selectedProgessStage: number;
  showPostEventPage: boolean;
  currentEventData: PublicEventDetails;
  registrationFields: RegistrationField[];
  isGuestAttendance?: boolean;
  selectedOpenRegistration: OpenRegistrationSelectType;
  resetCurrentPageErrorState(): void;
  setCurrentPageState(state: any): void;
  registrationPageState: RegistrationBrandingState;
  currentRegistrationTab?: number;
  setCurrentRegistrationTab?: Dispatch<SetStateAction<number>>;
  registrationFieldsState?: RegistrationField[];
  setRegistrationFieldsState?: Dispatch<SetStateAction<RegistrationField[]>>;
  closeSlideOut: boolean;
  pageOrder: EventMicrositePageType[];
}

export enum MicrositePagesConfigurationModalClassNames {
  Base = "microsite-pages-configuration-modal",
  Container = "microsite-pages-configuration-modal_container",
  Grid = "microsite-pages-configuration-modal_grid",
  GridLeftColumn = "microsite-pages-configuration-modal_grid_column--left",
  GridRightColumn = "microsite-pages-configuration-modal_grid_column--right",
  PreviewImage = "microsite-pages-configuration-modal_preview-image",
  PreviewViewport = "microsite-pages-configuration-modal_preview-viewport",
  Lobby = "microsite-pages-configuration-modal_lobby",
  WaitingRoom = "microsite-pages-configuration-modal_waiting-room",
}

export class MicrositePageConfigurationModalIdModel extends IdModelBase {
  modalForm: MicrositePageConfigurationModalFormIdModel;
  micrositePreview: MicrositePagesPreviewIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.modalForm = new MicrositePageConfigurationModalFormIdModel(`${this.id}ModalForm`);
    this.micrositePreview = new MicrositePagesPreviewIdModel(`${this.id}Preview`);
  }
}

export const MicrositePageConfigurationModalPageId = "MicrositePageConfigurationModalPage";
