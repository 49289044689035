import {
  getCurrentAudioOutput,
  getAudioOutputs,
  setAudioOutput,
} from "../../../../../utils/userMediaConfig.utils";

import {
  isShareScreenStream as OTIsShareScreenStream,
  isCameraStream as OTIsCameraStream,
  isPSTNStream as OTIsPSTNStream,
} from "../../../../../opentok-react/utils/stream.utils";
import { BroadcastStream } from "../../../interfaces/broadcastStream/broadcastStream";
import { OpenTok } from "../../../../../opentok-react/types/OT.type";
import { OTPublisherType, OTStreamTypes, OTSessionType } from "../../../../../opentok-react/types";
import { Connection } from "../../../../../opentok-react/types/Connection.type";
import { IMediaDeviceInfo, IMediaStreamTrack } from "../../../../../contexts";
import {
  OT_PR_FIRST_NAME,
  OT_PR_LAST_NAME,
} from "../../../../remoteBroadcastLayout/remoteBroadcastLayout.definition";

// limits supported by OT (720p | 16:9)
export const OT_VIDEO_WIDTH = 1280;
export const OT_VIDEO_HEIGHT = 720;

export const getOpenTok = (): OpenTok => {
  return window["OT"] as unknown as OpenTok;
};

async function getAudioInputs(setAudioInputs: (inputs: IMediaDeviceInfo[]) => void): Promise<void> {
  await navigator.mediaDevices.getUserMedia({ audio: true });
  getOpenTok().getDevices((err, devices) => {
    const inputs = devices
      .filter((device) => device.kind === "audioInput")
      .map((device) => ({ titleCase: device.label, ...device })) as IMediaDeviceInfo[];

    setAudioInputs(inputs);
  });
}

async function getVideoInputs(setVideoInputs: (inputs: any) => void): Promise<void> {
  await navigator.mediaDevices.getUserMedia({
    video: {
      width: { ideal: OT_VIDEO_WIDTH },
      height: { ideal: OT_VIDEO_HEIGHT },
    },
  });
  getOpenTok().getDevices((err, devices) => {
    const inputs = devices
      .filter((device) => device.kind === "videoInput")
      .map((device) => ({ titleCase: device.label, ...device }));

    setVideoInputs(inputs);
  });
}

function getCurrentAudioInput(): IMediaStreamTrack {
  const publisher = getPublisher();
  if (publisher) {
    const audioSource = publisher.getAudioSource();
    let _audioSource = (audioSource || {}) as IMediaStreamTrack;
    _audioSource.titleCase = audioSource?.label || "";
    return _audioSource;
  }
  return {} as IMediaStreamTrack;
}

async function getCurrentVideoInput(deviceId?: string): Promise<IMediaDeviceInfo> {
  try {
    const _media = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId,
        width: { ideal: OT_VIDEO_WIDTH },
        height: { ideal: OT_VIDEO_HEIGHT },
      },
    });
    if (MediaStreamTrack && MediaStreamTrack.prototype.stop) {
      _media?.getTracks().forEach((track) => track.stop());
    }

    return (_media
      .getTracks()
      .filter((device) => device.kind === "video")
      .map((device) => ({ titleCase: device.label, ...device }))[0] || {}) as IMediaDeviceInfo;
  } catch (e) {
    console.warn("Could not get user's device information");
  }

  return {} as IMediaDeviceInfo;
}

export function getPublishers(): OTPublisherType[] {
  return getOpenTok().publishers.map((publisher) => publisher);
}

export function getPublisher(): OTPublisherType {
  // @TODO: after opentok forked and updated should use publisher object
  return getOpenTok()
    .publishers.map((publisher) => publisher)
    .find((p) => [OTStreamTypes.Camera, OTStreamTypes.Custom].includes(p?.stream?.videoType));
}

export function getECPublisher(): OTPublisherType {
  return getPublishers().find((p) => p?.stream?.name === `${OT_PR_FIRST_NAME} ${OT_PR_LAST_NAME}`);
}

export function getScreenPublisher(): OTPublisherType {
  return getPublishers().find((p) => p?.stream?.videoType === OTStreamTypes.Screen);
}

async function setAudioInput(audioInput: IMediaDeviceInfo): Promise<boolean> {
  if (!audioInput) {
    return;
  }
  const publisher = getPublisher();
  if (publisher && audioInput?.deviceId) {
    try {
      await (publisher.setAudioSource(audioInput.deviceId) as any);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

async function setVideoInput(videoInput: IMediaDeviceInfo): Promise<void> {
  // const publisher = getPublisher();
  // if (publisher && publisher?.stream?.videoType !== OTStreamTypes.Screen) {
  //   const device = await publisher.cycleVideo();
  //   if (device.deviceId !== videoInput.deviceId) setVideoInput(videoInput);
  // }
}

export function getUserDataFromConnection(connection: Connection) {
  try {
    const connectionData = JSON.parse(connection?.data);
    return connectionData;
  } catch (err) {
    return null;
  }
}

export const OpenTokUtils = {
  getAudioInputs,
  getAudioOutputs,
  getVideoInputs,
  getCurrentAudioInput,
  getCurrentAudioOutput,
  getCurrentVideoInput,
  setAudioInput,
  setAudioOutput,
  setVideoInput,
};

export const isCameraStream = (stream: BroadcastStream): boolean =>
  OTIsCameraStream(stream?.getVendorStream());

export const isShareScreenStream = (stream: BroadcastStream): boolean =>
  OTIsShareScreenStream(stream?.getVendorStream());

export const isPSTNStream = (stream: BroadcastStream): boolean =>
  OTIsPSTNStream(stream?.getVendorStream());

export const isTempStream = (stream: BroadcastStream): boolean =>
  stream?.id === stream?.connection?.connectionId;

export const subscribeToAllAudioStreamsInSession = (
  session: OTSessionType,
  streams: BroadcastStream[]
) => {
  streams?.forEach((stream) => {
    if (!stream?.isLocalPublisher && !isShareScreenStream(stream)) {
      const subscribers = session?.getSubscribersForStream(stream?.getVendorStream());
      subscribers?.forEach((subscriber) => subscriber?.subscribeToAudio(true));
    }
  });
};
