import React, { useCallback, useContext, useMemo } from "react";
import {
  EventPermission,
  EventPermissionDictionary,
} from "../../../../configurations/userRolesPermissions.configuration";
import { AdminConsoleContext } from "../../../../contexts";
import { open } from "../../../../utils/ui.utils";
import { buildAdminPrivateSlidesUrl } from "../../../slidesPreview/utils/slidesPreview.util";
import { VideoWrapperIds } from "../../components/VideoWrapper/VideoWrapper.definition";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { useStreamGroupManagement } from "./useStreamGroupManagement.hook";

export interface AssetsPopoverMenuProps {
  meetingId: number;
  stream: BroadcastStream;
  userEventPermissions: EventPermissionDictionary;
  assetPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmRemoveFromStage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useAssetStreamMenu = (props: AssetsPopoverMenuProps) => {
  const { stream, userEventPermissions, confirmRemoveFromStage, assetPreviewModal, meetingId } =
    props;

  const {
    carouselSelectionService: { isStaged },
  } = useContext(AdminConsoleContext);

  const {
    currentSelectedGroup,
    subGroups,
    hasGroupsForAdding,
    isRootGroup,
    handleRemoveFromGroup,
  } = useStreamGroupManagement({ stream });

  /** Can Manage Stream */
  const canManageStream = userEventPermissions?.[EventPermission.eventManageStreams];

  /** Remove Stream */
  const canRemoveStream = canManageStream && isStaged?.(stream);

  const openPreviewModal = useCallback(
    (setState: (state: boolean) => void) => {
      return () => {
        if (stream.isSlideAsset) {
          const shareableLink = false;
          return window.open(
            buildAdminPrivateSlidesUrl(
              meetingId,
              stream.isV2AssetStream ? stream.id : stream.name,
              shareableLink
            ),
            "_blank"
          );
        }
        setState(true);
      };
    },
    [stream, meetingId]
  );

  const menu = useMemo(() => {
    const menuAssetsOptions = [
      // Add To Groups BTN
      canManageStream &&
        hasGroupsForAdding && {
          id: "group-selection",
          icon: "q4i-add-4pt",
          label: "Add to group",
          options: subGroups,
        },
      // Remove From Group BTN
      canManageStream &&
        hasGroupsForAdding &&
        !isRootGroup && {
          id: `remove-${currentSelectedGroup.identifier}-group`,
          icon: "q4i-close-4pt",
          label: "Remove from group",
          onClick: () => handleRemoveFromGroup(currentSelectedGroup.identifier),
        },
      // Unstage BTN
      canRemoveStream && {
        icon: "q4i-share-screen-off-filled",
        id: VideoWrapperIds.UnstageMenuItem,
        label: "Unstage",
        onClick: open(confirmRemoveFromStage),
      },
      // Preview BTN
      canManageStream && {
        icon: stream.isSlideAsset ? "q4i-link_4pt" : "q4i-play-filled",
        id: VideoWrapperIds.AssetMenuItem,
        label: "Preview",
        onClick: openPreviewModal(assetPreviewModal),
      },
    ];

    return menuAssetsOptions.filter(Boolean);
  }, [
    canManageStream,
    canRemoveStream,
    currentSelectedGroup.identifier,
    hasGroupsForAdding,
    isRootGroup,
    stream.isSlideAsset,
    subGroups,
    assetPreviewModal,
    confirmRemoveFromStage,
    handleRemoveFromGroup,
    openPreviewModal,
  ]);

  return { menu };
};
