import "./loadingScreen.component.scss";
import { memo, useContext, useEffect } from "react";
import { LoadingScreenProps } from "./loadingScreen.definition";
import { LoadingContext } from "../../contexts/loading/loading.context";

export const LoadingScreen = (props: LoadingScreenProps): JSX.Element => {
  const { setLoadingIcon, setShowLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoadingIcon?.(props.q4Icon ?? "");
    setShowLoading?.(true);
    return () => {
      setLoadingIcon?.("");
      setShowLoading?.(false);
    };
  }, [props.q4Icon, setLoadingIcon, setShowLoading]);

  return null;
};

export default memo(LoadingScreen);
