import React, { useCallback } from "react";
import { AssetPortalElementPrefixes, AssetPortalElementProps } from "./assetElement.definition";
import { AssetBroadcastStream } from "../../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";

export default function AudioAssetElement(props: AssetPortalElementProps) {
  const { asset, onAssetRendered } = props;

  const setAudioRef = useCallback(
    (ref) => {
      const assetBroadcastStream = new AssetBroadcastStream({
        ...asset,
        elementRef: ref,
      });
      if (ref)
        ref.onloadstart = assetBroadcastStream.markStreamSubscribed.bind(assetBroadcastStream);
      onAssetRendered(assetBroadcastStream);
    },
    [asset, onAssetRendered]
  );

  return asset?.url ? (
    <audio
      key={`${AssetPortalElementPrefixes.audio}_${asset.id}`}
      data-id={`${AssetPortalElementPrefixes.audio}_${asset.id}`}
      src={asset.url}
      crossOrigin={"anonymous"}
      autoPlay={false}
      ref={setAudioRef}
      controls
    />
  ) : null;
}
