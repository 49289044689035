import React, { useMemo } from "react";
import { CustomThumbnail } from "../../../../components/customThumbnail/customThumbnail.component";
import {
  AVATAR_DEFAULT_COLOUR,
  CustomAvatarIcon,
  CustomAvatarProps,
  CustomAvatarSizes,
} from "../../../../components/customAvatar/customAvatar.definition";
import config from "../../../../config";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";

export enum ThumbnailAltText {
  Audio = "Audio placeholder",
  Speaker = "Speaker placeholder",
  PSTN = "PSTN placeholder",
  Custom = "Custom Speaker placeholder",
}

export interface StreamThumbnailHookProps {
  stream: BroadcastStream;
  useV2?: boolean;
  displayName?: string;
  size?: string | CustomAvatarSizes;
  classNames?: {
    wrapper?: string;
    content?: string;
  };
}

export const useStreamThumbnail = (props: StreamThumbnailHookProps) => {
  const { stream, useV2 = false, displayName, size = CustomAvatarSizes.Small, classNames } = props;

  const v2Thumbnail = useMemo(() => {
    const colour = stream?.isPSTNStream && !displayName ? AVATAR_DEFAULT_COLOUR : undefined;
    const icon = stream?.isPSTNStream ? CustomAvatarIcon.Phone : null;

    const thumbnailProps: CustomAvatarProps = {
      name: displayName,
      size,
      classNames,
    };

    if (colour) thumbnailProps.colour = colour;
    if (icon) thumbnailProps.icon = icon;

    return <CustomThumbnail {...thumbnailProps} />;
  }, [displayName, size, stream, classNames]);

  if (stream?.isVideoAsset || stream?.isSlideAsset || stream?.isScreenShare) return null;

  if (stream?.isAudioAsset)
    return <img src={config.adminConsole.streamThumbnails.audio} alt={ThumbnailAltText.Audio} />;

  if (stream?.isPSTNStream && !useV2)
    return <img src={config.adminConsole.streamThumbnails.pstn} alt={ThumbnailAltText.PSTN} />;

  if (stream?.hasVideo) return null;

  return useV2 ? (
    v2Thumbnail
  ) : (
    <img src={config.adminConsole.streamThumbnails.camera} alt={ThumbnailAltText.Speaker} />
  );
};
