import { useCallback, useEffect, useRef, useState } from "react";

import { DominantSpeakerHookModel, DominantSpeakerHookProps } from "./dominantSpeaker.definition";

import { isStreamLocalPublisher, isShareScreenStream } from "../../utils/stream.utils";
import { throttle } from "lodash";
import { BroadcastStream } from "../../../views/adminConsole/interfaces/broadcastStream/broadcastStream";
import { useSensorFilter } from "../../utils/useSensorFilter";

const UPDATE_DOMINANT_SPEAKER_SIGNAL = "OT_UPDATE_DOMINANT_SPEAKER";

function currentDominantSpeakerDroppedOrMuted(
  streams: BroadcastStream[],
  dominantStreamId: string
): boolean {
  const dominantStream = streams.find((stream) => stream.id === dominantStreamId);
  if (!dominantStream?.hasAudio) return true;
}

export default function useDominantSpeakerHook(
  props: DominantSpeakerHookProps
): DominantSpeakerHookModel {
  const dominantSpeakerStreamId = useRef<string>();
  const dominantSpeakerUpdateTime = useRef<number>(null);

  const canUpdateDominantSpeaker = useRef(true);
  const streamUnderAnalysis = useRef<string>();

  const {
    streams,
    OTSession,
    dominantSpeakerContainer,
    updateUI = false,
    onDominantSpeakerChange,
  } = props;

  const streamsRef = useRef<Array<BroadcastStream>>(streams);

  const [streamAudioLevelReading, setStreamAudioLevelReading] = useState(0);
  const { average: averagedStreamAudioLevel } = useSensorFilter(streamAudioLevelReading);

  useEffect(() => {
    listenForSignalToUpdateDominantSpeaker();
  }, [OTSession]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (OTSession && streams.length > 0) {
      streamsRef.current = streams;
      registerAudioLevelUpdatedEventOnStreams();
    }
  }, [streams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      OTSession &&
      currentDominantSpeakerDroppedOrMuted(streams, dominantSpeakerStreamId.current)
    ) {
      removeDominantSpeaker();
    }
  }, [streams]); // eslint-disable-line react-hooks/exhaustive-deps

  const activity = useRef(null);
  const inactivityTime = useRef(null);

  useEffect(
    function determineDominantSpeaker() {
      const now = Date.now();

      if (averagedStreamAudioLevel > 0.05) {
        // if audio level high, reset inactivity counter
        inactivityTime.current = null;

        if (!activity.current) {
          activity.current = { timestamp: now, talking: false };
        } else if (
          // if audio level high (active) for more than 0.5 seconds
          now - activity.current.timestamp > 500 &&
          // each user holds dominant speaker for min 2 seconds
          now - dominantSpeakerUpdateTime.current > 2000
        ) {
          activity.current.talking = true;

          if (
            !currentDominantSpeakerDroppedOrMuted(streamsRef.current, streamUnderAnalysis.current)
          ) {
            updateDominantSpeaker({
              streamId: streamUnderAnalysis.current,
              updateTime: now,
              broadcastUpdate: true,
            });
          }
        }
      } else {
        // if audio level low, start inactive counter
        if (!inactivityTime.current) inactivityTime.current = now;

        // if audio level low (inactive) for more than 3 seconds
        if (activity.current?.talking && now - inactivityTime.current > 3000) {
          if (dominantSpeakerStreamId.current === streamUnderAnalysis.current) {
            removeDominantSpeaker();
          }
          activity.current = null;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [averagedStreamAudioLevel]
  );

  const signalAllStreamsToUpdateDominantSpeaker = useCallback(
    (streamId?: string, updateTime?: number) => {
      const payload = streamId ? JSON.stringify({ streamId, updateTime }) : "undefined";
      OTSession?.signal({
        type: UPDATE_DOMINANT_SPEAKER_SIGNAL,
        data: payload,
      });
    },
    [OTSession]
  );

  const removeDominantSpeaker = useCallback(
    (broadcastUpdate = true) => {
      // Current dominant speaker has become inactive
      onDominantSpeakerChange(null);

      dominantSpeakerStreamId.current = null;
      dominantSpeakerUpdateTime.current = null;

      if (broadcastUpdate) signalAllStreamsToUpdateDominantSpeaker(null);
    },
    [onDominantSpeakerChange, signalAllStreamsToUpdateDominantSpeaker]
  );

  const updateDominantSpeakerUI = useCallback(
    (streamId: string, broadcastUpdate?: boolean, updateTime?: number) => {
      if (!canUpdateDominantSpeaker.current) return;

      const subscriberContainerId = `OT-Subscriber-Container_${streamId}`;
      const subscriberContainer = document.getElementById(subscriberContainerId);

      if (dominantSpeakerContainer.current && subscriberContainer) {
        dominantSpeakerStreamId.current = streamId;
        dominantSpeakerUpdateTime.current = updateTime;

        if (broadcastUpdate) signalAllStreamsToUpdateDominantSpeaker(streamId, updateTime);

        dominantSpeakerContainer.current.innerHTML = "";
        dominantSpeakerContainer.current.append(subscriberContainer);
        const dominantStream = streamsRef.current.find((stream) => stream.id === streamId);
        if (isStreamLocalPublisher(dominantStream?.getVendorStream()))
          onDominantSpeakerChange(dominantStream);
      }
    },
    [dominantSpeakerContainer, onDominantSpeakerChange, signalAllStreamsToUpdateDominantSpeaker]
  );

  const updateDominantSpeaker = useCallback(
    ({
      streamId,
      updateTime,
      broadcastUpdate,
    }: {
      streamId?: string;
      broadcastUpdate?: boolean;
      updateTime?: number;
    }) => {
      if (updateUI) return updateDominantSpeakerUI(streamId, broadcastUpdate, updateTime);

      dominantSpeakerStreamId.current = streamId;
      dominantSpeakerUpdateTime.current = updateTime;

      if (broadcastUpdate) signalAllStreamsToUpdateDominantSpeaker(streamId, updateTime);

      const dominantStream = streamsRef.current.find((stream) => stream.id === streamId);
      onDominantSpeakerChange(dominantStream);
    },
    [
      onDominantSpeakerChange,
      signalAllStreamsToUpdateDominantSpeaker,
      updateDominantSpeakerUI,
      updateUI,
    ]
  );

  const registerAudioLevelUpdatedEventOnStreams = useCallback(() => {
    streamsRef.current.forEach((stream) => {
      // only register audio event on your own publishing stream. when algorithm determines you are the dominant audio stream
      // you will signal all other streams on the call that you are dominant audio stream
      if (
        isStreamLocalPublisher(stream.getVendorStream()) &&
        !isShareScreenStream(stream.getVendorStream())
      ) {
        const streamPublisher = OTSession.getPublisherForStream(stream.getVendorStream());
        streamPublisher?.off("audioLevelUpdated");
        streamPublisher?.on(
          "audioLevelUpdated",
          throttle((event) => {
            setStreamAudioLevelReading(event?.audioLevel || 0);
          }, 300)
        );

        streamUnderAnalysis.current = stream.id;
      }
    });
  }, [OTSession]);

  const listenForSignalToUpdateDominantSpeaker = useCallback(() => {
    OTSession?.on(`signal:${UPDATE_DOMINANT_SPEAKER_SIGNAL}`, (event) => {
      const payload = event.data;

      if (payload === "undefined") {
        const broadcastUpdate = false;
        return removeDominantSpeaker(broadcastUpdate);
      }

      const { streamId, updateTime } = JSON.parse(payload);
      if (dominantSpeakerStreamId.current !== streamId) {
        return updateDominantSpeaker({ streamId, updateTime, broadcastUpdate: false });
      }
    });
  }, [OTSession, removeDominantSpeaker, updateDominantSpeaker]);

  return {};
}
