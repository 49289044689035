import { OTPublisherProps } from "../../components/OTPublisher/OTPublisher.definition";
import { OTStreamType, OTPublisherType } from "../../types/index";

export interface ShareScreenPublisherHookProps {
  session: any;
  onSharePublisherCreated: (publisher: OTPublisherType) => void;
  onStreamCreated: (stream: OTStreamType) => void;
  onStreamDestroyed: (stream: OTStreamType) => void;
  isScreenShareAllowed?: boolean;
  onScreenShareNotAllowed?: () => void;
}

export interface ShareScreenPublisherHookModel {
  shareScreenPublisher: OTPublisherType;
  createShareScreenPublisher: (props: OTPublisherProps) => void;
  destroyShareScreenPublisher: () => void;
}

export enum ShareScreenErrorCodes {
  HARDWARE_PERMISSION_DENIED = 1500,
}
