import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { AssetType, SlideUploads } from "../assetUploads/assetUploads.definition";

interface PresenterControlledSlides
  extends Pick<SlideUploads, "id" | "displayName" | "size" | "context"> {
  assetType: AssetType;
  url: string;
}
export interface GetPresenterControlledSlidesQueryData {
  getPresenterControlledSlides: PresenterControlledSlides;
}

export interface GetUserControlledSlidesQueryVars {
  meetingId: number;
}

export const GET_PRESENTER_CONTROLLED_SLIDES = gql`
  query ($meetingId: Int) {
    getPresenterControlledSlides(meetingId: $meetingId)
  }
`;

export function useGetPresenterControlledSlidesQuery<
  D = GetPresenterControlledSlidesQueryData,
  V = GetUserControlledSlidesQueryVars
>(options?: QueryHookOptions<D, V>) {
  return useQuery<D, V>(GET_PRESENTER_CONTROLLED_SLIDES, options);
}
