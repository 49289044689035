import { SelectionModelV2 } from "../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { Layouts } from "../layoutBuilder.view";

/**
 * useEffect() uses the result of this function to determine if a
 * re-render is needed. By capturing a string of all the steam ids,
 * useEffect() will trigger whenever the contents of the stage change
 * @param streams streams being staged
 */
export function getStagedStreamIds(streams: BroadcastStream[]): string {
  const streamIds = streams.reduce((result, stream) => {
    if (stream) result.push(stream.id);
    return result;
  }, []);

  return streamIds.join(",");
}

export function getStagedStreamIdsV2(selection: SelectionModelV2): string {
  return selection?.selection?.reduce((streamIds, { id }) => [...streamIds, id], []).join(",");
}

export function getLayoutToBuild(mediaSelection, cameraSelection, isPreview): Layouts {
  // Covers edge case where switching from media -> speaker too quickly
  // results in mediaSelection = [ undefined ]
  if (mediaSelection?.length > 0 && mediaSelection[0]) return Layouts["MEDIA_LAYOUT"];
  if (cameraSelection?.length > 0) return Layouts["SPEAKER_LAYOUT"];
  if (!isPreview && mediaSelection?.length === 0 && cameraSelection.length === 0) {
    return Layouts["PLACEHOLDER_LAYOUT"];
  }

  return null;
}
