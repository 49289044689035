import { getClassName } from "@q4/nimbus-ui";
import { useState } from "react";

export const useClassToggle = ({
  className,
  toggleAbleClassName = `${className}--visible`,
  initialState = false,
}) => {
  const [state, toggleState] = useState(initialState);

  const toggledClassName = getClassName(className, [
    {
      condition: state,
      trueClassName: toggleAbleClassName,
    },
  ]);

  return {
    toggle: toggleState,
    toggledClassName,
  };
};
