import { SavedEventBrandingType } from "./microsite.hook.definition";
import {
  EventActionTypes,
  EventActionTypes as EventBrandingActionTypes,
} from "../../../components/event/modal/hooks/eventModal.hook";
import { cloneDeep, set, unset } from "lodash";
import { Dispatch, useReducer } from "react";

export interface EventBrandingReducerAction {
  type: EventBrandingActionTypes;
  newBrandingState?: any;
  path?: string | string[];
}

const handlePut = (
  currentBrandingState: SavedEventBrandingType,
  newBrandingState,
  path: string | string[]
): SavedEventBrandingType => {
  const currentBrandingStateClone = cloneDeep(currentBrandingState);

  set(currentBrandingStateClone, path, newBrandingState);

  return currentBrandingStateClone;
};

const handleRemove = (
  currentBrandingState: SavedEventBrandingType,
  path: string | string[]
): SavedEventBrandingType => {
  const currentBrandingStateClone = cloneDeep(currentBrandingState);

  unset(currentBrandingStateClone, path);

  return currentBrandingStateClone;
};

const eventBrandingReducer = (
  currentBrandingState: SavedEventBrandingType,
  { type, newBrandingState, path }: EventBrandingReducerAction
): SavedEventBrandingType => {
  switch (type) {
    case EventActionTypes.RESET:
      return newBrandingState;
    case EventActionTypes.PUT:
      return handlePut(currentBrandingState, newBrandingState, path);
    case EventActionTypes.REMOVE:
      return handleRemove(currentBrandingState, path);
  }
};

export const useEventBrandingReducer = (
  data: SavedEventBrandingType
): [SavedEventBrandingType, Dispatch<EventBrandingReducerAction>] => {
  const [eventBrandingPayload, eventBrandingDispatch]: [
    SavedEventBrandingType,
    Dispatch<EventBrandingReducerAction>
  ] = useReducer(eventBrandingReducer, data);

  return [eventBrandingPayload, eventBrandingDispatch];
};
