import { User as UserType } from "../../../services/user/user.model";

export enum ChangePasswordClassNames {
  Base = "change-password",
  Background = "change-password_backgroundImage",
  Button = "change-password_button",
  Form = "change-password_form",
  ForgotPassword = "change-password_forgotPassword",
  Icon = "change-password_icon",
  Inner = "change-password_inner",
  Password = "change-password_password",
  ShowPassword = "change-password_showPassword",
  Submit = "change-password_submit",
}

export interface ChangePasswordProps {
  user: UserType;
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (data) => Promise<boolean>;
  loading?: boolean;
}

const dataIdPrefix = "ChangePasswordModal";

export const DATA_IDS = {
  CURRENT_PASSWORD: `${dataIdPrefix}CurrentPasswordInput`,
  NEW_PASSWORD: `${dataIdPrefix}NewPasswordInput`,
  CONFIRM_PASSWORD: `${dataIdPrefix}ConfirmPasswordInput`,
  SAVE_BUTTON: `${dataIdPrefix}SaveButton`,
  CANCEL_BUTTON: `${dataIdPrefix}CancelButton`,
  CLOSE_BUTTON: `${dataIdPrefix}CloseButton`,
};
