import { IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { EventRegistrationProps } from "../../eventRegistration.definition";

export interface EventRegistrationSplitLayoutProps extends EventRegistrationProps {
  renderChildren?: () => JSX.Element;
}

export enum EventRegistrationSplitLayoutClassNames {
  Base = "event-registration-split-layout",
  Wrapper = "event-registration-split-layout_wrapper",
  Description = "event-registration-split-layout_description",
  LeftColumn = "event-registration-split-layout_left",
  LeftColumnFooter = "event-registration-split-layout_left_footer",
  LeftColumnContent = "event-registration-split-layout_left_content",
  RightColumn = "event-registration-split-layout_right",
  RightColumnScrollbar = "event-registration-split-layout_right_scrollbar",
  RightColumnContent = "event-registration-split-layout_right_content",
  RightColumnWithCenteredModifier = "event-registration-split-layout_right--centered",
  Footer = "event-registration-split-layout_footer",
}

export class EventRegistrationSplitLayoutIdModel extends IdModelBase {
  title: string;
  description: string;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = `${this.id}Title`;
    this.description = `${this.id}Descriptions`;
  }
}
