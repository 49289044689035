import { useContext, useMemo } from "react";
import { EventPermission } from "../../../../configurations/userRolesPermissions.configuration";
import { AdminConsoleContext } from "../../../../contexts";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { CarouselStreamWrapperProps } from "../../components/StreamWrapper/streamWrapper.definition";
import { VideoWrapperClassNames } from "../../components/VideoWrapper/VideoWrapper.definition";
import { canControlAudio, useStreamAudioControls } from "./useStreamAudioControls.hook";

export const useStreamMenu = (props: CarouselStreamWrapperProps) => {
  const {
    broadcasting,
    participant,
    stream,
    userEventPermissions,
    streamMenuActions: { onRemoveStream, setSpeakerAudio },
  } = props;

  const {
    carouselSelectionService: { isStaged },
  } = useContext(AdminConsoleContext);

  const { isMuted, toggleStreamAudio } = useStreamAudioControls({
    broadcasting,
    stream,
    setSpeakerAudio,
    participant,
    isStaged,
  });

  const menuItems = useMemo(() => {
    if (stream?.isTempStream) return [];

    const audioControllable = canControlAudio(stream, participant as Participant);
    const canUnstageStream =
      userEventPermissions?.[EventPermission.eventManageStreams] && isStaged(stream);
    const canManageAudio =
      userEventPermissions?.[EventPermission.eventManageOthers] || stream?.isLocalPublisher;
    const isScreenShare = stream.type === "screen";

    const menu = [
      !isScreenShare &&
        canManageAudio && {
          icon: isMuted ? VideoWrapperClassNames.MicFilled : VideoWrapperClassNames.MicOffFilled,
          id: "MuteStream",
          label: isMuted ? "Unmute" : "Mute",
          disabled: !audioControllable,
          onClick: toggleStreamAudio,
        },
      canUnstageStream && {
        icon: "q4i-share-screen-off-filled",
        id: "UnStage",
        label: "Unstage",
        onClick: () => onRemoveStream(stream),
      },
    ];

    return menu.filter(Boolean);
  }, [
    isMuted,
    participant,
    stream,
    userEventPermissions,
    isStaged,
    onRemoveStream,
    toggleStreamAudio,
  ]);

  return {
    menu: menuItems,
  };
};
