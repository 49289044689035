import { MutationFunctionOptions } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef } from "react";
import retry from "retry";
import {
  CreateParticipantMutationVars,
  MEDIA_SYNC_INTERVAL,
  Participant,
  ParticipantMediaSettings,
  ParticipantSignalEnum,
  ParticipantStatusEnum,
  RETRY_DEFAULTS,
  useCreateParticipantMutation,
  useParticipantCreatedSubscription,
  useParticipantMediaSettingsSubscription,
  useParticipantNameSubscription,
  useParticipantSignalMutation,
  useParticipantStatusSubscription,
  useParticipantsQuery,
  useUpdateParticipantMediaSettingsMutation,
  useUpdateParticipantNameMutation,
  useUpdateParticipantStatusMutation,
} from "./participants.hook.definition";
import {
  AllParticipantsSignalEnum,
  useAllParticipantsSignalMutation,
} from "./participants.hook.signal";

import { useStateRef } from "@q4/nimbus-ui";
import EPDebugService, {
  LogStreamTypes,
} from "../../services/eventsPlatformDebugger/epDebug.service";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";
interface ParticipantsHookProps {
  meetingId: number;
  skip?: boolean;
}

export default function useParticipants(props: ParticipantsHookProps) {
  const { meetingId, skip = false } = props;
  const [participants, participantsRef, setParticipants] = useStateRef<Partial<Participant>[]>([]);
  const [currentParticipant, currentParticipantRef, setCurrentParticipant] =
    useStateRef<Partial<Participant>>(null);

  const syncMediaSettingsInterval = useRef(null);

  const participantsByConnectionId = useMemo(
    () =>
      participants.reduce((byConnectionIdMap, participant) => {
        byConnectionIdMap[participant.connection?.connectionId] = participant;
        return byConnectionIdMap;
      }, {}),
    [participants]
  );

  const [
    getInitialParticipants,
    { loading: queriedParticipantsLoading, data: participantsQueryData  },
  ] = useParticipantsQuery();

  const onParticipantCreated = useCallback(
    ({ data: subscriptionData }) => {
      const newParticipant = subscriptionData?.data?.onParticipantCreated;
      if (participants.find((participant) => participant.id === newParticipant.id)) return;
      setParticipants([...participants, newParticipant]);
    },
    [participants, setParticipants]
  );

  useParticipantCreatedSubscription({
    variables: {
      meetingId,
    },
    skip,
    onData: onParticipantCreated,
  });

  const onParticipantStatusUpdated = useCallback(
    ({ data: subscriptionData }) => {
      const updatedParticipant = subscriptionData?.data?.onParticipantStatusUpdated;
      if (updatedParticipant?.status === ParticipantStatusEnum.INACTIVE) {
        const newParticipants = participants.filter(
          (participant) => participant.id !== updatedParticipant.id
        );
        setParticipants(newParticipants);
      } else if (updatedParticipant?.status === ParticipantStatusEnum.ACTIVE) {
        setParticipants(
          participants
            .filter((participant) => participant.id !== updatedParticipant.id)
            .concat(updatedParticipant)
        );
      }
    },
    [participants, setParticipants]
  );

  useParticipantStatusSubscription({
    variables: {
      meetingId,
    },
    skip,
    onData: onParticipantStatusUpdated,
  });

  const onUpdateParticipantName = useCallback(
    ({ data: subscriptionData }) => {
      const updatedParticipant = subscriptionData?.data?.onParticipantNameUpdated;
      if (!updatedParticipant) return;
      const updatedParticipants = participants?.map((participant) => {
        if (participant?.id === updatedParticipant.id) {
          return {
            ...participant,
            customName: updatedParticipant?.customName,
          };
        }
        return participant;
      });
      setParticipants(updatedParticipants);
    },
    [participants, setParticipants]
  );

  useParticipantNameSubscription({
    variables: {
      meetingId,
    },
    skip,
    onData: onUpdateParticipantName,
  });

  const onParticipantMediaSettingsUpdated = useCallback(
    ({ data: subscriptionData }) => {
      const updatedParticipant = subscriptionData?.data?.onParticipantMediaSettingsUpdated;
      if (!updatedParticipant) return;
      const updatedParticipants = participants?.map((participant) => {
        if (participant?.id === updatedParticipant.id) {
          return {
            ...participant,
            mediaSettings: updatedParticipant?.mediaSettings,
          };
        }
        return participant;
      });
      setParticipants(updatedParticipants);
    },
    [participants, setParticipants]
  );

  useParticipantMediaSettingsSubscription({
    variables: {
      meetingId,
    },
    skip,
    onData: onParticipantMediaSettingsUpdated,
  });

  const [createParticipantMutation] = useCreateParticipantMutation();
  const [updateParticipantStatusMutation] = useUpdateParticipantStatusMutation();
  const [updateParticipantNameMutation] = useUpdateParticipantNameMutation();
  const [updateParticipantMediaSettingsMutation] = useUpdateParticipantMediaSettingsMutation();
  const [signalParticipantMutation] = useParticipantSignalMutation();
  const [signalAllParticipantsMutation] = useAllParticipantsSignalMutation();

  const queriedParticipants = participantsQueryData?.getParticipants;

  useEffect(
    function initializeParticipants() {
      if (queriedParticipantsLoading || skip || participants.length) return;

      if (queriedParticipants) {
        setParticipants(queriedParticipants);
      } else {
        getInitialParticipants({
          variables: {
            meetingId,
          },
        });
      }
    },
    [
      meetingId,
      skip,
      getInitialParticipants,
      queriedParticipants,
      queriedParticipantsLoading,
      setParticipants,
      participants
    ]
  );

  useEffect(
    function initializeStateDebugger() {
      // const current = participants.find((p) => p?.id === currentParticipant?.id);
      EPDebugService.instance().debugState(
        "participantMediaSettings",
        currentParticipant?.mediaSettings
      );
    },
    [currentParticipant]
  );

  function getParticipantByConnectionId(connectionId: string) {
    return participantsRef?.current?.find(
      (participant) => participant.connection?.connectionId === connectionId
    );
  }

  function getParticipantByEmail(email: string) {
    return participants
      .filter((participant) => participant?.email === email)
      .reduce((foundParticipant: any, participant) => {
        foundParticipant = {
          ...participant,
          customName: participant?.customName || foundParticipant?.customName || participant?.name,
        };
        return foundParticipant;
      }, {});
  }

  const getParticipantById = useCallback(
    (id: string): Partial<Participant> => {
      return participants.find((participant) => participant?.id === id);
    },
    [participants]
  );

  async function createParticipant(
    variables: CreateParticipantMutationVars,
    mutationOptions?: MutationFunctionOptions
  ) {
    const createdParticipant: any = await createParticipantMutation({
      ...mutationOptions,
      variables: { ...variables },
    });
    setCurrentParticipant(createdParticipant?.data?.createParticipant);
    return createdParticipant?.data?.createParticipant;
  }
  async function updateParticipantStatus(participantId: string, status: ParticipantStatusEnum) {
    return await updateParticipantStatusMutation({
      variables: { meetingId, participantId, status },
    });
  }

  async function updateParticipantName(participantId: string, customName: string) {
    return await updateParticipantNameMutation({
      variables: { meetingId, participantId, customName },
    });
  }

  async function signalParticipant(participantId: string, signalType: ParticipantSignalEnum) {
    return await signalParticipantMutation({ variables: { meetingId, participantId, signalType } });
  }

  async function signalAllParticipants(signalType: AllParticipantsSignalEnum) {
    return await signalAllParticipantsMutation({ variables: { meetingId, signalType } });
  }

  async function updateParticipantMediaSettings(
    participantId: string,
    mediaSettings: ParticipantMediaSettings
  ) {
    const operation = retry.operation(RETRY_DEFAULTS);

    _logParticpantMediaSettings(mediaSettings);

    operation.attempt(async () => {
      try {
        await updateParticipantMediaSettingsMutation({
          variables: { meetingId, participantId, ...mediaSettings },
        });

        setCurrentParticipant((currentParticipant) => ({
          ...(currentParticipant || {}),
          mediaSettings: {
            ...(currentParticipant?.mediaSettings || ({} as any)),
            ...(mediaSettings || ({} as any)),
          },
        }));
      } catch (err) {
        console.error(
          `Unable to set participant media settings for ${currentParticipant?.email}`,
          err
        );
        operation.retry(err);
      }
    });
  }

  function _logParticpantMediaSettings(mediaSettings: ParticipantMediaSettings) {
    const logStream = mediaSettings.hasAudio
      ? LogStreamTypes.UNMUTE_LOG_STREAM
      : LogStreamTypes.MUTE_LOG_STREAM;

    EPDebugService.instance().addToLogStream(
      logStream,
      `setting participant's media settings: hasAudio: ${mediaSettings?.hasAudio}, audioInputsready: ${mediaSettings?.audioInputsReady}`
    );
  }

  function _syncMediaSettingsWithStream(getStreamByConnectionId: (id: string) => BroadcastStream) {
    const { connection, mediaSettings } = currentParticipantRef.current || {};

    if (!connection?.connectionId) return;

    const stream = getStreamByConnectionId(connection.connectionId);

    if (!stream) return;

    const { hasAudio } = stream;
    const { hasAudio: participantHasAudio } = mediaSettings || {};

    const discrepancy = {} as ParticipantMediaSettings;

    if (hasAudio !== participantHasAudio) {
      discrepancy.hasAudio = hasAudio;
    }

    if (Object.keys(discrepancy).length) {
      updateParticipantMediaSettings(currentParticipantRef?.current?.id, discrepancy);
    }
  }

  function syncParticipantMediaSettingsWithStream(
    getStreamByConnectionId: (id: string) => BroadcastStream
  ) {
    if (syncMediaSettingsInterval.current) clearInterval(syncMediaSettingsInterval.current);

    syncMediaSettingsInterval.current = setInterval(
      () => _syncMediaSettingsWithStream(getStreamByConnectionId),
      MEDIA_SYNC_INTERVAL
    );
  }

  return {
    participants,
    participantsByConnectionId,
    currentParticipant,
    currentParticipantRef,
    setCurrentParticipant,
    getParticipantById,
    getParticipantByConnectionId,
    getParticipantByEmail,
    createParticipant,
    signalParticipant,
    signalAllParticipants,
    updateParticipantStatus,
    updateParticipantName,
    updateParticipantMediaSettings,
    syncParticipantMediaSettingsWithStream,
  };
}
