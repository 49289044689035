import { createBrowserHistory } from "history";
import React from "react";
import { ConfigProvider as ColorProvider } from "react-avatar";
import ReactDOM from "react-dom";
import ApolloWrapper from "./apollo/apollo-wrapper";
import { AVATAR_COLOURS } from "./components/customAvatar/customAvatar.definition";
import { AppProvider, AttendeesProvider, EventProvider } from "./contexts";
import { Auth0Provider } from "./contexts";
import { ServicesProvider } from "./contexts";
import { SubscriptionsProvider } from "./contexts/subscriptions";
import { FeatureFlagsProvider } from "./contexts/featureFlags";
import { initDDLogs } from "./integrations/setupDDrum";
import { applyPolyfills } from "./polyfills/polyfills";
import * as serviceWorker from "./serviceWorker";
import { LoadingProvider } from "./contexts/loading/loading.context";
import "./styles/index.scss";
import App from "./views/app/app.view";
import { Router } from "react-router-dom";

applyPolyfills();

const history = createBrowserHistory();

// When react-create-app builds application, NODE_ENV == 'production'
if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test") {
  initDDLogs();
}

(() => {
  ReactDOM.render(
    <AppProvider>
      <Auth0Provider>
        <ServicesProvider>
          <EventProvider>
            <ApolloWrapper>
              <FeatureFlagsProvider>
                <AttendeesProvider>
                  <SubscriptionsProvider>
                    <ColorProvider colors={AVATAR_COLOURS}>
                      <LoadingProvider>
                        <Router history={history}>
                          <App />
                        </Router>
                      </LoadingProvider>
                    </ColorProvider>
                  </SubscriptionsProvider>
                </AttendeesProvider>
              </FeatureFlagsProvider>
            </ApolloWrapper>
          </EventProvider>
        </ServicesProvider>
      </Auth0Provider>
    </AppProvider>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
