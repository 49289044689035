import { useContext, useMemo } from "react";
import { isAttendeeBrandingEnabled } from "../../views/eventMicrosite/helper";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";

export const useAttendeeBrandingFlag = (props: { companyId: string }) => {
  const { companyId } = props;
  const { featureFlags } = useContext(FeatureFlagsContext);

  const { [FeatureFlagKeys.EpAttendeeConsoleBranding]: epAttendeeConsoleBranding } = featureFlags;

  const enabled = useMemo(
    () => isAttendeeBrandingEnabled(epAttendeeConsoleBranding, companyId),
    [companyId, epAttendeeConsoleBranding]
  );

  return { enabled };
};
