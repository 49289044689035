import {
  MutationHookOptions,
  useMutation,
  useQuery,
  QueryHookOptions,
  SubscriptionHookOptions,
  useSubscription,
  useLazyQuery,
} from "@apollo/client";
import { NotificationService } from "@q4/nimbus-ui";
import { useState, useRef, useCallback, useContext } from "react";
import { EventModelPopulated } from "../../../hooks/events/events.hook.definitions";
import { EventConferenceStatuses } from "../../../services/event/event.model";
import { PublicEventDetails } from "../../../services/event/eventGql.model";
import {
  UPDATE_EVENT_MICROSITE_BRANDING,
  UPDATE_EVENT_MICROSITE_PREVIEW,
  UPDATE_EVENT_START_PERIOD_SUBSCRIPTION,
  UpdateEventStartPeriodSubVars,
  UpdateEventStartPeriodSubData,
  GET_EVENT,
  GET_PUBLIC_EVENT,
} from "./microsite.hook.definition";
import { FeatureFlagsContext } from "../../../contexts/featureFlags";
import { FeatureFlagKeys } from "../../../configurations/featureFlagKeys";

export function useUpdateEventMicrositeBrandingMutation(options: MutationHookOptions) {
  return useMutation(UPDATE_EVENT_MICROSITE_BRANDING, options);
}

export function useUpdateEventMicrositePreviewMutation(options: MutationHookOptions) {
  return useMutation(UPDATE_EVENT_MICROSITE_PREVIEW, options);
}

export interface PublicEventQueryData {
  getPublicEventDetails: PublicEventDetails;
}

export interface EventQueryData {
  getEvent: EventModelPopulated;
}

export interface EventQueryVars {
  meetingId: number;
  assetParams?: {
    isAdminConsole: boolean;
  };
  skipCache?: boolean;
}

export function useEventQuery(options: QueryHookOptions<EventQueryData, EventQueryVars>) {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const drFeatureEnabled = featureFlags[FeatureFlagKeys.DisasterRecovery]?.enabled;
  const dsFeatureEnabled = featureFlags[FeatureFlagKeys.DualStream]?.enabled;

  return useQuery<EventQueryData, EventQueryVars>(
    GET_EVENT({
      isDisasterRecoveryEnabled: drFeatureEnabled,
      isDualStreamEnabled: dsFeatureEnabled,
    }),
    options
  );
}

export function useLazyEventQuery(options: QueryHookOptions<EventQueryData, EventQueryVars>) {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const drFeatureEnabled = featureFlags[FeatureFlagKeys.DisasterRecovery]?.enabled;
  const dsFeatureEnabled = featureFlags[FeatureFlagKeys.DualStream]?.enabled;

  return useLazyQuery(
    GET_EVENT({
      isDisasterRecoveryEnabled: drFeatureEnabled,
      isDualStreamEnabled: dsFeatureEnabled,
    }),
    {
      fetchPolicy: options.fetchPolicy || "cache-and-network",
      ...options,
    }
  );
}

export function usePublicEventQuery(
  options: QueryHookOptions<PublicEventQueryData, EventQueryVars>
) {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const drFeatureEnabled = featureFlags[FeatureFlagKeys.DisasterRecovery]?.enabled;

  return useQuery<PublicEventQueryData, EventQueryVars>(
    GET_PUBLIC_EVENT({ isDisasterRecoveryEnabled: drFeatureEnabled }),
    options
  );
}

export function useLazyPublicEventQuery(
  options: QueryHookOptions<PublicEventQueryData, EventQueryVars>
) {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const drFeatureEnabled = featureFlags[FeatureFlagKeys.DisasterRecovery]?.enabled;

  return useLazyQuery(GET_PUBLIC_EVENT({ isDisasterRecoveryEnabled: drFeatureEnabled }), {
    fetchPolicy: options.fetchPolicy || "cache-and-network",
    ...options,
  });
}

export function useEventQueryHook(props) {
  const { meetingId, eventStartedErrorMessage } = props;
  const notificationService = useRef(new NotificationService());

  const [currentEventData, setCurrentEventData] = useState<EventModelPopulated>();

  const _onCompleted = useCallback(
    (data) => {
      if (data?.getEvent?.conference?.status === EventConferenceStatuses.STARTED) {
        notificationService.current.error(eventStartedErrorMessage);
        if (props.onError) props.onError();
      } else setCurrentEventData(data.getEvent);
      props.onCompleted?.(data);
    },
    [eventStartedErrorMessage, props]
  );

  const _onError = useCallback(
    (error) => {
      notificationService.current.error(`Error getting event, Please refresh and try again`);
      if (props.onError) props.onError();
      console.error(error);
    },
    [props]
  );

  const { loading: eventLoading, refetch: refetchCurrentEventData } = useEventQuery({
    variables: { meetingId },
    fetchPolicy: !meetingId ? "cache-only" : "cache-first",
    skip: !meetingId,
    onCompleted: _onCompleted,
    onError: _onError,
  });

  return {
    currentEventData,
    eventLoading,
    refetchCurrentEventData,
    setCurrentEventData,
  };
}

export function usePublicEventQueryHook(props) {
  const { meetingId, onCompleted, onError } = props;
  const notificationService = useRef(new NotificationService());

  const [currentEventData, setCurrentEventData] = useState<PublicEventDetails>();

  const onCompletedCallback = useCallback(
    (data) => {
      setCurrentEventData(data.getPublicEventDetails);
      onCompleted?.(data);
    },
    [onCompleted]
  );

  const onErrorCallback = useCallback(
    (error) => {
      notificationService.current.error(`Error getting event, Please refresh and try again`);
      onError?.();
      console.error(error);
    },
    [onError]
  );

  const { loading: eventLoading, refetch: refetchCurrentEventData } = usePublicEventQuery({
    variables: { meetingId, skipCache: true },
    fetchPolicy: "network-only",
    skip: !meetingId,
    onCompleted: onCompletedCallback,
    onError: onErrorCallback,
  });

  return {
    currentEventData,
    eventLoading,
    refetchCurrentEventData,
    setCurrentEventData,
  };
}

export function useUpdateEventStartPeriodSubscription(
  options?: SubscriptionHookOptions<UpdateEventStartPeriodSubData, UpdateEventStartPeriodSubVars>
) {
  return useSubscription<UpdateEventStartPeriodSubData, UpdateEventStartPeriodSubVars>(
    UPDATE_EVENT_START_PERIOD_SUBSCRIPTION,
    options
  );
}
