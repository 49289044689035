import { useCallback, useContext, useRef } from "react";
import packageJson from "../../../package.json";
import { AttendeeOriginType } from "../../services/attendees/attendees.model";
import useLocalStorage, { LocalStorageKeys } from "../../utils/useLocalStorage";
import { IBehaviorAnalytics, BehaviorTrackingState } from "./behaviorAnalytics.hook.definition";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";

interface TrackerStateLS {
  state?: BehaviorTrackingState;
  expires?: Date;
}

export const useBehaviorAnalytics = (): IBehaviorAnalytics => {
  const { getLS, setLS } = useLocalStorage();

  const timerArray = useRef([]);
  const vendorDebug = useRef(false); // true for all Initialization/Events
  
  const { featureFlags } = useContext(FeatureFlagsContext);

  const vendorGetQ4PId = () => {
    const vendorConstant = "q4pid";
    let vendorId = window.localStorage.getItem(vendorConstant);
    if (!vendorId) {
      vendorId = `${Date.now()}-${Math.floor(Math.random() * 999)}`;
      window.localStorage.setItem(vendorConstant, vendorId);
    }
    return vendorId;
  };
  const isVendorReady = useCallback(() => {
    return true;
  }, []);

  const setTrackerState = useCallback(
    (trackerStateLS: TrackerStateLS): void => {
      setLS(LocalStorageKeys.BehaviorTrackingState, trackerStateLS);
    },
    [setLS]
  );

  const getTrackerState = useCallback((): TrackerStateLS | null => {
    return getLS(LocalStorageKeys.BehaviorTrackingState) as TrackerStateLS;
  }, [getLS]);

  const isTrackerActive = useCallback(() => {
    const trackerStateLS = getTrackerState();
    return trackerStateLS?.state === BehaviorTrackingState.Active;
  }, [getTrackerState]);

  const trackBehavior = useCallback(
    (event: string, object: object) => {
      if (!isTrackerActive()) return;
      if (!isVendorReady()) {
        timerArray.current.push(
          setTimeout(function vendorTrackDelay() {
            trackBehavior(event, object);
          }, 100)
        );
        return;
      }

      if (vendorDebug.current) console.log("trackBehavior", event, object);
      // vendor.track();
      // vendor.flushNow();
    },
    [isTrackerActive, isVendorReady]
  );

  const vendorSetup = useCallback((): boolean => {
    // if (isNullOrWhiteSpace(config.clientTracking.apiKey)) {
    //   console.warn(
    //     "Vendor is not initialised due to incorrect clientTrackingKey\nClient Tracking Key:",
    //     config.clientTracking.apiKey
    //   );
    //   return false;
    // }

    return true;
  }, []);

  const getVisitor = useCallback(() => {
    return {
      id: vendorGetQ4PId(),
      q4pid: vendorGetQ4PId(),
      epVersion: packageJson.version,
      projectId: "EP",
    };
  }, []);

  // const getAccount = useCallback(() => {
  //   return { id: "EventsPlatform" };
  // }, []);

  const identifyUser = useCallback(
    (props: { email?: string; projectIdentifier?: string; originType?: AttendeeOriginType }) => {
      if (!isTrackerActive() || !isVendorReady()) return null;
      const { email, projectIdentifier, originType } = props;

      const visitor = getVisitor();
      if (email) {
        visitor["email"] = email;
      }
      if (projectIdentifier) {
        visitor["projectIdentifier"] = projectIdentifier;
      }
      if (originType) {
        visitor["origin"] = originType;
      }

      if (vendorDebug.current) console.log("identifyUser", visitor);
      // vendor.identify(visitor);
      return visitor;
    },
    [getVisitor, isTrackerActive, isVendorReady]
  );

  const vendorInitialize = useCallback((): void => {
    return;
  }, []);

  const vendorInit = useCallback((): boolean => {
    const { enabled, percentage } = featureFlags?.[FeatureFlagKeys.BehaviorTracking] || {};
    if (!enabled || enabled === false) return false;

    let trackerStateLS = getTrackerState();

    if (trackerStateLS === null || new Date(trackerStateLS?.expires) < new Date()) {
      trackerStateLS = {
        state: Math.random() <= percentage ? BehaviorTrackingState.Active : BehaviorTrackingState.Inactive,
        expires: new Date(new Date().setDate(new Date().getDate() + 100)), // Expire after 100 days (~1 Fiscal Quarter)
      };
      setTrackerState(trackerStateLS);
    }

    if (trackerStateLS?.state !== BehaviorTrackingState.Active) return false;
    if (!vendorSetup()) {
      console.error("Vendor failed to load");
      return false;
    }
    vendorInitialize();
    return true;
  }, [featureFlags, vendorInitialize, vendorSetup, getTrackerState, setTrackerState]);

  return { identifyUser, vendorInit, trackBehavior, vendorGetQ4PId, isVendorReady };
};
