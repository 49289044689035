import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { getHelpCenterToken, setHelpCenterToken } from "./helpCenterAuth.utils";

export const GENERATE_HELP_CENTER_TOKEN = gql`
  mutation generateHelpCenterToken {
    generateHelpCenterToken
  }
`;

export function useGenerateHCTokenMutation<D = {}, V = {}>(options: MutationHookOptions<D, V>) {
  return useMutation<D, V>(GENERATE_HELP_CENTER_TOKEN, options);
}

export function useHelpCenterAuth({ postAuthDelayMS = 2000 } = {}) {
  const [generateHCToken] = useGenerateHCTokenMutation({});

  async function authenticateForHelpCenter() {
    try {
      const resp = await generateHCToken();
      const helpCenterToken = (resp?.data as any)?.generateHelpCenterToken;

      if (helpCenterToken) {
        setHelpCenterToken(helpCenterToken);
        return new Promise((resolve) => setTimeout(resolve, postAuthDelayMS));
      }

      throw new Error("Unable to authenticate for help center");
    } catch (e) {
      throw new Error(e?.message);
    }
  }

  function isAuthorizedToOpenHelpCenter() {
    return !!getHelpCenterToken();
  }

  return {
    authenticateForHelpCenter,
    isAuthorizedToOpenHelpCenter,
  };
}
