import config from "../../../config";
import {
  AdminSlidesPreviewPathPrefix,
  EventSlidesPreviewPathPrefix,
  SlidesPreviewPathSuffix,
} from "../../../configurations/routes/routes.configuration";

export function buildShareableSlidesUrl(meetingId: number, identifier: string) {
  return `${config.app.url}${SlidesPreviewPathSuffix}?meetingId=${meetingId}&id=${identifier}`;
}

export function buildAdminPrivateSlidesUrl(meetingId: number, filename: string, share?: boolean) {
  return `${
    config.app.url
  }${AdminSlidesPreviewPathPrefix}?meetingId=${meetingId}&fileName=${filename}${
    share ? "&share=" + share : ""
  }`;
}

export function buildPlatformPrivateSlidesUrl(
  meetingId: number,
  filename: string,
  share?: boolean
) {
  return `${
    config.app.url
  }${EventSlidesPreviewPathPrefix}?meetingId=${meetingId}&fileName=${filename}${
    share ? "&share=" + share : ""
  }`;
}
