import { Auth0ContextState } from "../../contexts/auth0/auth0.definition";
import { ServicesContextModel } from "../../contexts/services/services.context";
import { History } from "history";

export interface AppProps {
  authContext?: Auth0ContextState;
  services?: ServicesContextModel;
  history: History<{}>;
}

export enum AppClassNames {
  Base = "events-platform",
  Wrapper = "events-platform_wrapper",
  WrapperWithFullscreenModifier = "events-platform_wrapper--fullscreen",
  Navigation = "events-platform_navigation",
  Inner = "events-platform_inner",
  View = "events-platform_view",
  ViewWithPrivateModifier = "events-platform_view--private",
  Logo = "events-platform_logo",
  CollapsedLogo = "events-platform_collapsed-logo",
}

export enum SortType {
  Descending = -1,
  Ascending = 1,
}

export interface AgGridApi {
  setRowData: (data) => void;
}

export enum UIBreakpoints {
  Mobile = 600,
  Tablet = 1024,
}
