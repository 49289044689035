import { AssetData } from "../../../hooks/assetUploads/assetUploads.definition";
import { AssetBroadcastStream } from "../../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";

export const AssetPortalElementPrefixes = {
  video: "video",
  audio: "audio",
  slides: "slides",
};

export interface AssetPortalElementProps {
  asset: AssetData;
  onAssetRendered: (stream: AssetBroadcastStream) => void;
}
