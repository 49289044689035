import { getClassName } from "@q4/nimbus-ui";
import React from "react";
import { CustomAvatar } from "../customAvatar/customAvatar.component";
import { CustomAvatarProps } from "../customAvatar/customAvatar.definition";
import { CustomThumbnailClassNames } from "./customThumbnail.definition";
import "./customThumbnail.style.scss";

export const CustomThumbnail = (props: CustomAvatarProps) => {
  const wrapperClassName = getClassName(CustomThumbnailClassNames.Wrapper, [
    {
      condition: !!props?.classNames?.wrapper,
      trueClassName: props?.classNames?.wrapper,
    },
  ]);

  const contentClassName = getClassName(CustomThumbnailClassNames.Avatar, [
    {
      condition: !!props?.classNames?.content,
      trueClassName: props?.classNames?.content,
    },
  ]);

  return (
    <div className={CustomThumbnailClassNames.Base}>
      <CustomAvatar
        {...{
          ...props,
          classNames: {
            wrapper: wrapperClassName,
            content: contentClassName,
          },
        }}
      />
    </div>
  );
};
