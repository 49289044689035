import { PropsWithChildren } from "react";
import { EventPermissionDictionary } from "../../../../configurations/userRolesPermissions.configuration";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { MediaService } from "../../hooks/mediaControls/mediaControls.definition";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import "./carouselStreamWrapper.scss";

export interface CarouselStreamWrapperProps extends PropsWithChildren<unknown> {
  audioOnly: boolean;
  broadcasting: boolean;
  participant: Partial<Participant>;
  stream: BroadcastStream;
  userEventPermissions: EventPermissionDictionary;
  streamMenuActions: {
    setSpeakerAudio: MediaService["setSpeakerAudio"];
    onRemoveStream: (stream: BroadcastStream) => void;
  };
  assetStreamMenuActions?: {};
}

export enum CarouselStreamWrapperClassNames {
  Base = "csw",
  Thumbnail = "csw_thumbnail",
  Avatar = "csw_avatar",
}

export enum StagedStreamWrapperClassNames {
  Base = "staged_stream_wrapper",
  Thumbnail = "staged_stream_wrapper_thumbnail",
  Avatar = "staged_stream_wrapper_avatar",
}
