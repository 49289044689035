import { ServicesContextModel } from "../../../contexts/services/services.context";
import { Auth0ContextState } from "../../../contexts/auth0/auth0.definition";
import { Company } from "../../../services/company/company.model";
import { ErrorModel } from "../modal.definition";
import { isEmpty } from "lodash";

export interface CompanyModalProps {
  onCompanyModalClose: () => void;
  modalType?: string;
  modelOpen: boolean;
  services?: ServicesContextModel;
  auth0?: Auth0ContextState;
  onUpdateSubmission?: (company: Company) => Promise<boolean> | boolean;
  company?: Company;
  onCompanyUpdate?: (company: Company) => void;
  onLoading?: (value: boolean) => void;
  loading?: boolean;
}

export enum ModalType {
  Add = "add",
  Update = "update",
}

export const CustomIdentifierRegex = /^[a-zA-Z0-9]+[-.]?(?=[a-zA-Z0-9])[a-zA-Z0-9]+$|^[a-zA-Z0-9]$/;

/**
 * error messages
 */
export enum CompanyModalErrorMessage {
  emptyCompanyNameErrorMessage = "Company Name Required",
  emptyCompanyTickerErrorMessage = "Stock Ticker Required",
  emptyCustomUrlErrorMessage = "Custom URL Required",
  emptyCompanyOrganizationIdErrorMessage = "Q4 Platform Organization Required",
  formattingCompanyTickerErrorMessage = "Must be at least 1 alphanumeric characters and not include spaces",
}

/**
 * type input that shows error
 */
export enum CompanyModalErrorKey {
  Name = "name",
  Ticker = "ticker",
  Q4PlatformOrganization = "organizationId",
}

export class CompanyState {
  companyName: string;
  companyTicker: string;
  companyOrganizationId: { label: string; value: string; data: any } | null;

  constructor(company?: Company) {
    if (isEmpty(company)) {
      this.companyName = "";
      this.companyTicker = "";
      this.companyOrganizationId = null;
    } else {
      this.companyName = company.name;
      this.companyTicker = company.customIdentifier;
      this.companyOrganizationId = {
        label: company.organization?.name,
        value: company.organization?.id,
        data: company.organization,
      };
    }
  }
}

export class CompanyModalErrorState {
  companyNameError?: ErrorModel;
  companyTickerError?: ErrorModel;
  companyOrganizationIdError?: ErrorModel;

  constructor(state: CompanyModalErrorState) {
    this.companyNameError = state.companyNameError;
    this.companyTickerError = state.companyTickerError;
    this.companyOrganizationIdError = state.companyOrganizationIdError;
  }
}

export enum DCMClassNames {
  Base = "cm-dupe-warn",
  Summary = "cm-dupe-warn-summary",
  SummaryIcon = "cm-dupe-warn-summary--icon",
  DupeNames = "cm-dupe-warn-names",
  DupeNamesList = "cm-dupe-warn-names-list",
  ShowMore = "cm-dupe-warn-names-more",
  LaunchSection = "cm-dupe-warn-launch",
  Launch = "cm-dupe-warn-launch--anchor",
}
