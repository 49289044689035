import { OpenTokExceptionEvent } from "../../types/OT.type";
import { getOpenTok } from "../../utils/openTok.resolver";
import {
  SessionDisconnectedResons,
  SessionEventCallbacks,
  StreamEventCallbacks,
} from "./session.definition";

function areKeysValid(apiKey, sessionId, token) {
  if (!apiKey) {
    throw new Error("Missing apiKey");
  }
  if (!sessionId) {
    throw new Error("Missing sessionId");
  }
  if (!token) {
    throw new Error("Missing token");
  }
}

interface SessionFactoryProps {
  apiKey: string;
  sessionEvents: SessionEventCallbacks;
  sessionId: string;
  sessionOptions: any;
  streamEvents: StreamEventCallbacks;
  token: string;
}
export default function SessionFactory(props: SessionFactoryProps) {
  const { apiKey, sessionId, token, sessionOptions } = props;
  const { onSessionConnected, onSessionCreated, onSessionCreateError, onSessionDisconnected } =
    props.sessionEvents;
  const { onStreamCreated, onStreamDestroyed, onConnectionDestroyed, onConnectionCreated } =
    props.streamEvents;

  areKeysValid(apiKey, sessionId, token);
  const OT = getOpenTok();

  // listen for exceptions
  OT.on("exception", (exception: OpenTokExceptionEvent) => {
    console.error(`Code: ${exception.code}, message: ${exception.message},
    target: ${exception.target}, title: ${exception.title}`);
  });

  // Initialize session
  const session = OT.initSession(apiKey, sessionId, sessionOptions);

  // Register session events
  const sessionEventHandlers = {
    sessionConnected: (event) => onSessionConnected?.(event),
    streamCreated: (event: any) => onStreamCreated?.(event.stream),
    streamDestroyed: (event: any) => {
      session?.getSubscribersForStream?.(event.stream).forEach(session?.unsubscribe);
      onStreamDestroyed?.(event.stream);
    },
    connectionCreated: (event: any) => onConnectionCreated?.(event.connection),
    connectionDestroyed: (event: any) => onConnectionDestroyed?.(event.connection),
    sessionDisconnected: (event: any) => {
      if (event?.reason === "networkDisconnected") {
        console.error("Session:networkDisconnected", JSON.stringify(event));
        onSessionDisconnected?.(session, SessionDisconnectedResons.NETWORK_LOST);
      }
    },
  };

  session.on(sessionEventHandlers);

  if (session.isConnected) {
    session.disconnect();
  }
  // Connect session to OT
  session.connect(token, (err) => {
    if (err) {
      onSessionCreateError && onSessionCreateError(err);
    } else {
      onSessionCreated(session);
    }
  });

  return () => session.off(sessionEventHandlers);
}
