import { Moment } from "moment";
import { EventPermissionDictionary } from "../../configurations/userRolesPermissions.configuration";
import { AssetUploads } from "../../hooks/assetUploads/assetUploads.definition";
import { Asset } from "../../hooks/assets/assets.hook.definition";
import { Group } from "../../hooks/groups/groups.hook.definition";
import { TimezoneName } from "../../utils/timezones/timezones.definition";
import {
  BroadcastStatusEnum,
  DualStreamStatusEnum,
} from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { EventStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/eventState";
import { EventSettings } from "../../views/broadcastSlideout/components/rsl/hooks/rsl.hook.definition";
import {
  EventBrandingPreviewType,
  SavedEventBrandingType,
} from "../../views/eventMicrosite/hooks/microsite.hook.definition";
import { CustomRecordingUpload } from "./../../views/postEventEdit/definitions/postEventEdit.definition";
import {
  BroadcastContextStatus,
  EventCategoryType,
  EventRecordingUrlConfig,
  EventType,
  Guest,
  Host,
  Inspector,
  Observer,
  Speaker,
  Support,
} from "./event.model";
import { RegistrationField } from "./registrationFieldGql.model";

export enum HostingTypes {
  VirtualDataRoom = "VIRTUAL_DATA_ROOM",
  Default = "DEFAULT",
}

export enum MediaServerRegion {
  NorthAmericaVirginia = "us-east-1",
  NorthAmericaOregon = "us-west-2",
  EuropeDublin = "eu-west-1",
  EuropeFrankfurt = "eu-central-1",
  SouthAmericaSaoPaulo = "sa-east-1",
  AsiaPacificSingapore = "ap-southeast-1",
  AsiaPacificTokyo = "ap-northeast-1",
  AsiaPacificSydney = "ap-southeast-2",
}

export const defaultRegion = MediaServerRegion.NorthAmericaVirginia;

export interface PostEventConfig {
  feedbackSurvey?: boolean;
  enableArchive?: boolean;
  enableReplay?: boolean;
}
export interface GuestConfig {
  enabled?: boolean;
}

export interface QuestionConfig {
  text?: boolean;
  questionsPerAttendee?: number;
  charLimit?: number;
  qaLineEnabled?: boolean;
  qaLine?: string;
}
export interface DialInConfig {
  speaker: boolean;
  operatorLineEnabled?: boolean;
  operatorLine?: string;
}

export interface RSLConfig {
  enabled: boolean;
  hostingType?: HostingTypes;
}

export interface VideoConfig {
  enabled: boolean;
}

export interface ShareholderConfig {
  maxSessions: number;
}
export interface EmailVerification {
  enabled: boolean;
}

export interface PresenterControlledSlides {
  enabled: boolean;
}

export interface LayoutManagerConfig {
  enabled: boolean;
}

export interface SpeakerBioConfig {
  enabled: boolean;
}

export interface EventSIP {
  speakerDialInNumber?: string;
  pin?: {
    encryptedData?: string;
    initVector?: string;
    tag?: string;
  };
}

export interface BroadcastOutput {
  externalEnabled?: boolean;
}

export interface DisasterRecoveryOutput {
  enabled: boolean;
  redirectUrl: string;
}

export interface DualStreamConfig {
  enabled: boolean;
  region: string;
}

export interface EventConfiguration {
  postEvent?: PostEventConfig;
  guest?: GuestConfig;
  question?: QuestionConfig;
  rsl?: RSLConfig;
  dialIn?: DialInConfig;
  video?: VideoConfig;
  shareholder?: ShareholderConfig;
  emailVerification?: EmailVerification;
  presenterControlledSlides?: PresenterControlledSlides;
  layoutManager?: LayoutManagerConfig;
  speakerBios?: SpeakerBioConfig;
  broadcastOutput?: BroadcastOutput;
  disasterRecovery?: DisasterRecoveryOutput;
  dualStream?: DualStreamConfig;
}

export enum RecordingEditStatus {
  ERROR = "ERROR",
  PROGRESSING = "PROGRESSING",
  COMPLETE = "COMPLETE",
  NONE = "NONE",
}

export enum RecordingStatus {
  AVAILABLE = "available",
  ERROR = "error",
  STARTED = "started",
  DELETED = "deleted",
}

export interface RecordingChapter {
  id: string; // Guid
  time: number; // seconds from initial zero
  title: string;
  text: string;
}

export enum EventRecordingSource {
  Primary = "primary",
  Backup = "backup",
}

export interface EventRecording {
  id: string;
  duration?: number;
  editStatus: RecordingEditStatus;
  hide: boolean;
  name?: string;
  originalUrl: string;
  status?: RecordingStatus;
  uploadDate?: number;
  statusReason?: string;
  edited: {
    storageId?: string;
    url?: string;
    startTime?: number;
    endTime?: number;
  };
  chapters?: RecordingChapter[];
  source?: EventRecordingSource;
}

export enum RecordingStatusEnum {
  NOT_STARTED = "NOT_STARTED",
  STARTING = "STARTING",
  STARTED = "STARTED",
  STOPPING = "STOPPING",
  STOPPED = "STOPPED",
}

export interface EventConferenceSessionRecording {
  status: RecordingStatusEnum;
  updateTime: Date;
}

export interface EventConference {
  vendor: string;
  broadcastUrl: string;
  broadcastRecordings: EventRecordingUrlConfig[];
  eventRecordings: EventRecording[];
  sessionId: string;
  status?: BroadcastStatusEnum;
  startTime?: Date;
  context?: BroadcastContextStatus;
  sip: EventSIP;
  customRecordings?: CustomRecordingUpload[];
  hideCustomRecording?: boolean;
  sessionRecording?: EventConferenceSessionRecording;
  streamKey?: string;
  ingestEndpoint?: string;
  playbackUrl?: string;
  dualStreamStatus?: DualStreamStatusEnum;
  backupBroadcastUrl?: string;
}

export interface PublicPages {
  adminPostEventReport: {
    access: EventPassword[];
  };
}

export interface EventPassword {
  encrypted: string;
  iv: string;
  decrypted?: string;
}

export enum BroadcastSource {
  default = "default",
  external = "external",
}
export interface Event {
  id?: string;
  meetingId: number;
  title: string;
  brMeetingId?: string;
  description: string;
  companyId: string;
  status?: EventStatusEnum;
  branding: SavedEventBrandingType;
  previewOptions: EventBrandingPreviewType;
  eventStart: Date | Moment; // XXX: how is it possible that it can be Moment | Date? Should it be Date only?
  eventEnd: Date | Moment; // XXX: how is it possible that it can be Moment | Date? Should it be Date only?
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  conference: EventConference;
  configuration?: EventConfiguration;
  assets?: Asset[];
  assetUploads?: AssetUploads[];
  settings: EventSettings;
  eventType: EventType;
  groups?: Group[];
  speakers: Speaker[];
  supports: Support[];
  hosts: Host[];
  guests: Guest[];
  observers: Observer[];
  inspectors: Inspector[];
  customIdentifier?: string;
  notes: string;
  eventTz: TimezoneName;
  password: EventPassword;
  adjournment?: boolean;
  region?: MediaServerRegion;
  publicPages?: PublicPages;
  organizationId?: string;
  registrationFields?: RegistrationField[];
  speakerBios: SpeakerBio[];
  broadcastSource?: BroadcastSource;
  eventCategory?: EventCategoryType;
}

export interface SpeakerBio {
  id?: string;
  name: string;
  role: string;
  bio: string;
  photo: string;
  eventOnly?: boolean;
  position: number;
  active: boolean;
}

export interface PublicEventDetails {
  backupBroadcastUrl?: string;
  branding?: SavedEventBrandingType;
  brMeetingId?: string;
  broadcastContext: BroadcastContextStatus;
  broadcastRecordings: EventRecordingUrlConfig[];
  broadcastStatus: BroadcastStatusEnum;
  broadcastStartTime: Date;
  broadcastUrl: string;
  broadcastSource: BroadcastSource;
  companyId: string;
  companyName?: string;
  companyIdentifier?: string;
  configuration?: EventConfiguration;
  customIdentifier?: string;
  customRecordings: CustomRecordingUpload[];
  description?: string;
  dualStreamStatus?: DualStreamStatusEnum;
  eventEnd: Date;
  eventStart: Date;
  eventType?: EventType;
  eventTz: TimezoneName;
  hasUserControlledSlides: boolean;
  hideCustomRecording?: boolean;
  id?: string;
  meetingId: number;
  msToStart: number;
  notes?: string;
  organizationId?: string;
  previewOptions: EventBrandingPreviewType;
  registrationFields?: RegistrationField[];
  settings?: EventSettings;
  speakerBios?: SpeakerBio[];
  status: EventStatusEnum;
  title: string;
}

export interface GetEventAuthenticationGuestParams {
  meetingId: number;
  email: string;
  password: string;
  isEncrypted: boolean;
  firstName: string;
  lastName: string;
}

export interface GetEventAuthenticationGuestResponse {
  userEventAccessToken?: string;
  userEventPermissions?: EventPermissionDictionary;
  verifyEmail: boolean;
}

export type VerifyEventPasswordParams = {
  meetingId: number;
  password: string;
  isEncrypted?: boolean;
};

export type RequestPasscodeParams = {
  meetingId: number;
  email: string;
};

export type RequestPasscodeResponse = {
  emailSent: boolean;
};

export type ValidatePasscodeParams = {
  meetingId: number;
  password: string;
  isEncrypted?: boolean;
  passcode: string;
  email: string;
};

export enum Broadcaster {
  Vonage = "vonage",
  IVS = 'ivs'
}

export const defaultBroadcaster = Broadcaster.Vonage;
