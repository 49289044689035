import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";
import { Event as EventModel } from "./event.model";

export default class EventService {
  private readonly PUBLIC_API_PATH = "/public";

  constructor(private apiService: ApiService) {}

  async requestRsl(rslRequest: RslRequestBody): Promise<ApiResponse<any>> {
    const apiResponse = await this.apiService.post<RslRequestBody, RslResponse>(
      `${this.PUBLIC_API_PATH}/v1/rsl/request`,
      rslRequest
    );

    if (!apiResponse?.success) {
      throw new Error("Failed to request access for Registered Shareholder List");
    }

    return apiResponse;
  }
}

export type Event = EventModel;

// FIXME: on the UI we partially working with DTO (when getting data) / partially with persistance (when post/put data)
// additionally we receiving populated data, but to create/update we need to send data un-populated
// would be better to leave all conversions in a single place
export interface EventPersistance extends Omit<EventModel, "hosts" | "speakers" | "supports"> {
  hosts: string[];
  speakers: string[];
  supports: string[];
}

export interface RslRequestBody {
  meetingId: string;
  email: string;
}

export interface RslResponse {
  message: string;
}

export type EventUpdatePersistance = Partial<Omit<EventPersistance, "_id">>;
