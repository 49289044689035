import React, { useState, memo } from "react";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Button, getClassName, Ghostable } from "@q4/nimbus-ui";
import { NavigationClassNames } from "./navigation.definition";
import type { NavigationProps } from "./navigation.definition";
import "./navigation.component.scss";
import { getAppVersion } from "./app-version.resolver";
import { Version } from "../version/version.component";

export const Navigation = (props: NavigationProps): JSX.Element => {
  const { className, logo, collapsedLogo, routes, collapseToggleIcon, footerActions } = props;

  const [collapsed, setCollapsed] = useState(false);
  const baseClassName = getClassName(NavigationClassNames.Base, [
    { condition: !!className, trueClassName: className },
    {
      condition: collapsed,
      trueClassName: NavigationClassNames.BaseWithCollapsedModifier,
    },
  ]);

  function handleCollapseToggle(): void {
    setCollapsed(!collapsed);
  }

  return (
    <div className={baseClassName}>
      <div className={NavigationClassNames.Logo} title={`Version ${getAppVersion()}`}>
        {collapsed ? (collapsedLogo ? collapsedLogo : logo) : logo}
      </div>
      <ul className={NavigationClassNames.List}>
        {(routes || []).map((route) => {
          const { path, icon, label, target, exact, dataId } = route;
          return (
            <li key={path} className={NavigationClassNames.Item} data-id={dataId}>
              <NavLink
                to={path}
                className={NavigationClassNames.Route}
                exact={exact}
                target={target}
              >
                <i className={`${NavigationClassNames.Icon} ${icon}`} />
                <span className={NavigationClassNames.Label}>{label}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
      {footerActions && (
        <Ghostable ghosted={collapsed}>
          <footer className={NavigationClassNames.Footer}>
            {footerActions.map(
              (buttonProps, index): ReactNode => (
                <Button key={`navigation_footer-action--${index}`} {...buttonProps} />
              )
            )}
          </footer>
        </Ghostable>
      )}
      <div className={NavigationClassNames.FooterEnd}>
        {!collapsed && <Version />}

        <div
          className={NavigationClassNames.CollapseToggle}
          role="button"
          aria-label={"Navigation Menu Collapse Toggle"}
        >
          <i className={collapseToggleIcon} onClick={handleCollapseToggle} />
        </div>
      </div>
    </div>
  );
};

Navigation.defaultProps = {
  collapseToggleIcon: "q4i-caret-sm-left-4pt",
};

export default memo(Navigation);
