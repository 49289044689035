import { useState, useEffect, useRef, Dispatch, SetStateAction, MutableRefObject } from "react";

/**
 * This function was created to solve stale closure issue.
 * Uses state and ref with same variable.
 * @param defaultValue
 */

export function useSafeState<T>(
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [state, setState] = useState<T>(defaultValue);
  const stateRef = useRef(defaultValue);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  // don't return stateRef.current, cause the value might not be updated
  return [state, setState, stateRef];
}
