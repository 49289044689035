import { gql } from "@apollo/client";

export enum EventAssetType {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
}

export enum EventAssetTypeMap {
  VIDEO = "Video",
  AUDIO = "Audio",
}

export interface Asset {
  name: string;
  displayName: string;
  assetType: EventAssetType;
  assetFormat: string;
  size: number;
  duration: number;
}

export interface AssetsHookProps {
  meetingId: number;
  eventId: string;
  assets: Asset[];
}

export const UPLOAD_EVENT_ASSET_MUTATION = gql`
  mutation UPLOAD_EVENT_ASSET_MUTATION(
    $file: Upload!
    $meetingId: Int
    $size: Int
    $duration: Float
  ) {
    uploadEventAsset(file: $file, meetingId: $meetingId, size: $size, duration: $duration) {
      meetingId
    }
  }
`;

export const REMOVING_EVENT_ASSET_MUTATION = gql`
  mutation REMOVING_EVENT_ASSET_MUTATION($filenames: [String!]!, $meetingId: Int) {
    removeEventAssets(filenames: $filenames, meetingId: $meetingId) {
      eventEnd
      meetingId
    }
  }
`;
