import { useRef, useState } from "react";
import { QueryHookOptions, useQuery } from "@apollo/client";
import { NotificationService } from "@q4/nimbus-ui";
import {
  OrganizationDTO,
  SearchOrganizationsQueryData,
  SearchOrganizationsVariables,
  SEARCH_ORGANIZATIONS,
} from "./organizations.hook.definition";
import { DataOptionType } from "../../services/api/api.definition";

export function useOrganizationsSelect() {
  const [organizationsLoading, setOrganizationsLoading] = useState(false);
  const notificationService = useRef(new NotificationService());

  const { refetch: searchOrganizations } = useSearchOrganizations({
    skip: true,
  });

  async function getSearchResults(input: string): Promise<DataOptionType<OrganizationDTO>[]> {
    try {
      setOrganizationsLoading(true);
      const response = await searchOrganizations({ searchInput: input });
      if (response?.data?.searchOrganizations) {
        const organizations = response.data.searchOrganizations;
        return organizations.map(({ name, id }) => {
          return {
            label: name,
            value: id,
            data: { name, id },
          };
        });
      }
    } catch (error) {
      notificationService.current.error("Failed to search organizations");
    } finally {
      setOrganizationsLoading(false);
    }
  }

  return {
    organizationsLoading,
    getSearchResults,
  };
}

export function useSearchOrganizations<
  D = SearchOrganizationsQueryData,
  V = SearchOrganizationsVariables
>(options: QueryHookOptions<D, V>) {
  return useQuery<D, V>(SEARCH_ORGANIZATIONS, options);
}
