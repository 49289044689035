import { AttendeeRegistrationFormData } from "./../views/broadcastParticipant/broadcastRegistration/broadcastRegistration.definition";
import {
  BaseEventRegistration,
  FormEditState,
} from "./../views/broadcastParticipant/eventRegistration/eventRegistration.definition";
import { matchPath } from "react-router";
import { AttendeeRoutes } from "../configurations/routes/routes.configuration";
import { Q4_ATTENDEE_KEY_PREFIX } from "../contexts/q4Login/q4Login.definition";
import { ParticipantStorageKeyPrefix } from "../views/broadcastParticipant/broadcastParticipant.definition";

export function isAttendeeView() {
  const currentPath = window.location.pathname;
  return Object.values(AttendeeRoutes).some((path) =>
    matchPath(currentPath, {
      path,
    })
  );
}

export const setParticipantInLocalStore = (meetingId, attendeeData) => {
  const ParticipantStorageKey = `${ParticipantStorageKeyPrefix}${meetingId}`;
  sessionStorage?.setItem(ParticipantStorageKey, JSON.stringify(attendeeData));
};

export const removeParticipantFromLocalStore = (meetingId) => {
  const ParticipantStorageKey = `${ParticipantStorageKeyPrefix}${meetingId}`;
  const Q4AttendeeStorageKey = `${Q4_ATTENDEE_KEY_PREFIX}${meetingId}`;
  sessionStorage?.removeItem(ParticipantStorageKey);
  /** If the user was registered through Q4 Account */
  localStorage?.removeItem(Q4AttendeeStorageKey);
};

export const getParticipantFromLocalStore = (meetingId) => {
  const ParticipantStorageKey = `${ParticipantStorageKeyPrefix}${meetingId}`;
  const storageData = sessionStorage?.getItem(ParticipantStorageKey);
  if (!storageData) return;
  return JSON.parse(storageData);
};

export const initializeRegistrationFormState = (
  event: BaseEventRegistration,
  attendeeRegistrationFormData: AttendeeRegistrationFormData
) => new FormEditState({ ...event, ...attendeeRegistrationFormData });
