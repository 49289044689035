import { EventAssetType } from "./../../../hooks/assets/assets.hook.definition";
import { gql } from "@apollo/client";
import { FILE_SIZES } from "../../../components/fileUploader/fileUploader.definition";
import { EVENT_FRAGMENTS } from "../../../hooks/event/event.gql";
import { EventModel, EventModelPopulated } from "../../../hooks/events/events.hook.definitions";
import { EventRecording, RecordingChapter } from "../../../services/event/eventGql.model";
import { usePostEventRecordingHook } from "../hooks/postEventRecording.hook";
import { usePublishScheduleHook } from "../hooks/publishSchedule.hook";

/** Post Event Edit Modal */
export interface PostEventEditModalProps {
  event: EventModelPopulated;
  visible: boolean;
  onClose: () => void;
}
export enum PostEventEditModalClassNames {
  Base = "post-event-edit-modal",
}

export enum PostEventEditModalLabels {
  EmptyPreviewStateDefault = "No recording selected",
  EmptyPreviewStateReplayDisabled = "No content to display. Enable replay to edit post event details",
  SaveConfirmationExit = "EXIT WITHOUT SAVING",
  SaveConfirmationMsg = "Some of the changes you made failed to save. Do you want to try again?",
  SaveConfirmationTitle = "Failed to Save",
  SaveConfirmationTryAgain = "TRY AGAIN",
}

/** Post Event Edit Modal Content */
export enum PostEventEditClassNames {
  Base = "post-event-edit",
  SettingsPane = "post-event-edit-settings",
  SettingsTitle = "post-event-edit-settings-title",
  ReplaySettings = "post-event-edit-settings-replay",
  ReplaySettingsTitle = "post-event-edit-settings-replay--title",
  PublishScheduleSettings = "post-event-edit-settings-publish-schedule",
  PublishScheduleSettingsTitle = "post-event-edit-settings-publish-schedule--title",
  RecordingSettings = "post-event-edit-settings-recordings",
  PreviewPane = "post-event-edit-previews",
  PreviewPaneEmpty = "post-event-edit-previews_empty",
  EventRecordingsTitle = "post-event-edit-settings-recordings--title",
  EventTitleWrapper = "post-event-edit_event-title--wrapper",
  ErrorIcon = "ni-warning-4pt post-event-edit_event-title--error-icon",
}

export interface PostEventEditProps {
  eventRecordingProps: ReturnType<typeof usePostEventRecordingHook>;
  publishScheduleProps: Omit<ReturnType<typeof usePublishScheduleHook>, "publishConfirmationModal">;
  replayProps: { replayEnabled: boolean; toggleReplayEnabled: (enable: boolean) => void };
  eventTitle: string;
  eventAssetType: EventAssetType;
  setEventTitle: (title: string) => void;
  eventTitleError: boolean;
  recordingPoster: string;
  isDualStream?: boolean;
}

/** Post Event Replay */
export const ArchiveReplayOption = {
  label: "ENABLE REPLAY",
  id: "enable-replay",
};

/** Post Event Publish */
export enum PublishStateEnum {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum EventPublishTypes {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC",
}

/** Post Event Edit Recordings */
export enum EventRecordingUploadType {
  Default = "DEFAULT",
  Custom = "CUSTOM",
}

export interface CustomRecordingUpload {
  name: string;
  recordingId: string;
  displayName: string;
  assetType: EventAssetType;
  assetFormat: string;
  size: number;
  duration?: number;
  chapters?: RecordingChapter[];
}

export const CUSTOM_UPLOAD_FORMATS = ["mpeg", "wav", "x-wav", "mp4", "quicktime", "x-m4v", "webm"];
export const CUSTOM_UPLOAD_SIZE_LIMIT = 1 * FILE_SIZES.GIGABYTES;
export const CUSTOM_RECORDING_TITLE = "Custom Recording 1";

export const UPLOAD_POST_EVENT_RECORDING = gql`
  mutation postEventRecordingUpload(
    $file: JSON
    $eventId: String
    $companyId: String
    $size: Int
    $duration: Float
    $meetingId: Int
    $recordingId: String
    $upload: JSON
  ) {
    uploadPostEventRecording(
      file: $file
      eventId: $eventId
      companyId: $companyId
      size: $size
      duration: $duration
      meetingId: $meetingId
      recordingId: $recordingId
      upload: $upload
    ) {
      recording {
        name
        recordingId
        displayName
        assetType
        assetFormat
        size
        duration
      }
      upload
    }
  }
`;

export const REMOVE_POST_EVENT_RECORDING = gql`
  mutation postEventRecordingRemove(
    $meetingId: Int
    $eventId: String
    $companyId: String
    $recordingId: String
    $resourceType: String
  ) {
    removePostEventRecording(
      meetingId: $meetingId
      eventId: $eventId
      companyId: $companyId
      recordingId: $recordingId
      resourceType: $resourceType
    ) {
      removed
    }
  }
`;

export const TRIM_POST_EVENT_RECORDING = gql`
  mutation postEventRecordingRemove(
    $meetingId: Int
    $eventRecordingId: String
    $eventStartTime: Int
    $eventEndTime: Int
  ) {
    trimPostEventRecording(
      meetingId: $meetingId
      eventRecordingId: $eventRecordingId
      eventStartTime: $eventStartTime
      eventEndTime: $eventEndTime
    ) {
      trimmed
      eventRecordingId
    }
  }
`;

/** Post Event Edit Save */
export const POST_EVENT_EDIT_SAVE_MUTATION = ({
  isDisasterRecoveryEnabled,
  isDualStreamEnabled,
}) => gql`
  ${EVENT_FRAGMENTS.Event({ isDisasterRecoveryEnabled, isDualStreamEnabled })}

  mutation SAVE_MUTATION_STRING(
    $meetingId: Int
    $eventRecordings: Json
    $sitePublish: Json
    $eventReplay: Json
  ) {
    savePostEventSettings(
      meetingId: $meetingId
      eventRecordings: $eventRecordings
      sitePublish: $sitePublish
      eventReplay: $eventReplay
    ) {
      ...Event
    }
  }
`;

export interface PostEditEditSaveData {
  savePostEventSettings: EventModel;
}
export interface PostEditEditSaveVars {
  meetingId: number;
  eventRecordings: PostEventSavePayload["eventRecordings"];
  sitePublish: PostEventSavePayload["sitePublish"];
  eventReplay: PostEventSavePayload["eventReplay"];
}

export interface PostEventSavePayload {
  meetingId: number;
  eventRecordings: {
    uploadType: EventRecordingUploadType;
    recordings: EventRecording[];
    customUpload: CustomRecordingUpload;
  };
  sitePublish: {
    schedule: EventPublishTypes;
    status: PublishStateEnum;
  };
  eventReplay: {
    enabled: boolean;
  };
}

export interface RecordingChapterPayloadBase {
  recordingId: string;
}

export interface AddRecordingChapterParams {
  chapterTime: number;
  chapterTitle: string;
  chapterText?: string;
  chapterId?: string;
}

export interface UpdateRecordingChapterParams {
  chapterId: string;
  chapterTime?: number;
  chapterTitle?: string;
  chapterText?: string;
}

export interface DeleteRecordingChapterParams {
  chapterId: string;
}

export interface AddRecordingChapterPayload
  extends RecordingChapterPayloadBase,
    Partial<AddRecordingChapterParams> {}

export interface UpdateRecordingChapterPayload
  extends RecordingChapterPayloadBase,
    Partial<UpdateRecordingChapterParams> {}

export interface DeleteRecordingChapterPayload
  extends RecordingChapterPayloadBase,
    DeleteRecordingChapterParams {}
