import { isNil } from "@q4/nimbus-ui";
import { omit, omitBy } from "lodash";
import { v4 as uuid } from "uuid";
export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortOrder {
  Alphabetical = "alphabetical",
  Chronological = "chronological",
}

export class RegistrationFieldOption {
  id?: string;
  value: boolean | string | number | Date;
  default: boolean;
  isCustom?: boolean;
  label?: string;

  constructor(data: Partial<RegistrationFieldOption>) {
    const { id, value, isCustom, default: isDefault, ...rest } = data;

    this.id = id;
    this.value = value ?? null;
    this.isCustom = isCustom;
    this.default = isDefault;

    Object.assign(this, omitBy(omit(rest, ["__typename"]), isNil));
  }
}

export type RegistrationFieldCondition = {
  _registrationFieldId: string;
  conditionalValue: string | boolean | number | Date | RegistrationFieldOption;
};

export enum RegistrationFieldType {
  Text = "text",
  LongText = "longtext",
  Dropdown = "dropdown",
  MultiChoice = "multichoice",
  Consent = "consent",
  TextBlock = "textblock",
  PhoneNumber = "phonenumber",
  Date = "date",
  QuestionAnswer = "questionanswer",
  EmailAddress = "emailaddress",
}

export type RegistrationFieldSort = {
  enabled: boolean;
  direction: Sort;
  order: SortOrder;
};

export class RegistrationField {
  id: string;
  _templateId?: number;
  _companyId?: string;
  title: string;
  placeholder?: string;
  defaultValue?: string;
  required: boolean;
  hidden: boolean;
  condition?: RegistrationFieldCondition[];
  registrationFieldType: RegistrationFieldType;
  position: number;
  options?: RegistrationFieldOption[];
  optionSort?: RegistrationFieldSort;

  constructor(data: Partial<RegistrationField>) {
    const {
      registrationFieldType,
      hidden,
      required,
      id,
      position,
      _templateId,
      _companyId,
      options,
      optionSort,
      ...rest
    } = data;

    this.registrationFieldType = registrationFieldType;
    this.hidden = hidden ?? false;
    this.required = required ?? false;
    this.position = position ?? 100;
    this.id = id ?? uuid();
    this.options = options?.map((opt) => new RegistrationFieldOption(opt));

    if (
      [RegistrationFieldType.MultiChoice, RegistrationFieldType.Dropdown].includes(
        registrationFieldType
      )
    ) {
      this.optionSort = {
        enabled: false,
        direction: Sort.ASC,
        order: SortOrder.Alphabetical,
        ...omit(optionSort, ["__typename"]),
      };
    }
    Object.assign(this, omitBy(omit(rest, ["__typename"]), isNil));
  }
}
