import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import moment from "moment";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isBrowser, isChrome } from "react-device-detect";
import useBranding from "../../hooks/branding/branding.hook";
import { EventFeatures, FeatureMap } from "../../hooks/features/features.hook.definition";
import { useGetPublicEvent } from "../../services/attendeeApi/event";
import { Attendee } from "../../services/attendees/attendees.model";
import {
  AttendeeConsoleBranding,
  BroadcastContextStatus,
  EventType,
} from "../../services/event/event.model";
import { BroadcastSource, PublicEventDetails } from "../../services/event/eventGql.model";
import {
  RegistrationType,
  getEventEndToNowDuration,
  isPostEventPublishReady,
  isPreRegistrationTimeActive,
} from "../../utils/event.utils";
import { MusicOptions } from "../../views/eventMicrosite/micrositeModal.definition";
import { AppContext } from "../app/app.context";

export interface AttendeesContextState {
  backgroundImageWithTitle: string;
  eventDetails: PublicEventDetails;
  eventDetailsError: Error;
  eventDetailsLoading: boolean;
  eventFeatures: FeatureMap;
  eventType: EventType;
  hasAttendeePreRegistered: boolean;
  hoursAfterEnd: number;
  isDayAfterEnd: boolean;
  isAuthenticated: boolean;
  isBroadcastSpilledOver: boolean;
  isPostEventPagePublishReady: boolean;
  participantInfo: Partial<Attendee>;
  playAttendeeLobbyMusic: boolean;
  preRegistrationTimeActive: boolean;
  registrationType: RegistrationType;
  showAlreadyRegisteredForm: boolean;
  showPlayLobbyMusicButton: boolean;
  showPostEventRegistration: boolean;
  isVodActive: (vodEnabled: boolean) => boolean;
  initialFetchEventDetails(): void; // initialization purposes only, otherwise use fetchCurrentEventDetails
  updateCurrentEventDetails(event: PublicEventDetails): void;
  updateEventFeatures(features: FeatureMap): void;
  updateEventType(type: EventType): void;
  updateHasAttendeePreRegistered(isPreRegisteredAttendee: boolean): void;
  updateParticipantInfo(participant: Partial<Attendee>): void;
  updatePlayAttendeeLobbyMusic(state: boolean): void;
  updateShowAlreadyRegisteredForm(showForm: boolean): void;
  updateShowPlayLobbyMusicButton(state: boolean): void;
  attendeeConsoleBrandingState?: Omit<AttendeeConsoleBranding, "images">;
}

const AttendeesContext = createContext<Partial<AttendeesContextState>>({});

export const AttendeesProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const { meetingId } = useContext(AppContext);

  const [playAttendeeLobbyMusic, setPlayAttendeeLobbyMusic] = useState<boolean>(false);
  const [showPlayLobbyMusicButton, setShowPlayLobbyMusicButton] = useState<boolean>(false);
  const [eventDetails, setEventDetails] = useState<PublicEventDetails>(null);
  const [participantInfo, setParticipantInfo] = useState<Partial<Attendee>>(null);
  const [hasAttendeePreRegistered, setHasAttendeePreRegistered] = useState<boolean>(false);
  const [eventFeatures, setEventFeatures] = useState<FeatureMap>(null);
  const [eventType, setEventType] = useState<EventType>(null);

  const getHasPreregisteredFlag = () => {
    const filterParams = new URLSearchParams(window.location.search);
    return filterParams.get("hasPreRegistered") === "true";
  };
  const [showAlreadyRegisteredForm, setShowAlreadyRegisteredForm] =
    useState<boolean>(getHasPreregisteredFlag);

  const hoursAfterEnd = useMemo(() => {
    const momentDate = eventDetails?.eventEnd ? moment(eventDetails?.eventEnd) : undefined;
    return getEventEndToNowDuration(momentDate)?.asHours();
  }, [eventDetails?.eventEnd]);

  const isDayAfterEnd = useMemo(() => {
    const endDate = eventDetails?.eventEnd ? moment(eventDetails?.eventEnd) : undefined;
    return moment().isAfter(endDate, "day");
  }, [eventDetails?.eventEnd]);

  // Broadcast spills over when event end time has passed but broadcast is still ongoing
  const isBroadcastSpilledOver = useMemo(
    () =>
      hoursAfterEnd > 0 &&
      (!!eventDetails?.broadcastUrl ||
        !!(eventDetails?.broadcastContext === BroadcastContextStatus.PAUSED)),
    [hoursAfterEnd, eventDetails]
  );
  // Show post event registration only when event end time has passed and live broadcast has ended
  const showPostEventRegistration = useMemo(
    () => !eventDetails?.broadcastUrl && hoursAfterEnd > 0 && !isBroadcastSpilledOver,
    [hoursAfterEnd, eventDetails, isBroadcastSpilledOver]
  );

  const preRegistrationTimeActive = useMemo(
    () => isPreRegistrationTimeActive(eventDetails),
    [eventDetails]
  );

  const isPostEventPagePublishReady = useMemo(() => {
    return isPostEventPublishReady(eventDetails, hoursAfterEnd);
  }, [hoursAfterEnd, eventDetails]);

  const registrationType = useMemo<RegistrationType>(() => {
    if (eventFeatures && eventFeatures[EventFeatures.PreRegistration] && preRegistrationTimeActive)
      return RegistrationType.PRE_REGISTRATION;
    else if (showPostEventRegistration) return RegistrationType.POST_REGISTRATION;
    return RegistrationType.LOBBY_REGISTRATION;
  }, [eventFeatures, showPostEventRegistration, preRegistrationTimeActive]);

  const {
    brandedBackgroundImage: backgroundImageWithTitle,
    attendeeConsoleBrandingState,
    companyFavicon,
  } = useBranding({
    eventTitle: eventDetails?.title,
    companyId: eventDetails?.companyId,
    eventBranding: {
      ...eventDetails?.branding?.controlPanelBranding,
      ...(eventDetails?.branding?.attendeeConsoleBranding ?? {}),
    },
  });

  const {
    data: currentEventDetails,
    error: eventDetailsError,
    isLoading: eventDetailsLoading,
    refetch: fetchEventDetails,
  } = useGetPublicEvent({ meetingId });

  useEffect(() => {
    const musicOption = currentEventDetails?.branding?.preEventPageBranding?.musicOption;
    if (!musicOption) return;
    setPlayAttendeeLobbyMusic(isChrome && isBrowser && musicOption === MusicOptions[0].value);
    setShowPlayLobbyMusicButton(musicOption !== MusicOptions[1].value);
  }, [currentEventDetails]);

  const isVodActive = useCallback(
    (vodEnabled: boolean = false) => {
      return (
        vodEnabled &&
        (eventDetails?.broadcastSource === BroadcastSource.external ||
          eventDetails?.configuration?.broadcastOutput?.externalEnabled)
      );
    },
    [eventDetails?.broadcastSource, eventDetails?.configuration?.broadcastOutput?.externalEnabled]
  );

  useEffect(() => {
    setEventDetails(currentEventDetails);
  }, [currentEventDetails]);

  const updateCurrentEventDetails = useCallback((event: PublicEventDetails) => {
    setEventDetails(event);
  }, []);

  const updatePlayAttendeeLobbyMusic = useCallback((state: boolean) => {
    setPlayAttendeeLobbyMusic(state);
  }, []);

  const updateShowPlayLobbyMusicButton = useCallback((state: boolean) => {
    setShowPlayLobbyMusicButton(state);
  }, []);

  const updateEventFeatures = useCallback((features: FeatureMap) => {
    setEventFeatures(features);
  }, []);

  const updateEventType = useCallback((type: EventType) => {
    setEventType(type);
  }, []);

  const updateParticipantInfo = useCallback((participant: Partial<Attendee>) => {
    setParticipantInfo(participant);
  }, []);

  const updateShowAlreadyRegisteredForm = useCallback((showForm: boolean) => {
    setShowAlreadyRegisteredForm(showForm);
  }, []);

  const updateHasAttendeePreRegistered = useCallback((isPreRegisteredAttendee: boolean) => {
    setHasAttendeePreRegistered(isPreRegisteredAttendee);
  }, []);

  useEffect(() => {
    if (!companyFavicon) return;

    let link = document.querySelector("link[class='page-favicon']");
    if (!link) {
      link = document.createElement("link");
      link["rel"] = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link["class"] = "page-favicon custom-favicon";
    link["href"] = companyFavicon;
  }, [companyFavicon]);

  return (
    <AttendeesContext.Provider
      value={{
        backgroundImageWithTitle,
        eventDetails,
        eventDetailsError,
        eventDetailsLoading,
        eventFeatures,
        eventType,
        hasAttendeePreRegistered,
        hoursAfterEnd,
        isDayAfterEnd,
        isBroadcastSpilledOver,
        isPostEventPagePublishReady,
        participantInfo,
        playAttendeeLobbyMusic,
        preRegistrationTimeActive,
        registrationType,
        showAlreadyRegisteredForm,
        showPlayLobbyMusicButton,
        showPostEventRegistration,
        isVodActive,
        initialFetchEventDetails: fetchEventDetails,
        updateCurrentEventDetails,
        updateEventFeatures,
        updateEventType,
        updateHasAttendeePreRegistered,
        updateParticipantInfo,
        updatePlayAttendeeLobbyMusic,
        updateShowAlreadyRegisteredForm,
        updateShowPlayLobbyMusicButton,
        attendeeConsoleBrandingState,
      }}
    >
      {props?.children}
    </AttendeesContext.Provider>
  );
};

export default AttendeesContext;
