import { pick } from "lodash";
import { useCallback, useContext } from "react";
import { AdminConsoleContext, EventAssetsContext } from "../../../contexts";
import ConferenceContext from "../../../contexts/conference/conference.context";
import { AssetControlsActions } from "../../../hooks/remoteAssetControls/remoteAssetControls.definition";
import { SelectionModel } from "../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { AssetBroadcastStream } from "../../adminConsole/vendors/openTok/components/AssetBroadcastStream";

interface RemoveStreamHookProps {
  updateStage: (streams: SelectionModel) => void;
  mediaOnly?: boolean;
}

export const useRemoveStreamHook = (props: RemoveStreamHookProps) => {
  const { updateStage, mediaOnly = false } = props;

  const { carouselSelection, carouselSelectionHelpers } = useContext(AdminConsoleContext);
  const { remoteAssetControlsHook } = useContext(ConferenceContext);
  const { assetControlsService } = remoteAssetControlsHook;

  const { onStop } = useContext(EventAssetsContext);

  const removeStreamFromStage = useCallback(
    (stream: BroadcastStream) => {
      const { id } = stream || {};
      const { staged } = carouselSelection;

      if (stream?.isV2AssetStream && !stream?.isSlideAsset) {
        const assetStream = stream as AssetBroadcastStream;
        assetControlsService.changeAudioVideo(
          assetStream,
          AssetControlsActions.PAUSE,
          assetStream.volume
        );
      } else if (stream?.isAssetStream) {
        onStop(stream);
      }

      // filter current stream out of staged selection and update layout
      const selection = carouselSelectionHelpers.filterStreamsFromSelection(
        pick(staged, ["cameraSelection", "mediaSelection"]),
        id
      ) as SelectionModel;

      updateStage?.({ ...selection, mediaOnly });
    },
    [
      carouselSelection,
      carouselSelectionHelpers,
      assetControlsService,
      onStop,
      updateStage,
      mediaOnly,
    ]
  );
  return { removeStreamFromStage };
};
