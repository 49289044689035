import { isEmpty } from "lodash";
import { User } from "../services/user/user.model";
import { PlatformRoles, UserRoles } from "./userRolesPermissions.configuration";

export interface NavBarRoute {
  path?: string;
  label: string;
  dataId?: string;
  icon: string;
  exact?: boolean;
  dynamicPath?: (user: User) => string;
  permittedRoles?: Array<UserRoles>;
}

export const DATA_IDS = {
  HOME: "HomePage",
  EVENT: "EventPage",
  COMPANIES: "CompaniesPage",
  USERS: "UsersPage",
  COMPANY: "CompanyPage",
};

export const NavigationBarRoutes: NavBarRoute[] = [
  {
    path: "/",
    label: "Home",
    dataId: DATA_IDS.HOME,
    icon: "q4i-home-4pt",
    exact: true,
    permittedRoles: [UserRoles.ALL],
  },
  {
    path: "/events",
    label: "Scheduled Events",
    dataId: DATA_IDS.EVENT,
    icon: "q4i-calendar-4pt",
    permittedRoles: [UserRoles.EP_EVENT_MANAGER, UserRoles.EP_PLATFORM_HOST, UserRoles.Q4_ADMIN],
  },
  {
    path: "/companies",
    dataId: DATA_IDS.COMPANIES,
    label: "Companies",
    icon: "q4i-company-4pt",
    permittedRoles: [UserRoles.EP_EVENT_MANAGER, UserRoles.Q4_ADMIN],
  },
  {
    dynamicPath: (user: User) => `/companies/${!isEmpty(user.companies) && user.companies[0]}`,
    label: "Company",
    dataId: DATA_IDS.COMPANY,
    icon: "q4i-company-4pt",
    permittedRoles: [UserRoles.COMPANY_ADMIN],
  },
  {
    path: "/users",
    dataId: DATA_IDS.USERS,
    label: "Users",
    icon: "q4i-team-2pt",
    permittedRoles: [UserRoles.Q4_ADMIN, UserRoles.EP_EVENT_MANAGER],
  },
];

export function BuildRoleBasedNavigationRoutes(
  navigationBarRoutes: NavBarRoute[],
  user: User
): NavBarRoute[] {
  if (!user.userTypes) return [];

  const roles = buildUserRolesObj(user.userTypes);
  return navigationBarRoutes.reduce((routes, currentRoute) => {
    const { permittedRoles } = currentRoute;

    let permitted = false;
    permittedRoles.forEach((permittedRole) => {
      if (roles[permittedRole] || permittedRole === UserRoles.ALL) permitted = true;
    });

    if (permitted) {
      routes.push(buildDynamicRoute(currentRoute, user));
    }
    return routes;
  }, []);
}

function buildDynamicRoute(route: NavBarRoute, user: User): NavBarRoute {
  const { dynamicPath } = route;
  if (dynamicPath) {
    return { ...route, path: route.dynamicPath(user) };
  }
  return { ...route };
}

function buildUserRolesObj(userRoles: PlatformRoles[]) {
  return userRoles.reduce((roleObj, role) => ({ ...roleObj, [role]: true }), {});
}
