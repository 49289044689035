/**
 * Constructs path using PointerEvent target API.
 * @param event {PointerEvent} - IE11 PointerEvent
 * @param limit {Number} - number of classes in path
 * @return {Array}
 * @private
 */
const _buildTargetPath = (event, limit = 5) => {
  const path = [];
  let node = event.target;
  let counter = 0;

  while (node && node.className && counter < limit) {
    path.push(node);
    node = node.parentNode;
    counter++;
  }

  return path;
};

/**
 * Handle row click event
 * Contains logic to fix AgGrid event propagation and IE11 PointerEvent API.
 * @see { @link: https://github.com/ag-grid/ag-grid/issues/2224 } for further information.
 *
 * @param onRowClick {Function} - callback with on row click logic
 * @param customClickItems {Array} - array of classNames
 */
export const preventDefaultRowClick =
  (onRowClick, customClickItems) =>
  ({ data = {}, event = null }) => {
    if (!event) {
      onRowClick && onRowClick(data);
    }

    let path = event?.path;

    // IE11 doesn't have path in event
    if (!path) {
      path = _buildTargetPath(event);
    }

    // format classNames in path
    path = path.reduce((acc, curr) => {
      if (curr?.className) {
        acc.push(...curr.className.split(" "));
      }
      return acc;
    }, []);

    if (
      customClickItems.some((customClickItem) => path.find((target) => target === customClickItem))
    ) {
      return;
    }

    onRowClick && onRowClick(data);
  };

/**
 * Disable client sort for "ag-grid" that used under hood in table.
 * Comparator needed when we have sorting on backend side.
 *
 * @return 0 - does not change the order of the elements
 */
export const noopComparator = () => 0;
