import { EventType } from "../../services/event/event.model";

export enum PoweredByLogoClassNames {
  Base = "q4-pbl",
  Separator = "q4-pbl--separator",
  IconV2 = "q4-pbl--inline--icon-v2",
}

export interface PoweredByLogoProps {
  eventType?: EventType;
  className?: string;
  inline?: boolean;
  clickable?: boolean;
}
