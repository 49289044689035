import { useContext, useEffect } from "react";
import { ParticipantContext } from "../../../../contexts";
import { UserMessageTopics } from "../../../../hooks/userMessaging/userMessaging.hook";
import { usePrevious } from "../../../../opentok-react/utils/usePrevious";
import epDebugService from "../../../../services/eventsPlatformDebugger/epDebug.service";

export function useUserDebugger() {
  const {
    participantsService: { currentParticipant },
    userMessagingService: { subscribe },
  } = useContext(ParticipantContext);

  const prevCurrentParticipant = usePrevious(currentParticipant);

  // TOPIC : LOG_DEBUG_INFO
  const { data: logDebugInfoData, loading: logDebugInfoloading } = subscribe({
    variables: {
      userId: currentParticipant?.id,
      topic: UserMessageTopics.LOG_DEBUG_INFO,
    },
    skip: !!!currentParticipant?.id,
    shouldResubscribe: prevCurrentParticipant?.id !== currentParticipant?.id,
  });

  useEffect(
    function subscribeToLogUserInfoMsg() {
      const shouldLogInfo = logDebugInfoData?.receiveUserMessage?.data?.logInfo;
      if (!shouldLogInfo || logDebugInfoloading) return;
      epDebugService.instance().reportDebugStates();
    },
    [logDebugInfoData, logDebugInfoloading]
  );
}
