export enum Domain {
  Platform = "Platform",
  Event = "Event",
}

export enum EventRouteContext {
  PreCall = "PreCall",
  PreEvent = "PreEvent",
  LiveEvent = "LiveEvent",
  AttendeeEvent = "AttendeeEvent",
  PostEvent = "PostEvent",
  Microsite = "Microsite",
}

export enum PlatformRouteContext {
  Microsite = "Microsite",
}

export interface BaseDomain {
  domain: Domain;
  versionWhitelist?: boolean;
}

export interface PlatformDomain extends BaseDomain {
  domain: Domain.Platform;
  context?: PlatformRouteContext;
}
export interface EventDomain extends BaseDomain {
  domain: Domain.Event;
  context: EventRouteContext;
}

export type AppDomain = PlatformDomain | EventDomain;
