import type { Key } from "react";
import {
  ButtonIdModel,
  TextIdModel,
  BaseComponentProps,
  ModalIdModel,
  AutomationListId,
  IdModelBase,
} from "@q4/nimbus-ui";
import { InactivityDetectorHookProps } from "../../hooks/useInactivityDetector/useInactivityDetector.definition";

// #region Definitions

export interface InactivityPromptProps extends BaseComponentProps, InactivityDetectorHookProps {
  promptFooterAction?: () => void;
  promptFooterActionLabel?: string;
  promptMessageTitle?: string;
  promptMessageSubtitle?: string;
  onIdleHandler?: () => void;
  id: string;
}

export enum InactivityPromptClassName {
  Base = "inactivity-prompt",
  Subtitle = "inactivity-prompt_subtitle",
}

export const DefaultBroadcastChannelId = "q4_broadcast_channel";
// #endregion

// #region Automation
export class InactivityPromptListId extends AutomationListId<InactivityPromptIdModel> {
  constructor(parentId: string, prefix = "InactivityPrompt-", postfix?: string) {
    super(parentId, prefix, InactivityPromptIdModel, postfix);
  }
}

function isNullOrWhiteSpace(n: string): boolean {
  return !n || n.length === 0 || /^\s*$/.test(n);
}

export class InactivityPromptIdModel extends IdModelBase {
  component: string;
  modal: ModalIdModel;
  modalTitle: TextIdModel;
  modalSubtitle: TextIdModel;
  modalButton: ButtonIdModel;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);

    if (isNullOrWhiteSpace(this.id)) return;

    this.component = `${this.id}Component`;
    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.modalTitle = new TextIdModel(`${this.id}ModalTitle`);
    this.modalSubtitle = new TextIdModel(`${this.id}ModalSubtitle`);
    this.modalButton = new ButtonIdModel(`${this.id}ModalButton`);
  }
}
// #endregion
