import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useReducer,
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  AudioVideoContextState,
  AudioVideoContextProps,
  IMediaDeviceInfo,
} from "./audio-video.definition";
import EventContext from "../../contexts/event/event.context";
import { useSafeState } from "../../utils/react.utils";
import { throttle } from "lodash";
import { getCurrentAudioOutput } from "../../utils/userMediaConfig.utils";
import { BroadcastSource } from "../../services/event/eventGql.model";

const noAudioInput = {
  titleCase: "No microphone detected",
};
const noVideoInput = {
  titleCase: "No camera detected",
};
const initialState: Partial<AudioVideoContextState> = {
  audioInputs: [],
  audioInputsLoaded: false,
  audioOutputs: [],
  videoInputs: [],
  videoInputsLoaded: false,
  usersCurrentAudioInput: noAudioInput as IMediaDeviceInfo,
  usersCurrentAudioOutput: {
    titleCase: "No speakers detected",
  } as IMediaDeviceInfo,
  usersCurrentVideoInput: noVideoInput as IMediaDeviceInfo,
  audioOutputPermissionDenied: false,
  audioInputsReady: false,
  videoInputsReady: false,
};

const AudioVideoContext = createContext<Partial<AudioVideoContextState>>({});

const getCurrentIO = async (IOs: any[], currentIOGetter: () => any) => {
  if (IOs.length > 0) {
    const currentInput = await currentIOGetter?.();

    if (currentInput) {
      const currentAvailableInput = IOs.find((input) => input.titleCase === currentInput.titleCase);
      return currentAvailableInput ?? IOs[0];
    }
    return IOs[0];
  }
};

export const AudioVideoProvider: FunctionComponent<AudioVideoContextProps> = (props) => {
  const {
    children,
    setAudioInput,
    setAudioOutput,
    setVideoInput,
    getCurrentAudioInput,
    getCurrentVideoInput,
    getAudioInputs,
    getAudioOutputs,
    getVideoInputs,
  } = props;

  const { eventConfiguration, currentEvent } = useContext(EventContext);
  const isVideoEnabled = eventConfiguration?.video?.enabled;
  const isExternalEvent = useMemo(
    () => currentEvent?.broadcastSource === BroadcastSource.external,
    [currentEvent?.broadcastSource]
  );

  const stateRef = useRef(initialState);
  const [state, setState] = useReducer(reducer, initialState);
  function reducer(
    state: AudioVideoContextState,
    action: Partial<AudioVideoContextState>
  ): Partial<AudioVideoContextState> {
    const newState = {
      ...state,
      ...action,
    };
    stateRef.current = newState;
    return newState;
  }

  const [avSettingsConfigured, _setAVSettingsConfigured, avSettingsConfiguredRef] =
    useSafeState<boolean>(false);

  const [configuringAVSettings, setConfiguringAVSettings] = useState(true);

  useEffect(() => {
    setAudioVideo();
    if (navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = throttle(onUserMediaDevicesChange, 500);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function onUserMediaDevicesChange() {
    setAudioVideo();
    await refreshUserDefaultMicInput();
  }

  async function refreshUserDefaultMicInput() {
    const isDefaultDevice = (input) => input?.deviceId === "default";
    return new Promise((resolve) => {
      getAudioInputs?.(async (audioInputs: IMediaDeviceInfo[]): Promise<void> => {
        const defaultInput = (audioInputs || []).find(isDefaultDevice);
        if (defaultInput && isDefaultDevice(stateRef.current?.usersCurrentAudioInput)) {
          await setAudioInput(defaultInput);
        }
        resolve(true);
      });
    });
  }

  const updateAudioOutputsState = useCallback(async (audioOutputs) => {
    setState({
      audioOutputs,
      usersCurrentAudioOutput: await getCurrentIO(audioOutputs, getCurrentAudioOutput),
    });
  }, []);

  function setAudioVideo() {
    // Audio Inputs
    !isExternalEvent &&
      getAudioInputs &&
      getAudioInputs(async (audioInputs: IMediaDeviceInfo[]): Promise<void> => {
        setState({
          audioInputs,
          audioInputsLoaded: true,
          usersCurrentAudioInput: await getCurrentIO(audioInputs, getCurrentAudioInput),
        });
      }).catch(() => {
        setState({
          audioInputsLoaded: true,
        });
      });

    // Audio Outputs
    getAudioOutputs && getAudioOutputs(updateAudioOutputsState);
    // Video Inputs
    isVideoEnabled &&
      getVideoInputs &&
      getVideoInputs(async (videoInputs) => {
        setState({
          videoInputs,
          videoInputsLoaded: true,
          usersCurrentVideoInput: await getCurrentIO(videoInputs, () =>
            getCurrentVideoInput(state.usersCurrentVideoInput?.deviceId)
          ),
        });
      }).catch(() => {
        setState({
          videoInputsLoaded: true,
        });
      });
  }

  const handleAudioInputChange = async (audioInput, alwaysSetCurrent = false) => {
    if (audioInput?.deviceId === state.usersCurrentAudioInput?.deviceId) return true;
    if (setAudioInput) {
      const response = await setAudioInput(audioInput);
      if (response || alwaysSetCurrent) {
        setState({
          usersCurrentAudioInput: audioInput,
        });
        return true;
      }

      return false;
    }
  };

  const handleVideoInputChange = (videoInput) => {
    if (videoInput?.deviceId === state.usersCurrentVideoInput?.deviceId) return;
    setVideoInput && setVideoInput(videoInput);
    setState({
      usersCurrentVideoInput: videoInput,
    });
  };

  const handleAudioOutputChange = (audioOutput) => {
    if (audioOutput?.deviceId === state.usersCurrentAudioOutput?.deviceId) return;
    setAudioOutput && setAudioOutput(audioOutput);
    setState({
      usersCurrentAudioOutput: audioOutput,
    });
  };

  const setAVSettingsConfigured = () => {
    const avConnectedStatuses = {
      audioInputsReady: !!(
        state.audioInputs?.length && state.usersCurrentAudioInput !== noAudioInput
      ),
      videoInputsReady: !!(
        state.videoInputs?.length && state.usersCurrentVideoInput !== noVideoInput
      ),
    };
    setState({
      ...avConnectedStatuses,
    });
    _setAVSettingsConfigured(true);
    setConfiguringAVSettings(false);
  };

  const areAVSettingsConfigured = () => {
    return avSettingsConfiguredRef.current;
  };

  const setAudioOutputPermissionDenied = useCallback(() => {
    setState({
      audioOutputPermissionDenied: true,
    });
  }, []);

  const configureAVSettings = () => {
    setConfiguringAVSettings(true);
  };

  return (
    <AudioVideoContext.Provider
      value={{
        ...state,
        avSettingsConfigured: avSettingsConfigured,
        configuringAVSettings,
        configureAVSettings,
        areAVSettingsConfigured,
        handleAudioInputChange,
        handleVideoInputChange,
        handleAudioOutputChange,
        setAVSettingsConfigured,
        updateAudioOutputsState,
        setAudioOutputPermissionDenied,
      }}
    >
      {children}
    </AudioVideoContext.Provider>
  );
};

export default AudioVideoContext;
