export const vsmFeaturesList = [
  {
    featureName: "registration",
    enabled: true,
  },
  {
    featureName: "voting",
    enabled: true,
  },
  {
    featureName: "text-qa",
    enabled: true,
  },
  {
    featureName: "audio-qa",
    enabled: true,
  },
  {
    featureName: "rsl",
    enabled: true,
  },
  {
    featureName: "attendee-dial-in",
    enabled: true,
  },
  {
    featureName: "speaker-dial-in",
    enabled: true,
  },
  {
    featureName: "participant-dial-in",
    enabled: true,
  },
  {
    featureName: "guest-attendance",
    enabled: true,
  },
  {
    featureName: "shareholder-attendance",
    enabled: true,
  },
  {
    featureName: "speaker-participants",
    enabled: true,
  },
  {
    featureName: "support-participants",
    enabled: true,
  },
  {
    featureName: "host-participants",
    enabled: true,
  },
  {
    featureName: "board-observer-participants",
    enabled: true,
  },
  {
    featureName: "inspector-participants",
    enabled: true,
  },
  {
    featureName: "guest-speaker-email-verification",
    enabled: true,
  },
  {
    featureName: "archive-12-month",
    enabled: true,
  },
  {
    featureName: "recording-replay",
    enabled: true,
  },
  {
    featureName: "active-session-cap",
    enabled: true,
  },
  {
    featureName: "pre-event-qa",
    enabled: true,
  },
  {
    featureName: "meeting-materials",
    enabled: true,
  },
  {
    featureName: "vendor-support-broadridge",
    enabled: true,
  },
  {
    featureName: "post-event-report",
    enabled: true,
  },
  {
    featureName: "post-event-report",
    enabled: true,
  },
  {
    featureName: "microsite",
    enabled: true,
  },
];
