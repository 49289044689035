import { AssetUploads } from "../../hooks/assetUploads/assetUploads.definition";
import { AssetBroadcastStream } from "../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";

export interface AssetsPortalProps {
  assets: AssetUploads[];
  visible?: boolean;
  onAssetRendered: (stream: AssetBroadcastStream) => void;
  enablePollingInterval?: boolean;
}

export const AssetsPortalDataIds = {
  Base: "AssetsPortal",
};

export const AssetsPortalClassnames = {
  BaseVisible: "AssetsPortal",
  BaseHidden: "AssetsPortal_hidden",
};
