import { intersection, isArray } from "lodash";
import { UserRoles } from "../configurations/userRolesPermissions.configuration";

export function userHasPermittedRoles(userRoles, permittedRoles): boolean {
  if (allRolesPermitted(permittedRoles)) return true;
  return intersection(userRoles, permittedRoles).length > 0;
}

function allRolesPermitted(permittedRoles): boolean {
  return !!(isArray(permittedRoles) && permittedRoles.find((role) => role === UserRoles.ALL));
}
