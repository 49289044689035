import { SubscriptionHookOptions } from "@apollo/client";
import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import moment from "moment";
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useApolloEvents } from "../../apollo/apollo-events.hook";
import { getEventEndToNowDuration } from "../../utils/event.utils";
import { randomIntFromInterval } from "../../utils/numberGenerator.utils";
import {
  BroadcastContextStatus,
  useBroadcastStatusSubscription,
  useDualStreamStatusSubscription,
} from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { useUpdateEventStartPeriodSubscription } from "../../views/eventMicrosite/hooks/microsite.hook";
import { useQuestionStatusSubscription } from "../../views/questionsSlideOut/hooks/questionsSlideOut.hook";
import {
  DEFAULT_FALLBACK_POLL_INTERVAL,
  SubscriptionsContextState,
} from "./subscriptions.context.definition";
import EventContext from "../event/event.context";
import { useGetPublicEvent } from "../../services/attendeeApi/event";
import { Auth0Context } from "../auth0/auth0.context";
import { AppContext } from "../app/app.context";
import AttendeesContext from "../attendees/attendees.context";
import { useGetBroadcastStatus } from "../../services/attendeeApi/broadcast";

const useContextSub = (useSub: any, subOptions: SubscriptionHookOptions) => {
  const subCount = useRef(0);
  const [shouldSubscribe, setShouldSubscribe] = useState(false);
  const [subState, setSubState] = useState<any>();

  const subscribeTo = (subscribe: boolean) => {
    subCount.current = subscribe ? subCount.current + 1 : subCount.current - 1;
    setShouldSubscribe(!!subCount.current);
  };

  const { data } = useSub({
    ...subOptions,
    skip: !shouldSubscribe,
  });

  useEffect(() => {
    setSubState(data);
  }, [data]);

  return [shouldSubscribe, subState, setSubState, subscribeTo];
};

const SubscriptionsContext = createContext<Partial<SubscriptionsContextState>>({});

export const SubscriptionsProviderWithNoMemo = (
  props: BaseComponentWithChildrenProps
): JSX.Element => {
  const { isAttendeeAuthenticated } = useContext(Auth0Context);
  const { meetingId: eventMeetingId } = useContext(AppContext);
  const { eventDetails } = useContext(AttendeesContext);
  const { eventId } = useContext(EventContext);
  const [socketConnected, setSocketConnected] = useState(true);
  const [socketReconnected, setSocketReconnected] = useState(false);
  const [isBroadcastStatusPolling, setIsBroadcastStatusPolling] = useState(false);
  const meetingId = +eventMeetingId || eventId || null;
  const subOptions = { variables: { meetingId } };

  const { data: currentEventDetails, refetch: fetchEventDetails } = useGetPublicEvent({
    meetingId,
    skip: socketConnected || !meetingId || !isAttendeeAuthenticated,
    refreshInterval: DEFAULT_FALLBACK_POLL_INTERVAL,
  });

  // BROADCAST_STATUS_SUBSCRIPTION
  const [
    isBroadcastStatusSubActive,
    broadcastStatusData,
    setBroadcastStatusData,
    broadcastStatusSubscribe,
  ] = useContextSub(useBroadcastStatusSubscription, subOptions);

  // UPDATE_EVENT_START_PERIOD_SUBSCRIPTION
  const [
    isEventStartPeriodSubActive,
    eventStartData,
    setEventStartData,
    eventStartPeriodSubscribe,
  ] = useContextSub(useUpdateEventStartPeriodSubscription, subOptions);

  // QUESTION_STATUS_SUBSCRIPTION
  const [
    isQuestionStatusSubActive,
    questionStatusData,
    setQuestionStatusData,
    questionStatusSubscribe,
  ] = useContextSub(useQuestionStatusSubscription, subOptions);

  // DUAL_STREAM_STATUS_SUBSCRIPTION
  const [, dualStreamStatusData, , dualStreamStatusSubscribe] = useContextSub(
    useDualStreamStatusSubscription,
    subOptions
  );

  const { data: broadcastStatusUpdated, refetch: refetchEventBroadcastStatus } =
    useGetBroadcastStatus({
      meetingId,
      skip: !socketConnected || !isAttendeeAuthenticated,
      refreshInterval:
        isAttendeeAuthenticated && isBroadcastStatusPolling && randomIntFromInterval(25, 35) * 1000,
    });

  useEffect(() => {
    if (!broadcastStatusUpdated?.status) return;
    setBroadcastStatusData({
      onBroadcastStatusUpdated: {
        ...broadcastStatusUpdated,
      },
    });
  }, [broadcastStatusUpdated, setBroadcastStatusData]);

  const refetchEventDetails = useCallback(() => {
    if (!meetingId || !isAttendeeAuthenticated) return;
    fetchEventDetails();
    refetchEventBroadcastStatus();
  }, [isAttendeeAuthenticated, meetingId, fetchEventDetails, refetchEventBroadcastStatus]);

  useEffect(() => {
    if (!socketReconnected || !socketConnected) return;
    refetchEventDetails();
  }, [socketReconnected, refetchEventDetails, socketConnected]);

  const stopBroadcastStatusPolling = () => {
    if (isBroadcastStatusPolling) {
      setIsBroadcastStatusPolling(false);
    }
  };

  const startBroadcastStatusPolling = () => {
    const hoursAfterEventEnd = getEventEndToNowDuration(moment(eventDetails?.eventEnd)).asHours();
    const hoursBeforeEventStart = moment
      .duration(moment(eventDetails?.eventStart).diff(moment()))
      .asHours();
    const isEventStartTimeActive = hoursBeforeEventStart < 0;
    const isEventEndTimeActive = hoursAfterEventEnd > 0;

    const isBroadcastSpilledOver = !!(
      isEventEndTimeActive &&
      (eventDetails?.broadcastUrl ||
        eventDetails?.broadcastContext === BroadcastContextStatus.PAUSED)
    );

    if (
      !isBroadcastStatusPolling &&
      isEventStartTimeActive &&
      (!isEventEndTimeActive || isBroadcastSpilledOver)
    ) {
      setIsBroadcastStatusPolling(true);
    } else if (
      !isEventStartTimeActive &&
      (isEventEndTimeActive || !isBroadcastSpilledOver) &&
      isBroadcastStatusPolling
    ) {
      stopBroadcastStatusPolling();
    }
  };

  useEffect(() => {
    if (eventDetails && meetingId) startBroadcastStatusPolling();
  }, [eventDetails, meetingId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const eventData = currentEventDetails;
    if (isBroadcastStatusSubActive && !!eventData) {
      setBroadcastStatusData({
        onBroadcastStatusUpdated: {
          status: eventData?.broadcastStatus,
          context: eventData?.broadcastContext,
          startTime: eventData?.broadcastStartTime,
          broadcastUrl: eventData?.broadcastUrl,
        },
      });
    }
    if (isEventStartPeriodSubActive && !!eventData?.branding) {
      const openRegistration = eventData.branding?.registrationPageBranding?.openRegistration;
      if (openRegistration) {
        const openRegistrationMins = parseInt(openRegistration || "") * 60 * 1000;
        const eventStart = eventData?.eventStart.valueOf();

        if (eventStart - Date.now() - openRegistrationMins > 0) {
          setEventStartData({ onEventStartPeriodUpdated: eventData });
        }
      }
    }
    if (!!eventData?.settings) {
      if (isQuestionStatusSubActive) setQuestionStatusData({ onEventRslSettingUpdated: eventData });
    }
  }, [currentEventDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useApolloEvents({
    onConnected: () => setSocketConnected(true),
    onReconnected: () => {
      setSocketConnected(true);
      setSocketReconnected(true);
    },
    onDisconnected: () => {
      setSocketConnected(false);
      stopBroadcastStatusPolling();
      setSocketReconnected(false);
    },
  });

  return (
    <SubscriptionsContext.Provider
      value={{
        onBroadcastStatusUpdated: broadcastStatusData?.onBroadcastStatusUpdated,
        broadcastStatusSubscribe,
        onEventStartPeriodUpdated: eventStartData?.onEventStartPeriodUpdated,
        eventStartPeriodSubscribe,
        onEventQuestionSettingUpdated: questionStatusData?.onEventQuestionSettingUpdated,
        questionStatusSubscribe,
        startBroadcastStatusPolling,
        stopBroadcastStatusPolling,
        onDualStreamStatusUpdated: dualStreamStatusData?.onDualStreamStatusUpdated,
        dualStreamStatusSubscribe,
      }}
    >
      {props?.children}
    </SubscriptionsContext.Provider>
  );
};

export const SubscriptionsProvider = memo(SubscriptionsProviderWithNoMemo);

export default SubscriptionsContext;
