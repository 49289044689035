import { EventAssetType } from "../../../../hooks/assets/assets.hook.definition";
import { AssetType, AssetData } from "../../../../hooks/assetUploads/assetUploads.definition";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { Connection } from "../../../../opentok-react/types/Connection.type";
import { Undefinable } from "../../../../utils/types/tsTypes";

export enum SelectionType {
  CAMERA = "camera",
  SCREEN = "screen",
  CUSTOM = "custom",
  PSTN = "pstn",
}

export enum StreamOriginType {
  P2P = "peer-to-peer",
  CLIENT = "client",
}

export interface BroadcastStream
  extends ICommonBroadcastStream,
    IClientBroadcastStream,
    IBroadcastP2PStream {}

/** Basic broadcast stream props */
export interface ICommonBroadcastStream {
  readonly id: string;
  readonly name: string;
  readonly displayName?: string;
  readonly isLocalPublisher: boolean;
  readonly streamOrigin: StreamOriginType;
}

/** Stream props for locally created streams */
export interface IClientBroadcastStream {
  sourceUrl: string;
  readonly isV2AssetStream: boolean;
}

/** Stream props for p2p generated streams */
export interface IBroadcastP2PStream {
  // ## => After OT-PR -> deprecate or move to ClientBroadcastStream
  readonly assetData?: AssetData;
  readonly assetType?: EventAssetType | AssetType;
  readonly isAssetStream: boolean;
  readonly isAudioAsset: boolean;
  readonly isSlideAsset: boolean;
  readonly isVideoAsset: boolean;
  // <= ##

  readonly connection: Connection;

  readonly hasAudio: boolean;
  readonly hasMediaStateInfo: boolean;
  readonly hasVideo: boolean;

  readonly isCameraStream: boolean;
  readonly isPSTNStream: boolean;
  readonly isScreenShare: boolean;
  readonly isTempStream: boolean;

  readonly type: SelectionType | string;

  readonly getVendorStream: () => any;
  readonly hasAudioWithMediaState: (participant: Partial<Participant>) => boolean;
  readonly markStreamSubscribed: () => void;
  readonly videoElement: () => Undefinable<HTMLMediaElement>;
  readonly videoMediaStream: () => Promise<MediaStream>;
}
