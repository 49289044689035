export class ErrorModel {
  visible: boolean;
  icon = "ni-warning-4pt";
  message = "";
  prop = "";

  constructor(visible: boolean = false, message?: string, prop?: string) {
    this.message = message;
    this.visible = visible;
    this.icon = "ni-warning-4pt";
    this.prop = prop;
  }
}
