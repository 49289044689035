import { gql } from "@apollo/client";

const EventUserFragment = gql`
  fragment EventUser on User {
    id
    email
    firstName
    lastName
  }
`;

const EventGuestFragment = gql`
  fragment EventGuest on Guest {
    email
    guestType
    firstName
    lastName
    verificationCode {
      expiry
      code {
        encrypted
        iv
        tag
      }
    }
  }
`;

export const EventSpeakerBioFragment = gql`
  fragment EventSpeakerBio on SpeakerBio {
    id
    name
    role
    bio
    photo
    eventOnly
    position
    active
  }
`;

const EventConferenceFragment = ({ isDualStreamEnabled }) => gql`
  fragment EventConference on EventConference {
    status
    ${isDualStreamEnabled ? "dualStreamStatus" : ""}
    broadcastRecordings {
      url
      hide
    }
    customRecordings {
      name
      displayName
      assetType
      assetFormat
      size
      duration
      recordingId
      chapters
    }
    hideCustomRecording
    eventRecordings
    context
    startTime
    sessionId
    vendor
    broadcastUrl
    ${isDualStreamEnabled ? "backupBroadcastUrl" : ""}
    sip {
      speakerDialInNumber
      pin {
        tag
        encryptedData
        initVector
      }
    }
    sessionRecording {
      status
      updateTime
    }
    ingestEndpoint
    streamKey
    playbackUrl
  }
`;

const EventBrandingFragment = gql`
  fragment EventBranding on UpdateEventPageBranding {
    controlPanelBranding {
      primaryColor
      secondaryColor
      primaryFont {
        name
        displayName
        fileType
      }
      secondaryFont {
        name
        displayName
        fileType
      }
      primaryFontWeight
      secondaryFontWeight
      publishSchedule
      publishScheduleStatus
      videoRecording
      backgroundImage {
        displayName
        fileType
        name
      }
      companyLogo {
        displayName
        fileType
        name
      }
      hideMeetingReplay
    }
    welcomePageBranding {
      title
      description
      proxyWebsiteLink
      displayTime
    }
    registrationPageBranding {
      broadridgeTelephoneNumber
      broadridgeInternationalTelephoneNumber
      title
      description
      openRegistration
      proxyWebsiteLink
      termsAndConditionsUrl
    }
    preEventPageBranding {
      message
      lobbyMessage
      musicOption
    }
    thankYouPageBranding {
      title
      description
    }
    postEventPageBranding {
      meetingDetails
    }
    attendeeConsoleBranding {
      images {
        eventLogo {
          name
          displayName
          fileType
          signedUrl
        }
        eventFavicon {
          name
          displayName
          fileType
        }
      }
      headerColors {
        background
        text
      }
      footerColors {
        background
        primaryButton
        primaryButtonText
      }
      mediaBarColors {
        background
        controls
      }
    }
  }
`;

const EventPreviewOptionsFragment = gql`
  fragment EventPreviewOptions on UpdateEventPagePreview {
    welcomePageBranding {
      title
      description
      proxyWebsiteLink
      displayTime
    }
    registrationPageBranding {
      broadridgeTelephoneNumber
      broadridgeInternationalTelephoneNumber
      title
      description
      openRegistration
      proxyWebsiteLink
      termsAndConditionsUrl
    }
    preEventPageBranding {
      message
      lobbyMessage
      musicOption
    }
    thankYouPageBranding {
      title
      description
    }
    postEventPageBranding {
      meetingDetails
    }
  }
`;

const EventAssetFragment = gql`
  fragment EventAsset on EventAsset {
    name
    displayName
    assetType
    assetFormat
    size
    duration
  }
`;

const AssetUploadsFragment = gql`
  fragment AssetUploads on EventAssetV2 {
    id
    name
    displayName
    contentType
    assetType
    assetExt
    uploadDate
    size
    context
  }
`;

const EventSettingsFragment = gql`
  fragment EventSettings on EventSettings {
    questionEnabled
    rslEnabled
    votingEnabled
  }
`;

const EventGroupFragment = gql`
  fragment EventGroup on EventGroup {
    name
    identifier
    participants {
      identifier
      streamType
    }
    displayName
  }
`;

const EventPasswordFragment = gql`
  fragment EventPassword on EventPassword {
    decrypted
    encrypted
  }
`;

const EventMaterialFragment = gql`
  fragment EventMaterial on EventMeetingMaterial {
    name
    displayName
    materialSize
    materialType
    materialId
  }
`;

const EventConfigurationFragment = ({ isDisasterRecoveryEnabled, isDualStreamEnabled }) => gql`
  fragment EventConfiguration on EventConfiguration {
    question {
      questionsPerAttendee
      charLimit
      text
      qaLineEnabled
      qaLine
      questionsPerAttendee
    }
    guest {
      enabled
    }
    rsl {
      hostingType
      enabled
    }
    postEvent {
      enableReplay
      enableArchive
      feedbackSurvey
    }
    video {
      enabled
    }
    dialIn {
      speaker
      operatorLineEnabled
      operatorLine
    }
    shareholder {
      maxSessions
    }
    emailVerification {
      enabled
    }
    presenterControlledSlides {
      enabled
    }
    layoutManager {
      enabled
    }
    speakerBios {
      enabled
    }
    broadcastOutput {
      externalEnabled
    }
    ${isDisasterRecoveryEnabled ? "disasterRecovery { enabled redirectUrl }" : ""}
    ${isDualStreamEnabled ? "dualStream { enabled region }" : ""}
  }
`;

const EventFragment = ({ isDisasterRecoveryEnabled = false, isDualStreamEnabled = false }) => gql`
  ${EventUserFragment}
  ${EventGuestFragment}
  ${EventConferenceFragment({ isDualStreamEnabled })}
  ${EventBrandingFragment}
  ${EventPreviewOptionsFragment}
  ${EventAssetFragment}
  ${AssetUploadsFragment}
  ${EventSettingsFragment}
  ${EventGroupFragment}
  ${EventPasswordFragment}
  ${EventMaterialFragment}
  ${EventConfigurationFragment({ isDisasterRecoveryEnabled, isDualStreamEnabled })}
  ${EventSpeakerBioFragment}

  fragment Event on EventPopulated {
    id
    meetingId
    brMeetingId
    title
    description
    company
    companyId
    eventStart
    eventEnd
    createdBy
    createdAt
    updatedAt
    updatedBy
    eventTz
    status
    region
    organizationId
    conference {
      ...EventConference
    }
    branding {
      ...EventBranding
    }
    previewOptions {
      ...EventPreviewOptions
    }
    assets {
      ...EventAsset
    }
    assetUploads {
      ...AssetUploads
    }
    eventType
    notes
    settings {
      ...EventSettings
    }
    groups {
      ...EventGroup
    }
    customIdentifier
    speakers {
      ...EventUser
    }
    supports {
      ...EventUser
    }
    observers {
      ...EventUser
    }
    inspectors {
      ...EventUser
    }
    hosts {
      ...EventUser
    }
    guests {
      ...EventGuest
    }
    password {
      ...EventPassword
    }
    materials {
      ...EventMaterial
    }
    configuration {
      ...EventConfiguration
    }
    publicPages {
      adminPostEventReport {
        access {
          ...EventPassword
        }
      }
    }
    registrationFields {
      id
      _templateId
      _companyId
      title
      placeholder
      defaultValue
      required
      hidden
      condition {
        _registrationFieldId
        conditionalValue
      }
      registrationFieldType
      position
      options {
        id
        value
        default
        isCustom
      }
      optionSort {
        enabled
        direction
        order
      }
    }
    speakerBios {
      ...EventSpeakerBio
    }
    broadcastSource
    eventCategory
  }
`;

const PublicEventDetailsFragment = ({ isDisasterRecoveryEnabled = false, isDualStreamEnabled = false }) => gql`
  ${EventBrandingFragment}
  ${EventConfigurationFragment({ isDisasterRecoveryEnabled, isDualStreamEnabled })}
  ${EventMaterialFragment}
  ${EventPreviewOptionsFragment}
  ${EventSettingsFragment}

  fragment PublicEvent on PublicEventDetails {
    backupBroadcastUrl
    branding {
      ...EventBranding
    }
    brMeetingId
    broadcastContext
    broadcastRecordings {
      url
      hide
    }
    broadcastStatus
    broadcastUrl
    companyId
    companyIdentifier
    companyName
    configuration {
      ...EventConfiguration
    }
    customIdentifier
    customRecordings
    description
    dualStreamStatus
    eventEnd
    eventStart
    eventType
    eventTz
    hideCustomRecording
    id
    materials {
      ...EventMaterial
    }
    meetingId
    msToStart
    notes
    organizationId
    previewOptions {
      ...EventPreviewOptions
    }
    registrationFields
    settings {
      ...EventSettings
    }
    speakerBios
    status
    title
  }
`;

export const EVENT_FRAGMENTS = {
  AssetUploads: AssetUploadsFragment,
  Event: EventFragment,
  EventAsset: EventAssetFragment,
  EventBranding: EventBrandingFragment,
  EventConference: EventConferenceFragment,
  EventGroup: EventGroupFragment,
  EventGuest: EventGuestFragment,
  EventMaterial: EventMaterialFragment,
  EventPassword: EventPasswordFragment,
  EventPreviewOptions: EventPreviewOptionsFragment,
  EventSettings: EventSettingsFragment,
  EventSpeakerBio: EventSpeakerBioFragment,
  EventUser: EventUserFragment,
  PublicEventDetails: PublicEventDetailsFragment,
};
