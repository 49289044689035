import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { UserEventRole, UserRoles } from "../../configurations/userRolesPermissions.configuration";

export {
  ParticipantSignalEnum,
  useParticipantSignalMutation,
  useParticipantSignalSubscription,
  AllParticipantsSignalEnum,
  useAllParticipantsSignalMutation,
  useAllParticipantsSignalSubscription,
} from "./participants.hook.signal";

export const participantsMergePolicy = {
  getParticipants: {
    merge: false,
  },
};

export enum ParticipantStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ParticipantSourceEnum {
  PSTN = "pstn",
  WEB = "web",
}

export interface ParticipantMediaSettings {
  hasAudio: boolean;
  hasVideo: boolean;
  hasSharedScreen: boolean;
  audioInputsReady: boolean;
  videoInputsReady: boolean;
  hasPermissionsToPublish: boolean;
}
export interface Participant {
  id: string;
  eventRole: UserEventRole;
  platformRole: UserRoles;
  name: string;
  customName: string;
  email: string;
  phoneNumber: string;
  status: ParticipantStatusEnum;
  source: ParticipantSourceEnum;
  connection: {
    connectionId: string;
  };
  mediaSettings: ParticipantMediaSettings;
  createdAt: string;
  updatedAt: string;
}

export interface ParticipantsQueryData {
  getParticipants: Partial<Participant>[];
}

export interface ParticipantsQueryVars {
  meetingId: number;
}

export const GET_PARTICIPANTS = gql`
  query getParticipants($meetingId: Int) {
    getParticipants(meetingId: $meetingId, status: ACTIVE) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
      source
    }
  }
`;

export function useParticipantsQuery() {
  return useLazyQuery<ParticipantsQueryData, ParticipantsQueryVars>(GET_PARTICIPANTS);
}

export interface CreateParticipantMutationData {
  createParticipant: Partial<Participant>;
}

export interface CreateParticipantMutationVars {
  meetingId: number;
  eventRole?: string;
  platformRole?: string;
  name: string;
  email: string;
  // phoneNumber: string;
  connection: {
    connectionId: string;
  };
  wsConnectionId: string;
  mediaSettings: ParticipantMediaSettings;
  source: ParticipantSourceEnum;
}

export const CREATE_PARTICIPANT_MUTATION = gql`
  mutation createParticipant(
    $meetingId: Int
    $eventRole: String
    $platformRole: String
    $name: String
    $email: String
    $connection: ConnectionInput
    $wsConnectionId: String
    $mediaSettings: MediaSettingsInput
    $source: ParticipantSourceEnum
  ) {
    createParticipant(
      meetingId: $meetingId
      eventRole: $eventRole
      platformRole: $platformRole
      name: $name
      email: $email
      connection: $connection
      wsConnectionId: $wsConnectionId
      mediaSettings: $mediaSettings
      source: $source
    ) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
    }
  }
`;

export function useCreateParticipantMutation(
  options?: MutationHookOptions<CreateParticipantMutationData, CreateParticipantMutationVars>
) {
  return useMutation<CreateParticipantMutationData, CreateParticipantMutationVars>(
    CREATE_PARTICIPANT_MUTATION,
    options
  );
}

export interface UpdateParticipantStatusMutationData {
  updateParticipantStatus: Partial<Participant>;
}

export interface UpdateParticipantStatusMutationVars {
  meetingId: number;
  participantId: string;
  status: ParticipantStatusEnum;
}

export const UPDATE_PARTICIPANT_STATUS_MUTATION = gql`
  mutation updateParticipantStatus(
    $meetingId: Int
    $participantId: String
    $status: ParticipantStatusEnum
  ) {
    updateParticipantStatus(meetingId: $meetingId, participantId: $participantId, status: $status) {
      id
      status
    }
  }
`;

export function useUpdateParticipantStatusMutation(
  options?: MutationHookOptions<
    UpdateParticipantStatusMutationData,
    UpdateParticipantStatusMutationVars
  >
) {
  return useMutation<UpdateParticipantStatusMutationData, UpdateParticipantStatusMutationVars>(
    UPDATE_PARTICIPANT_STATUS_MUTATION,
    options
  );
}

export interface UpdateParticipantNameMutationData {
  updateParticipantName: Partial<Participant>;
}

export interface UpdateParticipantNameMutationVars {
  meetingId: number;
  participantId: string;
  customName: string;
}

export const UPDATE_PARTICIPANT_NAME_MUTATION = gql`
  mutation updateParticipantName($meetingId: Int, $participantId: String, $customName: String) {
    updateParticipantName(
      meetingId: $meetingId
      participantId: $participantId
      customName: $customName
    ) {
      id
      customName
    }
  }
`;

export function useUpdateParticipantNameMutation(
  options?: MutationHookOptions<
    UpdateParticipantNameMutationData,
    UpdateParticipantNameMutationVars
  >
) {
  return useMutation<UpdateParticipantNameMutationData, UpdateParticipantNameMutationVars>(
    UPDATE_PARTICIPANT_NAME_MUTATION,
    options
  );
}

export interface UpdateParticipantMediaSettingsMutationData {
  updateParticipantMediaSettings: Partial<Participant>;
}

export interface UpdateParticipantMediaSettingsMutationVars {
  meetingId: number;
  participantId: string;
  hasAudio: boolean;
  hasVideo: boolean;
  hasSharedScreen: boolean;
  audioInputsReady: boolean;
  videoInputsReady: boolean;
}

export const UPDATE_PARTICIPANT_MEDIA_SETTINGS_MUTATION = gql`
  mutation updateParticipantMediaSettings(
    $meetingId: Int
    $participantId: String
    $hasAudio: Boolean
    $hasVideo: Boolean
    $hasSharedScreen: Boolean
    $audioInputsReady: Boolean
    $videoInputsReady: Boolean
  ) {
    updateParticipantMediaSettings(
      meetingId: $meetingId
      participantId: $participantId
      hasAudio: $hasAudio
      hasVideo: $hasVideo
      hasSharedScreen: $hasSharedScreen
      audioInputsReady: $audioInputsReady
      videoInputsReady: $videoInputsReady
    ) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
    }
  }
`;

export function useUpdateParticipantMediaSettingsMutation(
  options?: MutationHookOptions<
    UpdateParticipantMediaSettingsMutationData,
    UpdateParticipantMediaSettingsMutationVars
  >
) {
  return useMutation<
    UpdateParticipantMediaSettingsMutationData,
    UpdateParticipantMediaSettingsMutationVars
  >(UPDATE_PARTICIPANT_MEDIA_SETTINGS_MUTATION, options);
}

export const PARTICIPANT_CREATED_SUBSCRIPTION = gql`
  subscription onParticipantCreated($meetingId: Int) {
    onParticipantCreated(meetingId: $meetingId) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      source
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
    }
  }
`;

interface ParticipantSubVars {
  meetingId: number;
}

export interface ParticipantCreatedSubData {
  onParticipantCreated: Partial<Participant>;
}

export function useParticipantCreatedSubscription(
  options?: SubscriptionHookOptions<ParticipantCreatedSubData, ParticipantSubVars>
) {
  return useSubscription<ParticipantCreatedSubData, ParticipantSubVars>(
    PARTICIPANT_CREATED_SUBSCRIPTION,
    options
  );
}

export const PARTICIPANT_STATUS_SUBSCRIPTION = gql`
  subscription onParticipantStatusUpdated($meetingId: Int) {
    onParticipantStatusUpdated(meetingId: $meetingId) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
    }
  }
`;

export interface ParticipantStatusSubData {
  onParticipantStatusUpdated: Partial<Participant>;
}

export function useParticipantStatusSubscription(
  options?: SubscriptionHookOptions<ParticipantStatusSubData, ParticipantSubVars>
) {
  return useSubscription<ParticipantStatusSubData, ParticipantSubVars>(
    PARTICIPANT_STATUS_SUBSCRIPTION,
    options
  );
}

export const PARTICIPANT_NAME_SUBSCRIPTION = gql`
  subscription onParticipantNameUpdated($meetingId: Int) {
    onParticipantNameUpdated(meetingId: $meetingId) {
      id
      customName
    }
  }
`;

export interface ParticipantNameSubData {
  onParticipantNameUpdated: Partial<Participant>;
}

export function useParticipantNameSubscription(
  options?: SubscriptionHookOptions<ParticipantNameSubData, ParticipantSubVars>
) {
  return useSubscription<ParticipantNameSubData, ParticipantSubVars>(
    PARTICIPANT_NAME_SUBSCRIPTION,
    options
  );
}

export const PARTICIPANT_MEDIA_SETTINGS_SUBSCRIPTION = gql`
  subscription onParticipantMediaSettingsUpdated($meetingId: Int) {
    onParticipantMediaSettingsUpdated(meetingId: $meetingId) {
      id
      eventRole
      platformRole
      name
      customName
      email
      phoneNumber
      status
      connection {
        connectionId
      }
      mediaSettings {
        hasAudio
        hasVideo
        hasSharedScreen
        audioInputsReady
        videoInputsReady
        hasPermissionsToPublish
      }
    }
  }
`;

export interface ParticipantMediaSettingsSubData {
  onParticipantMediaSettingsUpdated: Partial<Participant>;
}

export function useParticipantMediaSettingsSubscription(
  options?: SubscriptionHookOptions<ParticipantMediaSettingsSubData, ParticipantSubVars>
) {
  return useSubscription<ParticipantMediaSettingsSubData, ParticipantSubVars>(
    PARTICIPANT_MEDIA_SETTINGS_SUBSCRIPTION,
    options
  );
}

//Formula to calc timeout: Math.min(random * minTimeout * Math.pow(factor, attempt), maxTimeout)
export const RETRY_DEFAULTS = {
  retries: 5,
  factor: 2,
  minTimeout: 1 * 1000,
  maxTimeout: 60 * 1000,
  randomize: true,
};

export const MEDIA_SYNC_INTERVAL = 5 * 1000;
