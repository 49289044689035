import { useState, useEffect, useRef } from "react";
import { isFunction, isObject } from "lodash";
import { SubscriberHookProps } from "./subscriber.definition";
import { OTSubscriberProps } from "../../components/OTSubscriber/OTSubscriber.definition";

import { isStreamLocalPublisher, isShareScreenStream } from "../../utils/stream.utils";
import { OTStreamType, OTSubscriberType, SubscriberProperties } from "../../types";
import { promiseTimeout, RETRY_DEFAULT_DELAY } from "../../../utils";
import { useSafeState } from "../../../utils/react.utils";

/** Create a new subscription for a stream */
function newSubscriber(newSubscriberProps, throwError = true) {
  return new Promise((resolve, reject) => {
    const { session, stream, subscriberContainerRef, properties } = newSubscriberProps;

    const subscriber = session.subscribe(stream, subscriberContainerRef, properties, (err) => {
      if (err) {
        if (throwError) reject(err);
        else resolve(null);
      } else {
        resolve(subscriber);
      }
    });

    const eventHandlers = getNewSubscriberEventHandlers(newSubscriberProps);
    subscriber.on(eventHandlers);
  });
}

/** Get new subscription event handlers to register */
function getNewSubscriberEventHandlers(newSubscriberProps) {
  const { setStreamSubscribed, subscriberEventHandlers } = newSubscriberProps;

  return {
    videoElementCreated: () => {
      if (isFunction(setStreamSubscribed)) setStreamSubscribed();
    },
    disconnected: (event) => {
      console.warn("Subscriber:disconnected", JSON.stringify(event));
    },
    videoDisabled: (event) => {
      if (event?.reason === "publishVideo") return;
      console.error("Subscriber:videoDisabled", JSON.stringify(event));
    },
    videoEnabled: (event) => {
      if (event?.reason === "publishVideo") return;
      console.log(event);
    },
    // When subscriber connected to stream
    connected: (event) => {
      console.log(event);
    },
    ...subscriberEventHandlers,
  };
}

/** Get properties for opentok subscription */
function getOTSubscribeProps(
  initNewSubscriberProps: OTSubscriberProps,
  stream: OTStreamType
): SubscriberProperties {
  const properties = {
    ...initNewSubscriberProps,
    showControls: false,
    insertMode: "append",
    fitMode: "cover",
  } as SubscriberProperties;

  try {
    properties.name = JSON.parse(stream.connection.data)?.name;
  } catch {
    properties.name = null;
  }

  if (isShareScreenStream(stream)) {
    properties.style = { ...properties.style, audioLevelDisplayMode: "off" };
    properties.subscribeToAudio = false;
  }
  if (isStreamLocalPublisher(stream)) {
    properties.subscribeToAudio = false;
  }

  return properties;
}

export default function useSubscriberHook(props: SubscriberHookProps) {
  const [OTSubscriber, setSubscriber, OTSubscriberRef] = useSafeState<OTSubscriberType>(null);
  const [inRetry, setInRetry] = useState(false);

  const audioSubscriptionIntervalRuns = useRef<number>(0);

  useEffect(
    function onSubscriberCreated() {
      if (OTSubscriber) {
        props?.onSubscribe?.(OTSubscriber);
      }
    },
    [OTSubscriber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function subscribeToAudioOnInterval() {
      const interval = setInterval(() => {
        if (audioSubscriptionIntervalRuns.current >= 5) {
          clearInterval(interval);
        } else if (OTSubscriber && !isStreamLocalPublisher(OTSubscriber.stream)) {
          audioSubscriptionIntervalRuns.current = audioSubscriptionIntervalRuns.current + 1;
          OTSubscriber?.subscribeToAudio(true);
        }
      }, 5000);
      return () => clearInterval(interval);
    },
    [OTSubscriber]
  );

  async function createSubscriber(
    createSubscriberProps: OTSubscriberProps,
    subscriberContainerRef: any
  ) {
    const { stream, session, subscriberEventHandlers, setStreamSubscribed } = props;

    if (!session || !stream || inRetry) {
      setSubscriber(null);
      return;
    }

    try {
      const OTSubscribeProps = getOTSubscribeProps(
        createSubscriberProps.initSubscriberProperties,
        stream
      );

      const newSubscriberProps = {
        session,
        stream,
        subscriberContainerRef,
        subscriberEventHandlers,
        properties: OTSubscribeProps,
        setStreamSubscribed,
      };

      // Attempt to create a suscription for a tream with max retry
      const maxRetryAttempts = createSubscriberProps.maxRetryAttempts || 7;
      setInRetry(true);

      let subscriber: any;
      for (let i = 0; i < maxRetryAttempts; i++) {
        if (!subscriber) {
          await promiseTimeout(RETRY_DEFAULT_DELAY * i);
          subscriber = await newSubscriber(newSubscriberProps, i === maxRetryAttempts - 1);
        }
      }
      setInRetry(false);
      if (!subscriber) throw new Error("Unable to create subscriber");

      // Callbacks for successful subscription
      props?.onSubscribe?.(subscriber);

      setSubscriber(subscriber);
    } catch (err) {
      console.error("Failed to create subscriber: ", err);
      if (isFunction(createSubscriberProps.onError)) {
        createSubscriberProps.onError(err);
      }
    }
  }

  function getOTSubscriber() {
    if (OTSubscriberRef.current) return OTSubscriberRef.current;
  }

  function destroySubscriber(session, subscriberProps): Promise<void> {
    if (OTSubscriberRef.current && session) {
      return new Promise((resolve) => {
        OTSubscriberRef.current.once("destroyed", () => {
          if (subscriberProps.eventHandlers && isObject(subscriberProps.eventHandlers)) {
            OTSubscriberRef.current.off(subscriberProps.eventHandlers);
          }
          resolve();
        });
        session.unsubscribe(OTSubscriberRef.current);
      });
    }
  }

  return { OTSubscriber, inRetry, createSubscriber, destroySubscriber, getOTSubscriber };
}
