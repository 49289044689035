import { find, isArray } from "lodash";
import { useState, useRef, useCallback, useMemo } from "react";
import { useStateRef } from "@q4/nimbus-ui";
import { AssetType } from "../../../../hooks/assetUploads/assetUploads.definition";
import { OTStreamTypes } from "../../../../opentok-react/types";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { UpdateLayoutParams } from "../../vendors/openTok/services/openTokSignaling.service";
import {
  CarouselStreamSelectionProps,
  CarouselStreamSelectionModel,
  SelectionModel,
  SelectionType,
  StreamSelection,
  SelectionModelV2,
  LayoutType,
  SelectedStream,
  SelectionStreamType,
} from "./carouselStreamSelection.definition";

export default function useCarouselStreamSelection(
  props: CarouselStreamSelectionProps
): CarouselStreamSelectionModel {
  // needs mediaOnly passed in as prop for selectionV2 conversion in layoutBuilder
  const { cameraStreamSelectionLimit, mediaStreamSelectionLimit, mediaOnly } = props;

  // Means to avoid stale closure
  const currentSelectionRef = useRef<SelectionModel>({
    mediaSelection: [],
    cameraSelection: [],
  });
  const [currentSelection, setCurrentSelection] = useState<SelectionModel>({
    mediaSelection: [],
    cameraSelection: [],
  });

  const [currentStagedSelection, currentStagedSelectionRef, setCurrentlyStaged] =
    useStateRef<SelectionModel>({
      mediaSelection: [],
      cameraSelection: [],
    });

  function stageCurrentSelection(): SelectionModel {
    setCurrentlyStaged(currentSelectionRef.current);
    return currentSelectionRef.current;
  }

  function stageSelection(selection: SelectionModel): void {
    const stagedSelection = selection || {
      mediaSelection: [],
      cameraSelection: [],
    };
    setCurrentlyStaged(stagedSelection);
  }

  function clearSelection(): void {
    const stagedSelection = {
      mediaSelection: [],
      cameraSelection: [],
    };
    setCurrentlyStaged(stagedSelection);
  }

  function canSelect(stream: BroadcastStream): boolean {
    let baseRequirement;
    if (isMediaSelection(stream)) {
      baseRequirement =
        !!(currentSelectionRef.current.mediaSelection.length < mediaStreamSelectionLimit) &&
        !alreadySelected(currentSelectionRef.current.mediaSelection, stream);
    } else {
      baseRequirement =
        !!(currentSelectionRef.current.cameraSelection.length < cameraStreamSelectionLimit) &&
        !alreadySelected(currentSelectionRef.current.cameraSelection, stream);
    }

    return baseRequirement && checkSelectionValidityIfAsset(stream);
  }

  function select(stream: BroadcastStream): void {
    if (!canSelect(stream)) return;

    if (isMediaSelection(stream)) {
      currentSelectionRef.current = {
        ...currentSelectionRef.current,
        mediaSelection: currentSelectionRef.current.mediaSelection.concat(stream),
      };
    } else {
      currentSelectionRef.current = {
        ...currentSelectionRef.current,
        cameraSelection: currentSelectionRef.current.cameraSelection.concat(stream),
      };
    }

    return setCurrentSelection(() => currentSelectionRef.current);
  }

  function unselect(stream: BroadcastStream): void {
    currentSelectionRef.current = {
      ...currentSelectionRef.current,
      mediaSelection: currentSelectionRef.current.mediaSelection.filter(
        (selection) => selection.id !== stream.id
      ),
      cameraSelection: currentSelectionRef.current.cameraSelection.filter(
        (selection) => selection.id !== stream.id
      ),
    };
    return setCurrentSelection(() => currentSelectionRef.current);
  }

  function unselectAll(): void {
    currentSelectionRef.current = { mediaSelection: [], cameraSelection: [] };
    setCurrentSelection(() => currentSelectionRef.current);
  }

  function isSelected(stream: BroadcastStream): boolean {
    if (isMediaSelection(stream)) {
      return alreadySelected(currentSelectionRef.current.mediaSelection, stream);
    } else {
      return alreadySelected(currentSelectionRef.current.cameraSelection, stream);
    }
  }

  function getSelectionOrder(stream: BroadcastStream): number {
    if (isMediaSelection(stream)) {
      return normalizeArrayIndex(
        getSelectionIndex(currentSelectionRef.current.mediaSelection, stream)
      );
    }
    return normalizeArrayIndex(
      getSelectionIndex(currentSelectionRef.current.cameraSelection, stream)
    );
  }

  const alreadySelected = useCallback(
    (searchArray: Array<BroadcastStream>, stream: BroadcastStream): boolean => {
      return getSelectionIndex(searchArray, stream) !== -1;
    },
    []
  );

  function getSelectionIndex(searchArray: Array<BroadcastStream>, stream: BroadcastStream): number {
    return searchArray.findIndex((searchStreamElement) => searchStreamElement.id === stream.id);
  }

  function getSelectionType(stream: BroadcastStream): SelectionType {
    if (stream?.type === OTStreamTypes.Camera) return SelectionType.CAMERA;
    if (stream?.type === OTStreamTypes.PSTN) return SelectionType.CAMERA;
    if (stream?.type === OTStreamTypes.Custom) return SelectionType.CAMERA;
    return SelectionType.MEDIA;
  }

  const isMediaSelection = useCallback((stream: BroadcastStream) => {
    return getSelectionType(stream) === SelectionType.MEDIA || stream.isAssetStream;
  }, []);

  function isVideoAsset(stream: BroadcastStream): boolean {
    return stream?.assetType?.toUpperCase() === AssetType.VIDEO.toUpperCase();
  }

  function isAudioAsset(stream: BroadcastStream): boolean {
    return stream?.assetType?.toUpperCase() === AssetType.AUDIO.toUpperCase();
  }

  function isVideoAssetSelected(checkStage = false): boolean {
    const selectionRef = checkStage ? currentStagedSelectionRef : currentSelectionRef;
    return isAssetInSelection(selectionRef.current.mediaSelection, AssetType.VIDEO);
  }

  function isAudioAssetSelected(checkStage = false): boolean {
    const selectionRef = checkStage ? currentStagedSelectionRef : currentSelectionRef;
    return (
      isAssetInSelection(selectionRef.current.cameraSelection, AssetType.AUDIO) ||
      isAssetInSelection(selectionRef.current.mediaSelection, AssetType.AUDIO)
    );
  }

  function isSlideAssetSelected(checkStage = false): boolean {
    const selectionRef = checkStage ? currentStagedSelectionRef : currentSelectionRef;
    return isAssetInSelection(selectionRef.current.mediaSelection, AssetType.SLIDESHOW);
  }

  /**
   * Helper function for finding audio/video assets in an array of streams
   * @param selection
   * @param assetType
   */
  function isAssetInSelection(selection: BroadcastStream[], assetType: AssetType): boolean {
    try {
      return !!find(
        selection,
        (mediaStream: BroadcastStream) => mediaStream?.assetType?.toUpperCase() === assetType
      );
    } catch {
      return false;
    }
  }

  /**
   * Function to see if currently selected asset is already staged
   */

  function isSelectedAssetStaged(): boolean {
    const selectedAsset = Object.values(currentSelectionRef.current)
      ?.flat()
      ?.find((stream) => stream.isAssetStream);
    const stagedAsset = Object.values(currentStagedSelectionRef.current)
      ?.flat()
      ?.find((stream) => stream.isAssetStream);

    if (!stagedAsset || !selectedAsset) return false;

    return stagedAsset.name === selectedAsset.name;
  }

  /**
   * Video and Audio assets cannot be staged/selected together
   * @param stream
   */
  function checkSelectionValidityIfAsset(stream: BroadcastStream): boolean {
    const _isSlideAssetSelected = isSlideAssetSelected();
    const _isVideoAssetSelected = isVideoAssetSelected();
    const _isAudioAssetSelected = isAudioAssetSelected();

    // skip if stream is not an asset
    if (!stream.isAssetStream) return true;
    // disable selecting multiple assets of the same type
    else if (stream.isVideoAsset && _isVideoAssetSelected) return false;
    else if (stream.isAudioAsset && _isAudioAssetSelected) return false;
    else if (stream.isSlideAsset && _isSlideAssetSelected) return false;

    // disable selecting multiple assets of different types
    return (
      (stream.isVideoAsset && !_isAudioAssetSelected && !_isSlideAssetSelected) ||
      (stream.isAudioAsset && !_isVideoAssetSelected && !_isSlideAssetSelected) ||
      (stream.isSlideAsset && !_isVideoAssetSelected && !_isAudioAssetSelected)
    );
  }

  function normalizeArrayIndex(index: number): number {
    return index + 1;
  }

  function getStagedSelection(): SelectionModel {
    return currentStagedSelectionRef.current;
  }

  function getUnstagedStreams(newSelection: UpdateLayoutParams): BroadcastStream[] {
    const newSelectionStreamsIds = [
      ...(newSelection?.cameraSelection || []),
      ...(newSelection?.mediaSelection || []),
    ];

    const previousSelection = getStagedSelection();

    return [
      ...(previousSelection?.cameraSelection || []),
      ...(previousSelection?.mediaSelection || []),
    ].filter((stream) => !newSelectionStreamsIds.includes(stream.id));
  }

  /**
   * Helper function that returns a selectionModel (version 1) that contains broadcast streams instead of string IDs
   */
  function mapBroadcastStreamsToSelectionModel(
    selection: SelectionModel,
    streams: BroadcastStream[]
  ): SelectionModel {
    return {
      mediaSelection: streams.filter((stream) =>
        selection?.mediaSelection?.includes(stream.id as any)
      ),
      cameraSelection: streams.filter((stream) =>
        selection?.cameraSelection?.includes(stream.id as any)
      ),
      mediaOnly: selection?.mediaOnly,
    };
  }

  /**
   * Since the V2 selection is available before the stream loads, use this function to filter out any streams that are not available
   */
  function filterSelectionByAvailableStreams(
    selection: SelectedStream[],
    streams: BroadcastStream[]
  ): SelectedStream[] {
    return selection?.filter((stream) => streams.find((s) => s.id === stream.id)) ?? [];
  }

  /**
   * Helper function for converting from selection model v1 to v2
   */
  const convertToSelectionModelV2 = useCallback((selection: SelectionModel): SelectionModelV2 => {
    let layoutType: LayoutType = LayoutType.Speaker;

    if (selection?.mediaSelection?.length && selection.mediaSelection.length > 0) {
      layoutType = selection?.mediaOnly ? LayoutType.MediaOnly : LayoutType.Media;
    }

    let broadcastStreams = [
      ...(selection.mediaSelection || []),
      ...(selection.cameraSelection || []),
    ];
    //TODO: see ticket EP-8046, make sure the backend is returning selected streams not just strings
    let selectedStreams: SelectedStream[] = broadcastStreams.map((stream) => {
      if (stream.isScreenShare) {
        return {
          id: stream.id, // Stream coming in will sometimes be a string that represents the id but types don't align (partial pick keyof)
          type: SelectionStreamType.Screen,
        };
      } else if (stream.isPSTNStream) {
        return {
          id: stream.id,
          type: SelectionStreamType.PSTN,
        };
      } else if (stream.isAssetStream) {
        return {
          id: stream.id,
          type: SelectionStreamType.Asset,
          assetId: stream.assetData?.id,
        };
      } else {
        // assume it's a camera stream
        return {
          id: stream.id,
          type: SelectionStreamType.Camera,
        };
      }
    });
    return {
      selection: selectedStreams,
      layoutConfig: {
        type: layoutType,
      },
    };
  }, []);

  const isStaged = useCallback(
    (stream: BroadcastStream): boolean => {
      if (isMediaSelection(stream)) {
        return alreadySelected(currentStagedSelection.mediaSelection, stream);
      } else {
        return alreadySelected(currentStagedSelection.cameraSelection, stream);
      }
    },
    [alreadySelected, isMediaSelection, currentStagedSelection]
  );

  const filterStreamsFromSelection = useCallback(
    (selection: Partial<StreamSelection>, streamId: string) => {
      return Object.keys(selection).reduce((filteredSelection, selectionKey) => {
        return {
          ...filteredSelection,
          [selectionKey]: isArray(selection[selectionKey])
            ? (selection[selectionKey] as StreamSelection[keyof StreamSelection]).filter(
                (_stream) => _stream.id !== streamId
              )
            : selection[selectionKey],
        };
      }, {});
    },
    []
  );

  const selectedV2 = useMemo(
    () => convertToSelectionModelV2({ mediaOnly, ...currentSelection }),
    [mediaOnly, currentSelection, convertToSelectionModelV2]
  );
  const stagedV2 = useMemo(
    () => convertToSelectionModelV2({ mediaOnly, ...currentStagedSelection }),
    [mediaOnly, currentStagedSelection, convertToSelectionModelV2]
  );
  return {
    carouselSelection: {
      selected: currentSelection,
      selectedV2: selectedV2,
      staged: currentStagedSelection,
      stagedV2: stagedV2,
      cameraLimit: cameraStreamSelectionLimit,
      mediaLimit: mediaStreamSelectionLimit,
    },
    carouselSelectionService: {
      select,
      unselect,
      unselectAll,
      getSelectionOrder,
      canSelect,
      stageCurrentSelection,
      isSelected,
      isStaged,
      stageSelection,
      clearSelection,
      getStagedSelection,
      isVideoAsset,
      isAudioAsset,
      isVideoAssetSelected,
      isAudioAssetSelected,
      isSlideAssetSelected,
      isSelectedAssetStaged,
      getUnstagedStreams,
    },
    carouselSelectionHelpers: {
      filterStreamsFromSelection,
      convertToSelectionModelV2,
      mapBroadcastStreamsToSelectionModel,
      filterSelectionByAvailableStreams,
    },
  };
}
