import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { BroadcastStagedLayout } from "../../../../hooks/event/event.hook.definition";
import { UpdateLayoutParams } from "../../vendors/openTok/services/openTokSignaling.service";
import {
  LayoutConfig,
  SelectedStream,
  SelectionStreamType,
} from "../carouselSelection/carouselStreamSelection.definition";
import {
  StagedSelectionHook,
  StagedSelectionProps,
  useGetCurrentlyStagedLayout,
  useUpdateEventStagedBroadcastLayoutSubscription,
} from "./stagedSelection.definition";

export default function useStagedSelection(props: StagedSelectionProps): StagedSelectionHook {
  const { meetingId, skip = false, isOTPR = false, onStreamsStaged } = props;

  const notificationService = useRef(new NotificationService());

  /*
   * TODO: Remove initialStagedLayout & mediaOnlyState variables and update usages to use stagedSelection instead
   * @see EP-7883
   * */
  const [initialStagedLayout, setInitialStagedLayout] = useState<UpdateLayoutParams>();
  const [stagedSelection, setStagedSelection] = useState<{
    selection: BroadcastStagedLayout;
    selectionV2: SelectedStream[];
    layoutConfig: LayoutConfig;
  }>(null);
  const [mediaOnlyState, setMediaOnlyState] = useState(false);

  const { refetch: getCurrentlyStagedLayoutData } = useGetCurrentlyStagedLayout({ skip: true });

  const { data: stagedBroadcastLayoutUpdateData } = useUpdateEventStagedBroadcastLayoutSubscription(
    {
      variables: { meetingId },
      skip,
    }
  );

  const getCurrentlyStagedLayout = useCallback(
    async (meetingId: number) => {
      if (skip) return null;
      const response = await getCurrentlyStagedLayoutData({ meetingId });

      return response?.data?.getCurrentlyStagedLayout;
    },
    [getCurrentlyStagedLayoutData, skip]
  );

  // TODO: Remove this function once stagedSelection is being used
  // @see EP-7883
  const getInitialLayout = useCallback(
    async (meetingId: number) => {
      try {
        const currentlyStagedLayout = await getCurrentlyStagedLayout(meetingId);

        // !currentStagedLayout is a backwards compatibility check for old events
        if (!currentlyStagedLayout) return;
        const { mediaSelection, cameraSelection, selectionV2 } = currentlyStagedLayout;
        if (!isOTPR && (mediaSelection?.length > 0 || cameraSelection?.length > 0)) {
          setInitialStagedLayout(currentlyStagedLayout);
        } else if (isOTPR && selectionV2 && selectionV2.length > 0) {
          // if event is OTPR enabled overwrite camera/media selections with selectionV2
          const stagedLayout = {
            ...currentlyStagedLayout,
            cameraSelection: selectionV2
              .filter(
                (asset) =>
                  asset.type === SelectionStreamType.Camera ||
                  asset.type === SelectionStreamType.PSTN
              )
              .map((asset) => asset.id),
            mediaSelection: selectionV2
              .filter(
                (asset) =>
                  asset.type === SelectionStreamType.Asset ||
                  asset.type === SelectionStreamType.Screen
              )
              .map((asset) => asset.id),
          };
          setInitialStagedLayout(stagedLayout);
        }
      } catch (error) {
        console.error(error);
        return notificationService.current.error(
          "Something went wrong, could not get initial staged layout"
        );
      }
    },
    [getCurrentlyStagedLayout, isOTPR]
  );

  useEffect(() => {
    const onInitialize = async () => {
      try {
        const currentStagedLayout = await getCurrentlyStagedLayout(meetingId);

        if (!currentStagedLayout) return;

        const { mediaSelection, cameraSelection, mediaOnly, selectionV2, layoutConfig } =
          currentStagedLayout;

        setStagedSelection({
          selection: { mediaSelection, cameraSelection, mediaOnly, inactiveStreams: [] },
          selectionV2,
          layoutConfig,
        });

        setMediaOnlyState(mediaOnly ?? false);
      } catch (error) {
        console.error(error);
        return notificationService.current.error(
          "Something went wrong, could not get initial staged layout"
        );
      }
    };

    onInitialize();
  }, [getCurrentlyStagedLayout, meetingId]);

  useEffect(
    function onEventStagedBroadcastLayoutUpdate() {
      if (!stagedBroadcastLayoutUpdateData?.onEventStagedBroadcastLayoutUpdated) return;

      setStagedSelection({
        selection: (stagedBroadcastLayoutUpdateData?.onEventStagedBroadcastLayoutUpdated ||
          {}) as BroadcastStagedLayout,
        selectionV2:
          stagedBroadcastLayoutUpdateData?.onEventStagedBroadcastLayoutUpdated?.selectionV2 || [],
        layoutConfig:
          stagedBroadcastLayoutUpdateData?.onEventStagedBroadcastLayoutUpdated?.layoutConfig,
      });

      setMediaOnlyState(
        stagedBroadcastLayoutUpdateData?.onEventStagedBroadcastLayoutUpdated?.mediaOnly ?? false
      );

      onStreamsStaged?.();
    },
    [stagedBroadcastLayoutUpdateData, onStreamsStaged]
  );

  return {
    stagedSelectionService: {
      getInitialLayout,
      getCurrentlyStagedLayout,
      setInitialStagedLayout,
      setStagedSelection,
    },
    stagedSelectionState: { initialStagedLayout, stagedSelection, mediaOnlyState },
  };
}
