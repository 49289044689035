import { get, isEmpty } from "lodash";

export enum signalTypes {
  END_MEETING = "END_MEETING",
  MUTE_SELF = "MUTE_SELF",
  SET_SPEAKER_AUDIO = "SET_SPEAKER_AUDIO",
  SET_SPEAKER_VIDEO = "SET_SPEAKER_VIDEO",
  UPDATE_STAGING_LAYOUT = "UPDATE_STAGING_LAYOUT",
  JUMP_TO_ASSET_TIME = "JUMP_TO_ASSET_TIME",
  PLAY_ASSET = "PLAY_ASSET",
  PAUSE_ASSET = "PAUSE_ASSET",
  ASSET_ENDED = "ASSET_ENDED",
  VOLUME = "VOLUME",
  GET_ASSET_STATS = "GET_ASSET_STATS",
  ASSET_STATS = "ASSET_STATS",
  SET_ASSET_AUDIO = "SET_ASSET_AUDIO",
  JUMP_TO_SLIDE = "JUMP_TO_SLIDE",
}
export enum eventTypes {
  STREAM_PROPERTY_CHANGED = "streamPropertyChanged",
}

export interface OTSignalType {
  data: string;
  from: object;
}

export interface StreamPropertyChangedParams {
  changedProperty: string;
  newValue: string;
  oldValue: string;
  connectionId: string;
}

export interface SetSpeakerAudioParams {
  enableAudio: boolean;
  currentConnection: boolean;
}

export interface SetSpeakerVideoParams {
  enableVideo: boolean;
}

export interface SetBroadcastingParams {
  broadcasting: boolean;
}

export interface UpdateLayoutParams {
  mediaSelection: Array<string>;
  cameraSelection: Array<string>;
  mediaOnly?: boolean;
}

class service {
  private OTSession: any;

  constructor(OTSession?: any) {
    this.OTSession = OTSession;
  }

  public registerSignalListener(signalType: signalTypes, callback: any): void {
    this.OTSession?.on(`signal:${signalType}`, (signalData) => {
      switch (signalType) {
        case signalTypes.SET_SPEAKER_AUDIO:
          return callback(this.parseSignalParams<SetSpeakerAudioParams>(signalData));
        case signalTypes.SET_SPEAKER_VIDEO:
          return callback(this.parseSignalParams<SetSpeakerVideoParams>(signalData));
        case signalTypes.UPDATE_STAGING_LAYOUT:
          return callback(this.parseSignalParams<UpdateLayoutParams>(signalData));
        default:
          return callback(signalData);
      }
    });
  }

  public registerEventListener(eventType: eventTypes, callback: any): void {
    this.OTSession?.on(eventType, (event) => {
      switch (eventType) {
        case eventTypes.STREAM_PROPERTY_CHANGED:
          return callback(
            this.parseEventParams<StreamPropertyChangedParams>(eventType, event),
            event.stream
          );
        default:
          return callback(event);
      }
    });
  }

  private parseSignalParams<T>(signal: any): T {
    return JSON.parse(signal.data);
  }

  private parseEventParams<T>(eventType: eventTypes, event): T {
    switch (eventType) {
      case eventTypes.STREAM_PROPERTY_CHANGED:
        const steamChangedUserEvent = {
          changedProperty: event.changedProperty,
          newValue: event.newValue,
          oldValue: event.oldValue,
          connectionId: get(event, "stream.connection.connectionId"),
        };
        return JSON.parse(JSON.stringify(steamChangedUserEvent));
      default:
        return JSON.parse(JSON.stringify(event));
    }
  }

  public signalStreams(recipients: any[], signalType: signalTypes, payload?: string): void {
    recipients
      .map((recipient) => {
        // eslint-disable-next-line
        if (isEmpty(recipient)) return;

        return this.OTSession?.signal(
          {
            to: recipient,
            type: signalType,
            ...(payload ? { data: payload } : null),
          },
          (error) => {
            if (error) console.log(error);
          }
        );
      })
      .filter(Boolean);
  }

  public signalAllStreams(signalType: signalTypes, payload?: string): void {
    return this.OTSession?.signal(
      {
        type: signalType,
        ...(payload ? { data: payload } : null),
      },
      (error) => {
        if (error) console.log(error);
      }
    );
  }
}

const OTSignalingService = () => {
  let instance;
  return {
    getInstance: (OTSession?: any): service => {
      if (instance) return instance;
      instance = new service(OTSession);
      return instance;
    },
  };
};

export default OTSignalingService();
