import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
  useQuery,
} from "@apollo/client";

export const questionsMergePolicy = {
  questions: {
    merge: false,
  },
};
export interface Question {
  userId: string;
  text: string;
  displayName: string;
  createdAt: string;
  id: string;
  group: string;
  isAnonymous: boolean;
  isPreSubmitted?: boolean;
  isCurrent: boolean;
  __typename?: string;
}
export enum QuestionGroupEnum {
  RECEIVED = "RECEIVED",
  ANSWERED = "ANSWERED",
  REJECTED = "REJECTED",
  QUEUED = "QUEUED",
}

export interface QuestionsQueryData {
  questions: Question[];
}

export interface QuestionsQueryVars {
  meetingId: number;
  group: QuestionGroupEnum;
}
export interface UpdateQuestionMutationData {
  updateQuestionGroup: Partial<Question>;
}

export interface UpdateQuestionMutationVars {
  questionId: string;
  meetingId: number;
  from: QuestionGroupEnum;
  to: QuestionGroupEnum;
}

export interface SyncPreSubmittedQuestionsMutationData {
  syncQuestions: Question[];
}

export interface SyncPreSubmittedQuestionsMutationVars {
  brMeetingId: string;
  meetingId: number;
  controlNumber?: string;
  limit?: number;
  offset?: number;
}

export interface UpdatedQuestionSubscriptionData {
  onQuestionUpdated: Question;
}

export const QUESTIONS_QUERY = gql`
  query QUESTIONS_QUERY($meetingId: Int, $group: QuestionGroupEnum) {
    questions(meetingId: $meetingId, group: $group) {
      text
      userId
      displayName
      id
      isAnonymous
      isCurrent
      createdAt
      isPreSubmitted
    }
  }
`;

export const UPDATE_QUESTION_MUTATION = gql`
  mutation UPDATE_QUESTION(
    $questionId: String
    $meetingId: Int
    $from: QuestionGroupEnum
    $to: QuestionGroupEnum
  ) {
    updateQuestionGroup(from: $from, to: $to, questionId: $questionId, meetingId: $meetingId) {
      text
      userId
      displayName
      createdAt
      id
      isAnonymous
      isCurrent
      isPreSubmitted
    }
  }
`;

export const UPDATED_QUESTION_SUBSCRIPTION = gql`
  subscription UPDATED_QUESTION_SUBSCRIPTION($meetingId: Int) {
    onQuestionUpdated(meetingId: $meetingId) {
      text
      userId
      displayName
      createdAt
      id
      group
      isAnonymous
      isCurrent
      isPreSubmitted
    }
  }
`;

export const SYNC_PRE_SUBMITTED_QUESTIONS_MUTATION = gql`
  mutation SYNC_PRE_SUBMITTED_QUESTIONS_MUTATION(
    $brMeetingId: String
    $meetingId: Int
    $controlNumber: String
    $limit: Int
    $offset: Int
  ) {
    syncQuestions(
      brMeetingId: $brMeetingId
      meetingId: $meetingId
      controlNumber: $controlNumber
      limit: $limit
      offset: $offset
    ) {
      text
      userId
      displayName
      id
      isAnonymous
      isCurrent
      isPreSubmitted
    }
  }
`;

export const QUESTION_STATUS_SUBSCRIPTION = gql`
  subscription QUESTION_STATUS_SUBSCRIPTION($meetingId: Int) {
    onEventQuestionSettingUpdated(meetingId: $meetingId) {
      settings {
        questionEnabled
      }
    }
  }
`;

export function useQuestionsQuery(
  options: QueryHookOptions<QuestionsQueryData, QuestionsQueryVars>
) {
  return useQuery<QuestionsQueryData, QuestionsQueryVars>(QUESTIONS_QUERY, options);
}

export function useUpdateQuestionGroupMutation(
  options?: MutationHookOptions<UpdateQuestionMutationData, UpdateQuestionMutationVars>
) {
  return useMutation<UpdateQuestionMutationData, UpdateQuestionMutationVars>(
    UPDATE_QUESTION_MUTATION,
    options
  );
}

export function useSyncPreSubmittedQuestionsMutation(
  options?: MutationHookOptions<
    SyncPreSubmittedQuestionsMutationData,
    SyncPreSubmittedQuestionsMutationVars
  >
) {
  return useMutation<SyncPreSubmittedQuestionsMutationData, SyncPreSubmittedQuestionsMutationVars>(
    SYNC_PRE_SUBMITTED_QUESTIONS_MUTATION,
    options
  );
}

export interface QuestionStatusSubscriptionData {
  onEventQuestionSettingUpdated: {
    settings: {
      questionEnabled: boolean;
    };
  };
}

export interface QuestionStatusSubscriptionVariables {
  meetingId: number;
}

export function useQuestionStatusSubscription(
  options: SubscriptionHookOptions<
    QuestionStatusSubscriptionData,
    QuestionStatusSubscriptionVariables
  >
) {
  return useSubscription(QUESTION_STATUS_SUBSCRIPTION, options);
}
