import { gql } from "@apollo/client";

const CompanyAssetUrlObjFragment = gql`
  fragment CompanyAssetUrlObj on AssetUrlObj {
    name
    displayName
    fileType
  }
`;

const CompanyBrandingFragment = gql`
  ${CompanyAssetUrlObjFragment}

  fragment CompanyBranding on Branding {
    logos {
      ...CompanyAssetUrlObj
    }
    backgroundImgs {
      ...CompanyAssetUrlObj
    }
    fonts {
      ...CompanyAssetUrlObj
    }
  }
`;

const CompanyFragment = gql`
  ${CompanyBrandingFragment}

  fragment Company on Company {
    id
    name
    status
    customIdentifier
    organization {
      id
      name
    }
    branding {
      ...CompanyBranding
    }
  }
`;

export const COMPANY_FRAGMENTS = {
  CompanyAssetUrlObj: CompanyAssetUrlObjFragment,
  CompanyBranding: CompanyBrandingFragment,
  Company: CompanyFragment,
};
