import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonTheme, Text, TextTheme } from "@q4/nimbus-ui";
import {
  AudioVideoControlsProps,
  AudioVideoState,
  AudioVideoControlsClassNames,
  defaultVolume,
  defaultVolumeSlider,
} from "./AudioVideoControls.definition";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import "./AudioVideoControls.scss";
import { isNull } from "lodash";

function AudioVideoControls(props: AudioVideoControlsProps): JSX.Element {
  const {
    state,
    onPause,
    onPlay,
    onSeek,
    fileName,
    volume,
    onVolume,
    volumeMax,
    volumeMin,
    volumeSliderStyles,
    isAutoplayAsset,
    asset,
    selected,
    displayActionButtons = true,
  } = props;
  const userVolumeRef = useRef(volume || defaultVolume.MAX);
  const [sliderVolume, setSliderVolume] = useState(volume);

  useEffect(() => {
    if (volume !== 0) {
      userVolumeRef.current = volume;
    }
    setSliderVolume(volume);
  }, [volume]);

  useEffect(() => {
    const assetNamesMatch =
      !isNull(selected) && !isNull(asset) && asset?.name === selected?.asset?.name;
    if (
      (assetNamesMatch || isNull(selected)) &&
      isAutoplayAsset &&
      state === AudioVideoState.PAUSE
    ) {
      onPlay();
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const playIcon = useMemo(() => {
    if (state === AudioVideoState.PAUSE) return AudioVideoControlsClassNames.playIcon;
    return AudioVideoControlsClassNames.pauseIcon;
  }, [state]);

  const volumeIcon = useMemo(() => {
    if (sliderVolume === 0) return AudioVideoControlsClassNames.speakerOfIcon;
    return AudioVideoControlsClassNames.speakerOnIcon;
  }, [sliderVolume]);

  function onPlayClick() {
    if (state === AudioVideoState.PLAY) return onPause();
    onPlay();
  }

  function onAfterChange(value: number) {
    onVolume?.(value);
  }

  function onMuteClick() {
    if (volume === 0) {
      onVolume?.(userVolumeRef.current);
    } else {
      onVolume?.(0);
    }
  }

  function onSeekClick() {
    onSeek?.();
  }
  return (
    <div className={AudioVideoControlsClassNames.base}>
      {displayActionButtons && (
        <>
          <Text theme={TextTheme.White} className={AudioVideoControlsClassNames.fileName}>
            {fileName}
          </Text>
          <div className={AudioVideoControlsClassNames.buttonGroup}>
            <Button theme={ButtonTheme.White} icon={playIcon} onClick={onPlayClick} />
            <Button
              theme={ButtonTheme.White}
              icon={AudioVideoControlsClassNames.scrubIcon}
              onClick={onSeekClick}
            />
            <Button
              theme={ButtonTheme.White}
              icon={volumeIcon}
              onClick={onMuteClick}
              className={AudioVideoControlsClassNames.muteButton}
            />
            <div className={AudioVideoControlsClassNames.sliderWrapper}>
              <Slider
                min={volumeMin || defaultVolume.MIN}
                max={volumeMax || defaultVolume.MAX}
                onAfterChange={onAfterChange}
                onChange={setSliderVolume}
                {...{ ...defaultVolumeSlider, ...(volumeSliderStyles || {}) }}
                className={AudioVideoControlsClassNames.slider}
                value={sliderVolume}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default memo(AudioVideoControls);
