import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextIdModel } from "@q4/nimbus-ui";
import { Attendee } from "../../../../../services/attendees/attendees.model";
import { PublicEventDetails } from "../../../../../services/event/eventGql.model";

export enum PreRegistrationConfirmationClassNames {
  Base = "pre-registration-confirmation",
  Layout = "pre-registration-confirmation_layout",
  ImageContainer = "pre-registration-confirmation_image-container",
  Image = "pre-registration-confirmation_image",
  Title = "pre-registration-confirmation_title",
  Details = "pre-registration-confirmation_details",
  Link = "pre-registration-confirmation_link",
  Email = "pre-registration-confirmation_email",
  Icon = "pre-registration-confirmation_icon",
  AddToCalendar = "pre-registration-confirmation_add-to-calendar",
  DisabledIcon = "pre-registration-confirmation_icon--disabled",
  OutlookIcon = "pre-registration-confirmation_outlook-icon",
  WebcastLink = "pre-registration-confirmation_webcast-link",
}

export interface PreRegistrationConfirmationProps extends BaseComponentProps {
  event: PublicEventDetails;
  meetingId: number;
  isQ4Guest?: boolean;
  participantInfo: Partial<Attendee>;
  isMicrositePreview?: boolean;
  onClickPreRegistrationConfirmationWebcastLink(): void;
}

export class PreRegistrationConfirmationIdModel extends IdModelBase {
  header: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.header = new TextIdModel(`${this.id}Header`);
  }
}
