import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { EventStatusEnum } from "./eventState.definition";

export * from "./eventState.definition";

export interface Event {
  status: EventStatusEnum;
}

export interface CleanupEventVars {
  meetingId: number;
}

export interface CleanupEventData {
  cleanupEvent: Partial<Event>;
}

export interface UpdateEventStatusVars {
  meetingId: number;
  status?: EventStatusEnum;
}

export interface UpdateEventStatusData {
  updateEventStatus: Partial<Event>;
}

export const UPDATE_EVENT_STATUS_MUTATION = gql`
  mutation START_EVENT_MUTATION($meetingId: Int, $status: EventStatusEnum) {
    updateEventStatus(meetingId: $meetingId, status: $status) {
      status
    }
  }
`;

export const CLEANUP_EVENT_MUTATION = gql`
  mutation CLEANUP_EVENT_MUTATION($meetingId: Int) {
    cleanupEvent(meetingId: $meetingId) {
      status
    }
  }
`;

export const START_EVENT_MUTATION = gql`
  mutation START_EVENT_MUTATION($meetingId: Int) {
    startEvent(meetingId: $meetingId) {
      status
    }
  }
`;

export function useCleanupEventMutation(
  options?: MutationHookOptions<CleanupEventData, CleanupEventVars>
) {
  return useMutation<CleanupEventData, CleanupEventVars>(CLEANUP_EVENT_MUTATION, options);
}

export function useUpdateEventStatusMutation(
  options?: MutationHookOptions<UpdateEventStatusData, UpdateEventStatusVars>
) {
  return useMutation<UpdateEventStatusData, UpdateEventStatusVars>(
    UPDATE_EVENT_STATUS_MUTATION,
    options
  );
}

export interface StartEventVars {
  meetingId: number;
}

export interface StartEventData {
  startEvent: Partial<Event>;
}

export function useStartEventMutation(
  options?: MutationHookOptions<StartEventData, StartEventVars>
) {
  return useMutation<StartEventData, StartEventVars>(START_EVENT_MUTATION, options);
}

export interface EventStatusSubVars {
  meetingId: number;
}

export interface EventStatusSubData {
  onEventStatusUpdated: Partial<Event>;
}

export const EVENT_STATUS_SUBSCRIPTION = gql`
  subscription EVENT_STATUS_SUBSCRIPTION($meetingId: Int) {
    onEventStatusUpdated(meetingId: $meetingId) {
      status
    }
  }
`;

export function useEventStatusSubscription(
  options?: SubscriptionHookOptions<EventStatusSubData, EventStatusSubVars>
) {
  return useSubscription<EventStatusSubData, EventStatusSubVars>(
    EVENT_STATUS_SUBSCRIPTION,
    options
  );
}
