export enum ContentType {
  FormData = "multipart/form-data",
  Json = "application/json",
  Pdf = "application/pdf",
}
export enum SelectOptionType {
  Option = "option",
  Action = "action",
}

export type DataOptionType<T> = {
  value: string;
  label: string;
  type?: SelectOptionType;
  data?: T;
};

export interface ErrorMessage {
  message: string;
  extensions?: {
    code?: string;
    errorCode?: string;
    errorMessage?: string;
  };
}

export class ApiResponse<T> {
  success: boolean;
  data?: T;
  count?: number;
  message?: string;
  errors?: string[] | ErrorMessage[];

  constructor(success: boolean, data?: T, count?: number, ...errors: string[] | ErrorMessage[]) {
    this.success = success;
    this.data = data;
    this.count = count;
    this.errors = errors;
  }
}

export enum ApiMethod {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export enum ResponseMessage {
  Ok = "Ok",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
  NotAllowed = "Not allowed",
  NotFound = "Not Found",
  TooManyRequests = "Too Many Requests",
  InternalServerError = "Internal Server Error",
  GatewayTimeout = "Gateway Timeout",
}

export enum ResponseCode {
  Ok = 200,
  NotModified = 304,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
  GatewayTimeout = 504,
}

export const ResponseCodeKey: Record<number, ResponseMessage> = {
  200: ResponseMessage.Ok,
  401: ResponseMessage.Unauthorized,
  403: ResponseMessage.Forbidden,
  404: ResponseMessage.NotFound,
  429: ResponseMessage.TooManyRequests,
  500: ResponseMessage.InternalServerError,
  504: ResponseMessage.GatewayTimeout,
};
