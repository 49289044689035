import { useEffect, useState, useContext, useCallback } from "react";
import Cookies from "universal-cookie";

import { getCurrentVersion } from "../../../utils/version.utils";
import { Auth0Context, AppContext, AuthConstants } from "../../../contexts";
import { Domain } from "../../../configurations/routes/route.domains";
import { isEmpty } from "lodash";

const FETCH_LATEST_VERSION_INTERVAL = 60 * 1000; // 1min

const VersionWhiteListedRoutes = [AuthConstants.LOGIN_REDIRECT_PATH, "/"];

/**
 * Helper hook to get the most latest app version
 */

interface AppVersionQueryState {
  loading: boolean;
  error: boolean;
  currentVersion: string;
  expectedVersion: string;
  isExpectedVersion: boolean;
}

export const COMPANY_ID_HEADER_KEY = "x-company-id";
export const MEETING_ID_HEADER_KEY = "x-meeting-id";

export const USER_ID_HEADER_KEY = "x-user-id";

export default function useAppVersionQuery(): AppVersionQueryState {
  const { getCurrentUser, user } = useContext(Auth0Context);

  const { appDomain, meetingId } = useContext(AppContext);

  const [state, setState] = useState<AppVersionQueryState>({
    loading: false,
    error: false,
    isExpectedVersion: true,
    currentVersion: getCurrentVersion(),
    expectedVersion: null,
  });

  const getCompanyIdHeader = useCallback(() => {
    const user = getCurrentUser();
    const companyId = user?.companies?.[0];

    return {
      ...(companyId && { [COMPANY_ID_HEADER_KEY]: String(companyId) }),
    };
  }, [getCurrentUser]);

  const getMeetingIdHeader = useCallback(() => {
    return {
      ...(meetingId && { [MEETING_ID_HEADER_KEY]: String(meetingId) }),
    };
  }, [meetingId]);

  const getHeaders = useCallback(() => {
    const { domain } = appDomain || {};

    switch (domain) {
      case Domain.Platform:
        return getCompanyIdHeader();
      case Domain.Event:
        return getMeetingIdHeader();
      default:
        return {};
    }
  }, [appDomain, getCompanyIdHeader, getMeetingIdHeader]);

  const isPageVersionWhiteListed = useCallback(() => {
    return (
      !!appDomain?.versionWhitelist || VersionWhiteListedRoutes.includes(window.location.pathname)
    );
  }, [appDomain]);

  const fetchVersionByHeaders = useCallback(() => {
    if (isPageVersionWhiteListed() || !appDomain) return;

    const headers = getHeaders();
    if (isEmpty(headers)) return;

    return fetch("/meta.json", {
      cache: "no-cache",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((meta) => {
        const newState = {
          expectedVersion: meta.version,
          currentVersion: getCurrentVersion(),
          loading: false,
          error: false,
          isExpectedVersion: false,
        };

        if (!newState?.currentVersion || !newState?.expectedVersion) {
          newState.error = true;
        } else if (newState?.currentVersion !== newState?.expectedVersion) {
          newState.isExpectedVersion = false;
          const cookies = new Cookies();
          cookies.set("x-version", newState.expectedVersion, { path: "/" });
        } else {
          newState.isExpectedVersion = true;
        }

        setState((state) => ({ ...state, ...newState }));
      })
      .catch((err) => {
        console.error(err);
        setState((state) => ({ ...state, error: true, loading: false }));
      });
  }, [appDomain, getHeaders, isPageVersionWhiteListed]);

  useEffect(() => {
    fetchVersionByHeaders();
  }, [meetingId, user, fetchVersionByHeaders]);

  useEffect(
    function pollForLatestVersion() {
      if (!state.currentVersion) return;

      const intervalId = setInterval(fetchVersionByHeaders, FETCH_LATEST_VERSION_INTERVAL);
      return () => clearInterval(intervalId);
    },
    [state.currentVersion, fetchVersionByHeaders] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return state;
}
