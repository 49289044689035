import {
  ControlPanelBrandingErrorState,
  ControlPanelBrandingState,
} from "../../../micrositeModal.definition";
import { UploadedDataFormat } from "../../../../../services/event/event.model";

export enum ControlPanelFormClassNames {
  Base = "control-panel-form",
  Header = "control-panel-form_header",
  Background = "control-panel-form_background",
  BackgroundViewer = "control-panel-form_background-viewer",
  BackgroundImage = "control-panel-form_background-image",
  ChangeBackground = "control-panel-form_change-background",
  ChangeBackgroundModal = "control-panel-form_change-background-modal",
  BackgroundOption = "control-panel-form_background-option",
  BackgroundContainer = "control-panel-form_background-container",
  SelectedBackground = "control-panel-form_selected-background",
  SelectedBackgroundOption = "control-panel-form_selected-background-option",
  ColorTextBox = "control-panel-form_color-textbox",
  Swatch = "control-panel-form_color-textbox_swatch",
  SwatchError = "control-panel-form_color-textbox_swatch-error",
  Textbox = "control-panel-form_color-textbox_textbox",
  PrefixText = "control-panel-form_prefix-text",
  PrefixTextError = "control-panel-form_prefix-text-error",
}

export interface ControlPanelFormProps {
  companyId: string;
  companyFonts: UploadedDataFormat[];
  backgroundImages: UploadedDataFormat[];
  controlPanelState: ControlPanelBrandingState;
  controlPanelBrandingErrorState: ControlPanelBrandingErrorState;
  setControlPanelState(state: ControlPanelBrandingState): void;
  resetControlPanelBrandingErrorState(): void;
  setShowChangeBackgroundModal(): void;
}

export enum FontWeightName {
  regular = "regular",
  bold = "bold",
}

export const FontWeightTypes = [
  { value: FontWeightName.regular, label: "Regular" },
  { value: FontWeightName.bold, label: "Bold" },
];

export enum FontName {
  inter = "inter",
  arial = "arial",
  courier = "courier",
  georgia = "georgia",
  helvetica = "helvetica",
  roboto = "roboto",
  tahoma = "tahoma",
  timesNewRoman = "times New Roman",
  verdana = "verdana",
}

export interface GeneralForm {
  value: FontName;
  label: string;
  data: {
    displayName: string;
    name: string;
    fileType: string;
  };
}

export const GeneralFonts: GeneralForm[] = [
  {
    value: FontName.inter,
    label: "Inter",
    data: {
      displayName: "Inter",
      name: FontName.inter,
      fileType: null
    }
  },
  {
    value: FontName.arial,
    label: "Arial",
    data: {
      displayName: "Arial",
      name: "c713b99e-40d4-4d31-629a-6def5f7963c6.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.courier,
    label: "Courier",
    data: {
      displayName: "Courier",
      name: "57a83ee0-51c5-11eb-ae93-0242ac130002.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.georgia,
    label: "Georgia",
    data: {
      displayName: "Georgia",
      name: "a317e99b-4d04-4d31-926a-6def5f7369c6.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.helvetica,
    label: "Helvetica",
    data: {
      displayName: "Helvetica",
      name: "b426f19c-4d04-4d31-926a-6edf6f8469d7.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.roboto,
    label: "Roboto",
    data: {
      displayName: "Roboto",
      name: "e443d00v-2o0f-6q21-023d-6def5f7553v8.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.tahoma,
    label: "Tahoma",
    data: {
      displayName: "Tahoma",
      name: "a823b90f-4e15-4d31-629a-6def5f7967d5.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.timesNewRoman,
    label: "Times New Roman",
    data: {
      displayName: "Times New Roman",
      name: "c0133a80-2e49-4e8c-b6cb-84f07ac94f51.woff",
      fileType: "woff",
    },
  },
  {
    value: FontName.verdana,
    label: "Verdana",
    data: {
      displayName: "Verdana",
      name: "f318373b-f365-42d5-adc1-3c7568cfd08a.woff",
      fileType: "woff",
    },
  },
];

export const GeneralFontsList = GeneralFonts.map((font) => font.label);

export const DefaultFontColor = "FFFFFF";
export const DefaultHeaderBackground = "#1D2124";
export const DefaultMediaBarBackground = "#22272B";
export const DefaultFooterButtonColor = "#2A3035";
export const DefaultFooterBackground = "#121517";

const dataIdPrefix = "MicrositeControlPanel";
export const DATA_IDS = {
  PRIMARY_FONT_SELECT: `${dataIdPrefix}PrimaryFontSelect`,
  PRIMARY_FONT_STYLE_SELECT: `${dataIdPrefix}PrimaryStyleSelect`,
  PRIMARY_FONT_COLOR_INPUT: `${dataIdPrefix}PrimaryColorInput`,
  SECONDARY_FONT_SELECT: `${dataIdPrefix}SecondaryFontSelect`,
  SECONDARY_FONT_STYLE_SELECT: `${dataIdPrefix}SecondaryStyleSelect`,
  SECONDARY_FONT_COLOR_INPUT: `${dataIdPrefix}SecondaryColorInput`,
  CHANGE_BACKGROUND_MODAL: `${dataIdPrefix}ChangeBackgroundModal`,
  CHANGE_BACKGROUND_BUTTON: `${dataIdPrefix}ChangeBackround`,
  SAVE_BUTTON: `${dataIdPrefix}SaveButton`,
  CANCEL_BUTTON: `${dataIdPrefix}CancelButton`,
};
