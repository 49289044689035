import { sortBy } from "lodash";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";
import {
  getUserDataFromConnection,
  isCameraStream,
  isPSTNStream,
  isShareScreenStream,
} from "../../views/adminConsole/vendors/openTok/utils/openTok.utils";
import { extractTypenameFromData } from "../../views/eventMicrosite/helper";
import {
  GroupsStreamType,
  GroupParticipant,
  Group,
  DEFAULT_STREAM_ORDER,
} from "./groups.hook.definition";

export function getStreamType(stream: BroadcastStream) {
  if (stream?.isAssetStream) {
    return stream?.isVideoAsset ? GroupsStreamType.VIDEO_ASSET : GroupsStreamType.AUDIO_ASSET;
  }

  if (isCameraStream(stream)) return GroupsStreamType.CAMERA;
  if (isShareScreenStream(stream)) return GroupsStreamType.SCREEN;
  if (isPSTNStream(stream)) return GroupsStreamType.PSTN;
}

export function getIdentifierFromStream(stream: BroadcastStream) {
  if (stream?.isAssetStream) return stream.name;
  const userData = getUserDataFromConnection(stream?.connection);
  return isPSTNStream(stream) ? userData?.name : userData?.email;
}

export function getParticipantsFromStreams(streams: BroadcastStream[]): GroupParticipant[] {
  const participants = streams.map((stream) => {
    return {
      identifier: getIdentifierFromStream(stream),
      streamType: getStreamType(stream),
      stream: stream,
    };
  });
  return participants;
}

export function isStreamInGroup(group: Group, stream: BroadcastStream): boolean {
  const streamType = getStreamType(stream);
  const identifier = getIdentifierFromStream(stream);

  return !!group?.participants?.find(
    (participant) => participant.identifier === identifier && participant.streamType === streamType
  );
}

function mapStreamsInGroupIterator(
  group: Group,
  streams: BroadcastStream[],
  iterator: (stream: BroadcastStream) => any
) {
  const groupStreamsMapping = group.participants.reduce((acc, participant) => {
    // mapping participant identifiers to streamTypes
    // since we pair content (screen share) with the user, we override any existing identifier:streamType mapping with identifier:CAMERA
    // and for any CAMERA streamTypes, we also show the content streams
    if (
      !acc[participant.identifier] ||
      (acc[participant.identifier] !== GroupsStreamType.CAMERA &&
        participant.streamType === GroupsStreamType.CAMERA)
    ) {
      acc[participant.identifier] = participant.streamType;
    }
    if (
      participant.streamType === GroupsStreamType.AUDIO_ASSET ||
      participant.streamType === GroupsStreamType.VIDEO_ASSET
    ) {
      acc[participant.identifier] = participant.streamType;
    }
    return acc;
  }, {});

  streams.forEach((stream) => {
    const identifier = getIdentifierFromStream(stream);
    const streamType = getStreamType(stream);
    // check if the streamType matches the identifier:streamType mapping
    // or if the identifier is mapped to a CAMERA stream, in which case we push any stream with a matching identifier
    if (
      [
        streamType,
        GroupsStreamType.CAMERA,
        GroupsStreamType.AUDIO_ASSET,
        GroupsStreamType.VIDEO_ASSET,
      ].includes(groupStreamsMapping[identifier])
    ) {
      iterator(stream);
    }
  });
}

export function getStreamCountInGroup(group: Group, streams: BroadcastStream[]) {
  let count = 0;
  mapStreamsInGroupIterator(group, streams, () => count++);
  return count;
}

export function getStreamsInGroup(group: Group, streams: BroadcastStream[]): BroadcastStream[] {
  let groupStreams = [];
  mapStreamsInGroupIterator(group, streams, (stream) => groupStreams.push(stream));
  return groupStreams;
}

export function sortAllParticipants(participants: GroupParticipant[]) {
  return sortBy(participants, (participant) => {
    const stream = participant?.stream;
    const streamType = participant.streamType || stream?.type;
    const { userRole, userEventRole } = getUserDataFromConnection(stream?.connection) || {};

    // ordering is based on streamType, userEventRole, or userRole
    return Math.min(
      DEFAULT_STREAM_ORDER[streamType] || Number.MAX_VALUE,
      DEFAULT_STREAM_ORDER[userRole] || Number.MAX_VALUE,
      DEFAULT_STREAM_ORDER[userEventRole] || Number.MAX_VALUE
    );
  });
}

export function sortStreamsByParticipants(
  streams: BroadcastStream[],
  participants: GroupParticipant[]
) {
  return sortBy(streams, (stream) => {
    const participantIndex = participants.findIndex(
      (participant) =>
        participant?.identifier === getIdentifierFromStream(stream) &&
        participant?.streamType === getStreamType(stream)
    );
    return participantIndex;
  });
}

export const formatGroups = (groups: Group[]) =>
  (groups || []).map((group) => extractTypenameFromData(group));
