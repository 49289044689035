export interface HelpCenterUploadModalProps {
  visible: boolean;
  onClose: () => void;
}

export enum HelpCenterUploadModalClassNames {
  Base = "help-center-modal",
  Header = "help-center-modal-header",
  FileUploader = "help-center-modal-uploader",
  UploadedFiles = "help-center-modal-uploaded-files",
  UploadedFilesLoading = "help-center-modal-uploaded-files--loading",
  FileName = "help-center-modal-file-name",
  FileUploading = "help-center-modal-file-name--uploading",
  DateField = "help-center-modal-uploaded-files--date",
}

export enum HelpCenterUploadTabsIndex {
  Public = 0,
  Private = 1,
}
