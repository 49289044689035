import { EventPermissionDictionary } from "../../configurations/userRolesPermissions.configuration";
import { InvestorTypeNames } from "../../views/broadcastParticipant/eventRegistration/components/eventRegistrationForm.definition";
import { RegistrationType } from "../../views/broadcastParticipant/eventRegistration/eventRegistration.definition";
import { ApiResponse, ContentType } from "../api/api.definition";
import ApiService from "../api/api.service";
import {
  Attendee,
  AttendeeRegistrationFieldAnswer,
  AttendeeType,
  UserType,
} from "../attendees/attendees.model";

export interface ShareholderLoginResponse {
  token: string;
  shareRangeUpper: number;
  shareRangeLower: number;
  permissions: EventPermissionDictionary;
}
export interface ParticipantLoginResponse {
  token: string;
  permissions: EventPermissionDictionary;
}
export interface GuestLoginResponse {
  token: string;
  permissions: EventPermissionDictionary;
}

export enum AttendeeRegistrationType {
  PREREGISTRATION = "PREREGISTRATION",
  LOBBY = "LOBBY",
  POST_EVENT = "POST_EVENT",
}

export const registrationTypeMap: Record<RegistrationType, AttendeeRegistrationType> = {
  [RegistrationType.PRE_REGISTRATION]: AttendeeRegistrationType.PREREGISTRATION,
  [RegistrationType.LOBBY_REGISTRATION]: AttendeeRegistrationType.LOBBY,
  [RegistrationType.POST_REGISTRATION]: AttendeeRegistrationType.POST_EVENT,
};
export interface AttendeeLoginParams {
  userId?: string;
  email: string;
  attendeeEventRole?: string;
  meetingId: number;
  firstName: string;
  lastName: string;
  investorType?: InvestorTypeNames;
  attendeeType?: AttendeeType;
  companyName?: string;
  titleAtCompany?: string;
  wsConnectionId: string;
  controlNumber?: string;
  sendReminderEmail?: boolean;
  registrationType?: AttendeeRegistrationType;
  type?: UserType;
  institutionId?: string;
  registrationFieldAnswers?: AttendeeRegistrationFieldAnswer[];
}
export interface PostEventLoginParams {
  meetingId: number;
  password: string;
}
export interface LoginResponse {
  token: string;
  permissions: EventPermissionDictionary;
}
export interface AttendeeLoginResponse {
  loginResponse: LoginResponse;
  attendee: Attendee;
}
export interface PostEventLoginResponse {
  token: string;
  permissions: EventPermissionDictionary;
}
export interface CompanyLoginResponse {
  token: string;
}

export default class AuthService {
  private readonly AUTH_PATH = "/rest/auth";
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public async shareholderLogin(
    meetingId: string,
    userEmail: string,
    controlNumber: string,
    pin: string
  ): Promise<ApiResponse<ShareholderLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, ShareholderLoginResponse>(
      `${this.AUTH_PATH}/shareholder/login`,
      {
        meetingId,
        email: userEmail,
        controlNumber,
        pin,
      },
      ContentType.Json,
      useAuth,
      true
    );
  }

  public async participantLogin(
    meetingId: string,
    userEmail: string
  ): Promise<ApiResponse<ParticipantLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, ParticipantLoginResponse>(
      `${this.AUTH_PATH}/participant/login`,
      {
        meetingId,
        email: userEmail,
      },
      ContentType.Json,
      useAuth
    );
  }

  public async guestLogin(meetingId: string): Promise<ApiResponse<GuestLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, GuestLoginResponse>(
      `${this.AUTH_PATH}/guest/login`,
      {
        meetingId,
      },
      ContentType.Json,
      useAuth
    );
  }

  public async attendeeLogin(
    loginParams: AttendeeLoginParams
  ): Promise<ApiResponse<AttendeeLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, AttendeeLoginResponse>(
      `${this.AUTH_PATH}/attendee/login`,
      {
        ...loginParams,
      },
      ContentType.Json,
      useAuth
    );
  }

  public async companyLogin(
    companyId: string,
    password: string
  ): Promise<ApiResponse<CompanyLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, CompanyLoginResponse>(
      `${this.AUTH_PATH}/company/login`,
      { companyId, password },
      ContentType.Json,
      useAuth
    );
  }

  public async postEventLogin(
    loginParams: PostEventLoginParams
  ): Promise<ApiResponse<PostEventLoginResponse>> {
    const useAuth = false;

    return this.apiService.post<any, PostEventLoginResponse>(
      `${this.AUTH_PATH}/event/report/login`,
      {
        ...loginParams,
      },
      ContentType.Json,
      useAuth
    );
  }

  public resetPin(meetingId: string, controlNumber: string): Promise<ApiResponse<any>> {
    const useAuth = false;

    return this.apiService.post<any>(
      `/rest/broadridge/shareholder/reset-pin`,
      {
        meetingId,
        controlNumber,
      },
      ContentType.Json,
      useAuth,
      true
    );
  }
}
