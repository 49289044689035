import config from "../../config";
import {
  ApiMethod,
  ApiResponse,
  ContentType,
  ErrorMessage,
  ResponseCode,
  ResponseCodeKey,
  ResponseMessage,
} from "./api.definition";
import { isEmpty } from "lodash";
import auth0 from "auth0-js";

import { Auth0ContextState } from "../../contexts/auth0/auth0.definition";

import * as qs from "qs";

export interface ResponseErrorExtensions {
  errors?: string[] | ErrorMessage[];
}

export class ResponseError extends Error {
  errors?: string[] | ErrorMessage[];

  constructor(message: string, extensions: ResponseErrorExtensions = {}) {
    super(message);
    this.errors = extensions?.errors;
  }
}

export const unwrapResponse = <T extends unknown>(res: ApiResponse<T>): Promise<ApiResponse<T>> => {
  const { success, message, errors } = res;
  if (success) {
    return Promise.resolve(res);
  } else {
    const error = new ResponseError(message, { errors });
    return Promise.reject(error);
  }
};
export default class ApiService {
  private authContext: Auth0ContextState;
  private baseUrl: string;

  constructor(authContext, baseUrl = config.api.url) {
    this.authContext = authContext;
    this.baseUrl = baseUrl;
  }

  public get<TResponse>(
    relativeUrl: string,
    useAuth = true,
    queryParam: object = {},
    returnFullResponse = false
  ): Promise<ApiResponse<TResponse>> {
    const queryString = `${qs.stringify(queryParam, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    })}`;
    const url = relativeUrl + queryString;
    return this.makeRequest<void, TResponse>(
      url,
      ApiMethod.Get,
      useAuth,
      undefined,
      ContentType.Json,
      returnFullResponse
    );
  }

  public post<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    contentType = ContentType.Json,
    useAuth = true,
    returnFullResponse = false
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(
      relativeUrl,
      ApiMethod.Post,
      useAuth,
      data,
      contentType,
      returnFullResponse
    );
  }

  public put<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    useAuth = true,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(
      relativeUrl,
      ApiMethod.Put,
      useAuth,
      data,
      contentType
    );
  }

  public patch<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    useAuth = true,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(
      relativeUrl,
      ApiMethod.Patch,
      useAuth,
      data,
      contentType
    );
  }

  public delete<TBody, TResponse = TBody>(
    relativeUrl: string,
    data?: TBody,
    useAuth = true
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Delete, useAuth, data);
  }

  private async makeExternalRequest<TBody>(
    absolutePath: string,
    method: string,
    data?: TBody,
    contentType = ContentType.Json
  ): Promise<Response> {
    const response = await this.request<TBody>(`${absolutePath}`, method, contentType, data);
    return this.inspectStatusCode(response);
  }

  private async makeRequest<TBody, TResponse = TBody>(
    relativeUrl: string,
    method: string,
    useAuth = true,
    data?: TBody,
    contentType = ContentType.Json,
    returnFullResponse = false
  ): Promise<ApiResponse<TResponse>> {
    const accessToken = useAuth ? await this.authContext.getUserAccessToken() : null;

    let response = await this.request<TBody>(
      `${this.baseUrl}${relativeUrl}`,
      method,
      contentType,
      data,
      accessToken
    );
    if (returnFullResponse) {
      const respData = await response.json();
      if (response.status === ResponseCode.Ok) return respData;
      return {
        ...new ApiResponse<TResponse>(false, null, null, null),
        errors: respData.errors || (respData?.data ? [respData.data] : undefined),
      };
    } else {
      try {
        response = this.inspectStatusCode(response);
        return this.inspectForError(await response.json());
      } catch (error) {
        const { message } = error;
        return new ApiResponse<TResponse>(false, null, null, message);
      }
    }
  }

  private request<TBody>(
    apiPath: string,
    method: string,
    contentType: string,
    data?: TBody,
    authToken?: string
  ): Promise<Response> {
    const headers: HeadersInit = new Headers({
      "Content-Type": contentType,
      "Authorization": `Bearer ${authToken}`,
      "x-user-id": this.authContext?.user?.id,
    });

    if (isEmpty(authToken)) {
      headers.delete("Authorization");
    }
    if (contentType === ContentType.FormData) {
      headers.delete("Content-type");
    }

    if (!this.authContext?.user?.id) headers.delete("x-user-id");

    const body = !isEmpty(data) && contentType === ContentType.Json ? JSON.stringify(data) : data;

    const options: RequestInit = {
      method,
      headers,
      body: body as BodyInit,
    };
    return fetch(apiPath, options);
  }

  private inspectStatusCode = (response: Response): Response => {
    const { status } = response;

    if (ResponseCode.Ok === status) return response;
    if (Object.values(ResponseCode).includes(status)) {
      throw new Error(ResponseCodeKey[status]);
    }
    throw new Error(`An error occurred: Status Code(${status})`);
  };

  private inspectForError = <T>(response: ApiResponse<T>): ApiResponse<T> => {
    if (!isEmpty(response)) {
      const { success, message } = response;

      if (success) {
        return response;
      }

      console.error(`API error: ${message}`);

      return message === ResponseMessage.Unauthorized
        ? this.unauthorizedInterceptor(response)
        : response;
    } else {
      const errorMessage = "An error occurred";
      throw new Error(errorMessage);
    }
  };

  private unauthorizedInterceptor = <T>(response: ApiResponse<T>): ApiResponse<T> => {
    const { clientId: clientID, domain } = config.auth0;
    const webAuth = new auth0.WebAuth({ clientID, domain, responseType: "token id_token" });

    webAuth.logout({ returnTo: `${config.app.url}/login`, clientID });

    return response;
  };
}
