import { useState, useRef, useEffect, useCallback } from "react";
import { findIndex, omitBy, clone, isUndefined } from "lodash";
import { NotificationService } from "@q4/nimbus-ui";

import { Company } from "../../services/company/company.model";
import { UploadedDataFormat } from "../../services/event/event.model";
import {
  UPDATE_COMPANY_BACKGROUND_MUTATION,
  UPDATE_COMPANY_FONT_MUTATION,
  UPDATE_COMPANY_LOGO_MUTATION,
  REMOVE_COMPANY_BACKGROUND_MUTATION,
  REMOVE_COMPANY_LOGO_MUTATION,
  REMOVE_COMPANY_FONT_MUTATION,
  COMPANY_QUERY,
  CompanyDTO,
  GetCompaniesQueryData,
  SearchParamsQueryVars,
  GET_COMPANIES_QUERY,
  VERIFY_COMPANY_PASSWORD_MUTATION,
  GET_COMPANY_PASSWORD_QUERY,
  CREATE_COMPANY_PASSWORD_MUTATION,
  GetCompanyPasswordData,
  GetCompanyPasswordVariables,
  CreateCompanyPasswordData,
  CreateCompanyPasswordVariables,
  VerifyCompanyPasswordData,
  VerifyCompanyPasswordVariables,
  GET_OR_CREATE_COMPANY_PASSWORD_MUTATION,
  GetOrCreateCompanyPasswordData,
  GetOrCreateCompanyPasswordVariables,
  CREATE_COMPANY_MUTATION,
  CreateCompanyData,
  CreateCompanyVariables,
  UpdateCompanyStatusData,
  UPDATE_COMPANY_STATUS_MUTATION,
  UPDATE_COMPANY_MUTATION,
  UpdateCompanyData,
  UpdateCompanyVariables,
  UpdateCompanyStatusVariables,
  GET_VERSIONS,
  GetVersionsQueryData,
  GetVersionsQueryVars,
  UPDATE_COMPANY_VERSION_MUTATION,
  UpdateCompanyVersionData,
  UpdateCompanyVersionVariables,
  UPDATE_CURRENT_VERSION_MUTATION,
  UpdateCurrentVersionData,
  UpdateCurrentVersionVariables,
  IS_COMPANY_RESOURCE_USED_QUERY,
  GetCompanyQueryData,
  GetCompanyQueryVars,
  FetchedCompaniesQueryData,
  CompaniesSelectProps,
  CompanyGetType,
  UPDATE_COMPANY_FAVICON_MUTATION,
  REMOVE_COMPANY_FAVICON_MUTATION,
} from "./companies.hook.definition";
import { generateLogoUrl } from "../../utils/asset.utils";
import config from "../../config";

import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import { debounce } from "../../utils/ui.utils";
import { DataOptionType } from "../../services/api/api.definition";
import { useOrganizationSyncFeatureFlag } from "../featureFlagHooks/useOrganizationSyncFeatureFlag.hook";

function useCompaniesQuery<D = GetCompaniesQueryData, V = SearchParamsQueryVars>(
  options: QueryHookOptions<D, V>
) {
  return useQuery<D, V>(GET_COMPANIES_QUERY, options);
}

export default function useCompanies() {
  const [companies, setCompanies] = useState<CompanyDTO[]>([]);
  const [company, setCompany] = useState<CompanyDTO>();
  const [statusAddCompanyLogo, setStatusAddCompanyLogo] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const notificationService = useRef(new NotificationService());

  const [_createCompany] = useCreateCompany();
  const [_updateCompany] = useUpdateCompany();
  const [_updateCompanyStatus] = useUpdateCompanyStatus();
  const { organizationSyncEnabled } = useOrganizationSyncFeatureFlag();

  useEffect(() => {
    company && resetCompany(company);
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  function resetCompany(company: CompanyDTO) {
    const index = findIndex(companies, { id: company.id });
    if (index > -1) {
      const resetCompanies = clone(companies);
      resetCompanies.splice(index, 1, company);
      setCompanies(Array.from(resetCompanies));
    }
  }

  const { refetch: getAllCompanies } = useCompaniesQuery({ skip: true });

  async function getCompanies(params): Promise<FetchedCompaniesQueryData> {
    const searchParams = { ...params };
    const response = await getAllCompanies(searchParams);

    if (response?.error) {
      notificationService.current.error("Failed to get companies");
      throw response.error;
    }

    const fetchedCompanies = response?.data?.getCompanies?.companies ?? [];
    const fetchedTotalRecords = response?.data?.getCompanies?.totalCompanies ?? 0;

    setCompanies(fetchedCompanies);
    setTotalRecords(fetchedTotalRecords);

    return { companies: fetchedCompanies, totalCompanies: fetchedTotalRecords };
  }

  async function createCompany(query: Company): Promise<boolean> {
    try {
      const response = await _createCompany({ variables: { company: query } });
      const createdCompany = response.data.createCompany;
      setCompany(createdCompany ?? {});
      notificationService.current.success(`The company ${query.name} has been added successfully`);
      return !!createdCompany;
    } catch (error) {
      notificationService.current.error(
        `Failed to create new company. Make sure ${
          organizationSyncEnabled ? "Custom URL" : "Stock Ticker"
        } is unique`
      );
      return false;
    }
  }

  async function updateCompanyStatus(id: string, status: CompanyGetType): Promise<boolean> {
    try {
      const response = await _updateCompanyStatus({
        variables: {
          companyId: id,
          status,
        },
      });
      const updatedCompany = response.data.updateCompanyStatus;
      setCompany(updatedCompany ?? {});
      const statusLabel = `${
        updatedCompany?.status === CompanyGetType.Archived ? "archived" : "activated"
      }`;
      notificationService.current.success(`${updatedCompany?.name} has been ${statusLabel}`);
      return !!updatedCompany;
    } catch (error) {
      notificationService.current.error("Failed to save the changes");
      return false;
    }
  }

  async function updateCompanyById(id: string, query: Company): Promise<boolean> {
    try {
      const response = await _updateCompany({
        variables: {
          companyId: id,
          company: query,
        },
      });

      const updatedCompany = response?.data?.updateCompany;

      /*
       * _updateCompany does not return version and eventManagers, but originally when companies is set
       * this data lives in those objects. Rather than fetching again, merging the data with what's in companies
       * is quicker.
       * */
      if (updatedCompany) {
        const originalCompany = companies?.find((value) => value.id === updatedCompany.id) ?? {};
        const companyWithChanges = {
          ...originalCompany,
          ...updatedCompany,
        };
        setCompany(companyWithChanges);
        notificationService.current.success(`${query.name} changes has been saved`);
      } else {
        setCompany({});
      }
      return !!updatedCompany;
    } catch (error) {
      notificationService.current.error(
        `Failed to save the changes. Make sure ${
          organizationSyncEnabled ? "Custom URL" : "Stock Ticker"
        } is unique`
      );
      return false;
    }
  }

  const { refetch: getCompanyById } = useCompanyQuery({ skip: true });

  async function getCompany(
    params: GetCompanyQueryVars,
    errorNotification: boolean = true
  ): Promise<boolean> {
    const response = await getCompanyById(params);

    if (response?.error) {
      if (errorNotification) {
        notificationService.current.error("Failed to get company");
      }
      return false;
    }

    setCompany(response?.data?.getCompany);
    return true;
  }

  function useCompanyQuery<D = GetCompanyQueryData, V = GetCompanyQueryVars>(
    options: QueryHookOptions<D, V>
  ) {
    return useQuery<D, V>(COMPANY_QUERY, options);
  }

  function useIsCompanyResourceUsed(options?: any) {
    return useQuery(IS_COMPANY_RESOURCE_USED_QUERY, options);
  }

  function useUpdateCompanyBackground(options?: any) {
    return useMutation(UPDATE_COMPANY_BACKGROUND_MUTATION, options);
  }

  function useUpdateCompanyLogo(options?: any) {
    return useMutation(UPDATE_COMPANY_LOGO_MUTATION, options);
  }

  function useUpdateCompanyFavicon(options?: MutationHookOptions) {
    return useMutation(UPDATE_COMPANY_FAVICON_MUTATION, options);
  }

  function useUpdateFont(options?: any) {
    return useMutation(UPDATE_COMPANY_FONT_MUTATION, options);
  }

  function useRemoveCompanyBackground(options?: MutationHookOptions) {
    return useMutation(REMOVE_COMPANY_BACKGROUND_MUTATION, options);
  }

  function useRemoveCompanyLogo(options?: any) {
    return useMutation(REMOVE_COMPANY_LOGO_MUTATION, options);
  }

  function useRemoveCompanyFavicon(options?: any) {
    return useMutation(REMOVE_COMPANY_FAVICON_MUTATION, options);
  }

  function useRemoveCompanyFont(options?: any) {
    return useMutation(REMOVE_COMPANY_FONT_MUTATION, options);
  }

  return {
    getCompany,
    getCompanies,
    createCompany,
    useCompanyQuery,
    useCompaniesQuery,
    useIsCompanyResourceUsed,
    updateCompanyById,
    updateCompanyStatus,
    useUpdateCompanyBackground,
    useUpdateCompanyLogo,
    useUpdateCompanyFavicon,
    useUpdateFont,
    useRemoveCompanyBackground,
    useRemoveCompanyLogo,
    useRemoveCompanyFavicon,
    useRemoveCompanyFont,
    company,
    companies,
    totalRecords,
    statusAddCompanyLogo,
    setStatusAddCompanyLogo,
  };
}

export function useCompaniesSelect(props: CompaniesSelectProps) {
  const [companyLoading, setCompanyLoading] = useState(false);
  const { getCompanies } = props;
  const notificationService = useRef(new NotificationService());

  const loadOptions = (
    inputValue: string,
    callback: (options: DataOptionType<Company>[]) => void,
    options?: { includeBundleVersion: boolean }
  ) => {
    setCompanyLoading(true);
    Promise.resolve(
      getCompanies(
        omitBy(
          {
            searchTerm: inputValue,
            status: CompanyGetType.Active,
            sortField: "name",
            sortOrder: 1,
            limit: 25,
            includeBundleVersion: !!options?.includeBundleVersion,
          },
          (field) => isUndefined(field)
        )
      )
    )
      .then((result) => {
        setCompanyLoading(false);
        if (result.companies) {
          const companies = result.companies?.map((i) => {
            return { label: i.name, value: i.id, data: i };
          });
          callback(companies);
        }
      })
      .catch((error) => {
        setCompanyLoading(false);
        notificationService.current.error(error.message);
      });
  };

  const handleLoadCompanyOptions = useCallback(debounce(loadOptions), []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    companyLoading,
    handleLoadCompanyOptions,
  };
}

export function useCompanyQueryHook({ companyId, meetingId, useCompanyQuery }) {
  const [logoUrl, setLogoUrl] = useState<string>();
  const [fonts, setFonts] = useState<UploadedDataFormat[]>([]);
  const [companyBackgrounds, setCompanyBackgrounds] = useState<UploadedDataFormat[]>([]);
  const [logo, setLogo] = useState<UploadedDataFormat>();
  const [companyName, setCompanyName] = useState<string>();

  const skip = !companyId || !meetingId;

  const { loading: companyBrandingLoading, refetch: getCompanyBranding } = useCompanyQuery({
    skip,
    variables: { companyId, meetingId },
    onCompleted(companyData) {
      const company = companyData.getCompany;
      setCompanyName(company.name);
      const companyLogo = company?.branding?.logos?.length && company.branding.logos[0];

      const logoUrl = companyLogo && generateLogoUrl(company.id, companyLogo);
      setLogo(companyLogo);
      setLogoUrl(logoUrl || config.branding.defaultLogoUrl);

      setCompanyBackgrounds(company?.branding?.backgroundImgs);

      const initialFonts = companyData?.getCompany?.branding?.fonts
        ? companyData.getCompany.branding.fonts
        : [];
      if (initialFonts.length) {
        setFonts(initialFonts);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  return {
    companyBackgrounds,
    companyBrandingLoading,
    companyName,
    fonts,
    logoUrl,
    logo,
    getCompanyBranding,
  };
}

export function useGetCompanyPasswordQuery(
  options?: QueryHookOptions<GetCompanyPasswordData, GetCompanyPasswordVariables>
) {
  return useQuery(GET_COMPANY_PASSWORD_QUERY, options);
}

export function useCreateCompanyPasswordMutation(
  options?: MutationHookOptions<CreateCompanyPasswordData, CreateCompanyPasswordVariables>
) {
  return useMutation(CREATE_COMPANY_PASSWORD_MUTATION, options);
}

export function useGetOrCreateCompanyPasswordMutation(
  options?: MutationHookOptions<GetOrCreateCompanyPasswordData, GetOrCreateCompanyPasswordVariables>
) {
  return useMutation(GET_OR_CREATE_COMPANY_PASSWORD_MUTATION, options);
}

export function useVerifyCompanyPasswordMutation(
  options?: MutationHookOptions<VerifyCompanyPasswordData, VerifyCompanyPasswordVariables>
) {
  return useMutation(VERIFY_COMPANY_PASSWORD_MUTATION, options);
}

export function useCreateCompany(
  options?: MutationHookOptions<CreateCompanyData, CreateCompanyVariables>
) {
  return useMutation(CREATE_COMPANY_MUTATION, options);
}

export function useUpdateCompany(
  options?: MutationHookOptions<UpdateCompanyData, UpdateCompanyVariables>
) {
  return useMutation(UPDATE_COMPANY_MUTATION, options);
}

export function useUpdateCompanyStatus(
  options?: MutationHookOptions<UpdateCompanyStatusData, UpdateCompanyStatusVariables>
) {
  return useMutation(UPDATE_COMPANY_STATUS_MUTATION, options);
}

export function useGetVersionsQuery<D = GetVersionsQueryData, V = GetVersionsQueryVars>(
  options?: QueryHookOptions<D, V>
) {
  return useQuery<D, V>(GET_VERSIONS, options);
}

export function useUpdateCompanyVersion<
  D = UpdateCompanyVersionData,
  V = UpdateCompanyVersionVariables
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UPDATE_COMPANY_VERSION_MUTATION, options);
}

export function useUpdateCurrentVersion<
  D = UpdateCurrentVersionData,
  V = UpdateCurrentVersionVariables
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UPDATE_CURRENT_VERSION_MUTATION, options);
}
