import React, { useCallback, useMemo } from "react";
import {
  Button,
  ButtonTheme,
  getClassName,
  Message,
  MessageType,
  Toolbar,
  ToolbarSize,
  ToolbarTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import {
  AssetControlsFooterClassNames,
  AssetControlsFooterProps,
  TakeControlButtonProps,
  TakeControlButtonClassNames,
} from "./assetControlsFooter.definition";

import "./assetControlsFooter.style.scss";
import { useRebrandFlag } from "../../../hooks/featureFlagHooks/useRebrandFlag.hook";

export const TakeControlButton = (props: TakeControlButtonProps): JSX.Element => {
  const { className, fullScreenControls, takeControl } = props;
  const { isFullscreen, toggleFullscreen } = fullScreenControls;
  const [confirmationVisible, openConfirmation, closeConfirmation] = useVisibility();
  const { getRebrandingClassName } = useRebrandFlag();

  const openHandler = useCallback(() => {
    if (isFullscreen) toggleFullscreen();
    openConfirmation();
  }, [isFullscreen, toggleFullscreen, openConfirmation]);

  const primaryButtonClick = async () => {
    await takeControl();
    closeConfirmation();
  };

  return (
    <>
      <Button
        className={className}
        theme={ButtonTheme.Citrus}
        label="Take Control"
        onClick={openHandler}
      />
      <Message
        className={getRebrandingClassName(TakeControlButtonClassNames.Message)}
        messageType={MessageType.Confirmation}
        title="Control Slides?"
        message={`Are you sure you want to take control of the slides?`}
        visible={confirmationVisible}
        primaryActionProps={{
          label: "take control",
          onClick: primaryButtonClick,
        }}
        secondaryActionProps={{
          label: "cancel",
          onClick: closeConfirmation,
        }}
        onCloseRequest={closeConfirmation}
      />
    </>
  );
};

export const AssetControlsFooter = (props: AssetControlsFooterProps) => {
  const {
    fullScreenControls,
    takeControlHook,
    controlSlidesState: { currentSlide, totalSlides },
    controlSlidesService: { jumpToSlide },
  } = props;

  const { takeControlService, participantInControl } = takeControlHook;
  const { name, isLocallyControlled } = participantInControl;
  const { isFullscreen, toggleFullscreen } = fullScreenControls;

  const buttonClassName = isFullscreen ? AssetControlsFooterClassNames.ButtonFullscreen : "";

  const assetContext = useMemo(() => {
    const contextTheme = isLocallyControlled ? ButtonTheme.SoftGrey : ButtonTheme.DarkSlate;
    const contextClassNames = getClassName(AssetControlsFooterClassNames.Context, [
      {
        condition: !!isLocallyControlled,
        trueClassName: AssetControlsFooterClassNames.ContextInControl,
      },
      {
        condition: !!buttonClassName,
        trueClassName: AssetControlsFooterClassNames.ButtonFullscreen,
      },
    ]);
    return (
      <Button
        className={contextClassNames}
        theme={contextTheme}
        label={`${currentSlide} / ${totalSlides}`}
      />
    );
  }, [buttonClassName, isLocallyControlled, currentSlide, totalSlides]);

  const jumpToPrevSlide = useCallback(() => {
    if (currentSlide === 1) {
      return;
    }

    jumpToSlide(currentSlide - 1);
  }, [currentSlide, jumpToSlide]);

  const jumpToNextSlide = useCallback(() => {
    if (currentSlide === totalSlides) {
      return;
    }

    jumpToSlide(currentSlide + 1);
  }, [currentSlide, jumpToSlide, totalSlides]);

  const controlsWrapper = useCallback(
    (children: React.ReactNode) => {
      const squareButtonClassName = getClassName(buttonClassName, [
        {
          condition: !!buttonClassName,
          trueClassName: AssetControlsFooterClassNames.SquareFullscreen,
        },
      ]);

      if (!totalSlides) return null;

      if (isLocallyControlled) {
        return (
          <div className={AssetControlsFooterClassNames.Buttons}>
            <Button
              className={squareButtonClassName}
              theme={ButtonTheme.SoftGrey}
              icon="q4i-caret-sm-left_4pt"
              square={true}
              onClick={jumpToPrevSlide}
            />
            {children}
            <Button
              className={squareButtonClassName}
              icon="q4i-caret-sm-right_4pt"
              theme={ButtonTheme.SoftGrey}
              square={true}
              onClick={jumpToNextSlide}
            />
          </div>
        );
      } else {
        return (
          <div className={AssetControlsFooterClassNames.Buttons}>
            {children}
            <TakeControlButton
              className={buttonClassName}
              takeControl={takeControlService.takeControl}
              fullScreenControls={fullScreenControls}
            />
          </div>
        );
      }
    },
    [
      buttonClassName,
      isLocallyControlled,
      jumpToNextSlide,
      jumpToPrevSlide,
      takeControlService.takeControl,
      totalSlides,
      fullScreenControls,
    ]
  );

  const controller = useMemo(() => {
    const label = name ? (
      name
    ) : (
      <>
        <i>No one</i>
        <span className={AssetControlsFooterClassNames.Warning} />
      </>
    );

    return (
      <div className={AssetControlsFooterClassNames.Presenter}>
        <span>
          <b>Slides:</b> {label}
        </span>
      </div>
    );
  }, [name]);

  const fullscreen = useMemo(() => {
    const className = isFullscreen
      ? AssetControlsFooterClassNames.FullscreenExit
      : AssetControlsFooterClassNames.Fullscreen;
    return <i className={className} onClick={toggleFullscreen} />;
  }, [isFullscreen, toggleFullscreen]);

  const toolbarSize = useMemo(
    () => (isFullscreen ? ToolbarSize.Default : ToolbarSize.Small),
    [isFullscreen]
  );

  const baseClassName = useMemo(
    () =>
      getClassName(AssetControlsFooterClassNames.Base, [
        {
          condition: !isFullscreen,
          trueClassName: AssetControlsFooterClassNames.BaseNotFullscreen,
        },
      ]),
    [isFullscreen]
  );

  return (
    <Toolbar size={toolbarSize} className={baseClassName} theme={ToolbarTheme.Slate}>
      {controller}
      {controlsWrapper(assetContext)}
      {fullscreen}
    </Toolbar>
  );
};
