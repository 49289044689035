import "./app.view.scss";
import "@q4/nimbus-ui/dist/_styles.css";
// Npm packages
import React, { memo, useEffect, FunctionComponent, useContext, useMemo } from "react";
import { Q4Snowplow } from "@q4/snowplow";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "@q4/nimbus-ui";
import { compose } from "lodash/fp";

import { PrivateRoutes, PublicRoutes } from "../../configurations/routes/routes.configuration";
import contextSubscribe from "../../contexts/context.subscribe";
import { Auth0Context } from "../../contexts/auth0/auth0.context";

// Definitions
import { AuthConstants } from "../../contexts/auth0/auth0.definition";
import { AppClassNames, AppProps } from "./app.definition";
import { SnowplowSchema, SnowplowConfig } from "../../definitions/snowplow.definition";

// Components
import PrivateRoute from "./components/privateRoute/privateRoute.component";
import PublicRoute from "./components/publicRoute/publicRoute.component";
import LoginRedirectRoute from "./components/loginRedirectRoute/loginRedirectRoute.component";
import Fallback from "../../components/fallback/fallback.component";

import config from "../../config";
import useWindowResize from "../broadcastControls/hooks/useWindowResize";
import withCacheBuster from "../../hoc/cacheBuster/cacheBuster.hoc";
import { useBehaviorAnalytics } from "../../hooks/analytics/behaviorAnalytics.hook";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";
import { LoadingContext } from "../../contexts/loading/loading.context";
import { useRebrandFlag } from "../../hooks/featureFlagHooks/useRebrandFlag.hook";
import { useMSClarity } from "../../hooks/analytics/vendors/msClarity/useMSClarity";

function App(props: AppProps): JSX.Element {
  const { authContext } = props;
  const {
    authInitialized,
    isAuthenticated,
    user,
    isLoading,
    loginWithRedirect,
    logoutApplication,
    handleAuthOnCallback,
    updateUser,
  } = authContext;

  const { loadingScreen } = useContext(LoadingContext);

  const { innerHeight } = useWindowResize();

  // Analytics
  useMSClarity();
  const { vendorInit, identifyUser } = useBehaviorAnalytics();

  const { getRebrandingClassName } = useRebrandFlag();

  const { featureFlags } = useContext(FeatureFlagsContext);

  // Snowplow Tracking setup
  useEffect(
    function setupSnowplowTracking() {
      if (!featureFlags[FeatureFlagKeys.SnowplowCollector]) return;
      if (!isLoading && !Q4Snowplow.checkTracker()) {
        const ipApiBaseUrl = config.app.url.indexOf("localhost") > -1 ? undefined : config.app.url;
        const { collecterUrlMini, collecterUrlProd, trackerId, trackerISPKey } =
          config.analytics.snowplow;

        const trackerUrlArray = [];
        if (collecterUrlProd) trackerUrlArray.push(collecterUrlProd);
        if (collecterUrlMini) trackerUrlArray.push(collecterUrlMini);

        Q4Snowplow.init(trackerId, trackerUrlArray);
        Q4Snowplow.activateISP(SnowplowSchema.ISPContextSchema, trackerISPKey, ipApiBaseUrl);
        Q4Snowplow.setUserSchema(SnowplowSchema.UserContextSchema);
        Q4Snowplow.setProductSchema(SnowplowSchema.ProductContextSchema);
        Q4Snowplow.createEventContext(SnowplowSchema.EventContextSchema);
        Q4Snowplow.setProductContext(SnowplowConfig.ProductContext);
      }
    },
    [featureFlags, isLoading, isAuthenticated, user?.email, user?.userTypes]
  );

  useEffect(
    function firstLoad() {
      vendorInit();
    },
    [vendorInit]
  );

  // This will only track users who are authenticated by auth0 (not guests)
  useEffect(
    function initializeTracking() {
      if (isAuthenticated && user?.email) {
        identifyUser({ email: user.email });
      }
    },
    [isAuthenticated, user, vendorInit, identifyUser]
  );

  const privateRoutesMap = useMemo(() => {
    return Object.keys(PrivateRoutes).map((route: string) => (
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        isLoading={isLoading}
        key={`privateRoute`}
        path={route}
        routeDetails={PrivateRoutes[route]}
        user={user}
        loginWithRedirect={loginWithRedirect}
        logoutApplication={logoutApplication}
        updateUser={updateUser}
      />
    ));
  }, [isAuthenticated, isLoading, loginWithRedirect, logoutApplication, updateUser, user]);

  const publicRoutesMap = useMemo(() => {
    return Object.keys(PublicRoutes).map((route: string) => (
      <PublicRoute
        exact
        authInitialized={authInitialized}
        routeDetails={PublicRoutes[route]}
        key={`publicRoute`}
        path={route}
      />
    ));
  }, [authInitialized]);

  return (
    <>
      <div
        className={getRebrandingClassName(AppClassNames.Wrapper)}
        style={{ height: innerHeight }}
      >
        {loadingScreen}
        <Switch>
          <Redirect from="/wcl/:id" to="/attendee/:id" />
          <Redirect from="/wc/:id" to="/admin/:id" />
          {privateRoutesMap}
          {publicRoutesMap}
          <LoginRedirectRoute
            exact
            path={AuthConstants.LOGIN_REDIRECT_PATH as string}
            handleAuthOnCallback={handleAuthOnCallback}
          />
          <Route component={Fallback} />
        </Switch>
        <ToastContainer />
      </div>
    </>
  );
}

const withContext = (WrappedComponent: FunctionComponent<AppProps>) => {
  return contextSubscribe<AppProps>(
    [{ context: Auth0Context, mapToProps: "authContext" }],
    WrappedComponent
  );
};

export default compose(withContext, withCacheBuster, memo)(App);
