import { SharedConsoleProps } from "../../../broadcastControls/console.definition";
import { NotificationService } from "@q4/nimbus-ui";
import { QuestionConfig } from "../../../../components/event/modal/eventModal.definition";
import { AttendeeUser } from "../../../../hooks/attendees/attendeeLogin.definition";

export interface QuestionSettingProps extends SharedConsoleProps {
  currentUser?: AttendeeUser;
  isLoading?: boolean;
  notificationService?: NotificationService;
  eventQuestion?: QuestionConfig;
}

export enum QuestionClassNames {
  Base = "qa-attendee",
  SubmitQuestion = "qa-attendee_submit-questions",
  SubmitQuestionAudioOnly = "qa-attendee_submit-questions-audio-only",
  SubmitedQuestions = "qa-attendee_submitted-questions",
  SubmitTitle = "qa-attendee_submit-title",
  SubmittedTitle = "qa-attendee_submitted-title",
  Field = "qa-attendee_field",
  Button = "qa-attendee_button",
  SubmitField = "qa-attendee_submit-field",
  TextArea = "qa-attendee_text-field",
  EmptyState = "qa-attendee_empty-state",
  Status = "qa-attendee_question-status",
  StatusLabel = "qa-attendee_question-status-label",
  PhoneLink = "qa-attendee_phone-link",
}

export const MAX_ROWS = 4;
export const DEFAULT_MAX_QUESTIONS_PER_ATTENDEE = 10;
export const DEFAULT_MAX_LENGTH_CHAR = 4000;

export interface QuestionDetails {
  id: string;
  userId: string;
  eventId: number;
  text: string;
  isAnonymous: boolean;
  isCurrent: boolean;
  createdAt?: string;
}

export type onQuestionResponse = Pick<QuestionDetails, "text" | "eventId">;

export type onQuestionProp = {
  userEmail: string;
};

export type QuestionsCountQueryData = { questionsCount: number };
export type QuestionsCountQueryVariables = { meetingId: number };
export interface urlParam {
  id: string;
}

export interface QuestionsQueryData {
  questions: QuestionDetails[];
}

export interface MyQuestionsQueryData {
  myQuestions: QuestionDetails[];
}

const dataIdPrefix = "attendeeConsoleQuestions";

export const DATA_IDS = {
  LOADING_QUESTIONS: `${dataIdPrefix}LoadingQuestion`,
  NO_QUESTIONS_PLACEHOLDER: `${dataIdPrefix}NoQuestions`,
  SUBMIT_QUESTION: `${dataIdPrefix}SubmitQuestion`,
  LIVE_QUESTIONS_CLOSED: `${dataIdPrefix}LiveQuestionClosed`,
};

export const submitQuestionPlaceholderText = "Please type your question here";

export enum ConsoleQuestionClassNames {
  Base = "qa-attendee",
  CustomDisabled = "qa-attendee_custom-disabled",
}

export enum ConsoleQuestionMessages {
  Success = "Your question has been submitted.",
  Error = "Your question could not be sent. Please try again.",
  MaximumQuestions = "You have reached the maximum number of questions",
  EmptyStateTitle = "You have not submitted any questions yet",
}
