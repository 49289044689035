import { NotificationService } from "@q4/nimbus-ui";
import moment from "moment";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { BroadcastInteractionType, SnowplowSchema } from "../../definitions/snowplow.definition";
import { useAdminConsoleAnalytics } from "../../hooks/analytics/adminConsoleAnalytics.hook";
import { EventModelPopulated } from "../../hooks/events/events.hook.definitions";
import { Participant } from "../../hooks/participants/participants.hook.definition";
import { usePrevious } from "../../hooks/previousState/usePrevious.hook";
import { parseApolloError } from "../../utils/apolloErrorParser.utils";
import { getEventEndToNowDuration } from "../../utils/event.utils";
import {
  Broadcast,
  BroadcastContextStatus,
  BroadcastStatusEnum,
  DualStream,
  DualStreamStatusEnum,
  StopBroadcastVars,
  useBroadcastStatusSubscription,
  useDualStreamStatusSubscription,
  useStartBroadcastMutation,
  useStopBroadcastMutation,
  useUpdateBroadcastStatusMutation,
} from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { EventStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/eventState";
import EventContext from "../event/event.context";
import { useDualStreamFlag } from "../../hooks/featureFlagHooks/useDualStreaming.hook";

export interface LiveEventContextProps extends PropsWithChildren<unknown> {}

interface LiveEventContextState {
  broadcasting: boolean;
  dualStreamStatus: DualStreamStatusEnum;
  eventEnded: boolean;
  setEventEnded: React.Dispatch<React.SetStateAction<boolean>>;
  startBroadcast: (context?: BroadcastContextStatus) => Promise<unknown>;
  stopBroadcast: (context?: BroadcastContextStatus) => Promise<unknown>;
  processSnowplowPageView: (
    currentParticipant: Partial<Participant>,
    eventDetails: EventModelPopulated
  ) => void;
}

export const LiveEventContext = createContext<Partial<LiveEventContextState>>({});

export const LiveEventProvider = (props: LiveEventContextProps) => {
  const {
    currentEvent: eventDetails,
    setBroadcastStatusContext,
    setBroadcastStartTime,
    broadcastStartTime,
    setBroadcastStatus,
    broadcastStatus,
    eventStatus,
  } = useContext(EventContext);
  const prevEventStatus = usePrevious(eventStatus);
  const history = useHistory();

  const notificationService = useRef(new NotificationService());

  const [broadcasting, setBroadcasting] = useState(eventDetails?.conference?.broadcastUrl !== "");
  const { enabled: dualStreamEnabled } = useDualStreamFlag({ companyId: eventDetails?.companyId });
  const [dualStreamStatus, setDualStreamStatus] = useState(
    eventDetails?.conference?.dualStreamStatus || DualStreamStatusEnum.PRIMARY
  );

  const hoursAfterEventEnd = useMemo(
    () => getEventEndToNowDuration(moment(eventDetails?.eventEnd))?.asHours(),
    [eventDetails?.eventEnd]
  );

  const [eventEnded, setEventEnded] = useState(
    hoursAfterEventEnd > 0 || eventStatus === EventStatusEnum.ENDED
  );

  const onBroadcastStatusSubscriptionData = useCallback(
    ({ data: subscriptionData}) => {
      const broadcast = subscriptionData?.data
        ?.onBroadcastStatusUpdated as Partial<Broadcast>;

      if (broadcast.status === BroadcastStatusEnum.STARTED) {
        setBroadcasting(true);
        setBroadcastStatusContext(broadcast.context);
        if (!broadcastStartTime && broadcast.startTime) {
          setBroadcastStartTime(broadcast.startTime);
        }
      } else if (broadcast?.status === BroadcastStatusEnum.ENDED) {
        setBroadcasting(false);
        setBroadcastStatusContext(broadcast?.context);
      }
      broadcast.status && setBroadcastStatus(broadcast.status);
    },
    [broadcastStartTime, setBroadcastStartTime, setBroadcastStatus, setBroadcastStatusContext]
  );

  const onDualStreamStatusSubscriptionData = useCallback(
    ({ data: subscriptionData}) => {
      const dualStream = subscriptionData?.data
        ?.onDualStreamStatusUpdated as Partial<DualStream>;

      dualStream?.status && setDualStreamStatus(dualStream.status as DualStreamStatusEnum);
    },
    [setDualStreamStatus]
  );

  const { error } = useBroadcastStatusSubscription({
    variables: {
      meetingId: eventDetails?.meetingId,
    },
    skip: !eventDetails?.meetingId,
    onData: onBroadcastStatusSubscriptionData,
  });

  useDualStreamStatusSubscription({
    variables: {
      meetingId: eventDetails?.meetingId,
    },
    skip: !(eventDetails?.meetingId && dualStreamEnabled),
    onData: onDualStreamStatusSubscriptionData,
  });

  useEffect(() => {
    if (broadcastStatus === BroadcastStatusEnum.ENDING) {
      setBroadcastStatus(BroadcastStatusEnum.STARTED);
    } else if (broadcastStatus === BroadcastStatusEnum.STARTING) {
      setBroadcastStatus(BroadcastStatusEnum.ENDED);
    }
  }, [error]); //eslint-disable-line react-hooks/exhaustive-deps

  const { processSnowplowPageView, trackBroadcastActivity } = useAdminConsoleAnalytics();

  const [updateBroadcastStatusMutation] = useUpdateBroadcastStatusMutation();
  const [startBroadcastMutation] = useStartBroadcastMutation();
  const [stopBroadcastMutation] = useStopBroadcastMutation();

  const stopBroadcast = useCallback(
    async (context?: BroadcastContextStatus): Promise<unknown> => {
      const isPaused = context === BroadcastContextStatus.PAUSED;
      try {
        let variables: StopBroadcastVars = { meetingId: eventDetails?.meetingId };
        if (context) variables.context = context;
        const response = await stopBroadcastMutation({
          variables,
        });

        // track snowplow admin broadcast interaction (Paused & Finalized)
        const broadcastInteractionType = isPaused
          ? BroadcastInteractionType.PAUSED
          : BroadcastInteractionType.FINALIZED;

        trackBroadcastActivity(
          SnowplowSchema.EventBroadcastInteractionSchema,
          broadcastInteractionType
        );

        setBroadcasting(false);
        notificationService.current.success(
          `Broadcast successfully ${isPaused ? "paused" : "ended"}`
        );

        return response;
      } catch (error) {
        console.error(error);
        const parsedError = parseApolloError(error);
        if (parsedError?.errorTraces?.[0]?.extensions?.stopInProgress) {
          notificationService.current.info(
            `Unable to ${isPaused ? "pause" : "stop"} broadcast, action is already in progress`
          );
        } else {
          notificationService.current.error(`Unable to ${isPaused ? "pause" : "stop"} broadcast`);
          updateBroadcastStatusMutation({
            variables: {
              meetingId: eventDetails?.meetingId,
              status: BroadcastStatusEnum.END_FAILED,
            },
          });
        }
      }
    },
    [
      eventDetails?.meetingId,
      stopBroadcastMutation,
      updateBroadcastStatusMutation,
      trackBroadcastActivity,
    ]
  );

  const startBroadcast = useCallback(
    async (context?: BroadcastContextStatus): Promise<unknown> => {
      const isPaused = context === BroadcastContextStatus.PAUSED;
      try {
        const response = await startBroadcastMutation({
          variables: { meetingId: eventDetails?.meetingId },
        });

        // track snowplow admin broadcast interaction
        trackBroadcastActivity(
          SnowplowSchema.EventBroadcastInteractionSchema,
          BroadcastInteractionType.START
        );

        setBroadcasting(true);
        notificationService.current.success(
          `Broadcast successfully ${isPaused ? "resumed" : "started"}`
        );
        return response;
      } catch (error) {
        console.error(error);
        const parsedError = parseApolloError(error);
        if (parsedError?.errorTraces?.[0]?.extensions?.startInProgress) {
          notificationService.current.info(
            `Unable to start broadcast, action is already in progress`
          );
        } else {
          notificationService.current.error(`Unable to ${isPaused ? "resume" : "start"} broadcast`);
        }
      }
    },
    [eventDetails?.meetingId, startBroadcastMutation, trackBroadcastActivity]
  );

  useEffect(() => {
    if (eventStatus === EventStatusEnum.ENDED) {
      setEventEnded(true);
    } else if (eventStatus === EventStatusEnum.STARTED) {
      if (prevEventStatus === EventStatusEnum.ENDED) {
        history.go(0);
      }
      setEventEnded(false);
    }
  }, [eventStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(
    () => ({
      broadcasting,
      dualStreamStatus,
      startBroadcast,
      stopBroadcast,
      processSnowplowPageView,
      eventEnded,
      setEventEnded,
    }),
    [
      broadcasting,
      dualStreamStatus,
      startBroadcast,
      stopBroadcast,
      processSnowplowPageView,
      eventEnded,
    ]
  );

  return <LiveEventContext.Provider value={value}>{props?.children}</LiveEventContext.Provider>;
};
