import { AttendeeOriginType } from "../../services/attendees/attendees.model";
import { Nullable } from "../../utils/types/tsTypes";

export interface IdentifiedUser {
  id: string;
  q4pid?: string;
  epVersion?: string;
  projectId?: string;
  email?: string;
  projectIdentifier?: string;
  origin?: AttendeeOriginType;
}

export interface IBehaviorAnalytics {
  vendorGetQ4PId: () => string;
  vendorInit: () => boolean;
  identifyUser: (props: {
    email?: string;
    projectIdentifier?: string;
    originType?: AttendeeOriginType;
  }) => Nullable<IdentifiedUser>;
  trackBehavior: (event: string, object: object) => void;
  isVendorReady: () => boolean;
}

export enum BehaviorTrackingIds {
  Action = "ep-action",
  CustomReg = "ep-custom-reg-field",
  Login = "ep-login",
  OpenInvestorPortal = "ep-open-investor-portal",
}

export enum BehaviorTrackingState {
  Active = "active",
  Inactive = "inactive",
}
