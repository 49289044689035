import moment, { Moment } from "moment";
import { EventConfiguration } from "../../components/event/modal/eventModal.definition";
import { EventPermissionDictionary } from "../../configurations/userRolesPermissions.configuration";
import { Asset } from "../../hooks/assets/assets.hook.definition";
import { AssetType } from "../../hooks/assetUploads/assetUploads.definition";
import { EventMeetingMaterial } from "../../hooks/eventMaterials/eventMaterials.hook.definition";
import { Group } from "../../hooks/groups/groups.hook.definition";
import { TimezoneName } from "../../utils/timezones/timezones.definition";
import { EventStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/eventState";
import {
  EventMicrositeConfigurationMusicOptions,
  EventMicrositeConfigurationPublishSchedule,
  EventMicrositeConfigurationVideoRecording,
  PublishScheduleStatusEnum,
  SavedEventBrandingType,
} from "../../views/eventMicrosite/hooks/microsite.hook.definition";
import { defaultRegion, MediaServerRegion, RecordingChapter } from "./eventGql.model";
import { BroadcastStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";

interface UserType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
export type Speaker = UserType;

export type Support = UserType;

export type Observer = UserType;

export type Inspector = UserType;

export type Host = UserType;

export enum GuestType {
  SPEAKER = "SPEAKER",
  SUPPORT = "SUPPORT",
  INSPECTOR = "INSPECTOR",
  OBSERVER = "OBSERVER",
}
export interface Guest {
  id?: string;
  email: string;
  guestType: GuestType;
  verificationCode?: {
    expiry: Date;
    code: {
      encrypted: string;
      iv: string;
      tag: string;
    };
  };
}

export interface Company {
  id: string;
  name: string;
}

export interface EventDetails {
  event: Event;
  broadcast_url?: string;
  userEventPermissions: EventPermissionDictionary;
}

export interface SessionToken {
  sessionId: string;
  token: string;
  backupSessionId?: string;
  backupToken?: string;
}

export interface UploadedDataFormat {
  name: string;
  displayName: string;
  fileType: string;
  __typename?: string;
  signedUrl?: string;
}

export interface ControlPanelBranding {
  primaryFont: UploadedDataFormat;
  secondaryFont: UploadedDataFormat;
  primaryColor: string;
  secondaryColor: string;
  primaryFontWeight: string;
  secondaryFontWeight: string;
  publishSchedule: EventMicrositeConfigurationPublishSchedule;
  publishScheduleStatus: PublishScheduleStatusEnum;
  videoRecording: EventMicrositeConfigurationVideoRecording;
  backgroundImage?: UploadedDataFormat;
  companyLogo?: UploadedDataFormat;
  hideMeetingReplay?: boolean;
  __typename?: string;
}

interface CommonBranding {
  title: string;
  description?: string;
}

export interface WelcomePageBranding extends CommonBranding {
  proxyWebsiteLink?: string;
  displayTime?: boolean;
}

export interface RegistrationBranding extends CommonBranding {
  broadridgeTelephoneNumber?: string;
  broadridgeInternationalTelephoneNumber?: string;
  openRegistration: string;
  proxyWebsiteLink?: string;
  termsAndConditionsUrl?: string;
}

export interface PreEventBranding {
  message: string;
  musicOption: EventMicrositeConfigurationMusicOptions;
  lobbyMessage: string;
}

export type ThankYouBranding = CommonBranding;

export interface PostEventBranding {
  meetingDetails?: string;
}

export interface AttendeeConsoleBranding {
  // It should be aligned with `UpdateAttendeeConsoleBrandingParams`
  images: {
    eventLogo?: UploadedDataFormat;
    eventFavicon?: UploadedDataFormat;
  };
  headerColors: {
    background?: string;
    text?: string;
  };
  footerColors: {
    background?: string;
    primaryButton?: string;
    primaryButtonText?: string;
  };
  mediaBarColors: {
    background?: string;
    controls?: string;
  };
}

export enum EventType {
  VSM = "vsm",
  EARNINGS = "earnings",
  RECORDING = "recording",
  INVESTOR_DAY = "investorday",
}

export enum EventActivityType {
  EventStarted = "EVENT_STARTED",
  EventEnded = "EVENT_ENDED",
  BroadcastStarted = "BROADCAST_STARTED",
  BroadcastEnded = "BROADCAST_ENDED",
}

export enum EventCategoryType {
  LIVE_EVENT = "LIVE_EVENT",
  STREAM_TEST = "STREAM_TEST",
  DEMO = "DEMO",
  INTERNAL = "INTERNAL",
}

export interface EventActivity {
  activityDate: string;
  activityType: EventActivityType;
}

export interface EventSIP {
  speaker_dial_in_number: string;
  pin: {
    encrypted_data: string;
    init_vector: string;
  };
}

export enum EventConferenceStatuses {
  STARTED = "STARTED",
  ENDED = "ENDED",
}

export enum BroadcastContextStatus {
  DEFAULT = "NONE",
  PAUSED = "PAUSED",
  FINALIZED = "FINALIZED",
}

export interface EventRecordingEdited {
  storageId?: string;
  url?: string;
  startTime?: number;
  endTime?: number;
}

export interface EventRecordingUrlConfig {
  url: string;
  hide: boolean;
  assetType?: AssetType;
  chapters?: RecordingChapter[];
  edited?: EventRecordingEdited;
}

export interface EventConference {
  vender: string;
  session_id: string;
  broadcast_url?: string;
  broadcastRecordings?: EventRecordingUrlConfig[];
  sip?: EventSIP;
  status?: BroadcastStatusEnum;
  context?: BroadcastContextStatus;
  start_time?: Date;
}

export interface EventSettings {
  rsl_enabled: boolean;
  voting_enabled?: boolean;
  question_enabled?: boolean;
}

export interface EventPassword {
  encrypted: string;
  iv: string;
  decrypted?: string;
}

export interface EventEmailConfiguration {
  sendEmail?: boolean;
  emailText?: string;
  newUsersOnly?: boolean;
}

export const defaultEmailConfigurationObject: EventEmailConfiguration = {
  sendEmail: false,
  emailText: "",
  newUsersOnly: false,
};

export const defaultTz = TimezoneName.AmericaNewYork;

export class Event {
  _id?: string;
  title: string;
  description?: string;
  notes?: string;
  event_start: Moment;
  event_end: Moment;
  event_tz?: TimezoneName;
  meeting_id?: string;
  br_meeting_id?: string;
  speakers?: Speaker[];
  supports?: Support[];
  guests?: Guest[];
  hosts?: Host[];
  inspectors?: Inspector[];
  observers?: Observer[];
  coSpeakers?: Speaker[];
  companyID?: Company | any;
  status?: EventStatusEnum;
  custom_identifier?: string;
  event_type: EventType;
  conference?: EventConference;
  branding?: SavedEventBrandingType;
  groups?: Group[];
  assets?: Asset[];
  configuration: EventConfiguration;
  settings?: EventSettings;
  adjournment?: boolean;
  password?: EventPassword;
  materials?: EventMeetingMaterial[];
  confirmation?: EventEmailConfiguration;
  region?: MediaServerRegion;

  constructor(event: Partial<Event>) {
    const {
      title,
      description,
      notes,
      custom_identifier,
      event_start,
      event_end,
      meeting_id,
      br_meeting_id,
      speakers,
      supports,
      guests,
      hosts,
      inspectors,
      observers,
      coSpeakers,
      _id,
      status,
      conference,
      companyID,
      branding,
      groups,
      event_type,
      assets,
      configuration,
      settings,
      adjournment,
      password,
      materials,
      confirmation,
      event_tz,
      region,
    } = event ?? {};
    this._id = _id;
    this.title = title;
    this.description = description;
    this.notes = notes;
    this.custom_identifier = custom_identifier;
    this.event_type = event_type;
    this.event_tz = event_tz ?? defaultTz;
    this.event_start = typeof event_start === "string" ? moment(event_start) : event_start;
    this.event_end = typeof event_end === "string" ? moment(event_end) : event_end;
    this.meeting_id = meeting_id;
    this.br_meeting_id = br_meeting_id;
    this.speakers = speakers;
    this.supports = supports;
    this.guests = guests;
    this.hosts = hosts;
    this.inspectors = inspectors;
    this.observers = observers;
    this.coSpeakers = coSpeakers;
    this.status = status;
    this.conference = conference;
    this.companyID = companyID;
    this.branding = branding;
    this.groups = groups;
    this.assets = assets;
    this.configuration = configuration;
    this.settings = settings;
    this.adjournment = adjournment;
    this.password = password;
    this.materials = materials;
    this.confirmation = confirmation;
    this.region = region ?? defaultRegion;
  }
}

export interface EventAuthDetails {
  userEventAccessToken: string;
  userEventPermissions: EventPermissionDictionary;
}

export interface VerifyPasswordRequestModel {
  meetingId: string;
  password: string;
  isEncrypted: boolean;
}

export interface VerifyPasscodeRequestModel {
  meetingId: string;
  email: string;
  password: string;
  isEncrypted?: boolean;
  passcode: string;
}

export interface ResendPasscodeRequestModel {
  email: string;
  meetingId: string;
}

export interface VerifyEmailRequestModel {
  meetingId: string;
  email: string;
  password: string;
  isEncrypted: boolean;
  firstName: string;
  lastName: string;
}

export interface EmailVerifyResponse {
  userEventAccessToken?: string;
  userEventPermissions?: EventPermissionDictionary;
  verifyEmail: boolean;
}
