import React, { createContext, PropsWithChildren, useContext } from "react";
import EventService from "../../services/event/event.service";
import EventGqlService from "../../services/event/eventGql.service";
import AttendeeService from "../../services/attendees/attendee.service";
import ApiService from "../../services/api/api.service";
import { Auth0Context } from "../auth0/auth0.context";
import AuthService from "../../services/auth/auth.service";
import OrganizationService from "../../services/organization/organization.service";
import config from "../../config";

interface ServicesProviderProps extends PropsWithChildren<{}> {}

export interface ServicesContextModel {
  apiService?: ApiService;
  eventService?: EventService;
  eventGqlService?: EventGqlService;
  attendeeService?: AttendeeService;
  authService?: AuthService;
  organizationService?: OrganizationService;
}

const ServicesContext = createContext<Partial<ServicesContextModel>>({});

export const ServicesProvider = (props: ServicesProviderProps): JSX.Element => {
  const authContext = useContext(Auth0Context);

  const apiService = new ApiService(authContext);
  const eventService = new EventService(apiService);
  const authService = new AuthService(new ApiService(authContext, config.api.graphqlBaseUrl));
  const eventGqlService = new EventGqlService(
    new ApiService(authContext, config.api.graphqlBaseUrl)
  );
  const attendeeService = new AttendeeService(
    new ApiService(authContext, config.api.graphqlBaseUrl)
  );
  const organizationService = new OrganizationService(
    new ApiService(authContext, config.api.graphqlBaseUrl)
  );

  return (
    <ServicesContext.Provider
      value={{
        apiService,
        eventService,
        authService,
        eventGqlService,
        attendeeService,
        organizationService,
      }}
    >
      {props.children}
    </ServicesContext.Provider>
  );
};

export default ServicesContext;
