import { useEffect, useRef, useState } from "react";

export const useSensorFilter = (currentReading: number, filterSize: number = 5) => {
  const sensorReadingIndex = useRef(0);
  const sensorReadingsSum = useRef(0);

  const sensorReadings = useRef(Array(filterSize).fill(0));
  const [average, setAverage] = useState(0);

  useEffect(
    function calculateMovingAverage() {
      sensorReadingsSum.current =
        sensorReadingsSum.current - sensorReadings.current[sensorReadingIndex.current];
      sensorReadings.current[sensorReadingIndex.current] = currentReading;

      sensorReadingsSum.current = sensorReadingsSum.current + currentReading;
      sensorReadingIndex.current = (sensorReadingIndex.current + 1) % filterSize;

      const value = sensorReadingsSum.current / filterSize;
      setAverage(value);
    },
    [filterSize, currentReading]
  );

  return { average };
};
