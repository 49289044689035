import {
  BaseComponentProps,
  IdModelBase,
  CheckboxIdModel,
  isNullOrWhiteSpace,
  TextIdModel,
  ButtonIdModel,
  EditorIdModel,
  FormIdModel,
} from "@q4/nimbus-ui";
import { RegistrationField } from "../../../../../services/event/registrationFieldGql.model";
import { DiscardModalConfirmationIdModel } from "../../discardModalConfirmation/discardModalConfirmation.definition";
import { CustomRegistrationFieldFormBaseEditorHookModel } from "./customRegistrationFieldFormBaseEditor.hook.definition";
import styles from "./util/baseEditor.module.scss";

export interface CustomRegistrationFieldFormBaseEditorProps extends BaseComponentProps {
  title?: string;
  totalFieldCount?: number;
  currentRegistrationField: RegistrationField;
  typeConfigurationFields?: JSX.Element;
  fieldConfigurationFields?: JSX.Element;
  useFormState: CustomRegistrationFieldFormBaseEditorHookModel;
  editorConfig?: TinyMCEEditorConfig;
}

export const BaseEditorContentMaxLength = 2000;
export const BaseEditorCharacterCountWarningLimit = 1950;

export enum CustomRegistrationFieldFormBaseEditorClassName {
  Base = "custom-registration-field-form-base-editor",
  Header = "custom-registration-field-form-base-editor_header",
  Title = "custom-registration-field-form-base-editor_title",
  SubTitle = "custom-registration-field-form-base-editor_subtitle",
  Form = "custom-registration-field-form-base-editor_form",
  FieldConfiguration = "custom-registration-field-form-base-editor_field-configuration",
  FieldContent = "custom-registration-field-form-base-editor_field-content",
  FieldContentFooter = "custom-registration-field-form-base-editor_field-content-footer",
  TextBlockEditor = "custom-registration-field-form-base-editor_editor",
  ErrorContainer = "custom-registration-field-form-base-editor_error-container",
  CharacterCount = "custom-registration-field-form-base-editor_character-count",
  TypeConfiguration = "custom-registration-field-base-editor-block_type-configuration",
  Footer = "custom-registration-field-form-base-editor_footer",
  Actions = "custom-registration-field-form-base-editor_actions",
  FieldCounter = "custom-registration-field-form-base-editor_field-counter",
}

export enum CustomRegistrationFieldFormBaseEditorLabel {
  Title = "Text Block",
  Subtitle = "FROM FIELD TEMPLATES",
  FieldContent = "Field Content",
  Configuration = "Field Configuration",
  ShowField = "Show field",
  Cancel = "Cancel",
  Done = "Done",
}

export class CustomRegistrationFieldFormBaseEditorIdModel extends IdModelBase {
  formBase: FormIdModel;
  configurationBase: string;
  contentEditor: EditorIdModel;
  showField: CheckboxIdModel;
  discardModal: DiscardModalConfirmationIdModel;
  fieldCount: TextIdModel;
  cancelButton: ButtonIdModel;
  doneButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.formBase = new FormIdModel(`${this.id}FormBase`);
    this.configurationBase = `${this.id}ConfigurationBase`;
    this.contentEditor = new EditorIdModel(`${this.id}ContentField`);
    this.showField = new CheckboxIdModel(`${this.id}ShowFieldCheckbox`);
    this.discardModal = new DiscardModalConfirmationIdModel(`${this.id}DiscardModelConfirmation`);
    this.fieldCount = new TextIdModel(`${this.id}FieldCountText`);
    this.cancelButton = new ButtonIdModel(`${this.id}CancelButton`);
    this.doneButton = new ButtonIdModel(`${this.id}DoneButton`);
  }
}

export interface TinyMCEEditorConfig {
  toolbar: string;
  block_formats: string;
}

export const BaseEditorConfig = {
  toolbar: "formatselect | bold italic underline | link",
  contextmenu: false,
  content_style: `body { font-family:"Inter", sans-serif; } h2 { font-size: 16px; font-weight: 400; } p { font-size: 13px; font-weight: 400; } h5 { font-size: 11px; font-weight: 400; } a { color: ${styles.rainAccessibility} }`,
  block_formats: "Subheading=h2; Normal Text=p; Small Text=h5",
  visualblocks_default_state: true,
  end_container_on_empty_block: false,
  link_title: false,
  default_link_target: "_blank",
  target_list: false,
};
