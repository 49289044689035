import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useContext, useMemo, useRef } from "react";
import { AdminConsoleContext } from "../../../../contexts";
import { GroupsStreamType } from "../../../../hooks/groups/groups.hook.definition";

export const useStreamGroupManagement = ({ stream }) => {
  const notificationService = useRef(new NotificationService());

  /** Groups */
  const { useGroups } = useContext(AdminConsoleContext);
  const { groups, addToGroup, removeFromGroup, getDefaultGroup, currentSelectedGroup } = useGroups;

  const defaultGroup = getDefaultGroup();
  const isRootGroup = defaultGroup.identifier === currentSelectedGroup.identifier;

  const hasGroupsForAdding = useMemo(() => groups?.length > 1, [groups]);

  const handleAddingToGroup = useCallback(
    async (groupId) => {
      const currentGroup = groups.find((group) => group.identifier === groupId) || false;
      if (!currentGroup) return;

      const groupParticipant = {
        identifier: stream.name,
        stream,
        streamType: stream.isVideoAsset
          ? GroupsStreamType.VIDEO_ASSET
          : GroupsStreamType.AUDIO_ASSET,
      };
      const newParticipants = currentGroup?.participants.filter((groupParticipant) => {
        return groupParticipant.identifier !== stream.name;
      });

      newParticipants.push(groupParticipant);
      try {
        await addToGroup(groupId, newParticipants);
        notificationService.current.success("Stream successfully added to group.");
      } catch (err) {
        err && notificationService.current.error(err.message);
      }
    },
    [addToGroup, groups, stream]
  );

  const handleRemoveFromGroup = useCallback(
    async (groupId) => {
      const currentGroup = groups.find((group) => group.identifier === groupId) || false;
      if (!currentGroup) return;

      const deletedParticipants = currentGroup?.participants.filter((groupParticipant) => {
        return groupParticipant.identifier === stream.name;
      });

      try {
        await removeFromGroup(groupId, deletedParticipants);
        notificationService.current.success("Stream successfully removed from group.");
      } catch (err) {
        err && notificationService.current.error(err.message);
      }
    },
    [groups, removeFromGroup, stream.name]
  );

  // assets menu options creation
  const subGroups = useMemo(() => {
    const [, ...groupsForJoining] = groups;
    return groupsForJoining.map((group) => ({
      id: `group-${group.name}`,
      icon: "q4i-add-4pt",
      label: group.name,
      onClick: () => handleAddingToGroup(group.identifier),
    }));
  }, [groups, handleAddingToGroup]);

  return {
    currentSelectedGroup,
    groups,
    hasGroupsForAdding,
    isRootGroup,
    subGroups,
    handleAddingToGroup,
    handleRemoveFromGroup,
  };
};
