import {
  BaseComponentProps,
  IdModelBase,
  CheckboxIdModel,
  isNullOrWhiteSpace,
  TextIdModel,
  TextboxIdModel,
  ButtonIdModel,
  SelectIdModel,
} from "@q4/nimbus-ui";
import type { FormStateProps } from "../../../../../hooks/formState/formState.definition";
import {
  RegistrationField,
  RegistrationFieldType,
} from "../../../../../services/event/registrationFieldGql.model";
import { DiscardModalConfirmationIdModel } from "../../discardModalConfirmation/discardModalConfirmation.definition";
import type { CustomRegistrationFieldFormBaseHookModel } from "./customRegistrationFieldFormBase.hook.definition";

export interface CustomRegistrationFieldFormBaseProps extends BaseComponentProps {
  title: string;
  subTitle?: string;
  currentRegistrationField: RegistrationField;
  totalFieldCount?: number;
  typeConfigurationFields: JSX.Element;
  fieldConfigurationFields?: JSX.Element;
  validationSchema?: FormStateProps<RegistrationField>["validationSchema"];
  useFormState: CustomRegistrationFieldFormBaseHookModel;
}

export const FieldTypeOptions = [
  { value: RegistrationFieldType.Text, label: "Short Answer" },
  { value: RegistrationFieldType.LongText, label: "Long Answer" },
];

export interface CustomRegistrationFieldFormTypeProps extends BaseComponentProps {
  totalFieldCount?: number;
  currentRegistrationField: RegistrationField;
  onUpdate: (field: RegistrationField) => void;
  onCancel: (field?: RegistrationField) => void;
}

export enum CustomRegistrationFieldFormBaseClassName {
  Base = "custom-registration-field-form-base",
  Header = "custom-registration-field-form-base_header",
  Title = "custom-registration-field-form-base_title",
  SubTitle = "custom-registration-field-form-base_subtitle",
  Form = "custom-registration-field-form-base_form",
  FieldConfiguration = "custom-registration-field-form-base_field-configuration",
  TypeConfiguration = "custom-registration-field-form-base_type-configuration",
  Footer = "custom-registration-field-form-base_footer",
  Actions = "custom-registration-field-form-base_actions",
  FieldCounter = "custom-registration-field-form-base_field-counter",
}

export enum CustomRegistrationFieldFormBaseLabel {
  Title = "Answer Type",
  Subtitle = "From Field Templates",
  FieldTitle = "Field Title",
  FieldType = "Field Type",
  Placeholder = "Prompt",
  PlaceholderPrompt = "Hint or example text",
  Cancel = "Cancel",
  Done = "Done",
  Configuration = "Field Configuration",
  Required = "Required field",
  ShowField = "Show field",
}

export enum CustomRegistrationFieldLimit {
  Title = 200,
  Consent = 30,
  Option = 100,
  Placeholder = 500,
  Prompt = 30,
}

export class CustomRegistrationFieldFormBaseFieldConfigurationIdModel extends IdModelBase {
  required: CheckboxIdModel;
  showField: CheckboxIdModel;

  constructor(id: string) {
    super(id);

    this.required = new CheckboxIdModel(`${this.id}RequiredCheckbox`);
    this.showField = new CheckboxIdModel(`${this.id}ShowFieldCheckbox`);
  }
}

export class CustomRegistrationFieldFormBaseIdModel extends IdModelBase {
  title: TextboxIdModel;
  fieldType: SelectIdModel;
  discardModal: DiscardModalConfirmationIdModel;
  fieldConfiguration: CustomRegistrationFieldFormBaseFieldConfigurationIdModel;
  fieldCount: TextIdModel;
  cancelButton: ButtonIdModel;
  doneButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.title = new TextboxIdModel(`${this.id}TitleField`);
    this.fieldType = new SelectIdModel(`${this.id}FieldType`);
    this.fieldConfiguration = new CustomRegistrationFieldFormBaseFieldConfigurationIdModel(
      `${this.id}FieldConfiguration`
    );
    this.discardModal = new DiscardModalConfirmationIdModel(`${this.id}DiscardModelConfirmation`);
    this.fieldCount = new TextIdModel(`${this.id}FieldCountText`);
    this.cancelButton = new ButtonIdModel(`${this.id}CancelButton`);
    this.doneButton = new ButtonIdModel(`${this.id}DoneButton`);
  }
}
