import React, { useCallback, useEffect, useRef, useState } from "react";
import useSlidePlayer from "../../../hooks/assetPlayers/slidePlayer.hook";
import { AssetData } from "../../../hooks/assetUploads/assetUploads.definition";
import { AssetBroadcastStream } from "../../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";
import SlideViewer from "../../slideViewer/slideViewer.component";
import { SlideViewerProps } from "../../slideViewer/slideViewer.definition";
import { AssetPortalElementProps } from "./assetElement.definition";

// rendering slides in FHD to improve resolution
const slideElementWidth = 1920;

export default function SlideAssetElement(props: AssetPortalElementProps) {
  const { asset, onAssetRendered } = props;

  const [slidesCanvasRendered, setSlidesCanvasRendered] = useState(false);
  const _totalSlides = useRef<number>(null);
  const _setSlidesPage = useRef(null);

  const { assetReady, pageManager, getAssetStream, initialize, onPageChange } = useSlidePlayer();
  const { setCurrentPage } = pageManager;

  const onCanvasRefLoaded = useCallback((setPage: (page: number) => void, totalSlides: number) => {
    return (ref) => {
      if (!ref) return;

      _setSlidesPage.current = setPage;
      _totalSlides.current = totalSlides;
      setSlidesCanvasRendered(true);
    };
  }, []);

  useEffect(function initializeSlidesAsset() {
    (async function initializeAsync() {
      await initialize(asset.url);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function onSlidesAssetReady() {
      if (!assetReady || !slidesCanvasRendered) return;

      const assetBroadcastStream = new AssetBroadcastStream({
        ...asset,
        getAssetStream: async () => {
          return (await getAssetStream()).mediaStream;
        },
        setPage: (page: number) => {
          _setSlidesPage.current(page);
          setCurrentPage?.(page);
        },
        stats: { totalSlides: _totalSlides.current },
      } as AssetData);

      assetBroadcastStream.markStreamSubscribed();
      onAssetRendered(assetBroadcastStream);
    },
    [asset, assetReady, slidesCanvasRendered, getAssetStream, onAssetRendered, setCurrentPage]
  );

  const slideViewerProps: SlideViewerProps = {
    id: asset?.id,
    url: asset?.url,
    showControls: false,
    includeThumbnailList: false,
    thumbnailListVisible: false,
    overrides: {
      width: slideElementWidth,
    },
    onCanvasRefLoaded,
    onPageChange,
  };

  return <SlideViewer {...slideViewerProps} />;
}
