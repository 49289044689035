export enum SubscriberVideoClassNames {
  BASE = "subscriberVideo",
  VIDEO_ELEMENT = "subscriberVideo_videoElement",
  IMG_ELEMENT = "subscriberVideo_backgroundImage",
  CONTENT = "subscriberVideo_content",
  CUSTOM_MEDIA = "OT-customMedia",
  TEMP_MEDIA = "subscriberVideo_tempMedia",
  VIDEO_MEDIA = "OT-videoMedia",
  VIDEO_PLACEHOLDER = "subscriberVideo-placeholder",
  VIDEO_PLACEHOLDER_ICONS = "subscriberVideo-placeholder-icons",
  VIDEO_PLACEHOLDER_CAMERA = "q4i-camera-off-filled subscriberVideo-placeholder-icons-camera",
  VIDEO_PLACEHOLDER_WARNING = "subscriberVideo-placeholder-icons-warning",
}
