import React, { useCallback, useContext, useMemo, useRef } from "react";
import {
  AssetsPortalClassnames,
  AssetsPortalDataIds,
  AssetsPortalProps,
} from "./assetsPortal.definition";
import { getClassName } from "@q4/nimbus-ui";
import "./assetsPortal.component.scss";
import { ContentType } from "../../hooks/assetUploads/assetUploads.definition";
import useS3Hooks from "../../hooks/s3Hooks/signedUrls.hook";
import { EventContext } from "../../contexts";
import { useAsyncEffect } from "../../hooks/async/asyncEffect.hook";
import ConferenceContext from "../../contexts/conference/conference.context";
import AssetElementFactory from "./components/assetElementFactory";
import retry from "async-retry";

const RETRY_ATTEMPTS = 5;
const MIN_RETRY_TIMEOUT = 500;
const MAX_RETRY_TIMEOUT = 2000;

export default function AssetsPortal(props: AssetsPortalProps) {
  const { assets = [], visible = false, onAssetRendered } = props;

  const { currentEvent } = useContext(EventContext);
  const { streams } = useContext(ConferenceContext);

  const assetsToRender = useRef([]);
  const { useSignedAssetQuery } = useS3Hooks();

  const { refetch: getSignedAssetUrl } = useSignedAssetQuery({
    skip: true,
    onError: console.error,
  });

  const getAssetUrl = useCallback(
    async (meetingId, fileName) => {
      return retry(
        async () => {
          const result = await getSignedAssetUrl({ meetingId, fileName });
          return result?.data?.getEventSignedAssetUrl;
        },
        {
          retries: RETRY_ATTEMPTS,
          minTimeout: MIN_RETRY_TIMEOUT,
          maxTimeout: MAX_RETRY_TIMEOUT,
          onRetry: console.warn,
        }
      );
    },
    [getSignedAssetUrl]
  );

  useAsyncEffect(
    async function onInitialize() {
      if (!assets || !currentEvent?.meetingId) return;

      const isUserControlledSlides =
        !currentEvent?.configuration?.presenterControlledSlides?.enabled;

      await Promise.all(
        assets.map(async (asset) => {
          if (streams.find((stream) => stream.id === asset.id)) return;
          if (asset.contentType === ContentType.SLIDES) {
            if (isUserControlledSlides || !asset?.context?.useInMeeting) return;
          }

          try {
            const prefix = asset.contentType === ContentType.SLIDES ? "slides" : "av";
            let url = await getAssetUrl(currentEvent.meetingId, `${prefix}/${asset.id}`);
            
            if (asset.contentType === ContentType.AUDIO_VIDEO) {
              const blobData = await (await fetch(url)).blob();
              url = URL.createObjectURL(blobData);
            }            
            
            const assetWithUrl = { ...asset, url };
            const assetElementFactoryProps = {
              asset: assetWithUrl,
              onAssetRendered,
            };

            assetsToRender.current.push(
              <AssetElementFactory
                key={`AssetElementFactory_${asset.id}`}
                {...assetElementFactoryProps}
              />
            );
          } catch (error) {
            console.error(`Failed to fetch signed asset url for ${asset.id}`);
          }
        })
      );
      return () => {
        assetsToRender.current = [];
      };
    },
    [assets, currentEvent?.meetingId]
  );

  const baseClassName = useMemo(() => {
    return getClassName(null, [
      {
        condition: visible,
        trueClassName: AssetsPortalClassnames.BaseVisible,
      },
      {
        condition: !visible,
        trueClassName: AssetsPortalClassnames.BaseHidden,
      },
    ]);
  }, [visible]);

  return (
    <div data-id={AssetsPortalDataIds.Base} className={baseClassName}>
      {assetsToRender.current}
    </div>
  );
}
