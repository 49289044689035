import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { RouteComponentProps } from "react-router";
import { PublicEventDetails } from "../../../../services/event/eventGql.model";
import { EventRegistrationIdModel } from "../../../broadcastParticipant/eventRegistration/eventRegistration.definition";
import {
  EventMicrositePageDataTypes,
  EventMicrositePageType,
} from "../../hooks/microsite.hook.definition";
import {
  ControlPanelBrandingState,
  RegistrationBrandingState,
} from "../../micrositeModal.definition";

interface MatchParams {
  micrositePage: string;
  meetingId: string;
}

export interface MicrositePagesPreviewProps
  extends BaseComponentProps,
    RouteComponentProps<MatchParams> {
  currentEvent: PublicEventDetails;
  isGuestAttendance: boolean;
  miniPreviewPage?: EventMicrositePageType;
  miniPreviewPageData?: EventMicrositePageDataTypes;
  miniPreviewPageBranding?: ControlPanelBrandingState;
  isMiniPreview?: boolean;
  registrationPageState: RegistrationBrandingState;
  isCustomRegistrationPreview?: boolean;
}

export enum MicrositePagesPreviewClassNames {
  Base = "microsite-pages-preview",
  BaseWithLivePreviewModifier = "microsite-pages-preview--live",
  Banner = "microsite-pages-preview_banner",
  Room = "microsite-pages-preview_room",
  PreviewTitle = "microsite-pages-preview_title",
}

export class MicrositePagesPreviewIdModel extends IdModelBase {
  eventRegistration: EventRegistrationIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.eventRegistration = new EventRegistrationIdModel(`${this.id}EventRegistration`);
  }
}
