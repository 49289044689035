export const investorDayFeaturesList = [
  {
    featureName: "pre-registration",
    enabled: true,
  },
  {
    featureName: "registration",
    enabled: true,
  },
  {
    featureName: "text-qa",
    enabled: true,
  },
  {
    featureName: "audio-qa",
    enabled: false,
  },
  {
    featureName: "attendee-dial-in",
    enabled: false,
  },
  {
    featureName: "speaker-dial-in",
    enabled: true,
  },
  {
    featureName: "participant-dial-in",
    enabled: false,
  },
  {
    featureName: "speaker-bios",
    enabled: false,
  },
  {
    featureName: "guest-attendance",
    enabled: true,
  },
  {
    featureName: "speaker-participants",
    enabled: true,
  },
  {
    featureName: "support-participants",
    enabled: true,
  },
  {
    featureName: "host-participants",
    enabled: true,
  },
  {
    featureName: "archive-12-month",
    enabled: true,
  },
  {
    featureName: "recording-replay",
    enabled: true,
  },
  {
    featureName: "meeting-materials",
    enabled: true,
  },
  {
    featureName: "post-event-report",
    enabled: true,
  },
  {
    featureName: "post-event-report",
    enabled: true,
  },
  {
    featureName: "microsite",
    enabled: true,
  },
];
