/**
 * Attaches prefix to input hexcode if prefix is not already there
 * */
export const sanitizeColorCode = (hexCode: string) => {
  if (!hexCode) return "";

  if (hexCode.startsWith("#", 0) || hexCode.length === 0) return hexCode;

  return "#" + hexCode;
};

export const isColorCodeValid = (hexCode: string) => {
  const hexCodeRegex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

  return hexCode.length !== 0 && hexCodeRegex.test(hexCode);
};
