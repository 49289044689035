import { reduce, filter, find, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  DefaultGroupIdentifiers,
  Group,
  GroupParticipant,
  GroupsHookProps,
  GroupsHookModel,
} from "./groups.hook.definition";

import { useUpdateGroupsMutation, useUpdateGroupsSubscription } from "./groups.hook.signal";
import {
  formatGroups,
  getParticipantsFromStreams,
  getStreamType,
  sortAllParticipants,
} from "./groups.hook.utils";
import { getStagedStreamIds as getUniqStreamIds } from "../../views/broadcastLayoutBuilder/utils/layout.utils";

export default function useGroupsHook(props: GroupsHookProps): GroupsHookModel {
  const { meetingId, streams, initialGroups } = props;
  const [groups, setGroups] = useState<Group[]>([...getDefaultGroups(), ...initialGroups]);
  const [currentSelectedGroup, setCurrentSelectedGroup] = useState<Group>(getDefaultGroup());

  // mutations & subscriptions
  const [updateGroupsMutation] = useUpdateGroupsMutation();
  const { data: updateGroupsSubData } = useUpdateGroupsSubscription({
    variables: {
      meetingId,
    },
  });

  // Update the groups whenever a new group is created
  useEffect(() => {
    const updatedGroups = updateGroupsSubData?.onEventGroupsUpdated?.groups;
    if (updatedGroups) {
      // TODO -> map streams to the groups
      const allUsers = getGroupById(DefaultGroupIdentifiers.ALL_USERS);
      const extractedGroup = formatGroups(updatedGroups);
      setGroupsUniq([allUsers, ...extractedGroup]);
    }
  }, [updateGroupsSubData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the default group(s) whenever new streams are created
  const renderOnChange = getUniqStreamIds(streams);
  useEffect(() => {
    const allParticipants = getParticipantsFromStreams(streams);

    const updatedGroup = {
      ...getGroupById(DefaultGroupIdentifiers.ALL_USERS),
      participants: sortAllParticipants(allParticipants),
    };
    editGroup(DefaultGroupIdentifiers.ALL_USERS, updatedGroup);
  }, [renderOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Function for creating a new group
   * @param group - group with name (required) and participants (optional)
   */
  async function createGroup(group: Partial<Group>): Promise<Group> {
    try {
      if (!group?.name) {
        throw new Error("Missing group name");
      }

      if (getGroupByName(group.name)) {
        throw new Error("Group already exists");
      }

      const newGroup = {
        name: group.name,
        participants: group.participants || [],
        identifier: uuid(),
      };

      await updateEventGroups([...groups, newGroup]);
      return newGroup;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Function for updating group data
   * @param group
   */
  async function editGroup(groupId: string, groupData: Group): Promise<Group> {
    try {
      if (!groupId) {
        throw new Error("Missing group identifier");
      }

      const existingGroupIndex = getGroupIndexById(groupId);
      if (existingGroupIndex < 0) {
        throw new Error("Group does not exist");
      }

      // TODO - validate groupData parameters on db update
      const existingGroup = groups[existingGroupIndex];
      const updatedGroup = { ...existingGroup, ...groupData };

      const updatedGroups = groups.map((group) =>
        group.identifier === groupId ? updatedGroup : group
      );

      if (groupId === DefaultGroupIdentifiers.ALL_USERS) {
        setGroups(updatedGroups);
      } else {
        await updateEventGroups(updatedGroups);
      }

      return updatedGroup;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Adds participant(s) to a group
   * @param groupId
   * @param participants
   */
  async function addToGroup(groupId: string, participants: GroupParticipant[]): Promise<void> {
    try {
      const existingGroupIndex = getGroupIndexById(groupId);
      if (existingGroupIndex < 0) {
        throw new Error("Group does not exist");
      }

      // TODO - add guard for adding same participant/stream type
      const existingGroup = groups[existingGroupIndex];
      const oldParticipants = existingGroup.participants.filter((existing) => {
        return participants.find((participant) => existing.identifier === participant.identifier);
      });
      const updatedGroup = {
        ...existingGroup,
        participants: [...oldParticipants, ...participants],
      };
      const updatedGroups = groups.map((group) =>
        group.identifier === groupId ? updatedGroup : group
      );

      await updateEventGroups(updatedGroups);
      setGroups(updatedGroups);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Removes participant(s) from a group
   * @param groupId
   * @param participants
   */
  async function removeFromGroup(groupId: string, participants: GroupParticipant[]): Promise<void> {
    try {
      const existingGroupIndex = getGroupIndexById(groupId);
      if (existingGroupIndex < 0) {
        throw new Error("Group does not exist");
      }

      const participantIdsToRemove = participants.reduce((acc, participant) => {
        acc.push(participant.identifier);
        return acc;
      }, []);

      const updatedGroups = groups.map((group) => {
        if (group.identifier === groupId) {
          const newParticipants = group.participants.filter(
            (participant) => !participantIdsToRemove.includes(participant.identifier)
          );
          return { ...group, participants: newParticipants };
        }
        return group;
      });

      await updateEventGroups(updatedGroups);
      setGroups(updatedGroups);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Deletes a group if found
   * @param groupId
   */
  async function deleteGroup(groupId: string): Promise<Group[]> {
    try {
      const filteredGroups = groups.filter((group) => group.identifier !== groupId);
      await updateEventGroups(filteredGroups);
      setCurrentSelectedGroup(getDefaultGroup());

      return filteredGroups;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Updates the order of the groups
   * @param reorderedGroup
   */
  function reorderGroup(reorderedGroups: Group[]): void {
    try {
      const containsSameGroups = (group: Group) =>
        !!reorderedGroups.find((newGroup) => newGroup.identifier === group.identifier);

      if (!groups.every(containsSameGroups) || groups.length !== reorderedGroups.length) {
        throw new Error("Contents of group do not match");
      }

      setGroups(() => reorderedGroups);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Invokes the update groups mutation with the provided payload
   * @param payload
   */
  async function updateEventGroups(payload: Group[]) {
    await updateGroupsMutation({
      variables: {
        meetingId,
        groups: serializeGroupsPayload(payload),
      },
    });
  }

  /**
   * Dedups on identifier
   * @param payload
   */
  function setGroupsUniq(payload: Group[]) {
    setGroups(() => uniqBy(payload, "identifier"));
  }

  /**
   * Retrieves group by group identifier
   * TODO - returns undefined when group not found, handle better
   * @param groups
   * @param identifier
   */
  function getGroupById(identifier: string) {
    return find(groups, (group) => group.identifier === identifier);
  }

  /**
   * Retrieves group by group name
   * @param name
   */
  function getGroupByName(name: string): Group {
    return groups.find((group) => group.name === name);
  }

  /**
   * Retrieves the location of the group in the array
   * @param groupId
   */
  function getGroupIndexById(groupId: string): number {
    return groups.findIndex((group) => group.identifier === groupId);
  }

  function getDefaultGroup() {
    return groups.find((group) => group.identifier === DefaultGroupIdentifiers.ALL_USERS);
  }

  return {
    groups,
    setGroupsUniq,
    createGroup,
    editGroup,
    addToGroup,
    getDefaultGroup,
    removeFromGroup,
    deleteGroup,
    reorderGroup,
    setCurrentSelectedGroup,
    currentSelectedGroup,
  };
}

/**
 * Function for extracting important variables for GraphQL mutation
 * @param groups
 */
function serializeGroupsPayload(groups: Group[]): Group[] {
  const mapParticipants = (result: any, participant: any) => [
    ...result,
    {
      identifier: participant.identifier,
      streamType: participant.streamType || getStreamType(participant.stream),
    },
  ];
  return reduce(
    filter(groups, (group) => group.identifier !== DefaultGroupIdentifiers.ALL_USERS),
    (result: Group[], group: Group) => [
      ...result,
      {
        identifier: group.identifier,
        name: group.name,
        participants: reduce(group.participants, mapParticipants, []),
      },
    ],
    []
  );
}

/**
 * Creates list of default groups (used across all events)
 */
export function getDefaultGroups(): Group[] {
  return [
    {
      identifier: DefaultGroupIdentifiers.ALL_USERS,
      name: "All Users",
      participants: [],
    },
  ];
}
