import { getClassName } from "@q4/nimbus-ui";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import AspectRatioSizer from "../../../../components/aspectRatioSizer/aspectRatioSizer.component";
import { EventPermissionDictionary } from "../../../../configurations/userRolesPermissions.configuration";
import { EventContext } from "../../../../contexts";
import { Connection } from "../../../../opentok-react/types/Connection.type";
import { Participant } from "../../../../services/participants/participants.model";
import { CarouselStreamWrapper } from "../../../adminConsole/components/StreamWrapper/carouselStreamWrapper.component";
import { SelectionModel } from "../../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { SubscriberWidth } from "../../../adminConsole/vendors/openTok/openTok.definition";
import { useRemoveStreamHook } from "../../../broadcastLayoutBuilder/hooks/removeStream.hook";
import "./SubscriberVideo.component.scss";
import { SubscriberVideoClassNames } from "./SubscriberVideo.definition";

interface SubscriberVideoProps {
  stream: BroadcastStream;
  broadcasting: boolean;
  participant: Partial<Participant>;
  setSpeakerAudio: (connection: Connection, enableAudio: boolean, isPSTN?: boolean) => void;
  selectionDisabled: boolean;
  userEventPermissions?: EventPermissionDictionary;
  stageSelection?: (selection: SelectionModel) => void;
  mediaOnly?: boolean;
}

export default function SubscriberVideo(props: SubscriberVideoProps) {
  const {
    stream,
    participant,
    broadcasting,
    userEventPermissions,
    setSpeakerAudio,
    stageSelection,
    mediaOnly,
  } = props;

  const { currentEvent } = useContext(EventContext);
  const { removeStreamFromStage } = useRemoveStreamHook({ updateStage: stageSelection, mediaOnly });
  const videoElement = useRef<HTMLVideoElement>();

  const attachStreamWebrtcToVideoElement = useCallback(() => {
    (async () => {
      const src = await stream.videoMediaStream();
      if (videoElement?.current && src) {
        videoElement.current.srcObject = src;
        if (videoElement.current.srcObject) {
          videoElement.current.srcObject.addEventListener(
            "inactive",
            attachStreamWebrtcToVideoElement
          );
        }
      }
    })();
  }, [stream, stream?.hasVideo, participant?.mediaSettings?.videoInputsReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(attachStreamWebrtcToVideoElement, [attachStreamWebrtcToVideoElement]);

  const audioOnlyEvent = useMemo(
    () => !currentEvent?.configuration?.video?.enabled,
    [currentEvent]
  );

  const participantInitialized = useMemo(() => !!participant, [participant]);

  const baseSubscriberVideoClassNames = useMemo(() => {
    return getClassName(SubscriberVideoClassNames.VIDEO_ELEMENT, [
      {
        condition: stream.isLocalPublisher && !stream.isScreenShare && !stream.isAssetStream,
        trueClassName: "mirrored",
      },
    ]);
  }, [stream.isLocalPublisher, stream.isScreenShare, stream.isAssetStream]);

  const streamContent = useMemo(() => {
    return (
      !stream?.isAudioAsset &&
      stream?.hasVideo && (
        <video
          className={`${baseSubscriberVideoClassNames} ${SubscriberVideoClassNames.CONTENT}`}
          id={stream.id}
          crossOrigin={"anonymous"}
          autoPlay={true}
          muted={true}
          ref={videoElement}
        />
      )
    );
  }, [baseSubscriberVideoClassNames, stream?.hasVideo, stream.id, stream?.isAudioAsset]);

  const streamMenuActions = useMemo(
    () => ({
      setSpeakerAudio,
      onRemoveStream: removeStreamFromStage,
    }),
    [setSpeakerAudio, removeStreamFromStage]
  );

  return (
    <div className={SubscriberVideoClassNames.BASE}>
      <AspectRatioSizer width={SubscriberWidth}>
        {(participantInitialized || stream.isAssetStream || stream.isPSTNStream) && (
          <CarouselStreamWrapper
            audioOnly={audioOnlyEvent}
            stream={stream}
            participant={participant}
            broadcasting={broadcasting}
            streamMenuActions={streamMenuActions}
            userEventPermissions={userEventPermissions}
          >
            {streamContent}
          </CarouselStreamWrapper>
        )}
      </AspectRatioSizer>
    </div>
  );
}
