import { BaseComponentWithChildrenProps, ButtonProps } from "@q4/nimbus-ui";
import { Asset } from "../../../../hooks/assets/assets.hook.definition";
import { AssetData } from "../../../../hooks/assetUploads/assetUploads.definition";

export interface AudioVideoPreviewProps extends BaseComponentWithChildrenProps {
  asset: Asset;
  assetV2?: AssetData;
  meetingId: number;
  visible: boolean;
  footerActions?: ButtonProps[];
  onCloseRequest: () => void;
}

export enum AudioVideoPreviewClassNames {
  Base = "audio-video-preview",
  Container = "audio-video-preview_container",
  TimeStamp = "audio-video-preview_timestamp",
}
