import { useState } from "react";
import { isEmpty, keys, reduce } from "lodash";
import {
  passwordConfirmationMatches,
  passwordMeetsCriteria,
} from "../../views/login/services/passwordVerification.service";
import {
  ResetLoginFormModel,
  FormErrors,
  ErrorFormState,
} from "../../views/login/userLoginReset/loginReset.definition";
import { ErrorModel } from "../../components/modal/modal.definition";

export default function useRegistrationForm() {
  const [resetLoginState, setResetLoginState] = useState(new ResetLoginFormModel(null));
  const [errorState, setErrorState] = useState<ErrorFormState>(
    new ErrorFormState(getInitialErrorState())
  );

  function getInitialErrorState() {
    return new ErrorFormState({
      currentPasswordError: new ErrorModel(),
      passwordError: new ErrorModel(),
      confirmPasswordError: new ErrorModel(),
    });
  }

  function hasErrors(checkCurrentPassword = false) {
    const initialError = getInitialErrorState();

    if (checkCurrentPassword && isEmpty(resetLoginState.currentPassword)) {
      initialError.currentPasswordError = new ErrorModel(
        true,
        FormErrors.CURRENT_PASS_EMPTY,
        "Password"
      );
      setErrorState({ ...initialError });
    }

    if (!passwordMeetsCriteria(resetLoginState.password)) {
      initialError.passwordError = new ErrorModel(true, FormErrors.PASS_NOT_VALID, "Password");
      setErrorState({ ...initialError });
    }

    if (isEmpty(resetLoginState.passwordConfirmation)) {
      initialError.confirmPasswordError = new ErrorModel(true, FormErrors.PASS_EMPTY, "Password");
      setErrorState({ ...initialError });
    }

    if (isEmpty(resetLoginState.password)) {
      initialError.passwordError = new ErrorModel(true, FormErrors.PASS_EMPTY, "Password");
      setErrorState({ ...initialError });
    }

    if (
      !passwordConfirmationMatches(resetLoginState.password, resetLoginState.passwordConfirmation)
    ) {
      initialError.confirmPasswordError = new ErrorModel(
        true,
        FormErrors.PASS_MISMATCH,
        "Password"
      );
      setErrorState({ ...initialError });
    }

    if (
      !reduce(
        keys(initialError),
        (acc, errKey) => {
          return initialError[errKey].visible || acc;
        },
        false
      )
    ) {
      resetErrorState();
      return false;
    } else {
      return true;
    }
  }

  function handlePasswordChange(value: string): void {
    setResetLoginState({ ...resetLoginState, password: value });
  }
  function handlePasswordConfirmChange(value: string): void {
    setResetLoginState({ ...resetLoginState, passwordConfirmation: value });
  }
  function handleCurrentPasswordChange(value: string): void {
    setResetLoginState({ ...resetLoginState, currentPassword: value });
  }

  function resetErrorState() {
    setErrorState(getInitialErrorState);
  }

  function resetPasswordChangeState() {
    setResetLoginState(new ResetLoginFormModel(null));
  }

  return {
    resetLoginState,
    errorState,
    resetPasswordChangeState,
    resetErrorState,
    hasErrors,
    handlePasswordChange,
    handlePasswordConfirmChange,
    handleCurrentPasswordChange,
  };
}
