import { isNil, isEmpty, keys, reduce } from "lodash";
import validator from "validator";
import { ErrorModel } from "../../components/modal/modal.definition";
import { WaitingRoomType } from "../../components/waitingRoom/waitingRoom.definition";
import {
  AttendeeConsoleBranding,
  ControlPanelBranding,
  EventRecordingUrlConfig,
  UploadedDataFormat,
} from "../../services/event/event.model";
import { GeneralFonts } from "./components/micrositeControlPanelModal/controlPanelForm/controlPanelForm.definition";
import {
  LINE_HEIGHT,
  MAX_ROWS,
} from "./components/micrositePagesConfigurationModal/PageConfigurationModalForm/micrositePageConfigurationModalForm.definition";
import {
  EventBrandingPreviewType,
  EventMicrositePageType,
} from "./hooks/microsite.hook.definition";
import {
  AttendeeConsoleBrandingErrorKeys,
  AttendeeConsoleBrandingErrorState,
  AttendeeConsoleFieldErrorMap,
  ControlPanelBrandingErrorState,
  MicrositeModalErrorKey,
  MicrositeModalErrorMessage,
  PreEventPageBrandingErrorState,
  RegistrationBrandingErrorState,
  ThankYouPageBrandingErrorState,
  WelcomePageBrandingErrorState,
} from "./micrositeModal.definition";
import { isColorCodeValid, sanitizeColorCode } from "../../utils/colorCode.utils";

export const formatRecordings = (recordings: EventRecordingUrlConfig[]) =>
  (recordings || []).map((recording) => extractTypenameFromData(recording));

export const formatPhoneNumber = (number) =>
  number && number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");

export const formatOpenRegistrationOptions = (option) => {
  return { label: `${option} minutes before event`, value: option };
};

export const formatFontWeight = (fontWeight) => ({
  label: capitalize(fontWeight),
  value: fontWeight,
});

const capitalize = (value: string) => {
  if (typeof value !== "string") return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatFont = (font: UploadedDataFormat) => {
  if (!font) return;

  const { __typename, ...fontData } = font;
  return { label: font.displayName, value: font.name, data: fontData };
};

export const formatFonts = (fonts: UploadedDataFormat[]) => {
  const companyFonts = fonts.map((font) => {
    return formatFont(font);
  });

  return [...companyFonts, ...GeneralFonts];
};

export const checkIfFontExists = (
  font: UploadedDataFormat,
  savedCompanyFonts: UploadedDataFormat[]
) =>
  savedCompanyFonts.find((savedCompanyFont) => savedCompanyFont.name === font.name) ||
  GeneralFonts.find((generalFont) => generalFont.data.name === font.name);

export const extractTypenameFromData = (data) => {
  if (isNil(data)) return;

  let extractedData;

  if (data.__typename) {
    const { __typename, ...rest } = data;
    extractedData = rest;
  }
  return extractedData;
};

export const validURL = (url: string) => validator.isURL(url);

export const getWaitingRoomName = (page: EventMicrositePageType) => {
  if (!page) return;
  switch (page) {
    case EventMicrositePageType.WELCOME_PAGE:
      return WaitingRoomType.WelcomeRoom;
    case EventMicrositePageType.REGISTRATION_PAGE:
      return WaitingRoomType.RegistrationRoom;
    case EventMicrositePageType.PRE_EVENT_PAGE:
      return WaitingRoomType.BroadcastStarting;
    case EventMicrositePageType.THANK_YOU_PAGE:
      return WaitingRoomType.BroadcastEnded;
    case EventMicrositePageType.POST_EVENT_PAGE:
      return WaitingRoomType.PostEventRoom;
    case EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE:
      return WaitingRoomType.AttendeeConsole;
    default:
      console.error("Invalid page type for microsite:", page);
  }
};

export const getPreviewPageDetails = (
  previewPage: string,
  eventBrandingPreviewData: EventBrandingPreviewType
) => {
  const {
    welcomePageBranding,
    registrationPageBranding,
    preEventPageBranding,
    thankYouPageBranding,
    postEventPageBranding,
  } = eventBrandingPreviewData;
  const waitingRoomType = getWaitingRoomName(previewPage as EventMicrositePageType);

  switch (previewPage) {
    case EventMicrositePageType.WELCOME_PAGE:
      return {
        previewPageDetails: welcomePageBranding,
        room: "Welcome Page",
        waitingRoomType,
      };
    case EventMicrositePageType.REGISTRATION_PAGE:
      return {
        previewPageDetails: registrationPageBranding,
        room: "Registration Page",
        waitingRoomType,
      };
    case EventMicrositePageType.PRE_EVENT_PAGE:
      return {
        previewPageDetails: preEventPageBranding,
        room: "Pre-Event Page",
        waitingRoomType,
      };
    case EventMicrositePageType.THANK_YOU_PAGE:
      return {
        previewPageDetails: thankYouPageBranding,
        room: "Thank You Page",
        waitingRoomType,
      };
    case EventMicrositePageType.POST_EVENT_PAGE:
      return {
        previewPageDetails: postEventPageBranding,
        room: "Post-Event Page",
        waitingRoomType,
      };
    case EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE:
      return {
        previewPageDetails: registrationPageBranding,
        room: "Attendee Console",
        waitingRoomType,
      };
  }
};

export const getInitialWelcomePageBrandingErrorState = () => {
  return new WelcomePageBrandingErrorState({
    titleError: new ErrorModel(),
    proxyWebsiteLinkError: new ErrorModel(),
  });
};

export const getCurrentPageInitialErrorState = (currentPage: EventMicrositePageType) => {
  switch (currentPage) {
    case EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE:
      return new AttendeeConsoleBrandingErrorState({
        headerError: new ErrorModel(),
        footerError: new ErrorModel(),
        mediaBarError: new ErrorModel(),

        primaryButtonError: new ErrorModel(),
        primaryButtonTextError: new ErrorModel(),
        controlButtonError: new ErrorModel(),
        headerTextError: new ErrorModel(),
      });
    case EventMicrositePageType.WELCOME_PAGE:
      return new WelcomePageBrandingErrorState({
        titleError: new ErrorModel(),
        proxyWebsiteLinkError: new ErrorModel(),
      });
    case EventMicrositePageType.REGISTRATION_PAGE:
      return new RegistrationBrandingErrorState({
        titleError: new ErrorModel(),
        proxyWebsiteLinkError: new ErrorModel(),
        openRegistrationError: new ErrorModel(),
        termsAndConditionsUrlError: new ErrorModel(),
        broadridgeTelephoneNumberError: new ErrorModel(),
        broadridgeInternationalTelephoneNumberError: new ErrorModel(),
      });
    case EventMicrositePageType.PRE_EVENT_PAGE:
      return new PreEventPageBrandingErrorState({
        messageError: new ErrorModel(),
        musicOptionError: new ErrorModel(),
        lobbyMessageError: new ErrorModel(),
      });
    case EventMicrositePageType.THANK_YOU_PAGE:
      return new ThankYouPageBrandingErrorState({
        titleError: new ErrorModel(),
      });
  }
};

export const handleTextAreaChange = (event): void => {
  const maxHeight = LINE_HEIGHT * MAX_ROWS;
  event.target.style.height = "auto";
  event.target.style.height =
    maxHeight < event.target.scrollHeight ? `${maxHeight}px` : `${event.target.scrollHeight}px`;
};

export const getInitialControlPanelBrandingErrorState = () => {
  return new ControlPanelBrandingErrorState({
    primaryFontError: new ErrorModel(),
    secondaryFontError: new ErrorModel(),
    primaryColorError: new ErrorModel(),
    secondaryColorError: new ErrorModel(),
  });
};

export const getInitialAttendeeConsoleBrandingErrorState = () => {
  return new AttendeeConsoleBrandingErrorState({
    // background color errors
    headerError: new ErrorModel(),
    footerError: new ErrorModel(),
    mediaBarError: new ErrorModel(),

    // foreground color errors
    primaryButtonError: new ErrorModel(),
    primaryButtonTextError: new ErrorModel(),
    controlButtonError: new ErrorModel(),
    headerTextError: new ErrorModel(),
  });
};

export const isObjectEmpty = (value) => Object.values(value).every((o) => o === null);

export const getHasError = (initialError) =>
  reduce(
    keys(initialError),
    (acc, errKey) => {
      return initialError[errKey].visible || acc;
    },
    false
  );

export const checkForControlPanelErrors = (
  controlPanelData: ControlPanelBranding,
  resetControlPanelBrandingErrorState: () => void,
  setControlPanelBrandingErrorState: (state: ControlPanelBrandingErrorState) => void
) => {
  const initialControlPanelErrorState: ControlPanelBrandingErrorState =
    getInitialControlPanelBrandingErrorState();

  if (isEmpty(controlPanelData.primaryFont)) {
    initialControlPanelErrorState.primaryFontError = new ErrorModel(
      true,
      MicrositeModalErrorMessage.primaryFontErrorMessage,
      MicrositeModalErrorKey.PrimaryFont
    );
    setControlPanelBrandingErrorState({ ...initialControlPanelErrorState });
  }
  if (isEmpty(controlPanelData.secondaryFont)) {
    initialControlPanelErrorState.secondaryFontError = new ErrorModel(
      true,
      MicrositeModalErrorMessage.secondaryFontErrorMessage,
      MicrositeModalErrorKey.SecondaryFont
    );
    setControlPanelBrandingErrorState({ ...initialControlPanelErrorState });
  }

  if (!isColorCodeValid(sanitizeColorCode(controlPanelData.primaryColor))) {
    initialControlPanelErrorState.primaryColorError = new ErrorModel(
      true,
      MicrositeModalErrorMessage.primaryColorErrorMessage,
      MicrositeModalErrorKey.PrimaryColor
    );
    setControlPanelBrandingErrorState({ ...initialControlPanelErrorState });
  }
  if (!isColorCodeValid(sanitizeColorCode(controlPanelData.secondaryColor))) {
    initialControlPanelErrorState.secondaryColorError = new ErrorModel(
      true,
      MicrositeModalErrorMessage.secondaryColorErrorMessage,
      MicrositeModalErrorKey.SecondaryColor
    );
    setControlPanelBrandingErrorState({ ...initialControlPanelErrorState });
  }

  if (!getHasError(initialControlPanelErrorState)) {
    resetControlPanelBrandingErrorState();
    return false;
  }
  return true;
};

export const checkForAttendeeConsoleErrors = (
  attendeeConsoleData: AttendeeConsoleBranding,
  resetAttendeeConsoleBrandingErrorState: () => void,
  setAttendeeConsoleBrandingErrorState: (state: AttendeeConsoleBrandingErrorState) => void
) => {
  const initialAttendeeConsoleErrorState = getInitialAttendeeConsoleBrandingErrorState();

  const fieldErrorMap: AttendeeConsoleFieldErrorMap[] = [
    {
      key: AttendeeConsoleBrandingErrorKeys.HeaderText,
      value: attendeeConsoleData?.headerColors?.text,
      errorKey: MicrositeModalErrorKey.HeaderTextColor,
      errorMessage: MicrositeModalErrorMessage.headerTextColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.Header,
      value: attendeeConsoleData?.headerColors?.background,
      errorKey: MicrositeModalErrorKey.HeaderBackgroundColor,
      errorMessage: MicrositeModalErrorMessage.headerBackgroundColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.ControlButton,
      value: attendeeConsoleData?.mediaBarColors?.controls,
      errorKey: MicrositeModalErrorKey.MediaBarControlsColor,
      errorMessage: MicrositeModalErrorMessage.mediaBarControlsColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.MediaBar,
      value: attendeeConsoleData?.mediaBarColors?.background,
      errorKey: MicrositeModalErrorKey.MediaBarBackgroundColor,
      errorMessage: MicrositeModalErrorMessage.mediaBarBackgroundColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.Footer,
      value: attendeeConsoleData?.footerColors?.background,
      errorKey: MicrositeModalErrorKey.FooterBackgroundColor,
      errorMessage: MicrositeModalErrorMessage.footerBackgroundColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.PrimaryButton,
      value: attendeeConsoleData?.footerColors?.primaryButton,
      errorKey: MicrositeModalErrorKey.FooterPrimaryButtonColor,
      errorMessage: MicrositeModalErrorMessage.footerPrimaryButtonColorErrorMessage,
    },
    {
      key: AttendeeConsoleBrandingErrorKeys.PrimaryButtonText,
      value: attendeeConsoleData?.footerColors?.primaryButtonText,
      errorKey: MicrositeModalErrorKey.FooterPrimaryButtonTextColor,
      errorMessage: MicrositeModalErrorMessage.footerPrimaryButtonTextColorErrorMessage,
    },
  ];

  fieldErrorMap.forEach((option) => {
    if (isEmpty(option.value) || !isColorCodeValid(sanitizeColorCode(option.value))) {
      initialAttendeeConsoleErrorState[option.key] = new ErrorModel(
        true,
        option.errorMessage,
        option.errorKey
      );
      setAttendeeConsoleBrandingErrorState({ ...initialAttendeeConsoleErrorState });
    }
  });

  if (!getHasError(initialAttendeeConsoleErrorState)) {
    resetAttendeeConsoleBrandingErrorState();
    return false;
  }
  return true;
};

export const checkForErrors = (
  currentPage: EventMicrositePageType,
  currentPageData,
  setCurrentPageErrorState,
  resetCurrentPageErrorState
): boolean => {
  const initialCurrentPageErrorState: any = getCurrentPageInitialErrorState(currentPage);

  const emptyTitleField = currentPageData.title
    ? !currentPageData.title.trim()
    : !currentPageData.title;

  switch (currentPage) {
    case EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE:
      if (!getHasError(initialCurrentPageErrorState)) {
        resetCurrentPageErrorState();
        return false;
      }
      return true;

    case EventMicrositePageType.WELCOME_PAGE:
      if (emptyTitleField) {
        initialCurrentPageErrorState.titleError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.titleErrorMessage,
          MicrositeModalErrorKey.Title
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (currentPageData.proxyWebsiteLink && !validURL(currentPageData.proxyWebsiteLink)) {
        initialCurrentPageErrorState.proxyWebsiteLinkError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.invalidProxyWebsiteLinkErrorMessage,
          MicrositeModalErrorKey.ProxyWebsiteLink
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!getHasError(initialCurrentPageErrorState)) {
        resetCurrentPageErrorState();
        return false;
      }
      return true;
    case EventMicrositePageType.REGISTRATION_PAGE:
      if (emptyTitleField) {
        initialCurrentPageErrorState.titleError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.titleErrorMessage,
          MicrositeModalErrorKey.Title
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }
      if (!currentPageData.openRegistration) {
        initialCurrentPageErrorState.openRegistrationError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.openRegistrationErrorMessage,
          MicrositeModalErrorKey.OpenRegistration
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }
      if (
        currentPageData.broadridgeTelephoneNumber?.length > 0 &&
        currentPageData.broadridgeTelephoneNumber?.length < 10
      ) {
        initialCurrentPageErrorState.broadridgeTelephoneNumberError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.invalidBroadridgeTelephoneNumberErrorMessage,
          MicrositeModalErrorKey.broadridgeTelephoneNumber
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }
      if (
        currentPageData.broadridgeInternationalTelephoneNumber?.length > 0 &&
        currentPageData.broadridgeInternationalTelephoneNumber?.length < 10
      ) {
        initialCurrentPageErrorState.broadridgeInternationalTelephoneNumberError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.invalidBroadridgeTelephoneNumberErrorMessage,
          MicrositeModalErrorKey.BroadridgeInternationalTelephoneNumber
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }
      if (currentPageData.proxyWebsiteLink && !validURL(currentPageData.proxyWebsiteLink)) {
        initialCurrentPageErrorState.proxyWebsiteLinkError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.invalidProxyWebsiteLinkErrorMessage,
          MicrositeModalErrorKey.ProxyWebsiteLink
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }
      if (
        currentPageData.termsAndConditionsUrl &&
        !validURL(currentPageData.termsAndConditionsUrl)
      ) {
        initialCurrentPageErrorState.termsAndConditionsUrlError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.invalidTermsAndConditionsUrl,
          MicrositeModalErrorKey.TermsAndConditionsUrl
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!getHasError(initialCurrentPageErrorState)) {
        resetCurrentPageErrorState();
        return false;
      }
      return true;
    case EventMicrositePageType.PRE_EVENT_PAGE:
      if (!currentPageData.message) {
        initialCurrentPageErrorState.messageError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.emptyMessageErrorMessage,
          MicrositeModalErrorKey.Message
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!currentPageData.lobbyMessage) {
        initialCurrentPageErrorState.lobbyMessageError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.emptyMessageErrorMessage,
          MicrositeModalErrorKey.Message
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!currentPageData.musicOption) {
        initialCurrentPageErrorState.musicOptionError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.musicOptionErrorMessage,
          MicrositeModalErrorKey.MusicOption
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!getHasError(initialCurrentPageErrorState)) {
        resetCurrentPageErrorState();
        return false;
      }
      return true;
    case EventMicrositePageType.THANK_YOU_PAGE:
      if (emptyTitleField) {
        initialCurrentPageErrorState.titleError = new ErrorModel(
          true,
          MicrositeModalErrorMessage.titleErrorMessage,
          MicrositeModalErrorKey.Title
        );
        setCurrentPageErrorState({ ...initialCurrentPageErrorState });
      }

      if (!getHasError(initialCurrentPageErrorState)) {
        resetCurrentPageErrorState();
        return false;
      }
      return true;
  }
};

interface AttendeeConsoleBrandingFeatureFlag {
  companies?: string[];
  global?: boolean;
}

export const isAttendeeBrandingEnabled = (
  flag: AttendeeConsoleBrandingFeatureFlag,
  companyId: string
): boolean => {
  const { companies = [], global = false } = flag ?? {};

  return global || companies.includes(companyId);
};
