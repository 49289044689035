import {
  Button,
  ButtonSize,
  ButtonTheme,
  getClassName,
  KeyboardKey,
  PlaceholderContent,
  PlaceholderContentTheme,
  Spinner,
} from "@q4/nimbus-ui";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, {
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { useResizeDetector } from "react-resize-detector";
import { useSwipeable } from "react-swipeable";
import { doNothing } from "../../utils";
import { SlideListRenderer } from "./slideListRenderer.component";
import "./slideViewer.component.scss";
import { SlideViewerClassNames, SlideViewerProps } from "./slideViewer.definition";

// worker to render pdf
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const spinner = <Spinner masked={false} />;

export const LabelledSpinner = (
  <div className={SlideViewerClassNames.LoadingWrapper}>
    <div className={SlideViewerClassNames.Loading}>Loading slides</div>
    <Spinner masked={true} />
  </div>
);

async function convertFileToBlob(url: string): Promise<Blob> {
  const response = await fetch(url);
  return response.blob();
}

const SlideViewer = (props: SlideViewerProps): JSX.Element => {
  const {
    dataId,
    defaultId = "Slides",
    failed,
    file,
    id,
    infiniteScroll = false,
    initialPage = 1,
    nextPageBtnIcon = "q4i-caret-sm-right-4pt",
    onFileLoadFail,
    placeholderProps,
    prevPageBtnIcon = "q4i-caret-sm-left-4pt",
    thumbnailListVisible = true,
    includeThumbnailList = true,
    url,
    viewerScale = 1,
    showControls = true,
    overrides,
    onCanvasRefLoaded = () => doNothing,
    onPageChange = () => doNothing,
  } = props;

  const [aspectRatio, setAspectRatio] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pages, setPages] = useState(0);
  const mainViewerRef = useRef<HTMLDivElement>();

  const [pageResolution, setPageResolution] = useState({
    height: null,
    width: null,
  });

  const [_file, setFile] = useState<Blob>(null);

  const componentId = useMemo(() => `${id ?? ""}${defaultId}`, [defaultId, id]);
  const componentDataId = useMemo(() => `${dataId ?? ""}${defaultId}`, [dataId, defaultId]);

  const [docLoaded, setDocLoaded] = useState(false);

  // #region Helper Methods
  const setPrevPage = useCallback(() => {
    if (currentPage === 1) {
      if (infiniteScroll) {
        setCurrentPage(pages);
      }
      return;
    }

    setCurrentPage(currentPage - 1);
  }, [currentPage, infiniteScroll, pages]);

  const setNextPage = useCallback(() => {
    if (currentPage === pages) {
      if (infiniteScroll) {
        setCurrentPage(1);
      }
      return;
    }
    setCurrentPage(currentPage + 1);
  }, [currentPage, infiniteScroll, pages]);

  const setPage = useCallback(
    (page: number) => {
      if (page > pages || page < 1) return;
      setCurrentPage(page);
    },
    [pages]
  );

  const getPresentationFile = useCallback(
    async (url: string): Promise<void> => {
      try {
        const file = await convertFileToBlob(url);
        setFile(file);
      } catch (error) {
        console.error(error);
        onFileLoadFail?.(error);
      }
    },
    [onFileLoadFail]
  );

  const refreshRate = overrides?.height || overrides?.width ? 0 : 50;

  const { width: mainViewerWidth, height: mainViewerHeight } = useResizeDetector({
    refreshMode: "throttle",
    refreshRate,
    targetRef: mainViewerRef,
  });

  const updatePageResolution = useCallback(() => {
    const containerBounds = mainViewerRef.current?.getBoundingClientRect();

    const viewerHeight = mainViewerHeight || containerBounds?.height;
    const viewerWidth = mainViewerWidth || containerBounds?.width;

    if (!viewerWidth || !viewerHeight || !aspectRatio) return;

    const isOverflowX = viewerHeight * aspectRatio > viewerWidth;

    if (isOverflowX) {
      setPageResolution({
        height: null,
        width: viewerWidth,
      });
    } else {
      setPageResolution({
        height: viewerHeight,
        width: null,
      });
    }
  }, [mainViewerWidth, mainViewerHeight, aspectRatio]);

  useEffect(updatePageResolution, [updatePageResolution]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: setNextPage,
    onSwipedRight: setPrevPage,
  });
  // #endregion

  useEffect(
    function getFileByUrl() {
      getPresentationFile(url);
    },
    [getPresentationFile, url]
  );
  // #endregion

  // #region Handle Methods
  const handleDocumentLoad = useCallback(
    (data) => {
      const { numPages } = data;
      setDocLoaded(true);
      setCurrentPage(initialPage);
      setPages(numPages);
    },
    [initialPage]
  );

  const handlePageLoad = useCallback(
    (page: pdfjs.PDFPageProxy) => {
      const pageViewport = page.getViewport({ scale: viewerScale });
      const height = pageViewport.height;
      const width = pageViewport.width;

      setAspectRatio(width / height);
      updatePageResolution();
      onPageChange({ width, height });
    },
    [updatePageResolution, viewerScale, onPageChange]
  );

  function handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
    if (e.key === KeyboardKey.ArrowRight) setNextPage();
    else if (e.key === KeyboardKey.ArrowLeft) setPrevPage();
  }

  // #endregion

  // #region Render Methods
  const errorPlaceholder = useMemo(
    function (): JSX.Element {
      const errorId = `${componentId}ErrorPlaceholder`;
      const errorDataId = `${componentDataId}ErrorPlaceholder`;

      return (
        <PlaceholderContent
          className={SlideViewerClassNames.ErrorPlaceholder}
          theme={PlaceholderContentTheme.Light}
          id={errorId}
          data-id={errorDataId}
          {...placeholderProps}
        />
      );
    },
    [componentDataId, componentId, placeholderProps]
  );

  const noData = useMemo(
    function (): JSX.Element {
      return failed ? errorPlaceholder : LabelledSpinner;
    },
    [errorPlaceholder, failed]
  );
  // #endregion

  const controlLeftDisabled = useMemo(() => {
    return !infiniteScroll && currentPage === 1;
  }, [infiniteScroll, currentPage]);
  const controlRightDisabled = useMemo(() => {
    return !infiniteScroll && currentPage === pages;
  }, [infiniteScroll, currentPage, pages]);

  const controlLeftClassName = useMemo(
    () =>
      getClassName(SlideViewerClassNames.ControlsLeft, [
        {
          condition: controlLeftDisabled,
          trueClassName: `${SlideViewerClassNames.ControlsLeft}_disabled`,
        },
      ]),
    [controlLeftDisabled]
  );

  const controlRightClassName = useMemo(
    () =>
      getClassName(SlideViewerClassNames.ControlsRight, [
        {
          condition: controlRightDisabled,
          trueClassName: `${SlideViewerClassNames.ControlsRight}_disabled`,
        },
      ]),
    [controlRightDisabled]
  );

  const onThumbnailSelected = useCallback((_page) => setCurrentPage(_page), []);

  const controls = useMemo(() => {
    return (
      <div className={SlideViewerClassNames.Controls}>
        <Button
          icon={prevPageBtnIcon}
          className={controlLeftClassName}
          theme={ButtonTheme.SoftGrey}
          onClick={setPrevPage}
          id={`${componentId}ControlLeft`}
          data-id={`${componentDataId}ControlLeft`}
          disabled={controlLeftDisabled}
          size={ButtonSize.Large}
        />
        <Button
          className={SlideViewerClassNames.AmountPages}
          theme={ButtonTheme.SoftGrey}
          disabled={true}
          label={`${currentPage} / ${pages}`}
          size={ButtonSize.Large}
        />
        <Button
          icon={nextPageBtnIcon}
          className={controlRightClassName}
          theme={ButtonTheme.SoftGrey}
          onClick={setNextPage}
          id={`${componentId}ControlRight`}
          data-id={`${componentDataId}ControlRight`}
          disabled={controlRightDisabled}
          size={ButtonSize.Large}
        />
      </div>
    );
  }, [
    componentDataId,
    componentId,
    controlLeftClassName,
    controlLeftDisabled,
    controlRightClassName,
    controlRightDisabled,
    currentPage,
    nextPageBtnIcon,
    pages,
    prevPageBtnIcon,
    setNextPage,
    setPrevPage,
  ]);

  return (
    <div
      className={SlideViewerClassNames.Base}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      id={componentId}
      data-id={componentDataId}
    >
      <Document
        className={SlideViewerClassNames.Document}
        error={errorPlaceholder}
        file={file || _file}
        loading={LabelledSpinner}
        noData={noData}
        onLoadSuccess={handleDocumentLoad}
      >
        {docLoaded && (
          <SlideListRenderer
            currentPage={currentPage}
            pagesToRender={pages}
            readyToRender={docLoaded}
            render={includeThumbnailList}
            visible={thumbnailListVisible}
            onPageSelected={onThumbnailSelected}
          />
        )}
        <div className={SlideViewerClassNames.MainViewerOuter}>
          <div className={SlideViewerClassNames.MainViewer} ref={mainViewerRef}>
            <div
              className={SlideViewerClassNames.MainViewerInner}
              {...swipeHandlers}
              id="testSlides"
            >
              <Page
                key={`pdf_page_${currentPage}`}
                height={overrides?.height ?? pageResolution?.height}
                width={overrides?.width ?? pageResolution?.width}
                loading={spinner}
                onLoadSuccess={handlePageLoad}
                pageNumber={currentPage}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={viewerScale}
                wrap={false}
                canvasRef={onCanvasRefLoaded(setPage, pages)}
              />
              {showControls && controls}
            </div>
          </div>
        </div>
      </Document>
    </div>
  );
};

export default memo(SlideViewer);
