import React, { FunctionComponent, createContext, useMemo, useState } from "react";
import { LoadingScreen } from "../../components/loadingScreen/loadingScreen.component";

interface LoadingContextState {
  loadingScreen: JSX.Element | null;
  setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingIcon: React.Dispatch<React.SetStateAction<string>>;
}

export const LoadingContext = createContext<Partial<LoadingContextState>>({});

export const LoadingProvider: FunctionComponent<any> = (props) => {
  const { children } = props;

  const [showLoading, setShowLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState("");

  const loadingScreen = useMemo(() => {
    if (!showLoading) return null;
    return <LoadingScreen q4Icon={loadingIcon} />;
  }, [loadingIcon, showLoading]);

  const providerValues = useMemo(
    () => ({
      loadingScreen,
      setShowLoading,
      setLoadingIcon,
    }),
    [loadingScreen]
  );

  return <LoadingContext.Provider value={providerValues}>{children}</LoadingContext.Provider>;
};
