import { gql } from "@apollo/client";

export enum EventMeetingMaterialType {
  SLIDESHOW = "SLIDESHOW",
  SPREADSHEET = "SPREADSHEET",
  DOCUMENT = "DOCUMENT",
  WEBLINK = "WEBLINK",
}

export interface EventMeetingMaterial {
  name: string;
  displayName: string;
  materialId: string;
  materialSize?: string;
  materialType: EventMeetingMaterialType;
}
export interface UpdateEventMeetingMaterialVariables {
  meetingId: number;
  materialId: string;
  newMaterialName?: string;
  newMaterialUrl?: string;
}

export interface UpdateEventMeetingMaterialData {
  updateEventMeetingMaterial: {
    materials: EventMeetingMaterial[];
  };
}

export interface RemoveEventUrlMeetingMaterialsVariables {
  meetingId: number;
  materialId: string;
}

export interface RemoveEventUrlMeetingMaterialsData {
  removeEventMeetingMaterialLink: {
    title: string;
    materials: EventMeetingMaterial[];
  };
}

export interface UploadEventMeetingMaterialsVariables {
  file: File;
  eventId: string;
  meetingId: number;
  materialSize: string;
  materialType: EventMeetingMaterialType;
}

export interface UploadEventMeetingMaterialsData {
  uploadEventMeetingMaterial: {
    title: string;
    materials: EventMeetingMaterial[];
  };
}

export interface RemoveUploadedEventMeetingMaterialsVariables {
  eventId: string;
  meetingId: number;
  name: string;
  materialId: string;
}

export interface RemoveUploadedEventMeetingMaterialsData {
  removeUploadedEventMeetingMaterial: {
    title: string;
    materials: EventMeetingMaterial[];
  };
}

export interface CopyEventMeetingMaterialsVariables {
  fromEventId: string;
  toEventId: string;
  toMeetingId: number;
}

export interface CopyEventMeetingMaterialsData {
  copyEventMeetingMaterials: {
    title: string;
    materials: EventMeetingMaterial[];
  };
}

export interface UpdateEventMeetingMaterialsSubVars {
  meetingId: number;
}

export interface UpdateEventMeetingMaterialsSubData {
  onEventMeetingMaterialsUpdated: {
    materials: EventMeetingMaterial[];
  };
}

export const ADD_EVENT_MEETING_MATERIALS_LINK = gql`
  mutation ADD_EVENT_MEETING_MATERIALS_LINK(
    $meetingId: Int
    $urlMaterial: EventUrlMeetingMaterialsParams
  ) {
    addEventMeetingMaterialLink(meetingId: $meetingId, urlMaterial: $urlMaterial) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const REMOVE_EVENT_MEETING_MATERIALS_LINK = gql`
  mutation REMOVE_EVENT_MEETING_MATERIALS_LINK($meetingId: Int, $materialId: String) {
    removeEventMeetingMaterialLink(meetingId: $meetingId, materialId: $materialId) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const UPDATE_EVENT_MEETING_MATERIAL = gql`
  mutation UPDATE_EVENT_MEETING_MATERIAL(
    $meetingId: Int
    $materialId: String
    $newMaterialName: String
    $newMaterialUrl: String
  ) {
    updateEventMeetingMaterial(
      meetingId: $meetingId
      materialId: $materialId
      newMaterialName: $newMaterialName
      newMaterialUrl: $newMaterialUrl
    ) {
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const UPLOAD_EVENT_MEETING_MATERIALS = gql`
  mutation UPLOAD_EVENT_MEETING_MATERIALS(
    $eventId: String
    $meetingId: Int
    $file: Upload
    $materialSize: String
    $materialType: EventMeetingMaterialType
  ) {
    uploadEventMeetingMaterial(
      eventId: $eventId
      meetingId: $meetingId
      file: $file
      materialSize: $materialSize
      materialType: $materialType
    ) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const REMOVE_EVENT_MEETING_MATERIALS = gql`
  mutation REMOVE_EVENT_MEETING_MATERIALS(
    $eventId: String
    $meetingId: Int
    $name: String
    $materialId: String
  ) {
    removeUploadedEventMeetingMaterial(
      eventId: $eventId
      meetingId: $meetingId
      name: $name
      materialId: $materialId
    ) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const COPY_EVENT_MEETING_MATERIALS = gql`
  mutation COPY_EVENT_MEETING_MATERIALS(
    $fromEventId: String
    $toEventId: String
    $toMeetingId: Int
  ) {
    copyEventMeetingMaterials(
      fromEventId: $fromEventId
      toEventId: $toEventId
      toMeetingId: $toMeetingId
    ) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

export const UPDATE_EVENT_MEETING_MATERIAL_SUBSCRIPTION = gql`
  subscription ($meetingId: Int) {
    onEventMeetingMaterialsUpdated(meetingId: $meetingId) {
      title
      materials {
        name
        displayName
        materialSize
        materialType
        materialId
      }
    }
  }
`;

