import { ControlPanelBranding } from "../../services/event/event.model";
import { PublicEventDetails } from "../../services/event/eventGql.model";

export enum WaitingRoomType {
  LeftBroadcast = "LeftBroadcast",
  BroadcastStarting = "BroadcastStarting",
  BroadcastEnded = "BroadcastEnded",
  LeftSession = "LeftSession",
  MeetingStarting = "MeetingStarting",
  SessionEnded = "SessionEnded",
  PostEventRoom = "PostEventRoom",
  WelcomeRoom = "WelcomeRoom",
  RegistrationRoom = "RegistrationRoom",
  AttendeeConsole = "AttendeeConsole",
}

export interface WaitingRoomProps {
  type?: WaitingRoomType;
  event?: PublicEventDetails;
  eventBranding?: ControlPanelBranding;
  eventTitle?: string;
  roomDetails?: any;
  isPreview?: boolean;
  isMiniPreview?: boolean;
  companyId: string;
  isPaused?: boolean;
  setIsMusicModalEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleMusic?: () => void;
}

export enum WaitingRoomClassNames {
  Base = "waiting-room",
  Title = "waiting-room_title",
  Icon = "waiting-room_icon",
  Message = "waiting-room_message",
  Description = "waiting-room_description",
  Background = "waiting-room_background",
  LiveBackground = "waiting-room_live-background",
  Banner = "waiting-room_banner",
  Brand = "waiting-room_brand",
  Body = "waiting-room_body",
  Footer = "waiting-room_footer--support",
  PaddedMessage = "waiting-room_message_padded",
}

export enum FontExtension {
  woff = "woff",
  woff2 = "woff2",
  svg = "svg",
  ttf = "ttf",
  otf = "otf",
  eot = "eot",
}

const dataIdPrefix = "WaitingRoom";
export const DATA_IDS = {
  TITLE: `${dataIdPrefix}Title`,
  DESCRIPTION: `${dataIdPrefix}Description`,
};

export const EventSupportEmail = "events_support@q4inc.com";
