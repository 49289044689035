import React, { memo, useEffect, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Form,
  Modal,
  Spinner,
  SpinnerTheme,
  Text,
  Textbox,
  TextboxTheme,
} from "@q4/nimbus-ui";
import {
  ChangePasswordProps,
  ChangePasswordClassNames,
  DATA_IDS,
} from "./changePassword.definition";
import { FormErrors } from "../../../views/login/userLoginReset/loginReset.definition";
import useLoginResetForm from "../../../hooks/users/resetPassworForm.hook";
import "./changePassword.scss";

function ChangePassword(props: ChangePasswordProps): JSX.Element {
  const currentPasswordRef = React.createRef<HTMLInputElement>();
  const newPasswordRef = React.createRef<HTMLInputElement>();
  const confirmNewPasswordRef = React.createRef<HTMLInputElement>();

  const { visible, onClose, onSubmit, loading } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const {
    errorState,
    hasErrors,
    resetLoginState,
    handleCurrentPasswordChange,
    handlePasswordChange,
    handlePasswordConfirmChange,
    resetPasswordChangeState,
    resetErrorState,
  } = useLoginResetForm();

  useEffect(() => {
    // TODO add type property to nimbus TextBox component
    if (currentPasswordRef.current) currentPasswordRef.current.type = "password";
    if (newPasswordRef.current) newPasswordRef.current.type = "password";
    if (confirmNewPasswordRef.current) confirmNewPasswordRef.current.type = "password";
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleConfirmPasswordChange(value) {
    handlePasswordConfirmChange(value);
  }

  async function handleSaveChangePassword() {
    const errors = hasErrors(true);
    if (errors || !onSubmit) return;
    const isValid = await onSubmit({ ...resetLoginState });
    if (isValid) {
      resetPasswordChangeState();
    }
  }

  function handleCancelChangePassword() {
    resetPasswordChangeState();
    resetErrorState();
    onClose && onClose();
  }

  function toggleShowNewPassword() {
    setShowNewPassword(!showNewPassword);
    if (newPasswordRef.current) {
      newPasswordRef.current.type = showNewPassword ? "password" : "";
    }
  }

  function toggleShowConfirmNewPassword() {
    setShowConfirmNewPassword(!showConfirmNewPassword);
    if (confirmNewPasswordRef.current) {
      confirmNewPasswordRef.current.type = showConfirmNewPassword ? "password" : "";
    }
  }

  function toggleShowCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
    if (currentPasswordRef.current) {
      currentPasswordRef.current.type = showCurrentPassword ? "password" : "";
    }
  }

  return (
    <Modal
      title="Change password"
      visible={visible}
      onCloseRequest={onClose}
      footerActions={[
        {
          key: "close",
          label: "Cancel",
          dataId: DATA_IDS.CANCEL_BUTTON,
          theme: ButtonTheme.DarkSlate,
          onClick: handleCancelChangePassword,
        },
        {
          key: "save",
          label: "Save",
          dataId: DATA_IDS.SAVE_BUTTON,
          theme: ButtonTheme.Citrus,
          onClick: handleSaveChangePassword,
        },
      ]}
    >
      {loading && <Spinner masked={true} theme={SpinnerTheme.Rain} />}
      <Form
        className={ChangePasswordClassNames.Form}
        fields={[
          {
            key: "currentPassword",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Current Password",
            error: errorState.currentPasswordError,
            children: (
              <>
                <Textbox
                  className={ChangePasswordClassNames.Password}
                  dataId={DATA_IDS.CURRENT_PASSWORD}
                  value={resetLoginState.currentPassword}
                  inputRef={currentPasswordRef}
                  onChange={handleCurrentPasswordChange}
                  theme={errorState.currentPasswordError.visible ? TextboxTheme.Spice : null}
                />
                <Button
                  square
                  className={ChangePasswordClassNames.ShowPassword}
                  theme={ButtonTheme.LightGrey}
                  onClick={toggleShowCurrentPassword}
                  icon={"q4i-surveillance-4pt"}
                  size={ButtonSize.Large}
                />
              </>
            ),
          },
          {
            key: "password",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "New Password",
            error: errorState.passwordError,
            children: (
              <>
                <Textbox
                  className={ChangePasswordClassNames.Password}
                  dataId={DATA_IDS.NEW_PASSWORD}
                  inputRef={newPasswordRef}
                  onChange={handlePasswordChange}
                  value={resetLoginState.password}
                  theme={errorState.passwordError.visible ? TextboxTheme.Spice : null}
                />
                <Button
                  square
                  className={ChangePasswordClassNames.ShowPassword}
                  theme={ButtonTheme.LightGrey}
                  onClick={toggleShowNewPassword}
                  icon={"q4i-surveillance-4pt"}
                  size={ButtonSize.Large}
                />
              </>
            ),
          },
          {
            key: "passwordText",
            width: "1-of-1",
            smallWidth: "1-of-1",
            children: <Text>{FormErrors.PASS_NOT_VALID}</Text>,
          },
          {
            key: "confirmPassword",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Confirm New Password",
            error: errorState.confirmPasswordError,
            children: (
              <>
                <Textbox
                  className={ChangePasswordClassNames.Password}
                  dataId={DATA_IDS.CONFIRM_PASSWORD}
                  inputRef={confirmNewPasswordRef}
                  onChange={handleConfirmPasswordChange}
                  value={resetLoginState.passwordConfirmation}
                  theme={errorState.confirmPasswordError.visible ? TextboxTheme.Spice : null}
                />
                <Button
                  square
                  className={ChangePasswordClassNames.ShowPassword}
                  theme={ButtonTheme.LightGrey}
                  onClick={toggleShowConfirmNewPassword}
                  icon={"q4i-surveillance-4pt"}
                  size={ButtonSize.Large}
                />
              </>
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default memo(ChangePassword);
