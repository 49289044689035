import React from "react";
import { useEffect, useRef, useState } from "react";
import { Redirect, Route, useHistory, withRouter } from "react-router-dom";
import config from "../../../../config";
import { AuthConstants, LoginStates } from "../../../../contexts/auth0/auth0.definition";
import { LoginRedirectRouteProps } from "./loginRedirectRoute.definition";
import { LoadingScreen } from "../../../../components/loadingScreen/loadingScreen";

const LoginRedirectRoute = (props: LoginRedirectRouteProps): JSX.Element => {
  const { handleAuthOnCallback, path, ...rest } = props;
  const history = useHistory();

  const hashExists = window.location.hash ? true : false;

  const [isParsingHash, setIsParsingHash] = useState(hashExists);
  const finalUrl = getRedirectUrl();
  let url = useRef(finalUrl);

  function getRedirectUrl() {
    const appUrl = config.app.url;
    const redirectSessionKey = sessionStorage?.getItem(AuthConstants.LOGIN_REDIRECT_SESSION_KEY);
    let redirectUrl;
    // stripping appUrl from redirect session key
    if (redirectSessionKey?.includes(appUrl)) {
      redirectUrl = redirectSessionKey.replace(appUrl, "");
    }
    redirectUrl = redirectUrl || "/";
    return redirectUrl;
  }

  useEffect(() => {
    if (window.location.hash) {
      handleAuthOnCallback()
        .then(() => {
          setIsParsingHash(false);
        })
        .catch(() => history.push(`/login?state=${LoginStates.UserMissing}`));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isParsingHash ? (
    <LoadingScreen q4Icon="ni-q4-logo" />
  ) : (
    <Route path={path} {...rest}>
      {<Redirect to={url.current} />}
    </Route>
  );
};

export default withRouter(LoginRedirectRoute);
