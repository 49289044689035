import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { ParticipantContext } from "../../../../contexts";
import {
  TakeControlHookOutput,
  TakeControlHookProps,
  useGetCurrentPresenterQuery,
  usePresenterUpdatedSubscription,
  useTakeControlsMutation,
} from "./takeControl.definition";

export const useTakeControlHook = (props: TakeControlHookProps): TakeControlHookOutput => {
  const { meetingId } = props;

  const {
    participantsService: { currentParticipant, getParticipantById },
  } = useContext(ParticipantContext);

  const currentParticipantId = useMemo(() => currentParticipant?.id, [currentParticipant]);
  const [participantInControl, setParticipantInControl] = useState<string>("");
  const notificationService = useRef(new NotificationService());

  const [getCurrentPresenter, { data: currentPresenter }] = useGetCurrentPresenterQuery({
    fetchPolicy: "cache-and-network",
    onError: console.error,
  });

  const [_takeControl] = useTakeControlsMutation({
    onCompleted: () => {
      notificationService.current.info("You are now controlling slides for everyone.");

      if (participantInControl !== currentParticipantId)
        setParticipantInControl(currentParticipantId);
    },
    onError: () => {
      const msg = "Failed to take control of slides";
      console.error(msg);
      notificationService.current.error(msg);
    },
  });

  const getParticipantName = useCallback(
    (participantId: string) => {
      const participant = getParticipantById(participantId);
      return participant?.customName || participant?.name || "";
    },
    [getParticipantById]
  );

  const onPresenterUpdatedDataCallback = useCallback(
    ({ data: subscriptionData }) => {
      const latestInControl = subscriptionData?.data?.onAssetPresenterChange;

      if (!latestInControl || participantInControl === latestInControl) return;

      if (latestInControl !== currentParticipantId) {
        const participantName = getParticipantName(latestInControl);
        notificationService.current.info(`${participantName} took control of the slides.`);
      }

      if (participantInControl !== latestInControl) setParticipantInControl(latestInControl);
    },
    [currentParticipantId, getParticipantName, participantInControl]
  );

  usePresenterUpdatedSubscription({
    variables: { meetingId },
    skip: !meetingId,
    onData: onPresenterUpdatedDataCallback,
  });

  const isLocallyControlled = useMemo(() => {
    return currentParticipantId === participantInControl;
  }, [currentParticipantId, participantInControl]);

  const takeControl = useCallback(async () => {
    await _takeControl({
      variables: { meetingId, participantId: currentParticipantId },
    });
  }, [meetingId, currentParticipantId, _takeControl]);

  /**
   * Fetch the current presenter (if available)
   */
  useEffect(
    function getInitialPresenter() {
      if (!currentPresenter && meetingId) {
        getCurrentPresenter({
          variables: { meetingId },
        });
      }
    },
    [currentPresenter, meetingId, getCurrentPresenter]
  );

  /**
   * Set the initial presenter if subscription hasn't already made an update
   */
  useEffect(
    function setInitialPresenter() {
      if (currentPresenter?.getCurrentAssetPresenter && !participantInControl) {
        setParticipantInControl(currentPresenter.getCurrentAssetPresenter);
      }
    },
    [currentPresenter, participantInControl, setParticipantInControl]
  );

  return {
    participantInControl: {
      name: getParticipantName(participantInControl),
      isLocallyControlled,
    },
    takeControlService: {
      takeControl,
    },
  };
};
