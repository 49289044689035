import { getClassName, Spinner, useStateRef, Skeleton } from "@q4/nimbus-ui";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Page } from "react-pdf";
import "./slideListRenderer.component.scss";
import {
  SlideListRendererClassNames,
  SlideListRendererProps,
} from "./slideListRenderer.definition";
import { SmallSlideSettings } from "./slideViewer.definition";

const spinner = <Spinner masked={false} />;

const RENDER_BUFFER = 10;
function shouldRenderInSegments(pages) {
  return pages > RENDER_BUFFER * 1.5;
}

export function SlideListRenderer(props: SlideListRendererProps) {
  const { render, visible, currentPage, onPageSelected, readyToRender, pagesToRender } = props;

  const pagesRef = useRef(pagesToRender);

  const [slideThumbnails, slideThumbnailsRef, setSlidesThumbnails] = useStateRef([]);

  const [thumbnailsLoaded, thumbnailsLoadedRef, setThumbnailsLoaded] = useStateRef(0);
  const [thumbnailsLeftToLoad, setThumbnailsLeftToLoad] = useState(pagesRef.current);

  const renderInSegments = useRef(false);
  const renderSegmentBreakActive = useRef(false);

  useEffect(() => {
    pagesRef.current = pagesToRender;
  }, [pagesToRender]);

  useEffect(
    function calculateThumbnailsLeftToLoad() {
      if (!pagesRef.current) return;

      setThumbnailsLeftToLoad(pagesRef.current - thumbnailsLoadedRef.current);
    },
    [thumbnailsLoaded, pagesRef, thumbnailsLoadedRef]
  );

  const updateCurrentSmallPageStyling = useCallback(() => {
    const prevCurrentPage = document.querySelector(
      `.${SlideListRendererClassNames.SmallSlideWrapperIsActive}`
    );
    prevCurrentPage?.classList?.remove(SlideListRendererClassNames.SmallSlideWrapperIsActive);

    const newCurrentPage = document.querySelector(
      `.${SlideListRendererClassNames.SmallSlidePageIndicator}-${currentPage}`
    );
    newCurrentPage?.classList?.add(SlideListRendererClassNames.SmallSlideWrapperIsActive);
  }, [currentPage]);

  useEffect(updateCurrentSmallPageStyling, [updateCurrentSmallPageStyling]);

  const renderAThumbnail = useCallback(
    (pageToRender: number, onSuccessCallback?: (pageRendered: number) => void) => {
      return (
        <div
          className={`${SlideListRendererClassNames.SmallSlideWrapper} ${SlideListRendererClassNames.SmallSlidePageIndicator}-${pageToRender}`}
          onClick={() => onPageSelected(pageToRender)}
          key={`slide-thumbnail-${pageToRender}`}
        >
          <div className={SlideListRendererClassNames.SmallSlidePage}>{pageToRender}</div>
          <Page
            className={SlideListRendererClassNames.SmallSlide}
            onLoadSuccess={() => onSuccessCallback?.(pageToRender)}
            pageNumber={pageToRender}
            width={SmallSlideSettings.ITEM_WIDTH}
            loading={spinner}
            wrap={false}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </div>
      );
    },
    [onPageSelected]
  );

  const loadingThumbnails = useMemo(
    () =>
      Array.apply(null, Array(Math.min(RENDER_BUFFER, thumbnailsLeftToLoad))).map(
        (_: any, index) => (
          <div
            className={SlideListRendererClassNames.SmallSlideWrapper}
            key={`loading-thumbnail-${index}`}
          >
            <div className={SlideListRendererClassNames.SmallSlideLoadingPage}>
              <Skeleton
                className={SlideListRendererClassNames.SmallSlideLoadingPageAnimation}
                key={`loading-thumbnail-animation-${index}`}
                active={true}
              />
            </div>
          </div>
        )
      ),
    [thumbnailsLeftToLoad]
  );

  const _lazyLoadThumbnails = useCallback(
    (pageNumber) => {
      if (
        pageNumber > pagesRef.current ||
        slideThumbnailsRef.current.length >= pageNumber ||
        renderSegmentBreakActive.current
      ) {
        updateCurrentSmallPageStyling();
        return;
      }

      const renderedPage = renderAThumbnail(pageNumber, (pageRendered) => {
        setThumbnailsLoaded(thumbnailsLoadedRef.current + 1);

        if (pageNumber % RENDER_BUFFER === 0) {
          renderSegmentBreakActive.current = true;

          setTimeout(() => {
            renderSegmentBreakActive.current = false;
            _lazyLoadThumbnails(pageRendered + 1);
          }, 600);
        } else {
          _lazyLoadThumbnails(pageRendered + 1); // recursively load pages 1 by 1
        }
      });

      setSlidesThumbnails((s) => [...s, renderedPage]);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [slideThumbnailsRef, pagesRef, setSlidesThumbnails, setThumbnailsLoaded, thumbnailsLoadedRef]
  );

  useEffect(
    function lazyLoadThumbnails() {
      if (
        !pagesRef.current ||
        !readyToRender ||
        !render ||
        slideThumbnailsRef.current.length === pagesRef.current
      )
        return;

      renderInSegments.current = shouldRenderInSegments(pagesRef.current);

      if (slideThumbnailsRef.current.length > 0) {
        setSlidesThumbnails([]);
        setThumbnailsLoaded(0);
      }
      setTimeout(() => _lazyLoadThumbnails(1), 200);
    },
    [
      readyToRender,
      render,
      slideThumbnailsRef,
      setSlidesThumbnails,
      setThumbnailsLoaded,
      _lazyLoadThumbnails,
      pagesRef,
    ]
  );

  const thumbnailListClassName = getClassName(SlideListRendererClassNames.SlidesSidebar, [
    {
      condition: !visible, // condition = false => Base | condition = true => Base + whatever
      trueClassName: SlideListRendererClassNames.SlidesSidebarInvisible,
    },
  ]);

  if (!render) return null;

  return (
    <div className={thumbnailListClassName}>
      <div className={SlideListRendererClassNames.SlidesList}>
        {slideThumbnails}
        {loadingThumbnails}
      </div>
    </div>
  );
}
