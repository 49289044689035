import {
  ButtonIdModel,
  ButtonListId,
  CheckboxIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  TextboxListId,
} from "@q4/nimbus-ui";
import { CustomRegistrationFieldFormBaseIdModel } from "../base/customRegistrationFieldFormBase.definition";

export enum CustomRegistrationFieldFormOptionListClassName {
  Base = "custom-registration-field-form-optionlist",
  FieldTypeConfiguration = "custom-registration-field-form-optionlist_field-configuration",
  AddOptionButtons = "custom-registration-field-form-optionlist_add-option-buttons",
  AddOptionButton = "custom-registration-field-form-optionlist_add-option",
  RemoveOptionButton = "custom-registration-field-form-optionlist_remove-option",
  OptionList = "custom-registration-field-form-optionlist_option-list",
  OptionEntry = "custom-registration-field-form-optionlist_option-list_entry",
  OptionTextBox = "custom-registration-field-form-optionlist_option-list_entry_text-box",
}

export const MaxOptionCount = 25;

export class CustomRegistrationFieldFormOptionListIdModel extends IdModelBase {
  formBase: CustomRegistrationFieldFormBaseIdModel;
  sortCheckbox: CheckboxIdModel;
  addOption: ButtonIdModel;
  addCustomOption: ButtonIdModel;
  optionTextboxes: TextboxListId;
  optionRemoveButtons: ButtonListId;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;
    this.formBase = new CustomRegistrationFieldFormBaseIdModel(`${this.id}OptionListForm`);
    this.sortCheckbox = new CheckboxIdModel(`${this.id}SortCheckbox`);
    this.addOption = new ButtonIdModel(`${this.id}AddOptionButton`);
    this.addCustomOption = new ButtonIdModel(`${this.id}AddCustomOptionButton`);
    this.optionTextboxes = new TextboxListId(`${this.id}OptionTextInputs`);
    this.optionRemoveButtons = new ButtonListId(`${this.id}OptionRemoveButtons`);
  }
}
