import { gql, useQuery, useSubscription } from "@apollo/client";
import retry from "async-retry";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useGetFeatureFlag } from "../../services/attendeeApi/featureFlag";
import { isAttendeeView } from "../../utils/attendee.utils";

/** Feature Flag Query */
export const FEATURE_FLAG_QUERY = gql`
  query FEATURE_FLAG_QUERY {
    getFeatureFlags
  }
`;
export const useFeatureFlagQuery = (options) => {
  return useQuery(FEATURE_FLAG_QUERY, options);
};

/** Feature Flag Subscription */
export const FEATURE_FLAG_SUBSCRIPTION = gql`
  subscription FEATURE_FLAG_SUBSCRIPTION {
    onFeatureFlagsChange
  }
`;
export const useFeatureFlagSubscription = (options) => {
  return useSubscription(FEATURE_FLAG_SUBSCRIPTION, options);
};

export const useFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<Record<string, any>>({});

  const { refetch: getFlags } = useFeatureFlagQuery({
    skip: true,
    onError: console.error,
  });

  const { refetch: getAttendeesFlags } = useGetFeatureFlag({ skip: true });

  useEffect(function init() {
    if (isAttendeeView()) {
      getAttendeesFlags().then((featureFlagsData) => {
        if (!featureFlagsData?.data) return;
        setFeatureFlags(featureFlagsData?.data);
      });
      return;
    }
    retry(
      async () => {
        const featureFlagsData = await getFlags({});
        setFeatureFlags(featureFlagsData?.data?.getFeatureFlags);
      },
      {
        retries: 3,
        minTimeout: 200,
        maxTimeout: 500,
        onRetry: console.warn,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFeatureFlagsUpdated = useCallback(
    debounce(({ data: subscriptionData }) => {
      const _featureFlags = subscriptionData?.data?.onFeatureFlagsChange;

      if (_featureFlags) setFeatureFlags(_featureFlags);
    }, 500), // milliseconds
    []
  );

  useFeatureFlagSubscription({
    onData: onFeatureFlagsUpdated,
  });

  return featureFlags;
};
