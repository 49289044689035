/**
 * Add the most recently joined attendee to state
 */
export function addAttendeeToAttendeeList(subscriptionData: { data: any }, prev) {
  const newAttendee = subscriptionData.data.onAttendeeJoined;
  const newList = prev.attendees.items ? [...prev.attendees.items, newAttendee] : [newAttendee];
  return { attendees: newList };
}
/**
 * Remove the attendee that has just left from state
 */
export function removeAttendeeFromAttendeeList(subscriptionData: { data: any }, prev) {
  const attendeeToRemove = subscriptionData.data.onAttendeeLeft;
  const attendees = prev?.attendees?.items || [];

  const filteredAttendees = attendees.filter((e) => {
    if (e.id === attendeeToRemove.id) {
      return false;
    }
    return true;
  });
  return { attendees: filteredAttendees };
}
