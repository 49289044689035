import { useContext } from "react";
import config from "../../config";
import { Broadcast } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { useGetRequest } from "./hook/getRequest";
import { Auth0Context } from "../../contexts/auth0/auth0.context";

interface UseGetBroadcastStatusProps {
  meetingId: number;
  skip?: boolean;
  refreshInterval?: number;
}

const apiUrl = config.api.attendeeRest;

export const useGetBroadcastStatus = (props: UseGetBroadcastStatusProps) => {
  const { getUserAccessToken } = useContext(Auth0Context);
  const { meetingId, skip, refreshInterval } = props;
  const path = meetingId ? `${apiUrl}/event/${meetingId}/broadcast-status` : null;

  const { data, error, isLoading, refetch } = useGetRequest({
    skip,
    refreshInterval,
    path,
    getAuthToken: getUserAccessToken,
  });

  return {
    data: data as Broadcast,
    error,
    isLoading,
    refetch,
  };
};
