import React, { useMemo } from "react";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { VideoWrapperClassNames } from "../VideoWrapper/VideoWrapper.definition";

import "./BroadcastStreamBadge.scss";

const IconBaseClassName = "bsb-icon";

const BadgeClassNames = {
  AudioOn: `${IconBaseClassName} ${VideoWrapperClassNames.MicFilled}`,
  AudioOff: `${IconBaseClassName} ${VideoWrapperClassNames.MicOffFilled}`,
  AudioDisconnected: `${IconBaseClassName} ${VideoWrapperClassNames.MicOffFilled} ${VideoWrapperClassNames.MicOffFilled}--disconnected`,
  Phone: `${IconBaseClassName} ${VideoWrapperClassNames.OverlayBadgePSTN}`,
  ScreenShare: `${IconBaseClassName} ${VideoWrapperClassNames.OverlayBadgeScreenShare}`,
  VideoDisconnected: `${IconBaseClassName} q4i-camera-off-filled q4i-camera-off-filled--disconnected`,
  Warning: `${IconBaseClassName} q4i-warning_solid`,
};

export const BroadcastStreamBadge = (props: {
  stream: BroadcastStream;
  participant: Participant;
  audioOnly: boolean;
}) => {
  const { stream, participant, audioOnly } = props;

  const audioUnavailable = useMemo(
    () => !stream?.isPSTNStream && !participant?.mediaSettings?.audioInputsReady,
    [participant?.mediaSettings?.audioInputsReady, stream?.isPSTNStream]
  );
  const videoUnavailable = useMemo(
    () => !participant?.mediaSettings?.videoInputsReady && !audioOnly,
    [audioOnly, participant?.mediaSettings?.videoInputsReady]
  );

  const sourceIcon = useMemo(
    () => (stream?.isPSTNStream ? <i className={BadgeClassNames.Phone} /> : null),
    [stream?.isPSTNStream]
  );

  // Asset Icon
  if (stream?.isAssetStream) return null;

  // Screen Share Icon
  if (stream?.isScreenShare) return <i className={BadgeClassNames.ScreenShare} />;

  // Audio Icon
  const hasAudio = stream?.hasAudioWithMediaState(participant);
  const audioStateIcon = hasAudio ? BadgeClassNames.AudioOn : BadgeClassNames.AudioOff;
  const audioIcon = audioUnavailable ? (
    <i className={BadgeClassNames.AudioDisconnected} />
  ) : (
    <i className={audioStateIcon} />
  );

  // Video Icon
  const videoIcon =
    videoUnavailable && !stream?.isPSTNStream ? (
      <i className={BadgeClassNames.VideoDisconnected} />
    ) : null;

  const mediaWarningIcon = audioUnavailable ? <i className={BadgeClassNames.Warning} /> : null;

  return (
    <>
      {audioIcon} {videoIcon} {mediaWarningIcon} {sourceIcon}
    </>
  );
};
