import { getClassName } from "@q4/nimbus-ui";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { BestFitLayout } from "../../../../components/bestFitLayout/bestFitLayout.component";
import { CustomAvatarSizes } from "../../../../components/customAvatar/customAvatar.definition";
import { EventPermission } from "../../../../configurations/userRolesPermissions.configuration";
import { isStreamLocalPublisher } from "../../../../opentok-react/utils/stream.utils";
import { StageStreamWrapper } from "../../../adminConsole/components/StreamWrapper/stageStreamWrapper.component";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";
import StagedAssetView from "../../../stage/assets/stagedAssetView.component";
import { useRemoveStreamHook } from "../../hooks/removeStream.hook";
import { StageSize } from "../../layoutBuilder.definition";
import {
  MediaLayoutClassNames,
  MediaLayoutProps,
  MediaLayoutSpeakerSize,
} from "./MediaLayout.definition";
import "./MediaLayout.scss";
import EventContext from "../../../../contexts/event/event.context";

export function MediaLayout(props: MediaLayoutProps) {
  const {
    id,
    mediaSelection,
    cameraSelection,
    isPreview,
    participantsByConnectionId,
    renderOnChange, // key tells our useEffect() method to only trigger on mount/unmount and when camera streams change
    stageSize = StageSize.normal,
    stageSelection,
    showSpeakerThumbnails,
  } = props;

  const layoutWrapperId = useRef(id || uuidv4());
  const bestFitWrapperId = useRef(uuidv4());

  const { userEventPermissions } = useContext(EventContext);

  const { removeStreamFromStage } = useRemoveStreamHook({
    updateStage: stageSelection,
    mediaOnly: !showSpeakerThumbnails,
  });

  const _speakerSize = useMemo(
    () => (isPreview ? MediaLayoutSpeakerSize.XS : MediaLayoutSpeakerSize.S),
    [isPreview]
  );

  const videoElementToMediaMap = useRef<
    Record<string, { videoElement: HTMLVideoElement; stream: BroadcastStream }>
  >({});

  const attachVideoStreamsToVideoElements = useCallback(
    async () =>
      Object.values(videoElementToMediaMap.current).forEach(async ({ videoElement, stream }) => {
        if (stream && videoElement && !(videoElement.srcObject as MediaStream)?.active) {
          videoElement.srcObject = await stream.videoMediaStream?.();
          if (videoElement.srcObject) {
            videoElement.srcObject.addEventListener("inactive", attachVideoStreamsToVideoElements);
          }
        }
      }),
    []
  );

  // running this on render causes video to sputter, but running with
  // [] results in layouts not properly updating
  useEffect(() => {
    if (renderOnChange) attachVideoStreamsToVideoElements();
  }, [renderOnChange, showSpeakerThumbnails, attachVideoStreamsToVideoElements]);

  const setVideoRef = useCallback((videoRef: HTMLVideoElement, selection: BroadcastStream) => {
    if (!videoRef || !selection) return;
    videoElementToMediaMap.current[selection.id] = {
      videoElement: videoRef,
      stream: selection,
    };
  }, []);

  const getCameraClassName = useCallback(
    (stream: BroadcastStream) =>
      getClassName(MediaLayoutClassNames.VIDEO_ELEMENT, [
        {
          condition: isStreamLocalPublisher(stream.getVendorStream()),
          trueClassName: "mirrored",
        },
      ]),
    []
  );

  const mediaVideoElement = useMemo(() => {
    return (
      <div className={`${MediaLayoutClassNames.VIDEO_ELEMENT}--screen`}>
        <video
          id={mediaSelection?.name}
          key={mediaSelection?.name}
          data-id={mediaSelection?.name}
          ref={(ref: HTMLVideoElement) => setVideoRef(ref, mediaSelection)}
          crossOrigin={"anonymous"}
          autoPlay={true}
          muted={true}
        />
      </div>
    );
  }, [mediaSelection, setVideoRef]);

  const mediaSelectionComponent = useMemo(() => {
    if (mediaSelection?.isSlideAsset) {
      return (
        <StagedAssetView
          stream={mediaSelection}
          onStreamRemove={removeStreamFromStage}
          controlOptions={{
            includeThumbnails: !isPreview,
            includeFooter: !isPreview,
            includeRemoveStream:
              !isPreview && userEventPermissions?.[EventPermission.eventManageStreams],
          }}
        >
          {mediaVideoElement}
        </StagedAssetView>
      );
    }
    return (
      <StageStreamWrapper
        stream={mediaSelection}
        participant={participantsByConnectionId?.[mediaSelection?.connection?.connectionId]}
        onRemoveStream={removeStreamFromStage}
        previewMode={isPreview}
        userEventPermissions={userEventPermissions}
      >
        {!mediaSelection?.isAudioAsset && mediaVideoElement}
      </StageStreamWrapper>
    );
  }, [
    isPreview,
    mediaSelection,
    mediaVideoElement,
    participantsByConnectionId,
    userEventPermissions,
    removeStreamFromStage,
  ]);

  const videoContent = cameraSelection.map((_selection: BroadcastStream) => (
    <div className={"mediaLayoutSpeakers"} key={_selection?.id}>
      <StageStreamWrapper
        stream={_selection}
        participant={
          participantsByConnectionId &&
          participantsByConnectionId[_selection.connection?.connectionId]
        }
        onRemoveStream={removeStreamFromStage}
        previewMode={isPreview}
        userEventPermissions={userEventPermissions}
        avatarSize={CustomAvatarSizes.Thicc}
      >
        {!_selection.isAudioAsset && (
          <video
            className={getCameraClassName(_selection)}
            ref={(ref: HTMLVideoElement) => setVideoRef(ref, _selection)}
            crossOrigin={"anonymous"}
            autoPlay={true}
            muted={true}
          ></video>
        )}
      </StageStreamWrapper>
    </div>
  ));

  const renderAudioSpeakerComponents = useCallback(
    (selection: BroadcastStream) => (
      <StageStreamWrapper
        stream={selection}
        participant={
          participantsByConnectionId &&
          participantsByConnectionId[selection.connection?.connectionId]
        }
        onRemoveStream={removeStreamFromStage}
        audioOnly={!showSpeakerThumbnails}
        previewMode={isPreview}
        userEventPermissions={userEventPermissions}
      />
    ),
    [
      isPreview,
      participantsByConnectionId,
      removeStreamFromStage,
      showSpeakerThumbnails,
      userEventPermissions,
    ]
  );

  return (
    <div id={layoutWrapperId.current} className={MediaLayoutClassNames.WRAPPER}>
      <div className={MediaLayoutClassNames.WRAPPER_INNER}>
        <div
          className={`${MediaLayoutClassNames.MEDIA_CONTENT} ${MediaLayoutClassNames.MEDIA_CONTENT}--${stageSize}`}
        >
          {mediaSelectionComponent}
        </div>

        {cameraSelection.length > 0 ? (
          <div
            className={`${MediaLayoutClassNames.SPEAKER_CONTENT} ${MediaLayoutClassNames.SPEAKER_CONTENT}--${_speakerSize}`}
            id={bestFitWrapperId.current}
          >
            {!showSpeakerThumbnails ? (
              cameraSelection.map(renderAudioSpeakerComponents)
            ) : (
              <BestFitLayout
                layoutContents={videoContent}
                boundingContainerSettings={{
                  query: `[id="${bestFitWrapperId.current}"]`,
                  maxColumns: 1,
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
