import React from "react";
import { PropsWithChildren } from "react";
import { OTStreamType, OTSessionType } from "../../types/index";

import { BroadcastStream } from "../../../views/adminConsole/interfaces/broadcastStream/broadcastStream";

export enum SessionDisconnectedResons {
  NETWORK_LOST = "network_lost", // permanently disconnected
  RECONNECTING = "reconnecting",
}
export interface StreamEventCallbacks {
  onStreamCreated?: (stream: OTStreamType) => void;
  onStreamDestroyed?: (stream: OTStreamType) => void;
  onConnectionCreated?: (connection: any) => void;
  onConnectionDestroyed?: (connection: any) => void;
}

export interface SessionEventCallbacks {
  onDominantSpeakerChange?: onDominantSpeakerChangeInterface;
  onSessionConnected?: (ev: any) => void;
  onSessionCreated?: (session: OTSessionType) => void;
  onSessionCreateError?: (error: any) => void;
  onSessionDestroyed?: () => void;
  onSessionDisconnected?: (session: OTSessionType, reason: SessionDisconnectedResons) => void;
}

export interface SessionProviderProps extends PropsWithChildren<{}> {
  apiKey: string;
  dominantSpeakerContainer?: React.RefObject<HTMLDivElement>;
  streamEventCallbacks: StreamEventCallbacks;
  sessionId: string;
  streams: Array<BroadcastStream>;
  options?: any;
  token: string;
  sessionEventCallbacks: SessionEventCallbacks;
}

export interface SessionContextModel {
  OTSession: any;
  streams: Array<BroadcastStream>;
  dominantSpeakerStreamId: string;
  onStreamCreated: (stream: OTStreamType) => void;
  onStreamDestroyed: (stream: OTStreamType) => void;
  makeDominantConsoleView: (streamId: string) => void;
}

export type onDominantSpeakerChangeInterface = (dominantSpeakerStream: BroadcastStream) => void;
