import React, { memo, useCallback, useRef, useState } from "react";
import { EventAssetType } from "../../hooks/assets/assets.hook.definition";
import config from "../../config";
import AspectRatioSizer from "../aspectRatioSizer/aspectRatioSizer.component";
import { NotificationService } from "@q4/nimbus-ui";
import useS3Hooks from "../../hooks/s3Hooks/signedUrls.hook";
import { AudioVideoPlayerProps } from "./AudioVideoPlayer.definition";
import { useAsyncEffect } from "../../hooks/async/asyncEffect.hook";

function AudioVideoPlayer(props: AudioVideoPlayerProps): JSX.Element {
  const { fileName, onPause, onTimeUpdate, meetingId, mediaRef, assetType } = props;
  const [url, setUrl] = useState("");
  const notificationService = useRef(new NotificationService());
  const useHookItems = useS3Hooks({ meetingId, fileName });
  const { useSignedAssetQuery } = useHookItems;

  const { refetch: getSignedAssetUrl } = useSignedAssetQuery({
    skip: true,
    onError: (error) => {
      console.error(error);
      notificationService.current.error(`Error getting asset url`);
    },
  });

  const getAssetUrl = useCallback(async () => {
    if (!fileName || !meetingId) return;

    const result = await getSignedAssetUrl({ meetingId, fileName });
    return result?.data?.getEventSignedAssetUrl;
  }, [meetingId, fileName, getSignedAssetUrl]);

  useAsyncEffect(async () => {
    if (url) return;

    const urlResult = await getAssetUrl();
    setUrl(urlResult);
  }, [url, getAssetUrl, setUrl]);

  return url ? (
    <AspectRatioSizer>
      {assetType?.toUpperCase() === EventAssetType.VIDEO.toUpperCase() ? (
        <video src={url} ref={mediaRef} onEnded={onPause} onTimeUpdate={onTimeUpdate} />
      ) : (
        <>
          <img src={config.adminConsole.streamThumbnails.audio} alt={`${fileName}-preview`} />
          <audio src={url} ref={mediaRef} onTimeUpdate={onTimeUpdate} />
        </>
      )}
    </AspectRatioSizer>
  ) : null;
}

export default memo(AudioVideoPlayer);
