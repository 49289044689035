import {
  gql,
  QueryHookOptions,
  SubscriptionHookOptions,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { BroadcastStagedLayout } from "../../../../hooks/event/event.hook.definition";
import { Dispatch } from "react";
import { UpdateLayoutParams } from "../../vendors/openTok/services/openTokSignaling.service";

export const defaultMediaOnlyState: boolean = false;

export interface StagedSelectionProps {
  meetingId: number;
  skip?: boolean;
  isOTPR?: boolean;
  onStreamsStaged?: () => void;
}

export interface UpdateLayoutOnStreamDropProps {
  stream: BroadcastStream;
}

export interface UpdateEventStagedBroadcastLayoutSubVars {
  meetingId: number;
}

export interface UpdateEventStagedBroadcastLayoutSubData {
  onEventStagedBroadcastLayoutUpdated: BroadcastStagedLayout;
}

export interface GetCurrentlyStagedLayoutData {
  getCurrentlyStagedLayout: BroadcastStagedLayout;
}
export interface GetCurrentlyStagedLayoutVars {
  meetingId: number;
}

interface StagedSelectionState {
  stagedSelection: any;
  mediaOnlyState: boolean;
  initialStagedLayout: UpdateLayoutParams;
}

interface StagedSelectionService {
  getInitialLayout: (meetingId: number) => Promise<void>;
  getCurrentlyStagedLayout: (meetingId: number) => Promise<BroadcastStagedLayout>;
  setInitialStagedLayout: Dispatch<UpdateLayoutParams>;
  setStagedSelection: any;
}

export interface StagedSelectionHook {
  stagedSelectionState: StagedSelectionState;
  stagedSelectionService: StagedSelectionService;
}

export const GET_CURRENTLY_STAGED_LAYOUT = gql`
  query getCurrentlyStagedLayout($meetingId: Int) {
    getCurrentlyStagedLayout(meetingId: $meetingId) {
      mediaSelection
      cameraSelection
      mediaOnly
      selectionV2 {
        id
        type
        assetId
      }
      layoutConfig {
        type
      }
    }
  }
`;

const UPDATE_EVENT_STAGED_BROADCAST_LAYOUT_SUBSCRIPTION = gql`
  subscription ($meetingId: Int) {
    onEventStagedBroadcastLayoutUpdated(meetingId: $meetingId) {
      mediaSelection
      cameraSelection
      inactiveStreams
      mediaOnly
      selectionV2 {
        id
        type
        assetId
      }
      layoutConfig {
        type
      }
    }
  }
`;

export function useUpdateEventStagedBroadcastLayoutSubscription<
  D = UpdateEventStagedBroadcastLayoutSubData,
  V = UpdateEventStagedBroadcastLayoutSubVars
>(options?: SubscriptionHookOptions<D, V>) {
  return useSubscription<D, V>(UPDATE_EVENT_STAGED_BROADCAST_LAYOUT_SUBSCRIPTION, options);
}

export function useGetCurrentlyStagedLayout(
  options: QueryHookOptions<GetCurrentlyStagedLayoutData, GetCurrentlyStagedLayoutVars>
) {
  return useQuery(GET_CURRENTLY_STAGED_LAYOUT, {
    fetchPolicy: "network-only",
    ...options,
  });
}
