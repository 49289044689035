import cssVariables from "../../styles/_variables.scss";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";
export interface AudioVideoControlsProps {
  fileName: string;
  state: AudioVideoState;
  volume: number;
  volumeMin?: number;
  volumeMax?: number;
  onPlay: () => void;
  onPause: () => void;
  onVolume?: (volume: number) => void;
  onSeek?: () => void;
  volumeSliderStyles?: ReactSliderProps;
  isAutoplayAsset?: boolean;
  asset?: any;
  selected?: any;
  displayActionButtons?: boolean;
}

export enum AudioVideoControlsClassNames {
  base = "audio-video-controls",
  buttonGroup = "audio-video-controls_button-group",
  slider = "audio-video-controls_slider",
  sliderWrapper = "audio-video-controls_slider-wrapper",
  playIcon = "q4i-play-filled",
  pauseIcon = "q4i-pause-filled",
  scrubIcon = "q4i-previous-filled",
  speakerOnIcon = "q4i-speaker-on-filled",
  speakerOfIcon = "q4i-speaker-off-filled",
  muteButton = "audio-video-controls_mute-button",
  fileName = "audio-video-controls_file-name",
}

export enum AudioVideoState {
  PAUSE = "PAUSE",
  PLAY = "PLAY",
}

export const defaultVolume = {
  MIN: 0,
  MAX: 100,
};

export const defaultVolumeSlider = {
  trackStyle: { backgroundColor: cssVariables.slate },
  railStyle: { backgroundColor: cssVariables.grey },
  handleStyle: {
    backgroundColor: cssVariables.slate,
    borderColor: cssVariables.slate,
    height: 8,
    width: 8,
    marginTop: -2,
  },
};
interface ReactSliderProps {
  trackStyle?: object;
  railStyle?: object;
  handleStyle?: object;
}

export interface DisplayAssetDurationProps {
  stream: BroadcastStream;
}

export enum DisplayAssetDurationClassNames {
  base = "audio-video-duration",
  wrapper = "audio-video-duration_wrapper",
}
