import React from "react";
import loadable from "@loadable/component";

import { UserPermissions, UserRoles } from "../userRolesPermissions.configuration";
import { LoadingScreen } from "../../components/loadingScreen/loadingScreen";
import { Domain, EventRouteContext } from "./route.domains";
import { PrivateRoutesModel, PublicRoutesModel } from "./routes.definition";
import { isLocalhost } from "../../serviceWorker";

const fallback = {
  fallback: <LoadingScreen />,
};
const AdminConsoleEntry = loadable(
  () => import("../../views/adminConsole/adminConsoleEntry.view"),
  fallback
);
const AttendeeEventUrlRouteResolver = loadable(
  () => import("./resolvers/attendeeEventUrlResolver"),
  fallback
);
const AttendeeEventPostLoginCallback = loadable(
  () => import("../../views/eventPostLoginCallback/eventPostLoginCallback.view"),
  fallback
);
const AttendeeEventLogout = loadable(
  () => import("../../views/eventLogout/eventLogout.view"),
  fallback
);
const Companies = loadable(() => import("../../views/companies/companies.view"), fallback);
const CompanyDetails = loadable(() => import("../../views/companyDetails/company.view"), fallback);
const CustomEventUrlRouteResolver = loadable(
  () => import("./resolvers/customEventUrlRouteResolver"),
  fallback
);
const EventsDashboard = loadable(
  () => import("../../views/eventsDashboard/eventsDashboard.view"),
  fallback
);
const Fallback = loadable(() => import("../../components/fallback/fallback.component"), fallback);
const GuestLogin = loadable(() => import("../../views/login/guestLogin/guestLogin.view"), fallback);
const Home = loadable(() => import("../../views/home/home.view"), fallback);
const Login = loadable(() => import("../../views/login/userLogin/login.view"), fallback);
const LoginReset = loadable(
  () => import("../../views/login/userLoginReset/loginReset.view"),
  fallback
);
const MicrositeFullPreview = loadable(
  () =>
    import("../../views/eventMicrosite/components/micrositeFullPreview/micrositeFullPreview.view"),
  fallback
);
const MicrositePagesPreview = loadable(
  () =>
    import(
      "../../views/eventMicrosite/components/micrositePagesPreview/micrositePagesPreview.view"
    ),
  fallback
);
const NoPermissionsForm = loadable(
  () => import("../../views/login/guestLogin/noPermissions/noPermissions.view"),
  fallback
);
const PostEventReport = loadable(
  () => import("../../views/postEventReport/postEventReportEntry"),
  fallback
);
const PreCall = loadable(() => import("../../views/preCall/preCall.view"), fallback);
const RemoteBroadcastEntry = loadable(
  () => import("../../views/remoteBroadcastLayout/remoteBroadcastEntry.view"),
  fallback
);
const BroadcastPreviewEntry = loadable(
  () => import("../../views/broadcastPreview/broadcastPreviewEntry.view"),
  fallback
);
const UserRegistration = loadable(
  () => import("../../views/login/userLoginRegistration/userRegistration.view"),
  fallback
);
const Users = loadable(() => import("../../views/users/users.view"), fallback);

const PrivateSlidesPreview = loadable(async () => {
  const { PrivateSlidesPreview } = await import(
    "../../views/slidesPreview/privateSlidesPreview.view"
  );

  return (props) => <PrivateSlidesPreview {...props} />;
});
const PublicSlidesPreview = loadable(async () => {
  const { PublicSlidesPreview } = await import(
    "../../views/slidesPreview/publicSlidesPreview.view"
  );

  return (props) => <PublicSlidesPreview {...props} />;
});

export const EPFallBackRoute = "/not-found";
export const EventPathPrefix = "/event";
export const PreCallPathPrefix = "/pre-call";
export const AttendeePathPrefix = "/attendee";
export const AdminConsolePathPrefix = "/admin";
export const GuestAuthPathSuffix = "/guest";
export const NoPermissionsFormPathSuffix = "/unauthorized";
export const ReportPathPrefix = `${EventPathPrefix}/report`;
export const BroadcastLayoutPathPrefix = "/broadcastlayout";
export const BroadcastPreviewPathPrefix = "/broadcastPreview";
export const SlidesPreviewPathSuffix = "/slides-preview";
export const EventSlidesPreviewPathPrefix = `${EventPathPrefix}${SlidesPreviewPathSuffix}`;
export const AdminSlidesPreviewPathPrefix = `${AdminConsolePathPrefix}${SlidesPreviewPathSuffix}`;

export const PrivateRoutes: PrivateRoutesModel = {
  "/": {
    component: Home,
    appDomain: { domain: Domain.Platform },
    showNavigation: true,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.ALL],
  },
  "/events": {
    component: EventsDashboard,
    appDomain: { domain: Domain.Platform },
    showNavigation: true,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.ALL],
  },
  "/companies": {
    component: Companies,
    appDomain: { domain: Domain.Platform },
    showNavigation: true,
    permittedRoles: [UserRoles.Q4_ADMIN, UserRoles.EP_EVENT_MANAGER],
    permittedPermissions: [
      UserPermissions.VIEW_ALL_COMPANIES,
      UserPermissions.VIEW_TENANT_COMPANIES,
    ],
  },
  "/users": {
    component: Users,
    appDomain: { domain: Domain.Platform },
    showNavigation: true,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.ALL],
  },
  "/companies/:id": {
    component: CompanyDetails,
    appDomain: { domain: Domain.Platform },
    showNavigation: true,
    showHistoryBack: true,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [
      UserPermissions.VIEW_ALL_COMPANIES,
      UserPermissions.VIEW_TENANT_COMPANIES,
    ],
  },
  [`${EventSlidesPreviewPathPrefix}`]: {
    component: PrivateSlidesPreview,
    appDomain: { domain: Domain.Event, context: EventRouteContext.PreEvent },
    showNavigation: false,
    showHistoryBack: false,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.VIEW_ALL_EVENTS],
  },
  [`${AdminSlidesPreviewPathPrefix}`]: {
    component: PrivateSlidesPreview,
    appDomain: { domain: Domain.Event, context: EventRouteContext.PreEvent },
    showNavigation: false,
    showHistoryBack: false,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.VIEW_ALL_EVENTS],
  },
};

if (isLocalhost) {
  const Sandbox = loadable(() => import("../../views/sandbox/sandbox.view"), fallback);
  PrivateRoutes["/sandbox"] = {
    component: Sandbox,
    appDomain: { domain: Domain.Platform },
    showNavigation: false,
    permittedRoles: [UserRoles.ALL],
    permittedPermissions: [UserPermissions.ALL],
  };
}

export enum AttendeeRoutes {
  AttendeeEventCustomUrlGuest = "/:eventType(earnings|vsm)/:companyIdentifier/:customIdentifier/guest/:hasPreRegistered?",
  AttendeeEventCustomUrl = "/:eventType(earnings|vsm)/:companyIdentifier/:customIdentifier/:hasPreRegistered?",
  AttendeeEvent = "/attendee/:id/:hasPreRegistered?",
  AttendeeEventGuest = "/attendee/:id/guest",
  AttendeeEventPostLogin = "/attendee/post-login-callback",
  AttendeeEventSignOut = "/attendee/logout",
}

export const PublicRoutes: PublicRoutesModel = {
  [`${AdminConsolePathPrefix}${BroadcastLayoutPathPrefix}/:meetingId`]: {
    component: RemoteBroadcastEntry,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
      versionWhitelist: true,
    },
  },
  [`${AdminConsolePathPrefix}${BroadcastLayoutPathPrefix}/:meetingId${NoPermissionsFormPathSuffix}`]:
    {
      component: Fallback,
      appDomain: {
        domain: Domain.Event,
        context: EventRouteContext.LiveEvent,
        versionWhitelist: true,
      },
    },
  [`${AdminConsolePathPrefix}${BroadcastPreviewPathPrefix}/:meetingId`]: {
    component: BroadcastPreviewEntry,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
      versionWhitelist: true,
    },
  },
  [`${AdminConsolePathPrefix}/:id${GuestAuthPathSuffix}`]: {
    component: GuestLogin,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
    },
  },
  [`${AdminConsolePathPrefix}/:id${NoPermissionsFormPathSuffix}`]: {
    component: NoPermissionsForm,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
    },
  },
  [`${AdminConsolePathPrefix}/:id/:filter?`]: {
    component: AdminConsoleEntry,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
    },
  },
  [AttendeeRoutes.AttendeeEventGuest]: {
    component: AttendeeEventUrlRouteResolver,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  [AttendeeRoutes.AttendeeEventPostLogin]: {
    component: AttendeeEventPostLoginCallback,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  [AttendeeRoutes.AttendeeEventSignOut]: {
    component: AttendeeEventLogout,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  [AttendeeRoutes.AttendeeEvent]: {
    component: AttendeeEventUrlRouteResolver,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  "/microsite/:meetingId": {
    component: MicrositeFullPreview,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.Microsite,
    },
  },
  "/microsite/:meetingId/:micrositePage/:filter?": {
    component: MicrositePagesPreview,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.Microsite,
    },
  },
  "/login/:state?": {
    component: Login,
    appDomain: {
      domain: Domain.Platform,
      versionWhitelist: true,
    },
  },
  "/login/reset/:resetId": {
    component: LoginReset,
    appDomain: {
      domain: Domain.Platform,
      versionWhitelist: true,
    },
  },
  "/registration/user/:registrationId": {
    component: UserRegistration,
    appDomain: {
      domain: Domain.Platform,
      versionWhitelist: true,
    },
  },
  [PreCallPathPrefix]: {
    component: PreCall,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.LiveEvent,
    },
  },
  [EPFallBackRoute]: {
    component: Fallback,
    appDomain: {
      domain: Domain.Platform,
      versionWhitelist: true,
    },
  },
  [`${ReportPathPrefix}/:meetingId`]: {
    component: PostEventReport,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.PostEvent,
    },
  },
  [AttendeeRoutes.AttendeeEventCustomUrlGuest]: {
    component: CustomEventUrlRouteResolver,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  [AttendeeRoutes.AttendeeEventCustomUrl]: {
    component: CustomEventUrlRouteResolver,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.AttendeeEvent,
    },
  },
  "/slides-preview": {
    component: PublicSlidesPreview,
    appDomain: {
      domain: Domain.Event,
      context: EventRouteContext.PreEvent,
    },
  },
};
