import {
  AssetType,
  AttendeeMeetingMaterials,
} from "./../hooks/assetUploads/assetUploads.definition";
import { EventMeetingMaterial } from "../hooks/eventMaterials/eventMaterials.hook.definition";
import config from "../config";

import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "save-as";

import { UploadedDataFormat } from "../services/event/event.model";

const COMPANY_ID_TEMPLATE_PLACEHOLDER = "<companyId>";
const EVENT_ID_TEMPLATE_PLACEHOLDER = "<eventId>";

function generateRelativeLogosPath(companyId: string) {
  return config.branding.s3.logosPath.replace(COMPANY_ID_TEMPLATE_PLACEHOLDER, companyId);
}
function generateRelativeBackgroundImgsPath(companyId: string) {
  return config.branding.s3.backgroundPath.replace(COMPANY_ID_TEMPLATE_PLACEHOLDER, companyId);
}
function generateRelativeFontsPath(companyId: string) {
  return config.branding.s3.fontPath.replace(COMPANY_ID_TEMPLATE_PLACEHOLDER, companyId);
}
function generateRelativeMaterialsPath(eventId: string) {
  return config.branding.s3.materialsPath.replace(EVENT_ID_TEMPLATE_PLACEHOLDER, eventId);
}
function generateRelativeFaviconsPath(companyId: string) {
  return config.branding.s3.faviconsPath.replace(COMPANY_ID_TEMPLATE_PLACEHOLDER, companyId);
}

export function generateFontUrl(companyId: string, font: UploadedDataFormat) {
  try {
    const fileName = font?.name;
    const relativeFontsPath = generateRelativeFontsPath(companyId);
    return `${config.branding.s3.domain}/${relativeFontsPath}/${fileName}`;
  } catch (e) {
    return "";
  }
}

export function generateBackgroundUrl(companyId: string, backgroundImgs: UploadedDataFormat) {
  try {
    const fileName = backgroundImgs?.name;
    if (!fileName || !companyId) return "";
    const relativeLogosPath = generateRelativeBackgroundImgsPath(companyId);
    return `${config.branding.s3.domain}/${relativeLogosPath}/${fileName}`;
  } catch (e) {
    return "";
  }
}

export function generateMaterialsUrl(eventId: string, material: EventMeetingMaterial) {
  try {
    const fileName = material?.name;
    const relativeMaterialsPath = generateRelativeMaterialsPath(eventId);
    return `${config.branding.s3.domain}/${relativeMaterialsPath}/${fileName}`;
  } catch (e) {
    console.error(e);
  }
}

export function generateLogoUrl(companyId: string, logo: UploadedDataFormat) {
  try {
    const fileName = logo?.name;
    const relativeLogosPath = generateRelativeLogosPath(companyId);
    if (!fileName || !relativeLogosPath) return "";
    return `${config.branding.s3.domain}/${relativeLogosPath}/${fileName}`;
  } catch (e) {
    return "";
  }
}

export function generateFaviconUrl(companyId: string, favicon: UploadedDataFormat) {
  try {
    const faviconName = favicon?.name;
    const relativeFaviconsPath = generateRelativeFaviconsPath(companyId);
    if (!faviconName || !relativeFaviconsPath) return "";
    return `${config.branding.s3.domain}/${relativeFaviconsPath}/${faviconName}`;
  } catch (e) {
    return "";
  }
}

export const checkIfFileExists = (url: string, getRequest?: boolean): Promise<any> => {
  if (url) {
    return fetch(url, {
      method: getRequest ? "GET" : "HEAD",
      mode: "cors",
      cache: "no-cache",
    })
      .then((result) => {
        if (result.status !== 200) {
          throw new Error("File not found");
        }
        return result;
      })
      .catch((err) => {
        console.error("Failed to fetch file", err);
        throw new Error("Failed to fetch file");
      });
  }
  return Promise.reject("No url was provided");
};
export const openAssetLink = (url: string) => {
  // This implementation is to ensure that all assets open in a new tab (before auto-closing if necessary)
  const tempLink = document.createElement("a");
  tempLink.setAttribute("href", url);
  tempLink.setAttribute("target", "_blank");
  tempLink.setAttribute("rel", "noreferrer noopener");
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export enum ImageOrientation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
  EVEN = "even",
}

export const getImageOrientation = (src) => {
  var orientation,
    img = new Image();
  img.src = src;

  if (img.naturalWidth > img.naturalHeight) {
    orientation = ImageOrientation.LANDSCAPE;
  } else if (img.naturalWidth < img.naturalHeight) {
    orientation = ImageOrientation.PORTRAIT;
  } else {
    orientation = ImageOrientation.EVEN;
  }

  return orientation;
};

export const getFileDuration = async (assetType: AssetType, file: File) => {
  if (assetType !== AssetType.AUDIO && assetType !== AssetType.VIDEO) return;

  const assetElement = document.createElement(assetType.toLowerCase()) as
    | HTMLVideoElement
    | HTMLAudioElement;

  // wait for element to load metadata before creating asset
  assetElement.preload = "metadata";
  const assetDuration: Promise<number> = new Promise((resolve) => {
    assetElement.onloadedmetadata = () => {
      resolve(assetElement.duration);
    };
  });

  assetElement.src = URL.createObjectURL(file);
  const duration = await assetDuration;

  return duration;
};

export const getMaterialsLink = (materialUrl, materialAssetType): string => {
  if (materialAssetType === AssetType.WEBLINK) {
    if (materialUrl?.startsWith("http")) return materialUrl;
    else return `https://${materialUrl}`;
  } else return materialUrl;
};

export const openMaterialsUrl = (material: AttendeeMeetingMaterials) => {
  const { url, assetType } = material;

  const linkToOpen = assetType === AssetType.WEBLINK ? getMaterialsLink(url, assetType) : url;
  openAssetLink(linkToOpen);
};

export const zipMaterials = async (meetingId: number, materials: AttendeeMeetingMaterials[]) => {
  const zip = new JSZip();
  const zipFilename = `${meetingId}_materials.zip`;
  const zipFilePromises = [];

  for (const material of materials) {
    const getBinaryContentPromise = new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(material.url, (err, data) => {
        if (err) {
          reject({
            error: err,
            message: `${material.displayName} could not be downloaded. Please refresh and try again.`,
          });
          return;
        }

        zip.file(getFileNameWithExt(material.displayName, material.assetExt), data, {
          binary: true,
        });
        resolve(data);
      });
    });
    zipFilePromises.push(getBinaryContentPromise);
  }

  const results = await Promise.allSettled(zipFilePromises);
  const errors = results.filter(
    (result) => result.status !== "fulfilled"
  ) as PromiseRejectedResult[];

  if (errors.length < materials.length) {
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, zipFilename);
  }

  return { errors };
};

const fileExtensionToIconMap = new Map<string, string>([
  ["doc", "q4i-releases_2pt"],
  ["docx", "q4i-releases_2pt"],
  ["pdf", "q4i-releases_2pt"],
  ["ppt", "q4i-presentation-2pt"],
  ["pptx", "q4i-presentation-2pt"],
  ["xls", "q4i-xls_2pt"],
  ["xlsx", "q4i-xls_2pt"],
  ["mp3", "q4i-volume-max_2pt"],
  ["wav", "q4i-volume-max_2pt"],
  ["mp4", "q4i-video_2pt"],
  ["avi", "q4i-video_2pt"],
]);

const fileTypeToIconMap = new Map<string, string>([
  [AssetType.DOCUMENT, "q4i-releases_2pt"],
  [AssetType.SLIDESHOW, "q4i-presentation-2pt"],
  [AssetType.SPREADSHEET, "q4i-xls_2pt"],
  [AssetType.WEBLINK, "q4i-shareholder-id_2pt"],
  [AssetType.AUDIO, "q4i-volume-max_2pt"],
  [AssetType.VIDEO, "q4i-video_2pt"],
]);

const getFileNameWithExt = (displayName, assetExt = null) => {
  const displayNameSeparated = displayName.split(".");

  const existingNameValid =
    displayNameSeparated.length > 1 &&
    displayNameSeparated[displayNameSeparated.length - 1] === assetExt;

  if (!assetExt || existingNameValid) return displayName;

  return `${displayName}.${assetExt}`;
};

export const getMaterialsIcon = (assetType, assetExt = null) => {
  if (assetType === AssetType.SLIDESHOW) return fileTypeToIconMap.get(assetType);
  return fileExtensionToIconMap.get(assetExt) ?? fileTypeToIconMap.get(assetType);
};

interface CheckMaterialExistsParams {
  event?: any;
  material: AttendeeMeetingMaterials;
  openOnValid?: boolean;
  onSuccess?: () => void;
  onError: (message?: string) => void;
  getRequest?: boolean;
}

export const checkMaterialExists = async (params: CheckMaterialExistsParams) => {
  const {
    event = null,
    material,
    openOnValid = true,
    onError,
    onSuccess,
    getRequest = false,
  } = params;
  event?.preventDefault();
  const materialUrl = getMaterialsLink(material?.url, material?.assetType);

  try {
    if (material.assetType !== AssetType.WEBLINK) {
      await checkIfFileExists(materialUrl, getRequest);
    }
    openOnValid && openAssetLink(materialUrl);
    onSuccess?.();
  } catch (err) {
    onError(
      `The document (${material.displayName}) is no longer available. Please refresh the list.`
    );
  }
};
