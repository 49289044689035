import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace, TextIdModel } from "@q4/nimbus-ui";

export enum MicrositeCustomRegistrationEmptyStateClassNames {
  Base = "microsite-custom-registration_empty-state",
  Wrapper = "microsite-custom-registration_empty-state_wrapper",
  Title = "microsite-custom-registration_empty-state_title",
  Image = "microsite-custom-registration_empty-state_image",
  Message = "microsite-custom-registration_empty-state_message",
  FieldNumber = "microsite-custom-registration_empty-state_field-number",
}

export interface MicrositeCustomRegistrationEmptyStateProps extends BaseComponentProps {}

export class MicrositeCustomRegistrationEmptyStateIdModel extends IdModelBase {
  image: string;
  title: TextIdModel;
  message: TextIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.image = `${this.id}Image`;
    this.title = new TextIdModel(`${this.id}Title`);
    this.message = new TextIdModel(`${this.id}Message`);
  }
}
