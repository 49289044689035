import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
} from "@apollo/client";

export const RECEIVE_USER_MESSAGE = gql`
  subscription RECEIVE_USER_MESSAGE($userId: String, $topic: String) {
    receiveUserMessage(userId: $userId, topic: $topic) {
      data
    }
  }
`;

export const SEND_USER_MESSAGE = gql`
  mutation SEND_USER_MESSAGE($userId: String, $topic: String, $message: Json) {
    sendUserMessage(userId: $userId, topic: $topic, message: $message)
  }
`;

export enum UserMessageTopics {
  LOG_DEBUG_INFO = "user-message:log-debug-info",
}

export function useSendUserMessage(options?: MutationHookOptions<any, any>) {
  return useMutation(SEND_USER_MESSAGE, options);
}

export function useReceiveUserMessage(options: SubscriptionHookOptions<any, any>) {
  return useSubscription(RECEIVE_USER_MESSAGE, options);
}
