import { gql } from "@apollo/client";
import { Company as CompanyModel } from "../../services/company/company.model";
import { UploadedDataFormat } from "./../../services/event/event.model";
import { User } from "./../../services/user/user.model";
import { COMPANY_FRAGMENTS } from "./company.gql";

export const UPDATE_COMPANY_BACKGROUND_MUTATION = gql`
  mutation UPDATE_COMPANY_BACKGROUND($file: Upload!, $companyId: String) {
    updateCompanyBackground(file: $file, companyId: $companyId) {
      id
      name
      branding {
        backgroundImgs {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_LOGO_MUTATION = gql`
  mutation UPDATE_COMPANY_LOGO($file: Upload!, $companyId: String) {
    updateCompanyLogo(file: $file, companyId: $companyId) {
      id
      name
      branding {
        logos {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_FAVICON_MUTATION = gql`
  mutation UPDATE_COMPANY_FAVICON($file: Upload!, $companyId: String) {
    updateCompanyFavicon(file: $file, companyId: $companyId) {
      id
      name
      branding {
        favicons {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_FONT_MUTATION = gql`
  mutation UPDATE_COMPANY_FONT($file: Upload!, $companyId: String) {
    updateCompanyFont(file: $file, companyId: $companyId) {
      id
      name
      branding {
        fonts {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export const REMOVE_COMPANY_BACKGROUND_MUTATION = gql`
  mutation REMOVE_COMPANY_BACKGROUND($companyId: String, $name: String) {
    removeCompanyBackground(companyId: $companyId, name: $name) {
      name
      branding {
        backgroundImgs {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export const REMOVE_COMPANY_LOGO_MUTATION = gql`
  mutation REMOVE_COMPANY_LOGO($companyId: String) {
    removeCompanyLogo(companyId: $companyId) {
      name
    }
  }
`;

export const REMOVE_COMPANY_FAVICON_MUTATION = gql`
  mutation REMOVE_COMPANY_FAVICON($companyId: String) {
    removeCompanyFavicon(companyId: $companyId) {
      name
    }
  }
`;

export const REMOVE_COMPANY_FONT_MUTATION = gql`
  mutation REMOVE_COMPANY_FONT($companyId: String, $name: String) {
    removeCompanyFont(companyId: $companyId, name: $name) {
      name
      branding {
        fonts {
          name
          displayName
          fileType
        }
      }
    }
  }
`;

export enum CompanyBrandingResourceType {
  Font = "fonts",
  BackgroundImage = "background_imgs",
  Logo = "logos",
}

export const IS_COMPANY_RESOURCE_USED_QUERY = gql`
  query IS_COMPANY_RESOURCE_USED_QUERY(
    $companyId: String
    $resourceType: CompanyBrandingResourceType
    $resourceName: String
  ) {
    isCompanyResourceUsed(
      companyId: $companyId
      resourceType: $resourceType
      resourceName: $resourceName
    )
  }
`;

export interface GetCompanyQueryData {
  getCompany: CompanyDTO;
}

export interface GetCompanyQueryVars {
  companyId: string;
  meetingId?: number;
}

export const COMPANY_QUERY = gql`
  query COMPANY_QUERY($companyId: String, $meetingId: Int) {
    getCompany(companyId: $companyId, meetingId: $meetingId) {
      id
      name
      status
      customIdentifier
      organization {
        id
        name
      }
      branding {
        logos {
          name
          displayName
          fileType
        }
        backgroundImgs {
          name
          displayName
          fileType
        }
        fonts {
          name
          displayName
          fileType
        }
        favicons {
          name
          displayName
          fileType
        }
      }
      version
    }
  }
`;

export interface SearchParamsQueryVars {
  searchParams: {
    status?: CompanyStatus;
    searchTerm?: string;
    sortField: string;
    sortOrder?: number;
    page?: number;
    limit?: number;
  };
}

export enum CompanyStatus {
  Active = "active",
  Archived = "archived",
}

export enum CompanyGetType {
  All = "",
  Archived = "archived",
  Active = "active",
}

export enum CompanyGetField {
  SortByCompanyName = "name",
}

export interface CompanyDTO {
  id?: string;
  name?: string;
  status?: CompanyGetType;
  branding?: {
    logos: UploadedDataFormat[];
    backgroundImgs: UploadedDataFormat[];
    fonts: UploadedDataFormat[];
    favicons: UploadedDataFormat[];
  };
  createdAt?: Date;
  updatedAt?: Date;
  customIdentifier?: string;
  eventManagers?: (string | User)[];
  version?: string;
  organization?: {
    name: string;
    id: string;
  };
}

export interface FetchedCompaniesQueryData {
  companies: Array<CompanyDTO>;
  totalCompanies: number;
}

export interface GetCompaniesQueryData {
  getCompanies: FetchedCompaniesQueryData;
}

export interface CompaniesSelectProps {
  getCompanies: (searchParams: any) => Promise<FetchedCompaniesQueryData>;
}

export const GET_COMPANIES_QUERY = gql`
  query COMPANY_QUERY(
    $status: String
    $searchTerm: String
    $sortField: String
    $sortOrder: Int
    $page: Int
    $limit: Int
    $showManagedCompanies: Boolean
    $includeBundleVersion: Boolean
    $orgIds: [String]
  ) {
    getCompanies(
      status: $status
      searchTerm: $searchTerm
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      limit: $limit
      showManagedCompanies: $showManagedCompanies
      includeBundleVersion: $includeBundleVersion
      orgIds: $orgIds
    ) {
      companies {
        id
        name
        status
        customIdentifier
        organization {
          id
          name
        }
        branding {
          logos {
            name
            displayName
            fileType
          }
          backgroundImgs {
            name
            displayName
            fileType
          }
          fonts {
            name
            displayName
            fileType
          }
        }
        eventManagers {
          firstName
          lastName
          email
        }
        version
        stockTicker
      }
      totalCompanies
    }
  }
`;

export interface Bundle {
  version: string;
  current: boolean;
  companies?: string[];
}
export interface GetVersionsQueryData {
  getVersions: {
    bundles: Bundle[];
  };
}
export interface GetVersionsQueryVars {
  current?: CompanyStatus;
  companyId?: string;
}

export const GET_VERSIONS = gql`
  query getVersions($current: Boolean, $companyId: String) {
    getVersions(current: $current, companyId: $companyId) {
      bundles {
        version
        current
      }
    }
  }
`;

export interface GetCompanyPasswordData {
  getCompanyPassword: string;
}

export interface GetCompanyPasswordVariables {
  companyId: string;
}

export const GET_COMPANY_PASSWORD_QUERY = gql`
  query GET_COMPANY_PASSWORD_QUERY($companyId: String) {
    getCompanyPassword(companyId: $companyId)
  }
`;

export interface CreateCompanyPasswordData {
  createCompanyPassword: string;
}

export interface CreateCompanyPasswordVariables {
  companyId: string;
}

export const CREATE_COMPANY_PASSWORD_MUTATION = gql`
  mutation CREATE_COMPANY_PASSWORD_MUTATION($companyId: String) {
    createCompanyPassword(companyId: $companyId)
  }
`;

export interface GetOrCreateCompanyPasswordData {
  getOrCreateCompanyPassword: string;
}

export interface GetOrCreateCompanyPasswordVariables {
  companyId: string;
}

export const GET_OR_CREATE_COMPANY_PASSWORD_MUTATION = gql`
  mutation GET_OR_CREATE_COMPANY_PASSWORD_MUTATION($companyId: String) {
    getOrCreateCompanyPassword(companyId: $companyId)
  }
`;

export interface VerifyCompanyPasswordData {
  verifyCompanyPassword: string;
}

export interface VerifyCompanyPasswordVariables {
  companyId: string;
  password: string;
}

export const VERIFY_COMPANY_PASSWORD_MUTATION = gql`
  mutation VERIFY_COMPANY_PASSWORD_MUTATION($companyId: String, $password: String) {
    verifyCompanyPassword(companyId: $companyId, password: $password)
  }
`;

export interface CreateCompanyData {
  createCompany: CompanyModel;
}

export interface CreateCompanyVariables {
  company: CompanyModel;
}

export const CREATE_COMPANY_MUTATION = gql`
  ${COMPANY_FRAGMENTS.Company}

  mutation CREATE_COMPANY_MUTATION($company: CreateCompanyParams) {
    createCompany(company: $company) {
      ...Company
    }
  }
`;

export interface UpdateCompanyData {
  updateCompany: CompanyModel;
}

export interface UpdateCompanyVariables {
  companyId: string;
  company: CompanyModel;
}

export const UPDATE_COMPANY_MUTATION = gql`
  ${COMPANY_FRAGMENTS.Company}

  mutation UPDATE_COMPANY_MUTATION($companyId: String, $company: UpdateCompanyParams) {
    updateCompany(companyId: $companyId, company: $company) {
      ...Company
    }
  }
`;

export interface UpdateCompanyVersionData {
  changeCompanyVersion: {
    companies: string[];
    upgradeType: VersionUpdateType;
    version: string;
  };
}

export interface UpdateCompanyVersionVariables {
  companies: string[] | string;
  upgradeType: VersionUpdateType;
  version: string;
}

export const UPDATE_COMPANY_VERSION_MUTATION = gql`
  mutation UPDATE_COMPANY_VERSION_MUTATION(
    $companies: [String]
    $upgradeType: UpgradeType
    $version: String
  ) {
    changeCompanyVersion(companies: $companies, upgradeType: $upgradeType, version: $version) {
      companies
      upgradeType
      version
    }
  }
`;

export enum VersionUpdateType {
  UPGRADE = "UP",
  DOWNGRADE = "DOWN",
}
export interface UpdateCurrentVersionData {
  updateCurrentVersion: {
    service: {
      name: string;
      version: string;
    };
    bundleVersion: string;
  };
}

export interface UpdateCurrentVersionVariables {
  version: string;
}

export const UPDATE_CURRENT_VERSION_MUTATION = gql`
  mutation UPDATE_CURRENT_VERSION_MUTATION($version: String) {
    updateCurrentVersion(version: $version) {
      bundleVersion
    }
  }
`;

export interface UpdateCompanyStatusData {
  updateCompanyStatus: CompanyModel;
}

export interface UpdateCompanyStatusVariables {
  companyId: string;
  status: CompanyGetType;
}

export const UPDATE_COMPANY_STATUS_MUTATION = gql`
  ${COMPANY_FRAGMENTS.Company}

  mutation UPDATE_COMPANY_STATUS_MUTATION($companyId: String, $status: CompanyStatusTypes) {
    updateCompanyStatus(companyId: $companyId, status: $status) {
      ...Company
    }
  }
`;
