import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAttendeeBrandingFlag } from "../../hooks/featureFlagHooks/attendeeBrandingFlag.hook";
import { EventType, UploadedDataFormat } from "../../services/event/event.model";
import {
  AttendeeConsoleBrandingErrorState,
  AttendeeConsoleMicrositePagesOrder,
  ControlPanelBrandingErrorState,
  EarningsMicrositePagesOrder,
  MicrositePagesOrder,
} from "../../views/eventMicrosite/micrositeModal.definition";
import { EventModelPopulated } from "../../hooks/events/events.hook.definitions";
import {
  EventMicrositePageTabNumber,
  EventMicrositePageType,
  SavedEventBrandingType,
  UpdateAttendeeConsoleBrandingParams,
  UpdateControlPanelBrandingParams,
} from "../../views/eventMicrosite/hooks/microsite.hook.definition";
import useCompanies, { useCompanyQueryHook } from "../../hooks/companies/companies.hook";
import {
  EventBrandingReducerAction,
  useEventBrandingReducer,
} from "../../views/eventMicrosite/hooks/eventBranding.hook";
import { EventActionTypes } from "../../components/event/modal/hooks/eventModal.hook";
import { useUpdateEventMicrositeBrandingMutation } from "../../views/eventMicrosite/hooks/microsite.hook";
import {
  getInitialAttendeeConsoleBrandingErrorState,
  getInitialControlPanelBrandingErrorState,
} from "../../views/eventMicrosite/helper";
import { NotificationService } from "@q4/nimbus-ui";
import { generateBackgroundUrl } from "../../utils/asset.utils";
import config from "../../config";
import { Nullable } from "../../utils/types/tsTypes";
import {
  EventBrandingVisibilityState,
  EventBrandingVisibilityReducerAction,
  useEventBrandingVisiblityReducer,
} from "../../views/eventMicrosite/hooks/eventBrandingVisibility.hook";

export interface MicrositeContextProps {
  currentEventData: EventModelPopulated;
}
export interface MicrositeContextState {
  attendeeConsoleBrandingEnabled: boolean;
  attendeeConsoleLogoUrl: string;
  backgroundImageUrl: string;
  brandingVisibilityState: EventBrandingVisibilityState;
  companyBackgrounds?: UploadedDataFormat[];
  companyFonts?: UploadedDataFormat[];
  companyId?: string;
  currentPageTab?: Nullable<EventMicrositePageTabNumber>;
  currentPageView?: EventMicrositePageType;
  eventBrandingDispatch: Dispatch<EventBrandingReducerAction>;
  eventBrandingPayload: SavedEventBrandingType;
  isEarningsEvent: boolean;
  isInvestorDayEvent: boolean;
  isEventUsingCustomLogo: boolean;
  micrositeControlPanelBrandingErrorState: ControlPanelBrandingErrorState;
  attendeeConsoleBrandingErrorState: AttendeeConsoleBrandingErrorState;
  updateBrandingVisibility: (action: EventBrandingVisibilityReducerAction) => void;
  pageOrder: any[];
  saveAttendeeConsoleBrandingData: () => Promise<void>;
  saveMicrositeBrandingData: () => Promise<void>;
  setBrandingLogoUrl: Dispatch<string | undefined>;
  setCurrentPageTab: (pageTab: EventMicrositePageTabNumber) => void;
  setCurrentPageView: (tab: EventMicrositePageType) => void;
  setMicrositeControlPanelBrandingErrorState: Dispatch<ControlPanelBrandingErrorState>;
  setAttendeeConsoleBrandingErrorState: Dispatch<AttendeeConsoleBrandingErrorState>;
}

const MicrositeContext = createContext<Partial<MicrositeContextState>>({});

export const MicrositeProvider: FunctionComponent<MicrositeContextProps> = (props): JSX.Element => {
  const { currentEventData } = props;

  const notificationService = useRef(new NotificationService());
  const [currentPageTab, setCurrentPageTab] = useState<EventMicrositePageTabNumber>({});
  const [currentPageView, setCurrentPageView] = useState<EventMicrositePageType>(null);
  const [brandingLogoUrl, setBrandingLogoUrl] = useState<string>();

  const [eventBrandingPayload, eventBrandingDispatch] = useEventBrandingReducer(
    currentEventData?.branding
  );

  const { state: brandingVisibilityState, updateVisibility: updateBrandingVisibility } =
    useEventBrandingVisiblityReducer();

  const [micrositeControlPanelBrandingErrorState, setMicrositeControlPanelBrandingErrorState] =
    useState(getInitialControlPanelBrandingErrorState());

  const [attendeeConsoleBrandingErrorState, setAttendeeConsoleBrandingErrorState] = useState(
    getInitialAttendeeConsoleBrandingErrorState()
  );

  const { enabled: attendeeConsoleBrandingEnabled } = useAttendeeBrandingFlag({
    companyId: currentEventData?.companyId,
  });

  const { useCompanyQuery } = useCompanies();
  const {
    companyBackgrounds,
    fonts: companyFonts,
    logoUrl: companyLogoUrl,
  } = useCompanyQueryHook({
    companyId: currentEventData?.company?.id,
    meetingId: currentEventData?.meetingId,
    useCompanyQuery,
  });

  const isEarningsEvent = useMemo(
    () => currentEventData?.eventType === EventType.EARNINGS,
    [currentEventData?.eventType]
  );

  const isInvestorDayEvent = useMemo(
    () => currentEventData?.eventType === EventType.INVESTOR_DAY,
    [currentEventData?.eventType]
  );

  const isEventUsingCustomLogo = useMemo(() => !!brandingLogoUrl, [brandingLogoUrl]);

  const pageOrder = useMemo(
    () =>
      isEarningsEvent || isInvestorDayEvent
        ? attendeeConsoleBrandingEnabled
          ? AttendeeConsoleMicrositePagesOrder
          : EarningsMicrositePagesOrder
        : MicrositePagesOrder,
    [attendeeConsoleBrandingEnabled, isEarningsEvent, isInvestorDayEvent]
  );

  const backgroundImageUrl = useMemo(() => {
    if (!eventBrandingPayload?.controlPanelBranding?.backgroundImage)
      return config.branding.defaultBackgroundImgUrl;

    return generateBackgroundUrl(
      currentEventData?.company?.id,
      eventBrandingPayload?.controlPanelBranding?.backgroundImage
    );
  }, [currentEventData?.company?.id, eventBrandingPayload?.controlPanelBranding?.backgroundImage]);

  const logoUrl = useMemo(
    () => brandingLogoUrl ?? companyLogoUrl,
    [brandingLogoUrl, companyLogoUrl]
  );

  const [updateEventMicrositeBranding] = useUpdateEventMicrositeBrandingMutation({
    onError: () => notificationService.current.error("Unable to save changes"),
  });

  const saveMicrositeBrandingData = useCallback(async () => {
    const controlPanelBrandingParams: UpdateControlPanelBrandingParams = {
      ...eventBrandingPayload?.controlPanelBranding,
    };

    await updateEventMicrositeBranding({
      variables: {
        input: {
          meetingId: currentEventData?.meetingId,
          pageType: EventMicrositePageType.CONTROL_PANEL,
          controlPanelBrandingParams,
        },
      },
    });
  }, [
    currentEventData?.meetingId,
    eventBrandingPayload?.controlPanelBranding,
    updateEventMicrositeBranding,
  ]);

  const saveAttendeeConsoleBrandingData = useCallback(async () => {
    const attendeeConsoleBrandingParams: UpdateAttendeeConsoleBrandingParams = {
      ...eventBrandingPayload?.attendeeConsoleBranding,
    };

    delete attendeeConsoleBrandingParams?.images?.eventLogo?.signedUrl; // GraphQL does not need to have this passed in, this is just for the React app

    await updateEventMicrositeBranding({
      variables: {
        input: {
          meetingId: currentEventData?.meetingId,
          pageType: EventMicrositePageType.ALL_PAGES,
          allPagesBrandingParams: {
            attendeeConsoleBranding: attendeeConsoleBrandingParams,
          },
        },
      },
    });
  }, [
    currentEventData?.meetingId,
    eventBrandingPayload?.attendeeConsoleBranding,
    updateEventMicrositeBranding,
  ]);

  useEffect(
    function updateControlBrandingOnEventChange() {
      currentEventData?.branding &&
        eventBrandingDispatch({
          type: EventActionTypes.RESET,
          newBrandingState: currentEventData?.branding,
        });
    },
    [currentEventData?.branding, eventBrandingDispatch]
  );

  useEffect(
    function initializeBrandingLogoUrl() {
      setBrandingLogoUrl(
        eventBrandingPayload?.attendeeConsoleBranding?.images?.eventLogo?.signedUrl ?? null
      );
    },
    [eventBrandingPayload?.attendeeConsoleBranding?.images?.eventLogo?.signedUrl]
  );

  return (
    <MicrositeContext.Provider
      value={{
        // Data
        attendeeConsoleBrandingEnabled,
        attendeeConsoleLogoUrl: logoUrl,
        backgroundImageUrl,
        brandingVisibilityState,
        companyId: currentEventData?.company?.id,
        companyBackgrounds,
        companyFonts,
        currentPageTab,
        currentPageView,
        micrositeControlPanelBrandingErrorState,
        attendeeConsoleBrandingErrorState,
        eventBrandingPayload,
        isEarningsEvent,
        isInvestorDayEvent,
        isEventUsingCustomLogo,
        pageOrder,
        // Functions
        updateBrandingVisibility,
        eventBrandingDispatch,
        setBrandingLogoUrl,
        setCurrentPageTab,
        setCurrentPageView,
        saveAttendeeConsoleBrandingData,
        saveMicrositeBrandingData,
        setMicrositeControlPanelBrandingErrorState,
        setAttendeeConsoleBrandingErrorState,
      }}
    >
      {props?.children}
    </MicrositeContext.Provider>
  );
};

export default MicrositeContext;
