import { useEffect } from "react";
import { AsyncState } from "./asyncFn.definition";
import { useAsyncFn } from "./asyncFn.hook";

export const useAsyncEffect = <T, E = Error>(
  fn: (...args: unknown[]) => Promise<T>,
  deps: unknown[] = [],
  initialState: AsyncState<T, E> = { loading: true }
): AsyncState<T, E> => {
  const [state, fetch] = useAsyncFn<T, E>(fn, initialState);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, ...deps]);

  return state;
};
