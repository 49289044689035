import { IdModelBase, isNullOrWhiteSpace, TextboxIdModel } from "@q4/nimbus-ui";
import { CustomRegistrationFieldFormBaseIdModel } from "../base/customRegistrationFieldFormBase.definition";

export enum CustomRegistrationFieldFormPromptClassName {
  Base = "custom-registration-field-form-prompt",
  FieldTypeConfiguration = "custom-registration-field-form-prompt_field-configuration",
}

export enum CustomRegistrationFieldFormPromptLabel {
  FieldTypeConfiguration = "Prompt",
}

export enum CustomRegistrationFieldFormPromptPlaceholder {
  EmailAddress = "Enter Email Address",
  PhoneNumber = "Enter Phone Number",
}

export class CustomRegistrationFieldFormPromptIdModel extends IdModelBase {
  formBase: CustomRegistrationFieldFormBaseIdModel;
  prompt: TextboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.formBase = new CustomRegistrationFieldFormBaseIdModel(`${this.id}TextForm`);
    this.prompt = new TextboxIdModel(`${this.id}PromptTextbox`);
  }
}
