import { LoginAttemptStatus } from "./../../contexts/auth0/auth0.definition";
import { gql } from "@apollo/client";
import { UserRoles, PlatformRoles } from "../../configurations/userRolesPermissions.configuration";
import { UserType } from "../../services/user/user.definition";
import { Nullable } from "../../utils/types/tsTypes";
import { USER_FRAGMENTS } from "./users.gql";
import { User } from "../../services/user/user.model";

export interface UsersHookProps {
  user?: UserType;
  updateUser?: (user: UserType) => void;
}

export interface DeleteUserMutationData {
  deleteUser: { id: string };
}

export interface DeleteUserMutationVars {
  id: string;
  companyId?: string;
}

export interface SuspendUserMutationData {
  suspendUser: { id: string };
}

export interface SuspendUserMutationVars {
  id: string;
}

export interface UnsuspendUserMutationData {
  unsuspendUser: { id: string };
}

export interface UnsuspendUserMutationVars {
  id: string;
}

export interface UnlockUserMutationData {
  unlockUser: { id: string };
}

export interface UnlockUserMutationVars {
  id: string;
}

export interface ResendUserInviteMutationVars {
  email: string;
  companyName?: string;
}

export interface UpdateUserMutationData {
  updateUser: UserType;
}

export interface UpdateUserMutationVars {
  user: {
    id: string;
    userTypes?: PlatformRoles[];
    firstName?: string;
    lastName?: string;
    companies?: string[];
  };
}

export interface CreateUsersMutationData {
  createUsers: UserType[];
}

export interface CreateUsersMutationVars {
  emails: string[];
  userTypes: PlatformRoles[] | string[];
  companyId?: string;
  companyName?: string;
}

export interface AssignCompanyMutationData {
  assignCompany: void;
}

export interface AssignCompanyMutationVars {
  companyId: string;
  userIds: string[];
}

export interface UnassignCompanyMutationData {
  unAssignCompany: void;
}

export interface UserRegisterMutationData {
  UserRegister: void;
}

export interface UnassignCompanyMutationVars {
  companyId: string;
  userIds: string[];
}

export interface UserRegisterMutationVars {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  registrationId: string;
}

export interface GetUsersQueryData {
  getUsers: {
    users: UserType[];
    totalUsers: number;
  };
}

export interface GetUsersLiveEventQueryData {
  getUsersLiveEvent: {
    _typename: string;
    users: User[];
    totalUsers: number;
  };
}

export interface IsEmailRegisteredQueryVars {
  email: string;
}

export interface IsEmailRegisteredQueryData {
  isEmailRegistered: boolean;
}

export enum UserRegistrationErrorCode {
  Expired = "expired",
  Registered = "registered",
  Suspended = "suspended",
  Locked = "locked",
  NotFound = "notfound",
}

export interface GetUserRegistrationQueryData {
  getUserRegistration: {
    errorCode: Nullable<UserRegistrationErrorCode>;
    data: Nullable<User & { email: string }>;
  };
}

export interface GetUserRegistrationQueryVars {
  registrationId: string;
}

export interface GetFailedLoginCountQueryData {
  getFailedLoginCount: {
    loginAttempts: number;
  };
}

export interface GetFailedLoginCountQueryVars {
  email: string;
}

export interface UsersBaseQueryOptions {
  sortField: string;
  sortOrder: number;
  search: string;
  userStatus?: string;
  limit?: number;
  page?: number;
}

export interface GetUsersQueryVars extends UsersBaseQueryOptions {
  userType?: string | PlatformRoles;
  companyId?: string;
  meetingId?: number;
}

export interface GetUsersLiveEventQueryVars extends UsersBaseQueryOptions {
  userType?: UserRoles[];
  companyId: string;
  myCompany?: boolean;
}

export interface GetUsersLiveEventQueryResponse {
  users: User[];
  totalUsers: number;
}

export interface GetUsersQueryResponse {
  users: UserType[];
}

export interface UpdateLoginCountMutationData {
  updateLoginCount: void;
}

export interface UpdateLoginCountMutationVars {
  email: string;
  status: LoginAttemptStatus;
}

export interface ResetUserPasswordMutationData {
  resetUserPassword: any;
}

export interface ResetUserPasswordMutationVars {
  password: string;
  resetPasswordId: string;
}

export interface UpdateUserPasswordMutationData {
  updateUserPassword: any;
}

export interface UpdateUserPasswordMutationVars {
  currentPassword: string;
  password: string;
}

export interface RequestResetUserPasswordMutationData {
  requestResetUserPassword: any;
}

export interface RequestResetUserPasswordMutationVars {
  email: string;
}

export const DELETE_USER_MUTATION = gql`
  mutation DELETE_USER_MUTATION($id: String, $companyId: String) {
    deleteUser(id: $id, companyId: $companyId) {
      id
    }
  }
`;

export const SUSPEND_USER_MUTATION = gql`
  mutation SUSPEND_USER_MUTATION($id: String) {
    suspendUser(id: $id) {
      id
      userStatus
    }
  }
`;

export const UNSUSPEND_USER_MUTATION = gql`
  mutation UNSUSPEND_USER_MUTATION($id: String) {
    unsuspendUser(id: $id) {
      id
      userStatus
    }
  }
`;

export const UNLOCK_USER_MUTATION = gql`
  mutation UNLOCK_USER_MUTATION($id: String) {
    unlockUser(id: $id) {
      id
      userStatus
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION($user: UserInput) {
    updateUser(user: $user) {
      ...User
    }
  }

  ${USER_FRAGMENTS.User}
`;

export const CREATE_USERS_MUTATION = gql`
  mutation CREATE_USERS_MUTATION(
    $emails: [String]
    $userTypes: [String]
    $companyId: String
    $companyName: String
  ) {
    createUsers(
      emails: $emails
      userTypes: $userTypes
      companyId: $companyId
      companyName: $companyName
    )
  }
`;

export const ASSIGN_COMPANY_MUTATION = gql`
  mutation ASSIGN_COMPANY_MUTATION($companyId: String, $userIds: [String]) {
    assignCompany(companyId: $companyId, userIds: $userIds)
  }
`;

export const UNASSIGN_COMPANY_MUTATION = gql`
  mutation UNASSIGN_COMPANY_MUTATION($companyId: String, $userIds: [String]) {
    unassignCompany(companyId: $companyId, userIds: $userIds)
  }
`;

export const USER_REGISTER_MUTATION = gql`
  mutation USER_REGISTER_MUTATION(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $registrationId: String
  ) {
    userRegister(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      registrationId: $registrationId
    )
  }
`;

export const RESEND_USER_INVITE_MUTATION = gql`
  mutation RESEND_USER_INVITE_MUTATION($email: String, $companyName: String) {
    resendUserInvite(email: $email, companyName: $companyName)
  }
`;

export const GET_USERS_QUERY = gql`
  query GET_USERS_QUERY(
    $sortField: String
    $sortOrder: Int
    $limit: Int
    $page: Int
    $search: String
    $userStatus: String
    $userType: String
    $companyId: String
    $meetingId: Int
  ) {
    getUsers(
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
      page: $page
      search: $search
      userStatus: $userStatus
      userType: $userType
      companyId: $companyId
      meetingId: $meetingId
    ) {
      totalUsers
      users {
        ...User
      }
    }
  }

  ${USER_FRAGMENTS.User}
`;

export const GET_USERS_LIVE_EVENT_QUERY = gql`
  query GET_USERS_LIVE_EVENT_QUERY(
    $companyId: String
    $search: String
    $sortField: String
    $sortOrder: Int
    $page: Int
    $limit: Int
  ) {
    getUsersLiveEvent(
      companyId: $companyId
      search: $search
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      limit: $limit
    ) {
      users {
        id
        email
        firstName
        lastName
        companies
        userStatus
        userTypes
      }
      totalUsers
    }
  }
`;

export const IS_EMAIL_REGISTERED_QUERY = gql`
  query IS_EMAIL_REGISTERED_QUERY($email: String) {
    isEmailRegistered(email: $email)
  }
`;

export const GET_USER_REGISTRATION_QUERY = gql`
  query GET_USER_REGISTRATION_QUERY($registrationId: String) {
    getUserRegistration(registrationId: $registrationId) {
      errorCode
      data {
        id
        expiry
        email
      }
    }
  }
`;

export const UPDATE_LOGIN_COUNT_QUERY = gql`
  mutation GET_FAILED_LOGIN_COUNT_MUTATION($email: String, $status: LoginAttemptStatus) {
    updateLoginCount(email: $email, status: $status)
  }
`;

export const GET_FAILED_LOGIN_COUNT_QUERY = gql`
  query GET_FAILED_LOGIN_COUNT_QUERY($email: String) {
    getFailedLoginCount(email: $email) {
      loginAttempts
    }
  }
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation RESET_USER_PASSWORD_MUTATION($password: String, $resetPasswordId: String) {
    resetUserPassword(password: $password, resetPasswordId: $resetPasswordId)
  }
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UPDATE_USER_PASSWORD_MUTATION($currentPassword: String, $password: String) {
    updateUserPassword(currentPassword: $currentPassword, password: $password)
  }
`;

export const REQUEST_RESET_USER_PASSWORD_MUTATION = gql`
  mutation REQUEST_RESET_USER_PASSWORD_MUTATION($email: String) {
    requestResetUserPassword(email: $email)
  }
`;
