import { IdModelBase, isNullOrWhiteSpace, PortalProps } from "@q4/nimbus-ui";

export type SlideoutProps = PortalProps;

export enum SlideoutClassName {
  Base = "slideout",
  Content = "slideout_content",
}

export class SlideoutIdModel extends IdModelBase {
  content: string;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.content = `${this.id}Content`;
  }
}
