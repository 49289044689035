import React, { memo, useMemo, useRef, useState } from "react";
import config from "../../../../config";
import { Modal, Text, TextTheme } from "@q4/nimbus-ui";
import AudioVideoControls from "../../../../components/AudioVideoControls/AudioVideoControls.component";
import AudioVideoPlayer from "../../../AudioVideoPlayer/AudioVideoPlayer.component";
import { AudioVideoState } from "../../../../components/AudioVideoControls/AudioVideoControls.definition";
import { formatDuration } from "../../../../utils/datetime.utils";
import {
  AudioVideoPreviewProps,
  AudioVideoPreviewClassNames,
} from "./AudioVideoPreview.definition";
import "./AudioVideoPreview.component.scss";
import { AudioVideoContext } from "../../../../hooks/assetUploads/assetUploads.definition";
import { EventAssetType } from "../../../../hooks/assets/assets.hook.definition";

export function generateAssetUrl(meetingId: number | string, fileName: string): string {
  const basePath = `${config.branding.s3.domain}/${config.branding.s3.materialsPath}`;
  return basePath.replace(/<eventId>.*/, `${meetingId}/${fileName}`);
}

const AudioVideoPreview = (props: AudioVideoPreviewProps) => {
  const { asset, assetV2, meetingId, visible, footerActions, onCloseRequest } = props;
  const mediaRef = useRef<any>();
  const volumeMax = 100;
  const [currentTime, setCurrentTime] = useState(0);

  // audio/video controls (states)
  const [mediaState, setMediaState] = useState(AudioVideoState.PAUSE);
  const [volumeState, setVolumeState] = useState(volumeMax);

  const trueAsset = useMemo(() => {
    if (!assetV2) return asset;

    return {
      name: `av/${assetV2.id}`,
      displayName: assetV2.displayName,
      duration: (assetV2.context as AudioVideoContext)?.duration,
      assetType: assetV2.assetType as unknown as EventAssetType,
    };
  }, [asset, assetV2]);

  // audio/video controls (function)
  function onPlay() {
    mediaRef.current?.play?.();
    setMediaState(AudioVideoState.PLAY);
  }
  function onPause() {
    mediaRef.current?.pause?.();
    setMediaState(AudioVideoState.PAUSE);
  }
  function onVolume(volume: number) {
    mediaRef.current.volume = volume / volumeMax;
    setVolumeState(volume);
  }

  function onSeek() {
    mediaRef.current.currentTime = 0;
    onPause();
  }

  function onTimeUpdate() {
    setCurrentTime(mediaRef.current?.currentTime);
  }

  function onClose() {
    onSeek();
    onCloseRequest();
  }

  function generateTimeStamp(): JSX.Element {
    return (
      <Text className={AudioVideoPreviewClassNames.TimeStamp} theme={TextTheme.White}>
        {formatDuration(currentTime)} / {formatDuration(trueAsset.duration)}
      </Text>
    );
  }
  const timeStamp = useMemo(generateTimeStamp, [trueAsset, currentTime]);

  const player = useMemo(() => {
    return (
      <AudioVideoPlayer
        fileName={trueAsset?.name}
        assetType={trueAsset?.assetType}
        meetingId={meetingId}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
        mediaRef={mediaRef}
      />
    );
  }, [meetingId, trueAsset?.name, trueAsset?.assetType]);

  return (
    visible && (
      <Modal
        title="Preview"
        className={AudioVideoPreviewClassNames.Base}
        visible={visible}
        footerActions={footerActions}
        onCloseRequest={onClose}
      >
        <div className={AudioVideoPreviewClassNames.Container}>
          {player}
          <AudioVideoControls
            state={mediaState}
            volume={volumeState}
            fileName={trueAsset.displayName}
            onPlay={onPlay}
            onPause={onPause}
            onVolume={onVolume}
            onSeek={onSeek}
            asset={trueAsset}
          />
          {timeStamp}
        </div>
      </Modal>
    )
  );
};

export default memo(AudioVideoPreview);
