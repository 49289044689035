import {
  BaseComponentWithChildrenProps,
  ButtonProps,
  PopoverMenuProps,
  TooltipProps,
} from "@q4/nimbus-ui";
import { ReactNode } from "react";
import { ReleaseState } from "../../../../components/tags/ReleaseTag.component";

export enum VWSelectionTheme {
  citrus = "citrus",
  teal = "teal",
}

export interface VideoWrapperProps extends BaseComponentWithChildrenProps {
  id: string;
  customLeftBadge?: ReactNode;
  customRightBadge?: ReactNode;
  displayName: string;
  toolTipProps?: Pick<TooltipProps, "label" | "theme">;
  topLeftProps?: { release?: ReleaseState; custom?: ReactNode };
  topRightProps?: {
    buttonProps?: Partial<ButtonProps>;
    popOverProps?: Partial<PopoverMenuProps>;
    showTRMOnHover?: boolean;
  };
  thumbnailImg?: ReactNode;
  selected?: boolean;
  initializing?: boolean;
  selectionTheme?: VWSelectionTheme;
  onSelect?: (id: string) => void;
}

export enum VideoWrapperClassNames {
  Base = "video-wrapper",
  Title = "video-wrapper-title",
  Overlay = "video-wrapper-overlay",
  Selected = "video-wrapper-overlay-selected",
  CheckBox = "video-wrapper-overlay-checkbox",
  ImageWrapper = "video-wrapper-overlay-image-wrapper",
  CheckBoxSelected = "video-wrapper-overlay-checkbox-selected",
  CheckboxDisabled = "video-wrapper-overlay-checkbox-disabled",
  OverlayLeft = "video-wrapper-overlay-left",
  OverlayLeftBeta = "video-wrapper-overlay-left--beta",
  Name = "video-wrapper-overlay-left-name",
  OverlayBadgeDisconnected = "video-wrapper-overlay-left-name-badge--disconnected",
  OverlayBadgeScreenShare = "q4i-share-screen-on-filled",
  OverlayBadgePSTN = "q4i-phone-4pt",
  OverlayBadgeWarning = "video-wrapper-overlay-left-name-badge--warning",
  OverlayRight = "video-wrapper-overlay-right",
  OverlayRightTwoEl = "video-wrapper-overlay-right-two-el",
  OverlayRightAudioEl = "video-wrapper-overlay-right-only-audio-el",
  OverlayRightNumSelectEl = "video-wrapper-overlay-right-only-number-selection-el",
  Mic = "video-wrapper-overlay-mic",
  MicMuted = "video-wrapper-overlay-mic-muted",
  MicClickable = "video-wrapper-overlay-mic-clickable",
  MicFilled = "q4i-microphone-on-filled",
  MicOffFilled = "q4i-microphone-off-filled",
  MenuButton = "video-wrapper-overlay-menu-button",
  BaseAudioOnly = "video-wrapper--audio-only",
  OverlayAudioOnly = "video-wrapper-overlay--audio-only",
  NameAudioOnly = "video-wrapper-overlay-left-name--audio-only",
  RemoveStreamButton = "video-wrapper-overlay-remove-button",
  Confirmation = "video-wrapper-confirmation",
  // TODO - purge unused video wrapper class names
  // all classes below this line are for the V2 video wrapper
  Thumbnail = "vw-inner-thumbnail",
  LeftBadge = "vw-inner-bottom-left-badge",
  IsSelected = "vw-selection--selected",
  SelectedCitrus = "vw-selection--selected-citrus",
  SelectedTeal = "vw-selection--selected-teal",
}

export enum VideoWrapperIds {
  MuteMenuItem = "VideoWrapperMuteMenuItem",
  MenuButton = "VideoWrapperMenuButton",
  UnstageMenuItem = "VideoWrapperUnstageMenuItem",
  AssetMenuItem = "VideoWrapperAssetMenuItem",
}

const dataIdPrefix = "VideoWrapper";

export const DATA_IDS = {
  WRAPPER: `${dataIdPrefix}Wrapper`,
  OVERLAY: `${dataIdPrefix}Overlay`,
  NAME: `${dataIdPrefix}Name`,
  PREVIEW_MODAL: `${dataIdPrefix}PreviewModal`,
  CANCEL_STAGE_BUTTON: `${dataIdPrefix}CancelStageButton`,
};
