import React from "react";
import { AssetData, AssetType } from "../../../hooks/assetUploads/assetUploads.definition";
import SlideAssetElement from "./slideAssetElement.component";
import AudioAssetElement from "./audioAssetElement.component";
import VideoAssetElement from "./videoAssetElement.component";
import { AssetBroadcastStream } from "../../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";

export interface AssetElementFactoryProps {
  asset: AssetData;
  onAssetRendered: (stream: AssetBroadcastStream) => void;
}

export default function AssetElementFactory(props: AssetElementFactoryProps) {
  const { asset, onAssetRendered } = props;

  switch (asset.assetType) {
    case AssetType.SLIDESHOW:
      return <SlideAssetElement {...{ asset, onAssetRendered }} />;
    case AssetType.AUDIO:
      return <AudioAssetElement {...{ asset, onAssetRendered }} />;
    case AssetType.VIDEO:
      return <VideoAssetElement {...{ asset, onAssetRendered }} />;
    default:
      return null;
  }
}
