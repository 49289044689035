import { Participant } from "../../../../../hooks/participants/participants.hook.definition";
import { OTStreamType, OTStreamTypes } from "../../../../../opentok-react/types";
import { StreamOriginType } from "../../../interfaces/broadcastStream/broadcastStream";
import { BaseBroadcastStream } from "./BaseBroadcastStream";

function getStreamSubscriber(stream: OTStreamType) {
  const subscribers = (window as any).OTSession.getSubscribersForStream(stream);
  if (subscribers && subscribers.length) return subscribers[0];
}

function getStreamPublisher(stream: OTStreamType) {
  return (window as any).OTSession.getPublisherForStream(stream);
}

function parseStreamConnectionData(connection: { data: any }) {
  try {
    return JSON.parse(connection?.data);
  } catch {
    return;
  }
}

export class OpenTokStream extends BaseBroadcastStream {
  constructor(stream: OTStreamType) {
    if (!stream) throw new Error("OpenTokStream Error: a valid stream object is required.");

    super(stream);
  }

  get id() {
    return this._stream.id;
  }

  get type() {
    return this._stream.videoType;
  }

  get connection() {
    return this._stream.connection;
  }

  get hasAudio() {
    return this._stream.hasAudio;
  }

  get hasVideo() {
    return this._stream.hasVideo;
  }

  get name() {
    return this._stream.name;
  }

  get isLocalPublisher() {
    return this._stream.isLocalPublisher;
  }

  get isPSTNStream() {
    const connectionData = parseStreamConnectionData(this.connection);
    return connectionData?.sip || this.type === OTStreamTypes.PSTN;
  }

  get isTempStream() {
    return this.id === this.connection?.connectionId && !(this.isAssetStream || this.isPSTNStream);
  }

  get hasMediaStateInfo() {
    return !this.isPSTNStream;
  }

  get isScreenShare() {
    return this.type === OTStreamTypes.Screen;
  }

  get sourceUrl() {
    return "";
  }

  get streamOrigin() {
    return StreamOriginType.P2P;
  }

  getVendorStream(): OTStreamType {
    return this._stream;
  }

  videoElement() {
    return getStreamSubscriber(this._stream)?.videoElement();
  }

  hasAudioWithMediaState(participant: Participant) {
    return this?.hasMediaStateInfo ? this?.hasAudio : participant?.mediaSettings?.hasAudio;
  }

  async videoMediaStream(): Promise<MediaStream> {
    try {
      if (this._stream.isLocalPublisher) {
        return getStreamPublisher(this._stream)?.videoElement()?.srcObject;
      }
      await this.streamSubscribed;
      return getStreamSubscriber(this._stream)?.videoElement()?.srcObject;
    } catch (err) {
      console.error("Cannot get videoMediaStream: ", err);
      return null;
    }
  }
}
