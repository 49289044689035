import { useState, useRef, useEffect } from "react";
import { isFunction } from "lodash";

import {
  ShareScreenPublisherHookProps,
  ShareScreenPublisherHookModel,
  ShareScreenErrorCodes,
} from "../shareScreenPublisher/shareScreenPublisher.definition";
import { OTShareScreenPublisherProps } from "../../components/OTShareScreenPublisher/OTShareScreenPublisher.definition";

import { OTPublisherType } from "../../types/index";

export default function useShareScreenPublisherHook(
  props: ShareScreenPublisherHookProps
): ShareScreenPublisherHookModel {
  const OT = (window as any).OT;

  const [shareScreenPublisher, setShareScreenPublisher] = useState<OTPublisherType>();
  const isScreenShareAllowedRef = useRef(props.isScreenShareAllowed);

  useEffect(() => {
    isScreenShareAllowedRef.current = props.isScreenShareAllowed;
  }, [props.isScreenShareAllowed]);

  async function createShareScreenPublisher(
    publisherProps?: OTShareScreenPublisherProps
  ): Promise<void> {
    try {
      const {
        initShareScreenPublisherProps,
        onShareScreenPublish,
        onShareScreenPublisherInit,
        onCreateShareScreenPublisherError,
      } = publisherProps;

      const { onScreenShareNotAllowed } = props;

      const newPublisher = OT.initPublisher(
        null,
        {
          videoSource: "screen",
          insertDefaultUI: false,
          ...initShareScreenPublisherProps,
        },
        (err) => {
          if (err) {
            if (err?.code === ShareScreenErrorCodes["HARDWARE_PERMISSION_DENIED"]) return;
            onCreateShareScreenPublisherError &&
              isFunction(onCreateShareScreenPublisherError) &&
              onCreateShareScreenPublisherError(err);
          } else {
            if (!isScreenShareAllowedRef.current) {
              isFunction(onScreenShareNotAllowed) && onScreenShareNotAllowed();
              return destroyShareScreenPublisher(newPublisher);
            }
            onShareScreenPublisherInit &&
              isFunction(onShareScreenPublisherInit) &&
              onShareScreenPublisherInit();
          }
        }
      );

      // Register Publisher events
      newPublisher.on("streamCreated", function (event) {
        event.stream.isLocalPublisher = true;
        props?.onStreamCreated(event?.stream);
      });
      newPublisher.on("streamDestroyed", function (event) {
        props?.onStreamDestroyed(newPublisher.stream);
        destroyShareScreenPublisher();
      });

      // Publish Publisher to session
      const { session } = props;
      if (session?.connection) {
        publishToSession(newPublisher, onShareScreenPublish, onCreateShareScreenPublisherError);
      } else {
        session.once({
          sessionConnected: publishToSession(
            newPublisher,
            onShareScreenPublish,
            onCreateShareScreenPublisherError
          ),
        });
      }

      setShareScreenPublisher(newPublisher);
    } catch (err) {
      destroyShareScreenPublisher();
      publisherProps?.onCreateShareScreenPublisherError &&
        publisherProps.onCreateShareScreenPublisherError(err);
      console.log("share screen error ", err);
    }
  }

  function destroyShareScreenPublisher(ScreenPublisher?: OTPublisherType): void {
    let publisher = shareScreenPublisher || ScreenPublisher;
    if (publisher) {
      props?.session?.unpublish(publisher);
      publisher.destroy();

      setShareScreenPublisher(null);
    }
  }

  function publishToSession(
    publisher: OTPublisherType,
    onPublish?: Function,
    onError?: Function
  ): void {
    const { session } = props;

    session.publish(publisher, (err) => {
      if (err) {
        onError && isFunction(onError) && onError(err);
      } else {
        onPublish && isFunction(onPublish) && onPublish();
      }
    });
  }

  return {
    shareScreenPublisher,
    createShareScreenPublisher,
    destroyShareScreenPublisher,
  };
}
