import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { TakeControlHookOutput } from "../../adminConsole/hooks/takeControl/takeControl.definition";

export type FullscreenControls = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
};

export interface AssetControlsFooterProps {
  fullScreenControls: FullscreenControls;
  takeControlHook: TakeControlHookOutput;
  controlSlidesState: {
    currentSlide: number;
    totalSlides: number;
  };
  controlSlidesService: {
    jumpToSlide: (targetSlide: number) => void;
  };
}

export enum AssetControlsFooterClassNames {
  Base = "asset_controls_footer",
  BaseNotFullscreen = "asset_controls_footer--not-fullscreen",
  Buttons = "asset_controls_footer_buttons",
  ButtonFullscreen = "asset_controls_footer_buttons--fullscreen",
  SquareFullscreen = "asset_controls_footer_buttons--square",
  Context = "asset_controls_footer_context",
  ContextInControl = "asset_controls_footer_context--control",
  Presenter = "asset_controls_footer_presenter",
  Fullscreen = "q4i-fullscreen_4pt",
  FullscreenExit = "q4i-fullscreen-exit_4pt",
  Warning = "q4i-warning_solid",
}

export interface TakeControlButtonProps extends BaseComponentWithChildrenProps {
  takeControl: () => Promise<void>;
  fullScreenControls: FullscreenControls;
}

export enum TakeControlButtonClassNames {
  Message = "take_control_button_message",
}
