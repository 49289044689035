import { Company } from "../../services/company/company.model";
import { CompanyBrandingResourceType } from "../../hooks/companies/companies.hook.definition";

export const UPLOAD_FILE_SIZE = 5e6;
export const FILE_SIZE_TOO_LARGE_ERROR = "Image could not be uploaded: Too large";
export const FILE_TYPE_NOT_ACCEPTABLE_ERROR = "could not be uploaded: Unacceptable format";
export const MAX_COMPANY_FONT_FILES_LIMIT_REACHED_ERROR = "could not be uploaded: Limit reached";
export const ACCEPTABLE_IMAGE_FORMAT = ["jpeg", "jpg", "png", "svg"];
export const ACCEPTABLE_FAVICON_FORMAT = ["ico", "png", "vnd.microsoft.icon"];
export const ACCEPTABLE_IMAGE_SIZE = 5 * 1024 ** 2;
export const ACCEPTABLE_FONT_FORMAT = ["ttf", "woff", "font-woff", "otf", "eot"];
export const MAX_COMPANY_FONT_FILES_ALLOWED = 6;
export const MAX_COMPANY_IMAGE_FILES_ALLOWED = 10;

export const enum CompanyBrandingAssetType {
  FONT = "FONT",
  FAVICON = "FAVICON",
}

export interface CompanyBrandingResourceToBeRemoved {
  resourceType: CompanyBrandingResourceType;
  resourceName: string;
}

export interface CompanyBrandingProps {
  company?: Company;
}

export const CompanyBrandingIDs = {
  FontUploadButton: "FontUploadButton",
  BackgroundImagesUploadButton: "BackgroundImagesUploadButton",
};

export const CompanyBrandingTypeDescription = {
  Font: "Font files must be in TTF, OTF, EOT or WOFF format",
  Image: "Images must be in PNG or JPG format and no larger than 5MB",
  Favicon: "Favicons must be in PNG or ICO format and no larger than 32KB",
};

export enum CompanyBrandingClassNames {
  Base = "company-branding",
  FontUploaderButton = "company-branding_font_uploader_button",
  Uploader = "company-branding_uploader",
  FontListContainer = "company-branding_font_list_container",
  FontListChip = "company-branding_font_chip",
  UploaderDescription = "company-branding_uploader_description",
  UploaderTitle = "company-branding_uploader_title",
  UploaderCaption = "company-branding_uploader_caption",
  UploaderContainer = "company-branding_uploader_container",
  UploaderFontLogoContainer = "company-branding_font_logo_uploader_container",
  UploaderBackgroundContainer = "company-branding_background_container",
  UploaderBackgroundUploader = "company-branding_background_uploader",
  GridContainer = "company-branding_grid_container",
  Title = "company-branding_title",
  TitleContainer = "company-branding_title_container",
  BackgroundImageList = "company-branding_background-image_list",
  BackgroundOption = "company-branding_background-option",
  BackgroundContainer = "company-branding_background-container",
  ImageOverlay = "company-branding_image-overlay",
  Background = "company-branding_background",
  LogoDisplay = "company-branding_logo-display",
  LogoImage = "company-branding_logo-image",
  FaviconContainer = "company-branding_favicon-container",
  FaviconImage = "company-branding_favicon-image",
  ImagesContainer = "company-branding_images-container",
  FaviconTooltip = "company-branding_favicon-tooltip",
}

export enum CompanyBrandingMessages {
  ImageUploadSuccess = "Image successfully uploaded",
  ImageUploadFailure = "Image could not be uploaded",
  ImageRemoveSuccess = "Image successfully removed",
  ImageRemovalFailure = "Image could not be removed",
  FontUploadSuccess = "Font successfully uploaded",
  FontUploadFailure = "Font could not be uploaded",
  FontRemoveSuccess = "Font successfully removed",
  FontRemovalFailure = "Font could not be removed",
  UploadFailure = "Upload failure",
}

export const errorMap = {
  "Failed to fetch": "Network Error",
};

export enum CompanyBrandingDataIds {
  FaviconContainer = "FaviconContainer",
  FaviconUploadBtn = "FaviconUploadBtn",
}
