import type { ReactNode } from "react";
import type { BaseComponentProps, ButtonProps, AnchorTarget } from "@q4/nimbus-ui";

// #region Definitions
export interface NavigationRoute extends BaseComponentProps {
  path: string;
  label: ReactNode;
  icon: string;
  target?: AnchorTarget;
  exact?: boolean;
}

export interface NavigationProps extends BaseComponentProps {
  logo: ReactNode;
  collapsedLogo?: ReactNode;
  routes: NavigationRoute[];
  collapseToggleIcon?: string;
  footerActions?: ButtonProps[];
  onRouteChange?: (path: string) => void;
}

export enum NavigationClassNames {
  Base = "nui-navigation",
  BaseWithCollapsedModifier = "nui-navigation--collapsed",
  Logo = "nui-navigation_logo",
  List = "nui-navigation_list",
  Item = "nui-navigation_item",
  Route = "nui-navigation_route",
  Icon = "nui-navigation_icon",
  Label = "nui-navigation_label",
  Footer = "nui-navigation_footer",
  CollapseToggle = "nui-navigation_collapse-toggle",
  FooterEnd = "nui-navigation_footer-end",
  Version = "nui-navigation_version",
}
// #endregion
