import { MutationFunctionOptions } from "@apollo/client";
import { useEffect } from "react";
import {
  CreateParticipantWaitingRoomMutationVars,
  ParticipantWaitingRoom,
  useCreateParticipantWaitingRoomMutation,
  useParticipantsWaitingRoomQuery,
  useUpdateParticipantWaitingRoomNameMutation,
  useDeleteParticipantWaitingRoomMutation,
  useCreatedParticipantWaitingRoomSubscription,
  useDeletedParticipantWaitingRoomSubscription,
} from "./participantsWaitingRoom.hook.definition";

import { useStateRef } from "@q4/nimbus-ui";
interface ParticipantsWaitingRoomHookProps {
  meetingId: number;
}

export default function useParticipantsWaitingRoom(props: ParticipantsWaitingRoomHookProps) {
  const { meetingId } = props;
  const [
    participantsWaitingRoom,
    participantsWaitingRoomRef,
    setParticipantsWaitingRoom,
  ] = useStateRef<Partial<ParticipantWaitingRoom>[]>([]);

  const [
    getInitialParticipantsWaitingRoom,
    { data: participantsWaitingRoomQueryData },
  ] = useParticipantsWaitingRoomQuery();

  const {
    data: createdParticipantWaitingRoomSubsData,
  } = useCreatedParticipantWaitingRoomSubscription({
    variables: {
      meetingId,
    },
  });

  const {
    data: deletedParticipantWaitingRoomSubsData,
  } = useDeletedParticipantWaitingRoomSubscription({
    variables: {
      meetingId,
    },
  });

  const [createParticipantWaitingRoomMutation] = useCreateParticipantWaitingRoomMutation();
  const [updateParticipantWaitingRoomNameMutation] = useUpdateParticipantWaitingRoomNameMutation();
  const [deleteWaitingRoomParticipant] = useDeleteParticipantWaitingRoomMutation();

  const queriedParticipantsWaitingRoom =
    participantsWaitingRoomQueryData?.getWaitingRoomParticipants;
  const createdParticipantWaitingRoom =
    createdParticipantWaitingRoomSubsData?.onWaitingRoomParticipantCreated;

  const deletedParticipantWaitingRoom =
    deletedParticipantWaitingRoomSubsData?.onWaitingRoomParticipantDeleted;

  useEffect(() => {
    getInitialParticipantsWaitingRoom({
      variables: {
        meetingId,
      },
    });
  }, [getInitialParticipantsWaitingRoom, meetingId]);

  useEffect(
    function getWaitingRoomQuery() {
      if (queriedParticipantsWaitingRoom?.length) {
        setParticipantsWaitingRoom(queriedParticipantsWaitingRoom);
      }
    },
    [queriedParticipantsWaitingRoom, setParticipantsWaitingRoom]
  );

  // updating participants after creation new one
  useEffect(
    function onWaitingRoomParticipantCreate() {
      if (
        createdParticipantWaitingRoom &&
        !participantsWaitingRoom.find(
          (participantWaitingRoom) => participantWaitingRoom.id === createdParticipantWaitingRoom.id
        )
      ) {
        setParticipantsWaitingRoom([...participantsWaitingRoom, createdParticipantWaitingRoom]);
      }
    },
    [createdParticipantWaitingRoom] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (deletedParticipantWaitingRoom) {
        const newParticipantsWaitingRoom = participantsWaitingRoom.filter((participant) => {
          return participant.id !== deletedParticipantWaitingRoom.id;
        });
        setParticipantsWaitingRoom(newParticipantsWaitingRoom);
      }
    },
    [deletedParticipantWaitingRoom] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function getParticipantWaitingRoomById(id: string) {
    return participantsWaitingRoomRef?.current
      ?.filter((participantWaitingRoom) => participantWaitingRoom?.id === id)
      .reduce((foundParticipantWaitingRoom: any, participantWaitingRoom) => {
        foundParticipantWaitingRoom = {
          ...participantWaitingRoom,
          customName:
            participantWaitingRoom?.customName ||
            foundParticipantWaitingRoom?.customName ||
            participantWaitingRoom?.name,
        };
        return foundParticipantWaitingRoom;
      }, {});
  }

  function createParticipantWaitingRoom(
    variables: CreateParticipantWaitingRoomMutationVars,
    mutationOptions?: MutationFunctionOptions
  ) {
    const createdParticipantWaitingRoom: any = createParticipantWaitingRoomMutation({
      ...mutationOptions,
      variables: { ...variables },
    });
    return createdParticipantWaitingRoom?.data?.createParticipantWaitingRoom;
  }

  async function updateParticipantWaitingRoomName(
    participantWaitingRoomId: string,
    customName: string
  ) {
    return await updateParticipantWaitingRoomNameMutation({
      variables: { meetingId, participantWaitingRoomId, customName },
    });
  }

  return {
    participantsWaitingRoom,
    getParticipantWaitingRoomById,
    createParticipantWaitingRoom,
    updateParticipantWaitingRoomName,
    deleteWaitingRoomParticipant,
  };
}
