import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useLazyQuery,
  SubscriptionHookOptions,
  useSubscription,
} from "@apollo/client";

export interface CurrentController {
  name: string;
  isLocallyControlled: boolean;
}

export interface TakeControlHookProps {
  meetingId: number;
}

export interface TakeControlService {
  takeControl: () => Promise<void>;
}
export interface TakeControlHookOutput {
  participantInControl: CurrentController;
  takeControlService: TakeControlService;
}

export interface GetCurrentPresenterQueryData {
  getCurrentAssetPresenter: string;
}

export interface GetCurrentPresenterQueryParams {
  meetingId: number;
}

export interface TakeControlMutationData {
  takeControlOfPresentingAsset: string;
}

export interface TakeControlMutationParams {
  meetingId: number;
  participantId: string;
}

export interface PresenterChangeSubscriptionData {
  onAssetPresenterChange: string;
}

export interface PresenterChangeSubcriptionParams {
  meetingId: number;
}

const GET_CURRENT_PRESENTER_GQL = gql`
  query ($meetingId: Int!) {
    getCurrentAssetPresenter(meetingId: $meetingId)
  }
`;

const TAKE_CONTROL_GQL = gql`
  mutation ($meetingId: Int!, $participantId: String!) {
    takePresentationControls(meetingId: $meetingId, participantId: $participantId)
  }
`;

const PRESENTER_UPDATED_GQL = gql`
  subscription ($meetingId: Int) {
    onAssetPresenterChange(meetingId: $meetingId)
  }
`;

export function useGetCurrentPresenterQuery<
  D = GetCurrentPresenterQueryData,
  P = GetCurrentPresenterQueryParams
>(options?: QueryHookOptions<D, P>) {
  return useLazyQuery<D, P>(GET_CURRENT_PRESENTER_GQL, { notifyOnNetworkStatusChange: true, ...options });
}

export function useTakeControlsMutation<D = TakeControlMutationData, P = TakeControlMutationParams>(
  options: MutationHookOptions<D, P>
) {
  return useMutation<D, P>(TAKE_CONTROL_GQL, options);
}

export function usePresenterUpdatedSubscription<
  D = PresenterChangeSubscriptionData,
  P = PresenterChangeSubcriptionParams
>(options?: SubscriptionHookOptions<D, P>) {
  return useSubscription<D, P>(PRESENTER_UPDATED_GQL, options);
}
