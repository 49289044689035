import React, { memo, useContext, useEffect } from "react";

import { Scrollbars, ScrollbarsTheme } from "@q4/nimbus-ui";
import { Route, withRouter } from "react-router-dom";
import { AppContext } from "../../../../contexts";
import { AppClassNames } from "../../app.definition";
import { PublicRouteProps } from "./publicRoute.definition";
import { LoadingScreen } from "../../../../components/loadingScreen/loadingScreen";

const PublicRoute = (props: PublicRouteProps): JSX.Element => {
  const { authInitialized, path, routeDetails, ...rest } = props;

  const { updateAppDomain } = useContext(AppContext);

  useEffect(
    function setAppDomainForRoute() {
      updateAppDomain(routeDetails.appDomain);
    },
    [routeDetails, updateAppDomain]
  );

  const { component: Component } = routeDetails;
  const render = (props: any) =>
    authInitialized ? (
      <div className={AppClassNames.Inner}>
        <div className={AppClassNames.View}>
          <Scrollbars theme={ScrollbarsTheme.Dark}>
            <Component {...props} />
          </Scrollbars>
        </div>
      </div>
    ) : (
      <LoadingScreen q4Icon="ni-q4-logo" />
    );

  return <Route path={path} render={render} {...rest} />;
};

export default withRouter(memo(PublicRoute));
