import {
  BaseComponentWithChildrenProps,
  Button,
  ButtonProps,
  ButtonSize,
  ButtonTheme,
  getClassName,
} from "@q4/nimbus-ui";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useClassToggle } from "../../../../hooks/classToggle/useClassToggle.hook";

import "./audioStreamWrapper.component.scss";

interface AudioStreamProps extends BaseComponentWithChildrenProps {
  id: string;
  customLeftBadge?: ReactNode;
  displayName: string;
  primaryAction: ButtonProps;
  previewMode?: boolean;
  selected?: boolean;
  selectionTheme?: AWSelectionTheme;
  onSelect?: (id: string) => void;
}

export enum AWSelectionTheme {
  citrus = "citrus",
  teal = "teal",
}

export function AudioStreamWrapper(props: AudioStreamProps) {
  const {
    id,
    customLeftBadge,
    displayName,
    selected = false,
    selectionTheme = AWSelectionTheme.citrus,
    primaryAction,
    previewMode = false,
    onSelect,
  } = props;

  const [_selected, setSelected] = useState(selected);

  useEffect(
    function changeSelected() {
      setSelected(selected);
    },
    [selected]
  );

  const onSelectWrapper = useCallback(() => {
    if (onSelect) {
      setSelected((_selected) => !_selected);
      onSelect?.(id);
    }
  }, [id, onSelect]);

  const selectableClassName = getClassName("asw-inner", [
    {
      condition: _selected,
      trueClassName: `asw-inner--selected asw-inner--selected-${selectionTheme}`,
    },
  ]);

  const innerBadgeClassName = getClassName("asw-inner-left-badge", [
    {
      condition: previewMode,
      trueClassName: `asw-inner-left-badge-previewMode`,
    },
  ]);

  const primaryActionClick = useCallback(
    (event) => {
      event.stopPropagation();

      primaryAction?.onClick?.(event);
    },
    [primaryAction]
  );

  const { toggle: showPrimaryAction, toggledClassName: primaryActionClassName } = useClassToggle({
    className: "asw-inner-right-btn",
  });

  return (
    <div
      className={"asw"}
      onMouseEnter={() => showPrimaryAction(true)}
      onMouseLeave={() => showPrimaryAction(false)}
      onClick={onSelectWrapper}
    >
      <div className={selectableClassName}>
        <div className={"asw-inner-left"}>
          <div className={innerBadgeClassName}>
            {customLeftBadge} {displayName}
          </div>
        </div>
        <div className={"asw-inner-right"}>
          <>
            {primaryAction && (
              <Button
                theme={ButtonTheme.White}
                size={ButtonSize.Small}
                {...primaryAction}
                className={primaryActionClassName}
                onClick={primaryActionClick}
                icon={primaryAction?.icon}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
}
