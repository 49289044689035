import { ButtonTheme } from "@q4/nimbus-ui";
import { EventAssetType } from "../../hooks/assets/assets.hook.definition";
import { CompanyBrandingAssetType } from "../../views/companyBranding/companyBranding.definition";
import { EventMeetingMaterialType } from "../../hooks/eventMaterials/eventMaterials.hook.definition";

export interface FileUploaderProps {
  id?: string;
  uploadFile(fileType: EventAssetType | EventMeetingMaterialType | string, file: File): void;
  acceptableFileSize?: number;
  acceptableFileTypes?: string[];
  acceptableFilesInfo: string;
  fileUrl?: string;
  showPreview?: boolean;
  className?: string;
  required?: boolean;
  onChange?: () => void;
  placeholderImage?: string;
  uploadFailedError?: string;
  buttonTheme?: ButtonTheme;
  disabled?: boolean;
  loading?: boolean;
}

export interface FileUploaderBodyProps {
  acceptableFilesInfo: string;
  placeholderImage?: string;
  error: string;
  buttonTheme: ButtonTheme;
}

export enum FileUploaderClassNames {
  Base = "file-uploader",
  Body = "file-uploader-body",
  Action = "file-uploader-body_action",
  Note = "file-uploader-body_note",
  ErrorText = "file-uploader-body_error-text",
  Error = "file-uploader-error",
  UploaderPlaceholderImage = "file-uploader_placeholder-image",
  Disclaimer = "file-uploader-body_disclaimer",
}

export const FILE_SIZES = { KILOBYTES: 1024, MEGABYTES: 1024 ** 2, GIGABYTES: 1024 ** 3 };

export const MAX_FILE_SIZE = {
  [EventMeetingMaterialType.DOCUMENT]: 25 * FILE_SIZES.MEGABYTES,
  [EventMeetingMaterialType.SLIDESHOW]: 25 * FILE_SIZES.MEGABYTES,
  [EventMeetingMaterialType.SPREADSHEET]: 25 * FILE_SIZES.MEGABYTES,
  [EventAssetType.VIDEO]: FILE_SIZES.GIGABYTES,
  [EventAssetType.AUDIO]: 250 * FILE_SIZES.MEGABYTES,
  [CompanyBrandingAssetType.FONT]: 10 * FILE_SIZES.MEGABYTES,
  [CompanyBrandingAssetType.FAVICON]: 32 * FILE_SIZES.KILOBYTES,
};
