import { useMemo } from "react";

export const useCarouselStreamToolTips = ({ participant, stream, audioOnly }) => {
  const participantMediaAvailable = useMemo(
    () =>
      !!(
        participant?.mediaSettings?.audioInputsReady || participant?.mediaSettings?.videoInputsReady
      ),
    [participant]
  );

  // tooltip msg on subscriber video hover
  const selectionTooltipMsg = useMemo(() => {
    if (stream?.isAssetStream || stream?.isScreenShare || stream?.isPSTNStream) return;

    if (stream?.isTempStream && participantMediaAvailable) {
      return "Cannot be selected while initializing";
    }

    const defaultMessage = "Cannot be selected with no audio or video source";
    const defaultAudioOnlyMessage = "Cannot be selected with no audio source";

    if (audioOnly) {
      if (!participant?.mediaSettings?.audioInputsReady) {
        return defaultAudioOnlyMessage;
      }
    } else {
      if (
        !participant?.mediaSettings?.audioInputsReady &&
        !participant?.mediaSettings?.videoInputsReady
      ) {
        return defaultMessage;
      }
    }
  }, [
    stream?.isAssetStream,
    stream?.isScreenShare,
    stream?.isPSTNStream,
    stream?.isTempStream,
    participantMediaAvailable,
    audioOnly,
    participant?.mediaSettings?.audioInputsReady,
    participant?.mediaSettings?.videoInputsReady,
  ]);

  return { selectionTooltipMsg };
};
