import React, { createContext, FunctionComponent } from "react";
import useParticipants from "../../hooks/participants/participants.hook";
import { ParticipantContextModel, ParticipantContextProps } from "./participants.definition";
import useParticipantsWaitingRoom from "../../hooks/participantsWaitingRoom/participantsWaitingRoom.hook";
import {
  useReceiveUserMessage,
  UserMessageTopics,
  useSendUserMessage,
} from "../../hooks/userMessaging/userMessaging.hook";

const ParticipantContext = createContext<Partial<ParticipantContextModel>>({});

export const ParticipantProvider: FunctionComponent<ParticipantContextProps> = (props) => {
  const { children, meetingId, skip = false } = props;
  const participantsService = useParticipants({ meetingId, skip });
  const participantsWaitingRoomService = useParticipantsWaitingRoom({ meetingId });

  const [sendUserMessage] = useSendUserMessage({});

  async function messageParticipant(userId: string, topic: UserMessageTopics, message: unknown) {
    try {
      await sendUserMessage({
        variables: {
          userId,
          topic,
          message,
        },
      });
    } catch (e) {
      console.error(`Unable to message ${userId} on topic ${topic}`);
    }
  }

  return (
    <ParticipantContext.Provider
      value={{
        participantsService,
        participantsWaitingRoomService,
        userMessagingService: {
          message: messageParticipant,
          subscribe: useReceiveUserMessage,
        },
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
};

export default ParticipantContext;
