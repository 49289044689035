import { getClassName } from "@q4/nimbus-ui";
import React, { FC, memo, useMemo } from "react";
import "./poweredByLogo.component.scss";
import { PoweredByLogoClassNames, PoweredByLogoProps } from "./poweredByLogo.definition";
import { useRebrandFlag } from "../../hooks/featureFlagHooks/useRebrandFlag.hook";
import PoweredByLinkWrapper from "./poweredByLinkWrapper";

const PoweredByLogo: FC<PoweredByLogoProps> = ({ className, clickable, inline = true }) => {
  const baseClassName = useMemo(
    () =>
      getClassName(PoweredByLogoClassNames.Base, [
        {
          condition: inline,
          trueClassName: `${PoweredByLogoClassNames.Base}--inline`,
        },
      ]),
    [inline]
  );

  const { enabled, logoClassName: _logoClassName } = useRebrandFlag();

  const logoClassName = useMemo(
    () => `${_logoClassName} ${enabled ? PoweredByLogoClassNames.IconV2 : ""}`,
    [_logoClassName, enabled]
  );

  return (
    <div className={`${baseClassName} ${className}`}>
      <PoweredByLinkWrapper clickable={clickable}>
        Powered by
        {inline && <span className={PoweredByLogoClassNames.Separator}>|</span>}
        <i className={`${baseClassName}--icon ${logoClassName}`} />
      </PoweredByLinkWrapper>
    </div>
  );
};

export default memo(PoweredByLogo);
