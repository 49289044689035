import { useCallback, useEffect, useRef } from "react";
import moment from "moment";

export enum TimeUnit {
  hour = "hour",
  minute = "minute",
  second = "second",
  millisecond = "millisecond",
}

interface HeartBeatProps {
  callback: (index: number, next: () => void) => void;
  startIndex?: number;
  startTime?: number;
  intervals?: Array<number>;
  step?: number;
  timeUnit?: TimeUnit;
}

export function useHeartBeat(props: HeartBeatProps) {
  const {
    callback,
    startTime = 1,
    startIndex = 0,
    intervals,
    step = 1,
    timeUnit = TimeUnit.minute,
  } = props;
  const heartBeatTimeoutRef = useRef<NodeJS.Timeout>();

  const clearHeartBeat = useCallback(() => {
    if (heartBeatTimeoutRef.current) {
      clearTimeout(heartBeatTimeoutRef.current);
      heartBeatTimeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    return clearHeartBeat;
  }, [clearHeartBeat]);

  const onHeartBeatTimeout = useCallback(
    (index: number): void => {
      const nextIndex = index + step;
      callback(nextIndex, () => {
        if (!intervals) {
          heartBeatTimeoutRef.current = scheduleHeartBeat(nextIndex, step);
          return;
        }
        if (!intervals[nextIndex]) return clearHeartBeat();
        const minutes = intervals[nextIndex] - (intervals[nextIndex - 1] ?? 0);
        heartBeatTimeoutRef.current = scheduleHeartBeat(nextIndex, minutes);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, clearHeartBeat, intervals, step]
  );

  const scheduleHeartBeat = useCallback(
    (index = startIndex, delay = startTime): NodeJS.Timeout => {
      const delayTime = moment.duration(delay, timeUnit).asMilliseconds();
      return setTimeout(() => {
        onHeartBeatTimeout(index);
      }, delayTime);
    },
    [onHeartBeatTimeout, startIndex, startTime, timeUnit]
  );

  return {
    clearHeartBeat,
    scheduleHeartBeat,
  };
}
