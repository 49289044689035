import { createContext, FunctionComponent, useContext, useMemo } from "react";
import { AdminConsoleContext, EventContext } from "..";
import { Asset } from "../../hooks/assets/assets.hook.definition";
import { AssetUploads, ContentType } from "../../hooks/assetUploads/assetUploads.definition";
import useControlAsset, { AssetStats } from "../../hooks/controlAsset/controlAsset.hook";
import useControlSlides from "../../hooks/controlSlides/useControlSlides.hook";
import {
  ControlSlidesService,
  ControlSlidesState,
} from "../../hooks/controlSlides/useControlSlides.hook.definition";
import { OTSessionType } from "../../opentok-react/types";
import {
  CurrentController,
  TakeControlService,
} from "../../views/adminConsole/hooks/takeControl/takeControl.definition";
import { useTakeControlHook } from "../../views/adminConsole/hooks/takeControl/takeControl.hook";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";

interface EventAssetsContextState {
  onSeek: (stream: BroadcastStream) => void;
  onStop: (stream: BroadcastStream) => void;
  onStart: (stream: BroadcastStream) => void;
  onVolume: (stream: BroadcastStream, volume: number) => void;
  setAudio: (stream: BroadcastStream, hasAudio: boolean) => void;
  assetsState: Array<AssetStats>;
  eventAssets: Array<Asset | AssetUploads>;
  participantInControl: CurrentController;
  takeControlService: TakeControlService;
  controlSlidesState: ControlSlidesState;
  controlSlidesService: ControlSlidesService;
}

const EventAssetsContext = createContext<Partial<EventAssetsContextState>>({});

interface EventAssetsContextProps {
  session: OTSessionType;
}

export const EventAssetProvider: FunctionComponent<EventAssetsContextProps> = (props) => {
  const { children, session } = props;

  const {
    streams,
    carouselSelection: { staged },
    trackStreamsService,
  } = useContext(AdminConsoleContext);
  const { currentEvent: event } = useContext(EventContext);

  const assets = (event.assetUploads || []).filter(
    (asset) => asset.contentType === ContentType.AUDIO_VIDEO
  );

  const { areAssetsAvailable: assetsAvailable } = trackStreamsService;

  const slideAssets = useMemo(() => {
    return (event.assetUploads || []).filter((asset) => asset.contentType === ContentType.SLIDES);
  }, [event.assetUploads]);

  const eventAssetsService = useControlAsset({
    streams,
    staged,
    assets,
    session,
    assetsAvailable,
  });

  const { participantInControl, takeControlService } = useTakeControlHook({
    meetingId: event?.meetingId,
  });

  const eventSlidesService = useControlSlides({
    streams,
    session,
    staged,
    assets: slideAssets,
    canControlSlides: participantInControl.isLocallyControlled,
    assetsAvailable,
  });

  const { controlSlidesState, controlSlidesService } = eventSlidesService;

  return (
    <EventAssetsContext.Provider
      value={{
        ...eventAssetsService,
        controlSlidesState,
        controlSlidesService,
        participantInControl,
        takeControlService,
      }}
    >
      {children}
    </EventAssetsContext.Provider>
  );
};

export default EventAssetsContext;
