import { useContext, useRef } from "react";
import { Q4Snowplow } from "@q4/snowplow";
import { UserRoles } from "../../configurations/userRolesPermissions.configuration";

import { IUseAdminConsoleAnalytics } from "./adminConsoleAnalytics.hook.definition";
import { EventModelPopulated } from "./../events/events.hook.definitions";
import { Participant } from "./../participants/participants.hook.definition";

import {
  BroadcastInteractionType,
  Q4_COMPANY_ID,
  Q4_ORGANIZATION_ID,
  SnowplowSchema,
} from "../../definitions/snowplow.definition";
import { Domain } from "../../configurations/routes/route.domains";
import { AttendeeOriginType } from "../../services/attendees/attendees.model";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";

export const useAdminConsoleAnalytics = (): IUseAdminConsoleAnalytics => {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const hasSentQ4PageView = useRef(false);

  const processSnowplowPageView = (
    currentParticipant: Partial<Participant>,
    eventDetails: EventModelPopulated
  ) => {
    if (!featureFlags[FeatureFlagKeys.SnowplowCollector] || !Q4Snowplow.checkTracker()) return;

    if (eventDetails?.meetingId) {
      if (currentParticipant?.email) {
        Q4Snowplow.setUserEmail(currentParticipant?.email);
        Q4Snowplow.setUserContext({
          type: AttendeeOriginType.EP_AUTH0,
          clientId:
            currentParticipant?.platformRole === UserRoles.Q4_ADMIN
              ? Q4_COMPANY_ID
              : eventDetails?.companyId,
          organizationId:
            currentParticipant?.platformRole === UserRoles.Q4_ADMIN
              ? Q4_ORGANIZATION_ID
              : eventDetails?.organizationId,
        });
      }

      if (eventDetails?.status)
        Q4Snowplow.setEventContext({
          eventStatus: eventDetails.status,
          meetingId: eventDetails.meetingId,
        });

      Q4Snowplow.setProductContext({
        productDomain: Domain.Event,
        productSubDomain: "LiveBroadcast",
        clientId: eventDetails?.companyId,
        organizationId: eventDetails?.organizationId,
      });

      if (!hasSentQ4PageView.current) {
        Q4Snowplow.trackPageView();
        hasSentQ4PageView.current = true;
      }
    }
  };

  const trackBroadcastActivity = (
    schema: SnowplowSchema,
    broadcastInteractionType: BroadcastInteractionType
  ) => {
    if (featureFlags[FeatureFlagKeys.SnowplowCollector] && Q4Snowplow.checkTracker())
      Q4Snowplow.trackEvent(schema, { broadcastInteractionType });
  };

  return {
    processSnowplowPageView,
    trackBroadcastActivity,
  };
};
