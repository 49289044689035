import {
  AutomationListId,
  BaseComponentProps,
  ButtonIdModel,
  ButtonListId,
  ButtonProps,
  ForwardedRef,
  IdModelBase,
  isNullOrWhiteSpace,
  PopoverMenuButtonProps,
  TextIdModel,
} from "@q4/nimbus-ui";
import { MouseEventHandler } from "react";
import { RegistrationField } from "../../../../services/event/registrationFieldGql.model";

export interface CustomRegistrationCardProps extends BaseComponentProps {
  registrationField?: RegistrationField;
  title: string;
  subheading?: string;
  ref?: ForwardedRef<HTMLDivElement>;
  actionItems?: PopoverMenuButtonProps[];
  showSubActions?: boolean;
  subActions?: ButtonProps[];
  isSelected?: boolean;
  onSelect?: MouseEventHandler<HTMLDivElement>;
}

export enum CustomRegistrationCardClassName {
  Base = "custom-registration-card",
  BaseWithSelectedModifier = "custom-registration-card--selected",
  Title = "custom-registration-card_text-wrapper_title",
  Subheading = "custom-registration-card_text-wrapper_subheading",
  Utility = "custom-registration-card_actions-wrapper_utility",
  TextWrapper = "custom-registration-card_text-wrapper",
  ActionsWrapper = "custom-registration-card_actions-wrapper",
  SubActionsWrapper = "custom-registration-card_subactions-wrapper",
}

export class CustomRegistrationCardListId extends AutomationListId<CustomRegistrationCardIdModel> {
  constructor(parentId: string, prefix = "Card-", postfix?: string) {
    super(parentId, prefix, CustomRegistrationCardIdModel, postfix);
  }
}

export class CustomRegistrationCardIdModel extends IdModelBase {
  title: TextIdModel;
  subheading: TextIdModel;
  utility: ButtonIdModel;
  delete: ButtonIdModel;
  reorder: ButtonIdModel;
  subActionButtons: ButtonListId;
  edit: ButtonIdModel;

  constructor(id: string, index?: number | string) {
    super(id, index);
    if (isNullOrWhiteSpace(this.id)) return;

    this.title = new TextIdModel(`${this.id}TitleText`);
    this.subheading = new TextIdModel(`${this.id}SubheadingText`);
    this.utility = new ButtonIdModel(`${this.id}UtilityButton`);
    this.delete = new ButtonIdModel(`${this.id}DeleteButton`);
    this.subActionButtons = new ButtonListId(`${this.id}SubActionButtons`);
    this.reorder = new ButtonIdModel(`${this.id}ReorderButton`);
    this.edit = new ButtonIdModel(`${this.id}EditButton`);
  }
}
