import { useState, useEffect } from "react";
import { omitBy, isNil, isObject, isFunction } from "lodash";

import { PublisherHookProps, PublisherHookModel } from "./publisher.definition";
import { OTPublisherProps } from "../../components/OTPublisher/OTPublisher.definition";

import { OTPublisherType } from "../../types/index";
import { promiseTimeout, RETRY_DEFAULT_DELAY } from "../../../utils";

export default function usePublisherHook(props: PublisherHookProps): PublisherHookModel {
  const OT = (window as any).OT;

  const [publisher, setPublisher] = useState<OTPublisherType>();
  const [inRetry, setInRetry] = useState(false);

  // Call onPublisherCreated callback every time publisher created
  useEffect(() => {
    if (publisher) {
      props.onPublisherCreated && props.onPublisherCreated(publisher);
    }
  }, [publisher]); // eslint-disable-line react-hooks/exhaustive-deps

  function newPublisher(properties, throwError = true): Promise<void> {
    return new Promise((resolve, reject) => {
      const OTPublisher = OT.initPublisher(document.body, properties, (err) => {
        if (err) {
          OTPublisher?.destroy(); // clean up failed publisher
          if (throwError) reject(err);
          else resolve();
        } else {
          resolve(OTPublisher);
        }
      });
    });
  }

  function _getPublisherProps(initPublisherProperties) {
    let properties: Partial<{
      audioSource: any;
      insertDefaultUI: boolean;
      eventHandlers: { videoElementCreated: Function };
      insertMode: string;
    }> = initPublisherProperties;
    properties.insertDefaultUI = true;
    properties.insertMode = "append";

    if (!properties.audioSource) properties.audioSource = null;

    return properties;
  }

  async function createPublisher(publisherProps: OTPublisherProps) {
    const { initPublisherProperties, onCreatePublisherError, onPublish, onPublisherInit } =
      publisherProps;

    if (inRetry) return;

    /**
     * Specify a "videoElementCreated" event handler to append video. Enables consumer to move video element
     * without destroying the stream
     */
    let properties = _getPublisherProps(initPublisherProperties);

    // Initialize Publisher
    try {
      const maxRetryAttempts = publisherProps.maxRetryAttempts || 3;

      // Retry publisher if fails
      setInRetry(true);
      let OTPublisher: any;
      for (let i = 0; i < maxRetryAttempts; i++) {
        if (!OTPublisher) {
          await promiseTimeout(RETRY_DEFAULT_DELAY * i);
          OTPublisher = await newPublisher(properties, i === maxRetryAttempts - 1);
        }
      }
      setInRetry(false);

      onPublisherInit && isFunction(onPublisherInit) && onPublisherInit();

      // Register Publisher events
      OTPublisher.on("streamCreated", function (event) {
        event.stream.isLocalPublisher = true;
        props?.onStreamCreated(event?.stream);
      });
      OTPublisher.on(omitBy(properties.eventHandlers, isNil));

      OTPublisher.on("accessDenied", (event) => {
        console.error("Publisher:accessDenied", JSON.stringify(event));
      });

      // Publish Publisher to session
      const { session } = props;
      if (session?.connection) {
        publishToSession(OTPublisher, onPublish, onCreatePublisherError);
      } else {
        session.once(
          "sessionConnected",
          publishToSession(OTPublisher, onPublish, onCreatePublisherError)
        );
      }

      setPublisher(OTPublisher);
    } catch (err) {
      console.error("Failed to create publisher: ", err);
      onCreatePublisherError && isFunction(onCreatePublisherError) && onCreatePublisherError(err);
    }
  }

  function destroyPublisher(publisherProps: OTPublisherProps): void {
    if (publisher) {
      const { eventHandlers } = publisherProps;
      if (eventHandlers && isObject(eventHandlers)) {
        publisher.once("destroyed", () => {
          publisher.off();
        });
      }

      props?.session?.unpublish(publisher);
      publisher.destroy();
    }
  }

  function publishToSession(
    publisher: OTPublisherType,
    onPublish?: Function,
    onError?: Function
  ): void {
    const { session } = props;

    session.publish(publisher, (err) => {
      if (err) {
        onError && isFunction(onError) && onError(err);
      } else {
        onPublish && isFunction(onPublish) && onPublish();
      }
    });
  }

  return {
    publisher,
    createPublisher,
    destroyPublisher,
  };
}
