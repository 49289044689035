import React, { useCallback, useContext, useMemo } from "react";
import { isNil } from "lodash";

import Carousel from "../carousel/Carousel.component";

import { Connection } from "../../../../opentok-react/types/Connection.type";
import {
  EventPermission,
  EventPermissionDictionary,
} from "../../../../configurations/userRolesPermissions.configuration";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";

import SubscriberVideo from "./SubscriberVideo.component";
import { AdminConsoleContext, ParticipantContext } from "../../../../contexts";
import { SelectionModel } from "../../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";

export interface SubscribersProps extends BaseComponentWithChildrenProps {
  broadcasting?: boolean;
  mediaStaged?: boolean;
  screenStaged?: boolean;
  streams: BroadcastStream[];
  setSpeakerAudio: (connection: Connection, enableAudio: boolean, isPSTN?: boolean) => void;
  userEventPermissions?: EventPermissionDictionary;
  subscribeToAudio: boolean;
  stageSelection?: (selection: SelectionModel) => void;
  mediaOnly?: boolean;
}

export enum SliderClassNames {
  Base = "slider",
  Container = "slider-slide-container",
  Label = "slider-slide-label",
  Empty = "slider-empty-content",
}

const slidesToShowLimit = 6;
const slidesToScroll = 3;

export default function Subscribers(props: SubscribersProps): JSX.Element {
  const {
    streams,
    broadcasting,
    mediaStaged,
    setSpeakerAudio,
    userEventPermissions = {},
    stageSelection,
    mediaOnly,
  } = props;

  const { carouselSelectionService } = useContext(AdminConsoleContext);
  const {
    participantsService: { participantsByConnectionId },
  } = useContext(ParticipantContext);

  const selectionDisabled = useMemo(
    () => isNil(userEventPermissions[EventPermission.eventManageStreams]),
    [userEventPermissions]
  );

  const canSetAudio = useCallback(
    (stream: BroadcastStream) => {
      if (stream.isLocalPublisher) {
        return broadcasting
          ? mediaStaged && userEventPermissions[EventPermission.eventLiveManageAudio]
          : userEventPermissions[EventPermission.eventPreLiveManageAudio] ||
              userEventPermissions[EventPermission.eventPostLiveManageAudio];
      }

      return (
        (!broadcasting || carouselSelectionService?.isStaged(stream)) &&
        userEventPermissions[EventPermission.eventManageOthers]
      );
    },
    [broadcasting, carouselSelectionService, mediaStaged, userEventPermissions]
  );

  const sliderClassName = useMemo(
    () => `${SliderClassNames.Base}--${streams?.length <= slidesToShowLimit ? "center" : "start"}`,
    [streams?.length]
  );

  const hasStreams = useMemo(() => streams?.length > 0, [streams?.length]);

  return (
    <Carousel
      className={sliderClassName}
      slidesToShowLimit={slidesToShowLimit}
      slidesToScroll={slidesToScroll}
    >
      {streams?.map((stream) => (
        <SubscriberVideo
          stream={stream}
          participant={participantsByConnectionId?.[stream?.connection?.connectionId]}
          broadcasting={broadcasting}
          userEventPermissions={userEventPermissions}
          setSpeakerAudio={canSetAudio(stream) ? setSpeakerAudio : null}
          selectionDisabled={selectionDisabled}
          key={stream.id}
          stageSelection={stageSelection}
          mediaOnly={mediaOnly}
        />
      ))}
      {!hasStreams && <div className={SliderClassNames.Empty}> No Streams in selected group</div>}
    </Carousel>
  );
}
