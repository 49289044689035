import { AttendeeRegistrationFormData } from "./../../broadcastRegistration/broadcastRegistration.definition";
import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { ErrorModel } from "../../../../components/modal/modal.definition";
import { AttendeeEventRoles } from "../../../../configurations/userRolesPermissions.configuration";
import { ErrorMessage } from "../../../../services/api/api.definition";
import { Attendee, AttendeeOriginType } from "../../../../services/attendees/attendees.model";
import { EventType, RegistrationBranding } from "../../../../services/event/event.model";
import { PublicEventDetails } from "../../../../services/event/eventGql.model";
import { FormEditState } from "../eventRegistration.definition";

export enum EventRegistrationFormClassNames {
  FormLayout = "event-registration-form_layout",
  FormTitle = "event-registration-form_title",
  Form = "event-registration-form_field",
  Button = "event-registration-form_button",
  Label = "event-registration-form_label",
  Radio = "event-registration-form_radio",
  Tabs = "event-registration-form_tabs",
  BackButton = "event-registration-form_back_button",
  Pin = "event-registration-form_pin",
  ShowPin = "event-registration-form_show_pin",
  ForgotLink = "event-registration-form_forgot_link",
  Warning = "event-registration-form_warning",
  ContactUs = "event-registration-form_contact_us",
  CaptchaField = "event-registration-form_captcha_field",
  Captcha = "event-registration-form_captcha",
  Policies = "event-registration-form_policies",
  ReminderCheckbox = "event-registration-form_reminder-checkbox",
  InstitutionSection = "event-registration-form_institution-section",
  InstitutionSectionCaption = "event-registration-form_institution-section-caption",
  AlreadyRegisterLink = "event-registration-form_already-register-link",
}

export enum InvestorTypeNames {
  Individual = "individual",
  Enterprise = "enterprise",
  Institution = "institution",
  Security = "security",
  Company = "company",
  Other = "other",
}

export interface EventRegistrationFormProps {
  alreadyRegisteredSubmitError?: boolean;
  event?: PublicEventDetails;
  eventType?: EventType;
  loginAttempts?: number;
  isGuestAttendance?: boolean;
  isMiniPreview?: boolean;
  isPreview?: boolean;
  participantInfo?: Partial<Attendee>;
  roomDetails?: RegistrationBranding;
  showPinInput?: boolean;
  showPostEventRegistration?: boolean;
  showPreRegistrationConfirmation?: boolean;
  showAlreadyRegisteredForm?: boolean;
  submitAlreadyRegisteredFormLoading?: boolean;
  isQ4Guest?: boolean;
  showNewLayout?: boolean;
  attendeeRegistrationFormData?: AttendeeRegistrationFormData;
  setShowNewLayout?: Dispatch<SetStateAction<boolean>>;
  setRegistrationFormState?: Dispatch<SetStateAction<FormEditState>>;
  onClickAlreadyRegisteredSubmit?(email: string): void;
  onSubmit?: (form: FormEditState, originType: AttendeeOriginType) => Promise<ErrorMessage>;
  resetPin?: (controlNumber: string) => void;
  setShowPinInput?: (flag: boolean) => void;
  onClickPreRegistrationConfirmationWebcastLink?(): void;
}

export interface EventRegistrationPageTabs {
  value: AttendeeEventRoles;
  label: string;
  dataId: string;
}

const dataIdPrefix = "EventRegistration";

export const DATA_IDS = {
  CONTROL_NUMBER: `${dataIdPrefix}ControlNumber`,
  PIN: `${dataIdPrefix}Pin`,
  SHAREHOLDER_TAB: `${dataIdPrefix}ShareholderTab`,
  GUEST_TAB: `${dataIdPrefix}GuestTab`,
  FIRST_NAME: `${dataIdPrefix}FirstName`,
  LAST_NAME: `${dataIdPrefix}LastName`,
  COMPANY_EMAIL: `${dataIdPrefix}CompanyEmail`,
  COMPANY_NAME: `${dataIdPrefix}CompanyName`,
  TITLE_AT_COMPANY: `${dataIdPrefix}TitleAtCompany`,
  SEND_REMINDER_EMAIL: `${dataIdPrefix}SendReminderEmail`,
  REGISTER_BUTTON: `${dataIdPrefix}RegisterButton`,
  FORGOT_LINK: `${dataIdPrefix}ForgotLink`,
  SHOW_PIN: `${dataIdPrefix}ShowPin`,
  BACK_BTN: `${dataIdPrefix}BackBtn`,
  CONTACT_BTN: `${dataIdPrefix}ContactBtn`,
  CLOSE_MODAL_BTN: `${dataIdPrefix}CloseModalBtn`,
};

export enum ForgotSentStatus {
  Default = "default",
  Sent = "sent",
  Error = "error",
}

export class EventRegistrationFormErrorState {
  controlNumberError?: ErrorModel;
  pinError?: ErrorModel;
  firstNameError?: ErrorModel;
  lastNameError?: ErrorModel;
  emailError?: ErrorModel;
  companyNameError?: ErrorModel;

  constructor(state: EventRegistrationFormErrorState) {
    this.controlNumberError = state.controlNumberError;
    this.pinError = state.pinError;
    this.firstNameError = state.firstNameError;
    this.lastNameError = state.lastNameError;
    this.emailError = state.emailError;
    this.companyNameError = state.companyNameError;
  }
}

export enum EventRegistrationFormErrorMessage {
  nullEmailAddressMessage = "Email required",
  invalidFirstNameMessage = "First name required",
  invalidLastNameMessage = "Last name required",
  invalidCompanyNameMessage = "Company name required",
  invalidEmailMessage = "Valid email address required. Use the following format: name@domain.com, with no spaces between the letters.",
  invalidControlNumberErrorMessage = "Control numbers are 16 digits long. Please try again.",
  invalidBrPinErrorMessage = "Pin is not valid. Please try again.",
}
export const POLICY_RELEASE_DATE = "2020-12-21";

export class PageModel extends IdModelBase {
  formLayout: string;
  submitButton: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.formLayout = `${this.id}FormLayout`;
    this.submitButton = new ButtonIdModel(`${this.id}SubmitButton`);
  }
}

const PageId = "GuestRegistration";
export const GuestRegistrationIdModel = new PageModel(PageId);

export enum Q4LoginActions {
  Q4LoginStart = "q4-login-start",
  Q4LoginEnd = "q4-login-end",
}

export enum Q4LoginActionMessages {
  Q4LoginStart = "Q4 Login flow start",
  Q4LoginEnd = "Q4 Login flow end",
}
