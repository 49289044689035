import React, { FC, useContext } from "react";
import {
  DATA_IDS,
  PlaceholderLayoutClassNames,
  PlaceholderLayoutProps,
} from "./PlaceholderLayout.definition";
import { EventContext } from "../../../../contexts";

import PoweredByLogo from "../../../../components/q4PowerdByLogo/poweredByLogo.component";

import "./PlaceholderLayout.scss";
import { getClassName } from "@q4/nimbus-ui";

export const PlaceholderLayout: FC<PlaceholderLayoutProps> = function PlaceholderLayout(props) {
  const { id, message, className } = props;

  const innerClassName = getClassName(PlaceholderLayoutClassNames.INNER, [
    { condition: !!className, trueClassName: className },
  ]);

  const {
    currentEvent: { eventType },
  } = useContext(EventContext);

  return (
    <div
      id={id ?? DATA_IDS.PLACEHOLDER}
      className={PlaceholderLayoutClassNames.LAYOUT}
      data-id={DATA_IDS.PLACEHOLDER}
    >
      <div className={innerClassName}>
        <div className={PlaceholderLayoutClassNames.TEXT_CONTAINER}>
          <span>{message ? message : "No streams staged"}</span>
          <div className={PlaceholderLayoutClassNames.ICON_CONTAINER}>
            <PoweredByLogo eventType={eventType} />
          </div>
        </div>
      </div>
    </div>
  );
};
