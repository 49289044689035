export enum LoadingScreenClassName {
  Base = "ep-loading-screen",
  Content = "ep-loading-screen_content",
  Logo = "ep-loading-screen_logo",
  Dots = "ep-loading-screen_dots",
  LabelWrapper = "ep-loading-screen_label-wrapper",
  Label = "ep-loading-screen_label",
}

export interface LoadingScreenProps {
  q4Icon?: string;
}
