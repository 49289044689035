export enum UserRoles {
  ALL = "*", // not a real role, used for filtering
  EP_ATTENDEE_GUEST = "ep-attendee-guest",
  EP_ATTENDEE_ACTIVE = "ep-attendee-active",
  EP_ATTENDEE_SHAREHOLDER = "ep-attendee-shareholder",
  Q4_ADMIN = "q4-admin",
  COMPANY_ADMIN = "ep-company-admin",
  EP_EVENT_MANAGER = "ep-event-manager",
  EP_PLATFORM_HOST = "ep-platform-host",
  EP_EVENT_SUPPORT = "ep-event-support",
  EP_EVENT_SPEAKER = "ep-event-speaker",
  EP_EVENT_OBSERVER = "ep-event-observer",
  EP_EVENT_INSPECTOR = "ep-event-inspector",
  EP_EVENT_ADMINISTRATOR = "ep-event-administrator",
  EP_EVENT_HOST = "ep-event-host",
  EP_GUEST = "ep-guest",
  EP_WAITING_ROOM_PARTICIPANT = "ep-waiting-participant",
  EP_EXPERIENCE_COMPOSER = "ep-experience-composer",
}

export enum PlatformRoles {
  Q4_ADMIN = UserRoles.Q4_ADMIN,
  COMPANY_ADMIN = UserRoles.COMPANY_ADMIN,
  EP_EVENT_MANAGER = UserRoles.EP_EVENT_MANAGER,
  EP_PLATFORM_HOST = UserRoles.EP_PLATFORM_HOST,
  EP_GUEST = UserRoles.EP_GUEST,
}

export enum UserEventRole {
  EP_EVENT_ADMINISTRATOR = UserRoles.EP_EVENT_ADMINISTRATOR,
  EP_EVENT_SUPPORT = UserRoles.EP_EVENT_SUPPORT,
  EP_EVENT_SPEAKER = UserRoles.EP_EVENT_SPEAKER,
  EP_EVENT_OBSERVER = UserRoles.EP_EVENT_OBSERVER,
  EP_EVENT_INSPECTOR = UserRoles.EP_EVENT_INSPECTOR,
  EP_EVENT_HOST = UserRoles.EP_EVENT_HOST,
  EP_EVENT_COMPOSER = UserRoles.EP_EXPERIENCE_COMPOSER,
}

export enum UserWaitingRoomRoles {
  EP_WAITING_ROOM_PARTICIPANT = UserRoles.EP_WAITING_ROOM_PARTICIPANT,
}

export enum AttendeeEventRoles {
  SHAREHOLDER = UserRoles.EP_ATTENDEE_SHAREHOLDER,
  ACTIVE = UserRoles.EP_ATTENDEE_ACTIVE,
  GUEST = UserRoles.EP_ATTENDEE_GUEST,
}

export enum AssetEventRoles {
  ASSET = "ep-event-assets",
}

export const UserRoleNiceNameMapping = {
  "q4-admin": "Q4 Admin",
  "ep-company-admin": "Company Admin",
  "ep-event-manager": "Event Manager",
  "ep-event-administrator": "Event Manager",
  "ep-platform-host": "Q4 Platform Host",
  "ep-guest": "Guest",
  "ep-event-observer": "Board Observer",
  "ep-event-inspector": "Inspector",
  "ep-event-speaker": "Speaker",
  "ep-event-support": "Company Support",
  "ep-event-host": "Host",
};

export enum UserPermissions {
  ALL = "*",
  ATTENDEE_MANAGE_ATTENDEE = "attendee:manage:attendee",
  END_BROADCAST = "end:broadcast",
  MANAGE_PLATFORM_VERSIONS = "platform:manage:version-bundles",
  START_BROADCAST = "start:broadcast",
  UPLOAD_HELPCENTER_ZIP = "platform:upload:helpcenter",
  VIEW_PLATFORM_VERSIONS = "platform:view:version-bundles",

  /******* Users *******/
  READ_USERS = "platform:read:users",
  READ_TENANT_USERS = "platform:read:tenant:users",
  READ_EVENT_TENANT_USERS = "platform:read:event:tenant:users",
  MANAGE_USERS = "platform:manage:users",
  MANAGE_TENANT_USERS = "platform:manage:tenant:users",
  DELETE_COMPANY_USERS = "platform:delete:company:users",
  DELETE_USERS = "platform:delete:users",
  SUSPEND_USERS = "platform:suspend:users",
  SUSPEND_TENANT_USERS = "platform:suspend:tenant:users",
  LOCK_USERS = "platform:lock:users",
  LOCK_TENANT_USERS = "platform:lock:tenant:users",
  INVITE_USERS = "platform:invite:users",
  INVITE_TENANT_USERS = "platform:invite:tenant:users",

  /******* Companies *******/
  CREATE_COMPANIES = "platform:create:companies",
  VIEW_ALL_COMPANIES = "platform:view:companies",
  VIEW_TENANT_COMPANIES = "platform:view:tenant:companies",
  MANAGE_COMPANIES = "platform:manage:companies",
  MANAGE_TENANT_COMPANIES = "platform:manage:tenant:companies",
  ARCHIVE_COMPANIES = "platform:archive:companies",
  ARCHIVE_TENANT_COMPANIES = "platform:archive:tenant:companies",

  /******* Events *******/
  VIEW_ALL_EVENTS = "platform:view:all:events",
  VIEW_TENANT_EVENTS = "platform:view:tenant:events",
  CREATE_EVENTS = "platform:create:events",
  CREATE_TENANT_EVENTS = "platform:create:tenant:events",
  MANAGE_EVENTS = "platform:manage:events",
  MANAGE_TENANT_EVENTS = "platform:manage:tenant:events",
  MANAGE_PRE_EVENT_QUESTIONS = "platform:manage:pre:event:questions",
}

export enum EventPermission {
  eventBasePermission = "event:base:permission",
  eventDebugUser = "event:debug:user",
  eventManageStreams = "event:manage:streams",
  eventManageOthers = "event:manage:users",
  eventRenameUsers = "event:rename:users",
  eventManageBroadcastStatus = "event:manage:broadcaststatus",
  eventInviteUsers = "event:invite:users",
  eventPreLivePublishSelf = "event:prelive:publish:self",
  eventLivePublishSelf = "event:live:publish:self",
  eventPostLivePublishSelf = "event:postlive:publish:self",
  eventPreLiveManageAudio = "event:prelive:manage:audio",
  eventLiveManageAudio = "event:live:manage:audio",
  eventPostLiveManageAudio = "event:postlive:manage:audio",
  eventManageChat = "event:manage:chat",
  eventManageMeetingStatus = "event:manage:meetingstatus",
  eventViewAttendees = "event:view:attendees",
  eventStartMeeting = "event:start:meeting",
  eventEndEvent = "event:end:event",
  eventRunPrecall = "event:run:precall",
  eventManageQuestions = "event:manage:questions",
  eventAnswerQuestions = "event:answer:questions",
  eventRejectQuestions = "event:reject:questions",
  eventNotifyQuestions = "event:notify:questions",
  eventQueueQuestions = "event:queue:questions",
  eventUpdategroupQuestions = "event:updategroup:questions",
  eventReorderQuestions = "event:reorder:questions",
  eventViewQuestions = "event:view:questions",
  eventViewQuestionsQueued = "event:view:questions:queued",
  eventViewQuestionsRejected = "event:view:questions:rejected",
  eventViewQuestionsReceived = "event:view:questions:received",
  eventViewQuestionsAnswered = "event:view:questions:answered",
  eventAccessQuestions = "event:access:questions",
}

export type EventPermissionDictionary = Partial<Record<EventPermission, boolean>>;
