import { gql } from "@apollo/client";
import { Attendee, AttendeeType } from "../../services/attendees/attendees.model";
import { AttendeeReportType } from "../../views/postEventReport/components/attendees/attendeeReport.component.definition";
import { AttendeeMeetingMaterials, AttendeeSlides } from "../assetUploads/assetUploads.definition";

export interface AttendeeJoinedStatusSubVars {
  meetingId: number;
}

export interface AttendeeJoinedStatusSubData {
  onAttendeeJoined: Attendee;
}

export interface AttendeeLeftStatusSubVars {
  meetingId: number;
}

export interface AttendeeLeftStatusSubData {
  onAttendeeLeft: Attendee;
}

export interface AttendeesCountData {
  attendeesCount: number;
}

export interface AttendeesCountVars {
  meetingId: number;
  eventEnd?: Date;
  attendeeType?: AttendeeType;
  attendeeFilter?: string;
  countType?: AttendeeReportType;
  live?: boolean;
}

export interface GenerateEventAttendeesAttendanceReportData {
  generateEventAttendeesAttendanceReport: unknown;
}
export interface GenerateEventAttendeesAttendanceReportVars {
  meetingId: number;
  eventId: string;
  attendanceType: AttendeeReportType;
  eventEndDate: Date;
}

export interface AttestationFormDetails {
  firstName: string;
  lastName: string;
  address: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface AttendeesCountQueryHookProps {
  meetingId: number;
  eventEnd?: string;
  live: boolean;
  countType?: AttendeeReportType;
}

export const SAVE_ATTENDEE_ATTESTATION_FORM_DETAILS_MUTATION = gql`
  mutation UPDATE_ATTENDEE_ATTESTATION_FORM_COMPLETION_STATUS_MUTATION(
    $meetingId: Int!
    $email: String!
    $hasCompletedAttestationForm: Boolean!
    $firstName: String!
    $lastName: String!
    $address: String!
    $state: String!
    $country: String!
    $postalCode: String!
  ) {
    saveAttendeeAttestationFormDetails(
      input: {
        email: $email
        meetingId: $meetingId
        hasCompletedAttestationForm: $hasCompletedAttestationForm
        firstName: $firstName
        lastName: $lastName
        address: $address
        state: $state
        country: $country
        postalCode: $postalCode
      }
    ) {
      id
      meetingId
      email
      firstName
      lastName
      hasCompletedAttestationForm
      attestationFormDetails {
        firstName
        lastName
        address
        state
        country
        postalCode
      }
    }
  }
`;

export interface GetAttendeesData {
  attendee: Attendee[];
}
export interface GetAttendeesVariables {
  meetingId: number;
  attendeeFilter?: string;
  page?: number;
  limit?: number;
}

export const ATTENDEES_QUERY = gql`
  query ATTENDEES_QUERY($meetingId: Int, $attendeeFilter: String, $page: Int, $limit: Int) {
    attendees(meetingId: $meetingId, attendeeFilter: $attendeeFilter, page: $page, limit: $limit) {
      items {
        id
        meetingId
        email
        firstName
        lastName
        attendeeType
        wsConnectionId
        hasCompletedAttestationForm
        type
      }
      count
    }
  }
`;

export interface GetAttendeeData {
  attendee: Attendee;
}

export interface GetAttendeeVariables {
  meetingId: number;
  email: string;
}

export const ATTENDEE_QUERY = gql`
  query ATTENDEE_QUERY($meetingId: Int, $email: String) {
    attendee(meetingId: $meetingId, email: $email) {
      id
      meetingId
      email
      firstName
      lastName
      hasCompletedAttestationForm
      type
    }
  }
`;

export const ATTENDEES_COUNT_QUERY = gql`
  query ATTENDEES_COUNT_QUERY(
    $meetingId: Int
    $eventEnd: String
    $countType: AttendeeReportType
    $live: Boolean
  ) {
    attendeesCount(meetingId: $meetingId, eventEnd: $eventEnd, countType: $countType, live: $live)
  }
`;

export const ATTENDEES_LEFT_SUBSCRIPTION = gql`
  subscription ATTENDEES_LEFT_SUBSCRIPTION($meetingId: Int) {
    onAttendeeLeft(meetingId: $meetingId) {
      id
      meetingId
      email
      firstName
      lastName
    }
  }
`;

export const ATTENDEES_JOINED_SUBSCRIPTION = gql`
  subscription ATTENDEES_JOINED_SUBSCRIPTION($meetingId: Int) {
    onAttendeeJoined(meetingId: $meetingId) {
      id
      meetingId
      email
      firstName
      lastName
    }
  }
`;

export const GENERATE_EVENT_ATTENDEE_ATTENDANCE_REPORT = gql`
  mutation GENERATE_EVENT_ATTENDEE_ATTENDANCE_REPORT(
    $eventId: String
    $meetingId: Int
    $attendanceType: AttendeeReportType
    $eventEndDate: Date
  ) {
    generateEventAttendeesAttendanceReport(
      eventId: $eventId
      meetingId: $meetingId
      attendanceType: $attendanceType
      eventEndDate: $eventEndDate
    )
  }
`;

export const UPDATE_ATTENDEES_INTERVAL = 3000;

export interface UpdateEventContentUploadsSubVars {
  meetingId: number;
}

export interface UpdateEventMaterialsSubData {
  onAssetsUpdated: AttendeeMeetingMaterials[];
}

export interface UpdateEventSlidesSubData {
  onAssetsUpdated: AttendeeSlides[];
}

export const UPDATE_EVENT_MATERIALS_SUBSCRIPTION = gql`
  subscription ($meetingId: Int) {
    onAssetsUpdated(meetingId: $meetingId, contentType: MATERIALS)
  }
`;

export const UPDATE_EVENT_SLIDES_SUBSCRIPTION = gql`
  subscription ($meetingId: Int) {
    onAssetsUpdated(meetingId: $meetingId, contentType: SLIDES)
  }
`;
