import { useContext, useMemo } from "react";
import { PublicEventDetails } from "../event/eventGql.model";
import { ErrorMessage } from "../api/api.definition";
import { ErrorCode, ErrorStrings } from "../../components/fallback/fallback.definition";
import { useGetRequest } from "./hook/getRequest";
import config from "../../config";
import { EventType } from "../event/event.model";
import { usePostRequest } from "./hook/postRequest";
import { Auth0Context } from "../../contexts";
import { PreCallSession } from "../../views/preCall/hooks/preCallTests/preCallTests.definition";

interface UseGetPublicEventProps {
  meetingId: number;
  skip?: boolean;
  refreshInterval?: number;
}

interface UseGetEventByCustomIdentifierProps {
  eventType: EventType;
  companyIdentifier: string;
  customIdentifier: string;
  skip?: boolean;
  refreshInterval?: number;
}

interface UseCreatePreCallSessionProps {
  meetingId: number;
}

const apiUrl = config.api.attendeeRest;

export const useGetPublicEvent = (props: UseGetPublicEventProps) => {
  const { meetingId, skip, refreshInterval } = props;

  const path = meetingId ? `${apiUrl}/event/${meetingId}` : null;

  const { data, error, isLoading, refetch } = useGetRequest({ skip, refreshInterval, path });

  const processedError = useMemo(() => {
    const _error = error?.[0] as ErrorMessage;
    if (!_error) return null;
    const errorMessage =
      _error?.extensions?.code === ErrorCode.userInputError
        ? ErrorStrings.invalidMeetingId
        : _error?.message;
    return new Error(errorMessage);
  }, [error]);

  return {
    data: data as PublicEventDetails,
    error: processedError,
    isLoading,
    refetch,
  };
};

export const useGetEventByCustomIdentifier = (props: UseGetEventByCustomIdentifierProps) => {
  const { eventType, customIdentifier, companyIdentifier, skip, refreshInterval } = props;

  const path = `${apiUrl}/events?eventType=${eventType}&customIdentifier=${customIdentifier}&companyIdentifier=${companyIdentifier}`;

  const { data, error, isLoading, refetch } = useGetRequest({ skip, refreshInterval, path });

  const processedError = useMemo(() => {
    const _error = error?.[0] as ErrorMessage;
    if (!_error) return null;
    const errorMessage =
      _error?.extensions?.code === ErrorCode.userInputError
        ? ErrorStrings.invalidMeetingId
        : _error?.message;
    return new Error(errorMessage);
  }, [error]);

  return {
    data: data as PublicEventDetails,
    error: processedError,
    isLoading,
    refetch,
  };
};

export const useCreatePreCallSession = (props: UseCreatePreCallSessionProps) => {
  const { getUserAccessToken } = useContext(Auth0Context);
  const { meetingId } = props;

  const path = meetingId ? `${apiUrl}/events/${meetingId}/pre-call-session` : null;

  
  const { data, error, isLoading, update } = usePostRequest({
    path,
    getAuthToken: getUserAccessToken,
  });

  const processedError = useMemo(() => {
    const _error = error?.[0] as ErrorMessage;
    if (!_error) return null;
    const errorMessage =
      _error?.extensions?.code === ErrorCode.userInputError
        ? ErrorStrings.invalidMeetingId
        : _error?.message;
    return new Error(errorMessage);
  }, [error]);

  return {
    data: data as PreCallSession,
    error: processedError,
    isLoading,
    update,
  };
};
