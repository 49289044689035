import React from "react";
import { Text, TextTheme } from "@q4/nimbus-ui";
import { getAppVersion } from "../navigation/app-version.resolver";
import { NavigationClassNames } from "../navigation/navigation.definition";

export function Version(props: { themeForLight?: boolean }): JSX.Element {
  const { themeForLight } = props;
  const appVersion = getAppVersion();
  const year = new Date().getFullYear();
  const copyRightText = `© ${year} Q4 Inc. All rights reserved. Version ${appVersion}`;
  return (
    <Text
      className={NavigationClassNames.Version}
      theme={themeForLight ? TextTheme.DarkSlate : undefined}
    >
      {copyRightText}
    </Text>
  );
}
