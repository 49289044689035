import ApiService from "../api/api.service";
import { ApiResponse, ContentType } from "../api/api.definition";
import {
  GetEventAuthenticationGuestParams,
  GetEventAuthenticationGuestResponse,
  RequestPasscodeParams,
  RequestPasscodeResponse,
  ValidatePasscodeParams,
  VerifyEventPasswordParams,
} from "./eventGql.model";
import { EventAuthDetails } from "./event.model";
import { Institution } from "../../hooks/institutions/institutions.hook.definition";

export default class EventGqlService {
  private readonly EVENT_PATH = "/rest/event";

  // Constructor shorthand/Parameter properties
  // Read more:
  //  - https://stackoverflow.com/questions/64064704/typescript-create-object-in-shorthand-constructor
  //  - https://www.typescriptlang.org/docs/handbook/classes.html#parameter-properties
  constructor(private apiService: ApiService) {}

  public searchInstitution(
    searchInput: string,
    limit?: number
  ): Promise<ApiResponse<Institution[]>> {
    const useAuth = false;
    const queryParams = { searchInput: searchInput, limit };
    const returnFullResponse = true;
    return this.apiService.get(`/rest/institution`, useAuth, queryParams, returnFullResponse);
  }
  public async getEventAuthenticationGuest(
    params: GetEventAuthenticationGuestParams
  ): Promise<ApiResponse<GetEventAuthenticationGuestResponse>> {
    const useAuth = false;
    const returnFullResponse = true;
    const response = await this.apiService.post<
      GetEventAuthenticationGuestParams,
      GetEventAuthenticationGuestResponse
    >(
      `${this.EVENT_PATH}/${params.meetingId}/guest/auth`,
      params,
      ContentType.Json,
      useAuth,
      returnFullResponse
    );

    if (!response?.success) throw new Error(response?.message);

    return response;
  }

  public async verifyEventPassword(params: VerifyEventPasswordParams): Promise<ApiResponse<any>> {
    const useAuth = false;
    const returnFullResponse = true;
    const response = await this.apiService.post(
      `${this.EVENT_PATH}/${params.meetingId}/password/verify`,
      params,
      ContentType.Json,
      useAuth,
      returnFullResponse
    );

    if (!response?.success) throw new Error(response?.message);

    return response;
  }

  public async requestPasscode(
    params: RequestPasscodeParams
  ): Promise<ApiResponse<RequestPasscodeResponse>> {
    const useAuth = false;
    const returnFullResponse = true;
    const response = await this.apiService.post<RequestPasscodeParams, RequestPasscodeResponse>(
      `${this.EVENT_PATH}/${params.meetingId}/passcode/request`,
      params,
      ContentType.Json,
      useAuth,
      returnFullResponse
    );

    if (!response?.success) throw new Error(response?.message);

    return response;
  }

  public async validatePasscode(
    params: ValidatePasscodeParams
  ): Promise<ApiResponse<EventAuthDetails>> {
    const useAuth = false;
    const returnFullResponse = true;
    const response = await this.apiService.post<ValidatePasscodeParams, EventAuthDetails>(
      `${this.EVENT_PATH}/${params.meetingId}/passcode/validate`,
      params,
      ContentType.Json,
      useAuth,
      returnFullResponse
    );

    if (!response?.success) throw new Error(response?.message);

    return response;
  }
}
