import React, { useContext, useEffect } from "react";

import usePublisherHook from "../../hooks/publisher/publisher.hook";
import { SessionContext } from "../../contexts/session/session.context";

import { OTPublisherProps } from "./OTPublisher.definition";
import { SessionContextModel } from "../../contexts/session/session.definition";

export default function OTPublisher(props: OTPublisherProps): JSX.Element {
  const {
    shouldUserPublish: publish,
    videoWrapperClassNames,
    videoWrapperIds,
    insertDefaultUI,
    publishFailed,
  } = props;

  const sessionContext = useContext(SessionContext);
  const { OTSession, onStreamCreated } = sessionContext as SessionContextModel;

  const { publisher, createPublisher, destroyPublisher } = usePublisherHook({
    onStreamCreated,
    onPublisherCreated: props.onPublisherCreated,
    session: OTSession,
  });

  // publish & unpublish via prop
  useEffect(() => {
    if (!OTSession) return;
    if (publish && !publishFailed) {
      publisher && destroyPublisher(props);
      createPublisher(props);
    } else {
      publisher && destroyPublisher(props);
    }
  }, [OTSession, publish, publishFailed]); // eslint-disable-line react-hooks/exhaustive-deps

  return insertDefaultUI ? (
    <div className={videoWrapperClassNames} id={videoWrapperIds}></div>
  ) : null;
}
