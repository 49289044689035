import { Dispatch, SetStateAction } from "react";
import { EventMicrositePageType } from "../../hooks/microsite.hook.definition";
import {
  ControlPanelBrandingErrorState,
  ControlPanelBrandingState,
  OpenRegistrationSelectType,
  RegistrationBrandingState,
} from "../../micrositeModal.definition";
import { UploadedDataFormat } from "../../../../services/event/event.model";
import { RegistrationField } from "../../../../services/event/registrationFieldGql.model";
import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { MicrositePageConfigurationModalIdModel } from "../micrositePagesConfigurationModal/micrositePagesConfigurationModal.definition";
import { PublicEventDetails } from "../../../../services/event/eventGql.model";

export interface MicrositeControlPanelModalProps extends BaseComponentProps {
  isLoadingCompanyBrandingData: boolean;
  backgroundImages: UploadedDataFormat[];
  eventId: string;
  currentEventData: PublicEventDetails;
  registrationFields: RegistrationField[];
  companyId: string;
  companyFonts: UploadedDataFormat[];
  controlPanelState: ControlPanelBrandingState;
  controlPanelBrandingErrorState: ControlPanelBrandingErrorState;
  resetControlPanelBrandingErrorState(): void;
  currentPage: EventMicrositePageType;
  currentPageState: any;
  currentPageErrorState: any;
  selectedProgessStage: number;
  selectedOpenRegistration: OpenRegistrationSelectType;
  showPagesConfigurationForm: boolean;
  savingControlPanelPageData: boolean;
  resetCurrentPageErrorState(): void;
  setControlPanelState(state: any): void;
  setCurrentPageState(state: any): void;
  skipControlPanel(): Promise<boolean>;
  handleSaveControlPanelDataAndShowEditPages(): void;
  setShowChangeBackgroundModal(): void;
  registrationPageState: RegistrationBrandingState;
  showPostEventPage: boolean;
  currentRegistrationTab?: number;
  setCurrentRegistrationTab?: Dispatch<SetStateAction<number>>;
  setRegistrationFieldsState?: Dispatch<SetStateAction<RegistrationField[]>>;
  closeSlideOut: boolean;
  pageOrder: EventMicrositePageType[];
}

export enum MicrositeControlPanelModalClassNames {
  Base = "microsite-control-panel-modal",
  Header = "microsite-control-panel-modal_header",
  Content = "microsite-control-panel-modal_content",
  Settings = "microsite-control-panel-modal_settings",
  Title = "microsite-control-panel-modal_title",
}

const dataIdPrefix = "ControlPanelModal";
export const DATA_IDS = {
  EDIT_PAGES_BUTTON: `${dataIdPrefix}EditPagesButton`,
};

export class ControlPanelModalIdModel extends IdModelBase {
  configurationModal: MicrositePageConfigurationModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.configurationModal = new MicrositePageConfigurationModalIdModel(
      `${this.id}ConfigurationModal`
    );
  }
}
