import { RouteComponentProps } from "react-router";
import { Auth0ContextState } from "../../contexts";
import { EventContextState } from "../../contexts";

interface MatchParams {
  id: string;
}

export interface BroadcastParticipantProps extends RouteComponentProps<MatchParams> {
  auth0?: Auth0ContextState;
  eventContext?: EventContextState;
  meetingId: string;
}

export const ParticipantStorageKeyPrefix = "broadcast_participant_";
export const GoogleDownloadUrl = "https://www.google.com/intl/en_ca/chrome/";
export const EdgeDownloadUrl = "https://www.microsoft.com/en-us/edge";
export const SafariDownloadUrl = "https://support.apple.com/downloads/safari";
