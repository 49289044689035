import { ApolloProvider } from "@apollo/client";
import React, { memo, ReactNode } from "react";
import { AppContext, AppContextState } from "../contexts";
import { Auth0Context } from "../contexts/auth0/auth0.context";
import { Auth0ContextState } from "../contexts/auth0/auth0.definition";
import contextSubscribe from "../contexts/context.subscribe";
import EventContext from "../contexts/event/event.context";
import { EventContextState } from "../contexts/event/event.definition";
import { useApollo } from "./apollo.hook";

interface ApolloWrapperProps {
  auth?: Auth0ContextState;
  eventContext?: EventContextState;
  appContext?: AppContextState;
  children: ReactNode;
}

const ApolloWrapper = (props: ApolloWrapperProps) => {
  const { auth, children, eventContext, appContext } = props;
  const { participantRole, wsConnectionId, currentEvent } = eventContext;
  const { meetingId } = appContext;

  const userCompanyId = auth?.user?.companies?.[0];

  const { apolloClient } = useApollo({
    auth,
    isAttendeeAuthenticated: !!auth?.isAttendeeAuthenticated,
    companyId: userCompanyId,
    eventId: meetingId,
    participantRole,
    wsConnectionId,
    broadcastSource: currentEvent?.broadcastSource,
  });

  return <>{apolloClient && <ApolloProvider client={apolloClient}>{children}</ApolloProvider>}</>;
};

export default contextSubscribe<ApolloWrapperProps>(
  [
    {
      context: Auth0Context,
      mapToProps: "auth",
    },
    {
      context: EventContext,
      mapToProps: "eventContext",
    },
    {
      context: AppContext,
      mapToProps: "appContext",
    },
  ],
  memo(ApolloWrapper)
);
