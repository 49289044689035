import { NotificationService } from "@q4/nimbus-ui";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { EventAssetsContext } from "../../../../contexts";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { usePrevious } from "../../../../hooks/previousState/usePrevious.hook";
import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { CarouselServiceModel } from "../carouselSelection/carouselStreamSelection.definition";
import { MediaService } from "../mediaControls/mediaControls.definition";

interface StreamAudioControls {
  broadcasting: boolean;
  participant: Partial<Participant>;
  stream: BroadcastStream;
  isStaged: CarouselServiceModel["isStaged"];
  setSpeakerAudio?: MediaService["setSpeakerAudio"];
}

export function canControlAudio(stream: BroadcastStream, participant: Participant): boolean {
  if (!participant?.mediaSettings?.audioInputsReady) return false;

  if (stream?.isSlideAsset || stream?.isScreenShare) return false;

  return true;
}

export const useStreamAudioControls = (props: StreamAudioControls) => {
  const { broadcasting, stream, participant, isStaged, setSpeakerAudio } = props;

  const { setAudio: setAssetAudio } = useContext(EventAssetsContext);

  const notificationService = useRef(new NotificationService());

  const hasAudio = useMemo(
    () => stream?.hasAudioWithMediaState(participant),
    [stream, participant]
  );

  const [isMuted, setIsMuted] = useState(!hasAudio);
  const prevHasAudio = usePrevious(isMuted);

  useEffect(
    function updateMuteState() {
      if (prevHasAudio !== hasAudio) {
        setIsMuted(!hasAudio);
      }
    },
    [hasAudio, prevHasAudio]
  );

  const setStreamAudio = useCallback(
    async (update: boolean) => {
      if (stream?.isAssetStream) {
        return setAssetAudio(stream, update);
      }
      setSpeakerAudio && (await setSpeakerAudio(stream.connection, update, stream.isPSTNStream));
    },
    [setAssetAudio, setSpeakerAudio, stream]
  );

  const toggleStreamAudio = useCallback(
    async (event) => {
      event?.stopPropagation();

      try {
        if (!broadcasting || isStaged(stream)) {
          try {
            if (stream.isPSTNStream) setIsMuted(!isMuted); // optimistic UI update

            await setStreamAudio(!hasAudio);
          } catch (e) {
            console.error(`unable to set audio isMuted : ${!isMuted}`, e);

            if (stream.isPSTNStream) setIsMuted(isMuted); // reset
          }
        } else if (broadcasting && hasAudio) {
          await setStreamAudio(!hasAudio);
        } else {
          notificationService.current.info("Mute/Unmute is disabled while the user is not staged.");
        }
      } catch (e) {
        console.error(`unable to setAudio: ${!hasAudio}`, e);
      }
    },
    [broadcasting, hasAudio, isMuted, isStaged, setStreamAudio, setIsMuted, stream]
  );

  return { hasAudio, isMuted: !hasAudio, toggleStreamAudio };
};
