import { useContext, useMemo } from "react";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../../contexts/featureFlags";

export const useDualStreamFlag = (props: { companyId: string }) => {
  const { companyId } = props;
  const { featureFlags } = useContext(FeatureFlagsContext);

  const { [FeatureFlagKeys.DualStream]: dualStream } = featureFlags || {};

  const enabled: boolean = useMemo(
    () => dualStream?.enabled || dualStream?.companyId.some((value) => ["*", companyId].includes(value)),
    [companyId, dualStream]
  );

  return { enabled };
};
