import { useEffect, useState } from "react";
import { isEmpty, reduce, keys } from "lodash";
import {
  ResetEditProfileFormModel,
  EditProfileErrorState,
  FormErrors,
} from "../../components/modal/editProfileModal/editProfile.definition";
import { ErrorModel } from "../../components/modal/modal.definition";

export default function useRegistrationForm(user) {
  const [editProfileState, setEditProfileState] = useState(new ResetEditProfileFormModel(user));
  const [profileUser, setProfileUser] = useState(new ResetEditProfileFormModel(user));
  const [errorState, setErrorState] = useState<EditProfileErrorState>(
    new EditProfileErrorState(getInitialErrorState())
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    setEditProfileState({
      id: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
    });
    setProfileUser(user);
  }, [user]);

  function getInitialErrorState() {
    return new EditProfileErrorState({
      firstNameError: new ErrorModel(),
      lastNameError: new ErrorModel(),
    });
  }

  function handleFirstNameChange(value) {
    setEditProfileState({ ...editProfileState, firstName: value });
  }

  function handleLastNameChange(value) {
    setEditProfileState({ ...editProfileState, lastName: value });
  }

  function resetEditProfileState() {
    setEditProfileState(
      new ResetEditProfileFormModel({
        id: profileUser.id,
        lastName: profileUser.lastName,
        firstName: profileUser.firstName,
        email: profileUser.email,
      })
    );
  }

  function hasErrors() {
    const initialError = getInitialErrorState();

    if (isEmpty(editProfileState.firstName)) {
      initialError.firstNameError = new ErrorModel(true, FormErrors.FIRST_NAME_EMPTY, "Profile");
      setErrorState({ ...initialError });
    }

    if (isEmpty(editProfileState.lastName)) {
      initialError.lastNameError = new ErrorModel(true, FormErrors.LAST_NAME_EMPTY, "Profile");
      setErrorState({ ...initialError });
    }

    if (
      !reduce(
        keys(initialError),
        (acc, errKey) => {
          return initialError[errKey].visible || acc;
        },
        false
      )
    ) {
      resetErrorState();
      return false;
    } else {
      return true;
    }
  }

  function resetErrorState() {
    setErrorState(getInitialErrorState);
  }

  return {
    handleFirstNameChange,
    handleLastNameChange,
    resetEditProfileState,
    resetErrorState,
    editProfileState,
    errorState,
    hasErrors,
  };
}
