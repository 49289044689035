import { getClassName } from "@q4/nimbus-ui";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { BestFitLayout } from "../../../../components/bestFitLayout/bestFitLayout.component";
import { CustomAvatarSizes } from "../../../../components/customAvatar/customAvatar.definition";
import { EventContext } from "../../../../contexts";
import { isStreamLocalPublisher } from "../../../../opentok-react/utils/stream.utils";
import { StageStreamWrapper } from "../../../adminConsole/components/StreamWrapper/stageStreamWrapper.component";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { useRemoveStreamHook } from "../../hooks/removeStream.hook";
import { DATA_IDS, SpeakerLayoutClassNames, SpeakerLayoutProps } from "./SpeakerLayout.definition";
import "./SpeakerLayout.scss";

export function SpeakerLayout(props: SpeakerLayoutProps) {
  const {
    id,
    cameraSelection,
    isPreview = false,
    participantsByConnectionId,
    renderOnChange, // useEffect() uses this to determine when to attach video streams,
    stageSelection,
  } = props;

  const { removeStreamFromStage } = useRemoveStreamHook({ updateStage: stageSelection });

  const layoutWrapperId = useRef(id || uuidv4());

  // displaying a company background with title in speaker layout during audio only
  const { eventConfiguration, backgroundImageWithTitle, userEventPermissions } =
    useContext(EventContext);
  const audioOnly = !eventConfiguration?.video?.enabled;

  const videoElementToMediaMap = useRef<
    Array<{ videoElement: HTMLVideoElement; stream: BroadcastStream }>
  >([]);
  videoElementToMediaMap.current = [];

  // running this on render causes video to sputter, but running with
  // [] results in layouts not properly updating
  useEffect(() => {
    attachVideoStreamsToVideoElements(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderOnChange]);

  async function attachVideoStreamsToVideoElements() {
    videoElementToMediaMap.current.forEach(
      async (map: { videoElement: HTMLVideoElement; stream: BroadcastStream }) => {
        const { videoElement, stream } = map;
        if (stream && videoElement) {
          videoElement.srcObject = await stream.videoMediaStream();
        }
      }
    );
  }

  function setRef(videoRef: HTMLVideoElement, selection: BroadcastStream) {
    videoElementToMediaMap.current.push({
      videoElement: videoRef,
      stream: selection,
    });
  }

  function getCameraClassName(stream: BroadcastStream) {
    return getClassName(SpeakerLayoutClassNames.VIDEO_ELEMENT, [
      {
        condition: isStreamLocalPublisher(stream.getVendorStream()),
        trueClassName: "mirrored",
      },
    ]);
  }

  const videoContent = cameraSelection.map((_selection: BroadcastStream) => (
    <div className={SpeakerLayoutClassNames.CONTENT} key={_selection?.id}>
      <StageStreamWrapper
        stream={_selection}
        participant={
          participantsByConnectionId &&
          participantsByConnectionId[_selection.connection?.connectionId]
        }
        onRemoveStream={removeStreamFromStage}
        previewMode={isPreview}
        userEventPermissions={userEventPermissions}
        avatarSize={CustomAvatarSizes.Large}
      >
        {!_selection.isAudioAsset && (
          <video
            className={getCameraClassName(_selection)}
            ref={(ref: HTMLVideoElement) => setRef(ref, _selection)}
            crossOrigin={"anonymous"}
            autoPlay={true}
            muted={true}
          ></video>
        )}
      </StageStreamWrapper>
    </div>
  ));

  const renderAudioSpeakerComponents = useCallback(
    (selection: BroadcastStream) => {
      return (
        <>
          <StageStreamWrapper
            stream={selection}
            participant={
              participantsByConnectionId &&
              participantsByConnectionId[selection.connection?.connectionId]
            }
            onRemoveStream={removeStreamFromStage}
            audioOnly={audioOnly}
            previewMode={isPreview}
            userEventPermissions={userEventPermissions}
            avatarSize={CustomAvatarSizes.Small}
          />
        </>
      );
    },
    [audioOnly, isPreview, participantsByConnectionId, removeStreamFromStage, userEventPermissions]
  );

  const { max: maxColumns, min: minColumns } = useMemo(
    function calculateRowsColumns() {
      const _contents = videoContent.length;

      const max = _contents <= 4 ? 2 : 3;

      let min: number;
      if (_contents > 1 && _contents <= 4) min = 2;
      if (_contents > 4) min = 3;

      return { max, min };
    },
    [videoContent.length]
  );

  return (
    <div
      className={SpeakerLayoutClassNames.WRAPPER}
      id={layoutWrapperId.current}
      data-id={DATA_IDS.WRAPPER}
    >
      {audioOnly ? (
        <div className={SpeakerLayoutClassNames.WRAPPER_AUDIO_ONLY}>
          <div className={SpeakerLayoutClassNames.WRAPPER_AUDIO_ONLY_POSTER}>
            <img src={backgroundImageWithTitle} alt="Attendee View" />
          </div>
          <div className={SpeakerLayoutClassNames.WRAPPER_AUDIO_ONLY_SPEAKERS}>
            {cameraSelection.map(renderAudioSpeakerComponents)}
          </div>
        </div>
      ) : (
         <BestFitLayout
           layoutContents={videoContent}
           boundingContainerSettings={{
             query: `[id="${layoutWrapperId.current}"]`,
             maxColumns,
             minColumns,
           }}
         />
      )}
    </div>
  );
}
