import { debounce as lodashDebounce } from "lodash";

export const DEBOUNCE_DEFAULT_DELAY = 400;
export const RETRY_DEFAULT_DELAY = 1000;

export const debounce = (func, wait = DEBOUNCE_DEFAULT_DELAY) => {
  return lodashDebounce(func, wait);
};

export const promiseTimeout = (timeout) => {
  return new Promise((res) => setTimeout(res, timeout));
};

/**
 * Closures for setting states of opening/closing modals, messages, popovers, etc.
 * Prevents creating a new function for each action
 * @param setState
 */
export function open(setState: (state: boolean) => void) {
  return () => setState(true);
}
export function close(setState: (state: boolean) => void) {
  return () => setState(false);
}

/**
 * Sonarcloud throws errors whenever an empty function is used in code.
 * However, functions with a comment inside do not count as empty.
 */
export function doNothing() {
  // comment to bypass sonarcloud warnings
}
export async function doNothingAsync() {
  // comment to bypass sonarcloud warnings
}
