import { BroadcastStream } from "../../interfaces/broadcastStream/broadcastStream";
import { UpdateLayoutParams } from "../../vendors/openTok/services/openTokSignaling.service";

export enum SelectionStreamType {
  Camera = "Camera",
  Screen = "Screen",
  Asset = "Asset",
  PSTN = "PSTN",
}

export enum LayoutType {
  Speaker = "Speaker",
  Media = "Media",
  MediaOnly = "MediaOnly",
}

export interface CarouselStreamSelectionProps {
  mediaStreamSelectionLimit: number;
  cameraStreamSelectionLimit: number;
  mediaOnly?: boolean;
}

export interface CarouselStreamSelectionModel {
  carouselSelection: {
    selected: SelectionModel;
    selectedV2?: SelectionModelV2;
    staged: SelectionModel;
    stagedV2: SelectionModelV2;
    cameraLimit: number;
    mediaLimit: number;
  };
  carouselSelectionService: CarouselServiceModel;
  carouselSelectionHelpers: {
    filterStreamsFromSelection: (
      selection: Partial<StreamSelection>,
      streamId: string
    ) => Partial<StreamSelection>;
    convertToSelectionModelV2?: (selection: SelectionModel) => SelectionModelV2;
    mapBroadcastStreamsToSelectionModel?: (
      selection: SelectionModel,
      streams: BroadcastStream[]
    ) => SelectionModel;
    filterSelectionByAvailableStreams?: (
      selection: SelectedStream[],
      streams: BroadcastStream[]
    ) => SelectedStream[];
  };
}

export interface CarouselServiceModel {
  canSelect: (stream: BroadcastStream) => boolean;
  getSelectionOrder: (stream: BroadcastStream) => number;
  select: (stream: BroadcastStream) => void;
  unselect: (stream: BroadcastStream) => void;
  unselectAll: () => void;
  stageCurrentSelection: () => SelectionModel;
  isSelected: (stream: BroadcastStream) => boolean;
  isStaged: (stream: BroadcastStream) => boolean;
  stageSelection: (selection: SelectionModel) => void;
  clearSelection: () => void;
  getStagedSelection: () => SelectionModel;
  isVideoAsset: (stream: BroadcastStream) => boolean;
  isAudioAsset: (stream: BroadcastStream) => boolean;
  isVideoAssetSelected: (checkStage?: boolean) => boolean;
  isAudioAssetSelected: (checkStage?: boolean) => boolean;
  isSlideAssetSelected: (checkStage?: boolean) => boolean;
  isSelectedAssetStaged: () => boolean;
  getUnstagedStreams: (newSelection: UpdateLayoutParams) => BroadcastStream[];
}

export interface SelectionModel extends StreamSelection, SelectionModelMeta {}
export interface StreamSelection {
  mediaSelection: Array<Pick<BroadcastStream, Exclude<keyof BroadcastStream, "hasAudio hasVideo">>>; //mediaSelection might be stale, check actual stream
  cameraSelection: Array<
    Pick<BroadcastStream, Exclude<keyof BroadcastStream, "hasAudio hasVideo">>
  >;
}

export interface SelectionModelV2 {
  selection: SelectedStream[];
  layoutConfig: LayoutConfig;
}

export interface LayoutConfig {
  type: LayoutType;
}

export interface SelectedStream {
  id: string;
  type: SelectionStreamType;
  assetId?: string;
}

export interface SelectionModelMeta {
  mediaOnly?: boolean;
}

export enum SelectionType {
  CAMERA = "camera",
  MEDIA = "media",
}
