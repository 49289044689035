import { BaseComponentWithChildrenProps, getClassName } from "@q4/nimbus-ui";
import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { InitSubscriberProperties } from "../../../../contexts/conference/conference.definition";
import { Group } from "../../../../hooks/groups/groups.hook.definition";
import {
  getStreamsInGroup,
  sortStreamsByParticipants,
} from "../../../../hooks/groups/groups.hook.utils";
import OTSubscriber from "../../../../opentok-react/components/OTSubscriber/OTSubscriber.component";
import { SelectionModel } from "../../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";
import Subscribers, { SubscribersProps } from "./Subscribers.component";
import "./SubscribersRenderer.style.scss";

interface SubscribersRendererProps {
  group: Group;
  streams: BroadcastStream[];
  subscribersProps: SubscribersProps;
  stageSelection?: (selection: SelectionModel) => void;
  mediaOnly?: boolean;
}
interface SubscribersPortalProps extends BaseComponentWithChildrenProps {
  visible?: boolean;
  streams: BroadcastStream[];
  subscribersProps: SubscribersProps;
  initSubscriberProperties?: InitSubscriberProperties;
}

enum SubscribersPortalClassNames {
  Base = "subscribers_portal",
  Visible = "subscribers_portal--visible",
}

export default function SubscribersRenderer(props: SubscribersRendererProps) {
  const { group, streams, subscribersProps, stageSelection, mediaOnly } = props;

  const groupStreams = useMemo(() => getStreamsInGroup(group, streams), [group, streams]);

  const carouselStreams = useMemo(
    () => sortStreamsByParticipants(groupStreams, group.participants),
    [groupStreams, group.participants]
  );

  return (
    <Subscribers
      {...subscribersProps}
      streams={carouselStreams}
      stageSelection={stageSelection}
      mediaOnly={mediaOnly}
    />
  );
}

export function SubscribersPortal(props: SubscribersPortalProps) {
  const { className, visible = false, streams, subscribersProps, initSubscriberProperties } = props;

  const portalClassNames = useMemo(
    () =>
      getClassName(SubscribersPortalClassNames.Base, [
        {
          condition: visible,
          trueClassName: SubscribersPortalClassNames.Visible,
        },
        {
          condition: !!className,
          trueClassName: className,
        },
      ]),
    [visible, className]
  );

  const OTSubscribers = useMemo(
    () =>
      streams.map((stream) => {
        if (stream.id !== stream.connection?.connectionId && !stream.isLocalPublisher)
          return (
            <OTSubscriber
              {...subscribersProps}
              initSubscriberProperties={initSubscriberProperties}
              stream={stream.getVendorStream()}
              setStreamSubscribed={stream.markStreamSubscribed.bind(stream)}
              videoWrapperIds={`OT-Subscriber-Container_${stream.id}`}
              key={`OTSubscriber-${stream.id}`}
            />
          );
        return <React.Fragment key={`OTSubscriber-${stream?.id}`}></React.Fragment>;
      }),
    [streams, initSubscriberProperties, subscribersProps]
  );

  return ReactDOM.createPortal(
    <div className={portalClassNames}>{OTSubscribers}</div>,
    document.body
  );
}
