import React, { useRef } from "react";

import Slider from "@q4/react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./carousel.component.scss";

export enum CarouselSlider {
  Base = "slick",
  Next = "slick-next",
  Previous = "slick-prev",
  Wrapper = "slick-wrapper",
}

export enum ArrowType {
  Next = "right",
  Prev = "left",
}

export default function Carousel(props) {
  const { className, key, slidesToShowLimit, slidesToScroll } = props;
  const draggingRef = useRef(false);
  const sliderRef = useRef<Slider>();

  const handleOnItemClick = function (e) {
    if (draggingRef.current) e.stopPropagation();
  };

  /**
   * customized next and previous arrow
   * @param props
   * @constructor
   */
  function CustomizedArrow(props): JSX.Element {
    const { arrowDirection, className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className={`q4i-arrow-${arrowDirection}-4pt`} />
      </div>
    );
  }

  const currentSlideIndex = useRef(0);

  function beforeSlideAdded(sliderIndex: number) {
    currentSlideIndex.current = sliderIndex;
    draggingRef.current = true;
  }

  function afterSlideAdded() {
    sliderRef?.current?.slickGoTo(currentSlideIndex.current, true);
    draggingRef.current = false;
  }

  return (
    <Slider
      variableWidth
      className={className}
      dots={true}
      infinite={false}
      speed={300}
      slidesToShow={slidesToShowLimit}
      slidesToScroll={slidesToScroll}
      key={key}
      draggable={false}
      swipe
      ref={(slider) => {
        sliderRef.current = slider;
      }}
      nextArrow={
        <CustomizedArrow className={CarouselSlider.Next} arrowDirection={ArrowType.Next} />
      }
      prevArrow={
        <CustomizedArrow className={CarouselSlider.Previous} arrowDirection={ArrowType.Prev} />
      }
      beforeChange={beforeSlideAdded}
      afterChange={afterSlideAdded}
    >
      {React.Children.map(props.children, (child) => (
        <div className={CarouselSlider.Wrapper} onClickCapture={handleOnItemClick}>
          {child}
        </div>
      ))}
    </Slider>
  );
}
