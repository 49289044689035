import { gql } from "@apollo/client";

const LoginAttemptFragment = gql`
  fragment LoginAttempt on LoginAttempt {
    timestamp
    status
  }
`;

const UserFragment = gql`
  ${LoginAttemptFragment}

  fragment User on User {
    id
    email
    firstName
    lastName
    companies
    userStatus
    userTypes
    identityProviderUserId
    loginAttempts {
      ...LoginAttempt
    }
  }
`;

export const USER_FRAGMENTS = {
  LoginAttempt: LoginAttemptFragment,
  User: UserFragment,
};
