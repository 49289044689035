import React, { useContext, useEffect } from "react";

import { SessionContext } from "../../contexts/session/session.context";
import { OTSubscriberProps } from "./OTSubscriber.definition";

import useSubscriberHook from "../../hooks/subscriber/subscriber.hook";
import { isShareScreenStream, isStreamLocalPublisher } from "../../utils/stream.utils";
import { SubscriberHookProps } from "../../hooks/subscriber/subscriber.definition";
import { SessionContextModel } from "../../contexts/session/session.definition";

export default function OTSubscriber(props: OTSubscriberProps): JSX.Element {
  const { OTSession } = useContext(SessionContext) as SessionContextModel;
  const { stream, subscribeToAudio, shouldSubscribeToAudio } = props;

  let subscriberContainerRef: HTMLDivElement;

  const { OTSubscriber, createSubscriber, destroySubscriber, getOTSubscriber } = useSubscriberHook({
    ...props,
    session: OTSession,
  } as SubscriberHookProps);

  useEffect(
    function subscribeToStream() {
      if (OTSession && !OTSubscriber) {
        createSubscriber(props, subscriberContainerRef);
      }

      return function OTSubscriberCleanup() {
        if (OTSubscriber) destroySubscriber(OTSession, props);
      };
    },
    [OTSession, OTSubscriber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function subscribeToStreamAudio() {
      if (!subscribeToAudio && !shouldSubscribeToAudio?.()) return;
      if (!isShareScreenStream(stream) && !isStreamLocalPublisher(stream)) {
        getOTSubscriber()?.subscribeToAudio(true);
      }
    },
    [subscribeToAudio, OTSubscriber, stream] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { videoWrapperClassNames, videoWrapperIds } = props;
  return (
    <div
      className={`${videoWrapperClassNames} ${
        !isShareScreenStream(stream) ? "OT-videoMedia" : "OT-customMedia"
      }`}
      id={videoWrapperIds}
      ref={(refObj) => (subscriberContainerRef = refObj)}
    ></div>
  );
}
