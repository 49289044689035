import {
  Button,
  ButtonTheme,
  Ghostable,
  Layout,
  LayoutPadding,
  NotificationService,
  Origin,
  PopoverMenu,
  PopoverMenuProps,
  PopoverMenuTheme,
  Toolbar,
  ToolbarGroup,
  ToolbarRow,
  ToolbarTheme,
  useVisibility,
} from "@q4/nimbus-ui";
import { compact } from "lodash";
import React, { memo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ChangePasswordModal,
  EditUserProfileModal,
  HelpCenterUploadModal,
} from "../../../../components/modal";
import {
  UserPermissions,
  UserRoleNiceNameMapping,
} from "../../../../configurations/userRolesPermissions.configuration";
import useUsers from "../../../../hooks/users/users.hook";
import "./header.component.scss";
import {
  DATA_IDS,
  HeaderClassNames,
  HeaderIDs,
  HeaderPopoverMenuButtonIds,
  HeaderProps,
  UserPopoverMenuClassNames,
} from "./header.definition";
import { useHelpCenterAuth } from "../../../../hooks/helpCenterAuth/useHelpCenterAuth.hook";
import { HelpCenterConfigs } from "../../../../hooks/helpCenterAuth/helpCenterAuth.configs";

function Header(props: HeaderProps): JSX.Element {
  const { user, logoutApplication, showHistoryBack, updateUser } = props;

  const history = useHistory();
  const [changePasswordLoading, setChangePasswordLoading] = useState<boolean>(false);
  const [profileMenuOpen, openProfileMenu, closeProfileMenu] = useVisibility();
  const [changePasswordOpen, openChangePassword, closeChangePassword] = useVisibility();
  const [changeProfileModalOpen, openChangeProfile, closeChangeProfile] = useVisibility();
  const [helpCenterUploadOpen, openHelpCenterUpload, closeHelpCenterUpload] = useVisibility();
  const { updateUserById, updatePassword } = useUsers({
    user,
    updateUser,
  });

  const { authenticateForHelpCenter, isAuthorizedToOpenHelpCenter } = useHelpCenterAuth();

  const notificationService = useRef(new NotificationService());

  const userName =
    user?.firstName && user?.lastName ? [user.firstName, user.lastName].join(" ") : user?.email;

  function handleHistoryBack() {
    history.goBack();
  }

  function handleSignOutClick() {
    logoutApplication({});
  }

  async function handleOpenHelpCenter() {
    if (isAuthorizedToOpenHelpCenter()) return window.open(HelpCenterConfigs.url);

    notificationService.current.info("Opening help center...");
    try {
      await authenticateForHelpCenter();

      window.open(HelpCenterConfigs.url);
    } catch (e) {
      console.debug(e?.message);
      notificationService.current.error("Unable to open report guide");
    }
  }

  async function handleChangePasswordSubmit(value): Promise<boolean> {
    setChangePasswordLoading(true);
    const isValid = await updatePassword({
      currentPassword: value.currentPassword,
      password: value.password,
    });
    if (isValid) {
      closeChangePassword();
      setChangePasswordLoading(false);
      return true;
    }
    setChangePasswordLoading(false);
    return false;
  }

  function handleEditUserProfileSubmit(user) {
    const { id, firstName, lastName } = user;
    updateUserById(id, { firstName, lastName });
    closeChangeProfile();
  }

  // using compact() to clean up 'undefined' value when a user is not a Q4 admin
  const profileMenuOptions = compact([
    {
      id: HeaderPopoverMenuButtonIds.Info,
      className: UserPopoverMenuClassNames.Info,
      label: (
        <span className={UserPopoverMenuClassNames.InfoContent}>
          <span className={UserPopoverMenuClassNames.Name}>{userName}</span>
          {user?.userTypes && (
            <span className={UserPopoverMenuClassNames.UserType}>
              {UserRoleNiceNameMapping[user?.userTypes[0]]}
            </span>
          )}
        </span>
      ),
    },
    {
      id: HeaderPopoverMenuButtonIds.EditProfile,
      label: "Edit Profile",
      icon: "q4i-edit-4pt",
      dataId: DATA_IDS.EDIT_PROFILE_BUTTON,
      onClick: openChangeProfile,
    },
    {
      id: HeaderPopoverMenuButtonIds.HelpCenter,
      label: "Help Center",
      icon: "q4i-question-mark-4pt",
      onClick: handleOpenHelpCenter,
    },
    user.permissions?.[UserPermissions.UPLOAD_HELPCENTER_ZIP]
      ? {
          id: HeaderPopoverMenuButtonIds.UploadZip,
          label: "Upload Help Center ZIP",
          icon: "q4i-export-4pt",
          onClick: openHelpCenterUpload,
        }
      : undefined,
    {
      id: HeaderPopoverMenuButtonIds.ChangePassword,
      label: "Change Password",
      icon: "q4i-lock-4pt",
      dataId: DATA_IDS.CHANGE_PASSWORD_BUTTON,
      onClick: openChangePassword,
    },
    {
      id: HeaderPopoverMenuButtonIds.LogOut,
      label: "Log Out",
      icon: "q4i-logout-2pt",
      dataId: DATA_IDS.LOGOUT_BUTTON_BUTTON,
      onClick: handleSignOutClick,
    },
  ]);

  const profileMenuProps: PopoverMenuProps = {
    anchorTargetElementId: HeaderIDs.ProfileButton,
    targetOrigin: Origin.BottomRight,
    visible: profileMenuOpen,
    theme: PopoverMenuTheme.Slate,
    options: profileMenuOptions,
    onCloseRequest: closeProfileMenu,
    masked: true,
  };

  return (
    <Layout
      alignItems="stretched"
      justifyContent="flex-end"
      direction="column"
      padding={LayoutPadding.None}
    >
      <Toolbar
        className={HeaderClassNames.Base}
        theme={ToolbarTheme.Q4Blue}
        autoRow={false}
        autoRowProps={{ justifyContent: showHistoryBack ? "space-between" : "flex-end" }}
      >
        <ToolbarRow
          justifyContent={showHistoryBack ? "space-between" : "flex-end"}
          className="toolbar_row--custom"
        >
          {showHistoryBack && (
            <ToolbarGroup key={1}>
              <Button
                className={HeaderClassNames.BackButton}
                icon="q4i-arrow-left-4pt"
                label="Back"
                theme={ButtonTheme.Q4Blue}
                onClick={handleHistoryBack}
              />
            </ToolbarGroup>
          )}
          <ToolbarGroup key={2}>
            <Ghostable ghosted={!user}>
              <Button
                id={HeaderIDs.ProfileButton}
                dataId={DATA_IDS.PROFILE_BUTTON}
                className={HeaderClassNames.ProfileButton}
                icon="q4i-contact-4pt"
                theme={profileMenuOpen ? ButtonTheme.Slate : ButtonTheme.Q4Blue}
                onClick={openProfileMenu}
              />
            </Ghostable>
          </ToolbarGroup>
        </ToolbarRow>
        <PopoverMenu {...profileMenuProps} className={HeaderClassNames.UtilityMenu} />
      </Toolbar>
      <ChangePasswordModal
        loading={changePasswordLoading}
        visible={changePasswordOpen}
        user={user}
        onClose={closeChangePassword}
        onSubmit={handleChangePasswordSubmit}
      />
      <EditUserProfileModal
        visible={changeProfileModalOpen}
        user={user}
        onClose={closeChangeProfile}
        onSubmit={handleEditUserProfileSubmit}
      />
      <HelpCenterUploadModal visible={helpCenterUploadOpen} onClose={closeHelpCenterUpload} />
    </Layout>
  );
}

export default memo(Header);
