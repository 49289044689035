import { AttendeeRegistrationFormData } from "./../broadcastRegistration/broadcastRegistration.definition";
import { FormattedInstitution } from "./../../../hooks/institutions/institutions.hook.definition";
import { InvestorTypeNames } from "../../../views/broadcastParticipant/eventRegistration/components/eventRegistrationForm.definition";
import { SavedEventBrandingType } from "./../../eventMicrosite/hooks/microsite.hook.definition";
import { AttendeeEventRoles } from "../../../configurations/userRolesPermissions.configuration";
import { ErrorMessage } from "../../../services/api/api.definition";
import {
  Attendee,
  AttendeeOriginType,
  AttendeeRegistrationFieldAnswer,
} from "../../../services/attendees/attendees.model";
import { EventType } from "../../../services/event/event.model";
import { PublicEventDetails } from "../../../services/event/eventGql.model";
import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { MicrositeCustomRegistrationPreviewIdModel } from "../../eventMicrosite/components/micrositePagesConfigurationModal/PageConfigurationModalForm/components/micrositeCustomRegistrationFields/micrositeCustomRegistrationPreview/micrositeCustomRegistrationPreview.definition";
import { CustomRegistrationFormIdModel } from "./components/customRegistrationForm/customRegistrationForm.definition";
import { EventRegistrationSplitLayoutIdModel } from "./components/eventRegistrationSplitLayout/eventRegistrationSplitLayout.definition";
import { PreRegistrationConfirmationIdModel } from "./components/preRegistration/preRegistrationConfirmation.definition";

export interface EventRegistrationProps extends BaseComponentProps {
  alreadyRegisteredSubmitError?: boolean;
  companyId: string;
  event?: PublicEventDetails;
  branding?: SavedEventBrandingType;
  eventTitle: string;
  eventType: EventType;
  loginAttempts?: number;
  isGuestAttendance?: boolean;
  isMiniPreview?: boolean;
  isPreview?: boolean;
  isCustomRegistrationPreview?: boolean;
  participantInfo?: Partial<Attendee>;
  showPinInput?: boolean;
  showPostEventRegistration?: boolean;
  showPreRegistrationConfirmation?: boolean;
  submitAlreadyRegisteredFormLoading?: boolean;
  attendeeRegistrationFormData?: AttendeeRegistrationFormData;
  onClickAlreadyRegisteredSubmit?(email: string): void;
  resetPin?: (controlNumber: string) => void;
  setShowPinInput?: (flag: boolean) => void;
  onClickPreRegistrationConfirmationWebcastLink?(): void;
  onSubmit?: (form: FormEditState, originType: AttendeeOriginType) => Promise<ErrorMessage>;
}

export interface BaseEventRegistration {
  id?: string;
  role?: AttendeeEventRoles;
  controlNumber?: string;
  pin?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  investorType?: InvestorTypeNames;
  companyName?: string;
  titleAtCompany?: string;
  captcha?: string;
  sendReminderEmail?: boolean;
  institutionDetails?: FormattedInstitution;
  registrationFieldAnswers?: AttendeeRegistrationFieldAnswer[];
}

export class FormEditState implements BaseEventRegistration {
  id;
  role;
  controlNumber = "";
  pin = "";
  firstName = "";
  lastName = "";
  email = "";
  investorType;
  companyName = "";
  titleAtCompany = "";
  sendReminderEmail = false;
  institutionDetails = {
    id: "",
    institutionName: "",
    customName: false,
  };
  captcha;
  registrationFieldAnswers: AttendeeRegistrationFieldAnswer[];

  constructor(event?: BaseEventRegistration) {
    const {
      id,
      role,
      controlNumber,
      pin,
      firstName,
      lastName,
      email,
      investorType,
      companyName,
      titleAtCompany,
      captcha,
      sendReminderEmail,
      institutionDetails,
      registrationFieldAnswers,
    } = event || {};

    this.id = id;
    this.role = role;
    this.controlNumber = controlNumber;
    this.pin = pin;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.investorType = investorType;
    this.companyName = companyName;
    this.titleAtCompany = titleAtCompany;
    this.captcha = captcha;
    this.sendReminderEmail = sendReminderEmail;
    this.institutionDetails = institutionDetails;
    this.registrationFieldAnswers = registrationFieldAnswers;
  }
}

export enum EventRegistrationClassNames {
  Base = "event-registration",
  BaseVSM = "event-registration-vsm",
  Header = "event-registration_header",
  Banner = "event-registration_banner",
  Content = "event-registration_content",
  Description = "event-registration_description",
  DescriptionDetails = "event-registration_description-details",
  Text = "event-registration_text",
  Requirements = "event-registration_requirements",
  AnchorLink = "event-registration_anchor-link",
  Overlay = "event-registration_overlay",
  Title = "event-registration_title",
  TitleContainer = "event-registration_title-container",
  FormContainer = "event-registration_form-container",
  PreRegistration = "event-registration_pre-regitration",
  PreRegistrationLink = "event-registration_pre-regitration_link",
  HeaderContainer = "event-registration_header-container",
  Footer = "event-registration_footer",
  FooterElemContainer = "event-registration_footer-elem-container",
  IsGuest = "event-registration_is-guest"
}

const dataIdPrefix = "EventRegistration";
export const DATA_IDS = {
  TITLE: `${dataIdPrefix}Title`,
  DESCRIPTION: `${dataIdPrefix}Description`,
  PHONE: `${dataIdPrefix}Phone`,
};

export const PostEventRegistrationTitle = "Please register to access our event summary";

export enum RegistrationType {
  PRE_REGISTRATION = "PRE_REGISTRATION",
  POST_REGISTRATION = "POST_REGISTRATION",
  LOBBY_REGISTRATION = "LIVE_REGISTRATION",
}

export class EventRegistrationIdModel extends IdModelBase {
  cusomRegistrationPreview: MicrositeCustomRegistrationPreviewIdModel;
  customRegistrationForm: CustomRegistrationFormIdModel;
  splitLayout: EventRegistrationSplitLayoutIdModel;
  preRegistrationConfirmation: PreRegistrationConfirmationIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.cusomRegistrationPreview = new MicrositeCustomRegistrationPreviewIdModel(
      `${this.id}Preview`
    );
    this.customRegistrationForm = new CustomRegistrationFormIdModel(
      `${this.id}CustomRegistrationForm`
    );
    this.splitLayout = new EventRegistrationSplitLayoutIdModel(`${this.id}SplitLayout`);
    this.preRegistrationConfirmation = new PreRegistrationConfirmationIdModel(
      `${this.id}PreRegistrationConfirmation`
    );
  }
}
