const developerFeatureFlags = {
  videoTrimFeature: true,
  newAttendeeLogin: false,
  newEventContentUploads: false,
  enabledSlideViewer: false,
};

export enum FeatureFlagKeys {
  AttendeeInstitutionLookup = "attendee-institution-lookup",
  CacheBuster = "cache-buster",
  EpAttendeeConsoleBranding = "ep-attendee-console-branding",
  EventBroadcastSource = "event-broadcast-source",
  LayoutManagerUi = "layout-manager-ui",
  MediaOnlyLayout = "MediaOnlyLayout",
  NetworkErrorModal = "network-error-modal",
  PlatformLogin = "platform-login",
  PostEventSnowplow = "post-event-snowplow",
  Q4LV2 = "q4-login-v2",
  RemoteBroadcastLayout = "remote-broadcast-layout",
  SnowplowCollector = "snowplow-collector",
  TestNetworkQuality = "test-network-quality",
  BehaviorTracking = "behavior-tracking",
  BroadcastOutput = "ep-broadcast-output-backup",
  EpRebrand = "ep-rebrand",
  CCEPOrganizationSync = "cc-ep-organization-sync",
  InvestorPortalIntake = "investor-portal-intake",
  VodPlayback = "vod-playback",
  DisasterRecovery = "disaster-recovery",
  ICIntakeMicrosite = "ic-intake-microsite",
  DualStream = "dual-streaming",
}

export default developerFeatureFlags;
