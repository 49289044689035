import React, { createContext, useState, useEffect, useCallback } from "react";

import { SessionContextModel, SessionProviderProps } from "./session.definition";
import SessionFactory from "./session.factory";

import useDominantSpeakerHook from "../../hooks/dominantSpeaker/dominantSpeaker.hook";
import { OTSessionType } from "../../types";

export const SessionContext: any = createContext<Partial<SessionContextModel>>({});

export const SessionProvider = (props: SessionProviderProps): JSX.Element => {
  const [OTSession, setOTSession] = useState<OTSessionType>();

  const { onStreamCreated, onStreamDestroyed } = props.streamEventCallbacks;
  const {
    streams,
    sessionEventCallbacks: { onDominantSpeakerChange, onSessionCreated, onSessionDestroyed },
  } = props;

  useDominantSpeakerHook({
    streams,
    OTSession,
    onDominantSpeakerChange,
  });

  useEffect(
    function onSessionUpdates() {
      if (OTSession) {
        onSessionCreated && onSessionCreated(OTSession);
      } else {
        onSessionDestroyed && onSessionDestroyed();
      }
    },
    [OTSession] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Create session when context is used
  useEffect(function createSession() {
    return createOTSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _onSessionCreated = useCallback(
    (session: OTSessionType) => {
      session && setOTSession(session);
      onSessionCreated?.(session);
    },
    [setOTSession, onSessionCreated]
  );

  function createOTSession() {
    const { apiKey, sessionId, token, options } = props;

    return SessionFactory({
      apiKey,
      sessionId,
      token,
      sessionEvents: {
        ...props.sessionEventCallbacks,
        onSessionCreated: _onSessionCreated,
      },
      sessionOptions: options || {},
      streamEvents: props.streamEventCallbacks,
    });
  }

  return (
    <SessionContext.Provider
      value={{
        OTSession,
        streams,
        onStreamCreated,
        onStreamDestroyed,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
