import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  useLazyQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";

export const CHANGE_VOLUME_ONLY_SECONDS = 2;

export const ERROR_POOLING_THRESHOLD = 5;

export interface AssetControlsProps {
  streams: BroadcastStream[];
  skip?: boolean;
  enablePollingInterval?: boolean;
}

export interface AssetControlsService {
  changeSlide: (stream: BroadcastStream, slideNumber: number) => Promise<any>;
  changeAudioVideo: (
    stream: BroadcastStream,
    action: Exclude<AssetControlsActions, AssetControlsActions.UPDATE_SLIDE>,
    volume?: number,
    position?: number
  ) => Promise<any>;
}

export interface AssetControlsHook {
  assetControlsService: AssetControlsService;
}

export enum AssetControlsActions {
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  UPDATE_SLIDE = "UPDATE_SLIDE",
}
export interface AssetControlsData {
  assetId: string;
  data: number;
  signalTime: string;
  action: AssetControlsActions;
  volume?: number;
}

export interface AssetControlsVarsBase {
  meetingId: number;
}

export interface ChangeAssetControlsVars extends AssetControlsVarsBase {
  assetControls: AssetControlsData;
}

export interface GetAssetControlsQueryData {
  getAssetControls: AssetControlsData[];
}

export interface ChangeAssetControlsMutationData {
  changeAssetControls: AssetControlsData;
}

export interface AssetControlChangeSubData {
  subscribeToAssetControlsChange: AssetControlsData;
}

export const GET_ASSET_CONTROLS = gql`
  query GET_ASSET_CONTROLS($meetingId: Int) {
    getAssetControls(meetingId: $meetingId) {
      assetId
      data
      signalTime
      action
      volume
    }
  }
`;

export const CHANGE_ASSET_CONTROLS = gql`
  mutation CHANGE_ASSET_CONTROLS($meetingId: Int, $assetControls: JSON) {
    changeAssetControls(meetingId: $meetingId, assetControls: $assetControls) {
      assetId
      data
      signalTime
      action
      volume
    }
  }
`;

export const SUBSCRIBE_TO_ASSET_CONTROLS_CHANGE = gql`
  subscription ($meetingId: Int) {
    subscribeToAssetControlsChange(meetingId: $meetingId) {
      assetId
      data
      signalTime
      action
      volume
    }
  }
`;

export function useGetAssetControlsQuery<D = GetAssetControlsQueryData, V = AssetControlsVarsBase>(
  options?: QueryHookOptions<D, V>
) {
  return useLazyQuery<D, V>(GET_ASSET_CONTROLS, options);
}

export function useChangeAssetControlsMutation<
  D = ChangeAssetControlsMutationData,
  V = ChangeAssetControlsVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation(CHANGE_ASSET_CONTROLS, options);
}

export function useSubscribeToAssetControlsChange<
  D = AssetControlChangeSubData,
  V = AssetControlsVarsBase
>(options?: SubscriptionHookOptions<D, V>) {
  return useSubscription(SUBSCRIBE_TO_ASSET_CONTROLS_CHANGE, options);
}
