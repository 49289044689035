import { useEffect } from "react";
import config from "../../../../config";

export const useMSClarity = () => {
  useEffect(function initializeMSClarity() {
    try {
      const script = document.createElement("script");
      script.type = "text/javascript";

      script.innerHTML = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${config.analytics.msClarity.apiKey}");
        `;

      const headElement = document.head || document.getElementsByTagName("head")?.[0];
      headElement.appendChild(script);
    } catch (err) {
      console.error(err);
    }
  }, []);
};
