import { FetchResult } from "@apollo/client";
import { Asset, EventAssetType } from "../../../../../hooks/assets/assets.hook.definition";

export interface FileUploaderProps {
  assets: Array<Asset>;
  createAsset: (file: File) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  fullScreen?: boolean;
  meetingId: string;
}

export interface FileUploaderBodyProps {
  isInfoDisplay: boolean;
  error: string;
}

export enum FileUploaderClassNames {
  Base = "file-uploader",
  Body = "file-uploader-body",
  Action = "file-uploader-body_action",
  Or = "file-uploader-body_or",
  Note = "file-uploader-body_note",
  Error_Text = "file-uploader-body_error-text",
  Error = "file-uploader-error",
  Disclaimer = "file-uploader-body_disclaimer",
}

export const FILE_SIZES = { BYTES: 1024, MEGABYTES: 1024 ** 2, GIGABYTES: 1024 ** 3 };

//Size in bytes
export const MAX_FILE_SIZE = {
  [EventAssetType.VIDEO]: 1 * FILE_SIZES.GIGABYTES,
  [EventAssetType.AUDIO]: 250 * FILE_SIZES.MEGABYTES,
  // [EventAssetType.IMAGE]: 200 * FILE_SIZES.MEGABYTES,
};
