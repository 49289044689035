import { Spinner } from "@q4/nimbus-ui";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, { memo, useCallback, useState } from "react";
import { pdfjs, Document } from "react-pdf";
import { SlideListRenderer } from "../slideListRenderer.component";
import "./slideList.component.scss";

// worker to render pdf
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const spinner = <Spinner masked={false} />;

interface SlideListProps {
  url: string;
  onLoaded?: (documentData: any) => void;
  onFailed?: (error: Error) => void;
  controlSlidesState: {
    currentSlide: number;
    totalSlides: number;
  };
  controlSlidesService: {
    jumpToSlide: (targetSlide: number) => void;
    setTotalSlides: (totalSlides: number) => void;
  };
}

const SlideListClassNames = {
  Base: "slide-list",
  Document: "slide-list_document",
};

const SlideList = (props: SlideListProps): JSX.Element => {
  const {
    url,
    onLoaded,
    onFailed,
    controlSlidesService: { jumpToSlide, setTotalSlides },
    controlSlidesState: { currentSlide, totalSlides },
  } = props;

  const [docLoaded, setDocLoaded] = useState(false);

  const handleDocumentLoad = useCallback(
    (data) => {
      const { numPages } = data;
      setDocLoaded(true);
      onLoaded?.(data);
      setTotalSlides(numPages);
    },
    [onLoaded, setTotalSlides]
  );

  return (
    <Document
      className={SlideListClassNames.Document}
      file={url}
      loading={spinner}
      noData={spinner}
      onLoadError={onFailed}
      onLoadSuccess={handleDocumentLoad}
    >
      {docLoaded && (
        <SlideListRenderer
          currentPage={currentSlide}
          pagesToRender={totalSlides}
          readyToRender={docLoaded}
          render={true}
          visible={true}
          onPageSelected={jumpToSlide}
        />
      )}
    </Document>
  );
};

export default memo(SlideList);
