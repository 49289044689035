import { Connection } from "./Connection.type";
import { Dimensions } from "./Dimensions.type";

export enum OTStreamTypes {
  Camera = "camera",
  Screen = "screen",
  Custom = "custom",
  PSTN = "pstn",
}

export interface OTStreamType {
  userType: string;
  connection: Connection;
  creationTime: number;
  frameRate: number;
  hasAudio: boolean;
  hasVideo: boolean;
  id: string;
  isLocalPublisher?: boolean;
  name: string;
  streamId: string;
  videoDimensions: Dimensions;
  videoType: OTStreamTypes;
}
