import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AudioVideoControls from "../../components/AudioVideoControls/AudioVideoControls.component";
import { AudioVideoState } from "../../components/AudioVideoControls/AudioVideoControls.definition";
import { EventAssetsContext } from "../../contexts";
import ConferenceContext from "../../contexts/conference/conference.context";
import { doNothing } from "../../utils";
import { SelectionModel } from "../../views/adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { AssetBroadcastStream } from "../../views/adminConsole/vendors/openTok/components/AssetBroadcastStream";
import { OpenTokClassNames } from "../../views/adminConsole/vendors/openTok/openTok.definition";
import { Asset } from "../assets/assets.hook.definition";
import { AssetUploads } from "../assetUploads/assetUploads.definition";
import { AssetControlsActions } from "../remoteAssetControls/remoteAssetControls.definition";

export function getEventAssetControlProps(assetMapperByName: object, selection: SelectionModel) {
  const assetIds = Object.keys(assetMapperByName);
  const { cameraSelection = [], mediaSelection = [] } = selection;
  const stream = [...cameraSelection, ...mediaSelection].find((stream) =>
    assetIds.includes(stream.isV2AssetStream ? stream.id : stream.name)
  );
  if (!stream) return null;
  return {
    stream,
    asset: assetMapperByName[stream.isV2AssetStream ? stream.id : stream.name],
  };
}

interface useDisplayControlAssetProps {
  selected: SelectionModel;
  staged: SelectionModel;
  assets: Array<Asset | AssetUploads>;
  hasPermission: boolean;
  displayActionButtons?: boolean;
  isAutoplayAsset?: boolean;
}

export default function useDisplayControlAsset(props: useDisplayControlAssetProps) {
  const {
    selected,
    staged,
    assets,
    hasPermission,
    displayActionButtons = true,
    isAutoplayAsset,
  } = props;

  const [isPlayingState, setIsPlayingState] = useState(AudioVideoState.PAUSE);
  const [volumeState, setVolumeState] = useState<number>(null);

  const { onSeek, onStart, onStop, onVolume, assetsState } = useContext(EventAssetsContext);
  const { remoteAssetControlsHook } = useContext(ConferenceContext);
  const { assetControlsService } = remoteAssetControlsHook;

  const assetMapperByName = useMemo(() => {
    let assetsMap = {};
    assets?.forEach((asset) => {
      const assetId = (asset as AssetUploads).id ?? asset.name;
      assetsMap[assetId] = asset;
    });
    return assetsMap;
  }, [assets]);

  const previewAssetInfo = useMemo(() => {
    return getEventAssetControlProps(assetMapperByName, selected);
  }, [selected, assetMapperByName]);

  const stagedAssetInfo = useMemo(() => {
    return getEventAssetControlProps(assetMapperByName, staged);
  }, [staged, assetMapperByName]);

  const onPlayStream = useCallback(
    (stream: AssetBroadcastStream) => {
      assetControlsService.changeAudioVideo(stream, AssetControlsActions.PLAY, stream.volume);
    },
    [assetControlsService]
  );

  const onPauseStream = useCallback(
    (stream: AssetBroadcastStream, position?: number) => {
      assetControlsService.changeAudioVideo(
        stream,
        AssetControlsActions.PAUSE,
        stream.volume,
        position
      );
    },
    [assetControlsService]
  );

  const onPlayFn = useCallback(() => {
    const { stream } = stagedAssetInfo;
    const assetStream = stream as AssetBroadcastStream;
    return stream.isV2AssetStream ? onPlayStream(assetStream) : onStart(stream);
  }, [stagedAssetInfo, onStart, onPlayStream]);

  const onPauseFn = useCallback(
    (position?: number) => {
      const { stream } = stagedAssetInfo;
      const assetStream = stream as AssetBroadcastStream;
      return stream.isV2AssetStream ? onPauseStream(assetStream, position) : onStop(stream);
    },
    [stagedAssetInfo, onStop, onPauseStream]
  );

  const onVolumeFn = useCallback(
    (volume) => {
      const { stream } = stagedAssetInfo;
      const assetStream = stream as AssetBroadcastStream;
      return stream.isV2AssetStream
        ? assetControlsService.changeAudioVideo(
            assetStream,
            assetStream.isPlaying ? AssetControlsActions.PLAY : AssetControlsActions.PAUSE,
            volume
          )
        : onVolume(stream, volume);
    },
    [stagedAssetInfo, assetControlsService, onVolume]
  );

  const onSeekFn = useCallback(() => {
    const { stream } = stagedAssetInfo;
    return stream.isV2AssetStream ? onPauseFn(0) : onSeek(stream);
  }, [stagedAssetInfo, onPauseFn, onSeek]);

  useEffect(() => {
    if (!stagedAssetInfo || !stagedAssetInfo.stream.isV2AssetStream) return;

    const assetStream = stagedAssetInfo.stream as AssetBroadcastStream;
    const state = assetStream?.isV2AssetStream
      ? ((assetStream.isPlaying
          ? AssetControlsActions.PLAY
          : AssetControlsActions.PAUSE) as unknown as AudioVideoState)
      : null;

    setIsPlayingState(state);
    setVolumeState(assetStream.volume);

    // register play/pause handlers in video element to update the state accordingly
    assetStream.onMediaPlay = () => setIsPlayingState(AudioVideoState.PLAY);
    assetStream.onMediaPause = () => setIsPlayingState(AudioVideoState.PAUSE);
    assetStream.onMediaVolume = (volume: number) => setVolumeState(volume);

    return () => {
      setIsPlayingState(AudioVideoState.PAUSE);
      assetStream.onMediaPlay = doNothing;
      assetStream.onMediaPause = doNothing;
      assetStream.onMediaVolume = doNothing;
    };
  }, [stagedAssetInfo, isPlayingState, volumeState]);

  const StagedControlAsset = useMemo(() => {
    if (!stagedAssetInfo || !hasPermission) return;
    const { stream } = stagedAssetInfo;
    const assetStream = stream as AssetBroadcastStream;
    const asset = assetsState.find(
      (assetCheck) => assetCheck.name === (stream.isV2AssetStream ? stream.id : stream.name)
    );

    const state = stream?.isV2AssetStream ? isPlayingState : asset?.state;
    const volume = stream?.isV2AssetStream ? volumeState ?? assetStream.volume : asset?.volume;

    return (
      <div className={OpenTokClassNames.AssetStaged}>
        <AudioVideoControls
          state={state}
          volume={volume}
          onPause={onPauseFn}
          onPlay={onPlayFn}
          onSeek={onSeekFn}
          onVolume={onVolumeFn}
          fileName={stagedAssetInfo.asset.displayName}
          volumeMax={100} //TODO switch to 200 when gain issue resolve
          isAutoplayAsset={isAutoplayAsset}
          asset={asset}
          selected={previewAssetInfo}
        />
      </div>
    );
  }, [
    stagedAssetInfo,
    hasPermission,
    assetsState,
    isAutoplayAsset,
    previewAssetInfo,
    isPlayingState,
    volumeState,
    onPlayFn,
    onPauseFn,
    onVolumeFn,
    onSeekFn,
  ]);

  const PreviewControlAsset = useMemo(() => {
    if (!previewAssetInfo || !hasPermission) return;
    const asset = assetsState.find((assetCheck) => {
      const stream = previewAssetInfo.stream;
      return assetCheck.name === (stream.isV2AssetStream ? stream.id : stream.name);
    });

    return (
      <>
        <div className={OpenTokClassNames.AssetPreview}>
          <AudioVideoControls
            state={asset?.state}
            volume={asset?.volume}
            onPause={() => onStop(previewAssetInfo.stream)}
            onPlay={() => onStart(previewAssetInfo.stream)}
            onSeek={() => onSeek(previewAssetInfo.stream)}
            onVolume={(volume) => onVolume(previewAssetInfo.stream, volume)}
            fileName={previewAssetInfo.asset.displayName}
            volumeMax={100} //TODO swicth to 200 when gain issue resolve
            displayActionButtons={displayActionButtons}
            asset={asset}
          />
        </div>
      </>
    );
  }, [
    previewAssetInfo,
    hasPermission,
    assetsState,
    displayActionButtons,
    onStop,
    onStart,
    onSeek,
    onVolume,
  ]);

  return { StagedControlAsset, PreviewControlAsset, onPlayStream, onPauseStream };
}
