/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEmpty, map, values } from "lodash";

import {
  BaseComponentProps,
  ButtonIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  ModalIdModel,
  ModalProps,
} from "@q4/nimbus-ui";

// Models, Service, Definitions, Helper
import {
  AttendeeConsoleBranding,
  ControlPanelBranding,
  EventRecordingUrlConfig,
  PostEventBranding,
  PreEventBranding,
  RegistrationBranding,
  ThankYouBranding,
  UploadedDataFormat,
  WelcomePageBranding,
} from "../../services/event/event.model";

import {
  GeneralFonts,
  DefaultFontColor,
  FontWeightTypes,
  DefaultHeaderBackground,
  DefaultFooterBackground,
  DefaultFooterButtonColor,
  DefaultMediaBarBackground,
} from "./components/micrositeControlPanelModal/controlPanelForm/controlPanelForm.definition";
import { ErrorModel } from "../../components/modal/modal.definition";
import {
  EventMicrositeConfigurationMusicOptions,
  EventMicrositeConfigurationPublishSchedule,
  EventMicrositeConfigurationVideoRecording,
  EventMicrositePageType,
  PublishScheduleStatusEnum,
} from "./hooks/microsite.hook.definition";

import { checkIfFontExists, extractTypenameFromData, isObjectEmpty } from "./helper";
import { DiscardModalConfirmationIdModel } from "./components/discardModalConfirmation/discardModalConfirmation.definition";
import { ControlPanelModalIdModel } from "./components/micrositeControlPanelModal/controlPanelModal.definition";
import { ExitModelConfirmationIdModel } from "./components/exitModalConfirmation/exitModalConfirmation.definition";

export interface MicrositeModalProps extends BaseComponentProps {
  companyId: string;
  eventId: string;
  meetingId: number;
  initialBroadcastingState: boolean;
  visible: boolean;
  onCloseRequest: ModalProps["onCloseRequest"];
}

export enum MicrositeModalClassNames {
  Base = "microsite-modal",
  Header = "microsite-modal_header",
  BackButton = "microsite-modal_header_back-button",
}

export class ControlPanelBrandingState {
  primaryColor: string;
  secondaryColor: string;
  primaryFont: UploadedDataFormat;
  secondaryFont: UploadedDataFormat;
  primaryFontWeight: string;
  secondaryFontWeight: string;
  publishSchedule: EventMicrositeConfigurationPublishSchedule;
  videoRecording: EventMicrositeConfigurationVideoRecording;
  publishScheduleStatus: PublishScheduleStatusEnum;
  companyLogo: UploadedDataFormat;
  backgroundImage: UploadedDataFormat;
  hideMeetingReplay: boolean;
  broadcastRecordings: EventRecordingUrlConfig[];

  constructor(
    controlPanelBranding?: ControlPanelBranding,
    companyLogo?: UploadedDataFormat,
    companyFonts?: UploadedDataFormat[],
    companyBackgroundImages?: UploadedDataFormat[],
    broadcastRecordings?: EventRecordingUrlConfig[]
  ) {
    const { companyLogo: logo, __typename, ...remainingBranding } = controlPanelBranding || {};

    if (isEmpty(remainingBranding) || values(remainingBranding).every(isEmpty)) {
      this.primaryFont = GeneralFonts[0].data;
      this.secondaryFont = GeneralFonts[0].data;
      this.primaryColor = DefaultFontColor;
      this.secondaryColor = DefaultFontColor;
      this.primaryFontWeight = FontWeightTypes[1].value;
      this.secondaryFontWeight = FontWeightTypes[0].value;
      this.publishSchedule = EventMicrositeConfigurationPublishSchedule.AUTOMATIC;
      this.publishScheduleStatus = PublishScheduleStatusEnum.UNPUBLISHED;
      this.videoRecording = EventMicrositeConfigurationVideoRecording.Default;
      this.companyLogo = companyLogo;
      this.hideMeetingReplay = false;
      this.broadcastRecordings = broadcastRecordings || [];
      return;
    }
    const savedPrimaryFont = checkIfFontExists(controlPanelBranding?.primaryFont, companyFonts)
      ? extractTypenameFromData(controlPanelBranding?.primaryFont)
      : {};

    const savedSecondaryFont = checkIfFontExists(controlPanelBranding?.secondaryFont, companyFonts)
      ? extractTypenameFromData(controlPanelBranding?.secondaryFont)
      : {};
    const doesBackgroundImageExist = map(companyBackgroundImages, "name").includes(
      controlPanelBranding?.backgroundImage?.name
    );

    this.primaryColor = controlPanelBranding?.primaryColor || DefaultFontColor;
    this.secondaryColor = controlPanelBranding?.secondaryColor || DefaultFontColor;
    this.primaryFont = isObjectEmpty(savedPrimaryFont) ? GeneralFonts[0].data : savedPrimaryFont;
    this.secondaryFont = isObjectEmpty(savedSecondaryFont)
      ? GeneralFonts[0].data
      : savedSecondaryFont;
    this.primaryFontWeight = controlPanelBranding?.primaryFontWeight || FontWeightTypes[1].value;
    this.secondaryFontWeight =
      controlPanelBranding?.secondaryFontWeight || FontWeightTypes[0].value;
    this.publishSchedule =
      controlPanelBranding?.publishSchedule || EventMicrositeConfigurationPublishSchedule.AUTOMATIC;
    this.publishScheduleStatus =
      controlPanelBranding?.publishScheduleStatus || PublishScheduleStatusEnum.UNPUBLISHED;
    this.videoRecording =
      controlPanelBranding?.videoRecording || EventMicrositeConfigurationVideoRecording.Default;
    this.companyLogo = controlPanelBranding?.companyLogo || companyLogo;
    this.backgroundImage = doesBackgroundImageExist ? controlPanelBranding?.backgroundImage : null;
    this.hideMeetingReplay = controlPanelBranding?.hideMeetingReplay || false;
    this.broadcastRecordings = broadcastRecordings || [];
  }
}

export class ControlPanelBrandingErrorState {
  primaryFontError: ErrorModel;
  secondaryFontError: ErrorModel;
  primaryColorError: ErrorModel;
  secondaryColorError: ErrorModel;

  constructor(state: ControlPanelBrandingErrorState) {
    this.primaryFontError = state.primaryFontError;
    this.secondaryFontError = state.secondaryFontError;
    this.primaryColorError = state.primaryColorError;
    this.secondaryColorError = state.secondaryColorError;
  }
}

export interface OpenRegistrationSelectType {
  value: string;
  label: string;
}

export class WelcomePageBrandingState {
  title: string;
  description?: string;
  proxyWebsiteLink?: string;
  displayTime?: boolean;

  constructor(
    welcomePageBranding?: WelcomePageBranding,
    eventTitle?: string,
    eventDescription?: string
  ) {
    if (isEmpty(welcomePageBranding)) {
      this.title = eventTitle || "";
      this.description = eventDescription || "";
      this.displayTime = true;
      return;
    }

    this.title = welcomePageBranding.title || eventTitle || "";
    this.description = welcomePageBranding.description || eventDescription || "";
    this.proxyWebsiteLink = welcomePageBranding.proxyWebsiteLink;
    this.displayTime = !!welcomePageBranding.displayTime;
  }
}

export class WelcomePageBrandingErrorState {
  titleError: ErrorModel;
  proxyWebsiteLinkError: ErrorModel;

  constructor(state: WelcomePageBrandingErrorState) {
    this.titleError = state.titleError;
    this.proxyWebsiteLinkError = state.proxyWebsiteLinkError;
  }
}

export class RegistrationBrandingState {
  title: string;
  description?: string;
  broadridgeTelephoneNumber?: string;
  broadridgeInternationalTelephoneNumber?: string;
  openRegistration: string;
  proxyWebsiteLink?: string;
  termsAndConditionsUrl?: string;

  constructor(
    registrationPageBranding?: RegistrationBranding,
    eventTitle?: string,
    eventDescription?: string
  ) {
    if (isEmpty(registrationPageBranding)) {
      this.title = eventTitle || "";
      this.description = eventDescription || "";
      this.openRegistration = OpenRegistrationTypes[2].value;
      return;
    }

    this.title = registrationPageBranding.title || eventTitle || "";
    this.description = registrationPageBranding.description || eventDescription || "";
    this.broadridgeTelephoneNumber = registrationPageBranding.broadridgeTelephoneNumber;
    this.broadridgeInternationalTelephoneNumber =
      registrationPageBranding.broadridgeInternationalTelephoneNumber;
    this.openRegistration =
      registrationPageBranding?.openRegistration || OpenRegistrationTypes[2].value;
    this.proxyWebsiteLink = registrationPageBranding.proxyWebsiteLink;
    this.termsAndConditionsUrl = registrationPageBranding.termsAndConditionsUrl;
  }
}

export class RegistrationBrandingErrorState {
  titleError: ErrorModel;
  broadridgeTelephoneNumberError: ErrorModel;
  broadridgeInternationalTelephoneNumberError: ErrorModel;
  openRegistrationError: ErrorModel;
  proxyWebsiteLinkError: ErrorModel;
  termsAndConditionsUrlError: ErrorModel;

  constructor(state: RegistrationBrandingErrorState) {
    this.titleError = state.titleError;
    this.broadridgeTelephoneNumberError = state.broadridgeTelephoneNumberError;
    this.broadridgeInternationalTelephoneNumberError =
      state.broadridgeInternationalTelephoneNumberError;
    this.proxyWebsiteLinkError = state.proxyWebsiteLinkError;
    this.openRegistrationError = state.openRegistrationError;
    this.termsAndConditionsUrlError = state.termsAndConditionsUrlError;
  }
}

export class PreEventPageBrandingState {
  message: string;
  lobbyMessage: string;
  musicOption: EventMicrositeConfigurationMusicOptions;

  constructor(preEventPageBranding?: PreEventBranding) {
    if (isEmpty(preEventPageBranding)) {
      this.musicOption = MusicOptions[0].value;
      this.lobbyMessage = DefaultPreEventMessage;
      this.message = DefaultPreEventMessage;
      return;
    }

    this.message = preEventPageBranding.message || DefaultPreEventMessage;
    this.musicOption = preEventPageBranding.musicOption || MusicOptions[0].value;
    this.lobbyMessage = preEventPageBranding.lobbyMessage || DefaultPreEventMessage;
  }
}

export class PreEventPageBrandingErrorState {
  messageError: ErrorModel;
  musicOptionError: ErrorModel;
  lobbyMessageError: ErrorModel;

  constructor(state: PreEventPageBrandingErrorState) {
    this.messageError = state.messageError;
    this.musicOptionError = state.musicOptionError;
    this.lobbyMessageError = state.lobbyMessageError;
  }
}

export class ThankYouPageBrandingState {
  title: string;
  description?: string;

  constructor(
    thankyouPageBranding?: ThankYouBranding,
    eventTitle?: string,
    eventDescription?: string
  ) {
    if (isEmpty(thankyouPageBranding)) {
      this.title = eventTitle || "";
      this.description = eventDescription || "";
      return;
    }

    this.title = thankyouPageBranding.title || eventTitle || "";
    this.description = thankyouPageBranding.description || eventDescription || "";
  }
}

export class ThankYouPageBrandingErrorState {
  titleError: ErrorModel;

  constructor(state: ThankYouPageBrandingErrorState) {
    this.titleError = state.titleError;
  }
}

export class PostEventPageBrandingState {
  meetingDetails?: string;

  constructor(postEventPageBranding?: PostEventBranding) {
    if (isEmpty(postEventPageBranding)) {
      this.meetingDetails = "";
      return;
    }

    this.meetingDetails = postEventPageBranding.meetingDetails || "";
  }
}

export class AttendeeConsoleBrandingState {
  customBranding?: AttendeeConsoleBranding = {
    images: {},
    headerColors: {},
    footerColors: {},
    mediaBarColors: {},
  };

  constructor(attendeeConsoleBranding?: AttendeeConsoleBranding) {
    if (isEmpty(attendeeConsoleBranding)) {
      this.customBranding = {
        images: {},
        headerColors: {
          background: DefaultHeaderBackground,
          text: DefaultFontColor,
        },
        footerColors: {
          background: DefaultFooterBackground,
          primaryButton: DefaultFooterButtonColor,
          primaryButtonText: DefaultFontColor,
        },
        mediaBarColors: {
          background: DefaultMediaBarBackground,
          controls: DefaultFontColor,
        },
      };
    }

    if (!isEmpty(attendeeConsoleBranding)) {
      this.customBranding = { ...attendeeConsoleBranding };
    }
  }
}

export class AttendeeConsoleBrandingErrorState {
  // background color errors
  headerError: ErrorModel;
  footerError: ErrorModel;
  mediaBarError: ErrorModel;

  // foreground color errors
  primaryButtonError: ErrorModel;
  primaryButtonTextError: ErrorModel;
  controlButtonError: ErrorModel;
  headerTextError: ErrorModel;

  constructor(state: AttendeeConsoleBrandingErrorState) {
    this.headerError = state.headerError;
    this.footerError = state.footerError;
    this.mediaBarError = state.mediaBarError;

    this.primaryButtonError = state.primaryButtonError;
    this.primaryButtonTextError = state.primaryButtonTextError;
    this.controlButtonError = state.controlButtonError;
    this.headerTextError = state.headerTextError;
  }
}

export const DefaultPreEventMessage = "Please hold tight, the broadcast will begin shortly.";

export const OpenRegistrationTypes = [
  { value: "5", label: "5 minutes before event" },
  { value: "10", label: "10 minutes before event" },
  { value: "15", label: "15 minutes before event" },
  { value: "30", label: "30 minutes before event" },
  { value: "60", label: "60 minutes before event" },
  { value: "90", label: "90 minutes before event" },
];

export const MusicOptions = [
  {
    id: "default",
    name: "DefaultMusic",
    label: "Default music",
    value: EventMicrositeConfigurationMusicOptions.DEFAULT,
  },
  {
    id: "none",
    name: "NoMusic",
    label: "No music",
    value: EventMicrositeConfigurationMusicOptions.NONE,
  },
  {
    id: "custom",
    name: "CustomMusic",
    label: "Custom music",
    value: EventMicrositeConfigurationMusicOptions.CUSTOM,
  },
];

/**
 * type input that shows error
 */
export enum MicrositeModalErrorKey {
  Title = "title",
  broadridgeTelephoneNumber = "broadridgeTelephoneNumber",
  BroadridgeInternationalTelephoneNumber = "broadridgeInternationalTelephoneNumber",
  ProxyWebsiteLink = "proxyWebsiteLink",
  TermsAndConditionsUrl = "termsAndConditionsUrl",
  OpenRegistration = "openRegistration",
  Message = "message",
  MusicOption = "musicOption",
  PrimaryFont = "primaryFont",
  SecondaryFont = "secondaryFont",
  PrimaryColor = "primaryColor",
  SecondaryColor = "secondaryColor",

  HeaderTextColor = "headerTextColor",
  HeaderBackgroundColor = "headerBackgroundColor",
  MediaBarControlsColor = "mediaBarControlsColor",
  MediaBarBackgroundColor = "mediaBarBackgroundColor",
  FooterPrimaryButtonColor = "footerPrimaryButtonColor",
  FooterPrimaryButtonTextColor = "footerPrimaryButtonTextColor",
  FooterBackgroundColor = "footerBackgroundColor",
}

export enum AttendeeConsoleBrandingErrorKeys {
  HeaderText = "headerTextError",
  Header = "headerError",
  ControlButton = "controlButtonError",
  MediaBar = "mediaBarError",
  Footer = "footerError",
  PrimaryButton = "primaryButtonError",
  PrimaryButtonText = "primaryButtonTextError",
}

export interface AttendeeConsoleFieldErrorMap {
  key: AttendeeConsoleBrandingErrorKeys;
  value?: string;
  errorKey: MicrositeModalErrorKey;
  errorMessage: MicrositeModalErrorMessage;
}

/**
 * error messages
 */
export enum MicrositeModalErrorMessage {
  titleErrorMessage = "Title Required",
  primaryFontErrorMessage = "Primary Font Required",
  secondaryFontErrorMessage = "Secondary Font Required",
  primaryColorErrorMessage = "Primary Color Required",
  secondaryColorErrorMessage = "Secondary Color Required",

  headerTextColorErrorMessage = "Header Text Color Required",
  headerBackgroundColorErrorMessage = "Header Background Color Required",
  mediaBarControlsColorErrorMessage = "Media Bar Controls Color Required",
  mediaBarBackgroundColorErrorMessage = "Media Bar Background Color Required",
  footerPrimaryButtonColorErrorMessage = "Footer Button Color Required",
  footerPrimaryButtonTextColorErrorMessage = "Footer Button Label Color Required",
  footerBackgroundColorErrorMessage = "Footer Background Color Required",

  invalidBroadridgeTelephoneNumberErrorMessage = "Invalid Broadridge Telephone Number",
  invalidProxyWebsiteLinkErrorMessage = "Invalid Proxy Website Link",
  invalidTermsAndConditionsUrl = "Invalid Terms And Conditions Url",
  openRegistrationErrorMessage = "Open Registration Option Required",
  emptyMessageErrorMessage = "Message Required",
  musicOptionErrorMessage = "Music Option Required",
}

const dataIdPrefix = "MicrositeModal";
export const DATA_IDS = {
  MODAL_DIALOG: `${dataIdPrefix}Dialog`,
  MODAL_TITLE: `${dataIdPrefix}Title`,
  NEXT_BUTTON: `${dataIdPrefix}NextButton`,
  PREVIOUS_BUTTON: `${dataIdPrefix}PreviousButton`,
  PREVIEW_BUTTON: `${dataIdPrefix}PreviewButton`,
  SAVE_AND_EXIT_BUTTON: `${dataIdPrefix}SaveAndExitButton`,
  CANCEL_SAVE_MICROSITE_BUTTON: `${dataIdPrefix}CancelSaveMicrositeButton`,
  CONFIRM_SAVE_MICROSITE_BUTTON: `${dataIdPrefix}ConfirmSaveMicrositeButton`,
  CANCEL_DISCARD_MICROSITE_BUTTON: `${dataIdPrefix}CancelDiscardMicrositeButton`,
  CONFIRM_DISCARD_MICROSITE_BUTTON: `${dataIdPrefix}ConfirmDiscardMicrositeButton`,
};

export const MicrositeEditWarning = "The microsite cannot be edited once the event has started.";
export const SaveMicrositeConfigurationError =
  "Please fix the error on the current page before attempting to save and exit.";
export const PreviewMicrositeConfigurationError =
  "Please fix the error on the current page before attempting to preview.";
export const MAX_CUSTOM_POST_EVENT_REPLAY_UPLOAD_FILE_SIZE = 4e9;
export const ACCEPTABLE_CUSTOM_POST_EVENT_REPLAY_FORMAT = ["mp4", "quicktime", "x-m4v", "webm"];

export const MicrositePages = {
  [EventMicrositePageType.WELCOME_PAGE]: { label: "Welcome" },
  [EventMicrositePageType.REGISTRATION_PAGE]: { label: "Registration" },
  [EventMicrositePageType.PRE_EVENT_PAGE]: { label: "Pre-Event" },
  [EventMicrositePageType.THANK_YOU_PAGE]: { label: "Thank You" },
  [EventMicrositePageType.POST_EVENT_PAGE]: { label: "Post-Event Replay" },
  [EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE]: { label: "Page Styling" },
};

export const MicrositePagesOrder = [
  EventMicrositePageType.WELCOME_PAGE,
  EventMicrositePageType.REGISTRATION_PAGE,
  EventMicrositePageType.PRE_EVENT_PAGE,
  EventMicrositePageType.THANK_YOU_PAGE,
  EventMicrositePageType.POST_EVENT_PAGE,
];

export const EarningsMicrositePagesOrder = [
  EventMicrositePageType.REGISTRATION_PAGE,
  EventMicrositePageType.WELCOME_PAGE,
  EventMicrositePageType.PRE_EVENT_PAGE,
  EventMicrositePageType.THANK_YOU_PAGE,
  EventMicrositePageType.POST_EVENT_PAGE,
];

export const AttendeeConsoleMicrositePagesOrder = [
  EventMicrositePageType.ATTENDEE_CONSOLE_BRANDING_PAGE,
  EventMicrositePageType.REGISTRATION_PAGE,
  EventMicrositePageType.WELCOME_PAGE,
  EventMicrositePageType.PRE_EVENT_PAGE,
  EventMicrositePageType.THANK_YOU_PAGE,
  EventMicrositePageType.POST_EVENT_PAGE,
];

export class MicrositeModalIdModel extends IdModelBase {
  modal: ModalIdModel;
  discardModal: DiscardModalConfirmationIdModel;
  exitModal: ExitModelConfirmationIdModel;
  backButton: ButtonIdModel;
  controlPanel: ControlPanelModalIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.modal = new ModalIdModel(`${this.id}Modal`);
    this.discardModal = new DiscardModalConfirmationIdModel(`${this.id}DiscardModal`);
    this.exitModal = new ExitModelConfirmationIdModel(`${this.id}ExitModal`);
    this.backButton = new ButtonIdModel(`${this.id}BackButton`);
    this.controlPanel = new ControlPanelModalIdModel(`${this.id}ControlPanel`);
  }
}
