import { useState } from "react";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";

export interface DominantSpeakerModel {
  dominantSpeakerStream: BroadcastStream;
  dominantSpeakerName: string;
  dominantSpeakerService: {
    updateDominantSpeaker(stream: BroadcastStream, name: string): void;
  };
}

export function useDominantSpeaker(): DominantSpeakerModel {
  const [dominantSpeakerStream, setDominantSpeakerStream] = useState<BroadcastStream>();
  const [dominantSpeakerName, setDominantSpeakerName] = useState<string>();

  function updateDominantSpeaker(stream: BroadcastStream, name: string): void {
    setDominantSpeakerStream(stream);
    setDominantSpeakerName(name);
  }

  return {
    dominantSpeakerName,
    dominantSpeakerStream,
    dominantSpeakerService: {
      updateDominantSpeaker,
    },
  };
}
