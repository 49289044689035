import React, { createContext, FunctionComponent, useCallback, useMemo, useState } from "react";
import OTPublisher from "../../opentok-react/components/OTPublisher/OTPublisher.component";
import OTShareScreenPublisher from "../../opentok-react/components/OTShareScreenPublisher/OTShareScreenPublisher.component";
import { SessionProvider } from "../../opentok-react/contexts/session/session.context";
import { OpenTokConfig } from "../../views/adminConsole/vendors/openTok/openTok.definition";
import { SubscribersPortal } from "../../views/carousel/components/subscribers/SubscribersRenderer.component";
import AssetsPortal from "../../components/AssetsPortal/assetsPortal.component";
import {
  ConferenceContextProps,
  ConferenceContextState,
  ConferenceSubscriberProps,
} from "./conference.definition";
import config from "../../config";
import { AssetsPortalProps } from "../../components/AssetsPortal/assetsPortal.definition";
import useRemoteAssetControlsHook from "../../hooks/remoteAssetControls/remoteAssetControls.hook";

const ConferenceContext = createContext<Partial<ConferenceContextState>>({});

export const ConferenceProvider: FunctionComponent<ConferenceContextProps> = (
  props
): JSX.Element => {
  const {
    streams,
    children,
    sessionId,
    token,
    streamEventHandlers,
    sessionEventHandlers,
    cameraPublisherSettings,
    screenPublisherSettings,
    assetsPortalSettings,
    subscriberSettings,
    isSessionReadyToConnect,
    publishingAudio,
    publishingVideo,
  } = props;

  const { cameraPublisherEnabled, cameraPublisherProps } = useMemo(() => {
    const { enabled: cameraPublisherEnabled, ...cameraPublisherProps } =
      cameraPublisherSettings ?? {};
    return { cameraPublisherEnabled, cameraPublisherProps };
  }, [cameraPublisherSettings]);

  const { screenPublisherEnabled, screenPublisherProps } = useMemo(() => {
    const { enabled: screenPublisherEnabled, ...screenPublisherProps } =
      screenPublisherSettings ?? {};
    return { screenPublisherEnabled, screenPublisherProps };
  }, [screenPublisherSettings]);

  const [publishFailed, setPublishFailed] = useState(false);

  const {
    assets,
    visible: assetsPortalVisible = false,
    enabled: assetsPortalEnabled = false,
    onAssetRendered,
    enablePollingInterval = false,
  } = assetsPortalSettings ?? {};

  const { subscribersEnabled, visible, className, subscribersProps } = useMemo(() => {
    const {
      enabled: subscribersEnabled,
      visible = false,
      className,
      ...subscribersProps
    } = subscriberSettings ?? ({} as ConferenceSubscriberProps);
    return { subscribersEnabled, visible, className, subscribersProps };
  }, [subscriberSettings]);

  const createCameraPublisherError = useCallback(
    (event) => {
      setPublishFailed(true);
      cameraPublisherSettings.onError(event);
    },
    [cameraPublisherSettings]
  );

  const initSubscriberProperties = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      publishVideo: publishingVideo,
      publishAudio: publishingAudio,
      mirror: true,
      fitMode: "contain",
      resolution: "1280x720",
      style: {
        nameDisplayMode: "off",
        buttonDisplayMode: "off",
        audioLevelDisplayMode: "off",
        backgroundImageURI: config.adminConsole.streamThumbnails.camera,
      },
      audioFallbackEnabled: false,
    }),
    [publishingAudio, publishingVideo]
  );

  const cameraInitPublisherProperties = useMemo(
    () => ({
      ...initSubscriberProperties,
      ...cameraPublisherProps.initPublisherProperties,
    }),
    [initSubscriberProperties, cameraPublisherProps.initPublisherProperties]
  );

  const initShareScreenPublisherProps = useMemo(
    () => ({
      ...initSubscriberProperties,
      ...screenPublisherProps.initPublisherProperties,
    }),
    [initSubscriberProperties, screenPublisherProps.initPublisherProperties]
  );

  const assetsPortalProps: AssetsPortalProps = useMemo(
    () => ({
      assets,
      visible: assetsPortalVisible,
      onAssetRendered,
    }),
    [assets, assetsPortalVisible, onAssetRendered]
  );

  const remoteAssetControlsHook = useRemoteAssetControlsHook({
    streams,
    skip: !assetsPortalEnabled,
    enablePollingInterval,
  });

  return (
    <ConferenceContext.Provider
      value={{
        streams,
        initSubscriberProperties,
        publishFailed,
        setPublishFailed,
        subscribersProps,
        remoteAssetControlsHook,
      }}
    >
      {isSessionReadyToConnect && (
        <SessionProvider
          apiKey={OpenTokConfig.apiToken}
          sessionId={sessionId}
          token={token}
          streamEventCallbacks={streamEventHandlers}
          sessionEventCallbacks={sessionEventHandlers}
          streams={streams}
        >
          {children}
          {subscribersEnabled && (
            <SubscribersPortal
              className={className}
              visible={visible}
              streams={streams}
              subscribersProps={subscribersProps}
              initSubscriberProperties={initSubscriberProperties}
            />
          )}
          {assetsPortalEnabled && <AssetsPortal {...assetsPortalProps} />}
          {cameraPublisherEnabled && (
            <OTPublisher
              {...cameraPublisherProps}
              initPublisherProperties={cameraInitPublisherProperties}
              onCreatePublisherError={createCameraPublisherError}
              publishFailed={publishFailed}
            />
          )}
          {screenPublisherEnabled && (
            <OTShareScreenPublisher
              {...screenPublisherProps}
              initShareScreenPublisherProps={initShareScreenPublisherProps}
            />
          )}
        </SessionProvider>
      )}
    </ConferenceContext.Provider>
  );
};

export default ConferenceContext;
