import { BroadcastStream } from "../../../interfaces/broadcastStream/broadcastStream";
import {
  StreamFilterFunctions,
  StreamFilterKeys,
  StreamFilters,
} from "../../../interfaces/trackBroadcastStreams/trackBroadcastStreams.definition";

export function filterStreams(
  streams: BroadcastStream[],
  filters: StreamFilterKeys | StreamFilterFunctions
): BroadcastStream[] {
  return typeof filters === "function"
    ? streams.filter(filters)
    : streams.filter(StreamFilters[filters]);
}
