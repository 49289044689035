import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import "./helpCenterUpload.component.scss";

export interface ListHelpCenterFilesQueryParams {
  private: boolean;
  fileCount?: number;
  nextPage?: string;
}

export interface ListHelpCenterFilesQueryData {
  listHelpCenterFiles: any;
}

export const LIST_FILES_QUERY = gql`
  query ($private: Boolean, $fileCount: Int, $nextPage: String) {
    listHelpCenterFiles(private: $private, fileCount: $fileCount, nextPage: $nextPage)
  }
`;

export interface UploadHelpCenterFileMutationParams {
  file: File;
  private?: boolean;
  metadata?: Record<string, string>;
}

export interface UploadHelpCenterFileMutationData {
  uploadHelpCenterFile: string;
}

export const UPLOAD_FILE_MUTATION = gql`
  mutation ($file: Upload, $private: Boolean, $metadata: JSON) {
    uploadHelpCenterFile(file: $file, private: $private, metadata: $metadata)
  }
`;

export const useListHelpCenterFilesQuery = (
  options?: QueryHookOptions<ListHelpCenterFilesQueryData, ListHelpCenterFilesQueryParams>
) => {
  return useLazyQuery<ListHelpCenterFilesQueryData, ListHelpCenterFilesQueryParams>(
    LIST_FILES_QUERY, options
  );
};

export const useUploadHelpCenterFileMutation = (
  options: MutationHookOptions<UploadHelpCenterFileMutationData, UploadHelpCenterFileMutationParams>
) => {
  return useMutation<UploadHelpCenterFileMutationData, UploadHelpCenterFileMutationParams>(
    UPLOAD_FILE_MUTATION,
    options
  );
};
