import { RouteComponentProps } from "react-router";

interface MatchParams {
  meetingId: string;
}

export interface RemoteBroadcastLayoutProps extends RouteComponentProps<MatchParams> {
  skip?: boolean;
}

export enum RemoteBroadcastLayoutClassNames {
  Base = "remote_broadcast_layout",
  LocalBase = "remote_broadcast_layout--local",
  Subscribers = "remote_broadcast_layout_subscribers",
  VideoContainer = "remote_broadcast_layout_video_container",
  VideoWrappers = "remote_broadcast_layout_video_wrappers",
}

export enum RemoteBroadcastLayoutActions {
  StreamsStaged = "remote-broadcast-streams-staged",
  ConnectionInitiated = "remote-broadcast-connection-initiated",
  ConnectionEstablished = "remote-broadcast-connection-established",
}

export enum RemoteBroadcastLayoutActionMessages {
  StreamsStaged = "Remote broadcast streams staged",
  ConnectionInitiated = "Remote broadcast connection initiated",
  ConnectionEstablished = "Remote broadcast connection established",
}

export const OT_PR_EMAIL = "otpr_public_page@q4inc.com";
export const OT_PR_FIRST_NAME = "OT-PR PSTN Publisher";
export const OT_PR_LAST_NAME = "(IGNORE)";
export const OT_PR_NAME = `${OT_PR_FIRST_NAME} ${OT_PR_LAST_NAME}`;
