import { ApolloLink, Operation, FetchResult, Observable } from "@apollo/client/core";
import { print, GraphQLError } from "graphql";
import { createClient, ClientOptions, Client } from "graphql-ws";
import { castArray, omit } from "lodash";
import config from "../../config";
import {
  COMPANY_ID_HEADER_KEY,
  MEETING_ID_HEADER_KEY,
} from "../../hoc/cacheBuster/hooks/versionQuery.hook";
import { isTenantedView } from "../../utils/event.utils";
import { isAttendeeView } from "../../utils/attendee.utils";

interface WebSocketLinkOptions {
  connectionParams: () => Promise<Record<string, unknown>>;
  allowWindowReload?: boolean;
  notifyConnected: () => void;
  notifyReconnected: () => void;
  notifyConnectionError: (error: any) => void;
  notifyDisconnected: () => void;
}

const FALLBACK_TIMEOUT = 10000;
const fatalApolloErrorCodes = [4500, 4400, 4401, 4406, 4409, 4429];
const wsUrl = isAttendeeView() ? config.socket.attendeeUrl : config.socket.url;

const shouldRetry = (errOrCloseEvent: Error & CloseEvent) => {
  return !fatalApolloErrorCodes.includes(errOrCloseEvent.code);
};

// retry to reconnect every 10 seconds
const retryWait = () => new Promise((resolve) => setTimeout(resolve, 10_000));

export class WebSocketLink extends ApolloLink {
  private static instance: WebSocketLink;

  private client: Client;
  private defaultOptions: Partial<ClientOptions>;
  private options: WebSocketLinkOptions;
  private timer: ReturnType<typeof setTimeout>;
  private queryParameters: string;
  private activeSocket: WebSocket;
  private isSocketReconnect: boolean;
  private pingPongTimer: ReturnType<typeof setTimeout>;

  private resolve;
  private isReady = new Promise((resolve) => {
    this.resolve = resolve;
  });

  // Singleton
  private constructor(options: WebSocketLinkOptions) {
    super();
    this.options = options;
    this.defaultOptions = {
      url: wsUrl,
      lazy: true,
      keepAlive: 20_000,
      retryAttempts: 30,
      on: {
        opened: this.onOpened.bind(this),
        connected: this.onConnected.bind(this),
        error: this.onConnectionError.bind(this),
        closed: this.onClosed.bind(this),
        ping: this.onPing.bind(this),
        pong: this.onPong.bind(this),
      },
      shouldRetry,
      retryWait: async () => {
        await retryWait();
      },
    };
  }

  private onOpened(socket: WebSocket): void {
    this.activeSocket = socket;
  }

  private onConnected(): void {
    if (!this.isSocketReconnect) {
      this.options.notifyConnected();
      this.isSocketReconnect = true;
    } else {
      this.options.notifyReconnected();
    }
  }

  private onConnectionError(error: unknown): void {
    this.options.notifyConnectionError(error);
    console.error(error);
  }

  private onClosed(): void {
    this.options.notifyDisconnected();
  }

  private onPing(received: boolean): void {
    if (!received /* sent */) {
      this.pingPongTimer = setTimeout(() => {
        if (this.activeSocket && this.activeSocket.readyState === WebSocket.OPEN) {
          this.activeSocket.close(4205, "Client Restart");
          // On mobile devices (especially on IPad/Safari) when the user locks the screen the browser drops the WebSocket connection due to inactivity.
          // After unlocking socket stays as open in the JS however it freezes and doesn't react to messages.
          // https://stackoverflow.com/questions/24796103/keep-websocket-alive-in-mobile-safari
          //
          // https://github.com/enisdenjo/graphql-ws/issues/289
          //
          // Furthermore, the Maintainer of the `graphql-ws` recommends the following approach
          // https://github.com/enisdenjo/graphql-ws/discussions/290
          if (this.options.allowWindowReload) {
            window.location.reload();
          }
        }
      }, 10_000); // wait 10 seconds for the pong and then close the connection
    }
  }

  private onPong(received: boolean) {
    if (received) {
      clearTimeout(this.pingPongTimer); // pong is received, clear connection close timeout
    }
  }

  public static getInstance(options: WebSocketLinkOptions): WebSocketLink {
    if (!WebSocketLink.instance) {
      WebSocketLink.instance = new WebSocketLink(options);
    }

    return WebSocketLink.instance;
  }

  public setReady({
    isAuthenticated,
    allowWindowReload,
    queryParameters,
  }: {
    isAuthenticated: boolean;
    allowWindowReload: boolean;
    queryParameters: string;
  }): void {
    if (!isAuthenticated || this.client) {
      return;
    }
    this.queryParameters = queryParameters;
    this.options.allowWindowReload = allowWindowReload;

    if (
      (isTenantedView() && queryParameters.includes(MEETING_ID_HEADER_KEY)) ||
      queryParameters.includes(COMPANY_ID_HEADER_KEY)
    ) {
      // If we're running with fallback and `companyId` appears before fallback's timeout exceeds.
      // we have to clear the timeout and resolve the waiting immediately to prevent double resolution.
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.resolve();
    } else {
      this.fallBack();
    }
  }

  private fallBack() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.resolve();
    }, FALLBACK_TIMEOUT);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      this.subscribe(operation, sink);
    });
  }

  private async subscribe(operation: Operation, sink) {
    if (!this.client) {
      await this.initClient();
    }

    this.client.subscribe<FetchResult>(
      { ...operation, query: print(operation.query) },
      {
        next: sink.next.bind(sink),
        complete: sink.complete.bind(sink),
        error: (err) => {
          if (err instanceof Error) {
            return sink.error(err);
          }

          if (err instanceof CloseEvent) {
            return sink.error(
              // reason will be available on clean closes
              new Error(`Socket closed with event ${err.code} ${err.reason || ""}`)
            );
          }

          return sink.error(
            new Error((castArray(err) as GraphQLError[]).map(({ message }) => message).join(", "))
          );
        },
      }
    );
  }

  private async initClient() {
    await this.isReady;

    const client = createClient({
      ...this.defaultOptions,
      ...omit(this.options, [
        "allowWindowReload",
        "notifyConnected",
        "notifyReconnected",
        "notifyConnectionError",
        "notifyDisconnected",
      ]),
      url: `${wsUrl}?${this.queryParameters}`,
      connectionParams: async () => ({
        ...(await this.options.connectionParams()),
        isSocketReconnect: this.isSocketReconnect,
      }),
    });
    this.client = client;
  }
}

export default WebSocketLink;
