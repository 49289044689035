import { createContext } from "react";
import { Conversation } from "@twilio/conversations";
import { ConversationDescriptor } from "../../hooks/conversations/conversations.hook.definition";

export interface ConversationsContextModel {
  selectConversation: (conversationDescriptor: ConversationDescriptor) => Promise<Conversation>;
  conversationDescriptors: ConversationDescriptor[];
  isConversationsLoading: boolean;
  hasUnreadMessages: boolean;
  lastConversationOpened: string;
  setLastConversationOpened(lastChatOpened: string): void;
}

const ConversationsContext = createContext<Partial<ConversationsContextModel>>({});

export default ConversationsContext;
