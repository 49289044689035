import createActivityDetector from "activity-detector";
import { BroadcastChannel } from "broadcast-channel";
import { useState, useEffect, useCallback } from "react";
import {
  InactivityDetectorHookProps,
  InactivityDetectorHookModel,
  ActivityState,
  ChannelMessageProps,
  CreateActivityMonitorProps,
  InactivityDetectorDefaults,
} from "./useInactivityDetector.definition";

export const useInactivityDetector = ({
  initialState = InactivityDetectorDefaults.initialState,
  timeToIdle = InactivityDetectorDefaults.timeToIdle,
  autoInit = InactivityDetectorDefaults.autoInit,
  broadcastChannelId,
  activityEvents = InactivityDetectorDefaults.activeEvents,
  inactivityEvents = InactivityDetectorDefaults.inactivityEvents,
  ignoredEventsWhenIdle = InactivityDetectorDefaults.ignoredEventsWhenIdle,
  lockOnInactivity = InactivityDetectorDefaults.lockOnInactivity,
}: InactivityDetectorHookProps): InactivityDetectorHookModel => {
  const [state, setState] = useState(initialState);
  const [channel, setChannel] = useState(null);
  const [activityMonitor, setActivityMonitor] = useState(null);

  const initializeActivityDetector = useCallback(
    ({ initialState, autoInit = true }: CreateActivityMonitorProps) => {
      const activityDetector = createActivityDetector({
        timeToIdle,
        initialState,
        activityEvents,
        inactivityEvents,
        ignoredEventsWhenIdle,
        autoInit,
      });
      activityDetector.on(ActivityState.Idle, setStateIdle);
      activityDetector.on(ActivityState.Active, setStateActive);
      setActivityMonitor(activityDetector);
    },
    [activityEvents, ignoredEventsWhenIdle, inactivityEvents, timeToIdle]
  );

  const runFullCleanup = useCallback(() => {
    channel?.close();

    activityMonitor?.stop();
  }, [channel, activityMonitor]);

  function setStateIdle() {
    setState(ActivityState.Idle);
  }

  function setStateActive() {
    setState(ActivityState.Active);
  }

  useEffect(() => {
    if (!channel || !state) return;

    channel.postMessage({ state });

    if (lockOnInactivity && state === ActivityState.Idle) runFullCleanup();
  }, [state, channel, lockOnInactivity, runFullCleanup]);

  useEffect(() => {
    initializeActivityDetector({
      initialState,
      autoInit,
    });

    const channelMessageHandler = async (message: ChannelMessageProps) => {
      if (message.state !== state) {
        setState(message.state);

        if (activityMonitor) {
          await activityMonitor.stop();
          setActivityMonitor(
            initializeActivityDetector({
              initialState: message.state,
              autoInit: true,
            })
          );
        }
      }
    };

    try {
      const broadcastChannel = new BroadcastChannel(broadcastChannelId);
      broadcastChannel.addEventListener("message", channelMessageHandler);
      setChannel(broadcastChannel);
    } catch (error) {
      console.error("Not able to create a BroadcastChannel instance in this browser.");
    }

    return () => runFullCleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    userIsInactive: state === ActivityState.Idle,
  };
};
