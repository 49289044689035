import { ModalProps } from "@q4/nimbus-ui";
import moment, { Moment } from "moment-timezone";
import {
  EventModelPopulated,
  PartialEventModelPopulated,
} from "../../../hooks/events/events.hook.definitions";
import { TimezoneItem, TimezoneName } from "../../../utils/timezones/timezones.definition";
import { Company } from "../../../services/company/company.model";
import {
  EventCategoryType,
  EventEmailConfiguration,
  EventType,
  Guest,
  Host,
  Speaker,
  Support,
  defaultTz,
} from "../../../services/event/event.model";
import {
  BroadcastSource,
  Broadcaster,
  EventConference,
  MediaServerRegion,
  PresenterControlledSlides,
  defaultRegion
} from "../../../services/event/eventGql.model";
import { RegistrationField } from "../../../services/event/registrationFieldGql.model";
import { EventStatusEnum } from "../../../views/adminConsole/hooks/BroadcastRealTimeEvents/eventState";
import { ConsoleParticipantType } from "../../../views/broadcastSlideout/components/participants/consoleParticipants.definition";
import {
  DEFAULT_MAX_LENGTH_CHAR,
  DEFAULT_MAX_QUESTIONS_PER_ATTENDEE,
} from "../../../views/broadcastSlideout/components/question/consoleQuestion.definition";
import { SavedEventBrandingType } from "../../../views/eventMicrosite/hooks/microsite.hook.definition";
import {
  AttendeeConsoleBrandingState,
  ControlPanelBrandingState,
  PostEventPageBrandingState,
  PreEventPageBrandingState,
  RegistrationBrandingState,
  ThankYouPageBrandingState,
  WelcomePageBrandingState,
} from "../../../views/eventMicrosite/micrositeModal.definition";
import { ErrorModel } from "../../modal/modal.definition";

export interface EventModalProps {
  company?: Company;
  event?: EventModelPopulated;
  mode?: EventModalMode;
  overrides?: { eventType: EventType };
  saving?: boolean;
  timezoneOptions?: TimezoneItem[];
  visible: boolean;
  onCreateNewEvent: (
    event: EventModelPopulated,
    emailConfObject: EventEmailConfiguration
  ) => Promise<void>;
  onUpdateEvent: (
    event: EventModelPopulated,
    emailConfObject: EventEmailConfiguration
  ) => Promise<void>;
  onCloseRequest: ModalProps["onCloseRequest"];
  isCurrentUserHost: boolean;
  hasManageTenantEventsPermission: boolean;
  meetingId?: number;
}

/**
 * type input that shows error
 */
export enum EventModalErrorKey {
  Title = "title",
  Company = "company",
  Speaker = "speaker",
  Host = "host",
  CustomExtension = "Custom Extension",
  BrMeetingId = "Broadridge Meeting ID",
  PhoneRequired = "PhoneRequired",
}

export enum EventModalMode {
  DuplicateEvent = "EVENT_DUPLICATE",
  NewEvent = "NEW_EVENT",
  UpdateEvent = "UPDATE_EVENT",
}

export const EventModalTitle = {
  [EventModalMode.DuplicateEvent]: "Duplicate Event",
  [EventModalMode.NewEvent]: "New Event",
  [EventModalMode.UpdateEvent]: "Update Event",
};

export const EventModalButtonLabel = {
  [EventModalMode.DuplicateEvent]: "Duplicate Event",
  [EventModalMode.NewEvent]: "Schedule Event",
  [EventModalMode.UpdateEvent]: "Update Event",
};

export const EventEmailConfigurationUsers = [
  "speakers",
  "supports",
  "observers",
  "inspectors",
  "guests",
];
/**
 * error messages
 */
export enum EventModalErrorMessage {
  brMeetingIdErrorMessage = "Broadridge Meeting ID Required",
  companyErrorMessage = "Company Name Required",
  customExtensionFormattingErrorMessage = "Must be at least 3 alphanumeric characters and not include spaces",
  emptyCustomExtensionErrorMessage = "Custom Extension Required",
  emptyErrorMessage = "Event Name Required",
  emptyEventTypeErrorMessage = "Event Type Required",
  eventCategoryRequired = "Event Category Required",
  hostErrorMessage = "Host Required",
  invalidBrMeetingIdErrorMessage = "Meeting IDs are 14 characters long, including UPPER CASE letters and numbers (eg, RICST320220829). Please try again.",
  phoneNumberRequired = "Phone Number Required",
  regionRequired = "Medea Server Region Required",
  selectInputError = "Please enter a valid email or registered username",
  speakersErrorMessage = "Speaker Required",
  startAfterEndErrorMessage = "Event Start Date should not be after Event End Date",
  timezoneRequired = "Event Time Zone Required",
  uniqueCustomExtensionErrorMessage = "Custom Extension must be unique",
  disasterRecoveryUrlFormatErrorMessage = "Valid link required",
}

export enum EventModalClassNames {
  Base = "event-modal",
  Title = "event-modal_title",
  Subtitle = "event-modal_subtitle",
  Container = "event-modal_container",
  Form = "event-modal_form",
  Toggle = "event-modal_toggle",
  ToggleSection = "event-modal_toggle-section",
  ComboField = "event-modal_combo-field",
  KeyLineField = "event-modal_key-line-field",
  SelectContainer = "event-modal_select-container",
  SelectInputError = "event-modal_select-input-error",
  Text = "event-modal_text",
  BroadcastSourceText = "event-modal_broadcast-source_text",
  BroadcastSourceSpace = "event-modal_broadcast-source_space",
  QASettings = "event-modal_qa-settings",
  RSLSettings = "event-modal_rsl-settings",
  DialInSettings = "event-modal_dial-in-settings",
  Settings = "event-modal_settings",
  SettingsCheckbox = "event-modal_settings-checkbox",
  SettingsSpace = "event-modal_settings-space",
  VanityUrlContainer = "event-modal_vanity-url-container",
}

const dataIdPrefix = "NewEvent";

export const DATA_IDS = {
  SCHEDULE_EVENT: `${dataIdPrefix}ScheduleButton`,
  CANCEL_EVENT: `${dataIdPrefix}CancelButton`,
  EVENT_NAME: `${dataIdPrefix}NameBox`,
  EVENT_DESCRIPTION: `${dataIdPrefix}DescriptionBox`,
  COMPANY_NAME: `${dataIdPrefix}CompanyNameSelect`,
  EVENT_SPEAKERS: `${dataIdPrefix}SpeakersSelect`,
  EVENT_SUPPORTS: `${dataIdPrefix}SupportsSelect`,
  EVENT_OBSERVERS: `${dataIdPrefix}ObserversSelect`,
  EVENT_INSPECTORS: `${dataIdPrefix}InspectorsSelect`,
  EVENT_HOST: `${dataIdPrefix}HostSelect`,
  CUSTOM_IDENTIFIER: `${dataIdPrefix}CustomIdentifier`,
  BR_MEETING_ID: `${dataIdPrefix}BroadridgeMeetingId`,
};

export type EventTypeSelectOption = {
  value: EventType;
  label: string;
};

export type EventCategorySelectOption = {
  value: EventCategoryType;
  label: string;
};

export const EventTypeSelectOptions: EventTypeSelectOption[] = [
  // { value: EventType.VSM, label: "VSM", disabled: true }, // NOTE - unsupported atm
  { value: EventType.EARNINGS, label: "Earnings" },
  { value: EventType.INVESTOR_DAY, label: "Investor Day" },
  { value: EventType.RECORDING, label: "Recording" },
];

export const EventCategorySelectOptions: EventCategorySelectOption[] = [
  { value: EventCategoryType.DEMO, label: "Demo" },
  { value: EventCategoryType.INTERNAL, label: "Internal (Q4)" },
  { value: EventCategoryType.LIVE_EVENT, label: "Live Event" },
  { value: EventCategoryType.STREAM_TEST, label: "Stream Test" },
];

export type EventMaxShareholderSessionsSelectOptions = {
  value: number;
  label: string;
};

export const DefaultMaxShareholderSessions = 3;

export const DEFAULT_CUSTOM_EXTENSION = moment().year().toString();

export const DEFAULT_CUSTOM_EXTENSION_CHECKBOX_STATE = false;

export const EventDayFormat = "MM/DD/YYYY";

export const EventTimePickerFormat = "h:mma";

export interface QuestionConfig {
  text?: boolean;
  questionsPerAttendee?: number;
  charLimit?: number;
  qaLineEnabled?: boolean;
  qaLine?: string;
}
export interface DialInConfig {
  speaker: boolean;
  operatorLineEnabled?: boolean;
  operatorLine?: string;
}

export enum HostingTypes {
  VirtualDataRoom = "VIRTUAL_DATA_ROOM",
  Default = "DEFAULT",
}
export interface RSLConfig {
  enabled: boolean;
  hostingType?: HostingTypes;
}

export interface VideoConfig {
  enabled: boolean;
}

export interface ShareholderConfig {
  maxSessions: number;
}
export interface PostEventConfig {
  feedbackSurvey?: boolean;
  enableArchive?: boolean;
  enableReplay?: boolean;
}

export interface EmailVerification {
  enabled: boolean;
}

export interface GuestConfig {
  enabled?: boolean;
}

export interface LayoutManagerConfig {
  enabled: boolean;
}

export interface BroadcastOutputConfig {
  externalEnabled?: boolean;
}

export interface DisasterRecoveryConfig {
  enabled: boolean;
  redirectUrl: string;
}

export interface DualStreamConfig {
  enabled: boolean;
  region: string;
}

export interface EventConfiguration {
  question?: QuestionConfig;
  rsl?: RSLConfig;
  dialIn?: DialInConfig;
  video?: VideoConfig;
  shareholder?: ShareholderConfig;
  postEvent?: PostEventConfig;
  emailVerification?: EmailVerification;
  guest?: GuestConfig;
  presenterControlledSlides?: PresenterControlledSlides;
  layoutManager?: LayoutManagerConfig;
  broadcastOutput?: BroadcastOutputConfig;
  disasterRecovery?: DisasterRecoveryConfig;
  dualStream?: DualStreamConfig;
}

export interface EventSettings {
  rslEnabled?: boolean;
  votingEnabled?: boolean;
  questionEnabled?: boolean;
}

/**
 * default configuration toggle value
 */
export const defaultConfiguration: EventConfiguration = {
  question: {
    text: false,
    questionsPerAttendee: DEFAULT_MAX_QUESTIONS_PER_ATTENDEE,
    charLimit: DEFAULT_MAX_LENGTH_CHAR,
  },
  rsl: { enabled: true, hostingType: HostingTypes.Default },
  dialIn: { speaker: false },
  video: { enabled: false },
  shareholder: { maxSessions: DefaultMaxShareholderSessions },
  postEvent: { enableArchive: false, enableReplay: false, feedbackSurvey: false },
  guest: { enabled: true },
  presenterControlledSlides: { enabled: false },
  broadcastOutput: { externalEnabled: false },
  dualStream: { enabled: false, region: MediaServerRegion.NorthAmericaOregon },
};

export const defaultEventSettings: EventSettings = {
  rslEnabled: true,
  votingEnabled: true,
  questionEnabled: false,
};

export const defaultBranding: SavedEventBrandingType = {
  controlPanelBranding: new ControlPanelBrandingState(),
  welcomePageBranding: new WelcomePageBrandingState(),
  registrationPageBranding: new RegistrationBrandingState(),
  preEventPageBranding: new PreEventPageBrandingState(),
  thankYouPageBranding: new ThankYouPageBrandingState(),
  postEventPageBranding: new PostEventPageBrandingState(),
  attendeeConsoleBranding: new AttendeeConsoleBrandingState().customBranding,
};

export class EventEditStateBase {
  company?: Company;
  configuration: EventConfiguration;
  description = "";
  eventEnd: Moment;
  eventStart: Moment;
  eventType: EventType;
  eventTz?: TimezoneName = defaultTz;
  guests?: Guest[];
  notes = "";
  speakers?: Speaker[];
  status: EventStatusEnum;
  title = "New Event";
  registrationFields: RegistrationField[];
  /**
    @deprecated You should use "company" object instead "companyId". Example: "company.id"
  */
  companyId?: string;
  eventCategory?: EventCategoryType;

  constructor(event?: PartialEventModelPopulated, company?: Company) {
    const roundingDuration = moment.duration(15, "minutes");
    const roundedStartDate = moment(Math.ceil(+moment() / +roundingDuration) * +roundingDuration);
    const roundedEndDate = moment(roundedStartDate).add(1, "hours");

    this.company = event?.company ?? company;
    this.companyId = event?.companyId;
    this.configuration = event?.configuration ?? defaultConfiguration;
    this.description = event?.description ?? "";
    this.eventTz = event?.eventTz ?? defaultTz;
    this.eventEnd = moment.tz(event?.eventEnd ?? roundedEndDate, this.eventTz);
    this.eventStart = moment.tz(event?.eventStart ?? roundedStartDate, this.eventTz);
    this.eventType = event?.eventType ?? EventType.EARNINGS;
    this.guests = event?.guests ?? [];
    this.notes = event?.notes ?? "";
    this.speakers = event?.speakers ?? [];
    this.status = event?.status ?? EventStatusEnum.NOT_STARTED;
    this.title = event?.title ?? "New Event";
    this.registrationFields = event?.registrationFields ?? [];
    this.eventCategory = event?.eventCategory;
  }
}

export class EarningsEventEditState extends EventEditStateBase {
  eventType = EventType.EARNINGS;
  branding?: SavedEventBrandingType;
  previewOptions?: SavedEventBrandingType;
  region?: MediaServerRegion;
  supports?: Support[];
  hosts?: Host[];
  settings?: EventSettings;
  customIdentifier?: string;
  broadcastSource?: BroadcastSource;
  conference?: EventConference;

  constructor(event?: PartialEventModelPopulated, company?: Company) {
    super(event, company);
    this.branding = event?.branding ?? defaultBranding;
    this.previewOptions = event?.previewOptions ?? defaultBranding;
    this.region = event?.region ?? defaultRegion;
    this.supports = event?.supports ?? [];
    this.hosts = event?.hosts ?? [];
    this.settings = event?.settings ?? defaultEventSettings;
    this.customIdentifier = event?.customIdentifier;
    this.broadcastSource = event?.broadcastSource ?? BroadcastSource.default;
    this.conference = event?.conference;
  }
}

export class InvestorDayEventEditState extends EarningsEventEditState {
  eventType = EventType.INVESTOR_DAY;
}

export class RecordingEventEditState extends EventEditStateBase {
  eventType = EventType.RECORDING;
  constructor(event?: PartialEventModelPopulated, company?: Company) {
    super(event, company);
    this.eventEnd = this.eventEnd?.clone().endOf("day");
    this.configuration = {
      ...this.configuration,
      dialIn: { ...this.configuration.dialIn, speaker: true },
      postEvent: { ...this.configuration.postEvent, enableArchive: true },
    };
  }
}

export interface EventEditState
  extends RecordingEventEditState,
  EarningsEventEditState,
  InvestorDayEventEditState { }

export class EventModalErrorState {
  companyNameError?: ErrorModel;
  customExtensionError?: ErrorModel;
  endDateError?: ErrorModel;
  endTimeError?: ErrorModel;
  eventCategoryError?: ErrorModel;
  eventNameError?: ErrorModel;
  eventTypeError?: ErrorModel;
  hostError?: ErrorModel;
  operatorLineError?: ErrorModel;
  qaLineError?: ErrorModel;
  regionError?: ErrorModel;
  speakersError?: ErrorModel;
  startDateError?: ErrorModel;
  startTimeError?: ErrorModel;
  tzError?: ErrorModel;
  disasterRecoveryUrlError?: ErrorModel;

  constructor(state: EventModalErrorState) {
    this.companyNameError = state.companyNameError;
    this.customExtensionError = state.customExtensionError;
    this.endDateError = state.endDateError;
    this.endTimeError = state.endTimeError;
    this.eventCategoryError = state.eventCategoryError;
    this.eventNameError = state.eventNameError;
    this.eventTypeError = state.eventTypeError;
    this.hostError = state.hostError;
    this.operatorLineError = state.operatorLineError;
    this.qaLineError = state.qaLineError;
    this.regionError = state.regionError;
    this.speakersError = state.speakersError;
    this.startDateError = state.startDateError;
    this.startTimeError = state.startTimeError;
    this.tzError = state.tzError;
    this.disasterRecoveryUrlError = state.disasterRecoveryUrlError;
  }
}

export interface EventInviteProps {
  meetingId: number;
  users: string[];
  guests?: Guest[];
  inviteType: ConsoleParticipantType;
}

export const EVENT_NOTES_EDITOR_WIDTH = 950;

export interface MediaServerRegionOption {
  value: MediaServerRegion;
  label: string;
}

export const MediaServerRegionOptions: MediaServerRegionOption[] = [
  { value: MediaServerRegion.NorthAmericaVirginia, label: "North America Virginia" },
  { value: MediaServerRegion.NorthAmericaOregon, label: "North America Oregon" },
  { value: MediaServerRegion.EuropeDublin, label: "Europe Dublin" },
  { value: MediaServerRegion.EuropeFrankfurt, label: "Europe Frankfurt" },
  { value: MediaServerRegion.SouthAmericaSaoPaulo, label: "South America Sao Paulo" },
  { value: MediaServerRegion.AsiaPacificSingapore, label: "Asia Pacific Singapore" },
  { value: MediaServerRegion.AsiaPacificTokyo, label: "Asia Pacific Tokyo" },
  { value: MediaServerRegion.AsiaPacificSydney, label: "Asia Pacific Sydney" },
];

export interface BroadcasterOption {
  value: Broadcaster;
  label: string;
}

export const BroadcasterOptions: BroadcasterOption[] = [
  { value: Broadcaster.Vonage, label: "Vonage" },
  { value: Broadcaster.IVS, label: "IVS" },
];