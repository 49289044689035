import config from "../../config";
import { useGetRequest } from "./hook/getRequest";

interface UseGetFeatureFlagProps {
  skip?: boolean;
}

const apiUrl = config.api.attendeeRest;

const path = `${apiUrl}/flags`;
export const useGetFeatureFlag = (props: UseGetFeatureFlagProps) => {
  const { skip } = props;

  const { data, error, isLoading, refetch } = useGetRequest({ skip, path });

  return {
    data: data,
    error,
    isLoading,
    refetch,
  };
};
