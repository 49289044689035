import { useState, useReducer, Dispatch } from "react";
import { set, unset, cloneDeep } from "lodash";
import {
  EarningsEventEditState,
  EventEditState,
  EventEditStateBase,
  EventModalProps,
  InvestorDayEventEditState,
  RecordingEventEditState,
} from "../eventModal.definition";
import { PartialEventModelPopulated } from "../../../../hooks/events/events.hook.definitions";
import { Company } from "../../../../services/company/company.model";
import { EventType } from "../../../../services/event/event.model";

export enum EventActionTypes {
  RESET = "RESET",
  REMOVE = "REMOVE",
  PUT = "PUT",
}

export interface EventsReducerAction {
  type: EventActionTypes;
  newEventState?: any;
  path?: string | string[];
}

export enum ReducerErrorMessage {
  WrongArrayLengths = "Array Lengths inputted are incorrect",
  WrongEventStateType = "NewEventState must be an array when trying to change multiple properties in Event",
}

/**
 * Checks to see that both values are arrays and have the same length
 * @param newEventState
 * @param path
 */
const isMultiAttrOp = (newEventState, path) => {
  if (Array.isArray(path)) {
    if (Array.isArray(newEventState)) {
      if (newEventState.length === path.length) return true;
      throw Error(ReducerErrorMessage.WrongArrayLengths);
    }
    throw Error(ReducerErrorMessage.WrongEventStateType);
  }

  return false;
};

/**
 *
 * @param currentEventState
 * @param newEventState
 * @param path
 */
const handlePut = (
  currentEventState: EventEditState,
  newEventState,
  path: string | string[]
): EventEditState => {
  const currentEventStateClone = cloneDeep(currentEventState);
  if (isMultiAttrOp(newEventState, path)) {
    for (let i = 0; i < path.length; i++) {
      set(currentEventStateClone, path[i], newEventState[i]);
    }
  } else {
    set(currentEventStateClone, path, newEventState);
  }

  return currentEventStateClone;
};

/**
 *
 * @param currentEventState
 * @param path
 */
const handleRemove = (
  currentEventState: EventEditState,
  path: string | string[]
): EventEditState => {
  const currentEventStateClone = cloneDeep(currentEventState);

  if (Array.isArray(path)) {
    for (const key of path) {
      unset(currentEventStateClone, key);
    }
  } else {
    unset(currentEventStateClone, path);
  }

  return currentEventStateClone;
};

/**
 * Will handle all state changes for the form.
 * @param currentEventState
 * @param EventsReducerAction
 */
const eventsReducer = (
  currentEventState: EventEditState,
  { type, newEventState, path }: EventsReducerAction
): EventEditState => {
  switch (type) {
    case EventActionTypes.RESET:
      return newEventState;
    case EventActionTypes.PUT:
      return handlePut(currentEventState, newEventState, path);
    case EventActionTypes.REMOVE:
      return handleRemove(currentEventState, path);
  }
};

export function useEventsSettingsFormReducer(
  data: EventEditState
): [EventEditState, Dispatch<EventsReducerAction>] {
  const [eventEdit, eventEditStateDispatch]: [EventEditState, Dispatch<EventsReducerAction>] =
    useReducer(eventsReducer, data);
  return [eventEdit, eventEditStateDispatch];
}
interface FormHookProps<D, E extends new (...args: any[]) => any> {
  data: D;
  getInitialErrorState: any;
  ResetFormModel: any; // FIXME: not used
  ErrorType: E;
}

export const getEventEditState = (
  event: PartialEventModelPopulated,
  company: Company,
  overrides?: EventModalProps["overrides"]
) => {
  const eventType = event?.eventType ?? overrides?.eventType;

  switch (eventType) {
    case EventType.EARNINGS:
      return new EarningsEventEditState(event, company);
    case EventType.RECORDING:
      return new RecordingEventEditState(event, company);
    case EventType.INVESTOR_DAY:
      return new InvestorDayEventEditState(event, company);
    default:
      return new EventEditStateBase(event, company);
  }
};

export default function useEventsSettingsForm<
  D = any,
  E extends new (...args: any[]) => any = new (...args: any[]) => any
>(props: FormHookProps<D, E>) {
  const { data, getInitialErrorState, ErrorType } = props;
  const [saving, setSaving] = useState(false);
  const [editEvent, setEditEventDispatch] = useEventsSettingsFormReducer(
    data as unknown as EventEditState // FIXME: it's better to align data and reducer types
  );
  const [errorState, setErrorState] = useState(new ErrorType(getInitialErrorState()));

  function resetErrorState() {
    setErrorState(getInitialErrorState);
  }

  return {
    saving,
    setSaving,
    editEvent,
    setEditEventDispatch,
    errorState,
    setErrorState,
    resetErrorState,
  };
}
