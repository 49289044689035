import { useMemo, useEffect, useCallback } from "react";
import legacyFavicon from "../../assets/favicon/favicon-32x32.png";
import rebrandFavicon from "../../assets/favicon/favicon-32x32-rebrand.png";
import { getClassName } from "@q4/nimbus-ui";

export enum LogoColors {
  white = "white",
  black = "black",
  blue = "blue",
}

interface UseRebrandingProps {
  v2logoColor?: LogoColors;
}

export const useRebrandFlag = (props?: UseRebrandingProps) => {
  const { v2logoColor = LogoColors.white } = props ?? {};

  const enabled = true;

  const v1LogoClassName = "q4i-q4-logo";

  const v2LogoClassName = useMemo(() => `q4i-logo-v2-${v2logoColor}`, [v2logoColor]);

  const logoClassName = useMemo(
    () => (enabled ? v2LogoClassName : v1LogoClassName),
    [enabled, v1LogoClassName, v2LogoClassName]
  );

  const getRebrandingClassName = useCallback(
    (baseClassName = "") => {
      return getClassName(baseClassName, [{ trueClassName: "rebranding", condition: enabled }]);
    },
    [enabled]
  );

  useEffect(() => {
    if (enabled === undefined) return;

    let link = document.querySelector("link.page-favicon");
    if (!link) {
      link = document.createElement("link");
      link["rel"] = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (link.className.includes("custom-favicon")) return;

    link["href"] = enabled ? rebrandFavicon : legacyFavicon;
  }, [enabled]);

  return {
    enabled,
    logoClassName,
    getRebrandingClassName,
  };
};
