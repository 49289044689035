import { BaseComponentProps, FormIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";
import { PublicEventDetails } from "../../../../../services/event/eventGql.model";
import type { RegistrationField } from "../../../../../services/event/registrationFieldGql.model";
import { EventMicrositePageType } from "../../../hooks/microsite.hook.definition";
import { OpenRegistrationSelectType } from "../../../micrositeModal.definition";
import { MicrositeCustomRegistrationPanelIdModel } from "./components/micrositeCustomRegistrationFields/micrositeCustomRegistrationPanel/micrositeCustomRegistrationPanel.definition";

export const MIN_ROWS = 3;
export const MAX_ROWS = 4;
export const MAX_LENGTH = 297;
export const REGISTRATION_MAX_LENGTH = 1000;
export const LINE_HEIGHT = 22;

export enum MicrositePageConfigurationModalFormClassNames {
  Base = "microsite-page-configuration-modal-form",
  FormContainer = "microsite-page-configuration-modal-form_container",
  Scroller = "microsite-page-configuration-modal-form_scroller",
  Title = "microsite-page-configuration-modal-form_title",
  NoMarginTitle = "microsite-page-configuration-modal-form_title--no-margin",
  Tab = "microsite-page-configuration-modal-form_tab",
  Message = "microsite-page-configuration-modal-form_message",
  MessageError = "microsite-page-configuration-modal-form_message-error",
  RegistrationOptions = "microsite-page-configuration-modal-form_registration-options",
  MusicOptions = "microsite-page-configuration-modal-form_music-options",
}

export interface MicrositePageConfigurationModalFormProps extends BaseComponentProps {
  currentPage: EventMicrositePageType;
  currentPageState: any;
  currentPageErrorState: any;
  currentEvent: PublicEventDetails;
  registrationFields: RegistrationField[];
  openRegistration: OpenRegistrationSelectType;
  resetCurrentPageErrorState(): void;
  setCurrentPageState(state: any): void;
  currentRegistrationTab?: number;
  setCurrentRegistrationTab?: Dispatch<SetStateAction<number>>;
  registrationFieldsState?: RegistrationField[];
  setRegistrationFieldsState?: Dispatch<SetStateAction<RegistrationField[]>>;
  closeSlideOut: boolean;
}

const dataIdPrefix = "MicrositePageConfiguration";

export const DATA_IDS = {
  TITLE_INPUT: `${dataIdPrefix}TitleInput`,
  DESCRIPTION_INPUT: `${dataIdPrefix}DescriptionInput`,
  PROXY_URL_INPUT: `${dataIdPrefix}ProxyUrlInput`,
  BR_US_PHONE_NUMBER_INPUT: `${dataIdPrefix}BRUSPhoneNumberInput`,
  BR_PHONE_NUMBER_INPUT: `${dataIdPrefix}BRPhoneNumberInput`,
  OPEN_REGISTRATION_SELECT: `${dataIdPrefix}OpenRegistrationSelect`,
  TERMS_INPUT: `${dataIdPrefix}TermsInput`,
  ATTENDEE_MESSAGE_INPUT: `${dataIdPrefix}AttendeeMessageInput`,
  PARTICIPANT_MESSAGE_INPUT: `${dataIdPrefix}ParticipantMessageInput`,
  MEETING_DETAILS_INPUT: `${dataIdPrefix}MeetingDetailsInput`,
  MUSIC_OPTIONS_RADIO: `${dataIdPrefix}MusicOptionsRadio`,
  DISPLAY_TIME_CHECKBOX: `${dataIdPrefix}DisplayTimeCheckbox`,
};

export interface DescriptionFieldOptions {
  maxLength?: number;
}

export class MicrositePageConfigurationModalFormIdModel extends IdModelBase {
  pageTab: string;
  header: string;
  form: FormIdModel;
  customRegistration: MicrositeCustomRegistrationPanelIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.pageTab = `${this.id}PageTab`;
    this.header = `${this.id}Header`;
    this.form = new FormIdModel(`${this.id}Form`);
    this.customRegistration = new MicrositeCustomRegistrationPanelIdModel(
      `${this.id}CustomRegistrationPanel`
    );
  }
}
