import { useCallback } from "react";

// Used to define the keys for the local storage
export enum LocalStorageKeys {
  MicrositePreview = "micrositePreview",
  BehaviorTrackingState = "behaviorTrackingState",
}

interface IuseLocalStorageReturn {
  /**
   * Sets the value to local storage
   *
   * @param {string} key - The key of the value to set
   * @param {string} value - The value to set
   * @param {string} keyIdentifier - Used to distinguish between multiple values with the same key
   */
  setLS: (key: string, value: any, keyIdentifier?: string | number) => void;
  /**
   * Retrieves the value from the local storage
   *
   * @param {string} key - The key of the value to retrieve
   * @returns {any} The retrieved value
   */
  getLS: (key: string, keyIdentifier?: string | number) => any | null;
  /**
   * Removes the value from the local storage
   *
   * @param {string} key - The key of the value to remove
   */
  removeLS: (key: string, keyIdentifier?: string | number) => void;
}

/**
 * `useLocalStorage` is a Local Storage wrapper that provides a simple LSKey based API.
 *
 * ```typescript
 * const { setLS, getLS, removeLS } = useLocalStorage();
 * setLS(LocalStorageKeys.MicrositePreview, {variable: 'value'}, meetingId);
 * getLS(LocalStorageKeys.MicrositePreview, meetingId);
 * removeLS(LocalStorageKeys.MicrositePreview, meetingId);
 * ```
 */
const useLocalStorage = (): IuseLocalStorageReturn => {
  const getLSKey = (key: LocalStorageKeys, keyIdentifier: string | number) => {
    return keyIdentifier ? `${key}-${keyIdentifier}` : key;
  };

  const setLS = useCallback((key: LocalStorageKeys, value: any, keyIdentifier: string | number) => {
    window?.localStorage?.setItem(getLSKey(key, keyIdentifier), JSON.stringify(value));
  }, []);

  const getLS = useCallback((key: LocalStorageKeys, keyIdentifier: string | number) => {
    const value = window?.localStorage?.getItem(getLSKey(key, keyIdentifier));
    return value ? JSON.parse(value) : null;
  }, []);

  const removeLS = useCallback((key: LocalStorageKeys, keyIdentifier: string) => {
    window?.localStorage?.removeItem(getLSKey(key, keyIdentifier));
  }, []);

  return {
    setLS,
    getLS,
    removeLS,
  };
};

export default useLocalStorage;
