import { isEmpty } from "lodash";
import { User as UserType } from "../../../services/user/user.model";
import { ErrorModel } from "../modal.definition";

export interface EditProfileProps {
  user: UserType;
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (data) => void;
}

export class ResetEditProfileFormModel {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;

  constructor(profileDate) {
    if (isEmpty(profileDate)) return;

    this.id = profileDate.id;
    this.firstName = profileDate.firstName;
    this.lastName = profileDate.lastName;
    this.email = profileDate.email;
  }
}

export class EditProfileErrorState {
  firstNameError?: ErrorModel;
  lastNameError?: ErrorModel;

  constructor(state: EditProfileErrorState) {
    this.firstNameError = state.firstNameError;
    this.lastNameError = state.lastNameError;
  }
}

export enum FormErrors {
  FIRST_NAME_EMPTY = "First Name field required",
  LAST_NAME_EMPTY = "Last Name Field required",
}
