import { UserRoles } from "../../configurations/userRolesPermissions.configuration";
import { BroadcastStream } from "../../../src/views/adminConsole/interfaces/broadcastStream/broadcastStream";

export enum DefaultGroupIdentifiers {
  ALL_USERS = "all-users",
}

export enum GroupsStreamType {
  CAMERA = "camera",
  SCREEN = "screen",
  PSTN = "pstn",
  VIDEO_ASSET = "videoAsset",
  AUDIO_ASSET = "audioAsset",
}

export const DEFAULT_STREAM_ORDER = {
  [GroupsStreamType.SCREEN]: 1,
  [GroupsStreamType.VIDEO_ASSET]: 2,
  [GroupsStreamType.AUDIO_ASSET]: 3,
  [UserRoles.EP_EVENT_SPEAKER]: 4,
  [GroupsStreamType.CAMERA]: 4,
  [GroupsStreamType.PSTN]: 4,
  [UserRoles.EP_EVENT_HOST]: 5,
  // TODO proponent
  [UserRoles.COMPANY_ADMIN]: 6,
  [UserRoles.EP_EVENT_SUPPORT]: 7,
  [UserRoles.EP_EVENT_MANAGER]: 8,
  [UserRoles.Q4_ADMIN]: 8,
};

export interface GroupsHookProps {
  meetingId: number;
  streams: BroadcastStream[];
  initialGroups?: Group[];
}

export interface GroupsHookModel {
  groups: Group[];
  setGroupsUniq: (payload: Group[]) => void;
  createGroup: (group: Partial<Group>) => Promise<Group>;
  editGroup: (groupId: string, groupData: Group) => Promise<Group>;
  addToGroup: (groupId: string, participants: GroupParticipant[]) => Promise<void>;
  getDefaultGroup: () => Group;
  removeFromGroup: (groupId: string, participants: GroupParticipant[]) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<Group[]>;
  reorderGroup: (reorderedGroups: Group[]) => void;
  setCurrentSelectedGroup: React.Dispatch<React.SetStateAction<Group>>;
  currentSelectedGroup: Group;
}

export interface GroupParticipant {
  identifier: string;
  stream?: BroadcastStream;
  streamType?: GroupsStreamType;
}

export interface Group {
  name: string;
  identifier: string;
  participants: GroupParticipant[];
  displayName?: string;
}
