import React, { memo, useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import {
  Button,
  ButtonTheme,
  FileUploader,
  FileUploaderApiPayload,
  FileUploaderTheme,
  getClassName,
  GridColumn,
  Keyline,
  KeylineTheme,
  Modal,
  NotificationService,
  Text,
  ToggleButtons,
  ToggleButtonsTheme,
} from "@q4/nimbus-ui";
import {
  HelpCenterUploadModalClassNames,
  HelpCenterUploadModalProps,
  HelpCenterUploadTabsIndex,
} from "./helpCenterUpload.definition";
import {
  useListHelpCenterFilesQuery,
  useUploadHelpCenterFileMutation,
} from "./helpCenterUpload.utils";
import "./helpCenterUpload.component.scss";
import { FileUploaderClassNames } from "../../event/assets/components/fileUploader/fileUploader.definition";
import { Auth0Context } from "../../../contexts";
import { cloneDeep, orderBy } from "lodash";

function HelpCenterUploadModal(props: HelpCenterUploadModalProps): JSX.Element {
  const { visible, onClose } = props;
  const [loading, setLoading] = useState(true);
  const [uploadError, setUploaderError] = useState("");
  const [selectedTab, setSelectedTab] = useState(HelpCenterUploadTabsIndex.Public);
  const notificationService = useRef(new NotificationService());

  const zipMimes = ["application/zip", "application/x-zip-compressed"];
  const [uploadedFile, setUploadedFile] = useState<ColumnSpec[]>([]);
  const isPrivate = selectedTab === HelpCenterUploadTabsIndex.Private;

  const { user } = useContext(Auth0Context);
  const fullName = `${user.firstName} ${user.lastName}`;

  const fileCount = 5; // value must be >= 2 (endpoint returns directory as entry)
  const [listHelpCenterFilesFn, { data }] = useListHelpCenterFilesQuery();

  const [uploadHelpCenterFileFn, { data: mutationData, error }] = useUploadHelpCenterFileMutation(
    {}
  );
  const uploadHelpCenterFile = mutationData?.uploadHelpCenterFile || null;

  const toggleButtonItems = [
    {
      key: "public",
      label: "Public",
    },
    {
      key: "private",
      label: "Private",
    },
  ];

  const modalClassName = getClassName(HelpCenterUploadModalClassNames.Base, [
    {
      condition: !!uploadError,
      trueClassName: HelpCenterUploadModalClassNames.Base + "--error",
    },
  ]);

  function onToggleChange(event: number) {
    setSelectedTab(event);
  }

  function getUploadMessage() {
    const s3BucketType = selectedTab === HelpCenterUploadTabsIndex.Public ? "Public" : "Private";
    return `Drag and drop to upload a ZIP file to the ${s3BucketType} Help Center`;
  }

  function isZipFile(type: string): boolean {
    return zipMimes.includes(type);
  }

  useEffect(
    function listFiles() {
      visible &&
        listHelpCenterFilesFn({
          variables: {
            private: isPrivate,
            fileCount,
          },
        });
    },
    [selectedTab, visible] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function getUploadedFiles() {
      if (!data?.listHelpCenterFiles) return setLoading(true);
      setLoading(false);
      setUploadedFile(formatQueryResults(data.listHelpCenterFiles));
    },
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function renderOnMutationComplete() {
      if (!uploadHelpCenterFile) return;
      if (error) {
        // restore from query
        setUploadedFile(formatQueryResults(data.listHelpCenterFiles));
        return notificationService.current.error("Failed to upload ZIP");
      }

      notificationService.current.success("Your ZIP was successfully uploaded!");
      uploadComplete();
    },
    [uploadHelpCenterFile] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Validates file type is valid and attempts to upload to S3
   * @param payload
   */
  const onFileUpload = async function <T extends File>(
    payload: FileUploaderApiPayload<T>
  ): Promise<any> {
    const { files } = payload;
    const file = files[0];

    try {
      if (!file) return;

      if (!isZipFile(file?.type)) {
        throw new Error("Invalid File Type - file must be a ZIP");
      }

      uploadError && setUploaderError("");

      // file name needs to be properly encoded for lambda to work
      const fileToUpload = new File([file], encodeURI(file.name), { type: file.type });
      setUploadedFile(formatFileRow(fileToUpload.name, fullName, undefined, true));
      uploadHelpCenterFileFn({
        variables: {
          private: isPrivate,
          file: fileToUpload,
          metadata: {
            uploader: fullName,
          },
        },
      });
    } catch (error) {
      setUploaderError(error?.message);
    }
    return Promise.resolve();
  };

  /**
   * Helper function for creating entries in the uploaded files table/grid
   * @param fileName
   * @param userName
   * @param date
   */
  function formatFileRow(
    fileName: string,
    userName: string,
    date?: string,
    uploading?: boolean
  ): ColumnSpec[] {
    const fileNameClassName = getClassName(HelpCenterUploadModalClassNames.FileName, [
      {
        condition: !!uploading,
        trueClassName: HelpCenterUploadModalClassNames.FileUploading,
      },
    ]);
    return [
      {
        value: decodeURI(fileName),
        width: "2-of-4",
        className: fileNameClassName,
      },
      { value: userName, width: "1-of-4" },
      {
        value: moment(date).format("YYYY-MM-DD"),
        width: "1-of-4",
        className: HelpCenterUploadModalClassNames.DateField,
      },
    ];
  }

  /**
   * Removes opacity from file name (once upload finishes)
   */
  function uploadComplete() {
    if (!uploadedFile[0]) return;

    const uploadedFileClone = cloneDeep(uploadedFile);
    uploadedFileClone[0].className = HelpCenterUploadModalClassNames.FileName;
    setUploadedFile(uploadedFileClone);
  }

  /**
   * Function for formating query results into GridRow objects. Built for multiple
   * entries, but help center only needs to display one row.
   * @param results
   */
  function formatQueryResults(results: any) {
    const uploaded = [];
    const prefix = "upload/";
    const orderedContents = orderBy(results?.Contents, "LastModified", "desc") || [];
    for (let file of orderedContents) {
      if (file?.Key === prefix) continue;

      const fileName = file?.Key?.replace(prefix, "");
      const userName = file?.MetaData?.uploader || "N/A";
      const date = file?.LastModified;
      uploaded.push(formatFileRow(fileName, userName, date));
    }

    // Originally built to support multiple files, but only one file is being displayed
    return uploaded[0] || [];
  }

  /**
   * Formates the uploadedFile state variable into a grid component
   * @param file
   */
  function renderUploadedFile(file: ColumnSpec[], loading: boolean) {
    const gridRowClassNames = getClassName(HelpCenterUploadModalClassNames.UploadedFiles, [
      {
        condition: !!loading,
        trueClassName: HelpCenterUploadModalClassNames.UploadedFilesLoading,
      },
    ]);

    return (
      <GridRow key={`grid-row-uploaded`} className={gridRowClassNames} columns={file}></GridRow>
    );
  }

  return (
    <Modal
      className={modalClassName}
      title="Upload a ZIP"
      visible={visible}
      onCloseRequest={onClose}
      footerActions={[
        {
          key: "done",
          label: "Done",
          theme: ButtonTheme.Citrus,
          onClick: onClose,
        },
      ]}
    >
      <ToggleButtons
        items={toggleButtonItems}
        theme={ToggleButtonsTheme.White}
        selected={selectedTab}
        onChange={onToggleChange}
      />
      <GridRow
        key={HelpCenterUploadModalClassNames.Header}
        className={HelpCenterUploadModalClassNames.Header}
        columns={[
          { value: "Last Upload", width: "2-of-4" },
          { value: "Uploader", width: "1-of-4" },
          { value: "Date", width: "1-of-4" },
        ]}
      ></GridRow>
      <Keyline theme={KeylineTheme.SoftGrey} />
      {renderUploadedFile(uploadedFile, loading)}
      <FileUploader
        className={HelpCenterUploadModalClassNames.FileUploader}
        theme={FileUploaderTheme.White}
        fileApi={onFileUpload}
        fileUrl={""}
        onChange={emptyFunction}
        uploadMessage={<UploaderBody message={getUploadMessage()} error={uploadError} />}
        dropzoneProps={{ accept: zipMimes }}
      />
    </Modal>
  );
}

function emptyFunction() {}
const UploaderBody = (props): JSX.Element => {
  const { error, message } = props;

  return (
    <div className={FileUploaderClassNames.Body}>
      <Text className={FileUploaderClassNames.Action}>{message}</Text>
      <Text className={FileUploaderClassNames.Or}>Or</Text>
      <Button label="BROWSE" onClick={emptyFunction} theme={ButtonTheme.Rain}></Button>
      {error && <Text className={FileUploaderClassNames.Error_Text}>{error}</Text>}
    </div>
  );
};

/** TODO - move logic to new component */
export interface ColumnSpec {
  value: any;
  width?: string;
  className?: string;
}
export interface GridRowProps {
  className?: string;
  columns: ColumnSpec[];
}
export function GridRow(props: GridRowProps) {
  const { className, columns } = props;

  return (
    <div className={className}>
      {columns.map((column) => (
        /** TODO - add better solution for keying columns */
        <GridColumn key={`${column.value}`} width={column.width} className={column.className}>
          {column.value}
        </GridColumn>
      ))}
    </div>
  );
}
export default memo(HelpCenterUploadModal);
