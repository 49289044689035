import { SortType } from "../app/app.definition";
import { User } from "../../services/user/user.model";
import { CompanyGetField, CompanyGetType } from "../../hooks/companies/companies.hook.definition";
import { noopComparator } from "../../utils/agGrid.utils";

export interface CompaniesProps {
  companyUpdate?: string;
  version?: {
    latestVersion: string;
    oldVersion: string;
    currentVersion: string;
  };
  canManageVersions?: boolean;
  showVersionManagement: boolean;
  user?: User;
}

export enum CompaniesClassNames {
  Base = "companies",
  Header = "companies_header",
  Content = "companies_content",
  EventManagerCell = "companies_table-eventManagers",
  CurrentVersionTitle = "companies_current_version-title",
  NewVersionTitle = "companies_new_version-title",
  NewVersionEmptyTitle = "companies_new_version-title--empty",
}

export type ListOptions = {
  sortField?: CompanyGetField;
  sortOrder?: SortType;
  status?: CompanyGetType;
  page?: number;
  limit?: number;
  searchTerm?: string;
};

export const statusOptions = [
  { value: CompanyGetType.All, label: "All" },
  { value: CompanyGetType.Active, label: "Active" },
  { value: CompanyGetType.Archived, label: "Archived" },
];

export const columnDefs = [
  {
    headerName: "Company Name",
    field: "name",
    sortable: true,
    sort: "asc",
    comparator: noopComparator,
  },
  {
    headerName: "Stock Ticker",
    field: "customIdentifier",
    sortable: true,
    maxWidth: 300,
    comparator: noopComparator,
  },
  {
    headerName: "Q4 Platform Organization",
    field: "organization.name",
    sortable: true,
    comparator: noopComparator,
  },
  {
    headerName: "Event Manager",
    field: "eventManagers",
    maxWidth: 300,
    cellClass: CompaniesClassNames.EventManagerCell,
    cellRenderer: "tooltipRender",
    valueFormatter: (props) =>
      props.value &&
      props.value
        .map((user: User) =>
          user?.firstName && user?.lastName
            ? `${user?.firstName} ${user?.lastName}`
            : `${user.email}`
        )
        .join(", "),
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 170,
    maxWidth: 200,
    cellRenderer: "statusRender",
  },
];

export enum CompaniesPopoverMenuButtonIds {
  UpgradeAllCompanies = `UPGRADE_ALL_COMPANIES`,
  DowngradeAllCompanies = `DOWNGRADE_ALL_COMPANIES`,
}

const dataIdPrefix = "Companies";
export const DATA_IDS = {
  UTILITY_BUTTON: `${dataIdPrefix}UtilityButton`,
  CONFIRM_UPGRADE_ALL_COMPANIES: `${dataIdPrefix}ConfirmUpgradeAll`,
  CONFIRM_DOWNGRADE_ALL_COMPANIES: `${dataIdPrefix}ConfirmDowngradeAll`,
  CANCEL_UPGRADE_ALL_COMPANIES_BUTTON: `${dataIdPrefix}ConfirmUpgradeAll`,
  CANCEL_DOWNGRADE_ALL_COMPANIES_BUTTON: `${dataIdPrefix}ConfirmDowngradeAll`,
};

export const defaultColDef = {
  suppressMenu: true,
  sortable: false,
};

export enum CompanyModalClassNames {
  Base = "company-modal",
  NameField = "company-modal_name-field",
  LogoField = "company-modal_logo-field",
  NameFieldInput = "company-modal_logo-field-input",
  Notification = "company-modal_notification",
}

export const getArchiveMessage = (companyName) =>
  `Are you sure you want to archive ${
    companyName || "this company"
  }? Their account will become inactive.`;
export const getReactivateMessage = (companyName) =>
  `Are you sure you want to re-activate ${
    companyName || "this company"
  }? They will regain all event functionality.`;
export const getReactivateUnavailableMessage = (companyName: string) =>
  `${companyName || "The company"} was archived. Visit Capital Connect to activate this company.`;

export const pageSizeOptions = [10, 25, 50];
export const initialPageSize = 10;
export const initialPage = 1;
