export function polyfillNotification(): void {
  if ("Notification" in window) {
    return;
  }

  class DeniedNotification implements Notification {
    actions: readonly NotificationAction[];
    badge: string;
    body: string;
    data: any;
    dir: NotificationDirection;
    icon: string;
    image: string;
    lang: string;
    renotify: boolean;
    requireInteraction: boolean;
    silent: boolean;
    tag: string;
    timestamp: number;
    title: string;
    vibrate: readonly number[];
    onclick: (this: Notification, ev: Event) => any;
    onclose: (this: Notification, ev: Event) => any;
    onerror: (this: Notification, ev: Event) => any;
    onshow: (this: Notification, ev: Event) => any;

    static readonly maxActions: number;
    static readonly permission: NotificationPermission = "denied";

    static requestPermission(
      deprecatedCallback?: NotificationPermissionCallback
    ): Promise<NotificationPermission> {
      return Promise.resolve(DeniedNotification.permission);
    }

    constructor(title: string, options: NotificationOptions = {}) {
      Object.assign(this, { title, ...options });
    }
    close(): void {
      return;
    }
    addEventListener(type: any, listener: any, options?: any): void {
      return;
    }
    removeEventListener(type: any, listener: any, options?: any): void {
      return;
    }
    dispatchEvent(event: Event): boolean {
      return false;
    }
  }

  (window as any).Notification = DeniedNotification;
}
