import React, { createContext, FunctionComponent } from "react";
import {
  DominantSpeakerModel,
  useDominantSpeaker,
} from "../../hooks/dominantSpeaker/dominantSpeaker.hook";

export interface RemoteBroadcastContextProps {}
export interface RemoteBroadcastContextState {
  dominantSpeakerHook: DominantSpeakerModel;
}

const RemoteBroadcastContext = createContext<Partial<RemoteBroadcastContextState>>({});
export const RemoteBroadcastProvider: FunctionComponent<RemoteBroadcastContextProps> = (
  props
): JSX.Element => {
  const dominantSpeakerHook = useDominantSpeaker();
  return (
    <RemoteBroadcastContext.Provider value={{ dominantSpeakerHook }}>
      {props?.children}
    </RemoteBroadcastContext.Provider>
  );
};

export default RemoteBroadcastContext;
