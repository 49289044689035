export const ASPECT_RATIO_FACTOR = {
  DEFAULT: 1.77,
  AUDIO_EVENT: 2.6,
  MEDIA_SPEAKER: 2.13,
  SPEAKER_TWO: 3.57,
  SPEAKER_THREE_FOUR: 1.73,
  SPEAKER_FIVE: 2.56,
};

export enum StageSize {
  small = "small",
  normal = "normal",
  large = "large",
}
