import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@apollo/client";
import { UserEventRole } from "../../configurations/userRolesPermissions.configuration";

export interface ParticipantWaitingRoom {
  id: string;
  eventRole?: UserEventRole;
  name: string;
  email: string;
  customName?: string;
}

export interface ParticipantsWaitingRoomQueryData {
  getWaitingRoomParticipants: Partial<ParticipantWaitingRoom>[];
}

export interface ParticipantsWaitingRoomQueryVars {
  meetingId: number;
}

export const GET_PARTICIPANTS_WAITING_ROOM = gql`
  query getWaitingRoomParticipants($meetingId: Int) {
    getWaitingRoomParticipants(meetingId: $meetingId) {
      id
      eventRole
      name
      email
    }
  }
`;

export function useParticipantsWaitingRoomQuery() {
  return useLazyQuery<ParticipantsWaitingRoomQueryData, ParticipantsWaitingRoomQueryVars>(
    GET_PARTICIPANTS_WAITING_ROOM
  );
}

export interface CreateParticipantWaitingRoomMutationData {
  createParticipantWaitingRoom: Partial<ParticipantWaitingRoom>;
}

export interface CreateParticipantWaitingRoomMutationVars {
  meetingId: number;
  eventRole?: UserEventRole;
  name: string;
  email: string;
  wsConnectionId: string;
}

export const CREATE_WAITING_ROOM_PARTICIPANT_MUTATION = gql`
  mutation createWaitingRoomParticipant(
    $meetingId: Int
    $eventRole: String
    $name: String
    $email: String
    $wsConnectionId: String
  ) {
    createWaitingRoomParticipant(
      meetingId: $meetingId
      eventRole: $eventRole
      name: $name
      email: $email
      id: $wsConnectionId
    ) {
      id
      eventRole
      name
      email
    }
  }
`;

export function useCreateParticipantWaitingRoomMutation(
  options?: MutationHookOptions<
    CreateParticipantWaitingRoomMutationData,
    CreateParticipantWaitingRoomMutationVars
  >
) {
  return useMutation<
    CreateParticipantWaitingRoomMutationData,
    CreateParticipantWaitingRoomMutationVars
  >(CREATE_WAITING_ROOM_PARTICIPANT_MUTATION, options);
}

export interface UpdateParticipantWaitingRoomNameMutationData {
  updateParticipantWaitingRoomName: Partial<ParticipantWaitingRoom>;
}

export interface UpdateParticipantWaitingRoomNameMutationVars {
  meetingId: number;
  participantWaitingRoomId: string;
  customName: string;
}

export const UPDATE_WAITER_NAME_MUTATION = gql`
  mutation updateParticipantWaitingRoomName(
    $meetingId: Int
    $participantWaitingRoomId: String
    $customName: String
  ) {
    updateParticipantWaitingRoomName(
      meetingId: $meetingId
      participantWaitingRoomId: $participantWaitingRoomId
      customName: $customName
    ) {
      id
      customName
    }
  }
`;

export function useUpdateParticipantWaitingRoomNameMutation(
  options?: MutationHookOptions<
    UpdateParticipantWaitingRoomNameMutationData,
    UpdateParticipantWaitingRoomNameMutationVars
  >
) {
  return useMutation<
    UpdateParticipantWaitingRoomNameMutationData,
    UpdateParticipantWaitingRoomNameMutationVars
  >(UPDATE_WAITER_NAME_MUTATION, options);
}

export const WAITER_NAME_SUBSCRIPTION = gql`
  subscription onParticipantWaitingRoomNameUpdated($meetingId: Int) {
    onParticipantWaitingRoomNameUpdated(meetingId: $meetingId) {
      id
      customName
    }
  }
`;

export interface ParticipantWaitingRoomSubVars {
  meetingId: number;
}
export interface ParticipantWaitingRoomNameSubData {
  onParticipantWaitingRoomNameUpdated: Partial<ParticipantWaitingRoom>;
}

export function useParticipantWaitingRoomNameSubscription(
  options?: SubscriptionHookOptions<
    ParticipantWaitingRoomNameSubData,
    ParticipantWaitingRoomSubVars
  >
) {
  return useSubscription<ParticipantWaitingRoomNameSubData, ParticipantWaitingRoomSubVars>(
    WAITER_NAME_SUBSCRIPTION,
    options
  );
}

export interface DeleteParticipantWaitingRoomMutationData {
  deleteWaitingRoomParticipant: Partial<ParticipantWaitingRoom>;
}

export interface DeleteParticipantWaitingRoomMutationVars {
  meetingId: number;
  id: string;
}

export const DELETE_PARTICIPANT_WAITING_ROOM_MUTATION = gql`
  mutation deleteWaitingRoomParticipant($meetingId: Int, $id: String) {
    deleteWaitingRoomParticipant(meetingId: $meetingId, id: $id) {
      id
      eventRole
      name
      email
    }
  }
`;

export function useDeleteParticipantWaitingRoomMutation(
  options?: MutationHookOptions<
    DeleteParticipantWaitingRoomMutationData,
    DeleteParticipantWaitingRoomMutationVars
  >
) {
  return useMutation<
    DeleteParticipantWaitingRoomMutationData,
    DeleteParticipantWaitingRoomMutationVars
  >(DELETE_PARTICIPANT_WAITING_ROOM_MUTATION, options);
}

export interface CreatedParticipantWaitingRoomSubData {
  onWaitingRoomParticipantCreated: Partial<ParticipantWaitingRoom>;
}

export interface CreatedParticipantWaitingRoomSubVars {
  meetingId: number;
}

export const WAITING_ROOM_PARTICIPANT_CREATED_SUBSCRIPTION = gql`
  subscription onWaitingRoomParticipantCreated($meetingId: Int) {
    onWaitingRoomParticipantCreated(meetingId: $meetingId) {
      id
      email
      name
      eventRole
    }
  }
`;

export function useCreatedParticipantWaitingRoomSubscription(
  options?: SubscriptionHookOptions<
    CreatedParticipantWaitingRoomSubData,
    CreatedParticipantWaitingRoomSubVars
  >
) {
  return useSubscription<
    CreatedParticipantWaitingRoomSubData,
    CreatedParticipantWaitingRoomSubVars
  >(WAITING_ROOM_PARTICIPANT_CREATED_SUBSCRIPTION, options);
}

export interface DeletedParticipantWaitingRoomSubData {
  onWaitingRoomParticipantDeleted: Partial<ParticipantWaitingRoom>;
}

export interface DeletedParticipantWaitingRoomSubVars {
  meetingId: number;
}

export const WAITING_ROOM_PARTICIPANT_DELETED_SUBSCRIPTION = gql`
  subscription onWaitingRoomParticipantDeleted($meetingId: Int) {
    onWaitingRoomParticipantDeleted(meetingId: $meetingId) {
      id
      email
      name
      eventRole
    }
  }
`;

export function useDeletedParticipantWaitingRoomSubscription(
  options?: SubscriptionHookOptions<
    DeletedParticipantWaitingRoomSubData,
    DeletedParticipantWaitingRoomSubVars
  >
) {
  return useSubscription<
    DeletedParticipantWaitingRoomSubData,
    DeletedParticipantWaitingRoomSubVars
  >(WAITING_ROOM_PARTICIPANT_DELETED_SUBSCRIPTION, options);
}
