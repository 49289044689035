import React from "react";
import {
  BroadcastStream,
  SelectionType,
  StreamOriginType,
} from "../../../interfaces/broadcastStream/broadcastStream";
import { OTStreamType } from "../../../../../opentok-react/types";
import { Connection } from "../../../../../opentok-react/types/Connection.type";
import { Participant } from "../../../../../hooks/participants/participants.hook.definition";
import { AssetData, AssetType } from "../../../../../hooks/assetUploads/assetUploads.definition";
import { Undefinable } from "../../../../../utils/types/tsTypes";

export abstract class BaseBroadcastStream implements BroadcastStream {
  protected _displayName: string;
  protected _assetType: AssetType;
  protected _stream: OTStreamType;
  protected _assetData: AssetData;

  protected _streamSubscribed: Promise<void>;

  protected _resolveStreamSubscribed: () => void;

  constructor(stream: OTStreamType, options = {}) {
    this._stream = stream;
    this._streamSubscribed = new Promise(
      function (resolve) {
        this._resolveStreamSubscribed = resolve;
      }.bind(this)
    );
  }

  abstract get id(): string;
  abstract get hasAudio(): boolean;
  abstract get hasMediaStateInfo(): boolean;
  abstract get hasVideo(): boolean;
  abstract get isLocalPublisher(): boolean;
  abstract get name(): string;
  abstract get sourceUrl(): string;
  abstract get streamOrigin(): StreamOriginType;

  get type(): SelectionType | string {
    return "";
  }

  get connection(): Connection {
    return {} as Connection;
  }

  get streamSubscribed(): Promise<void> {
    return this._streamSubscribed;
  }

  get assetType(): AssetType {
    return this._assetType;
  }

  set assetType(type: AssetType) {
    this._assetType = type;
  }

  get displayName(): string {
    return this._displayName;
  }

  set displayName(displayName: string) {
    this._displayName = displayName;
  }

  get assetData(): AssetData {
    return this._assetData;
  }

  set assetData(assetData: AssetData) {
    this._assetData = assetData;
  }

  get isCameraStream(): boolean {
    return (
      (this.type === SelectionType.CAMERA || this.type === SelectionType.CUSTOM) &&
      !this.isAssetStream
    );
  }

  get isAssetStream(): boolean {
    return this.isVideoAsset || this.isAudioAsset || this.isSlideAsset;
  }

  get isV2AssetStream(): boolean {
    return false;
  }

  get isVideoAsset(): boolean {
    return this._assetType?.toUpperCase() === AssetType.VIDEO.toUpperCase();
  }

  get isAudioAsset(): boolean {
    return this._assetType?.toUpperCase() === AssetType.AUDIO.toUpperCase();
  }

  get isSlideAsset(): boolean {
    return this._assetType?.toUpperCase() === AssetType.SLIDESHOW.toUpperCase();
  }

  get isPSTNStream(): boolean {
    return false;
  }

  get isTempStream(): boolean {
    return false;
  }

  get isScreenShare(): boolean {
    return false;
  }

  getVendorStream(): any {
    return this._stream;
  }

  markStreamSubscribed(): void {
    this._resolveStreamSubscribed();
  }

  videoElement(): Undefinable<HTMLMediaElement> {
    return (<video></video>) as unknown as HTMLVideoElement;
  }

  hasAudioWithMediaState(participant: Participant): boolean {
    return false;
  }

  async videoMediaStream(): Promise<MediaStream> {
    return {} as MediaStream;
  }
}
