import { AudioVideoState } from "../../../../../components/AudioVideoControls/AudioVideoControls.definition";
import {
  AssetData,
  AssetStats,
  AssetType,
} from "../../../../../hooks/assetUploads/assetUploads.definition";
import { OTStreamType } from "../../../../../opentok-react/types";
import { StreamOriginType } from "../../../interfaces/broadcastStream/broadcastStream";
import { BaseBroadcastStream } from "./BaseBroadcastStream";

export class AssetBroadcastStream extends BaseBroadcastStream {
  private _asset: AssetData;
  _stats: AssetStats;
  private _mediaStream: MediaStream;

  // airpods and other headphones can send in play/pause signals and de-sync
  // videos in the admin console. Adding these flags to indicate when to ignore
  // a play/pause event that is fired from an external source
  private _playSignalFired: boolean;
  private _pauseSignalFired: boolean;

  private _getAssetStream: () => Promise<MediaStream>;
  private _onMediaPlay: () => void;
  private _onMediaPause: () => void;
  private _onMediaEnd: () => void;
  private _onMediaVolume: (volume: number) => void;

  constructor(asset: AssetData) {
    if (!asset) throw new Error("AssetBroadcastStream Error: a valid asset obj is required");

    super({} as OTStreamType);

    this._asset = asset;
    this.assetType = asset.assetType;
    this._getAssetStream = asset.getAssetStream;
    this._stats = { currentSlide: 1, ...(asset.stats ?? ({} as AssetStats)) };

    if (this.assetType === AssetType.VIDEO || this.assetType === AssetType.AUDIO) {
      this.addMediaEventListeners();
    }
  }

  get asset() {
    return this._asset;
  }

  get id() {
    return this._asset.id;
  }

  get displayName() {
    return this._asset.displayName;
  }

  get name() {
    return this._asset.name;
  }

  get isLocalPublisher() {
    return true;
  }

  get isAssetStream() {
    return true;
  }

  get vendorStream() {
    return null;
  }

  get sourceUrl() {
    return this._asset.url;
  }

  get hasAudio() {
    return this._asset?.assetType === AssetType.AUDIO || this._asset?.assetType === AssetType.VIDEO;
  }

  get hasVideo() {
    return (
      this._asset?.assetType === AssetType.VIDEO || this._asset?.assetType === AssetType.SLIDESHOW
    );
  }

  get isV2AssetStream() {
    return true;
  }

  get streamOrigin(): StreamOriginType {
    return StreamOriginType.CLIENT;
  }

  get hasMediaStateInfo() {
    return false;
  }

  get stats() {
    return this._stats;
  }

  get volume(): number {
    return Math.round(this.videoElement()?.volume * 100);
  }

  get isPlaying(): boolean {
    return !this.videoElement().paused;
  }

  get state(): AudioVideoState {
    return this.isPlaying ? AudioVideoState.PLAY : AudioVideoState.PAUSE;
  }

  set onMediaEnd(_onMediaEnd: () => void) {
    this._onMediaEnd = _onMediaEnd;
  }

  set onMediaPlay(_onMediaPlay: () => void) {
    this._onMediaPlay = _onMediaPlay;
  }

  set onMediaPause(_onMediaPause: () => void) {
    this._onMediaPause = _onMediaPause;
  }

  set onMediaVolume(_onMediaVolume: (volume: number) => void) {
    this._onMediaVolume = _onMediaVolume;
  }

  videoElement() {
    return this._asset?.elementRef as unknown as HTMLVideoElement;
  }

  play(volume?: number, position?: number) {
    volume >= 0 && this.changeVolume(volume);
    position >= 0 && this.changePosition(position);

    this._playSignalFired = true;
    return this.videoElement()?.play();
  }

  pause(volume?: number, position?: number) {
    volume >= 0 && this.changeVolume(volume);
    position >= 0 && this.changePosition(position);

    this._pauseSignalFired = true;
    return this.videoElement()?.pause();
  }

  changeVolume(volume: number) {
    this.videoElement().volume = volume / 100;
  }

  changePosition(position: number) {
    this.videoElement().currentTime = position;
  }

  changeSlide(page: number) {
    this._stats.currentSlide = page;
    return this._asset?.setPage?.(page);
  }

  async videoMediaStream(): Promise<MediaStream> {
    if (this._mediaStream?.active) return this._mediaStream;

    try {
      await this.streamSubscribed;
      if (this._getAssetStream) return await this._getAssetStream();
      this._mediaStream = (this._asset?.elementRef as any).captureStream(60);
      return this._mediaStream;
    } catch (err) {
      console.error("Cannot get videoMediaStream: ", err);
      return null;
    }
  }

  /**
   * Function for custom audio/video event handlers
   */
  private addMediaEventListeners() {
    // seeks back to position 0 after the video has ended
    this.videoElement()?.addEventListener("ended", () => {
      this._onMediaEnd?.();
      this.videoElement().addEventListener("loadeddata", this.pause.bind(this));
      this.videoElement().load();
      this.play();
    });

    // runs custom play function
    this.videoElement()?.addEventListener("play", () => {
      if (!this._playSignalFired) return this.pause();

      this._playSignalFired = false;
      this._onMediaPlay?.();
    });

    // runs custom pause function
    this.videoElement()?.addEventListener("pause", () => {
      if (!this._pauseSignalFired) return this.play();

      this._pauseSignalFired = false;
      this._onMediaPause?.();
    });

    this.videoElement()?.addEventListener("volumechange", () => {
      this._onMediaVolume?.(this.volume);
    });

    // Shows first frame of video after loading (instead of blank element)
    this.videoElement()?.addEventListener("loadeddata", () => {
      if (!this.isPlaying) {
        this.play();
        this.pause();
      }
    });
  }
}
