import { intersection, isArray } from "lodash";
import { UserPermissions } from "../configurations/userRolesPermissions.configuration";

export function userHasPermittedPermissions(userPermissions, permittedPermissions): boolean {
  if (allPermissionsPermitted(permittedPermissions)) return true;
  return intersection(userPermissions, permittedPermissions).length > 0;
}

function allPermissionsPermitted(permittedPermissions): boolean {
  return !!(
    isArray(permittedPermissions) &&
    permittedPermissions.find((permission) => permission === UserPermissions.ALL)
  );
}
