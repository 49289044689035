import { AttendeeConsoleBranding } from "../../services/event/event.model";

export enum ImageType {
  backgroundImage = "backgroundImage",
  companyLogo = "companyLogo",
}

export interface FontType {
  fontUrl: string;
  displayName: string;
  fileType: string;
  name?: string;
}

export interface AttendeeConsoleBrandingHeaderData {
  background?: string;
  text?: string;
}

export interface AttendeeConsoleBrandingFooterData {
  background?: string;
  primaryButton?: string;
  primaryButtonText?: string;
}

export interface AttendeeConsoleBrandingMediaBarData {
  background?: string;
  controls?: string;
}

export interface BrandingHookModel {
  backgroundImage: string;
  companyLogo: string;
  companyFavicon: string;
  attendeeConsoleBrandingState: Omit<AttendeeConsoleBranding, "images">;
  brandedBackgroundImage: string;
  hasEventBranding: boolean;
  primaryColor: string;
  loading: boolean;
  primaryFontData: FontType;
  preBroadcastMusicUri: string;
  secondaryFontData: FontType;
  secondaryColor: string;
}

export interface BrandingHookProps {
  eventBranding?: any;
  eventTitle?: string;
  companyId: string;
}
