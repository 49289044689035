import React, { memo } from "react";
import { ButtonTheme, Form, Modal, Textbox, TextboxTheme } from "@q4/nimbus-ui";
import { EditProfileProps } from "./editProfile.definition";
import useRegistrationForm from "../../../hooks/users/editProfile.hook";

function EditUserProfileModal(props: EditProfileProps): JSX.Element {
  const { visible, onClose, onSubmit, user } = props;

  const {
    resetEditProfileState,
    resetErrorState,
    errorState,
    hasErrors,
    handleFirstNameChange,
    handleLastNameChange,
    editProfileState,
  } = useRegistrationForm(user);

  function handleSubmitForm() {
    const errors = hasErrors();
    if (errors) return;
    onSubmit && onSubmit(editProfileState);
  }

  function handleCancelEdit() {
    resetEditProfileState();
    resetErrorState();
    onClose && onClose();
  }

  return (
    <Modal
      title="Edit Profile"
      visible={visible}
      onCloseRequest={onClose}
      footerActions={[
        {
          key: "close",
          label: "Cancel",
          theme: ButtonTheme.DarkSlate,
          onClick: handleCancelEdit,
        },
        {
          key: "save",
          label: "Save",
          theme: ButtonTheme.Citrus,
          onClick: handleSubmitForm,
        },
      ]}
    >
      <Form
        fields={[
          {
            key: "profileFirstName",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "First Name",
            error: errorState.firstNameError,
            children: (
              <Textbox
                onChange={handleFirstNameChange}
                theme={errorState.firstNameError.visible ? TextboxTheme.Spice : null}
                value={editProfileState.firstName}
              />
            ),
          },
          {
            key: "profileLastName",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Last Name",
            error: errorState.lastNameError,
            children: (
              <Textbox
                onChange={handleLastNameChange}
                theme={errorState.lastNameError.visible ? TextboxTheme.Spice : null}
                value={editProfileState.lastName}
              />
            ),
          },
          {
            key: "profileEmail",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Email",
            children: (
              <Textbox
                onChange={handleLastNameChange}
                disabled={true}
                value={editProfileState.email}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default memo(EditUserProfileModal);
