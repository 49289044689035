import { EventModel } from "../../hooks/events/events.hook.definitions";
import {
  Broadcast,
  DualStreamStatusUpdate,
} from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { EventStartPeriodUpdated } from "../../views/eventMicrosite/hooks/microsite.hook.definition";

export const DEFAULT_FALLBACK_POLL_INTERVAL = 60000;

export type SubscriptionsContextSubscribe = (subscribe: boolean) => void;

export interface SubscriptionsContextState {
  onBroadcastStatusUpdated: Partial<Broadcast>;
  broadcastStatusSubscribe: SubscriptionsContextSubscribe;
  onEventStartPeriodUpdated: EventStartPeriodUpdated;
  eventStartPeriodSubscribe: SubscriptionsContextSubscribe;
  onEventVotingSettingUpdated: Partial<EventModel>;
  votingStatusSubscribe: SubscriptionsContextSubscribe;
  onEventRslSettingUpdated: Partial<EventModel>;
  rslStatusSubscribe: SubscriptionsContextSubscribe;
  onEventQuestionSettingUpdated: Partial<EventModel>;
  questionStatusSubscribe: SubscriptionsContextSubscribe;
  onDualStreamStatusUpdated: Partial<DualStreamStatusUpdate>;
  dualStreamStatusSubscribe: SubscriptionsContextSubscribe;
  startBroadcastStatusPolling(): void;
  stopBroadcastStatusPolling(): void;
}
