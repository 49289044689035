import { User as UserModel } from "./user.model";

export type UserType = UserModel;

export enum UserStatusType {
  All = "",
  Active = "active",
  Pending = "pending",
  Suspended = "suspended",
  Locked = "locked",
}

export enum UserGetField {
  SortByCompanyName = "firstName",
}

export interface UserDetailProp {
  expiry: string; // Date?
  id: string;
}
