import {
  Button,
  ButtonSize,
  ButtonTheme,
  getClassName,
  Origin,
  PopoverMenu,
  PopoverMenuTheme,
  Tooltip,
  TooltipTheme,
} from "@q4/nimbus-ui";
import React, { useCallback, useEffect, useState } from "react";
import { ReleaseState, ReleaseTag } from "../../../../components/tags/ReleaseTag.component";
import { useClassToggle } from "../../../../hooks/classToggle/useClassToggle.hook";
import {
  VideoWrapperClassNames,
  VideoWrapperProps,
  VWSelectionTheme,
} from "./VideoWrapper.definition";
import "./VideoWrapper.scss";

const VWReleaseTag = (props: { release: ReleaseState }) => {
  const { release } = props;

  return release ? <ReleaseTag tag={release} /> : null;
};

export function VideoWrapper(props: VideoWrapperProps) {
  const {
    id,
    children,
    customLeftBadge,
    customRightBadge,
    displayName,
    selected = false,
    initializing = false,
    selectionTheme = VWSelectionTheme.citrus,
    toolTipProps,
    topLeftProps,
    topRightProps,
    thumbnailImg,
    onSelect,
    className = "",
  } = props;
  const { label: toolTipLabel = "", theme: toolTipTheme = TooltipTheme.Citrus } =
    toolTipProps || {};
  const { release, custom: customTLComponent } = topLeftProps || {};
  const {
    buttonProps: trmButton,
    popOverProps: trmPopOver,
    showTRMOnHover = false,
  } = topRightProps || {};

  const [_selected, setSelected] = useState(selected);

  useEffect(
    function changeSelected() {
      setSelected(selected);
    },
    [selected]
  );

  const onSelectWrapper = useCallback(() => {
    if (onSelect) {
      setSelected((_selected) => !_selected);
      onSelect?.(id);
    }
  }, [id, onSelect]);

  const selectableClassName = getClassName("vw-selection", [
    {
      condition: _selected,
      trueClassName: `${VideoWrapperClassNames.IsSelected}`,
    },
    {
      condition: _selected && selectionTheme === VWSelectionTheme.citrus,
      trueClassName: `${VideoWrapperClassNames.SelectedCitrus}`,
    },
    {
      condition: _selected && selectionTheme === VWSelectionTheme.teal,
      trueClassName: `${VideoWrapperClassNames.SelectedTeal}`,
    },
  ]);

  const baseClassName = getClassName("vw", [
    {
      condition: !!className,
      trueClassName: className,
    },
    { condition: initializing, falseClassName: "vw-ready" },
  ]);

  // Top Right Menu (trm)
  const [trMenuOpen, setTRMenuOpen] = useState(false);

  const onTRMBtnClick = useCallback(
    (event) => {
      event.stopPropagation();

      trmPopOver && setTRMenuOpen((trMenuOpen) => !trMenuOpen);
      trmButton?.onClick?.(event);
    },
    [trmButton, trmPopOver]
  );

  const onTRMenuClose = useCallback(() => setTRMenuOpen(false), []);

  const { toggle: showToolTip, toggledClassName: toolTipClassName } = useClassToggle({
    className: "vwToolTip",
  });

  const { toggle: showTRMButton, toggledClassName: trmButtonClassName } = useClassToggle({
    className: "vw-inner-top-right-menu-btn",
    initialState: !showTRMOnHover,
  });

  const onWrapperMouseEnter = useCallback(() => {
    toolTipProps && showToolTip(true);
    showTRMOnHover && showTRMButton(true);
  }, [showTRMButton, showTRMOnHover, showToolTip, toolTipProps]);

  const onWrapperMouseLeave = useCallback(() => {
    toolTipProps && showToolTip(false);
    showTRMOnHover && showTRMButton(false);
  }, [showTRMButton, showTRMOnHover, showToolTip, toolTipProps]);

  return (
    <div
      className={baseClassName}
      id={id}
      onMouseEnter={onWrapperMouseEnter}
      onMouseLeave={onWrapperMouseLeave}
      onClick={onSelectWrapper}
    >
      {toolTipProps && (
        <Tooltip
          className={toolTipClassName}
          position={Origin.Top}
          theme={toolTipTheme}
          label={toolTipLabel}
        />
      )}
      <div className={selectableClassName} />
      <div className={"vw-inner"}>
        <div className={"vw-inner-top"}>
          <div className={"vw-inner-top-left"}>
            {customTLComponent ? customTLComponent : <VWReleaseTag release={release} />}
          </div>
          <div className={"vw-inner-top-right"}>
            <>
              {(trmButton || trmPopOver) && (
                <Button
                  theme={ButtonTheme.White}
                  size={ButtonSize.Small}
                  {...trmButton}
                  className={`${trmButtonClassName} ${trmButton?.className || ""}`}
                  id={`${trmButton.id || "vw"}-trmMenu-${id}`}
                  onClick={onTRMBtnClick}
                  icon={trmPopOver ? "q4i-utility-4pt" : trmButton?.icon}
                />
              )}
              {trmPopOver && (
                <PopoverMenu
                  popoverOrigin={Origin.BottomRight}
                  targetOrigin={Origin.TopRight}
                  theme={PopoverMenuTheme.White}
                  {...trmPopOver}
                  anchorTargetElementId={`${trmButton.id || "vw"}-trmMenu-${id}`}
                  visible={trMenuOpen}
                  onCloseRequest={onTRMenuClose}
                />
              )}
            </>
          </div>
        </div>
        <div className={"vw-inner-bottom"}>
          {(customLeftBadge || displayName) && (
            <div className={"vw-inner-bottom-left"}>
              <div className={"vw-inner-bottom-left-badge"}>
                {customLeftBadge} {displayName}
              </div>
            </div>
          )}
          <div className={"vw-inner-bottom-right"}>{customRightBadge}</div>
        </div>
      </div>
      {thumbnailImg && <div className={VideoWrapperClassNames.Thumbnail}>{thumbnailImg}</div>}
      <div className={"vw-inner-children"}>{children}</div>
    </div>
  );
}
