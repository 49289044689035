import React from "react";
import { BaseComponentProps, getClassName } from "@q4/nimbus-ui";
import "./releaseTag.scss";

export enum ReleaseState {
  ALPHA = "ALPHA",
  BETA = "BETA",
}
interface ReleaseTagProps extends BaseComponentProps {
  tag?: ReleaseState;
}
enum TagClassNames {
  Base = "tag",
  Alpha = "tag--alpha",
  Beta = "tag--beta",
}

export const ReleaseTag = (props: ReleaseTagProps) => {
  const { className, tag = ReleaseState.BETA } = props;

  const tagClass = getClassName(TagClassNames.Base, [
    { condition: !!className, trueClassName: className },
    { condition: tag === ReleaseState.BETA, trueClassName: TagClassNames.Beta },
    { condition: tag === ReleaseState.ALPHA, trueClassName: TagClassNames.Alpha },
  ]);

  return <span className={tagClass}>{tag}</span>;
};
