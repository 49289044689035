export interface FallbackProps {
  hideBackBtn?: boolean;
  title?: string;
  subtitle?: any;
  isSupportedBrowsers?: boolean;
  showUnsupportedSafari?: boolean;
  continueToEvent?: () => void;
  actions?: Array<any>;
  isBrowserSupport?: boolean;
}

export enum UnsupportedBrowser {
  Base = "unsupported-browser",
  Title = "unsupported-browser_title",
  Container = "unsupported-browser_container",
  ContainerItem = "unsupported-browser_container-item",
  Logo = "unsupported-browser_logo",
  Anchor = "unsupported-browser_anchor",
}

export enum FallbackClassName {
  Base = "fallback",
  ContinueFooter = "fallback_continue-footer",
  ContinueAnchor = "fallback_continue-footer-anchor",
}

export enum ErrorCode {
  userInputError = "UNPROCESSABLE_ENTITY",
}

export enum ErrorStrings {
  invalidMeetingId = "We can't seem to load the page you're looking for. Refresh your page and try again.",
}
