import { IdModelBase, TextboxIdModel, isNullOrWhiteSpace, SelectIdModel } from "@q4/nimbus-ui";
import { RegistrationFieldType } from "../../../../../services/event/registrationFieldGql.model";
import { CustomRegistrationFieldFormBaseIdModel } from "../base/customRegistrationFieldFormBase.definition";

export enum CustomRegistrationFieldFormTextClassName {
  Base = "custom-registration-field-form-text",
  FieldTypeConfiguration = "custom-registration-field-form-text_field-configuration",
}

export enum CustomRegistrationFieldFormTextLabel {
  FieldTypeConfiguration = "Field Type",
}

export const FieldTypeOptions = [
  { value: RegistrationFieldType.Text, label: "Short Answer" },
  { value: RegistrationFieldType.LongText, label: "Long Answer" },
];

export class CustomRegistrationFieldFormTextIdModel extends IdModelBase {
  formBase: CustomRegistrationFieldFormBaseIdModel;
  prompt: TextboxIdModel;
  fieldType: SelectIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.formBase = new CustomRegistrationFieldFormBaseIdModel(`${this.id}TextForm`);
    this.prompt = new TextboxIdModel(`${this.id}PromptTextbox`);
    this.fieldType = new SelectIdModel(`${this.id}FieldTypeSelect`);
  }
}
