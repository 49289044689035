import DocumentImage from "../../assets/events/materials.png";
import { PlaceholderContentProps } from "@q4/nimbus-ui";

export enum SlideViewerClassNames {
  Base = "slide-viewer",
  BaseVod = "slide-viewer--vod",
  AmountPages = "slide-viewer_amount-pages",
  Controls = "slide-viewer_controls",
  ControlsLeft = "slide-viewer_controls-left",
  ControlsRight = "slide-viewer_controls-right",
  ControlsDisabled = "slide-viewer_controls-disabled",
  Document = "slide-viewer_document",
  ErrorPlaceholder = "slide-viewer_error-placeholder",
  Loading = "slide-viewer_loading-text",
  LoadingWrapper = "slide-viewer_loading-wrapper",
  MainViewer = "slide-viewer_main-viewer",
  MainViewerInner = "slide-viewer_main-viewer-inner",
  MainViewerOuter = "slide-viewer_main-viewer-outer",
  SlidesList = "slide-viewer_slides-list",
  SlidesSidebar = "slide-viewer_slides-sidebar",
  SlidesSidebarInvisible = "slide-viewer_slides-sidebar--invisible",
  SmallSlide = "slide-viewer_small-slide",
  SmallSlidePage = "slide-viewer_small-slide-page",
  SmallSlideLoadingPage = "slide-viewer_small-slide-loading-page",
  SmallSlideLoadingPageAnimation = "slide-viewer_small-slide-loading-page-animation",
  SmallSlidePageIndicator = "small-slide",
  SmallSlideWrapper = "slide-viewer_small-slide-wrapper",
  SmallSlideWrapperIsActive = "slide-viewer_small-slide-wrapper--is-active",
  Thumbnail = "slide-viewer_small-slide-loading-wrapper",
}

export class SlideViewerPlaceholderProps {
  image = DocumentImage;
  title = "Oops! We encountered an issue";
  subtitle = "Please try reloading the presentation";

  constructor(
    placeholderContentProps: Partial<
      Pick<PlaceholderContentProps, "image" | "title" | "subtitle" | "actions">
    >
  ) {
    Object.assign(this, placeholderContentProps);
  }
}

export interface SlideViewerProps {
  dataId?: string;
  defaultId?: string;
  failed?: boolean;
  file?: Blob;
  id?: string;
  infiniteScroll?: boolean;
  initialPage?: number;
  nextPageBtnIcon?: string;
  placeholderProps?: SlideViewerPlaceholderProps;
  prevPageBtnIcon?: string;
  thumbnailListVisible?: boolean; // toggle visibility of thumbnail list
  includeThumbnailList?: boolean; // should thumbnail list render
  url?: string;
  viewerScale?: number;
  showControls?: boolean;
  overrides?: {
    width?: number;
    height?: number;
  };
  onFileLoadFail?(error): void;
  onCanvasRefLoaded?: (setPage: (page: number) => void, totalSlides: number) => (ref) => void;
  onPageChange?: ({ width, height }) => void;
}

export const SmallSlideSettings = {
  ITEM_WIDTH: 112,
};

export interface SlidePageManagerModel {
  currentPage: number;
  getCurrentPage(): number;
  getPages(): number;
  jumpToPage(targetPage: number): void;
  setPages(numPages: number): void;
  setCurrentPage(page: number): void;
}
