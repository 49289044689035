import React, { memo, ReactNode, useCallback, useMemo } from "react";
import AssetVideoWrapper from "../../adminConsole/components/assetVideoWrapper/assetVideoWrapper.component";
import SlidesList from "../../../components/slideViewer/slideList/slideList.component";
import { ButtonTheme, Message, MessageType, useVisibility } from "@q4/nimbus-ui";
import "./stagedSlides.component.scss";
import { isFunction } from "lodash";
import { ReleaseState } from "../../../components/tags/ReleaseTag.component";
import { useRebrandFlag } from "../../../hooks/featureFlagHooks/useRebrandFlag.hook";

interface StagedSlidesProps {
  url: string;
  displayName: string;
  children: ReactNode;
  onStreamRemove: () => void;
  controlSlidesState: {
    currentSlide: number;
    totalSlides: number;
  };
  controlSlidesService: {
    jumpToSlide: (targetSlide: number) => void;
    setTotalSlides: (totalSlides: number) => void;
  };
  includeThumbnails: boolean;
  release?: ReleaseState;
}

const StagedSlidesClassNames = {
  Base: "staged-slides",
  Confirmation: "staged-slides_confirmation",
};

const StagedSlides = (props: StagedSlidesProps) => {
  const {
    url,
    displayName,
    children,
    onStreamRemove,
    controlSlidesService,
    controlSlidesState,
    includeThumbnails,
    release,
  } = props;

  const [removeModalVisible, showRemoveModal, hideRemoveModal] = useVisibility();
  const { getRebrandingClassName } = useRebrandFlag();

  const removeStreamFromStage = useCallback(() => {
    onStreamRemove();
    hideRemoveModal();
  }, [onStreamRemove, hideRemoveModal]);

  const onStreamRemoveClick = useMemo(() => {
    if (!isFunction(onStreamRemove)) return null;
    return showRemoveModal;
  }, [onStreamRemove, showRemoveModal]);

  return (
    <>
      {includeThumbnails && (
        <SlidesList
          url={url}
          controlSlidesService={controlSlidesService}
          controlSlidesState={controlSlidesState}
        />
      )}
      <AssetVideoWrapper onStreamRemove={onStreamRemoveClick} release={release}>
        {children}
      </AssetVideoWrapper>
      {removeModalVisible && (
        <Message
          className={getRebrandingClassName(StagedSlidesClassNames.Confirmation)}
          messageType={MessageType.Warning}
          title="Remove From Stage"
          message={`${displayName || "Your selection"} will be removed from the live stage`}
          visible={removeModalVisible}
          primaryActionProps={{
            label: "remove",
            // @ts-ignore
            theme: ButtonTheme.Cherry,
            onClick: removeStreamFromStage,
          }}
          secondaryActionProps={{
            label: "cancel",
            onClick: hideRemoveModal,
          }}
          onCloseRequest={hideRemoveModal}
        />
      )}
    </>
  );
};

export default memo(StagedSlides);
