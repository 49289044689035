import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { createContext, useState } from "react";
import { v4 as uuid } from "uuid";
import useBranding from "../../hooks/branding/branding.hook";
import {
  AttendeeEventRoles,
  UserEventRole,
} from "../../configurations/userRolesPermissions.configuration";

import useEventDetails from "../../hooks/eventDetails/eventDetails.hook";
import { EventContextState } from "./event.definition";

const EventContext = createContext<Partial<EventContextState>>({});

export const EventProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const {
    eventId,
    currentEvent,
    userEventPermissions,
    broadcastStatus,
    eventConfiguration,
    broadcastStatusContext,
    broadcastStartTime,
    eventStatus,
    sessionToken,
    meetingMaterials,
    setEventId,
    setCurrentEvent,
    setUserEventPermissions,
    setBroadcastStatus,
    setBroadcastStatusContext,
    setBroadcastStartTime,
    setEventStatus,
    setSessionToken,
    setMeetingMaterials,
  } = useEventDetails();

  // Connection Info
  const [wsConnectionId] = useState<string>(uuid());
  const [participantRole, setParticipantRole] = useState<UserEventRole | AttendeeEventRoles>();

  function updateParticipantRole(participantRole: UserEventRole | AttendeeEventRoles) {
    setParticipantRole(participantRole);
  }

  const [materialsLoading, setMaterialsLoading] = useState(false);

  function updateMaterialsLoading(flag: boolean) {
    setMaterialsLoading(flag);
  }
  const { brandedBackgroundImage: backgroundImageWithTitle } = useBranding({
    eventTitle: currentEvent?.title,
    companyId: currentEvent?.company?.id,
    eventBranding: currentEvent?.branding?.controlPanelBranding,
  });

  const [featuresByEventType, setFeaturesByEventType] = useState({});

  return (
    <EventContext.Provider
      value={{
        eventId,
        currentEvent,
        userEventPermissions,
        broadcastStatus,
        wsConnectionId,
        participantRole,
        eventConfiguration,
        broadcastStatusContext,
        broadcastStartTime,
        eventStatus,
        sessionToken,
        meetingMaterials,
        materialsLoading,
        backgroundImageWithTitle,
        featuresByEventType,
        // XXX: why do we have both `setCurrentEvent` and `setEventId`, should we compute `eventId` instead
        // currently we can have `currentEvent.eventId` and `eventId` with different values
        setEventId,
        setCurrentEvent,
        setUserEventPermissions,
        setBroadcastStatus,
        updateParticipantRole,
        setBroadcastStatusContext,
        setBroadcastStartTime,
        setEventStatus,
        setSessionToken,
        setMeetingMaterials,
        updateMaterialsLoading,
        setFeaturesByEventType,
      }}
    >
      {props?.children}
    </EventContext.Provider>
  );
};

export default EventContext;
