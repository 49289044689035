import { datadogLogs, HandlerType, StatusType } from "@datadog/browser-logs";

// DD allows us to name our loggers, which may act as a nice filter
export enum LoggerDomains {
  Custom = "CustomLogger",
  Q4Login = "Q4Login",
  ExperienceComposer = "ExperienceComposer",
  AdminConsole = "AdminConsole",
  AttendeeConsole = "AttendeeConsole",
}

export interface LoggerContext {
  handler?: Array<HandlerType> | HandlerType;
  context?: {
    [key: string]: string;
  };
  level?: StatusType;
  loggerName?: LoggerDomains;
}

export const createLogger = (props: LoggerContext = {}) => {
  const {
    handler = ["http", "console"],
    context = {},
    level = StatusType.error,
    loggerName = LoggerDomains.Custom,
  } = props;
  const ddLogger = datadogLogs.createLogger(loggerName, { handler, context, level });
  return ddLogger;
};

export const setLogsContextProperty = (key: string, value: any) => {
  datadogLogs.setGlobalContextProperty(key, value);
};

export const removeLogsContextProperty = (key: string) => {
  datadogLogs.removeGlobalContextProperty(key);
};
