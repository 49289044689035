import { ResizeSensor } from "css-element-queries";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { largestRect } from "rect-scaler";
import "./bestFitLayout.component.scss";

export interface BestFitProps {
  layoutContents: Array<any>;
  contentSettings?: {
    aspectRatio?: number;
  };
  boundingContainerSettings?: {
    query?: string;
    maxColumns?: number;
    minColumns?: number;
  };
}

export const BestFitLayout = ({
  layoutContents = [],
  boundingContainerSettings: { query = "body", maxColumns, minColumns } = {},
  contentSettings: { aspectRatio = 16 / 9 } = {},
}: BestFitProps) => {
  const blfRef = useRef<HTMLDivElement>();

  const resizeSensorRef = useRef<ResizeSensor>();

  const recalculateLayout = useCallback(() => {
    const contentCount = layoutContents.length;

    const boundingContainer = document.querySelector(query);

    const wPadding = boundingContainer
      ? window
          ?.getComputedStyle?.(boundingContainer)
          ?.getPropertyValue?.("padding-left")
          ?.split("px")?.[0]
      : 0;
    const hPadding = boundingContainer
      ? window
          ?.getComputedStyle?.(boundingContainer)
          ?.getPropertyValue?.("padding-top")
          ?.split("px")?.[0]
      : 0;

    const boundingWidth = Math.max(
      boundingContainer.getBoundingClientRect().width - 2 * +(wPadding || 0),
      0
    );
    const boundingHeight = Math.max(
      boundingContainer.getBoundingClientRect().height - 2 * +(hPadding || 0),
      0
    );

    const rectWidth = 1;
    const rectHeight = rectWidth / aspectRatio;

    let {
      cols: columns,
      width,
      height,
    } = largestRect(boundingWidth, boundingHeight, contentCount, rectWidth, rectHeight);

    if ((maxColumns && columns > maxColumns) || (minColumns && columns < minColumns)) {
      const forcedColumns = columns > maxColumns ? maxColumns : minColumns;
      const { width: calcWidth, height: calcHeight } = contentDimensionsByColumn({
        columns: forcedColumns,
        contentCount,
        boundingWidth,
        boundingHeight,
        aspectRatio,
      });

      width = calcWidth;
      height = calcHeight;
      columns = forcedColumns;
    }

    blfRef.current?.style.setProperty("--width", width + "px");
    blfRef.current?.style.setProperty("--height", height + "px");
    blfRef.current?.style.setProperty("--cols", columns + "");
  }, [aspectRatio, layoutContents.length, maxColumns, minColumns, query]);

  useEffect(
    function listenForBoundingContainerResize() {
      const boundingContainer = document.querySelector(query);

      if (boundingContainer) {
        resizeSensorRef.current = new ResizeSensor(boundingContainer, recalculateLayout);
      } else {
        window.addEventListener("resize", recalculateLayout);
      }
      recalculateLayout();

      return () => {
        resizeSensorRef.current?.detach?.();
        window.removeEventListener("resize", recalculateLayout);
      };
    },
    [query, recalculateLayout]
  );

  return (
    <>
      <div className="bfl" ref={blfRef}>
        {layoutContents.map((content: ReactElement) => (
          <div className="bfl-container" key={content?.key}>
            {content}
          </div>
        ))}
      </div>
    </>
  );
};

function contentDimensionsByColumn({
  columns,
  contentCount,
  boundingWidth,
  boundingHeight,
  aspectRatio,
}) {
  const rows = Math.ceil(contentCount / columns);

  const hScale = boundingWidth / (columns * aspectRatio);
  const vScale = boundingHeight / rows;
  let width: number;
  let height: number;

  if (hScale <= vScale) {
    width = Math.floor(boundingWidth / columns);
    height = Math.floor(width / aspectRatio);
  } else {
    height = Math.floor(boundingHeight / rows);
    width = Math.floor(height * aspectRatio);
  }

  return { height, width };
}
