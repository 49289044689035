import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "./utils/WebSocketLink";
import { getMainDefinition } from "@apollo/client/utilities";
import { useCallback, useEffect, useRef, useState } from "react";
import config from "../config";
import { Auth0ContextState } from "../contexts/auth0/auth0.definition";
import { ApolloMergePolicy } from "./apollo-merge";
import { typeDefs } from "./typeDefs";
import { createUploadLink } from "apollo-upload-client";
import {
  AttendeeEventRoles,
  UserEventRole,
} from "../configurations/userRolesPermissions.configuration";
import { getParticipantFromLocalStore, isAttendeeView } from "../utils/attendee.utils";
import { useApolloEvents } from "./apollo-events.hook";
import { entries, isArray, isPlainObject } from "lodash";
import { useBrowserTabState } from "../hooks/browser/browser.hook";
import { usePrevious } from "../opentok-react/utils/usePrevious";
import { isMobile } from "react-device-detect";
import {
  COMPANY_ID_HEADER_KEY,
  MEETING_ID_HEADER_KEY,
  USER_ID_HEADER_KEY,
} from "../hoc/cacheBuster/hooks/versionQuery.hook";
import { BroadcastSource } from "../services/event/eventGql.model";
import { isTenantedView } from "../utils/event.utils";

interface ApolloHookProps {
  auth: Auth0ContextState;
  isAttendeeAuthenticated: boolean;
  eventId: number;
  companyId: string;
  wsConnectionId: string;
  participantRole: UserEventRole | AttendeeEventRoles;
  broadcastSource?: BroadcastSource;
}

const omitTypeNames = <T extends any>(variables: T): T => {
  return isArray(variables)
    ? (variables.map(omitTypeNames) as T)
    : isPlainObject(variables)
    ? (entries(variables as object).reduce(
        (cleanVars, [key, value]) =>
          key === "__typename" ? cleanVars : { ...cleanVars, [key]: omitTypeNames(value) },
        {}
      ) as T)
    : variables;
};

const cleanTypeName = new ApolloLink((operation, forward) => {
  const def = getMainDefinition(operation.query);
  if (def && "operation" in def && def.operation === "mutation") {
    operation.variables = omitTypeNames(operation.variables);
  }
  return forward ? forward(operation) : null;
});

const httpLink = createUploadLink({ uri: `${config.api.graphqlBaseUrl}/graphql` });

export function useApollo(props: ApolloHookProps) {
  const {
    auth,
    isAttendeeAuthenticated,
    companyId,
    eventId,
    wsConnectionId,
    participantRole,
    broadcastSource,
  } = props;

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const eventIdRef = useRef({});
  eventIdRef.current = eventId;

  const participantRoleRef = useRef({});
  participantRoleRef.current = participantRole;

  const wsConnectionIdRef = useRef({});
  wsConnectionIdRef.current = wsConnectionId;

  const broadcastSourceRef = useRef({});
  broadcastSourceRef.current = broadcastSource;

  // using refs for eventId, companyId and auth as they were all stale
  // inside our apollo token middleware
  const companyIdRef = useRef({});
  companyIdRef.current = companyId;

  const authContextRef = useRef<Auth0ContextState>();
  authContextRef.current = auth;

  const { browserTabActive } = useBrowserTabState();
  const prevBrowserTabActive = usePrevious(browserTabActive);
  const previousToken = usePrevious(auth.currentToken);

  const { notifyConnectionError, notifyConnected, notifyReconnected, notifyDisconnected } =
    useApolloEvents();

  const getWSConnQueryString = useCallback(() => {
    return new URLSearchParams({
      ...(eventId && { [MEETING_ID_HEADER_KEY]: `${eventId}` }),
      ...(companyId && { [COMPANY_ID_HEADER_KEY]: `${companyId}` }),
      "ep-wss": "true",
    }).toString();
  }, [companyId, eventId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initApolloClient, []);

  const connectionParamsFn = useCallback(async () => {
    const token = await auth.getUserAccessToken();
    const connectionParams = {
      ...getConnectionParams(),
    };

    if (token) {
      connectionParams["Authorization"] = `Bearer ${token}`;
    }

    return connectionParams;
  }, [auth]);

  const wsLink = WebSocketLink.getInstance({
    notifyConnected,
    notifyReconnected,
    notifyConnectionError,
    notifyDisconnected,
    connectionParams: connectionParamsFn,
  });

  const establishWsConnection = useCallback(() => {
    // Prevents participant duplication
    if (isTenantedView() && !participantRoleRef.current) {
      return;
    }

    // In case we're waiting for `companyId`/`meetingId` to initialize the WS link
    // it affects the rest of GraphQL requests.
    // Other words, while waiting for the WS link to be initialized Apollo client is still undefined.
    // so, it's not possible to execute any requests against our API. The page stays blank.
    //
    // The idea behind the following implementation is to initialize WS link immediately
    // to avoid the Apollo client still being undefined for a long.
    // Create a waiting mechanism inside WebSocketLink class.
    // The singleton pattern here helps to avoid multiple requests being sent
    // and losing the inner state of the class when react rerenders.
    wsLink.setReady({
      isAuthenticated: isAttendeeView() ? isAttendeeAuthenticated : auth.isAuthenticated,
      allowWindowReload: isMobile && !prevBrowserTabActive && browserTabActive,
      queryParameters: getWSConnQueryString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getWSConnQueryString,
    wsLink,
    isAttendeeAuthenticated,
    auth.isAuthenticated,
    prevBrowserTabActive,
    browserTabActive,
    participantRoleRef.current,
  ]);

  useEffect(
    function createWebsocketConnection() {
      // This code might be executed a number of times.
      // The singleton implementation inside guarantees that we're creating only one WS connection/client
      // It's not a case of waiting for the company id or for the admin/attendee to be authenticated and initialize the WS link afterward
      // because we might miss essential GraphQL subscriptions we do wherever in the code while waiting.
      establishWsConnection();
    },
    [
      previousToken,
      auth.currentToken,
      auth.isAuthenticated,
      isAttendeeAuthenticated,
      getWSConnQueryString, // includes eventId, companyId deps
      establishWsConnection,
    ]
  );

  function initApolloClient() {
    const authLink = setContext(async (_, { headers }) => {
      const customMeetingId = eventIdRef.current
        ? { [MEETING_ID_HEADER_KEY]: eventIdRef.current }
        : {};
      const customCompanyId = !!companyIdRef.current
        ? { [COMPANY_ID_HEADER_KEY]: companyIdRef.current }
        : {};

      const userMetaData = {};

      if (authContextRef.current?.user?.id) {
        userMetaData[USER_ID_HEADER_KEY] = authContextRef.current.user.id;
      }

      const defaultTokenMiddleware = async () => {
        const token = await authContextRef.current?.getUserAccessToken();
        return {
          ...(token && {
            authorization: `Bearer ${token}`,
            ...customMeetingId,
            ...customCompanyId,
            ...userMetaData,
          }),
        };
      };
      return {
        headers: headers?.Authorization
          ? { ...headers, ...customMeetingId, ...customCompanyId, ...userMetaData }
          : await defaultTokenMiddleware(),
      };
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      httpLink
    );

    const errorLink = onError(({ operation, forward, response }) => {
      auth?.errorInterceptor(response);
      forward(operation);
    });

    const apolloClient = new ApolloClient({
      link: authLink.concat(cleanTypeName).concat(errorLink).concat(splitLink),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              ...ApolloMergePolicy,
            },
          },
        },
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
      },
      typeDefs: typeDefs,
    });

    setClient(apolloClient);
  }

  function getConnectionParams() {
    const participantInfo = getParticipantFromLocalStore(eventIdRef.current);
    return {
      eventId: eventIdRef.current,
      participantRole: participantRoleRef.current,
      wsConnectionId: wsConnectionIdRef.current,
      userId: participantInfo?.id,
      broadcastSource: broadcastSourceRef.current,
    };
  }
  return {
    apolloClient: client,
  };
}
