import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
} from "@apollo/client";

export interface Broadcast {
  status: string;
  context?: BroadcastContextStatus;
  startTime?: Date;
  broadcastUrl?: string;
  backupBroadcastUrl?: string;
}

export interface DualStream {
  status: string;
}

export enum BroadcastStatusEnum {
  STARTING = "STARTING",
  START_FAILED = "START_FAILED",
  STARTED = "STARTED",
  ENDING = "ENDING",
  END_FAILED = "END_FAILED",
  ENDED = "ENDED",
}

export enum DualStreamStatusEnum {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum BroadcastContextStatus {
  DEFAULT = "NONE",
  PAUSED = "PAUSED",
  FINALIZED = "FINALIZED",
}

export interface UpdateBroadcastStatusVars {
  meetingId: number;
  status?: BroadcastStatusEnum;
}

export interface UpdateBroadcastStatusData {
  updateBroadcastStatus: Partial<Broadcast>;
}

export interface UpdateDualStreamStatusVars {
  meetingId: number;
  status?: DualStreamStatusEnum;
}

export interface UpdateDualStreamStatusData {
  updateDualStreamStatus: Partial<DualStream>;
}

export interface StartBroadcastVars {
  meetingId: number;
}

export interface StartBroadcastData {
  startEventBroadcast: Partial<Broadcast>;
}

export interface StopBroadcastVars {
  meetingId: number;
  context?: BroadcastContextStatus;
}

export interface StopBroadcastData {
  stopEventBroadcast: Partial<Broadcast>;
}

export const UPDATE_BROADCAST_STATUS_MUTATION = gql`
  mutation UPDATE_BROADCAST_STATUS_MUTATION($meetingId: Int, $status: BroadcastStatusEnum) {
    updateBroadcastStatus(meetingId: $meetingId, status: $status) {
      status
    }
  }
`;

export const UPDATE_DUAL_STREAM_STATUS_MUTATION = gql`
  mutation UPDATE_DUAL_STREAM_STATUS_MUTATION($meetingId: Int, $status: DualStreamStatusEnum) {
    updateDualStreamStatus(meetingId: $meetingId, status: $status) {
      status
    }
  }
`;

export const STOP_BROADCAST_MUTATION = gql`
  mutation STOP_BROADCAST_MUTATION($meetingId: Int, $context: BroadcastContextEnum) {
    stopEventBroadcast(meetingId: $meetingId, context: $context) {
      status
    }
  }
`;

export const START_BROADCAST_MUTATION = gql`
  mutation START_BROADCAST_MUTATION($meetingId: Int) {
    startEventBroadcast(meetingId: $meetingId) {
      status
    }
  }
`;

export function useUpdateBroadcastStatusMutation(
  options?: MutationHookOptions<UpdateBroadcastStatusData, UpdateBroadcastStatusVars>
) {
  return useMutation<UpdateBroadcastStatusData, UpdateBroadcastStatusVars>(
    UPDATE_BROADCAST_STATUS_MUTATION,
    options
  );
}

export function useUpdateDualStreamStatusMutation(
  options?: MutationHookOptions<UpdateDualStreamStatusData, UpdateDualStreamStatusVars>
) {
  return useMutation<UpdateDualStreamStatusData, UpdateDualStreamStatusVars>(
    UPDATE_DUAL_STREAM_STATUS_MUTATION,
    options
  );
}

export function useStopBroadcastMutation(
  options?: MutationHookOptions<StopBroadcastData, StopBroadcastVars>
) {
  return useMutation<StopBroadcastData, StopBroadcastVars>(STOP_BROADCAST_MUTATION, options);
}

export function useStartBroadcastMutation(
  options?: MutationHookOptions<StartBroadcastData, StartBroadcastVars>
) {
  return useMutation<StartBroadcastData, StartBroadcastVars>(START_BROADCAST_MUTATION, options);
}

export interface BroadcastStatusSubVars {
  meetingId: number;
}

export interface BroadcastStatusSubData {
  onBroadcastStatusUpdated: Partial<Broadcast>;
}

export const BROADCAST_STATUS_SUBSCRIPTION = gql`
  subscription BROADCAST_STATUS_SUBSCRIPTION($meetingId: Int) {
    onBroadcastStatusUpdated(meetingId: $meetingId) {
      status
      context
      startTime
      broadcastUrl
      backupBroadcastUrl
    }
  }
`;

export function useBroadcastStatusSubscription(
  options?: SubscriptionHookOptions<BroadcastStatusSubData, BroadcastStatusSubVars>
) {
  return useSubscription<BroadcastStatusSubData, BroadcastStatusSubVars>(
    BROADCAST_STATUS_SUBSCRIPTION,
    options
  );
}

export interface DualStreamStatusUpdate {
  status: DualStreamStatusEnum;
}

export interface DualStreamStatusSubVars {
  meetingId: number;
}

export interface DualStreamStatusSubData {
  onDualStreamStatusUpdated: DualStreamStatusUpdate;
}

export const DUAL_STREAM_STATUS_SUBSCRIPTION = gql`
  subscription DUAL_STREAM_STATUS_SUBSCRIPTION($meetingId: Int) {
    onDualStreamStatusUpdated(meetingId: $meetingId) {
      status
    }
  }
`;
export function useDualStreamStatusSubscription(
  options?: SubscriptionHookOptions<DualStreamStatusSubData, DualStreamStatusSubVars>
) {
  return useSubscription<DualStreamStatusSubData, DualStreamStatusSubVars>(
    DUAL_STREAM_STATUS_SUBSCRIPTION,
    options
  );
}

export interface ResetBroadcastKeyVars {
  meetingId: number;
}

export interface ResetBroadcastKeyData {}

const RESET_BROADCAST_KEY = gql`
  mutation RESET_BROADCAST_KEY($meetingId: Int) {
    resetBroadcastKey(meetingId: $meetingId)
  }
`;

export function useResetBroadcastKeyMutation(
  options?: MutationHookOptions<ResetBroadcastKeyData, ResetBroadcastKeyVars>
) {
  return useMutation<ResetBroadcastKeyData, ResetBroadcastKeyVars>(RESET_BROADCAST_KEY, options);
}
