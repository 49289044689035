import { gql } from "@apollo/client";

export interface SignedUrlHookVariables {
  meetingId: number;
  fileName: string;
}

export interface SignedUrlResult {
  getEventSignedAssetUrl: string;
}

export const SIGNED_ASSET_QUERY = gql`
  query SIGNED_ASSET_QUERY($fileName: String, $meetingId: Int) {
    getEventSignedAssetUrl(fileName: $fileName, meetingId: $meetingId)
  }
`;
