export enum TimezoneName {
  PacificNiue = "Pacific/Niue",
  PacificPagoPago = "Pacific/Pago_Pago",
  PacificHonolulu = "Pacific/Honolulu",
  PacificRarotonga = "Pacific/Rarotonga",
  PacificTahiti = "Pacific/Tahiti",
  PacificMarquesas = "Pacific/Marquesas",
  AmericaAnchorage = "America/Anchorage",
  PacificGambier = "Pacific/Gambier",
  AmericaLosAngeles = "America/Los_Angeles",
  AmericaTijuana = "America/Tijuana",
  AmericaVancouver = "America/Vancouver",
  AmericaWhitehorse = "America/Whitehorse",
  PacificPitcairn = "Pacific/Pitcairn",
  AmericaDenver = "America/Denver",
  AmericaPhoenix = "America/Phoenix",
  AmericaMazatlan = "America/Mazatlan",
  AmericaDawsonCreek = "America/Dawson_Creek",
  AmericaEdmonton = "America/Edmonton",
  AmericaHermosillo = "America/Hermosillo",
  AmericaYellowknife = "America/Yellowknife",
  AmericaBelize = "America/Belize",
  AmericaChicago = "America/Chicago",
  AmericaMexicoCity = "America/Mexico_City",
  AmericaRegina = "America/Regina",
  AmericaTegucigalpa = "America/Tegucigalpa",
  AmericaWinnipeg = "America/Winnipeg",
  AmericaCostaRica = "America/Costa_Rica",
  AmericaElSalvador = "America/El_Salvador",
  PacificGalapagos = "Pacific/Galapagos",
  AmericaGuatemala = "America/Guatemala",
  AmericaManagua = "America/Managua",
  AmericaCancun = "America/Cancun",
  AmericaBogota = "America/Bogota",
  PacificEaster = "Pacific/Easter",
  AmericaNewYork = "America/New_York",
  AmericaIqaluit = "America/Iqaluit",
  AmericaToronto = "America/Toronto",
  AmericaGuayaquil = "America/Guayaquil",
  AmericaHavana = "America/Havana",
  AmericaJamaica = "America/Jamaica",
  AmericaLima = "America/Lima",
  AmericaNassau = "America/Nassau",
  AmericaPanama = "America/Panama",
  AmericaPortAuPrince = "America/Port-au-Prince",
  AmericaRioBranco = "America/Rio_Branco",
  AmericaHalifax = "America/Halifax",
  AmericaBarbados = "America/Barbados",
  AtlanticBermuda = "Atlantic/Bermuda",
  AmericaBoaVista = "America/Boa_Vista",
  AmericaCaracas = "America/Caracas",
  AmericaCuracao = "America/Curacao",
  AmericaGrandTurk = "America/Grand_Turk",
  AmericaGuyana = "America/Guyana",
  AmericaLaPaz = "America/La_Paz",
  AmericaManaus = "America/Manaus",
  AmericaMartinique = "America/Martinique",
  AmericaPortOfSpain = "America/Port_of_Spain",
  AmericaPortoVelho = "America/Porto_Velho",
  AmericaPuertoRico = "America/Puerto_Rico",
  AmericaSantoDomingo = "America/Santo_Domingo",
  AmericaThule = "America/Thule",
  AmericaStJohns = "America/St_Johns",
  AmericaAraguaina = "America/Araguaina",
  AmericaAsuncion = "America/Asuncion",
  AmericaBelem = "America/Belem",
  AmericaArgentinaBuenosAires = "America/Argentina/Buenos_Aires",
  AmericaCampoGrande = "America/Campo_Grande",
  AmericaCayenne = "America/Cayenne",
  AmericaCuiaba = "America/Cuiaba",
  AmericaFortaleza = "America/Fortaleza",
  AmericaGodthab = "America/Godthab",
  AmericaMaceio = "America/Maceio",
  AmericaMiquelon = "America/Miquelon",
  AmericaMontevideo = "America/Montevideo",
  AntarcticaPalmer = "Antarctica/Palmer",
  AmericaParamaribo = "America/Paramaribo",
  AmericaPuntaArenas = "America/Punta_Arenas",
  AmericaRecife = "America/Recife",
  AntarcticaRothera = "Antarctica/Rothera",
  AmericaBahia = "America/Bahia",
  AmericaSantiago = "America/Santiago",
  AtlanticStanley = "Atlantic/Stanley",
  AmericaNoronha = "America/Noronha",
  AmericaSaoPaulo = "America/Sao_Paulo",
  AtlanticSouthGeorgia = "Atlantic/South_Georgia",
  AtlanticAzores = "Atlantic/Azores",
  AtlanticCapeVerde = "Atlantic/Cape_Verde",
  AmericaScoresbysund = "America/Scoresbysund",
  AfricaAbidjan = "Africa/Abidjan",
  AfricaAccra = "Africa/Accra",
  AfricaBissau = "Africa/Bissau",
  AtlanticCanary = "Atlantic/Canary",
  AfricaCasablanca = "Africa/Casablanca",
  AmericaDanmarkshavn = "America/Danmarkshavn",
  EuropeDublin = "Europe/Dublin",
  AfricaElAaiun = "Africa/El_Aaiun",
  AtlanticFaroe = "Atlantic/Faroe",
  EtcUtc = "Etc/UTC",
  EuropeLisbon = "Europe/Lisbon",
  EuropeLondon = "Europe/London",
  AfricaMonrovia = "Africa/Monrovia",
  AtlanticReykjavik = "Atlantic/Reykjavik",
  AfricaAlgiers = "Africa/Algiers",
  EuropeAmsterdam = "Europe/Amsterdam",
  EuropeAndorra = "Europe/Andorra",
  EuropeBerlin = "Europe/Berlin",
  EuropeBrussels = "Europe/Brussels",
  EuropeBudapest = "Europe/Budapest",
  EuropeBelgrade = "Europe/Belgrade",
  EuropePrague = "Europe/Prague",
  AfricaCeuta = "Africa/Ceuta",
  EuropeCopenhagen = "Europe/Copenhagen",
  EuropeGibraltar = "Europe/Gibraltar",
  AfricaLagos = "Africa/Lagos",
  EuropeLuxembourg = "Europe/Luxembourg",
  EuropeMadrid = "Europe/Madrid",
  EuropeMalta = "Europe/Malta",
  EuropeMonaco = "Europe/Monaco",
  AfricaNdjamena = "Africa/Ndjamena",
  EuropeOslo = "Europe/Oslo",
  EuropeParis = "Europe/Paris",
  EuropeRome = "Europe/Rome",
  EuropeStockholm = "Europe/Stockholm",
  EuropeTirane = "Europe/Tirane",
  AfricaTunis = "Africa/Tunis",
  EuropeVienna = "Europe/Vienna",
  EuropeWarsaw = "Europe/Warsaw",
  EuropeZurich = "Europe/Zurich",
  AsiaAmman = "Asia/Amman",
  EuropeAthens = "Europe/Athens",
  AsiaBeirut = "Asia/Beirut",
  EuropeBucharest = "Europe/Bucharest",
  AfricaCairo = "Africa/Cairo",
  EuropeChisinau = "Europe/Chisinau",
  AsiaDamascus = "Asia/Damascus",
  AsiaGaza = "Asia/Gaza",
  EuropeHelsinki = "Europe/Helsinki",
  AsiaJerusalem = "Asia/Jerusalem",
  AfricaJohannesburg = "Africa/Johannesburg",
  AfricaKhartoum = "Africa/Khartoum",
  EuropeKiev = "Europe/Kiev",
  AfricaMaputo = "Africa/Maputo",
  EuropeKaliningrad = "Europe/Kaliningrad",
  AsiaNicosia = "Asia/Nicosia",
  EuropeRiga = "Europe/Riga",
  EuropeSofia = "Europe/Sofia",
  EuropeTallinn = "Europe/Tallinn",
  AfricaTripoli = "Africa/Tripoli",
  EuropeVilnius = "Europe/Vilnius",
  AfricaWindhoek = "Africa/Windhoek",
  AsiaBaghdad = "Asia/Baghdad",
  EuropeIstanbul = "Europe/Istanbul",
  EuropeMinsk = "Europe/Minsk",
  EuropeMoscow = "Europe/Moscow",
  AfricaNairobi = "Africa/Nairobi",
  AsiaQatar = "Asia/Qatar",
  AsiaRiyadh = "Asia/Riyadh",
  AntarcticaSyowa = "Antarctica/Syowa",
  AsiaTehran = "Asia/Tehran",
  AsiaBaku = "Asia/Baku",
  AsiaDubai = "Asia/Dubai",
  IndianMahe = "Indian/Mahe",
  IndianMauritius = "Indian/Mauritius",
  EuropeSamara = "Europe/Samara",
  IndianReunion = "Indian/Reunion",
  AsiaTbilisi = "Asia/Tbilisi",
  AsiaYerevan = "Asia/Yerevan",
  AsiaKabul = "Asia/Kabul",
  AsiaAqtau = "Asia/Aqtau",
  AsiaAqtobe = "Asia/Aqtobe",
  AsiaAshgabat = "Asia/Ashgabat",
  AsiaDushanbe = "Asia/Dushanbe",
  AsiaKarachi = "Asia/Karachi",
  IndianKerguelen = "Indian/Kerguelen",
  IndianMaldives = "Indian/Maldives",
  AntarcticaMawson = "Antarctica/Mawson",
  AsiaYekaterinburg = "Asia/Yekaterinburg",
  AsiaTashkent = "Asia/Tashkent",
  AsiaColombo = "Asia/Colombo",
  AsiaKolkata = "Asia/Kolkata",
  AsiaKathmandu = "Asia/Kathmandu",
  AsiaAlmaty = "Asia/Almaty",
  AsiaBishkek = "Asia/Bishkek",
  IndianChagos = "Indian/Chagos",
  AsiaDhaka = "Asia/Dhaka",
  AsiaOmsk = "Asia/Omsk",
  AsiaThimphu = "Asia/Thimphu",
  AntarcticaVostok = "Antarctica/Vostok",
  IndianCocos = "Indian/Cocos",
  AsiaYangon = "Asia/Yangon",
  AsiaBangkok = "Asia/Bangkok",
  IndianChristmas = "Indian/Christmas",
  AntarcticaDavis = "Antarctica/Davis",
  AsiaSaigon = "Asia/Saigon",
  AsiaHovd = "Asia/Hovd",
  AsiaJakarta = "Asia/Jakarta",
  AsiaKrasnoyarsk = "Asia/Krasnoyarsk",
  AsiaBrunei = "Asia/Brunei",
  AsiaShanghai = "Asia/Shanghai",
  AsiaChoibalsan = "Asia/Choibalsan",
  AsiaHongKong = "Asia/Hong_Kong",
  AsiaKualaLumpur = "Asia/Kuala_Lumpur",
  AsiaMacau = "Asia/Macau",
  AsiaMakassar = "Asia/Makassar",
  AsiaManila = "Asia/Manila",
  AsiaIrkutsk = "Asia/Irkutsk",
  AsiaSingapore = "Asia/Singapore",
  AsiaTaipei = "Asia/Taipei",
  AsiaUlaanbaatar = "Asia/Ulaanbaatar",
  AustraliaPerth = "Australia/Perth",
  AsiaPyongyang = "Asia/Pyongyang",
  AsiaDili = "Asia/Dili",
  AsiaJayapura = "Asia/Jayapura",
  AsiaYakutsk = "Asia/Yakutsk",
  PacificPalau = "Pacific/Palau",
  AsiaSeoul = "Asia/Seoul",
  AsiaTokyo = "Asia/Tokyo",
  AustraliaDarwin = "Australia/Darwin",
  AntarcticaDumontDUrville = "Antarctica/DumontDUrville",
  AustraliaBrisbane = "Australia/Brisbane",
  PacificGuam = "Pacific/Guam",
  AsiaVladivostok = "Asia/Vladivostok",
  PacificPortMoresby = "Pacific/Port_Moresby",
  PacificChuuk = "Pacific/Chuuk",
  AustraliaAdelaide = "Australia/Adelaide",
  AntarcticaCasey = "Antarctica/Casey",
  AustraliaHobart = "Australia/Hobart",
  AustraliaSydney = "Australia/Sydney",
  PacificEfate = "Pacific/Efate",
  PacificGuadalcanal = "Pacific/Guadalcanal",
  PacificKosrae = "Pacific/Kosrae",
  AsiaMagadan = "Asia/Magadan",
  PacificNorfolk = "Pacific/Norfolk",
  PacificNoumea = "Pacific/Noumea",
  PacificPohnpei = "Pacific/Pohnpei",
  PacificFunafuti = "Pacific/Funafuti",
  PacificKwajalein = "Pacific/Kwajalein",
  PacificMajuro = "Pacific/Majuro",
  AsiaKamchatka = "Asia/Kamchatka",
  PacificNauru = "Pacific/Nauru",
  PacificTarawa = "Pacific/Tarawa",
  PacificWake = "Pacific/Wake",
  PacificWallis = "Pacific/Wallis",
  PacificAuckland = "Pacific/Auckland",
  PacificEnderbury = "Pacific/Enderbury",
  PacificFakaofo = "Pacific/Fakaofo",
  PacificFiji = "Pacific/Fiji",
  PacificTongatapu = "Pacific/Tongatapu",
  PacificApia = "Pacific/Apia",
  PacificKiritimati = "Pacific/Kiritimati",
}

export const timezones: TimezoneItem[] = [
  { label: "Niue", value: TimezoneName.PacificNiue },
  { label: "Pago Pago", value: TimezoneName.PacificPagoPago },
  { label: "Hawaii", value: TimezoneName.PacificHonolulu },
  { label: "Rarotonga", value: TimezoneName.PacificRarotonga },
  { label: "Tahiti", value: TimezoneName.PacificTahiti },
  { label: "Marquesas", value: TimezoneName.PacificMarquesas },
  { label: "Alaska", value: TimezoneName.AmericaAnchorage },
  { label: "Gambier", value: TimezoneName.PacificGambier },
  { label: "Pacific Time (US and Canada)", value: TimezoneName.AmericaLosAngeles },
  { label: "Pacific Time - Tijuana", value: TimezoneName.AmericaTijuana },
  { label: "Pacific Time - Vancouver", value: TimezoneName.AmericaVancouver },
  { label: "Pacific Time - Whitehorse", value: TimezoneName.AmericaWhitehorse },
  { label: "Pitcairn", value: TimezoneName.PacificPitcairn },
  { label: "Mountain Time (US and Canada)", value: TimezoneName.AmericaDenver },
  { label: "Mountain Time - Arizona", value: TimezoneName.AmericaPhoenix },
  { label: "Mountain Time - Chihuahua, Mazatlan", value: TimezoneName.AmericaMazatlan },
  { label: "Mountain Time - Dawson Creek", value: TimezoneName.AmericaDawsonCreek },
  { label: "Mountain Time - Edmonton", value: TimezoneName.AmericaEdmonton },
  { label: "Mountain Time - Hermosillo", value: TimezoneName.AmericaHermosillo },
  { label: "Mountain Time - Yellowknife", value: TimezoneName.AmericaYellowknife },
  { label: "Belize", value: TimezoneName.AmericaBelize },
  { label: "Central Time (US and Canada)", value: TimezoneName.AmericaChicago },
  { label: "Central Time - Mexico City", value: TimezoneName.AmericaMexicoCity },
  { label: "Central Time - Regina", value: TimezoneName.AmericaRegina },
  { label: "Central Time - Tegucigalpa", value: TimezoneName.AmericaTegucigalpa },
  { label: "Central Time - Winnipeg", value: TimezoneName.AmericaWinnipeg },
  { label: "Costa Rica", value: TimezoneName.AmericaCostaRica },
  { label: "El Salvador", value: TimezoneName.AmericaElSalvador },
  { label: "Galapagos", value: TimezoneName.PacificGalapagos },
  { label: "Guatemala", value: TimezoneName.AmericaGuatemala },
  { label: "Managua", value: TimezoneName.AmericaManagua },
  { label: "America Cancun", value: TimezoneName.AmericaCancun },
  { label: "Bogota", value: TimezoneName.AmericaBogota },
  { label: "Easter Island", value: TimezoneName.PacificEaster },
  { label: "Eastern Time (US and Canada)", value: TimezoneName.AmericaNewYork },
  { label: "Eastern Time - Iqaluit", value: TimezoneName.AmericaIqaluit },
  { label: "Eastern Time - Toronto", value: TimezoneName.AmericaToronto },
  { label: "Guayaquil", value: TimezoneName.AmericaGuayaquil },
  { label: "Havana", value: TimezoneName.AmericaHavana },
  { label: "Jamaica", value: TimezoneName.AmericaJamaica },
  { label: "Lima", value: TimezoneName.AmericaLima },
  { label: "Nassau", value: TimezoneName.AmericaNassau },
  { label: "Panama", value: TimezoneName.AmericaPanama },
  { label: "Port-au-Prince", value: TimezoneName.AmericaPortAuPrince },
  { label: "Rio Branco", value: TimezoneName.AmericaRioBranco },
  { label: "Atlantic Time - Halifax", value: TimezoneName.AmericaHalifax },
  { label: "Barbados", value: TimezoneName.AmericaBarbados },
  { label: "Bermuda", value: TimezoneName.AtlanticBermuda },
  { label: "Boa Vista", value: TimezoneName.AmericaBoaVista },
  { label: "Caracas", value: TimezoneName.AmericaCaracas },
  { label: "Curacao", value: TimezoneName.AmericaCuracao },
  { label: "Grand Turk", value: TimezoneName.AmericaGrandTurk },
  { label: "Guyana", value: TimezoneName.AmericaGuyana },
  { label: "La Paz", value: TimezoneName.AmericaLaPaz },
  { label: "Manaus", value: TimezoneName.AmericaManaus },
  { label: "Martinique", value: TimezoneName.AmericaMartinique },
  { label: "Port of Spain", value: TimezoneName.AmericaPortOfSpain },
  { label: "Porto Velho", value: TimezoneName.AmericaPortoVelho },
  { label: "Puerto Rico", value: TimezoneName.AmericaPuertoRico },
  { label: "Santo Domingo", value: TimezoneName.AmericaSantoDomingo },
  { label: "Thule", value: TimezoneName.AmericaThule },
  { label: "Newfoundland Time - St. Johns", value: TimezoneName.AmericaStJohns },
  { label: "Araguaina", value: TimezoneName.AmericaAraguaina },
  { label: "Asuncion", value: TimezoneName.AmericaAsuncion },
  { label: "Belem", value: TimezoneName.AmericaBelem },
  { label: "Buenos Aires", value: TimezoneName.AmericaArgentinaBuenosAires },
  { label: "Campo Grande", value: TimezoneName.AmericaCampoGrande },
  { label: "Cayenne", value: TimezoneName.AmericaCayenne },
  { label: "Cuiaba", value: TimezoneName.AmericaCuiaba },
  { label: "Fortaleza", value: TimezoneName.AmericaFortaleza },
  { label: "Godthab", value: TimezoneName.AmericaGodthab },
  { label: "Maceio", value: TimezoneName.AmericaMaceio },
  { label: "Miquelon", value: TimezoneName.AmericaMiquelon },
  { label: "Montevideo", value: TimezoneName.AmericaMontevideo },
  { label: "Palmer", value: TimezoneName.AntarcticaPalmer },
  { label: "Paramaribo", value: TimezoneName.AmericaParamaribo },
  { label: "Punta Arenas", value: TimezoneName.AmericaPuntaArenas },
  { label: "Recife", value: TimezoneName.AmericaRecife },
  { label: "Rothera", value: TimezoneName.AntarcticaRothera },
  { label: "Salvador", value: TimezoneName.AmericaBahia },
  { label: "Santiago", value: TimezoneName.AmericaSantiago },
  { label: "Stanley", value: TimezoneName.AtlanticStanley },
  { label: "Noronha", value: TimezoneName.AmericaNoronha },
  { label: "Sao Paulo", value: TimezoneName.AmericaSaoPaulo },
  { label: "South Georgia", value: TimezoneName.AtlanticSouthGeorgia },
  { label: "Azores", value: TimezoneName.AtlanticAzores },
  { label: "Cape Verde", value: TimezoneName.AtlanticCapeVerde },
  { label: "Scoresbysund", value: TimezoneName.AmericaScoresbysund },
  { label: "Abidjan", value: TimezoneName.AfricaAbidjan },
  { label: "Accra", value: TimezoneName.AfricaAccra },
  { label: "Bissau", value: TimezoneName.AfricaBissau },
  { label: "Canary Islands", value: TimezoneName.AtlanticCanary },
  { label: "Casablanca", value: TimezoneName.AfricaCasablanca },
  { label: "Danmarkshavn", value: TimezoneName.AmericaDanmarkshavn },
  { label: "Dublin", value: TimezoneName.EuropeDublin },
  { label: "El Aaiun", value: TimezoneName.AfricaElAaiun },
  { label: "Faeroe", value: TimezoneName.AtlanticFaroe },
  { label: "UTC", value: TimezoneName.EtcUtc },
  { label: "Lisbon", value: TimezoneName.EuropeLisbon },
  { label: "London", value: TimezoneName.EuropeLondon },
  { label: "Monrovia", value: TimezoneName.AfricaMonrovia },
  { label: "Reykjavik", value: TimezoneName.AtlanticReykjavik },
  { label: "Algiers", value: TimezoneName.AfricaAlgiers },
  { label: "Amsterdam", value: TimezoneName.EuropeAmsterdam },
  { label: "Andorra", value: TimezoneName.EuropeAndorra },
  { label: "Berlin", value: TimezoneName.EuropeBerlin },
  { label: "Brussels", value: TimezoneName.EuropeBrussels },
  { label: "Budapest", value: TimezoneName.EuropeBudapest },
  { label: "Central European Time - Belgrade", value: TimezoneName.EuropeBelgrade },
  { label: "Central European Time - Prague", value: TimezoneName.EuropePrague },
  { label: "Ceuta", value: TimezoneName.AfricaCeuta },
  { label: "Copenhagen", value: TimezoneName.EuropeCopenhagen },
  { label: "Gibraltar", value: TimezoneName.EuropeGibraltar },
  { label: "Lagos", value: TimezoneName.AfricaLagos },
  { label: "Luxembourg", value: TimezoneName.EuropeLuxembourg },
  { label: "Madrid", value: TimezoneName.EuropeMadrid },
  { label: "Malta", value: TimezoneName.EuropeMalta },
  { label: "Monaco", value: TimezoneName.EuropeMonaco },
  { label: "Ndjamena", value: TimezoneName.AfricaNdjamena },
  { label: "Oslo", value: TimezoneName.EuropeOslo },
  { label: "Paris", value: TimezoneName.EuropeParis },
  { label: "Rome", value: TimezoneName.EuropeRome },
  { label: "Stockholm", value: TimezoneName.EuropeStockholm },
  { label: "Tirane", value: TimezoneName.EuropeTirane },
  { label: "Tunis", value: TimezoneName.AfricaTunis },
  { label: "Vienna", value: TimezoneName.EuropeVienna },
  { label: "Warsaw", value: TimezoneName.EuropeWarsaw },
  { label: "Zurich", value: TimezoneName.EuropeZurich },
  { label: "Amman", value: TimezoneName.AsiaAmman },
  { label: "Athens", value: TimezoneName.EuropeAthens },
  { label: "Beirut", value: TimezoneName.AsiaBeirut },
  { label: "Bucharest", value: TimezoneName.EuropeBucharest },
  { label: "Cairo", value: TimezoneName.AfricaCairo },
  { label: "Chisinau", value: TimezoneName.EuropeChisinau },
  { label: "Damascus", value: TimezoneName.AsiaDamascus },
  { label: "Gaza", value: TimezoneName.AsiaGaza },
  { label: "Helsinki", value: TimezoneName.EuropeHelsinki },
  { label: "Jerusalem", value: TimezoneName.AsiaJerusalem },
  { label: "Johannesburg", value: TimezoneName.AfricaJohannesburg },
  { label: "Khartoum", value: TimezoneName.AfricaKhartoum },
  { label: "Kyiv", value: TimezoneName.EuropeKiev },
  { label: "Maputo", value: TimezoneName.AfricaMaputo },
  { label: "Kaliningrad", value: TimezoneName.EuropeKaliningrad },
  { label: "Nicosia", value: TimezoneName.AsiaNicosia },
  { label: "Riga", value: TimezoneName.EuropeRiga },
  { label: "Sofia", value: TimezoneName.EuropeSofia },
  { label: "Tallinn", value: TimezoneName.EuropeTallinn },
  { label: "Tripoli", value: TimezoneName.AfricaTripoli },
  { label: "Vilnius", value: TimezoneName.EuropeVilnius },
  { label: "Windhoek", value: TimezoneName.AfricaWindhoek },
  { label: "Baghdad", value: TimezoneName.AsiaBaghdad },
  { label: "Istanbul", value: TimezoneName.EuropeIstanbul },
  { label: "Minsk", value: TimezoneName.EuropeMinsk },
  { label: "Moscow", value: TimezoneName.EuropeMoscow },
  { label: "Nairobi", value: TimezoneName.AfricaNairobi },
  { label: "Qatar", value: TimezoneName.AsiaQatar },
  { label: "Riyadh", value: TimezoneName.AsiaRiyadh },
  { label: "Syowa", value: TimezoneName.AntarcticaSyowa },
  { label: "Tehran", value: TimezoneName.AsiaTehran },
  { label: "Baku", value: TimezoneName.AsiaBaku },
  { label: "Dubai", value: TimezoneName.AsiaDubai },
  { label: "Mahe", value: TimezoneName.IndianMahe },
  { label: "Mauritius", value: TimezoneName.IndianMauritius },
  { label: "Samara", value: TimezoneName.EuropeSamara },
  { label: "Reunion", value: TimezoneName.IndianReunion },
  { label: "Tbilisi", value: TimezoneName.AsiaTbilisi },
  { label: "Yerevan", value: TimezoneName.AsiaYerevan },
  { label: "Kabul", value: TimezoneName.AsiaKabul },
  { label: "Aqtau", value: TimezoneName.AsiaAqtau },
  { label: "Aqtobe", value: TimezoneName.AsiaAqtobe },
  { label: "Ashgabat", value: TimezoneName.AsiaAshgabat },
  { label: "Dushanbe", value: TimezoneName.AsiaDushanbe },
  { label: "Karachi", value: TimezoneName.AsiaKarachi },
  { label: "Kerguelen", value: TimezoneName.IndianKerguelen },
  { label: "Maldives", value: TimezoneName.IndianMaldives },
  { label: "Mawson", value: TimezoneName.AntarcticaMawson },
  { label: "Yekaterinburg", value: TimezoneName.AsiaYekaterinburg },
  { label: "Tashkent", value: TimezoneName.AsiaTashkent },
  { label: "Colombo", value: TimezoneName.AsiaColombo },
  { label: "India Standard Time", value: TimezoneName.AsiaKolkata },
  { label: "Kathmandu", value: TimezoneName.AsiaKathmandu },
  { label: "Almaty", value: TimezoneName.AsiaAlmaty },
  { label: "Bishkek", value: TimezoneName.AsiaBishkek },
  { label: "Chagos", value: TimezoneName.IndianChagos },
  { label: "Dhaka", value: TimezoneName.AsiaDhaka },
  { label: "Omsk", value: TimezoneName.AsiaOmsk },
  { label: "Thimphu", value: TimezoneName.AsiaThimphu },
  { label: "Vostok", value: TimezoneName.AntarcticaVostok },
  { label: "Cocos", value: TimezoneName.IndianCocos },
  { label: "Rangoon", value: TimezoneName.AsiaYangon },
  { label: "Bangkok", value: TimezoneName.AsiaBangkok },
  { label: "Christmas", value: TimezoneName.IndianChristmas },
  { label: "Davis", value: TimezoneName.AntarcticaDavis },
  { label: "Hanoi", value: TimezoneName.AsiaSaigon },
  { label: "Hovd", value: TimezoneName.AsiaHovd },
  { label: "Jakarta", value: TimezoneName.AsiaJakarta },
  { label: "Krasnoyarsk", value: TimezoneName.AsiaKrasnoyarsk },
  { label: "Brunei", value: TimezoneName.AsiaBrunei },
  { label: "China Time - Beijing", value: TimezoneName.AsiaShanghai },
  { label: "Choibalsan", value: TimezoneName.AsiaChoibalsan },
  { label: "Hong Kong", value: TimezoneName.AsiaHongKong },
  { label: "Kuala Lumpur", value: TimezoneName.AsiaKualaLumpur },
  { label: "Macau", value: TimezoneName.AsiaMacau },
  { label: "Makassar", value: TimezoneName.AsiaMakassar },
  { label: "Manila", value: TimezoneName.AsiaManila },
  { label: "Irkutsk", value: TimezoneName.AsiaIrkutsk },
  { label: "Singapore", value: TimezoneName.AsiaSingapore },
  { label: "Taipei", value: TimezoneName.AsiaTaipei },
  { label: "Ulaanbaatar", value: TimezoneName.AsiaUlaanbaatar },
  { label: "Western Time - Perth", value: TimezoneName.AustraliaPerth },
  { label: "Pyongyang", value: TimezoneName.AsiaPyongyang },
  { label: "Dili", value: TimezoneName.AsiaDili },
  { label: "Jayapura", value: TimezoneName.AsiaJayapura },
  { label: "Yakutsk", value: TimezoneName.AsiaYakutsk },
  { label: "Palau", value: TimezoneName.PacificPalau },
  { label: "Seoul", value: TimezoneName.AsiaSeoul },
  { label: "Tokyo", value: TimezoneName.AsiaTokyo },
  { label: "Central Time - Darwin", value: TimezoneName.AustraliaDarwin },
  { label: "Dumont D'Urville", value: TimezoneName.AntarcticaDumontDUrville },
  { label: "Eastern Time - Brisbane", value: TimezoneName.AustraliaBrisbane },
  { label: "Guam", value: TimezoneName.PacificGuam },
  { label: "Vladivostok", value: TimezoneName.AsiaVladivostok },
  { label: "Port Moresby", value: TimezoneName.PacificPortMoresby },
  { label: "Truk", value: TimezoneName.PacificChuuk },
  { label: "Central Time - Adelaide", value: TimezoneName.AustraliaAdelaide },
  { label: "Casey", value: TimezoneName.AntarcticaCasey },
  { label: "Eastern Time - Hobart", value: TimezoneName.AustraliaHobart },
  { label: "Eastern Time - Melbourne, Sydney", value: TimezoneName.AustraliaSydney },
  { label: "Efate", value: TimezoneName.PacificEfate },
  { label: "Guadalcanal", value: TimezoneName.PacificGuadalcanal },
  { label: "Kosrae", value: TimezoneName.PacificKosrae },
  { label: "Magadan", value: TimezoneName.AsiaMagadan },
  { label: "Norfolk", value: TimezoneName.PacificNorfolk },
  { label: "Noumea", value: TimezoneName.PacificNoumea },
  { label: "Ponape", value: TimezoneName.PacificPohnpei },
  { label: "Funafuti", value: TimezoneName.PacificFunafuti },
  { label: "Kwajalein", value: TimezoneName.PacificKwajalein },
  { label: "Majuro", value: TimezoneName.PacificMajuro },
  { label: "Petropavlovsk-Kamchatskiy", value: TimezoneName.AsiaKamchatka },
  { label: "Nauru", value: TimezoneName.PacificNauru },
  { label: "Tarawa", value: TimezoneName.PacificTarawa },
  { label: "Wake", value: TimezoneName.PacificWake },
  { label: "Wallis", value: TimezoneName.PacificWallis },
  { label: "Auckland", value: TimezoneName.PacificAuckland },
  { label: "Enderbury", value: TimezoneName.PacificEnderbury },
  { label: "Fakaofo", value: TimezoneName.PacificFakaofo },
  { label: "Fiji", value: TimezoneName.PacificFiji },
  { label: "Tongatapu", value: TimezoneName.PacificTongatapu },
  { label: "Apia", value: TimezoneName.PacificApia },
  { label: "Kiritimati", value: TimezoneName.PacificKiritimati },
];

export interface TimezoneItem {
  label: string;
  value: TimezoneName;
}

export const priorityTimezones = [
  TimezoneName.AmericaNewYork,
  TimezoneName.AmericaChicago,
  TimezoneName.AmericaDenver,
  TimezoneName.AmericaLosAngeles,
  TimezoneName.AmericaAnchorage,
  TimezoneName.PacificHonolulu,
];

export type TimezoneOptionsModel = TimezoneItem[];
