import { useCallback, useRef, useState } from "react";
import { AsyncState, AsyncFnModel } from "./asyncFn.definition";

export const unwrapAsyncState = <T, E = Error>(state: AsyncState<T, E>): T => {
  if (state.error) throw state.error;
  return state.data;
};

export const useAsyncFn = <T, E = Error>(
  fn: (...args: unknown[]) => Promise<T>,
  initialState: AsyncState<T, E> = { loading: false }
): AsyncFnModel<T, E> => {
  const [state, setState] = useState<AsyncState<T, E>>(initialState);
  const fnRef = useRef(fn);

  fnRef.current = fn;

  type Args = Parameters<typeof fn>;
  const fetch = useCallback((...args: Args): Promise<AsyncState<T, E>> => {
    setState((prevState) => ({ ...prevState, loading: true }));

    return fnRef
      .current(...args)
      .then((data) => {
        setState({ loading: false, data });
        return { loading: false, data } as AsyncState<T, E>;
      })
      .catch((error: E) => {
        setState({ loading: false, error });
        return { loading: false, error } as AsyncState<T, E>;
      });
  }, []);

  const reset = useCallback(() => setState(initialState), [initialState]);

  return [state, fetch, reset];
};
