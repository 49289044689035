import { useMemo } from "react";
import { getDisplayName } from "../../../utils/broadcastPresenters.utils";

export const useStreamDisplayName = ({ stream, participant }) => {
  const streamDisplayName = useMemo(() => {
    if (stream?.isAssetStream) {
      return stream.displayName;
    }
    return getDisplayName(stream?.type, participant);
  }, [stream, participant]);

  const phoneNumberVisible = useMemo(
    () => streamDisplayName === participant?.phoneNumber,
    [streamDisplayName, participant?.phoneNumber]
  );

  return { streamDisplayName, phoneNumberVisible };
};
