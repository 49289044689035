import React, { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { CarouselStreamSelectionModel } from "../../views/adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import useCarouselStreamSelection from "../../views/adminConsole/hooks/carouselSelection/carouselStreamSelection.hook";
import {
  useDominantSpeaker,
  DominantSpeakerModel,
} from "../../hooks/dominantSpeaker/dominantSpeaker.hook";
import useGroupsHook from "../../hooks/groups/groups.hook";
import useTrackOTStreamsHook from "../../views/adminConsole/vendors/openTok/hooks/trackOpentokStreams/trackOTStreams.hook";
import { formatGroups } from "../../hooks/groups/groups.hook.utils";

import { TrackStreamsModel } from "../../views/adminConsole/interfaces/trackBroadcastStreams/trackBroadcastStreams.definition";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";
import EventContext from "../event/event.context";
import { useUserDebugger } from "../../views/adminConsole/hooks/devDebugger/devDebugger.hook";
import { GroupsHookModel } from "../../hooks/groups/groups.hook.definition";
import useTimeDelta from "../../hooks/timeDelta/timeDelta.hook";
import { StagedSelectionHook } from "../../views/adminConsole/hooks/stagedSelection/stagedSelection.definition";
import useStagedSelection from "../../views/adminConsole/hooks/stagedSelection/stagedSelection.hook";

export interface AdminConsoleContextProps {}
export interface AdminConsoleContextState
  extends CarouselStreamSelectionModel,
    DominantSpeakerModel {
  useGroups: GroupsHookModel;
  streams: Array<BroadcastStream>;
  trackStreamsService: TrackStreamsModel;
  stagedSelectionHook: StagedSelectionHook;
  systemDelta: number;
}

const AdminConsoleContext = createContext<Partial<AdminConsoleContextState>>({});

export const AdminConsoleProvider: FunctionComponent<AdminConsoleContextProps> = (
  props
): JSX.Element => {
  const { currentEvent: eventDetails } = useContext(EventContext);

  const stagedSelectionHook = useStagedSelection({
    meetingId: eventDetails?.meetingId,
    isOTPR: eventDetails?.configuration?.layoutManager?.enabled,
  });

  const { carouselSelection, carouselSelectionService, carouselSelectionHelpers } =
    useCarouselStreamSelection({
      mediaStreamSelectionLimit: 1,
      cameraStreamSelectionLimit: 5,
      mediaOnly: stagedSelectionHook?.stagedSelectionState?.mediaOnlyState ?? false,
    });
  const [systemDelta, setSystemDelta] = useState<number>(0);

  useUserDebugger();

  const { dominantSpeakerStream, dominantSpeakerName, dominantSpeakerService } =
    useDominantSpeaker();

  const { streams, trackStreamsService } = useTrackOTStreamsHook({});
  const { getTimeDelta } = useTimeDelta();

  const groupHookProps = {
    meetingId: eventDetails?.meetingId,
    streams,
    initialGroups: formatGroups(eventDetails?.groups) || [],
  };

  const useGroups = useGroupsHook(groupHookProps);

  useEffect(() => {
    const timeDelta = async () => {
      const response = await getTimeDelta(new Date());
      setSystemDelta(response.delta);
    };
    timeDelta();
  }, [getTimeDelta]);

  return (
    <AdminConsoleContext.Provider
      value={{
        carouselSelection,
        carouselSelectionService,
        carouselSelectionHelpers,
        dominantSpeakerStream,
        dominantSpeakerName,
        dominantSpeakerService,
        streams,
        trackStreamsService,
        stagedSelectionHook,
        useGroups,
        systemDelta,
      }}
    >
      {props?.children}
    </AdminConsoleContext.Provider>
  );
};

export default AdminConsoleContext;
