import { Participant } from "../../../hooks/participants/participants.hook.definition";
import { GetSipStreamVars } from "../../../hooks/streaming/sipStreaming.definition";
import { SelectionType } from "../interfaces/broadcastStream/broadcastStream";

export function redirectParticipantToLiveBroadcast(
  eventMeetingId,
  history,
  notificationService
): void {
  history.push(`/attendee/${eventMeetingId}`);
  return notificationService.current.error(
    "You don't have permissions to join this event. You've been redirected to view this event as an attendee"
  );
}

export function getDisplayName(
  streamType: SelectionType | string,
  participant: Partial<Participant>
): string {
  const name = participant?.customName || participant?.name || participant?.phoneNumber;
  if (name) {
    if (streamType === SelectionType.SCREEN) {
      return getScreenShareDisplayName(name);
    }
    return name;
  }

  return "";
}

/**
 * Helper function for a few confirmation modals
 * @param name
 */
export function getScreenShareDisplayName(name: string) {
  return `Screen Share - ${name}`;
}

/**
 * Function for getting the stream name for a PSTN line
 * @param eventService
 * @param meetingId
 * @param connectionId
 */
export async function getSipStreamName(
  getSipStream: (variables: GetSipStreamVars) => Promise<{ speaker_number: string }>,
  meetingId: number,
  connectionId: string
): Promise<string> {
  const pstnStream = await getSipStream({ meetingId, connectionId });
  const number = pstnStream?.speaker_number;
  return number === "client:Anonymous" ? "Anonymous" : number;
}
