import { SelectionModel } from "../../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";
import { StageSize } from "../../layoutBuilder.definition";

export enum MediaLayoutSpeakerSize {
  XS = "xs",
  S = "sm",
  M = "md",
  L = "lg",
}
export interface MediaLayoutProps {
  id?: string;
  mediaSelection: BroadcastStream;
  cameraSelection: Array<BroadcastStream>;
  isPreview?: boolean;
  participantsByConnectionId?: object;
  renderOnChange: string;
  speakerSize?: MediaLayoutSpeakerSize;
  stageSize?: StageSize;
  stageSelection?: (selection: SelectionModel) => void;
  showSpeakerThumbnails?: boolean;
}

export enum MediaLayoutClassNames {
  LAYOUT = "mediaLayout",
  WRAPPER = "mediaLayout_wrapper",
  WRAPPER_INNER = "mediaLayout_wrapper_inner",
  MEDIA_CONTENT = "mediaLayout_wrapper_inner_media",
  SPEAKER_CONTENT = "mediaLayout_wrapper_inner_speakers",
  SPEAKER_CONTENT_AUDIO = "mediaLayout_wrapper_inner_speakers--audio",
  CONTENT = "mediaLayout_content",
  MEDIA_SELECTION = "mediaLayout_mediaSelection",
  CAMERA_SELECTION = "mediaLayout_cameraSelection",
  CAMERA_SELECTION_CONTAINER = "mediaLayout_cameraSelectionContainer",
  CAMERA_SELECTION_VIDEO_CONTAINER = "mediaLayout_cameraSelectionContainer-videoContainer",
  VIDEO_ELEMENT = "mediaLayout_videoElement",
}
