import React, { MutableRefObject, useContext, useMemo } from "react";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { EventContext } from "../../contexts";
import ConferenceContext from "../../contexts/conference/conference.context";
import { FeatureFlagsContext } from "../../contexts/featureFlags";
import {
  LayoutType,
  SelectionModel,
  SelectionModelV2,
} from "../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../adminConsole/interfaces/broadcastStream/broadcastStream";
import { StreamFilterKeys } from "../adminConsole/interfaces/trackBroadcastStreams/trackBroadcastStreams.definition";
import { filterStreams } from "../adminConsole/vendors/openTok/utils/trackOTStreams.utils";
import { StageSize } from "./layoutBuilder.definition";
import { PlaceholderLayout, SpeakerLayout } from "./layouts";
import { MediaLayout } from "./layouts/MediaLayout/MediaLayout";
import { MediaLayoutSpeakerSize } from "./layouts/MediaLayout/MediaLayout.definition";
import { getLayoutToBuild, getStagedStreamIds } from "./utils/layout.utils";

export interface LayoutBuilderProps {
  id?: string;
  currentSelection: SelectionModel;
  currentSelectionV2?: SelectionModelV2;
  isPreview: boolean;
  stageSize?: StageSize;
  participantsByConnectionId?: object;
  parentRef?: MutableRefObject<HTMLDivElement>;
  stageSelection?: (selection: SelectionModel) => void;
  showSpeakerThumbnails?: boolean;
}

export enum Layouts {
  MEDIA_LAYOUT = "MediaLayout",
  SPEAKER_LAYOUT = "SpeakerLayout",
  PLACEHOLDER_LAYOUT = "PlaceholderLayout",
}

export default function LayoutBuilderView(props: LayoutBuilderProps): JSX.Element {
  const {
    id,
    currentSelection,
    currentSelectionV2,
    isPreview,
    stageSize = StageSize.normal,
    participantsByConnectionId,
    stageSelection,
    showSpeakerThumbnails: showSpeakersFlag,
  } = props;

  const { eventConfiguration } = useContext(EventContext);
  const { streams } = useContext(ConferenceContext);
  const { featureFlags } = useContext(FeatureFlagsContext);
  const audioOnly = !eventConfiguration?.video?.enabled;

  const { mediaSelection, cameraSelection } = currentSelection;
  const { selection, layoutConfig } = currentSelectionV2 ?? {};

  const showSpeakerThumbnailsOverride = useMemo(() => {
    return audioOnly ? false : showSpeakersFlag;
  }, [audioOnly, showSpeakersFlag]);

  const renderOnChange = getStagedStreamIds([...mediaSelection, ...cameraSelection]);

  const layout = useMemo(() => {
    const layoutToBuild = getLayoutToBuild(mediaSelection, cameraSelection, isPreview);
    switch (layoutToBuild) {
      case Layouts.MEDIA_LAYOUT:
        return (
          <MediaLayout
            mediaSelection={mediaSelection[0]}
            cameraSelection={cameraSelection}
            stageSize={stageSize}
            participantsByConnectionId={participantsByConnectionId}
            renderOnChange={renderOnChange}
            stageSelection={stageSelection}
            isPreview={isPreview}
            speakerSize={isPreview && MediaLayoutSpeakerSize.XS}
            showSpeakerThumbnails={showSpeakerThumbnailsOverride}
          />
        );
      case Layouts.SPEAKER_LAYOUT:
        return (
          <SpeakerLayout
            cameraSelection={cameraSelection}
            participantsByConnectionId={participantsByConnectionId}
            renderOnChange={renderOnChange}
            stageSelection={stageSelection}
            isPreview={isPreview}
          />
        );
      case Layouts.PLACEHOLDER_LAYOUT:
        return <PlaceholderLayout />;
      default:
        return null;
    }
  }, [
    cameraSelection,
    isPreview,
    mediaSelection,
    participantsByConnectionId,
    renderOnChange,
    showSpeakerThumbnailsOverride,
    stageSelection,
    stageSize,
  ]);

  // move into layout above after testing
  const layoutV2 = useMemo(() => {
    if (!selection?.length) {
      return isPreview ? null : <PlaceholderLayout id={id} />;
    }

    const streamIds = selection.map((stream) => stream.id);
    const streamIdFilter = (stream: BroadcastStream) => streamIds.includes(stream.id);

    // camera - get all streams where type is camera, pstn or audio asset
    const speakerStreams = filterStreams(streams, StreamFilterKeys.Speakers);
    const filteredSpeakers = filterStreams(speakerStreams, streamIdFilter);

    // media - get all streams where type is video, screen or slides
    const mediaStreams = filterStreams(streams, StreamFilterKeys.Media);
    const filteredMedia = filterStreams(mediaStreams, streamIdFilter);

    // preview needs the ability to override the layout while media-only is the current layout selection
    const showSpeakers =
      showSpeakerThumbnailsOverride ?? layoutConfig.type !== LayoutType.MediaOnly;

    switch (layoutConfig?.type) {
      case LayoutType.Speaker:
        return (
          <SpeakerLayout
            id={id}
            cameraSelection={filteredSpeakers}
            participantsByConnectionId={participantsByConnectionId}
            renderOnChange={renderOnChange}
            stageSelection={stageSelection}
            isPreview={isPreview}
          />
        );
      case LayoutType.Media:
      case LayoutType.MediaOnly:
        return (
          <MediaLayout
            id={id}
            mediaSelection={filteredMedia[0]}
            cameraSelection={filteredSpeakers}
            stageSize={stageSize}
            participantsByConnectionId={participantsByConnectionId}
            renderOnChange={renderOnChange}
            stageSelection={stageSelection}
            isPreview={isPreview}
            speakerSize={isPreview && MediaLayoutSpeakerSize.XS}
            showSpeakerThumbnails={showSpeakers}
          />
        );
      default:
        return null;
    }
  }, [
    id,
    streams,
    selection,
    layoutConfig,
    isPreview,
    participantsByConnectionId,
    showSpeakerThumbnailsOverride,
    renderOnChange,
    stageSelection,
    stageSize,
  ]);

  return (
    <>
      {featureFlags[FeatureFlagKeys.LayoutManagerUi]?.enabled &&
      eventConfiguration?.layoutManager?.enabled
        ? layoutV2
        : layout}
    </>
  );
}
