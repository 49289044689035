import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useRef,
} from "react";
import { isEmpty } from "lodash";
import { useRouteMatch } from "react-router-dom";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";

import config from "../../config";
import {
  BaseEventRegistration,
  FormEditState,
} from "../../views/broadcastParticipant/eventRegistration/eventRegistration.definition";
import { FormattedInstitution } from "../../hooks/institutions/institutions.hook.definition";
import { AttendeeEventRoles } from "../../configurations/userRolesPermissions.configuration";
import { PublicUserFlow, PublicUserInfo } from "../../services/organization/organization.model";
import { AttendeeOriginType } from "../../services/attendees/attendees.model";
import { ParticipantStorageKeyPrefix } from "../../views/broadcastParticipant/broadcastParticipant.definition";
import { AttendeesContext } from "../attendees";
import {
  LoginULUserIntoEPParams,
  NotificationErrorMessage,
  Q4_ATTENDEE_KEY_PREFIX,
  Q4_LOGIN_EVENTS_REGISTER,
  Q4LoginContextState,
  SSOLoginParams,
} from "./q4Login.definition";
import { InvestorTypeNames } from "../../views/broadcastParticipant/eventRegistration/components/eventRegistrationForm.definition";
import { doNothing } from "../../utils";
import { PublicEventDetails } from "../../services/event/eventGql.model";
import {
  LoggerDomains,
  createLogger,
  removeLogsContextProperty,
  setLogsContextProperty,
} from "../../integrations/logger";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";
import { FeatureFlagsContext } from "../featureFlags";
import { useGetPublicUserInfo } from "../../services/attendeeApi/attendee";

const logger = createLogger({ loggerName: LoggerDomains.Q4Login });

const Q4LoginContext = createContext<Partial<Q4LoginContextState>>({});

export const Q4LoginProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [auth0Client, setAuth0Client] = useState<Auth0Client>(null);
  const [cachedRegistration, setCachedRegistration] = useState<FormEditState | null>(null);
  const [isEventRegistered, setIsEventRegistered] = useState<boolean>(false);
  const [isCheckingAuthentication, setIsCheckingAuthentication] = useState(true);
  const [isValidatingUser, setIsValidatingUser] = useState(null);
  const [failedToValidateUser, setFailedToValidateUser] = useState(false);
  const [isHandlingFailure, setIsHandlingFailure] = useState(false);
  const [notificationErrorMessage, setNotificationErrorMessage] =
    useState<NotificationErrorMessage | null>(null);

  const [user, setUser] = useState<PublicUserInfo | null>(null);
  const idToken = useRef(null);

  const { eventDetails, showPostEventRegistration } = useContext(AttendeesContext);
  const { path } = useRouteMatch();
  const { featureFlags } = useContext(FeatureFlagsContext);

  const { data: publicUserInfo, refetch: fetchUserData } = useGetPublicUserInfo({
    idToken: idToken.current,
    skip: true,
  });

  const isGuestRoute = useMemo(
    () =>
      path === "/attendee/:id/guest" ||
      path ===
        "/:eventType(earnings|vsm)/:companyIdentifier/:customIdentifier/guest/:hasPreRegistered?",
    [path]
  );

  const isPregisterRoute = useMemo(() => path === "/attendee/:id/:hasPreRegistered?", [path]);

  const hasLogoutFlag = useMemo(() => {
    const filterParams = new URLSearchParams(window.location.search);
    return filterParams.get("aulLogout") === "1";
  }, []);

  useEffect(
    function setLoggingContext() {
      eventDetails?.meetingId && setLogsContextProperty("meetingId", eventDetails?.meetingId);
      eventDetails?.companyId && setLogsContextProperty("companyId", eventDetails?.companyId);

      return () => {
        removeLogsContextProperty("meetingId");
        removeLogsContextProperty("companyId");
      };
    },
    [eventDetails?.meetingId, eventDetails?.companyId]
  );

  const getRedirectUrlFromEvent = useCallback(
    (eventDetails: PublicEventDetails) => {
      const { meetingId, eventType, companyIdentifier, customIdentifier } = eventDetails ?? {};
      return !customIdentifier || isPregisterRoute
        ? `/attendee/${meetingId}`
        : `/${eventType.toLowerCase()}/${companyIdentifier?.toUpperCase()}/${
            customIdentifier?.toLowerCase() ?? ""
          }`;
    },
    [isPregisterRoute]
  );

  const logout = useCallback(
    function logout(redirectUrl?: string) {
      const { meetingId } = eventDetails ?? {};
      const eventUrl = redirectUrl ? redirectUrl : getRedirectUrlFromEvent(eventDetails);

      /** Clean up any possible session from EP */
      const storageEngines = [sessionStorage, localStorage];
      storageEngines.forEach((storage) =>
        storage.removeItem(`${ParticipantStorageKeyPrefix}${meetingId}`)
      );

      auth0Client?.logout({
        returnTo: `${window.location.origin}/attendee/logout?eventUrl=${eventUrl}${
          notificationErrorMessage
            ? `&errorMessage=${encodeURIComponent(notificationErrorMessage)}`
            : ""
        }`,
      });
    },
    [eventDetails, getRedirectUrlFromEvent, auth0Client, notificationErrorMessage]
  );

  const handleFailure = useCallback(
    async function handleFailure() {
      setIsHandlingFailure(true);
      logout();
    },
    [logout]
  );

  const checkForAuthentication = useCallback(async () => {
    let accessToken;
    try {
      accessToken = await auth0Client.getTokenSilently({ detailedResponse: true });
    } catch (error) {
      // it mainly fails when the user isn't authenticated
      // do nothing here when it fails to don't pollute the console
      // and bother the user with weird messages when he/she lands on the event registration page
      doNothing();
    }

    if (!accessToken) return setIsCheckingAuthentication(false);

    // it always returns `expires_in` = 86400 (1 day)
    // added a check here just to be in safe
    if (accessToken?.expires_in < 1800) {
      return logout();
    } else if (accessToken?.id_token) {
      if (user) return;
      idToken.current = accessToken.id_token;

      await fetchUserData();
    }
  }, [auth0Client, fetchUserData, logout, user]);

  useEffect(() => {
    if (!publicUserInfo) return;

    try {
      // Allow backward compatibility
      if (publicUserInfo) {
        if (
          isEmpty(publicUserInfo.firstName) ||
          isEmpty(publicUserInfo.lastName) ||
          isEmpty(publicUserInfo.email)
        ) {
          setNotificationErrorMessage(NotificationErrorMessage.MissingRegistrationData);
          setFailedToValidateUser(true);
        } else {
          const { meetingId } = eventDetails ?? {};
          const events = JSON.parse(localStorage.getItem(Q4_LOGIN_EVENTS_REGISTER) || "[]");
          // check if user is already registered the event.
          const isEventIncluded = events.includes(meetingId?.toString());
          setIsEventRegistered(isEventIncluded);
          setUser(publicUserInfo);
        }
      } else {
        setNotificationErrorMessage(NotificationErrorMessage.Q4UserNotFound);
        setFailedToValidateUser(true);
      }
    } catch (error) {
      logger.error(error.message);
      setNotificationErrorMessage(NotificationErrorMessage.SomethingWentWrong);
      setFailedToValidateUser(true);
    }
    setIsCheckingAuthentication(false);
  }, [publicUserInfo, eventDetails]);

  const registerToEvent = useCallback((meetingId: string) => {
    const events = JSON.parse(localStorage.getItem(Q4_LOGIN_EVENTS_REGISTER) || "[]");
    const isEventRegistered = events.includes(meetingId);
    if (!isEventRegistered) {
      localStorage.setItem(Q4_LOGIN_EVENTS_REGISTER, JSON.stringify([...events, meetingId]));
    }
  }, []);

  async function initAuth0Q4Login() {
    const client = await createAuth0Client({
      domain: config.auth0.universalLoginDomain,
      client_id: config.auth0.universalLoginClientId,
    });
    setAuth0Client(client);
  }

  const loginWithRedirect = useCallback(
    async (params: SSOLoginParams) => {
      await auth0Client.loginWithRedirect({
        helpUrl: config.q4l.helpUrl,
        projectId: "EP",
        step: "login",
        referrer: "Events Platform",
        referrerHost: window?.location?.host || "",
        ...params,
      });
    },
    [auth0Client]
  );

  // This function called when user is logged in through Q4L and:
  // - user is trying access the event he/she already registered for
  // - user is trying access another event
  const loginULUserIntoEP = useCallback(
    async (params: LoginULUserIntoEPParams) => {
      const { onSubmit, sendReminder, extraFormData, originType } = params;
      const { meetingId } = eventDetails ?? {};

      if (
        (user || cachedRegistration) &&
        !failedToValidateUser &&
        isValidatingUser === null &&
        !isHandlingFailure
      ) {
        setIsValidatingUser(true);

        try {
          // Q4 Account - Institutional Lookup, backward compatibility (adapting to Guest architecture)
          let institutionDetails: FormattedInstitution;
          if (user?.institutionId) {
            institutionDetails = {
              id: user.institutionId,
              institutionName: user.company,
              customName: false,
            };
          }

          let sendReminderEmail = sendReminder;
          if (!showPostEventRegistration && sendReminder === undefined) {
            sendReminderEmail = true;
          }

          const formEditArray: BaseEventRegistration = {
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            investorType: user?.type,
            sendReminderEmail,
            role: AttendeeEventRoles.GUEST,
          };
          if (institutionDetails) {
            formEditArray.institutionDetails = institutionDetails;
          }
          if (user?.company && user?.role) {
            formEditArray.companyName = user?.company;
            formEditArray.titleAtCompany = user?.role;
          }
          if (user?.type) {
            if (user?.type === InvestorTypeNames.Company)
              formEditArray.investorType = InvestorTypeNames.Enterprise;
            else formEditArray.investorType = user.type;
          }

          let form =
            cachedRegistration || new FormEditState({ ...formEditArray, ...extraFormData });

          let origin =
            user?.flow === PublicUserFlow.Register
              ? AttendeeOriginType.Q4L_REGISTER
              : AttendeeOriginType.Q4L_LOGIN;

          origin = originType ?? origin;

          await onSubmit(form, origin);

          /** Cache locally */
          localStorage.setItem(
            `${Q4_ATTENDEE_KEY_PREFIX}${meetingId}`,
            JSON.stringify({ ...form, meetingId })
          );

          setCachedRegistration(form);
        } catch (error) {
          setFailedToValidateUser(true);
        }
        setIsValidatingUser(false);
      }
    },
    [
      cachedRegistration,
      eventDetails,
      failedToValidateUser,
      isHandlingFailure,
      isValidatingUser,
      showPostEventRegistration,
      user,
    ]
  );

  useEffect(
    function initializeAuth0Client() {
      if (!auth0Client) {
        initAuth0Q4Login()
          .then(() => doNothing())
          .catch((error) => logger.error(error.message));
      }
    },
    [auth0Client]
  );

  useEffect(
    function verifyAuthentication() {
      if (!auth0Client) return;

      if (!featureFlags[FeatureFlagKeys.PlatformLogin]) return setIsCheckingAuthentication(false);

      checkForAuthentication()
        .then(() => doNothing())
        .catch((error) => logger.error(error.message));
    },
    [featureFlags, auth0Client, checkForAuthentication]
  );

  useEffect(
    function failureFallback() {
      if (failedToValidateUser && !isHandlingFailure) {
        handleFailure()
          .then(() => doNothing())
          .catch((error) => logger.error(error.message));
      }
    },
    [failedToValidateUser, handleFailure, isHandlingFailure]
  );

  useEffect(
    function checkForCachedRegistration() {
      /** If authenticated, check if the user had previously registered for the event */
      if (!!user && eventDetails?.meetingId && !cachedRegistration) {
        const cachedAttendee = JSON.parse(
          localStorage.getItem(`${Q4_ATTENDEE_KEY_PREFIX}${eventDetails?.meetingId}`) ?? "{}"
        );

        if (cachedAttendee?.meetingId) {
          delete cachedAttendee.meetingId;
          setCachedRegistration(cachedAttendee);
        }
      }
    },
    [user, eventDetails?.meetingId, cachedRegistration]
  );

  useEffect(
    function checkIfNeedLogout() {
      if (hasLogoutFlag && !!user && featureFlags[FeatureFlagKeys.PlatformLogin]) {
        logout();
      }
    },
    [hasLogoutFlag, user, logout, featureFlags]
  );

  return (
    <Q4LoginContext.Provider
      value={{
        user,
        isGuestRoute,
        isAuthenticated: !!user,
        isInitializingQ4Login:
          isCheckingAuthentication || isValidatingUser || !auth0Client || isHandlingFailure,
        isQ4LoginEnabled: featureFlags[FeatureFlagKeys.PlatformLogin],
        isAlreadyRegistered: !!cachedRegistration || isEventRegistered,
        auth0Client,
        getRedirectUrlFromEvent,
        loginULUserIntoEP,
        logout,
        registerToEvent,
        loginWithRedirect,
      }}
    >
      {props.children}
    </Q4LoginContext.Provider>
  );
};

export default Q4LoginContext;
