import { find } from "lodash";
import { useContext, useMemo } from "react";
import { AssetEventRoles } from "../../../../../../configurations/userRolesPermissions.configuration";
import { EventContext } from "../../../../../../contexts";
import { useGetPresenterControlledSlidesQuery } from "../../../../../../hooks/slideAssets/slideAssets.hook";
import { OTStreamType, OTStreamTypes } from "../../../../../../opentok-react/types";
import { newTempOTStream } from "../../../../../../opentok-react/utils/stream.utils";
import { promiseTimeout } from "../../../../../../utils";
import { useSafeState } from "../../../../../../utils/react.utils";
import { OT_PR_EMAIL } from "../../../../../remoteBroadcastLayout/remoteBroadcastLayout.definition";
import { BroadcastStream } from "../../../../interfaces/broadcastStream/broadcastStream";
import {
  TrackBroadcastStreamsModel,
  TrackBroadcastStreamsProps,
} from "../../../../interfaces/trackBroadcastStreams/trackBroadcastStreams.definition";
import { OpenTokStream } from "../../components/OpenTokStream";

export default function useTrackOTStreamsHook(
  props: TrackBroadcastStreamsProps
): TrackBroadcastStreamsModel {
  const [streams, setStreams, streamsRef] = useSafeState<OpenTokStream[]>([]);
  const { currentEvent } = useContext(EventContext);

  const { refetch: getPresenterControlledSlides } = useGetPresenterControlledSlidesQuery({
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  function convertToVendorStreamType(streams: BroadcastStream[]): OTStreamType[] {
    return streams.map((stream) => stream.getVendorStream());
  }

  function getCurrentStreams(): Array<BroadcastStream> {
    return streams;
  }

  function refreshStreams(): void {
    setStreams((streams) => [...streams]);
  }

  function getStreamById(id: string): OpenTokStream {
    return streamsRef?.current?.find((stream) => stream.id === id);
  }

  function getStreamByConnectionId(id: string): OpenTokStream {
    return streamsRef?.current?.find((stream) => stream?.connection?.connectionId === id);
  }

  function isPSTNStream(stream: OTStreamType): boolean {
    try {
      const data = JSON.parse(stream?.connection?.data);
      return !!data.sip;
    } catch (error) {
      return !stream?.connection?.data && !!stream?.connection?.connectionId;
    }
  }

  function isOTPRStream(stream: OpenTokStream): boolean {
    try {
      const data = JSON.parse(stream?.connection?.data);
      return data.email === OT_PR_EMAIL;
    } catch (error) {
      return false;
    }
  }

  async function onStreamCreated(_stream: OpenTokStream) {
    const hasTempStream = _tempStreamExists(_stream);
    const isOTPR = isOTPRStream(_stream);
    const stream = await applyMetaData(_stream);

    setStreams((streams) => {
      if (hasTempStream && stream.type !== OTStreamTypes.Screen) {
        return streams.map((item) => {
          if (item.id === stream.connection.connectionId) {
            return stream;
          }
          return item;
        });
      } else if (streamExists(stream) || isOTPR) {
        return streams;
      }

      return streams.concat(stream);
    });
  }

  function onStreamDestroyed(stream: OTStreamType) {
    setStreams((streams) => streams.filter((existingStream) => existingStream.id !== stream.id));
  }

  async function onConnectionCreated(
    connection: any,
    shouldTrackStream: (connectionData: any) => boolean
  ) {
    const connectionData = JSON.parse(connection.data);

    //Don't create temp stream
    if (connectionData && !shouldTrackStream?.(connectionData)) return;

    const openTokStream = new OpenTokStream({
      ...newTempOTStream(),
      id: connection.connectionId || "",
      streamId: connection.connectionId || "",
      name: connectionData?.name || "",
      connection: connection || {},
    });

    setStreams((streams) => [...streams, openTokStream]);
  }

  function onConnectionDestroyed(connection: any) {
    setStreams((streams) =>
      streams.filter((existingStream) => existingStream.connection.connectionId !== connection.id)
    );
  }

  function streamExists(stream: OpenTokStream | OTStreamType) {
    return !!streamsRef.current.find((existingStream) => existingStream.id === stream.id);
  }

  function _tempStreamExists(stream: OpenTokStream | OTStreamType) {
    return !!streamsRef.current.find(
      (existingStream) => existingStream.id === stream.connection.connectionId
    );
  }

  async function applyMetaData(stream: OpenTokStream) {
    if (stream.isV2AssetStream) return stream;

    try {
      const connectionData = JSON.parse(stream.connection.data);
      const meetingId = +currentEvent?.meetingId;
      if (connectionData.type === AssetEventRoles.ASSET) {
        const asset = find(currentEvent?.assetUploads || [], { id: stream.name });

        if (asset) {
          stream.displayName = asset.displayName;
          stream.assetType = asset.assetType;
          stream.assetData = { ...asset };
          if (stream.isSlideAsset) {
            try {
              const sourceUrlResult = await getPresenterControlledSlides({ meetingId });
              stream.assetData.url = sourceUrlResult?.data?.getPresenterControlledSlides?.url;
            } catch (error) {
              console.warn("Failed to get slides url");
            }
          }
        }
      }
    } catch (error) {
      console.warn("Failed to capture asset metadata for stream", stream);
      console.warn(error);
    }
    return stream;
  }

  function clearStreams() {
    setStreams([]);
  }

  /**
   * Will remove stream from tracking list & add back after timeout
   * @param streamId
   * @param refreshTimeoutMs : timeout before adding back stream in ms
   */
  async function refreshStreamById(
    streamId: string,
    refreshTimeoutMs: number = 6000
  ): Promise<void> {
    const streamToRefresh = getStreamById(streamId);
    if (!streamToRefresh) return;

    // remove stream
    setStreams((streams) => streams.filter((stream) => stream.id !== streamId));

    // add stream back after refresh timeout
    await promiseTimeout(refreshTimeoutMs);
    if (!(streamToRefresh as any)?.getVendorStream()?.destroyed) {
      setStreams((streams) => [...streams, streamToRefresh]);
    }
  }

  /**
   * Helper function for determining if there are assets available in the stream pool
   */
  const areAssetsAvailable = useMemo(
    () => !!streams.find((stream) => stream.isAssetStream && !stream.isTempStream),
    [streams]
  );

  return {
    streams,
    trackStreamsService: {
      areAssetsAvailable,
      clearStreams,
      convertToVendorStreamType,
      getCurrentStreams,
      getStreamByConnectionId,
      getStreamById,
      isPSTNStream,
      onConnectionCreated,
      onConnectionDestroyed,
      onStreamCreated,
      onStreamDestroyed,
      refreshStreamById,
      refreshStreams,
      streamExists,
    },
  };
}
