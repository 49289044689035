import { gql } from "@apollo/client";
import { UploadedDataFormat } from "../../../services/event/event.model";
import { EVENT_FRAGMENTS } from "../../../hooks/event/event.gql";
import {
  EventRecordingUploadType,
  EventPublishTypes,
  PublishStateEnum,
} from "../../postEventEdit/definitions/postEventEdit.definition";
import { EventModel } from "../../../hooks/events/events.hook.definitions";
import { RegistrationField } from "../../../services/event/registrationFieldGql.model";
export {
  EventRecordingUploadType as EventMicrositeConfigurationVideoRecording,
  EventPublishTypes as EventMicrositeConfigurationPublishSchedule,
  PublishStateEnum as PublishScheduleStatusEnum,
} from "../../postEventEdit/definitions/postEventEdit.definition";

export enum EventMicrositeConfigurationMusicOptions {
  DEFAULT = "DEFAULT",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}

export enum EventMicrositePageType {
  ATTENDEE_CONSOLE_BRANDING_PAGE = "attendee_console_branding",
  CONTROL_PANEL = "control_panel",
  WELCOME_PAGE = "welcome_page",
  REGISTRATION_PAGE = "registration_page",
  PRE_EVENT_PAGE = "pre_event_page",
  THANK_YOU_PAGE = "thank_you_page",
  POST_EVENT_PAGE = "post_event_page",
  ALL_PAGES = "all_pages",
}

export type EventMicrositePageTabNumber = Partial<Record<EventMicrositePageType, number>>;

export interface SavedEventBrandingType {
  controlPanelBranding: UpdateControlPanelBrandingParams;
  welcomePageBranding: UpdateEventWelcomePageBrandingParams;
  registrationPageBranding: UpdateEventRegistrationPageBrandingParams;
  preEventPageBranding: UpdateEventPreEventPageBrandingParams;
  thankYouPageBranding: UpdateEventThankYouPageBrandingParams;
  postEventPageBranding: UpdateEventPostEventPageBrandingParams;
  attendeeConsoleBranding?: UpdateAttendeeConsoleBrandingParams;
}

export interface EventBrandingPreviewType {
  controlPanelBranding: UpdateControlPanelBrandingParams;
  welcomePageBranding: UpdateEventWelcomePageBrandingParams;
  registrationPageBranding: UpdateEventRegistrationPageBrandingParams;
  preEventPageBranding: UpdateEventPreEventPageBrandingParams;
  thankYouPageBranding: UpdateEventThankYouPageBrandingParams;
  postEventPageBranding: UpdateEventPostEventPageBrandingParams;
  attendeeConsoleBranding?: UpdateAttendeeConsoleBrandingParams;
}

type CommonBrandingParams = {
  title: string;
  description?: string;
};

export interface UpdateControlPanelBrandingParams {
  primaryFont: UploadedDataFormat;
  secondaryFont: UploadedDataFormat;
  primaryColor: string;
  secondaryColor: string;
  primaryFontWeight: string;
  secondaryFontWeight: string;
  backgroundImage: UploadedDataFormat;
  companyLogo: UploadedDataFormat;
  publishSchedule: EventPublishTypes;
  publishScheduleStatus: PublishStateEnum;
  videoRecording: EventRecordingUploadType;
  hideMeetingReplay?: boolean;
}

export interface UpdateEventWelcomePageBrandingParams extends CommonBrandingParams {
  proxyWebsiteLink?: string;
  displayTime?: boolean;
}

export interface UpdateEventRegistrationPageBrandingParams extends CommonBrandingParams {
  broadridgeTelephoneNumber?: string | null;
  broadridgeInternationalTelephoneNumber?: string | null;
  openRegistration: string;
  proxyWebsiteLink?: string;
  termsAndConditionsUrl?: string;
}

export interface UpdateEventPreEventPageBrandingParams {
  message: string;
  lobbyMessage: string;
  musicOption: EventMicrositeConfigurationMusicOptions;
}

export type UpdateEventThankYouPageBrandingParams = CommonBrandingParams;

export interface UpdateEventPostEventPageBrandingParams {
  meetingDetails?: string;
}

export interface UpdateAttendeeConsoleBrandingParams {
  images: {
    eventLogo?: UploadedDataFormat;
    eventFavicon?: UploadedDataFormat;
  };
  headerColors: {
    background?: string;
    text?: string;
  };
  footerColors: {
    background?: string;
    primaryButton?: string;
    primaryButtonText?: string;
  };
  mediaBarColors: {
    background?: string;
    controls?: string;
  };
}

export type EventMicrositePageDataTypes =
  | UpdateControlPanelBrandingParams
  | UpdateEventWelcomePageBrandingParams
  | UpdateEventRegistrationPageBrandingParams
  | UpdateEventPreEventPageBrandingParams
  | UpdateEventThankYouPageBrandingParams
  | UpdateEventPostEventPageBrandingParams
  | UpdateAttendeeConsoleBrandingParams;

export interface UpdateEventMicrositeInput {
  meetingId: number;
  pageType: string;
  registrationFields?: RegistrationField[];
  controlPanelBrandingParams?: UpdateControlPanelBrandingParams;
  allPagesBrandingParams?: {
    postEventPageBranding?: UpdateEventPostEventPageBrandingParams;
    preEventPageBranding?: UpdateEventPreEventPageBrandingParams;
    registrationPageBranding?: UpdateEventRegistrationPageBrandingParams;
    thankYouPageBranding?: UpdateEventThankYouPageBrandingParams;
    welcomePageBranding?: UpdateEventWelcomePageBrandingParams;
    attendeeConsoleBranding?: UpdateAttendeeConsoleBrandingParams;
  };
}

export interface UpdateEventMicrositePreviewInput {
  meetingId: number;
  pageType: string;
  registrationFields?: RegistrationField[];
  postEventPageBrandingParams?: UpdateEventPostEventPageBrandingParams;
  preEventPageBrandingParams?: UpdateEventPreEventPageBrandingParams;
  registrationPageBrandingParams?: UpdateEventRegistrationPageBrandingParams;
  thankYouPageBrandingParams?: UpdateEventThankYouPageBrandingParams;
  welcomePageBrandingParams?: UpdateEventWelcomePageBrandingParams;
  attendeeConsoleBrandingParams?: UpdateAttendeeConsoleBrandingParams;
}

export interface UpdateEventStartPeriodSubVars {
  meetingId: number;
}

export interface EventStartPeriodUpdated {
  event: Partial<EventModel>;
  isEventStarted: boolean;
}

export interface UpdateEventStartPeriodSubData {
  onEventStartPeriodUpdated: EventStartPeriodUpdated;
}

export const GET_PUBLIC_EVENT = ({ isDisasterRecoveryEnabled }) => gql`
  ${EVENT_FRAGMENTS.PublicEventDetails({ isDisasterRecoveryEnabled })}

  query getPublicEventDetails($meetingId: Int, $skipCache: Boolean) {
    getPublicEventDetails(meetingId: $meetingId, skipCache: $skipCache) {
      ...PublicEvent
    }
  }
`;

export const GET_EVENT = ({ isDisasterRecoveryEnabled, isDualStreamEnabled }) => gql`
  ${EVENT_FRAGMENTS.Event({ isDisasterRecoveryEnabled, isDualStreamEnabled })}

  query getEvent($meetingId: Int) {
    getEvent(meetingId: $meetingId) {
      ...Event
    }
  }
`;

export const UPDATE_EVENT_MICROSITE_PREVIEW = gql`
  mutation micrositePreview($input: UpdateEventMicrositePreviewInput!) {
    updateEventMicrositePreview(input: $input) {
      previewOptions {
        welcomePageBranding {
          title
          description
          proxyWebsiteLink
          displayTime
        }
        registrationPageBranding {
          broadridgeTelephoneNumber
          broadridgeInternationalTelephoneNumber
          title
          description
          openRegistration
          proxyWebsiteLink
          termsAndConditionsUrl
        }
        preEventPageBranding {
          message
          lobbyMessage
          musicOption
        }
        thankYouPageBranding {
          title
          description
        }
        postEventPageBranding {
          meetingDetails
        }
      }
    }
  }
`;

export const UPDATE_EVENT_MICROSITE_BRANDING = gql`
  mutation micrositeBranding($input: UpdateEventMicrositeInput!) {
    updateEventMicrositeBranding(input: $input) {
      title
      description
      registrationFields {
        id
        _templateId
        _companyId
        title
        placeholder
        defaultValue
        required
        hidden
        condition {
          _registrationFieldId
          conditionalValue
        }
        registrationFieldType
        position
        options {
          id
          value
          default
        }
        optionSort {
          enabled
          direction
          order
        }
      }
      branding {
        controlPanelBranding {
          primaryColor
          secondaryColor
          primaryFont {
            name
            displayName
            fileType
          }
          secondaryFont {
            name
            displayName
            fileType
          }
          primaryFontWeight
          secondaryFontWeight
        }
        welcomePageBranding {
          title
          description
          proxyWebsiteLink
          displayTime
        }
        registrationPageBranding {
          broadridgeTelephoneNumber
          broadridgeInternationalTelephoneNumber
          title
          description
          openRegistration
          proxyWebsiteLink
          termsAndConditionsUrl
        }
        preEventPageBranding {
          message
          lobbyMessage
          musicOption
        }
        thankYouPageBranding {
          title
          description
        }
        postEventPageBranding {
          meetingDetails
        }
        attendeeConsoleBranding {
          images {
            eventLogo {
              name
              displayName
              fileType
            }
            eventFavicon {
              name
              displayName
              fileType
            }
          }
          headerColors {
            background
            text
          }
          footerColors {
            background
            primaryButton
            primaryButtonText
          }
          mediaBarColors {
            background
            controls
          }
        }
      }
    }
  }
`;

export const UPDATE_EVENT_START_PERIOD_SUBSCRIPTION = gql`
  subscription ($meetingId: Int) {
    onEventStartPeriodUpdated(meetingId: $meetingId) {
      event {
        meetingId
        branding {
          registrationPageBranding {
            broadridgeTelephoneNumber
            broadridgeInternationalTelephoneNumber
            title
            description
            openRegistration
            proxyWebsiteLink
            termsAndConditionsUrl
          }
        }
      }
      isEventStarted
    }
  }
`;
