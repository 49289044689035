import { User } from "../../services/user/user.model";
import { ExecutionResult } from "graphql/execution/execute";

export enum LoginStates {
  Suspended = "suspended",
  Registered = "registered",
  Reset = "reset",
  UnsuccessfulAuth = "unsuccessful_auth",
  UserMissing = "user_missing",
}

export interface PermissionObj {
  [permissionName: string]: boolean;
}

export enum AuthKeySuffixes {
  PlATFORM = "platform",
  ATTENDEE = "attendee",
  CONSOLE = "console",
  REPORT = "report",
}

export enum AuthConstants {
  LOGIN_FAIL_LIMIT = 5,
  LOGIN_REDIRECT_SESSION_KEY = "auth0LoginRedirect",
  LOGIN_REDIRECT_PATH = "/authcallback",
  AUTH_TOKEN_COOKIE_NAME = "authToken",
}

export enum LoginAttemptStatus {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface Auth0ContextState {
  authInitialized?: boolean;
  isAuthenticated?: boolean;
  isAttendeeAuthenticated?: boolean;
  loginDisabled?: boolean;
  user?: User;
  isUserSuspended: boolean;
  currentToken?: string;
  isLoading?: boolean;
  getCurrentUser?: () => User;
  updateUser?: (user: User) => void;
  updateAttendeeToken?: (token: string) => void;
  updateAttendeeAuthenticated?: (isAuthenticated: boolean) => void;
  updateReportToken?: (token: string) => void;
  updateEventToken?: (token: string) => void;
  getUserAccessToken?: () => Promise<string>;
  checkAuthenticatedUser?: () => Promise<any>;
  enableAuthentication?: () => void;
  getIdTokenClaims?: () => any;
  handleRedirectCallback?: () => void;
  handleAuthOnCallback?: () => Promise<any>;
  loginApplication?: (
    loginApplicationProps: LoginApplicationProps,
    failedLoginCountNum: number
  ) => Promise<void>;
  loginWithRedirect?: (loginWithRedirectProps: LoginWithRedirectProps) => void;
  logoutApplication?: (logoutProps: LogoutApplicationProps) => void;
  errorInterceptor?: (response: ExecutionResult) => void;
}

export const BaseTokenPermissions = {
  AdminConsole: "event:base:permission",
};

export interface LoginApplicationProps {
  email: string;
  password: string;
}
export interface LoginWithRedirectProps {
  appState?: { targetUrl: string };
}

export interface LogoutApplicationProps {
  returnTo?: string;
}

export interface DecodedAccessToken {
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  gty: string;
  permissions: string[];
  jti: string;
}
