import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { RefObject } from "react";

export enum AspectRatio {
  Wide = "wide",
  Standard = "standard",
}

export interface AspectRatioSizerProps extends BaseComponentWithChildrenProps {
  width?: string | number;
  ratio?: AspectRatio;
  childRef?: RefObject<HTMLDivElement>;
  childWrapperId?: string;
}

export enum AspectRatioSizerClassNames {
  Base = "aspect-ratio-sizer",
  Stage = "aspect-ratio-sizer_stage",
  PaddedWrapper = "aspect-ratio-sizer_padded-wrapper",
  ChildWrapper = "aspect-ratio-sizer_child-wrapper",
}
