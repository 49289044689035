import { useCallback, useState } from "react";
import { EventMeetingMaterial } from "../eventMaterials/eventMaterials.hook.definition";
import { PermissionObj } from "../../contexts";
import { BroadcastContextStatus, SessionToken } from "../../services/event/event.model";
import { EventConfiguration } from "../../services/event/eventGql.model";
import { BroadcastStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/broadcastState";
import { EventStatusEnum } from "../../views/adminConsole/hooks/BroadcastRealTimeEvents/eventState";
import { EventModelPopulated } from "../events/events.hook.definitions";

export interface EventDetailsModel {
  eventId: number;
  currentEvent: EventModelPopulated;
  userEventPermissions: PermissionObj;
  sessionToken: SessionToken;
  broadcastStatus: BroadcastStatusEnum;
  eventConfiguration: EventConfiguration;
  broadcastStatusContext: BroadcastContextStatus;
  broadcastStartTime: Date;
  eventStatus: EventStatusEnum;
  setEventId: (number) => void;
  setUserEventPermissions: (eventPermissions: PermissionObj) => void;
  setCurrentEvent: (Event) => void;
  setBroadcastStatus: (BroadcastStatusEnum) => void;
  setBroadcastStatusContext: (context: BroadcastContextStatus) => void;
  setBroadcastStartTime: (time: Date) => void;
  setEventStatus: (eventStatus: EventStatusEnum) => void;
  setSessionToken: (sessionToken: SessionToken) => void;
}

export default function useEventDetails() {
  const [eventId, _setEventId] = useState<number>();
  const [currentEvent, _setCurrentEvent] = useState<EventModelPopulated>();
  const [userEventPermissions, _setUserEventPermissions] = useState<PermissionObj>();
  const [broadcastStatus, _setBroadcastStatus] = useState<BroadcastStatusEnum>();
  const [eventConfiguration, _setEventConfiguration] = useState<EventConfiguration>();
  const [broadcastStatusContext, _setBroadcastStatusContext] = useState<BroadcastContextStatus>();
  const [broadcastStartTime, _setBroadcastStartTime] = useState<Date>();
  const [eventStatus, _setEventStatus] = useState<EventStatusEnum>();
  const [sessionToken, _setSessionToken] = useState<SessionToken>();
  const [meetingMaterials, setMeetingMaterials] = useState<Array<EventMeetingMaterial>>();

  const setEventId = useCallback((eventId: number) => {
    return _setEventId(eventId);
  }, []);

  const setCurrentEvent = useCallback((event: EventModelPopulated) => {
    _setCurrentEvent(event);
    _setEventConfiguration(event?.configuration);
    _setBroadcastStatusContext(event?.conference?.context);
  }, []);

  const setUserEventPermissions = useCallback((permissions: PermissionObj) => {
    _setUserEventPermissions(permissions);
  }, []);

  const setBroadcastStatus = useCallback((broadcastStatus: BroadcastStatusEnum) => {
    _setBroadcastStatus(broadcastStatus);
  }, []);

  const setBroadcastStatusContext = useCallback((context: BroadcastContextStatus) => {
    _setBroadcastStatusContext(context);
  }, []);

  const setBroadcastStartTime = useCallback((time: Date) => {
    _setBroadcastStartTime(time);
  }, []);

  const setEventStatus = useCallback((eventStatus: any) => {
    _setEventStatus(eventStatus);
  }, []);

  const setSessionToken = useCallback((eventToken: SessionToken) => {
    _setSessionToken(eventToken);
  }, []);

  return {
    eventId,
    currentEvent,
    userEventPermissions,
    broadcastStatus,
    eventConfiguration,
    broadcastStatusContext,
    broadcastStartTime,
    eventStatus,
    sessionToken,
    meetingMaterials,
    setEventId,
    setCurrentEvent,
    setUserEventPermissions,
    setBroadcastStatus,
    setBroadcastStatusContext,
    setBroadcastStartTime,
    setEventStatus,
    setSessionToken,
    setMeetingMaterials,
  };
}
