import { stringify } from "qs";
import { useContext, useMemo } from "react";
import config from "../../config";
import { Auth0Context } from "../../contexts";
import { SubscriberAttendee } from "../attendees/attendees.model";
import { PublicUserInfo } from "../organization/organization.model";
import { useGetRequest } from "./hook/getRequest";
import { usePostRequest } from "./hook/postRequest";
import { useDeleteRequest } from "./hook/deleteRequest";

const apiUrl = config.api.attendeeRest;

interface UseGetPublicUserInfoProps {
  idToken: string;
  skip?: boolean;
  refreshInterval?: number;
}

interface UseGetSubscriberAttendeeProps {
  meetingId: number;
  email: string;
  skip?: boolean;
}

interface UseCreateQuestionProps {
  attendeeId: string;
}

interface UseGetQuestionsProps {
  attendeeId: string;
}

interface UseRemoveAttendeeByWsIdProps {
  meetingId: number;
  wsId: string;
}

export const useGetPublicUserInfo = (props: UseGetPublicUserInfoProps) => {
  const { idToken, skip, refreshInterval } = props;

  const path = useMemo(() => {
    if (!idToken) return null;

    const queryParams = `${stringify({ authToken: idToken }, { addQueryPrefix: true })}`;

    return `${apiUrl}/attendee/public/profile${queryParams}`;
  }, [idToken]);

  const { data, error, isLoading, refetch } = useGetRequest({
    skip,
    refreshInterval,
    path,
  });

  return {
    data: data as PublicUserInfo,
    error,
    isLoading,
    refetch,
  };
};

export const useGetSubscriberAttendee = (props: UseGetSubscriberAttendeeProps) => {
  const { meetingId, email, skip } = props;

  const path = useMemo(() => {
    if (!meetingId || !email) return null;

    const queryParams = `${stringify(
      { meetingId, email },
      {
        addQueryPrefix: true,
      }
    )}`;

    return `${apiUrl}/attendee${queryParams}`;
  }, [email, meetingId]);

  const { data, error, isLoading, refetch } = useGetRequest({
    skip,
    path,
  });

  return {
    data: data as SubscriberAttendee,
    error,
    isLoading,
    refetch,
  };
};

export const useCreateAttendee = () => {
  const { getUserAccessToken } = useContext(Auth0Context);
  const path = `${apiUrl}/attendee`;

  const { data, error, isLoading, update } = usePostRequest({
    path,
    getAuthToken: getUserAccessToken,
  });

  return {
    data,
    error,
    isLoading,
    createAttendee: update,
  };
};

export const useCreateQuestion = (props: UseCreateQuestionProps) => {
  const { attendeeId } = props;
  const { getUserAccessToken } = useContext(Auth0Context);
  const path = `${apiUrl}/attendee/${attendeeId}/question`;

  const { data, error, isLoading, update } = usePostRequest({
    path,
    getAuthToken: getUserAccessToken,
  });

  return {
    data,
    error,
    isLoading,
    createQuestion: update,
  };
};

export const useGetQuestions = (props: UseGetQuestionsProps) => {
  const { attendeeId } = props;
  const { getUserAccessToken } = useContext(Auth0Context);
  const path = `${apiUrl}/attendee/${attendeeId}/questions`;

  const { data, error, isLoading, refetch } = useGetRequest({
    path,
    skip: !attendeeId,
    getAuthToken: getUserAccessToken,
  });

  return {
    data,
    error,
    isLoading,
    refetch,
  };
};

export const useRemoveAttendeeByWsId = (props: UseRemoveAttendeeByWsIdProps) => {
  const { meetingId, wsId } = props;
  const { getUserAccessToken } = useContext(Auth0Context);
  const path = `${apiUrl}/attendees?meetingId=${meetingId}&wsId=${wsId}`;

  const { data, error, isLoading, remove } = useDeleteRequest({
    path,
    getAuthToken: getUserAccessToken,
  });

  return {
    data,
    error,
    isLoading,
    remove,
  };
};
