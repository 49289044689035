import { useEffect, useCallback, useState, useMemo } from "react";
import { AudioVideoState } from "../../components/AudioVideoControls/AudioVideoControls.definition";
import { OTSessionType } from "../../opentok-react/types";
import { SelectionModel } from "../../views/adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../views/adminConsole/interfaces/broadcastStream/broadcastStream";

import OTSignalingService, {
  OTSignalType,
  signalTypes,
} from "../../views/adminConsole/vendors/openTok/services/openTokSignaling.service";
import { Asset } from "../assets/assets.hook.definition";
import { AssetUploads, AudioVideoUploads } from "../assetUploads/assetUploads.definition";

export interface ControlAssetProps {
  streams: BroadcastStream[];
  staged: SelectionModel;
  assets: Array<Asset | AssetUploads>;
  session: OTSessionType;
  assetsAvailable: boolean;
}
export interface AssetStats {
  name?: string;
  volume?: number;
  state?: AudioVideoState;
  currentTime?: number;
  duration?: number;
}

export default function useControlAsset(props: ControlAssetProps) {
  const { streams, staged, assets, session, assetsAvailable } = props;
  const [opentokSignal, setOpentokSignal] = useState(null);

  useEffect(() => {
    if (session) setOpentokSignal(OTSignalingService.getInstance(session));
  }, [session]);

  const [assetsState, setAssetsState] = useState(
    (assets || []).map((asset: Asset | AssetUploads) => {
      return {
        name: (asset as AssetUploads).id ?? asset.name,
        state: AudioVideoState.PAUSE,
        volume: 100,
        currentTime: 0,
        duration: (asset as AssetUploads).id
          ? (asset as AudioVideoUploads).context.duration
          : (asset as Asset).duration,
      };
    })
  );

  // getting the name of the file currently staged to help determine when to get stats
  const stagedAssetName = useMemo(
    function getStagedAssetName() {
      const assetIsStaged = [...staged?.cameraSelection, ...staged?.mediaSelection].find(
        (stream) => stream.isAssetStream
      );
      return assetIsStaged?.name || "";
    },
    [staged]
  );

  // signal worker for stats on play/pause status of asset
  const getAssetStats = useCallback(
    (name: string) => {
      const connection = streams.find((stream) => stream.name === name)?.connection;
      connection && opentokSignal?.signalStreams?.([connection], signalTypes.GET_ASSET_STATS);
    },
    [opentokSignal, streams]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  function updateState(data: AssetStats, name: string): void {
    setAssetsState((oldState) =>
      oldState.map((state) => {
        if (state.name !== name) return state;
        return { ...state, ...data };
      })
    );
  }

  const receiveStats = useCallback((event: OTSignalType) => {
    try {
      const [assetName, stats] = event.data.split(";");
      let parsedStats = JSON.parse(stats);
      updateState(parsedStats, assetName);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleAssetEnded = useCallback((event: OTSignalType) => {
    try {
      const [assetName, data] = event.data.split(";");
      const parsedData = JSON.parse(data);
      updateState(parsedData, assetName);
    } catch (error) {
      console.error(error);
    }
  }, []);

  // whenever staged contents change, get play/pause status if there is an asset
  useEffect(
    function getAssetStatsOnStageChange() {
      stagedAssetName && getAssetStats(stagedAssetName);
    },
    [stagedAssetName, getAssetStats]
  );

  // whenever assets become available by the worker, register signal listeners
  useEffect(
    function registerWorkerListener() {
      if (!opentokSignal || !assetsAvailable) return;
      session?.on(`signal:${signalTypes.ASSET_STATS}`, receiveStats);
      return () => {
        session?.off(`signal:${signalTypes.ASSET_STATS}`, receiveStats);
      };
    },
    [session, assetsAvailable, opentokSignal, receiveStats]
  );

  useEffect(
    function registerAssetEndedListener() {
      if (!opentokSignal || !assetsAvailable) return;
      session?.on(`signal:${signalTypes.ASSET_ENDED}`, handleAssetEnded);
      return () => {
        session?.off(`signal:${signalTypes.ASSET_ENDED}`, handleAssetEnded);
      };
    },
    [session, assetsAvailable, opentokSignal, handleAssetEnded]
  );

  const onSeek = useCallback(
    (stream: BroadcastStream) => {
      opentokSignal.signalStreams(
        [stream.connection],
        signalTypes.JUMP_TO_ASSET_TIME,
        `${stream.name};0`
      );
    },
    [opentokSignal]
  );

  const onStart = useCallback(
    (stream: BroadcastStream) => {
      updateState({ state: AudioVideoState.PLAY }, stream.name);
      opentokSignal.signalStreams([stream.connection], signalTypes.PLAY_ASSET, stream.name);
    },
    [opentokSignal]
  );

  const onStop = useCallback(
    (stream: BroadcastStream) => {
      updateState({ state: AudioVideoState.PAUSE }, stream.name);
      opentokSignal.signalStreams([stream.connection], signalTypes.PAUSE_ASSET, stream.name);
    },
    [opentokSignal]
  );

  const onVolume = useCallback(
    (stream: BroadcastStream, volume: number) => {
      updateState({ volume }, stream.name);
      opentokSignal.signalStreams(
        [stream.connection],
        signalTypes.VOLUME,
        `${stream.name};${volume}`
      );
    },
    [opentokSignal]
  );

  const setAudio = useCallback(
    (stream: BroadcastStream, hasAudio: boolean) => {
      opentokSignal.signalStreams(
        [stream.connection],
        signalTypes.SET_ASSET_AUDIO,
        `${stream.name};${hasAudio}`
      );
    },
    [opentokSignal]
  );

  return { onSeek, onStop, onStart, onVolume, setAudio, assetsState, eventAssets: assets };
}
