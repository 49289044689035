import { ButtonTheme, Message, MessageType, PopoverMenuButtonProps } from "@q4/nimbus-ui";
import { pick } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DisplayAssetDuration from "../../../../components/AudioVideoControls/displayDuration.component";
import { CustomAvatarSizes } from "../../../../components/customAvatar/customAvatar.definition";
import AudioVideoPreviewComponent from "../../../../components/event/modal/AudioVideoPreview/AudioVideoPreview.component";
import { FeatureFlagKeys } from "../../../../configurations/featureFlagKeys";
import { EventPermission } from "../../../../configurations/userRolesPermissions.configuration";
import { AdminConsoleContext, EventAssetsContext, EventContext } from "../../../../contexts";
import { FeatureFlagsContext } from "../../../../contexts/featureFlags";
import { Asset } from "../../../../hooks/assets/assets.hook.definition";
import {
  AssetUploads,
  AudioVideoUploads,
} from "../../../../hooks/assetUploads/assetUploads.definition";
import { Participant } from "../../../../hooks/participants/participants.hook.definition";
import { close } from "../../../../utils/ui.utils";
import { useAssetStreamMenu } from "../../hooks/carouselStreamMenu/assetStreamMenu.hook";
import { useStreamMenu } from "../../hooks/carouselStreamMenu/streamMenu.hook";
import { useStreamThumbnail } from "../../hooks/streamThumbnail/streamThumbnail.hook";
import { AssetBroadcastStream } from "../../vendors/openTok/components/AssetBroadcastStream";
import { BroadcastStreamBadge } from "../BroadcastStreamBadge/BroadcastStreamBadge";
import { VideoWrapper } from "../VideoWrapper/VideoWrapper.component";
import { VideoWrapperClassNames } from "../VideoWrapper/VideoWrapper.definition";
import "./carouselStreamWrapper.scss";
import { useCarouselStreamToolTips } from "./hooks/useCarouselStreamToolTips.hook";
import { useStreamDisplayName } from "./hooks/useStreamDisplayName.hook";
import {
  CarouselStreamWrapperClassNames,
  CarouselStreamWrapperProps,
} from "./streamWrapper.definition";
import { useRebrandFlag } from "../../../../hooks/featureFlagHooks/useRebrandFlag.hook";

export const CarouselStreamWrapper = (props: CarouselStreamWrapperProps) => {
  const { stream, participant, children, audioOnly = false, userEventPermissions } = props;

  const { currentEvent } = useContext(EventContext);
  const { eventAssets } = useContext(EventAssetsContext);
  const {
    carouselSelectionService: { select, unselect, canSelect, isSelected },
  } = useContext(AdminConsoleContext);

  const [streamSelected, setStreamSelected] = useState(false);
  const { getRebrandingClassName } = useRebrandFlag();

  const { featureFlags } = useContext(FeatureFlagsContext);

  useEffect(
    function onSelectedChange() {
      const _isSelected = isSelected(stream);
      setStreamSelected(_isSelected);
    },
    [isSelected, stream, streamSelected]
  );

  const onSelectStream = useCallback(
    (_streamId: string) => {
      if (!streamSelected) {
        select(stream);
      } else {
        unselect(stream);
      }
    },
    [select, stream, streamSelected, unselect]
  );

  /** Confirmation Modals */
  const [removeStreamConfirmation, setRemoveStreamConfirmation] = useState(false);
  const [selectionPreviewModal, setAssetPreviewModal] = useState(false);

  const onConfirmRemove = useCallback(() => {
    setRemoveStreamConfirmation(false);
    props.streamMenuActions.onRemoveStream(stream);
  }, [props.streamMenuActions, stream]);

  /** Stream Menu Options */
  const { menu: StreamMenuItems } = useStreamMenu({ ...props, ...props.streamMenuActions });
  const { menu: AssetStreamMenuItems } = useAssetStreamMenu({
    ...pick(props, ["stream", "userEventPermissions"]),
    meetingId: currentEvent?.meetingId,
    assetPreviewModal: setAssetPreviewModal,
    confirmRemoveFromStage: setRemoveStreamConfirmation,
  });

  const menuItems = useMemo(
    () => (stream.isAssetStream ? AssetStreamMenuItems : StreamMenuItems),
    [AssetStreamMenuItems, StreamMenuItems, stream.isAssetStream]
  ) as PopoverMenuButtonProps[];

  /** Tooltip Messages */
  const { selectionTooltipMsg } = useCarouselStreamToolTips({ participant, stream, audioOnly });

  /** Stream Asset Data */
  const streamAsset = useMemo(() => {
    return eventAssets.find((asset) => stream.name === (asset as AssetUploads).id) || {};
  }, [stream, eventAssets]);

  const isPlayableAsset = useMemo(() => stream?.isVideoAsset || stream?.isAudioAsset, [stream]);

  /** Stream Info */
  const { streamDisplayName, phoneNumberVisible } = useStreamDisplayName({ stream, participant });
  const thumbnail = useStreamThumbnail({
    stream,
    displayName: phoneNumberVisible ? null : streamDisplayName,
    useV2: currentEvent?.configuration?.layoutManager?.enabled,
    size: CustomAvatarSizes.Large,
    classNames: {
      wrapper: CarouselStreamWrapperClassNames.Thumbnail,
      content: CarouselStreamWrapperClassNames.Avatar,
    },
  });

  const streamSelectable = useMemo(() => {
    if (stream?.isTempStream) return false;

    return (
      (streamSelected || (!streamSelected && canSelect(stream))) &&
      userEventPermissions?.[EventPermission.eventManageOthers]
    );
  }, [canSelect, stream, streamSelected, userEventPermissions]);

  const isStreamInitializing = useMemo(
    () =>
      stream?.isTempStream &&
      (participant?.mediaSettings?.videoInputsReady || participant?.mediaSettings.audioInputsReady),
    [
      participant?.mediaSettings.audioInputsReady,
      participant?.mediaSettings?.videoInputsReady,
      stream?.isTempStream,
    ]
  );

  const streamInitializingIndicator = useMemo(
    () => (isStreamInitializing ? <>Initializing...</> : null),
    [isStreamInitializing]
  );

  return (
    <>
      <VideoWrapper
        id={stream?.id}
        displayName={streamDisplayName}
        customLeftBadge={
          <BroadcastStreamBadge
            stream={stream}
            participant={participant as Participant}
            audioOnly={audioOnly}
          />
        }
        customRightBadge={isPlayableAsset && <DisplayAssetDuration stream={stream} />}
        thumbnailImg={thumbnail}
        toolTipProps={selectionTooltipMsg && { label: selectionTooltipMsg }}
        topRightProps={{
          buttonProps: {
            theme: ButtonTheme.None,
            className: "csw-utility-btn",
            id: "carousel",
          },
          ...(menuItems.length && { popOverProps: { options: menuItems } }),
        }}
        topLeftProps={{
          custom: streamInitializingIndicator,
          release:
            stream.isV2AssetStream &&
            currentEvent.configuration?.layoutManager?.enabled &&
            featureFlags[FeatureFlagKeys.LayoutManagerUi]?.tagState,
        }}
        selected={streamSelected}
        initializing={isStreamInitializing}
        onSelect={streamSelectable && onSelectStream}
      >
        {children}
      </VideoWrapper>
      <Message
        className={getRebrandingClassName(VideoWrapperClassNames.Confirmation)}
        messageType={MessageType.Warning}
        title="Remove From Stage"
        message={`${streamDisplayName || "Your selection"} will be removed from the live stage`}
        visible={removeStreamConfirmation}
        primaryActionProps={{
          label: "remove",
          // @ts-ignore
          theme: ButtonTheme.Cherry,
          onClick: onConfirmRemove,
        }}
        secondaryActionProps={{
          label: "cancel",
          onClick: close(setRemoveStreamConfirmation),
        }}
        onCloseRequest={close(setRemoveStreamConfirmation)}
      />
      {selectionPreviewModal && (
        <AudioVideoPreviewComponent
          meetingId={currentEvent?.meetingId}
          asset={{
            ...(streamAsset as Asset),
            name: "av/" + (streamAsset as AudioVideoUploads).id,
            duration: (streamAsset as AudioVideoUploads).context?.duration,
          }}
          assetV2={(stream as AssetBroadcastStream).asset}
          visible={selectionPreviewModal}
          onCloseRequest={close(setAssetPreviewModal)}
        />
      )}
    </>
  );
};
