import {
  gql,
  MutationHookOptions,
  SubscriptionHookOptions,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { Group } from "./groups.hook.definition";

// Named this interface the same as it's defined in events-platform-api
export interface UpdateEventGroupsParams {
  meetingId: number;
  groups: Group[];
}

export const UPDATE_GROUPS_MUTATION = gql`
  mutation updateEventGroups($meetingId: Int, $groups: [UpdateEventGroupsParams]!) {
    updateEventGroups(meetingId: $meetingId, groups: $groups) {
      groups {
        name
        identifier
      }
    }
  }
`;

export function useUpdateGroupsMutation(options?: MutationHookOptions<UpdateEventGroupsParams>) {
  return useMutation<UpdateEventGroupsParams>(UPDATE_GROUPS_MUTATION, options);
}

export interface UpdateGroupsSubVars {
  meetingId: number;
}

export interface UpdateGroupsSubData {
  onEventGroupsUpdated: {
    groups: Group[];
  };
}

export const UPDATE_GROUPS_SUBSCRIPTION = gql`
  subscription($meetingId: Int) {
    onEventGroupsUpdated(meetingId: $meetingId) {
      groups {
        participants {
          identifier
          streamType
        }
        identifier
        name
      }
    }
  }
`;

export function useUpdateGroupsSubscription(
  options?: SubscriptionHookOptions<UpdateGroupsSubData, UpdateGroupsSubVars>
) {
  return useSubscription<UpdateGroupsSubData, UpdateGroupsSubVars>(
    UPDATE_GROUPS_SUBSCRIPTION,
    options
  );
}
