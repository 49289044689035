/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Anchor,
  AnchorTheme,
  AsyncSelect,
  ButtonTheme,
  Form,
  Modal,
  NotificationService,
  SelectTheme,
  Text,
  Textbox,
  TextboxTheme,
} from "@q4/nimbus-ui";
import { isEmpty, keys, reduce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import useCompanies from "../../../hooks/companies/companies.hook";
import { CompanyDTO } from "../../../hooks/companies/companies.hook.definition";
import { useOrganizationsSelect } from "../../../hooks/organizations/organizations.hook";
import { debounce } from "../../../utils/ui.utils";
import { CompanyModalClassNames } from "../../../views/companies/companies.definition";
import { ErrorModel } from "../modal.definition";
import { useOrganizationSyncFeatureFlag } from "../../../hooks/featureFlagHooks/useOrganizationSyncFeatureFlag.hook";

import {
  CompanyModalErrorKey,
  CompanyModalErrorMessage,
  CompanyModalErrorState,
  CompanyModalProps,
  CompanyState,
  CustomIdentifierRegex,
  DCMClassNames,
  ModalType,
} from "./companyModal.definition";

import "./companyModal.scss";

function getInitialErrorState() {
  return new CompanyModalErrorState({
    companyNameError: new ErrorModel(),
    companyTickerError: new ErrorModel(),
    companyOrganizationIdError: new ErrorModel(),
  });
}

export default function CompanyModal(props: CompanyModalProps): JSX.Element {
  const {
    modalType = ModalType.Add,
    modelOpen,
    onCompanyModalClose,
    company,
    onLoading,
    loading,
    onCompanyUpdate,
  } = props;

  // Refs
  const notificationService = useRef(new NotificationService());

  // States
  const [companyState, setCompanyState] = useState(new CompanyState(null));
  const [errorState, setErrorState] = useState<CompanyModalErrorState>(
    new CompanyModalErrorState(getInitialErrorState())
  );
  const [_dupeMappedCompanies, setDupeMappedCompanies] = useState<
    Array<{ name: string; id: string }>
  >([]);

  // Hooks
  const history = useHistory();
  const { createCompany, company: storedCompany } = useCompanies();
  const { organizationSyncEnabled } = useOrganizationSyncFeatureFlag();
  const { companies: dupeMappedCompanies, getCompanies: getDupeMappedCompanies } = useCompanies();

  const { organizationsLoading, getSearchResults } = useOrganizationsSelect();

  useEffect(() => {
    if (storedCompany?.id) {
      const companyPath = `/companies/${storedCompany.id}`;
      history.push(companyPath);
    }
  }, [history, storedCompany]);

  useEffect(
    function onOrgMappingChange() {
      if (!companyState?.companyOrganizationId?.value) return;

      getDupeMappedCompanies({
        limit: 10,
        page: 1,
        searchTerm: "",
        showManagedCompanies: false,
        sortField: "name",
        sortOrder: 1,
        orgIds: [companyState?.companyOrganizationId?.value],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyState?.companyOrganizationId]
  );

  useEffect(
    function onDupeMappedCompaniesChange() {
      setDupeMappedCompanies(
        (dupeMappedCompanies || []).map((_dupe: CompanyDTO) => ({
          name: _dupe.name,
          id: _dupe?.organization?.id || "",
        }))
      );
    },
    [dupeMappedCompanies]
  );

  function resetErrorState() {
    setErrorState(getInitialErrorState);
  }

  function handleSync(): void {
    const initialState = new CompanyState(company);
    setCompanyState(company ? { ...initialState, ...company } : initialState);
  }

  function handleReset(): void {
    resetErrorState();
    setCompanyState(new CompanyState(null));
  }

  function checkForErrors(): boolean {
    const currentErrorState = getInitialErrorState();

    if (!companyState.companyName?.trim()) {
      currentErrorState.companyNameError = new ErrorModel(
        true,
        CompanyModalErrorMessage.emptyCompanyNameErrorMessage,
        CompanyModalErrorKey.Name
      );
      setErrorState({ ...currentErrorState });
    }

    if (!companyState.companyTicker?.trim()) {
      currentErrorState.companyTickerError = new ErrorModel(
        true,
        organizationSyncEnabled
          ? CompanyModalErrorMessage.emptyCustomUrlErrorMessage
          : CompanyModalErrorMessage.emptyCompanyTickerErrorMessage,
        CompanyModalErrorKey.Ticker
      );
      setErrorState({ ...currentErrorState });
    } else if (
      companyState.companyTicker?.length < 1 ||
      !CustomIdentifierRegex.test(companyState.companyTicker)
    ) {
      currentErrorState.companyTickerError = new ErrorModel(
        true,
        CompanyModalErrorMessage.formattingCompanyTickerErrorMessage,
        CompanyModalErrorKey.Ticker
      );
      setErrorState({ ...currentErrorState });
    }

    if (!companyState.companyOrganizationId?.value?.trim()) {
      currentErrorState.companyOrganizationIdError = new ErrorModel(
        true,
        CompanyModalErrorMessage.emptyCompanyOrganizationIdErrorMessage,
        CompanyModalErrorKey.Q4PlatformOrganization
      );
      setErrorState({ ...currentErrorState });
    }

    if (
      !reduce(
        keys(currentErrorState),
        (acc, errKey) => {
          return currentErrorState[errKey].visible || acc;
        },
        false
      )
    ) {
      resetErrorState();
      return false;
    } else {
      return true;
    }
  }

  async function handleCompanyModalSubmit(): Promise<void> {
    const hasErrors = checkForErrors();
    if (hasErrors) return;

    if (onLoading) {
      onLoading(true);
    }
    const response = await createCompany({
      name: companyState.companyName,
      customIdentifier: companyState.companyTicker,
      organizationId: companyState.companyOrganizationId.value,
    });
    onCompanyUpdate({
      name: companyState.companyName,
      customIdentifier: companyState.companyTicker,
      organizationId: companyState.companyOrganizationId.value,
    });
    if (onLoading) {
      onLoading(false);
    }
    if (response) {
      handleModalClose();
    }
  }

  async function handleUpdateCompanyModalSubmit(): Promise<void> {
    const { onUpdateSubmission } = props;
    const hasErrors = checkForErrors();
    if (!onUpdateSubmission || hasErrors) return;

    const response = await onUpdateSubmission({
      name: companyState.companyName,
      customIdentifier: companyState.companyTicker,
      organizationId: companyState.companyOrganizationId.value,
    });

    if (response) {
      handleModalClose();
    }
  }

  function handleCompanyNameChange(value: string): void {
    setCompanyState((currentCompanyState) => ({ ...currentCompanyState, companyName: value }));
    if (errorState.companyNameError.visible) {
      setErrorState({ ...errorState, companyNameError: new ErrorModel() });
    }
  }

  function handleCompanyTickerChange(value: string): void {
    setCompanyState((currentCompanyState) => ({ ...currentCompanyState, companyTicker: value }));
    if (errorState.companyTickerError.visible) {
      setErrorState({ ...errorState, companyTickerError: new ErrorModel() });
    }
  }

  function handleCompanyOrganizationChange(selected: any): void {
    setCompanyState((currentCompanyState) => ({
      ...currentCompanyState,
      companyOrganizationId: selected,
    }));
    if (errorState.companyTickerError.visible) {
      setErrorState({ ...errorState, companyOrganizationIdError: new ErrorModel() });
    }
  }

  function handleModalClose(): void {
    resetErrorState();
    setCompanyState(new CompanyState());
    onCompanyModalClose();
  }

  async function loadOptions(inputValue: string, callback: any): Promise<void> {
    if (!isEmpty(inputValue)) {
      const options = await getSearchResults(inputValue);
      callback(options);
    }
  }
  if (modalType === ModalType.Add && organizationSyncEnabled) {
    notificationService.current.warn("Companies are now created in Capital Connect");
    return null;
  }

  return (
    <Modal
      className={CompanyModalClassNames.Base}
      title={modalType === ModalType.Add ? "New Company" : "Edit Company"}
      visible={modelOpen}
      onCloseRequest={handleModalClose}
      footerActions={[
        {
          key: "cancel",
          label: "CANCEL",
          theme: ButtonTheme.DarkSlate,
          onClick: handleModalClose,
          disabled: loading,
        },
        {
          key: "submit",
          label: "SAVE",
          theme: ButtonTheme.Citrus,
          loading: loading,
          onClick:
            modalType === ModalType.Add ? handleCompanyModalSubmit : handleUpdateCompanyModalSubmit,
        },
      ]}
      ghostableProps={{
        onEntering: handleSync,
        onExited: handleReset,
      }}
    >
      {organizationSyncEnabled && (
        <>
          <OrganizationSyncWarning />
          <div className="" />
        </>
      )}
      <Form
        fields={[
          {
            key: "name",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: "Company Name",
            error: errorState.companyNameError,
            className: CompanyModalClassNames.NameField,
            children: (
              <Textbox
                className={CompanyModalClassNames.NameFieldInput}
                value={companyState?.companyName}
                placeholder={company?.name}
                theme={errorState.companyNameError.visible ? TextboxTheme.Spice : null}
                onChange={handleCompanyNameChange}
                disabled={organizationSyncEnabled}
              />
            ),
          },
          {
            key: "ticker",
            width: "1-of-2",
            smallWidth: "1-of-1",
            label: organizationSyncEnabled ? "Custom URL" : "Stock Ticker",
            error: errorState.companyTickerError,
            className: CompanyModalClassNames.NameField,
            children: (
              <Textbox
                className={CompanyModalClassNames.NameFieldInput}
                value={companyState?.companyTicker}
                theme={errorState.companyTickerError.visible ? TextboxTheme.Spice : null}
                onChange={handleCompanyTickerChange}
              />
            ),
          },
          !organizationSyncEnabled && {
            key: "organizationId",
            width: "1-of-1",
            smallWidth: "1-of-1",
            label: "Q4 Platform Organization",
            error: errorState.companyOrganizationIdError,
            children: (
              <AsyncSelect
                value={companyState?.companyOrganizationId}
                loadOptions={debounce(loadOptions)}
                loading={organizationsLoading}
                theme={errorState.companyOrganizationIdError.visible ? SelectTheme.Spice : null}
                onChange={handleCompanyOrganizationChange}
              />
            ),
          },
        ].filter((field) => field)}
      />
      {!organizationSyncEnabled && (
        <DuplicateCompanyMappingWarning
          dupes={_dupeMappedCompanies}
          companyName={companyState?.companyOrganizationId?.label}
        />
      )}
    </Modal>
  );
}

const OrganizationSyncWarning = () => {
  const launchCreateOrgUrl = useCallback(() => window.open(config.app.capitalConnectDomain), []);

  return (
    <div className={DCMClassNames.LaunchSection}>
      <Text>
        To add or edit a company,{" "}
        <Anchor
          onClick={launchCreateOrgUrl}
          className={DCMClassNames.Launch}
          theme={AnchorTheme.Rain}
        >
          visit Capital Connect (Admin)
        </Anchor>{" "}
        or contact an event operations manager.
      </Text>
    </div>
  );
};

const DEFAULT_NUM_DUPES_TO_SHOW = 3;
const DuplicateCompanyMappingWarning = ({
  companyName,
  dupes,
}: {
  companyName: string;
  dupes: Array<{ id?: string; name: string }>;
}) => {
  const [showMore, setShowMore] = useState(false);
  const showMoreText = useMemo(
    () =>
      showMore
        ? {
            text: "Show less",
            icon: "q4i-caret-sm-up-4pt",
          }
        : {
            text: "Show more",
            icon: "q4i-caret-sm-down-4pt",
          },
    [showMore]
  );

  const dupesCount = useMemo(() => dupes?.length || 0, [dupes]);

  const listOfDupes = useMemo(
    () =>
      dupes.map((_dupe, index) => (
        <li
          key={_dupe.id || _dupe.name}
          className={`${DCMClassNames.DupeNamesList}${
            index > 2 && !showMore ? "--hidden" : "--show"
          }`}
        >
          {_dupe.name}
        </li>
      )),
    [dupes, showMore]
  );

  const toggleShowMore = useCallback(() => setShowMore(!showMore), [showMore]);

  if (!dupesCount) return null;

  return (
    <>
      <div className={DCMClassNames.Base}>
        <Text className={DCMClassNames.Summary}>
          <span className={DCMClassNames.SummaryIcon}>
            <i className="q4i-warning_solid"></i>
          </span>{" "}
          There are <strong>{dupesCount}</strong> existing companies already linked to{" "}
          <strong>{companyName || ""}</strong>
        </Text>
        <div className={DCMClassNames.DupeNames}>
          <ul className={DCMClassNames.DupeNamesList}> {listOfDupes}</ul>
          {dupesCount > DEFAULT_NUM_DUPES_TO_SHOW ? (
            <span>
              <Anchor
                onClick={toggleShowMore}
                className={DCMClassNames.ShowMore}
                theme={AnchorTheme.Rain}
              >
                {`${showMoreText.text} `}
                <i className={showMoreText.icon}></i>
              </Anchor>
            </span>
          ) : null}
        </div>
      </div>
      <OrganizationSyncWarning />
    </>
  );
};
