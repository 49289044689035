import { SelectionModel } from "../../../adminConsole/hooks/carouselSelection/carouselStreamSelection.definition";
import { BroadcastStream } from "../../../adminConsole/interfaces/broadcastStream/broadcastStream";

export interface SpeakerLayoutProps {
  id?: string;
  cameraSelection: Array<BroadcastStream>;
  participantsByConnectionId?: object;
  renderOnChange: string;
  isPreview?: boolean;
  stageSelection?: (selection: SelectionModel) => void;
}

export enum SpeakerLayoutClassNames {
  CONTENT = "speakerLayoutContent",
  LAYOUT = "cameraLayout",
  WRAPPER = "cameraLayout_wrapper",
  WRAPPER_AUDIO_ONLY = "cameraLayout_wrapper_audio-only",
  WRAPPER_AUDIO_ONLY_POSTER = "cameraLayout_wrapper_audio-only--poster",
  WRAPPER_AUDIO_ONLY_SPEAKERS = "cameraLayout_wrapper_audio-only--speakers",
  VIDEO_ELEMENT = "cameraLayout_videoElement",
}

const dataIdPrefix = "SpeakerLayout";

export const DATA_IDS = {
  WRAPPER: `${dataIdPrefix}Wrapper`,
};
