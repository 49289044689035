import { BaseComponentProps, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import { SlideoutIdModel } from "../../../../components/slideout/slideout.definition";
import type { RegistrationField } from "../../../../services/event/registrationFieldGql.model";
import { CustomRegistrationFieldFormConsentIdModel } from "../customRegistrationFieldForm/consent/customRegistrationFieldFormConsent.definition";
import { CustomRegistrationFieldFormOptionListIdModel } from "../customRegistrationFieldForm/optionList/customRegistrationFieldFormOptionList.definition";
import { CustomRegistrationFieldFormPromptIdModel } from "../customRegistrationFieldForm/prompt/customRegistrationFieldFormPrompt.definition";
import { CustomRegistrationFieldFormTextIdModel } from "../customRegistrationFieldForm/text/customRegistrationFieldFormText.definition";
import { CustomRegistrationFieldFormTextBlockIdModel } from "../customRegistrationFieldForm/textBlock/customRegistrationFieldFormTextBlock.definition";
import { CustomRegistrationFieldTypeIdModel } from "../customRegistrationFieldTypeSelector/customRegistrationFieldTypeSelector.definition";

export interface CustomRegistrationFieldSlideoutProps extends BaseComponentProps {
  totalFieldCount: number;
  visible: boolean;
  currentFields: RegistrationField[];
  currentFieldState: [RegistrationField, React.Dispatch<React.SetStateAction<RegistrationField>>];
  isEditState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onClose: () => void;
  onUpdate: (field: RegistrationField, remove?: boolean) => void;
}

export class CustomRegistrationFieldSlideoutIdModel extends IdModelBase {
  slideout: SlideoutIdModel;
  textForm: CustomRegistrationFieldFormTextIdModel;
  promptForm: CustomRegistrationFieldFormPromptIdModel;
  textBlockForm: CustomRegistrationFieldFormTextBlockIdModel;
  consentForm: CustomRegistrationFieldFormConsentIdModel;
  dropdownForm: CustomRegistrationFieldFormOptionListIdModel;
  multichoiceForm: CustomRegistrationFieldFormOptionListIdModel;
  typeSelector: CustomRegistrationFieldTypeIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) return;

    this.slideout = new SlideoutIdModel(`${this.id}Slideout`);
    this.textForm = new CustomRegistrationFieldFormTextIdModel(`${this.id}TextForm`);
    this.promptForm = new CustomRegistrationFieldFormPromptIdModel(`${this.id}PromptForm`);
    this.textBlockForm = new CustomRegistrationFieldFormTextBlockIdModel(`${this.id}TextBlockForm`);
    this.consentForm = new CustomRegistrationFieldFormConsentIdModel(`${this.id}ConsentForm`);
    this.dropdownForm = new CustomRegistrationFieldFormOptionListIdModel(`${this.id}DropdownForm`);
    this.multichoiceForm = new CustomRegistrationFieldFormOptionListIdModel(
      `${this.id}MultichoiceForm`
    );
    this.typeSelector = new CustomRegistrationFieldTypeIdModel(`${this.id}TypeSelector`);
  }
}

export enum CustomRegistrationFieldSlideoutClassName {
  Base = "custom-registration-field-slideout",
}
