import moment, { MomentInput } from "moment-timezone";

const HOUR = 60 * 60;
/**
 * Formating the number of seconds into a readable value
 * @param seconds
 */
export function formatDuration(seconds: number) {
  const duration = moment.duration(seconds, "seconds");

  return seconds < HOUR
    ? moment(duration.as("milliseconds")).format("m:ss")
    : `${duration.hours()}:${moment(duration.as("milliseconds")).format("mm:ss")}`;
}

export type CustomDateFormat = "date" | "date-time" | "time" | "file-name";

export interface FormatDateOptions {
  format: CustomDateFormat;
  showTz?: boolean;
}

export function formatDate(date: MomentInput, { format, showTz }: FormatDateOptions) {
  const formats: Record<CustomDateFormat, string> = {
    "date": "MMMM D, YYYY",
    "date-time": "MMMM D, YYYY hh:mm A",
    "time": "hh:mm A",
    "file-name": "MMMM-D-YYYY",
  };

  const momentDate = moment(date);
  return (
    momentDate.format(formats[format] ?? format) + (showTz ? momentDate.format(" ([GMT]Z)") : "")
  );
}

export function minutesToMilliseconds(minutes: number): number {
  const millisecondsInSecond = 1000;
  const secondsInMinute = 60;
  return millisecondsInSecond * secondsInMinute * minutes;
}

export function getSecondsFromHHMMSS(value) {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return null;
}

export function toHHMMSS(secs: number, fullTimeFormat = false) {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  const formattedTime = [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== "00" || index > 0 || fullTimeFormat)
    .join(":");

  return fullTimeFormat ? formattedTime : formattedTime.replace(/^0/, "");
}

export const formatTime = (timeInSeconds = 0) => {
  if (timeInSeconds < 0) return "0:00";

  let seconds: string | number = Math.floor(timeInSeconds % 60);
  let minutes: string | number = Math.floor((timeInSeconds / 60) % 60);
  let hours: string | number = Math.floor(timeInSeconds / (60 * 60));

  seconds = seconds.toString().padStart(2, "0");
  if (timeInSeconds >= 600) minutes = minutes.toString().padStart(2, "0");
  if (timeInSeconds >= 36000) hours = hours.toString().padStart(2, "0");

  return timeInSeconds >= 3600 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};
