import { gql, QueryHookOptions, useQuery } from "@apollo/client";

export interface TimeDeltaModel {
  clientTime: Date;
  serverTime: Date;
  delta: number;
}

export interface GetTimeDeltaQueryData {
  getTimeDelta: TimeDeltaModel;
}
export type GetTimeDeltaQueryVars = {
  currentTime: Date;
};

export const GET_TIME_DELTA_QUERY = gql`
  query GET_TIME_DELTA_QUERY($currentTime: Date) {
    getTimeDelta(currentTime: $currentTime) {
      clientTime
      serverTime
      delta
    }
  }
`;

export function useTimeDeltaQuery<D = GetTimeDeltaQueryData, V = GetTimeDeltaQueryVars>(
  options: QueryHookOptions<D, V>
) {
  return useQuery<D, V>(GET_TIME_DELTA_QUERY, {
    ...options,
  });
}
