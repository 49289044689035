import { useContext } from "react";
import { FeatureFlagsContext } from "../../contexts/featureFlags";
import { FeatureFlagKeys } from "../../configurations/featureFlagKeys";

export const useOrganizationSyncFeatureFlag = () => {
  const { featureFlags } = useContext(FeatureFlagsContext);

  const { [FeatureFlagKeys.CCEPOrganizationSync]: organizationFeatureFlag } = featureFlags ?? {};

  const { enabled, enforceEntitlements } = organizationFeatureFlag ?? {};

  return { organizationSyncEnabled: enabled, enforceEntitlements };
};
