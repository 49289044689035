import React, { createContext, useReducer, FunctionComponent, useCallback } from "react";
import { ConsoleContextState, ConsoleContextProps } from "./console.definition";
import { ConsoleSlideOutType } from "../../views/broadcastSlideout/consoleSlideOut.definition";

const initialState: Partial<ConsoleContextState> = {
  slideOutCollapsed: true,
  bottomSlideOutCollapsed: true,
  currentSlideOutType: ConsoleSlideOutType.Settings,
  className: null,
};

function reducer(
  state: ConsoleContextState,
  action: Partial<ConsoleContextState>
): Partial<ConsoleContextState> {
  return {
    ...state,
    ...action,
  };
}

const ConsoleContext = createContext<Partial<ConsoleContextState>>({});
export const ConsoleProvider: FunctionComponent<ConsoleContextProps> = (props) => {
  const { children, onBottomSlideOutToggle, resetCurrentChannelRefState } = props;
  const [state, setState] = useReducer(reducer, initialState);

  // Functions for changing the state of the console
  const setSlideOutCollapsed = useCallback((collapsed: boolean) => {
    setState({
      slideOutCollapsed: collapsed,
    });
  }, []);

  const setBottomSlideOutCollapsed = useCallback((collapsed: boolean) => {
    setState({
      bottomSlideOutCollapsed: collapsed,
    });
  }, []);

  const setCurrentSlideOutType = useCallback((slideType: ConsoleSlideOutType) => {
    setState({
      currentSlideOutType: slideType,
    });
  }, []);

  const setClassName = useCallback((className: string) => {
    setState({
      className,
    });
  }, []);

  const handleSlideOutClose = useCallback((): void => {
    if (state.currentSlideOutType === ConsoleSlideOutType.Chats) resetCurrentChannelRefState?.();

    !state.slideOutCollapsed && setSlideOutCollapsed(true);
  }, [
    resetCurrentChannelRefState,
    setSlideOutCollapsed,
    state.currentSlideOutType,
    state.slideOutCollapsed,
  ]);

  const handleSlideOutRequest = useCallback(
    (slideOutType: ConsoleSlideOutType): void => {
      if (state.slideOutCollapsed) {
        setSlideOutCollapsed(false);
        setCurrentSlideOutType(slideOutType);
      } else if (state.currentSlideOutType !== slideOutType) {
        // switch console side slider without closing tab
        if (slideOutType !== ConsoleSlideOutType.Chats) {
          resetCurrentChannelRefState?.();
        }
        setCurrentSlideOutType(slideOutType);
      } else {
        handleSlideOutClose();
      }
    },
    [
      resetCurrentChannelRefState,
      handleSlideOutClose,
      setCurrentSlideOutType,
      setSlideOutCollapsed,
      state.currentSlideOutType,
      state.slideOutCollapsed,
    ]
  );

  const handleBottomSlideOutRequest = useCallback((): void => {
    setBottomSlideOutCollapsed(!state.bottomSlideOutCollapsed);
    onBottomSlideOutToggle?.(!state.bottomSlideOutCollapsed);
  }, [onBottomSlideOutToggle, setBottomSlideOutCollapsed, state.bottomSlideOutCollapsed]);

  return (
    <ConsoleContext.Provider
      value={{
        ...state,
        setSlideOutCollapsed,
        setBottomSlideOutCollapsed,
        setCurrentSlideOutType,
        setClassName,
        handleSlideOutRequest,
        handleBottomSlideOutRequest,
        handleSlideOutClose,
      }}
    >
      {children}
    </ConsoleContext.Provider>
  );
};

export default ConsoleContext;
