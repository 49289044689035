import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import OTSignalingService, {
  OTSignalType,
  signalTypes,
} from "../../views/adminConsole/vendors/openTok/services/openTokSignaling.service";
import { AssetUploads } from "../assetUploads/assetUploads.definition";
import { getEventAssetControlProps } from "../controlAsset/displayControlAsset.hook";
import useRemoteAssetControlsHook from "../remoteAssetControls/remoteAssetControls.hook";
import { ControlSlidesProps } from "./useControlSlides.hook.definition";

export default function useControlSlides(props: ControlSlidesProps) {
  const { streams, session, staged, assets, assetsAvailable, canControlSlides } = props;

  const [opentokSignal, setOpentokSignal] = useState(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [totalSlides, setTotalSlides] = useState<number>(1);

  const canControlSlidesRef = useRef(false);

  const remoteAssetControls = useRemoteAssetControlsHook({ streams, skip: true });
  const assetControlsService = remoteAssetControls?.assetControlsService;

  const stagedAssetName = useMemo(
    function getStagedAssetName() {
      const assetIsStaged = staged?.mediaSelection.find(
        (stream) => stream.isSlideAsset && !stream.isV2AssetStream
      );
      return assetIsStaged?.name || "";
    },
    [staged]
  );

  useEffect(() => {
    if (session) setOpentokSignal(OTSignalingService.getInstance(session));
  }, [session]);

  useEffect(() => {
    canControlSlidesRef.current = canControlSlides;
  }, [canControlSlides]);

  const assetMapperByName = useMemo(() => {
    return assets?.reduce((acc, asset) => {
      const assetId = (asset as AssetUploads).id ?? asset.name;
      acc[assetId] = asset;
      return acc;
    }, {});
  }, [assets]);

  const stagedAssetInfo = useMemo(() => {
    return getEventAssetControlProps(assetMapperByName, staged);
  }, [staged, assetMapperByName]);

  const getAssetStats = useCallback(
    (name: string) => {
      stagedAssetInfo?.stream?.connection &&
        opentokSignal.signalStreams(
          [stagedAssetInfo.stream.connection],
          signalTypes.GET_ASSET_STATS
        );
    },
    [opentokSignal, stagedAssetInfo]
  );

  const receiveStats = useCallback((event: OTSignalType) => {
    try {
      const parsedStats = JSON.parse(event.data.split(";")[1]);
      parsedStats.currentPage && setCurrentSlide(parsedStats.currentPage);
      parsedStats.totalPages && setTotalSlides(parsedStats.totalPages);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const jumpToSlide = useCallback(
    (targetSlide: number, escapeControlCheck?: boolean) => {
      if (
        stagedAssetInfo &&
        !stagedAssetInfo.stream.isV2AssetStream &&
        (canControlSlidesRef.current || escapeControlCheck)
      ) {
        opentokSignal.signalStreams(
          [stagedAssetInfo?.stream.connection],
          signalTypes.JUMP_TO_SLIDE,
          `${stagedAssetInfo?.stream.name};${targetSlide}`
        );
      }
      if (stagedAssetInfo?.stream.isV2AssetStream) {
        assetControlsService?.changeSlide(stagedAssetInfo.stream, targetSlide);
        setCurrentSlide(targetSlide);
      }
    },
    [opentokSignal, assetControlsService, stagedAssetInfo]
  );

  useEffect(
    function getAssetStatsOnStageChange() {
      stagedAssetName && getAssetStats(stagedAssetName);
    },
    [stagedAssetName, getAssetStats]
  );

  useEffect(
    function registerWorkerListener() {
      if (!opentokSignal || !assetsAvailable) return;
      session?.on(`signal:${signalTypes.ASSET_STATS}`, receiveStats);
      return () => {
        session?.off(`signal:${signalTypes.ASSET_STATS}`, receiveStats);
      };
    },
    [session, assetsAvailable, opentokSignal, receiveStats]
  );

  return {
    controlSlidesState: {
      currentSlide,
      totalSlides,
    },
    controlSlidesService: {
      jumpToSlide,
      setTotalSlides,
    },
  };
}
