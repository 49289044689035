import { useState, useRef, useEffect, useCallback } from "react";
import { findIndex } from "lodash";
import { NotificationService } from "@q4/nimbus-ui";
import {
  ApolloError,
  MutationHookOptions,
  useMutation,
  QueryHookOptions,
  useQuery,
  useLazyQuery,
} from "@apollo/client";

import { UserType } from "../../services/user/user.definition";
import { assignUserProps } from "../../components/modal/assignEventManagerModal/assignEventManager.definition";
import { User } from "../../services/user/user.model";
import {
  GET_USERS_QUERY,
  GET_USERS_LIVE_EVENT_QUERY,
  DELETE_USER_MUTATION,
  DeleteUserMutationData,
  DeleteUserMutationVars,
  UsersHookProps,
  GetUsersLiveEventQueryData,
  GetUsersLiveEventQueryVars,
  GetUsersLiveEventQueryResponse,
  GetUsersQueryData,
  GetUsersQueryResponse,
  GetUsersQueryVars,
  SuspendUserMutationData,
  SuspendUserMutationVars,
  UnsuspendUserMutationData,
  UnsuspendUserMutationVars,
  SUSPEND_USER_MUTATION,
  UNSUSPEND_USER_MUTATION,
  UnlockUserMutationData,
  UnlockUserMutationVars,
  UNLOCK_USER_MUTATION,
  ResendUserInviteMutationVars,
  RESEND_USER_INVITE_MUTATION,
  UpdateUserMutationData,
  UpdateUserMutationVars,
  UPDATE_USER_MUTATION,
  AssignCompanyMutationData,
  AssignCompanyMutationVars,
  ASSIGN_COMPANY_MUTATION,
  UnassignCompanyMutationData,
  UnassignCompanyMutationVars,
  UNASSIGN_COMPANY_MUTATION,
  CreateUsersMutationData,
  CreateUsersMutationVars,
  CREATE_USERS_MUTATION,
  IsEmailRegisteredQueryVars,
  IsEmailRegisteredQueryData,
  IS_EMAIL_REGISTERED_QUERY,
  GetUserRegistrationQueryVars,
  GetUserRegistrationQueryData,
  GET_USER_REGISTRATION_QUERY,
  USER_REGISTER_MUTATION,
  UserRegisterMutationData,
  UserRegisterMutationVars,
  UPDATE_LOGIN_COUNT_QUERY,
  UpdateLoginCountMutationData,
  UpdateLoginCountMutationVars,
  GET_FAILED_LOGIN_COUNT_QUERY,
  GetFailedLoginCountQueryData,
  GetFailedLoginCountQueryVars,
  RESET_USER_PASSWORD_MUTATION,
  ResetUserPasswordMutationData,
  ResetUserPasswordMutationVars,
  UPDATE_USER_PASSWORD_MUTATION,
  UpdateUserPasswordMutationData,
  UpdateUserPasswordMutationVars,
  REQUEST_RESET_USER_PASSWORD_MUTATION,
  RequestResetUserPasswordMutationData,
  RequestResetUserPasswordMutationVars,
} from "./users.hook.definition";

export function useDeleteUserMutation(
  options?: MutationHookOptions<DeleteUserMutationData, DeleteUserMutationVars>
) {
  return useMutation<DeleteUserMutationData, DeleteUserMutationVars>(DELETE_USER_MUTATION, options);
}

export function useSuspendUserMutation<D = SuspendUserMutationData, V = SuspendUserMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(SUSPEND_USER_MUTATION, options);
}

export function useUnsuspendUserMutation<
  D = UnsuspendUserMutationData,
  V = UnsuspendUserMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UNSUSPEND_USER_MUTATION, options);
}

export function useUnlockUserMutation<D = UnlockUserMutationData, V = UnlockUserMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(UNLOCK_USER_MUTATION, options);
}

export function useResendUserInviteMutation<D = void, V = ResendUserInviteMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(RESEND_USER_INVITE_MUTATION, options);
}

export function useUpdateUserMutation<D = UpdateUserMutationData, V = UpdateUserMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(UPDATE_USER_MUTATION, options);
}

export function useCreateUsersMutation<D = CreateUsersMutationData, V = CreateUsersMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(CREATE_USERS_MUTATION, options);
}

export function useAssignCompanyMutation<
  D = AssignCompanyMutationData,
  V = AssignCompanyMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(ASSIGN_COMPANY_MUTATION, options);
}

export function useUnassignCompanyMutation<
  D = UnassignCompanyMutationData,
  V = UnassignCompanyMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UNASSIGN_COMPANY_MUTATION, options);
}

export function useResetUserPasswordMutation<
  D = ResetUserPasswordMutationData,
  V = ResetUserPasswordMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(RESET_USER_PASSWORD_MUTATION, options);
}

export function useUpdateUserPasswordMutation<
  D = UpdateUserPasswordMutationData,
  V = UpdateUserPasswordMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UPDATE_USER_PASSWORD_MUTATION, options);
}

export function useRequestResetUserPasswordMutation<
  D = RequestResetUserPasswordMutationData,
  V = RequestResetUserPasswordMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(REQUEST_RESET_USER_PASSWORD_MUTATION, options);
}

export function useUserRegisterMutation<D = UserRegisterMutationData, V = UserRegisterMutationVars>(
  options?: MutationHookOptions<D, V>
) {
  return useMutation<D, V>(USER_REGISTER_MUTATION, options);
}

export function useUpdateLoginCountMutation<
  D = UpdateLoginCountMutationData,
  V = UpdateLoginCountMutationVars
>(options?: MutationHookOptions<D, V>) {
  return useMutation<D, V>(UPDATE_LOGIN_COUNT_QUERY, options);
}

export function useGetUsersLiveEventQuery<
  D = GetUsersLiveEventQueryData,
  V = GetUsersLiveEventQueryVars
>(options?: QueryHookOptions<D, V>) {
  return useQuery<D, V>(GET_USERS_LIVE_EVENT_QUERY, options);
}

export function useGetUsersQuery<D = GetUsersQueryData, V = GetUsersQueryVars>(
  options?: QueryHookOptions<D, V>
) {
  return useQuery<D, V>(GET_USERS_QUERY, options);
}

export function useIsEmailRegisteredQuery<
  D = IsEmailRegisteredQueryData,
  V = IsEmailRegisteredQueryVars
>(options?: QueryHookOptions<D, V>) {
  return useQuery<D, V>(IS_EMAIL_REGISTERED_QUERY, options);
}

export function useGetUserRegistrationQuery<
  D = GetUserRegistrationQueryData,
  V = GetUserRegistrationQueryVars
>(options?: QueryHookOptions<D, V>) {
  return useQuery<D, V>(GET_USER_REGISTRATION_QUERY, options);
}

export function useGetFailedLoginCountLazyQuery<
  D = GetFailedLoginCountQueryData,
  V = GetFailedLoginCountQueryVars
>(options?: QueryHookOptions<D, V>) {
  return useLazyQuery<D, V>(GET_FAILED_LOGIN_COUNT_QUERY, options);
}

export default function useUsers(props?: UsersHookProps) {
  const { user, updateUser } = props || {};
  const [users, setUsers] = useState<UserType[]>();
  const [totalRecords, setTotalRecords] = useState(0);
  const notificationService = useRef(new NotificationService());

  const [deleteUserMutation] = useDeleteUserMutation({
    onCompleted() {
      notificationService.current.success("User successfully deleted");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to delete user");
    },
  });

  const [suspendUserMutation] = useSuspendUserMutation({
    onCompleted() {
      notificationService.current.success("User successfully suspended");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to suspend user");
    },
  });

  const [unsuspendUserMutation] = useUnsuspendUserMutation({
    onCompleted() {
      notificationService.current.success("User successfully reactivated");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to reactivate user");
    },
  });

  const [unlockUserMutation] = useUnlockUserMutation({
    onCompleted() {
      notificationService.current.success("User successfully unlocked");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to unlock user");
    },
  });

  const [resendUserInviteMutation] = useResendUserInviteMutation({
    onCompleted() {
      notificationService.current.success("An email has been resend successfully");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to resend email to user");
    },
  });

  const [updateUserMutation] = useUpdateUserMutation({
    onCompleted() {
      notificationService.current.success("The user has been updated");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to update user");
    },
  });

  const [createUsersMutation] = useCreateUsersMutation({
    onCompleted() {
      notificationService.current.success(`The invitation has been sent to users`);
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to invite user");
    },
  });

  const [assignCompanyMutation] = useAssignCompanyMutation();

  const [unassignCompanyMutation] = useUnassignCompanyMutation();

  const [resetUserPasswordMutation] = useResetUserPasswordMutation({
    onCompleted() {
      notificationService.current.success("Your password has been reset successfully!");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to reset the password");
    },
  });

  const [updateUserPasswordMutation] = useUpdateUserPasswordMutation({
    onCompleted() {
      notificationService.current.success("Your password has been changed successfully!");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Current password is not valid");
    },
  });

  const [requestResetUserPasswordMutation] = useRequestResetUserPasswordMutation({
    onCompleted() {
      notificationService.current.success("Check your email for a password reset link");
    },
    onError(err: ApolloError) {
      console.error(err);
      notificationService.current.error("Failed to request new password");
    },
  });

  const { refetch: _getUsersLiveEvent } = useGetUsersLiveEventQuery({ skip: true });

  const getUsersLiveEvent = useCallback(
    async function (
      params: GetUsersLiveEventQueryVars,
      errorNotification: boolean = true
    ): Promise<GetUsersLiveEventQueryResponse> {
      const response = await _getUsersLiveEvent(params);

      if (response?.error) {
        errorNotification && notificationService.current.error("Failed to get users");
        return null;
      }

      const fetchedUsers = response?.data?.getUsersLiveEvent;

      return {
        users: fetchedUsers.users,
        totalUsers: fetchedUsers.totalUsers,
      };
    },
    [_getUsersLiveEvent]
  );

  const { refetch: fetchUsers } = useGetUsersQuery({ skip: true });

  const getUsers = useCallback(
    async function (
      params: GetUsersQueryVars,
      errorNotification: boolean = true,
      updateState: boolean = true
    ): Promise<GetUsersQueryResponse> {
      const response = await fetchUsers(params);

      if (response?.error) {
        errorNotification && notificationService.current.error("Failed to get users");
        return null;
      }

      const getUsersRes = response?.data?.getUsers;

      if (updateState) {
        setUsers(getUsersRes?.users);
        setTotalRecords(getUsersRes?.totalUsers);
      }

      return { users: response?.data?.getUsers?.users };
    },
    [fetchUsers]
  );

  useEffect(() => {
    user && resetUser(user);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetUser = useCallback(
    function (updatedUser: UserType) {
      const index = findIndex(users, { id: updatedUser.id });
      if (index > -1) {
        const _users = users.map((targetUser) =>
          targetUser.id === updatedUser.id ? { ...targetUser, ...updatedUser } : targetUser
        );
        setUsers(_users);
      }
    },
    [users]
  );

  const assignCompany = useCallback(
    async function (companyId: string, assignUsers: assignUserProps[]): Promise<void> {
      try {
        await assignCompanyMutation({
          variables: {
            companyId,
            userIds: assignUsers.map(({ id }) => id),
          },
        });

        notificationService.current.success(
          `${assignUsers.map(({ email }) => email).join(", ")} assigned as event manager`
        );
      } catch (e) {
        console.error(e);
        notificationService.current.error("Failed to assign managers");
      }
    },
    [assignCompanyMutation]
  );

  const unassignCompany = useCallback(
    async function (companyId: string, unAssignUsers: User[]): Promise<void> {
      try {
        await unassignCompanyMutation({
          variables: {
            companyId,
            userIds: unAssignUsers.map(({ id }) => id),
          },
        });

        notificationService.current.success(
          `${unAssignUsers.map(({ email }) => email).join(", ")} unassigned as event manager`
        );
      } catch (e) {
        console.error(e);
        notificationService.current.error("Failed to unassign managers");
      }
    },
    [unassignCompanyMutation]
  );

  const deleteUser = useCallback(
    async function (userId: string, companyId?: string): Promise<void> {
      await deleteUserMutation({
        variables: {
          id: userId,
          companyId,
        },
      });
    },
    [deleteUserMutation]
  );

  const suspend = useCallback(
    async function (userId: string): Promise<void> {
      const response = await suspendUserMutation({
        variables: {
          id: userId,
        },
      });

      resetUser(response?.data?.suspendUser);
    },
    [resetUser, suspendUserMutation]
  );

  const unsuspend = useCallback(
    async function (userId: string): Promise<void> {
      const response = await unsuspendUserMutation({
        variables: {
          id: userId,
        },
      });

      resetUser(response?.data?.unsuspendUser);
    },
    [resetUser, unsuspendUserMutation]
  );

  const unlock = useCallback(
    async function (userId: string): Promise<void> {
      const response = await unlockUserMutation({
        variables: {
          id: userId,
        },
      });

      resetUser(response?.data?.unlockUser);
    },
    [resetUser, unlockUserMutation]
  );

  const resendUserInvite = useCallback(
    async function (email: string, companyName: string): Promise<void> {
      await resendUserInviteMutation({
        variables: {
          email,
          companyName,
        },
      });
    },
    [resendUserInviteMutation]
  );

  const updateUserById = useCallback(
    async function (userId: string, data): Promise<void> {
      const response = await updateUserMutation({
        variables: {
          user: {
            id: userId,
            ...data,
          },
        },
      });

      const updatedUser = response?.data?.updateUser || ({} as any);

      const { firstName, lastName } = updatedUser;
      const _updatedUser = user ? { ...user, firstName, lastName } : updatedUser;
      resetUser(_updatedUser);
      updateUser?.(_updatedUser);
    },
    [resetUser, updateUser, updateUserMutation, user]
  );

  const createUsers = useCallback(
    async (variables: CreateUsersMutationVars) => {
      await createUsersMutation({ variables });
    },
    [createUsersMutation]
  );

  const { refetch: fetchIsEmailRegistered } = useIsEmailRegisteredQuery({ skip: true });

  const checkEmailExists = useCallback(
    async function (email: string): Promise<boolean> {
      try {
        const response = await fetchIsEmailRegistered({
          email,
        });
        return response?.data?.isEmailRegistered;
      } catch (error) {
        notificationService.current.error("Failed to check the email");
      }
    },
    [fetchIsEmailRegistered]
  );

  const resetPassword = useCallback(
    async (variables: ResetUserPasswordMutationVars): Promise<boolean> => {
      const response = await resetUserPasswordMutation({ variables });
      return !!response?.data?.resetUserPassword;
    },
    [resetUserPasswordMutation]
  );

  const updatePassword = useCallback(
    async (variables: UpdateUserPasswordMutationVars): Promise<boolean> => {
      const response = await updateUserPasswordMutation({ variables });
      return !!response?.data?.updateUserPassword;
    },
    [updateUserPasswordMutation]
  );

  const requestResetPassword = useCallback(
    async (variables: RequestResetUserPasswordMutationVars): Promise<void> => {
      await requestResetUserPasswordMutation({ variables });
    },
    [requestResetUserPasswordMutation]
  );

  return {
    getUsers,
    getUsersLiveEvent,
    inviteUsers: createUsers,
    users,
    totalRecords,
    resendUserInvite,
    updateUserById,
    checkEmailExists,
    requestResetPassword,
    resetPassword,
    updatePassword,
    suspend,
    unsuspend,
    unlock,
    assignCompany,
    unassignCompany,
    deleteUser,
  };
}
