import { useState, useRef, useCallback, useEffect } from "react";

import { SlidePlayerModel } from "./player.definition";
import { useStateRef } from "@q4/nimbus-ui";
import { SlidePageManagerModel } from "../../components/slideViewer/slideViewer.definition";
import { debounce } from "lodash";

// rendering slides in FHD to improve resolution
const canvasWidth = 1920;
const canvasHeight = 1080;

const ratio = canvasWidth / canvasHeight;

export default function useSlidePlayer(): SlidePlayerModel {
  const [assetReady, setAssetReady] = useState(false);

  const slidesCanvas = useRef<HTMLCanvasElement>(null);

  const canvas = useRef<HTMLCanvasElement>();

  const canvasDimensions = useRef({
    width: 0,
    height: 0,
  });
  const animationRef = useRef<number>();

  const isCanvasNeedToClear = useRef(true);

  const pageManager = useSlidePageManager();

  const createCanvas = (width: number, height: number) => {
    const _canvas = document.createElement("canvas");
    _canvas.width = width;
    _canvas.height = height;
    canvas.current = _canvas;

    return _canvas;
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const renderCanvas: () => void = useCallback(
    debounce(() => {
      if (slidesCanvas.current) {
        const { width, height } = canvasDimensions.current;
        const { width: slidesCanvasWidth, height: slidesCanvasHeight } = slidesCanvas.current;

        if (!width || !height || !slidesCanvasWidth || !slidesCanvasHeight) {
          animationRef.current = window.requestAnimationFrame(renderCanvas);
          return;
        }

        // For debuging
        // if (!(window as any).a) {
        //   console.log(canvas.current.width, canvas.current.height, offset, width, height);
        //   (window as any).a = 1;
        // }

        if (isCanvasNeedToClear.current) {
          if (height > width / ratio) {
            canvas.current.height = height;
            canvas.current.width = height * ratio;
          } else {
            canvas.current.height = width / ratio;
            canvas.current.width = width;
          }
        }

        const offset = {
          xOffset: (canvas.current.width - width) / 2,
          yOffset: (canvas.current.height - height) / 2,
        };

        const ctx = canvas.current.getContext("2d", { alpha: false });

        ctx.imageSmoothingQuality = "high";

        if (isCanvasNeedToClear.current) {
          // clear canvas before painting
          ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
          isCanvasNeedToClear.current = false;
        }

        ctx.drawImage(slidesCanvas.current, offset.xOffset, offset.yOffset, width, height);
      }

      animationRef.current = window.requestAnimationFrame(renderCanvas);
    }, 200),
    []
  );

  useEffect(function cleanup() {
    return () => {
      if (animationRef.current) window.cancelAnimationFrame(animationRef.current);
    };
  }, []);

  const onPageChange = useCallback(() => {
    slidesCanvas.current = _getSlidesCanvas();
    const { width, height } = slidesCanvas.current?.getBoundingClientRect() ?? {};
    canvasDimensions.current = { width, height };

    if (animationRef.current) window.cancelAnimationFrame(animationRef.current);

    isCanvasNeedToClear.current = true;

    animationRef.current = window.requestAnimationFrame(renderCanvas);
  }, [renderCanvas]);

  const _getSlidesCanvas = () =>
    document.querySelector(".slide-viewer canvas") as HTMLCanvasElement;

  const initializeSlides = async (url) => {
    if (slidesCanvas.current) return;
    const canvas = _getSlidesCanvas();

    slidesCanvas.current = canvas;

    setTimeout(() => {
      initializeSlides(url);
    }, 5000);
  };

  const initialize = async (url: string) => {
    if (!url) return;
    await initializeSlides(url);

    createCanvas(canvasWidth, canvasHeight);

    setAssetReady(true);
  };

  const getAssetStream = async () => {
    return {
      videoTrack: (canvas.current as any).captureStream(90).getVideoTracks()[0],
      audioTrack: false,
      mediaStream: (canvas.current as any).captureStream(90),
    };
  };

  const getAssetStats = () => {
    return {
      currentPage: pageManager.getCurrentPage(),
      totalPages: pageManager.getPages(),
    };
  };

  return {
    assetReady,
    pageManager,
    getAssetStream,
    initialize,
    getAssetStats,
    onPageChange,
  };
}

export function useSlidePageManager(): SlidePageManagerModel {
  const [currentPage, currentPageRef, _setCurrentPage] = useStateRef(1);
  const [, pagesRef, _setPages] = useStateRef(1);

  const getCurrentPage = () => {
    return currentPageRef.current;
  };

  const getPages = () => {
    return pagesRef.current;
  };

  const setPages = useCallback(
    (totalPages: number) => {
      _setPages(totalPages);
    },
    [_setPages]
  );

  const setCurrentPage = useCallback(
    (targetPage: number) => {
      _setCurrentPage(targetPage);
    },
    [_setCurrentPage]
  );

  const jumpToPage = useCallback(
    (targetPage: number) => {
      if (currentPageRef.current === targetPage) {
        return;
      }
      setCurrentPage(targetPage);
    },
    [currentPageRef, setCurrentPage]
  );

  return {
    currentPage,
    getCurrentPage,
    getPages,
    setPages,
    setCurrentPage,
    jumpToPage,
  };
}
