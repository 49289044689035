import ApiService from "../api/api.service";
import { ApiResponse } from "../api/api.definition";
import { SubscriberAttendee } from "./attendees.model";

export default class AttendeeService {
  private readonly ATTENDEE_PATH = "/rest/attendee";
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  public getSubscriberAttendeeByEmail(
    meetingId: number,
    email: string
  ): Promise<ApiResponse<SubscriberAttendee>> {
    const useAuth = false;
    const queryParams = { meetingId, email };
    const returnFullResponse = true;
    return this.apiService.get(`${this.ATTENDEE_PATH}`, useAuth, queryParams, returnFullResponse);
  }
}
