import { InvestorTypeNames } from "../../views/broadcastParticipant/eventRegistration/components/eventRegistrationForm.definition";
import { RegistrationFieldOption } from "../event/registrationFieldGql.model";

export class Attendee {
  id?: string;
  eventId: number;
  email: string;
  firstName: string;
  lastName: string;
  investorType?: InvestorTypeNames;
  attendeeType: AttendeeType;
  companyName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  controlNumber?: string;
  wsConnectionId: string;
  attendeeFilter: string;
  page: number;
  limit: number;
  hasCompletedAttestationForm: boolean;
  type: UserType;
  registrationFieldAnswers?: AttendeeRegistrationFieldAnswer[];
}

export interface SubscriberAttendee {
  id: string;
  meetingId: string;
  email: string;
  attendeeType: AttendeeType;
  wsConnectionId: string;
}

export enum AttendeeType {
  SHAREHOLDER = "SHAREHOLDER",
  GUEST = "GUEST",
}

export enum UserType {
  Q4_LOGIN = "Q4_LOGIN",
  GUEST = "GUEST",
}

export enum AttendeeOriginType {
  EP_AUTH0 = "ep-auth0",
  Q4L_REGISTER = "Q4L-register",
  Q4L_LOGIN = "Q4L-login",
  Q4L_COOKIE = "Q4L-cookie",
  GUEST = "guest",
}

export type AttendeeRegistrationFieldAnswerTypes =
  | boolean
  | string
  | number
  | RegistrationFieldOption
  | RegistrationFieldOption[]
  | Date;

export interface AttendeeRegistrationFieldAnswer {
  _id?: string;
  _registrationField: string;
  _attendee?: string;
  _meeting: string;
  answer: AttendeeRegistrationFieldAnswerTypes;
}
