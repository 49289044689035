import React, { useMemo, useEffect, memo } from "react";
import {
  Modal,
  Text,
  Layout,
  ButtonTheme,
  ButtonProps,
  ModalProps,
  TextPreset,
  TextProps,
  TextTheme,
  LayoutPadding,
  getClassName,
} from "@q4/nimbus-ui";
import { useInactivityDetector } from "../../hooks/useInactivityDetector";
import {
  InactivityPromptClassName,
  InactivityPromptIdModel,
  InactivityPromptProps,
  DefaultBroadcastChannelId,
} from "./inactivityPrompt.definition";
import "./inactivityPrompt.component.scss";

export const InactivityPrompt = (props: InactivityPromptProps): JSX.Element => {
  const {
    className,
    id,
    dataId,
    broadcastChannelId,
    promptFooterAction,
    promptFooterActionLabel,
    promptMessageTitle,
    promptMessageSubtitle,
    timeToIdle,
    lockOnInactivity,
    onIdleHandler,
  } = props;

  const idModel = useMemo(getIdModel, [id]);
  const baseClassName = useMemo(getBaseClassName, [className]);

  const { userIsInactive } = useInactivityDetector({
    timeToIdle,
    broadcastChannelId,
    lockOnInactivity,
  });

  const promptActions: ButtonProps[] = [
    {
      key: "inactivity-prompt_primary-action",
      label: promptFooterActionLabel,
      theme: ButtonTheme.Citrus,
      onClick: promptFooterAction,
      id: idModel.modalButton.id,
    },
  ];
  const modalProps: ModalProps = {
    visible: userIsInactive,
    title: "",
    includeExit: false,
    footerActions: promptActions,
    id: idModel.modal.id,
  };
  const titleTextProps: TextProps = {
    preset: TextPreset.DisplayLarge,
    theme: TextTheme.DarkSlate,
    id: idModel.modalTitle.id,
  };
  const subTitleTextProps: TextProps = {
    preset: TextPreset.Subheader,
    theme: TextTheme.LightSlate,
    id: idModel.modalSubtitle.id,
  };

  function getIdModel(): InactivityPromptIdModel {
    return new InactivityPromptIdModel(id);
  }

  function getBaseClassName(): string {
    return getClassName(InactivityPromptClassName.Base, [
      { condition: !!className, trueClassName: className },
    ]);
  }

  useEffect(() => {
    if (userIsInactive && onIdleHandler) onIdleHandler();
  }, [userIsInactive, onIdleHandler]);

  return (
    <div className={baseClassName} id={idModel.component} data-id={dataId}>
      <Modal {...modalProps}>
        <Layout direction="column" justifyContent="space-between" padding={LayoutPadding.None}>
          <Text {...titleTextProps}>{promptMessageTitle}</Text>
          <Layout padding={LayoutPadding.Default}>
            <Text className={InactivityPromptClassName.Subtitle} {...subTitleTextProps}>
              {promptMessageSubtitle}
            </Text>
          </Layout>
        </Layout>
      </Modal>
    </div>
  );
};

InactivityPrompt.defaultProps = {
  broadcastChannelId: DefaultBroadcastChannelId,
  promptFooterActionLabel: "Login",
  promptMessageTitle: "Session Expired",
  promptMessageSubtitle:
    "You have been logged out due to inactivity. You will be redirected to the login page.",
};

export default memo(InactivityPrompt);
