import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React, { createContext, useCallback, useState } from "react";
import { AppDomain } from "../../configurations/routes/route.domains";

export interface AppContextState {
  meetingId: number;
  appDomain: AppDomain;
  updateMeetingId(meetingId: number): void;
  updateAppDomain(domain: AppDomain): void;
}

export const AppContext = createContext<Partial<AppContextState>>({});

export const AppProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const [meetingId, setMeetingId] = useState<number>(null);
  const [appDomain, setAppDomain] = useState<AppDomain>(null);

  /**
   * Update meeting id
   * @param meetingId
   */
  const updateMeetingId = useCallback((meetingId: number) => {
    setMeetingId(meetingId);
  }, []);

  /**
   * Update app domain
   * @param appDomain
   */
  const updateAppDomain = useCallback((appDomain: AppDomain) => {
    setAppDomain(appDomain);
  }, []);

  return (
    <AppContext.Provider
      value={{
        meetingId,
        appDomain,
        updateMeetingId,
        updateAppDomain,
      }}
    >
      {props?.children}
    </AppContext.Provider>
  );
};
