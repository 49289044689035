import Cookies from "js-cookie";
import { HelpCenterConfigs } from "./helpCenterAuth.configs";

export function setHelpCenterToken(token: string, expiryDays: number = 1) {
  const { key, domain, secure } = HelpCenterConfigs.token;
  Cookies.set(key, token, { expires: expiryDays, domain, secure });
}

export function getHelpCenterToken() {
  return Cookies.get(HelpCenterConfigs.token.key);
}
