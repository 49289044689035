import { FC } from "react";
import { PoweredByLinkWrapperProps } from "./poweredByLinkWrapper.definition";

const PoweredByLinkWrapper: FC<PoweredByLinkWrapperProps> = ({ children, clickable = false }) => {
  return clickable ? (
    <a href="https://www.q4inc.com/Powered-by-Q4/" target="blank">
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

export default PoweredByLinkWrapper;
