import { useVisibility } from "@q4/nimbus-ui";

export enum EventBrandingVisibilityActionTypes {
  OPEN_LOGO = "OPEN_LOGO",
  CLOSE_LOGO = "CLOSE_LOGO",
  OPEN_BACKGROUND = "OPEN_BACKGROUND",
  CLOSE_BACKGROUND = "CLOSE_BACKGROUND",
}

export interface EventBrandingVisibilityReducerAction {
  type: EventBrandingVisibilityActionTypes;
}

export interface EventBrandingVisibilityState {
  brandingLogoModalVisible: boolean;
  backgroundModalVisible: boolean;
}

export const useEventBrandingVisiblityReducer = () => {
  const [brandingLogoModalVisible, openBrandingLogoModal, closeBrandingLogoModal] = useVisibility();
  const [backgroundModalVisible, openChangeBackgroundModal, closeChangeBackgroundModal] =
    useVisibility();

  const visibilityReducer = ({ type }: EventBrandingVisibilityReducerAction): void => {
    switch (type) {
      case EventBrandingVisibilityActionTypes.OPEN_LOGO:
        return openBrandingLogoModal();
      case EventBrandingVisibilityActionTypes.CLOSE_LOGO:
        return closeBrandingLogoModal();
      case EventBrandingVisibilityActionTypes.OPEN_BACKGROUND:
        return openChangeBackgroundModal();
      case EventBrandingVisibilityActionTypes.CLOSE_BACKGROUND:
        return closeChangeBackgroundModal();
    }
  };

  return {
    state: {
      brandingLogoModalVisible,
      backgroundModalVisible,
    },
    updateVisibility: visibilityReducer,
  };
};
