import React, { createContext } from "react";
import { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { useFlags } from "../../hooks/featureFlags/featureFlags.hook";

export interface FeatureFlagsContextState {
  featureFlags: Record<string, any>;
}

export const FeatureFlagsContext = createContext<Partial<FeatureFlagsContextState>>({});

export const FeatureFlagsProvider = (props: BaseComponentWithChildrenProps): JSX.Element => {
  const { children } = props;

  const featureFlags = useFlags();

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags }}>{children}</FeatureFlagsContext.Provider>
  );
};
