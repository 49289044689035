import {
  BaseComponentProps,
  ButtonIdModel,
  CheckboxIdModel,
  FormIdModel,
  IdModelBase,
  isNullOrWhiteSpace,
  LayoutIdModel,
  TextIdModel,
} from "@q4/nimbus-ui";
import { RegistrationField } from "../../../../../services/event/registrationFieldGql.model";
import { FormFieldAnswers } from "./hook/customRegistrationForm.hook.definition";

export enum CustomRegistrationFormClassNames {
  Base = "custom-registration-form",
  Layout = "custom-registration-form_layout",
  Image = "custom-registration-form_image",
  Title = "custom-registration-form_title",
  Message = "custom-registration-form_message",
  Button = "custom-registration-form_button",
  Form = "custom-registration-form_container",
  TextBlock = "custom-registration-form_textblock",
  Consent = "custom-registration-form_consent",
  Dropdown = "custom-registration-form_dropdown",
  ConsentCheckbox = "custom-registration-form_consent-checkbox",
  MultiChoice = "custom-registration-form_multi-choice",
  Field = "custom-registration-form_field",
  Reminder = "custom-registration-form_reminder",
}

export interface CustomRegistrationFormProps extends BaseComponentProps {
  registrationFields: RegistrationField[];
  meetingId: string;
  companyName: string;
  showReminder?: boolean;
  title?: string;
  onSubmit?: (
    registrationFieldAnswers: FormFieldAnswers[],
    sendReminder?: boolean
  ) => Promise<void>;
}

export class CustomRegistrationFormIdModel extends IdModelBase {
  image: string;
  header: TextIdModel;
  message: TextIdModel;
  button: ButtonIdModel;
  form: FormIdModel;
  reminder: CheckboxIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) return;

    this.image = `${this.id}Image`;
    this.header = new LayoutIdModel(`${this.id}Header`);
    this.message = new TextIdModel(`${this.id}Message`);
    this.button = new ButtonIdModel(`${this.id}Button`);
    this.form = new FormIdModel(`${this.id}Form`);
    this.reminder = new CheckboxIdModel(`${this.id}ReminderCheckbox`);
  }
}

export const ChildSuffix = "CustomField";
