import { OTStreamType, OTStreamTypes } from "../types";

export function isStreamLocalPublisher(stream: OTStreamType): boolean {
  return !!stream?.isLocalPublisher;
}

export function isCameraStream(stream: OTStreamType): boolean {
  return stream?.videoType === OTStreamTypes.Custom || stream?.videoType === OTStreamTypes.Camera;
}

export function isShareScreenStream(stream: OTStreamType): boolean {
  return stream?.videoType === OTStreamTypes.Screen;
}

export function isPSTNStream(stream: OTStreamType): boolean {
  return stream?.videoType === OTStreamTypes.PSTN;
}

export function getStreamType(stream: OTStreamType): OTStreamTypes {
  if (isCameraStream(stream)) return OTStreamTypes.Camera;
  if (isShareScreenStream(stream)) return OTStreamTypes.Screen;
  if (isPSTNStream(stream)) return OTStreamTypes.PSTN;
}

export const newTempOTStream = () => ({
  userType: "",
  connection: {},
  creationTime: new Date().getTime(),
  frameRate: 0,
  hasAudio: false,
  hasVideo: false,
  id: "",
  name: "",
  streamId: "",
  videoDimensions: { height: 0, width: 0 },
  videoType: OTStreamTypes.Camera,
});
