import { useEffect, useState } from "react";

export enum BrowserTabVisibility {
  Visible = "visible",
  Hidden = "hidden",
}

export function useBrowserTabState() {
  const [browserTabActive, setBrowserTabActive] = useState(true);

  const visibilityChange = () => {
    if (document.visibilityState === BrowserTabVisibility.Visible) {
      setBrowserTabActive(true);
    } else {
      setBrowserTabActive(false);
    }
  };

  useEffect(function browserTabChange() {
    document.addEventListener("visibilitychange", () => visibilityChange());
    return function browserTabChangeCleanup() {
      document.removeEventListener("visibilitychange", () => visibilityChange());
    };
  }, []);

  return { browserTabActive };
}
