import { Auth0Client } from "@auth0/auth0-spa-js";
import { ErrorMessage } from "../../services/api/api.definition";
import { AttendeeOriginType } from "../../services/attendees/attendees.model";
import { PublicUserInfo } from "../../services/organization/organization.model";
import { FormEditState } from "../../views/broadcastParticipant/eventRegistration/eventRegistration.definition";
import { PublicEventDetails } from "../../services/event/eventGql.model";

export interface LoginULUserIntoEPParams {
  sendReminder?: boolean;
  extraFormData?: Record<string, any>;
  originType?: AttendeeOriginType;
  onSubmit?: (form: FormEditState, originType: AttendeeOriginType) => Promise<ErrorMessage>;
}

export interface SSOLoginParams {
  redirect_uri: string;

  // optional
  appState?: Record<string, string>;
  cancelUrl?: string;
  emailVerificationRedirect?: string;
  helpUrl?: string;
  passwordResetRedirect?: string;
  projectId?: string;
  projectIdentifier?: string;
  scope?: string;
  skipUrl?: string;
  step?: "login" | "register";
  widgetBackgroundUrl?: string;
  widgetText?: string;
}
export interface Q4LoginContextState {
  user: PublicUserInfo;
  isGuestRoute: boolean;
  isAuthenticated: boolean;
  isInitializingQ4Login: boolean;
  isQ4LoginEnabled: boolean;
  isAlreadyRegistered: boolean;
  auth0Client: Auth0Client;
  getRedirectUrlFromEvent(eventDetails: PublicEventDetails): string;
  loginULUserIntoEP(params: LoginULUserIntoEPParams): Promise<void>;
  logout(redirectUrl?: string): void;
  registerToEvent(meetingId: string): void;
  loginWithRedirect(options: SSOLoginParams): Promise<void>;
}

export enum NotificationErrorMessage {
  Q4UserNotFound = "Your user was not found. Please, register with Q4 before logging in.",
  MissingRegistrationData = "Your registration is missing some information. Please, try to register with Q4 again.",
  SomethingWentWrong = "Something went wrong. Please, try again later.",
}

export const Q4_LOGIN_EVENTS_REGISTER = "q4_login_events_register";
export const Q4_ATTENDEE_KEY_PREFIX = "q4_broadcast_attendee_";
