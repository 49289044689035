import "./loadingScreen.component.scss";
import React, { memo } from "react";
import { LoadingScreenClassName, LoadingScreenProps } from "./loadingScreen.definition";
import config from "../../config";

export const LoadingScreen = (props: LoadingScreenProps): JSX.Element => {
  return (
    <div className={LoadingScreenClassName.Base}>
      <div className={LoadingScreenClassName.Content}>
        <img
          src={`${config.branding.s3.domain}/public/frontend/Q4_logo_motion_poster_v2.jpg`}
          className={LoadingScreenClassName.Logo}
          alt="Q4 loading logo"
        />
        <div className={LoadingScreenClassName.LabelWrapper}>
          <div className={LoadingScreenClassName.Label}>
            Loading
            <span className={LoadingScreenClassName.Dots} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingScreen);
