import { ApolloError } from "@apollo/client";
import { useEffect } from "react";
import { Subject, Subscription } from "rxjs";

const apolloEventsSubject = new Subject();

export enum ApolloActions {
  CONNECTED = "APOLLO_CONNECTED",
  RECONNECTED = "APOLLO_RECONNECTED",
  DISCONNECTED = "APOLLO_DISCONNECTED",
  CONNECT_ERROR = "APOLLO_CONNECT_ERROR",
}

export type ApolloAction = {
  type: ApolloActions;
  payload?: any;
};

interface ApolloEventsHookProps {
  onConnectionError?: (error: ApolloError) => void;
  onConnected?: () => void;
  onReconnected?: () => void;
  onDisconnected?: () => void;
}

export function useApolloEvents(props?: ApolloEventsHookProps) {
  const { onConnectionError, onConnected, onReconnected, onDisconnected } = props || {};

  useEffect(function apolloEventsSubscribe() {
    const apolloEventsSub: Subscription = apolloEventsSubject.subscribe({
      next: (action: ApolloAction) => {
        switch (action.type) {
          case ApolloActions.CONNECTED:
            if (onConnected) onConnected();
            break;
          case ApolloActions.RECONNECTED:
            if (onReconnected) onReconnected();
            break;
          case ApolloActions.DISCONNECTED:
            if (onDisconnected) onDisconnected();
            break;
          case ApolloActions.CONNECT_ERROR:
            if (onConnectionError) onConnectionError(action.payload);
        }
      },
    });

    return function apolloEventsUnsubscribe() {
      apolloEventsSub.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    notifyConnectionError: (error) =>
      apolloEventsSubject.next({ type: ApolloActions.CONNECT_ERROR, payload: error }),
    notifyConnected: () => apolloEventsSubject.next({ type: ApolloActions.CONNECTED }),
    notifyReconnected: () => apolloEventsSubject.next({ type: ApolloActions.RECONNECTED }),
    notifyDisconnected: () => apolloEventsSubject.next({ type: ApolloActions.DISCONNECTED }),
  };
}
